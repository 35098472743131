<template>

  <div>

    <!-- side filter-->
    <course-list-side-filter
      v-if="isFilterActive"
      :isFilterActive.sync="isFilterActive"
      :masters="masters.rows"
      :number.sync="number"
      :group.sync="group"
      :classroom_id.sync="classroom_id"
      :mastercourse_id.sync="mastercourse_id"
      :teacher_id.sync="teacher_id"
      :fStatus.sync="fStatus"
      :time_of_day.sync="time_of_day"
      :date_start_from.sync="date_start_from"
      :date_start_to.sync="date_start_to"
      :date_end_from.sync="date_end_from"
      :date_end_to.sync="date_end_to"
      :classrooms="classrooms"
      :teachers="teachers"
      :TofDArray="TofDArray"
      :statusArray="statusArray"
    />
      <!-- :cities="cities" -->

    <!-- add course modal -->
    <courses-list-add
      v-if="isAddActive"
      :isAddActive.sync="isAddActive"
      :masters="masters.rows"
      :classrooms="classrooms"
      :teachers="teachers"
      :TofDArray="TofDArray"
      :statusArray="statusArray"
      @refetch-data="refetchData"
    />
      <!-- :cities="cities" -->

    <!-- add similar course modal -->
    <courses-list-add
      v-if="isAddsimilarActive"
      :isAddActive.sync="isAddsimilarActive"
      :masters="masters.rows"
      :classrooms="classrooms"
      :teachers="teachers"
      :TofDArray="TofDArray"
      :statusArray="statusArray"
      :currentCourseData="currentCourseData"
      @refetch-data="refetchData"
    />
      <!-- :cities="cities" -->

    <!-- old edit course modal -->
    <!--    <modal-course-->
    <!--      :isModalActive.sync="isModalActive"-->
    <!--      :data="singleData"-->
    <!--      :classrooms="classrooms"-->
    <!--      :teachers="teachers"-->
    <!--      :masters="masters.rows"-->
    <!--      :cities="cities"-->
    <!--      :msgs="msgs"-->
    <!--      :statusArray="statusArray"-->
    <!--      :UsersToAdd="UsersToAdd"-->
    <!--      :UsersAlreadyAdded="UsersAlreadyAdded"-->
    <!--      :currentUser="currentUser"-->
    <!--      :loading="spinner"-->
    <!--      @refetch-data="refetchData"-->
    <!--      @initilize="ModalData($event)"-->
    <!--      @loading="loadSpinner"-->
    <!--      v-if="isModalActive"-->
    <!--    />-->

    <!-- new big modal -->
    <!--    <courses-list-big-modal-->
    <!--      :isBigModalActive.sync="isBigModalActive"-->
    <!--      :classrooms="classrooms"-->
    <!--      :teachers="teachers"-->
    <!--      :masters="masters.rows"-->
    <!--      :cities="cities"-->
    <!--      :TofDArray="TofDArray"-->
    <!--      :currentCourseData="currentCourseData"-->
    <!--      :msgs="msgs"-->
    <!--      :statusArray="statusArray"-->
    <!--      :UsersToAdd="UsersToAdd"-->
    <!--      :UsersAlreadyAdded="UsersAlreadyAdded"-->
    <!--      :currentUser="currentUser"-->
    <!--      :loading="spinner"-->
    <!--      @refetch-data="refetchData"-->
    <!--      @initilize="ModalData($event)"-->
    <!--      @loading="loadSpinner"-->
    <!--      v-if="isBigModalActive"-->
    <!--    />-->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Status Filter -->
          <b-col
            v-if="!isOverView"
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          />

        </b-row>

      </div>

      <b-table
        ref="refCourseListTable"
        class="position-relative"
        :items="fetchCourses"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
              style="width: 3rem; height: 3rem;"
            />
            <strong class="ml-1">{{ $t('Loading...') }}</strong>
          </div>
        </template>
        <!-- Selected -->
        <template #cell(selected)="data">
          <b-form-checkbox
            v-model="selectedRows"
            :value="data.item.id"
            name="selected-rows"
            @change="onRowSelected"
          />
        </template>

        <!-- Column: ID -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
            <!--            <b-link-->
            <!--              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"-->
            <!--              class="font-weight-bold d-block text-nowrap"-->
            <!--            >-->
            {{ data.item.id }}
            <!--            </b-link>-->
          </b-media>
        </template>

        <!-- Column: Course Name & group -->
        <template #cell(course)="data">
          <span
            v-b-tooltip.hover.top="data.item.status"
            :class="`bullet bullet-sm mr-1 bullet-${resolveCourseStatusVariant(data.item.status)}`"
          />
          <b>{{ data.item.course_name }}</b>
        </template>
        <!-- Column: reg_conf_cnt -->
        <template #cell(reg_conf_cnt)="data">
          <span class="">{{ data.item.reg_conf_cnt }}</span>
        </template>

        <!-- Column: reg_uncnf_cnt -->
        <template #cell(reg_uncnf_cnt)="data">
          <span class="">{{ data.item.reg_uncnf_cnt }}</span>
        </template>

        <!-- Column: reg_cancel_cnt -->
        <template #cell(reg_cancel_cnt)="data">
          <span class="">{{ data.item.reg_cancel_cnt }}</span>
        </template>

        <!-- Column: students_limit -->
        <template #cell(students_limit)="data">
          <span class="">{{ data.item.students_limit }}</span>
        </template>

        <!-- Column: available -->
        <template #cell(available)="data">
          <span class="">{{ data.item.available }}</span>
        </template>

        <!-- Column: Role -->
        <template #cell(student)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.student_name }}</span>
          </div>
        </template>

        <!-- Column: visa -->
        <template #cell(visa)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.visa_needed }}
          </b-badge>
        </template>

        <!-- Column: who pays -->
        <template #cell(who_pays)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.who_pays }}
          </b-badge>
        </template>

        <!-- Column: who pays -->
        <template #cell(payer_confirmed)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.payer_confirmed }}
          </b-badge>
        </template>
        <!-- Column: who pays -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: who pays -->
        <template #cell(attendance)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.attendance }}
          </b-badge>
        </template>
        <template #cell(comment)="data">
          <b-button
            :id="`popover-target-${data.item.id}`"
            variant="flat-primary"
            size="sm"
            class="btn-icon rounded-circle"
            @click="handleChatClick({model:'teacher', id: data.item.id})"
          >
            <feather-icon
              icon="MessageSquareIcon"
              class="align-middle text-body"
              badge-classes="bg-danger"
              badge=""
              size="21"
            />
          </b-button>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item>
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-b-modal="getModal1Id(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-b-modal="getModal2Id(data.item.id)"
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Archive</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-b-modal="getModal4Id(data.item.id)"
            >
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">Hide</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-b-modal="getModal5Id(data.item.id)"
            >
              <feather-icon icon="MoveIcon" />
              <span class="align-middle ml-50">Activate</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-b-modal="getModal3Id(data.item.id)"
            >
              <feather-icon icon="PrinterIcon" />
              <span
                class="align-middle ml-50"
                @click="addSimilar(data.item.id)"
              >Add Similar</span>
            </b-dropdown-item>
            <!-- basic modal delete-->
            <b-modal
              :id="'modal1'+data.item.id"
              title="Delete Course"
              ok-title="Accept"
              @ok="remove(data.item.id)"
            >
              <b-card-text>
                <h5>Are you sure you want to delete  <strong>{{ data.item.course_name }} ?</strong></h5>
              </b-card-text>
            </b-modal>

            <!-- basic modal archive-->
            <b-modal
              :id="'modal2'+data.item.id"
              title="Basic Modal"
              ok-title="Accept"
              @ok="archive(data.item.id)"
            >
              <b-card-text>
                <h5>Are you sure you want to archive <strong>{{ data.item.course_name }} ?</strong></h5>
              </b-card-text>
            </b-modal>

            <!-- basic modal hide-->
            <b-modal
              :id="'modal4'+data.item.id"
              title="Hide course"
              ok-title="Hide"
              centered
              @ok="hideCourse(data.item.id)"
            >
              <b-card-text>
                <h5>Are you sure you want to hide <strong>{{ data.item.course_name }} </strong>?</h5>
              </b-card-text>
            </b-modal>

            <!-- basic modal activate-->
            <b-modal
              :id="'modal5'+data.item.id"
              title="Activate Course"
              ok-title="Accept"
              @ok="activateCourse(data.item.id)"
            >
              <b-card-text>
                <h5>Are you sure you want to activate <strong>{{ data.item.course_name }} ?</strong></h5>
              </b-card-text>
            </b-modal>

            <!-- basic modal -->
            <b-modal
              :id="'moda33'+data.item.id"
              title="Basic Modal"
              ok-title="Accept"
              @ok="archive(data.item.id)"
            >
              <b-card-text>
                <b-form-group
                  label="Template"
                  label-for="template"
                >
                  <b-form-input
                    v-model="query1"
                    autofocus
                    placeholder="Template"
                    @keyup="autoCompleteTemplate(query1)"
                  />
                  <v-select
                    v-if="templates.length"
                    v-model="selectedTemplate"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="templates"
                  />
                </b-form-group>
              </b-card-text>
            </b-modal>

            <!-- basic modal not used-->
            <b-modal
              :id="'modal333'+data.item.id"
              title="Hide course"
              ok-title="Accept"
              @ok="hideCourse(data.item.id)"
            >
              <b-card-text>
                <b-form-group
                  label="Course"
                  label-for="course"
                >
                  <b-form-input
                    v-model="query2"
                    autofocus
                    placeholder="Course"
                    @keyup="autoCompleteCourse(query2)"
                  />
                  <v-select
                    v-if="courses.length"
                    v-model="selectedCourse"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="course_name"
                    :options="courses"
                  />
                </b-form-group>
              </b-card-text>
            </b-modal>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2" />
    </b-card>
    <div
      v-if="isChatActive"
      class="commentschats"
    >
      <feather-icon
        icon="XSquareIcon"
        size="22"
        class="align-middle text-body close-btn"
        @click="isChatActive = false"
      />
      <chat-module
        :model-id="chatModelId.id"
        model-type="course"
      />
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BFormInput, BButton, BTable, BMedia,
  // BLink,
  BBadge, BDropdown, BDropdownItem, BModal, BFormGroup, BFormCheckbox,
  VBTooltip,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ChatModule from '@/views/admin/ChatModule.vue'
import CoursesListAdd from './CoursesListAdd.vue'
// import ModalCourse from '../courses-view/ModalCourse.vue'
// import CoursesListBigModal from './CoursesListBigModal.vue'
import useCoursesGroupListAvailability from './useCoursesGroupListAvailability'
import coursesStoreModule from '../coursesStoreModule'
// import Course from '../../Course.vue'
import CourseListSideFilter from './CourseListFilter.vue'

export default {
  name: 'CoursesListGroupAvailability',
  components: {
    // Course,,,
    CoursesListAdd,
    CourseListSideFilter,
    // ModalCourse,
    // CoursesListBigModal,
    // ModalCourse,
    ChatModule,

    BFormCheckbox,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    // BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    // BPagination,
    BModal,
    BFormGroup,
    BSpinner,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    singleCourseGroup: {
      type: String,
    },
    isOverView: {
      type: Boolean,
    },
  },
  watch: {
    isOverView(newVal) {
      this.isOverViewList = newVal
    },
  },
  mounted() {
    if (typeof this.singleCourseGroup !== 'undefined') {
      this.courseGroup = this.singleCourseGroup
    }
  },
  setup() {
    /* eslint-disable camelcase */
    const getModal1Id = id => `modal1${id}`
    const getModal2Id = id => `modal2${id}`
    const getModal3Id = id => `modal3${id}`
    const getModal4Id = id => `modal4${id}`
    const getModal5Id = id => `modal5${id}`

    const USER_APP_STORE_MODULE_NAME = 'app-courses-group'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, coursesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const onRowSelected = data => {
      console.log(data)
    }

    const multipleRowActions = (ids, action) => {
      console.log(action)
      if (action === 'activate') {
        activateCourse(ids)
      }
      if (action === 'delete') {
        remove(ids)
      }
      if (action === 'archive') {
        archive(ids)
      }
      if (action === 'hide') {
        hideCourse(ids)
      }
    }

    const modalAction = ref('')
    const capitalizeFirstLetter = string => {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const isFilterActive = ref(false)
    const isAddActive = ref(false)
    const isAddsimilarActive = ref(false)
    const currentCourseData = ref(null)
    const isBigModalActive = ref(false)
    const isModalActive = ref(false)
    const singleData = ref({})
    const spinner = ref(false)
    const msgs = ref([])
    const UsersToAdd = ref([])
    const UsersAlreadyAdded = ref([])
    // const ModalData = (id, tab) => {
    //   console.log(tab)
    //   store.dispatch('app-courses/getSCourse', id)
    //     .then(() => {
    //       currentCourseData.value = store.state.coursesStoreModule.singleCourse
    //       const sDays = currentCourseData.value.days
    //       const daysArr = sDays.split(',')
    //       currentCourseData.value.days = daysArr
    //       if (currentCourseData.value.conversation === null) {
    //         msgs.value = []
    //         UsersToAdd.value = []
    //         UsersAlreadyAdded.value = []
    //       } else {
    //         if (typeof currentCourseData.value.conversation !== 'undefined') {
    //           msgs.value = currentCourseData.value.conversation.messages
    //         }
    //         UsersToAdd.value = currentCourseData.value.users_To_Add
    //         UsersAlreadyAdded.value = currentCourseData.value.users_Already_In
    //       }
    //       isBigModalActive.value = true
    //     }).catch(error => console.log(error))
    // }

    const addSimilar = id => {
        store.dispatch('app-courses-group/getSCourse', id)
          .then(() => {
            currentCourseData.value = store.state.coursesStoreModule.singleCourse
          })
          .then(() => {
            isAddsimilarActive.value = true
          })
          .catch(error => console.log(error))
    }

    const loadSpinner = () => {
      // open
      spinner.value = true
      setTimeout(() => {
        spinner.value = false
      }, 1000)
    }
    const roleOptions = [
      { label: 'Admin', value: 'administrator' },
      { label: 'Student', value: 'student' },
      { label: 'Teacher', value: 'teacher' },
      { label: 'Agent', value: 'agent' },
      { label: 'Company', value: 'company' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]
    const query1 = ref('')
    const query2 = ref('')
    const isBusy = ref(false)
    const selectedTemplate = ref({})
    const selectedCourse = ref({})
    const templates = ref([])
    const courses = ref([])
    // Use toast
    const toast = useToast()
    const {
      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      coursesStatusOptions,
      filterDefault,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCourseListTable,
      refetchData,
      number,
      group,
      mastercourse_id,
      teacher_id,
      classroom_id,
      date_start_from,
      date_start_to,
      date_end_from,
      date_end_to,
      fStatus,
      time_of_day,
      // cities,
      masters,
      classrooms,
      teachers,
      statusArray,
      TofDArray,
      currentUser,
      selectedRows,
      courseGroup,
      isOverViewList,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveCourseStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useCoursesGroupListAvailability()
    const regToWaitingList = id => {
      store.dispatch('app-courses-group/regToWaitingList', id)
      .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while confirming registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const formatDateTime = dateTime => {
      if (typeof dateTime !== 'undefined') {
        const timestamp = new Date(dateTime).getTime()
        const todate = new Date(timestamp).getDate()
        const tomonth = new Date(timestamp).getMonth() + 1
        const toyear = new Date(timestamp).getFullYear()

        const today = `0${todate.toString().slice(-2)}`
        const thisMonth = `0${tomonth.toString().slice(-2)}`
        const day = (today.length === 2 ? today : today.substr(1))
        const month = (thisMonth.length === 2 ? thisMonth : thisMonth.substr(1))

        const original_date = `${day}-${month}-${toyear}`
        return original_date
      }
      return ''
    }
    const formatTeacher = teacherName => {
      if (teacherName !== null) {
        const tName = teacherName.split(' ')
        let name = ''
        tName.forEach((s, i) => {
          if (i < 2) {
            name += s.charAt(0).toUpperCase()
          }
        })
        return name
      }
      return ''
    }
    const formatClassroom = teacherName => {
      if (teacherName !== null) {
        const tName = teacherName.split(' ')
        let name = ''
        tName.forEach((s, i) => {
          if (i < 2) {
            name += s.charAt(0).toUpperCase()
          }
        })
        return name
      }
      return ''
    }
    const sendPTest = pId => {
      store.dispatch('app-courses-group/sendPTest', pId)
      .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while confirming registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const hideCourse = id => {
      // const course_id = selectedCourse.value.id
      store.dispatch('app-courses-group/hideCourse', id)
      .then(response => {
        refetchData()
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while hiding course',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const autoCompleteCourse = search => {
      courses.value = []
      store.dispatch('app-courses-group/autoCompleteCourses', search)
      .then(response => { courses.value = response.data })
    }
    const autoCompleteTemplate = search => {
      templates.value = []
      store.dispatch('app-courses-group/autoComplete', search)
      .then(response => {
        templates.value = response.data
      })
    }

    const generateContract = id => {
      store.dispatch('app-courses-group/generateContract', id)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while creating contract registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const activateCourse = id => {
      store.dispatch('app-courses-group/activateCourseAction', id)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while activating course',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const archive = confId => {
      refetchData()
      store.dispatch('app-courses-group/archiveCourse', confId)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Course Has Been Archived',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while archiving course',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }

    const cancel = cancelId => {
      refetchData()
      store.dispatch('app-courses-group/cancelRegistration', cancelId)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Registration Has Been canceled',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while canceling registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const remove = regId => {
      store.dispatch('app-courses-group/deleteCourse', regId)
        .then(() => {
          refetchData()
          toast({
            component: ToastificationContent,
            props: {
              title: 'Course Has Been removed',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while removing course',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const isChatActive = ref(false)
    const chatModelId = ref(null)
    const handleChatClick = data => {
      isChatActive.value = true
      chatModelId.value = data
    }
    /* console.log(masters) */

    return {
      // Modal ids
      getModal1Id,
      getModal2Id,
      getModal3Id,
      getModal4Id,
      getModal5Id,
      // ModalData,
      addSimilar,
      singleData,
      loadSpinner,
      spinner,

      isChatActive,
      chatModelId,
      handleChatClick,

      // Actions
      remove,
      cancel,
      archive,
      activateCourse,
      generateContract,
      hideCourse,
      sendPTest,
      regToWaitingList,

      // autocomplete
      query1,
      query2,
      selectedTemplate,
      selectedCourse,
      templates,
      courses,
      autoCompleteTemplate,
      autoCompleteCourse,
      formatDateTime,
      formatTeacher,
      formatClassroom,
      isBusy,

      // Sidebar
      isFilterActive,
      // Add modal
      isAddActive,
      // infos modal
      isModalActive,
      // biggest modal remplacement
      isBigModalActive,
      isAddsimilarActive,
      currentCourseData,
      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      filterDefault,
      coursesStatusOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCourseListTable,
      refetchData,
      number,
      group,
      mastercourse_id,
      teacher_id,
      classroom_id,
      date_start_from,
      date_start_to,
      date_end_from,
      date_end_to,
      fStatus,
      time_of_day,
      // cities,
      masters,
      classrooms,
      teachers,
      statusArray,
      TofDArray,
      msgs,
      UsersToAdd,
      UsersAlreadyAdded,
      currentUser,
      selectedRows,
      modalAction,
      capitalizeFirstLetter,
      courseGroup,
      isOverViewList,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveCourseStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      onRowSelected,
      multipleRowActions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  /* background-color: #f7f7f7; */
}
.input:focus {
  outline: none;
}
  .courses-filter {
    min-width: 150px;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
  .dark-layout .apexcharts-legend-text{
    color: rgba(255, 255, 255, 0.68) !important;
  }
  .dark-layout .apexcharts-inner path{
    stroke: rgb(0, 0, 0);
    stroke-opacity: 0.1;
  }
</style>
