import { render, staticRenderFns } from "./RequestsList.vue?vue&type=template&id=8847bee2&scoped=true&"
import script from "./RequestsList.vue?vue&type=script&lang=js&"
export * from "./RequestsList.vue?vue&type=script&lang=js&"
import style0 from "./RequestsList.vue?vue&type=style&index=0&id=8847bee2&lang=scss&scoped=true&"
import style1 from "./RequestsList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8847bee2",
  null
  
)

export default component.exports