<template>
  <div>
    <vue-perfect-scrollbar
      v-once
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area-notifications"
      @scroll="handleScroll"
    >
      <div
        height="720"
        width="1280"
      >
        <NotifcationElement
          :notifications="notifications"
          @mark-notification-as-read="markNotificationAsRead"
        />
      </div>
    </vue-perfect-scrollbar>
    <div
      v-if="isBusy"
      class="d-flex justify-content-center mb-1"
    >
      <b-spinner
        variant="primary"
        label="Loading..."
      />
    </div>
    <div
      v-if="notifications.length === 0 && !isBusy"
      class="dropdown-menu-footer text-center"
    >
      {{ $t('No new notifications') }}
    </div>
    <b-modal
      id="survey1-modal"
      title="Help us make your course even better!"
      centered
      no-close-on-backdrop
      hide-footer
      size="xl"
    >
      <student-survey
        :surveyData="surveyData"
        @close-survey-modal="$bvModal.hide('survey1-modal')"
      />
    </b-modal>
    <b-modal
      id="survey2-modal"
      title="You have already filled this survey"
      centered
      no-close-on-backdrop
      ok-only
      ok-title="Close"
    >
      <b-card-text>Thank you for compliting our survey!</b-card-text>
    </b-modal>
  </div>
</template>
<script>
import { BModal, BSpinner } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import NotifcationElement from './NotifcationElement.vue'

export default {
  components: {
    VuePerfectScrollbar,
    NotifcationElement,
    BSpinner,
    BModal,
  },
  data() {
    return {
      surveyData: {},
      isBusy: false,
      notifications: [],
      page: 1,
      perfectScrollbarSettings: { maxScrollbarLength: 60 },
    }
  },
  created() {
    this.getNotifications(1)
  },
  methods: {
    handleScroll(el) {
      if ((el.srcElement.offsetHeight + el.srcElement.scrollTop) >= el.srcElement.scrollHeight) {
        /* eslint-disable no-plusplus */
        this.getNotifications(this.page++)
      }
    },
    getNotifications(page) {
      this.isBusy = true
      this.$http.get('/notifications', { params: { page } })
        .then(response => {
          this.isBusy = false
          response.data.Notifications.forEach(notification => {
            this.notifications.push(
              {
                id: notification.notification_id,
                title: notification.text,
                avatar: notification.avatar,
                isReaded: notification.read_at,
                type: notification.type,
                date: notification.date,
                data: notification.data,
                // subtitle: notification.body,
                // createdAt: notification.created_at,
              },
            )
          })
        })
    },
    markNotificationAsRead(notificationId, type, data) {
      if (type === 'survey1' || type === 'survey2') {
        this.$http.post('survey/check', { survey_id: data.survey.id }).then(res => {
          if (res.data.message === 'pending') {
            this.surveyData = { type, data }
            this.$bvModal.show('survey1-modal')
          } else {
            this.$bvModal.show('survey2-modal')
          }
        })
      }
      this.$http.post(`/notification/mark-as-read/${notificationId}`)
    },
  },
}
</script>
<style lang="scss">
.scroll-area-notifications {
  position: relative;
  margin: auto;
  width: 600px;
  height: 720px;
}
</style>
