<template>
  <div />
</template>
<script>

export default {
  props: {
    roleFilter: {
      type: [String, null],
      default: null,
    },
  },
  watch: {
    $route(to) {
      const role = to.name.split('-')[3]
      this.$nextTick(this.changeRole(role))
    },
  },
  created() {
    const role = this.$route.name.split('-')[3]
    this.$nextTick(this.changeRole(role))
  },
  methods: {
    changeRole(role) {
      this.$emit('update:roleFilter', role)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
