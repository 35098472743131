export default [
  {
    path: '/student',
    name: 'student',
    component: () => import('@/views/student/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Student',
      breadcrumb: [
        {
          text: 'Student',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/courses/courses-list',
    name: 'student-list-courses',
    component: () => import('@/views/student/courses-list/CoursesList.vue'),
  },
  {
    path: '/student/calendar/teacher-calendar',
    name: 'student-calendar',
    component: () => import('@/views/student/student-calendar/Calendar.vue'),
  },
  {
    path: '/student/statistics',
    name: 'student-statistics',
    component: () => import('@/views/student/statistics/Statistics.vue'),
  },
  {
    path: '/student/invoices',
    name: 'student-invoices',
    component: () => import('@/views/student/invoices/InvoicesList.vue'),
  },
]
