<template>

  <div class="container my-2">

    <Signup
      v-if="isSignupActive"
      :is-signup-active.sync="isSignupActive"
      :table-item="tableItem"
      @refetch-data="refetchData"
      :user-data="userData"
    />

    <!-- Filters -->
    <courses-list-filters
      :time-of-day-filter.sync="timeOfDayFilter"
      :classroom-type-filter.sync="classroomTypeFilter"
      :session-type-filter.sync="sessionTypeFilter"
      :month-filter.sync="monthFilter"
      :level-filter.sync="levelFilter"
      :day-time-options="dayTimeOptions"
      :classroom-type-options="classroomTypeOptions"
      :session-type-options="sessionTypeOptions"
      :month-options="monthOptions"
      :level-options="levelOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- Col -->
          <b-col
            cols="12"
            md="6"
          >
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                debounce="500"
              />
<!--              <b-button-->
<!--                variant="primary"-->
<!--                class="d-inline-block mr-1 text-nowrap"-->
<!--                @click="copyLink"-->
<!--              >-->
<!--                <feather-icon icon="LinkIcon" />-->
<!--                Share link-->
<!--              </b-button>-->
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchCourses"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>

        <!-- Column: Name & Date & Hours -->
        <template #cell(date_start)="data">
          <strong class="text-nowrap d-block">{{ data.item.full_course_name }}</strong>
          <span class="text-nowrap">{{ data.item.formated_date_start }} to {{ data.item.formated_date_end }}</span>
          (<span v-if="data.item.hours"> {{ data.item.hours }} h</span>)
        </template>

        <!-- Column: Days & Time -->
        <template #cell(classroom_type)="data">
          <b-badge
            v-if="data.item.classroom_type"
            :variant="data.item.classroom_type === 'classroom' ? 'primary' : 'info'"
            class="text-capitalize"
          >
            {{ data.item.classroom_type }}
          </b-badge>
        </template>

        <!-- Column: Days & Time -->
        <template #cell(days)="data">
          <strong class="d-block" v-if="data.item.days">{{ data.item.days }}</strong>
          <span class="text-nowrap">{{ data.item.time_from }} - {{ data.item.time_to }}</span>
        </template>

        <!-- Column: Price -->
        <template #cell(price)="data">
          <span class="text-nowrap" v-if="data.item.price">{{ data.item.price }} {{ $store.state.appConfig.currency }}</span>
        </template>

        <!-- Column: Action -->
        <template #cell(action)="data">
          <div class="d-flex justify-content-end">
              <!-- v-b-modal="getModal1Id(data.item.id)" -->
            <b-button
              v-if="!$router.currentRoute.meta.layout && !data.item.full"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              size="sm"
              variant="success"
              @click="getSignupData(data.item)"
            >
              {{ ('Sign up') }}
            </b-button>
            <b-button
              v-if="$router.currentRoute.meta.layout === 'full' && !data.item.full"
              :href="`${websiteUrl}/register/?course_id=${data.item.id}`"
              target="_blank"
              size="sm"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="success"
            >
              {{ ('Sign up') }}
            </b-button>
          </div>
          <!-- Sign up modal -->
          <b-modal
            :id="'modal1'+data.item.id"
            :ok-title="'Sign up for '+data.item.price+' NK'"
            size="lg"
            no-close-on-backdrop
            centered
            hide-footer
            @ok="signUp(data.item.id)"
          >
            <template #modal-title>
              <label for="">{{ `Sign up for course: ${data.item.course_name} (${data.item.formated_date_start} to ${data.item.formated_date_end})` }}</label>
              <!-- <h4> Group code : {{ currentCourseData.group }}  <small> Course id: {{ currentCourseData.id }}</small></h4> -->
            </template>
          </b-modal>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable,
  BPagination, BModal, BButton, BSpinner, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import axios from 'axios'
import courseStoreModule from '@/views/courses/courseStoreModule'
import CoursesListFilters from './CoursesListFilters.vue'
import useCoursesList from './useCoursesList'
import Signup from './Signup.vue'

export default {
  components: {
    CoursesListFilters,
    Signup,
    // UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BModal,
    BButton,
    BSpinner,
    BBadge,
    vSelect,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      websiteUrl: process.env.VUE_APP_WEBSITEURL,
      isSignupActive: false,
      tableItem: null,
      userData: {},
    }
  },
  methods: {
    getSignupData(item) {
      this.tableItem = item
      this.isSignupActive = true
    },
    copyLink() {
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer 56676576df926c0c6a32c8bbf124c8d78532297d',
      }
      const payload = {
        group_guid: 'Bmb126xbXHa',
        domain: 'bit.ly',
        long_url: window.location.href,
      }
      axios.post('https://api-ssl.bitly.com/v4/shorten', payload, { headers }).then(res => {
        this.$copyText(res.data.link).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Link copied',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }, () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Can not copy!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      })
    },
  },
  created() {
    this.$http.get('courses/years/months').then(res => {
      this.monthOptions = res.data
      this.monthOptions.unshift({ monthName: 'All', value: null })
    })
    this.$http.get('/account-setting/data').then(res => {
      this.userData.firstname = JSON.parse(localStorage.userData).firstname
      this.userData.lastname = JSON.parse(localStorage.userData).lastname
      this.userData.email = res.data.general.email
      this.userData.dob = res.data.info.dob
      this.userData.phone = res.data.info.phone
    })
  },
  setup() {
    /* eslint-disable camelcase, no-return-assign */
    const getModal1Id = id => `modal1${id}`
    const getModal2Id = id => `modal2${id}`

    const USER_APP_STORE_MODULE_NAME = 'app-course'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, courseStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const dayTimeOptions = [
      { label: 'Anytime', value: 'anytime' },
      { label: 'Daytime', value: 'daytime' },
      { label: 'Evening', value: 'evening' },
    ]

    const sessionTypeOptions = [
      { label: 'All', value: null },
      { label: 'Individual', value: 'individual' },
      { label: 'Standard', value: 'standard' },
      { label: 'Intensive', value: 'intensive' },
      { label: 'Grammar', value: 'grammar' },
      { label: 'Exam', value: 'exam' },
      { label: 'Conversation', value: 'conversation' },
    ]
    const classroomTypeOptions = [
      { label: 'All', value: null },
      { label: 'Online', value: 'online' },
      { label: 'Hybrid', value: 'hybrid' },
      { label: 'Classroom', value: 'classroom' },
    ]

    const monthOptions = [{ monthName: 'All', value: null }]

    const levelOptions = [
      { label: 'All', value: null },
      { label: 'Beginner', value: 'beginner' },
      { label: 'Intermediate', value: 'intermediate' },
      { label: 'Advanced', value: 'advanced' },
    ]

    // Use toast

    const {
      isBusy,
      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Extra Filters
      timeOfDayFilter,
      classroomTypeFilter,
      sessionTypeFilter,
      monthFilter,
      levelFilter,
    } = useCoursesList()

    return {
      isBusy,
      // Modal ids
      getModal1Id,
      getModal2Id,

      // Table params
      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filters options
      dayTimeOptions,
      classroomTypeOptions,
      sessionTypeOptions,
      monthOptions,
      levelOptions,

      // Extra Filters
      timeOfDayFilter,
      classroomTypeFilter,
      sessionTypeFilter,
      monthFilter,
      levelFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
