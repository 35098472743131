<template>
  <div>
    <b-sidebar
      id="add-new-user-sidebar"
      :visible="isAddNewStudentSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-add-new-student-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ $t('Add student to a course') }}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>
        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refRegForm"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Student -->
            <validation-provider
              #default="validationContext"
              name="Student"
              rules="required"
            >
              <b-form-group
                :label="$t('Student')"
                label-for="student"
                :state="getValidationState(validationContext)"
              >
                <div class="d-flex">
                  <div
                    class="mr-50"
                    style="width: 93%"
                  >
                    <v-select
                      v-model="selectedStudent"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="students"
                      :clearable="false"
                      @input="getStudentData(selectedStudent)"
                      @keyup.native="autoCompleteStudent($event.target.value, 'student')"
                      @click.native="autoCompleteStudent('', 'student')"
                    >
                      <span slot="no-options">
                        <div
                          v-if="isTypingStudent"
                          class="text-center d-flex align-items-center justify-content-center"
                        >
                          <b-spinner
                            variant="primary"
                            label="Text Centered"
                          />
                        </div>
                        <div
                          v-if="!isTypingStudent && !students.length"
                          class="text-center d-flex align-items-center justify-content-center"
                        >
                          No matching records found
                        </div>
                      </span>
                    </v-select>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                  <!-- v-b-modal.new-student-modal -->
                  <b-button
                    v-b-tooltip.hover.top="'Add new student'"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon"
                    @click="selectedStudent = {}"
                  >
                    <feather-icon icon="UserPlusIcon" />
                  </b-button>
                </div>
              </b-form-group>
            </validation-provider>
            <label
              v-if="!selectedStudent.id"
              class="mb-1"
            >Fill the new student informations</label>
            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
            >
              <b-form-group
                :label="$t('Name')"
                label-for="name"
              >
                <b-form-input
                  v-model="selectedStudent.name"
                  autofocus
                  :disabled="selectedStudent.id ? true : false"
                  :state="getValidationState(validationContext)"
                  trim
                  :placeholder="$t('Name')"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Email  -->
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                :label="$t('Email')"
                label-for="email"
              >
                <b-form-input
                  v-model="selectedStudent.email"
                  autofocus
                  type="email"
                  :state="getValidationState(validationContext)"
                  trim
                  :placeholder="('Email')"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <small
                  v-if="errors"
                  class="text-danger"
                >{{ errors.email[0] }}</small>
              </b-form-group>
            </validation-provider>
            <!-- Phone  -->
            <validation-provider
              #default="validationContext"
              name="Phone"
            >
              <b-form-group
                :label="$t('Phone')"
                label-for="phone"
              >
                <b-form-input
                  v-model="selectedStudent.phone"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  :placeholder="$t('Phone')"
                  :class="errors.email[0] ? 'is-invalid' : ''"
                  @keyup="errors.email = []"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Birth date -->
            <validation-provider
              #default="validationContext"
              name="Birth date"
            >
              <b-form-group
                label="Birth date"
                label-for="Birth date"
                :state="getValidationState(validationContext)"
              >
                <b-form-input
                  v-model="selectedStudent.dob"
                  placeholder="Date of birth"
                  class="form-control"
                  type="date"
                />
                <!-- <flat-pickr
                v-model="selectedStudent.dob"
                class="form-control"
                placeholder="Birth date"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss',altFormat: 'd-m-Y',altInput: true}"
              /> -->
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Country -->
            <validation-provider
              #default="validationContext"
              name="Country"
            >
              <b-form-group
                label="Country"
                label-for="country"
              >
                <v-select
                  v-model="selectedStudent.country"
                  :reduce="x => x.name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="countryOptions"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Country -->
            <validation-provider
              #default="validationContext"
              name="Country"
            >
              <b-form-group
                label="Nationality"
                label-for="nationality"
              >
                <v-select
                  v-model="selectedStudent.nationality"
                  :reduce="x => x.nationality"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="nationality"
                  :options="countryOptions"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="Postcode - City"
            >
              <b-form-group
                label="Postcode - City"
                label-for="city"
              >
                <div class="d-flex justify-content-start">
                  <b-form-input
                    v-model="selectedStudent.postcode"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Postcode"
                    class="mr-25"
                    style="width: 30%"
                  />
                  <b-form-input
                    v-model="selectedStudent.city"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    :placeholder="$t('City')"
                    style="width: 70%"
                  />
                </div>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Address -->
            <validation-provider
              #default="validationContext"
              name="Address"
            >
              <b-form-group
                :label="'Address'"
                label-for="address"
              >
                <b-form-input
                  v-model="selectedStudent.address"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Address"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Course -->
            <validation-provider
              #default="validationContext"
              name="course"
              rules="required"
            >
              <b-form-group
                :label="$t('Course')"
                label-for="course"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="selectedCourse"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="course_name"
                  :reduce="x => x.id"
                  :options="courses"
                  :clearable="false"
                >
                  <template #selected-option="{ course_name, group }">
                    <div class="d-flex justify-content-between">
                      <b class="text-primary"> {{ course_name }} <small
                        v-if="group"
                        class="text-secondary"
                      >({{ group }})</small></b>
                    </div>
                  </template>
                  <template #option="{ course_name, group, date_start, id }">
                    <div class="d-flex justify-content-between">
                      <div>
                        <b class="d-block">{{ course_name }}</b>
                        <small
                          v-if="group"
                          class="text-secondary"
                        > ID: {{ id }} ({{ group }})</small>
                      </div>
                      <small v-if="date_start">{{ date_start.split(' ')[0].split('-')[2] }}-{{ date_start.split(' ')[0].split('-')[1] }}-{{ date_start.split(' ')[0].split('-')[0] }}</small>
                    </div>
                  </template>
                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Who pays -->
            <validation-provider
              #default="validationContext"
              name="Payer"
              rules="required"
            >
              <b-form-group
                label="Payer"
                label-for="who-pays"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="who_pays"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="payerOptions"
                  :clearable="false"
                  @input="resetPayerInfo()"
                />
                <!-- :disabled="isWhoPaysDisabled" -->
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Payer name -->
            <validation-provider
              v-if="who_pays && (who_pays.code === 'payer' || who_pays.code === 'company')"
              #default="validationContext"
              name="Payer name"
              rules="required"
            >
              <b-form-group
                :label="('Payer name')"
                label-for="payer-name"
              >
                <b-form-input
                  v-model="payer_name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  :placeholder="who_pays.code === 'payer' ? 'Payer name' : 'Company name'"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Payer address -->
            <validation-provider
              v-if="who_pays && (who_pays.code === 'payer' || who_pays.code === 'company')"
              #default="validationContext"
              name="Invoicing address"
              rules="required"
            >
              <b-form-group
                :label="$t('Invoicing address')"
                label-for="invoicing-address"
              >
                <b-form-input
                  v-model="payer_address"
                  autofocus
                  :state="getValidationState(validationContext)"
                  :placeholder="('Invoicing address')"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Company number -->
            <validation-provider
              v-if="who_pays && who_pays.code === 'company'"
              #default="validationContext"
              name="Company org.number"
              rules="required"
            >
              <b-form-group
                :label="$t('Company org.number')"
                label-for="Company-org.number"
              >
                <b-form-input
                  v-model="company_number"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  :placeholder="('Company org.number')"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Responsible -->
            <validation-provider
              v-if="who_pays && who_pays.code === 'company'"
              #default="validationContext"
              name="Responsible / contact person"
              rules="required"
            >
              <b-form-group
                :label="('Responsible / contact person')"
                label-for="Resposible-contact-person"
              >
                <b-form-input
                  v-model="responsible"
                  autofocus
                  :state="getValidationState(validationContext)"
                  :placeholder="('Responsible / contact person')"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Phone number -->
            <validation-provider
              v-if="who_pays && (who_pays.code === 'payer' || who_pays.code === 'company')"
              #default="validationContext"
              name="Phone number"
              rules="required"
            >
              <b-form-group
                :label="('Phone number')"
                label-for="Phone-number"
              >
                <b-form-input
                  v-model="payer_phone"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43"
                  :placeholder="('Phone number')"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Email address -->
            <validation-provider
              v-if="who_pays && (who_pays.code === 'payer' || who_pays.code === 'company')"
              #default="validationContext"
              name="Email address"
              rules="required|email"
            >
              <b-form-group
                :label="('Email address')"
                label-for="Email-address"
              >
                <b-form-input
                  v-model="payer_email"
                  autofocus
                  type="email"
                  :state="getValidationState(validationContext)"
                  trim
                  :placeholder="('Email address')"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Payment method -->
            <validation-provider
              #default="validationContext"
              name="Payment method"
              rules="required"
            >
              <b-form-group
                label="Payment method"
                label-for="payment-method"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="selectedMethod"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :reduce="x => x.id"
                  :options="methods.filter(el => el.id)"
                  :clearable="false"
                />
                <!-- @input="checkMethodWhopays(selectedMethod.name)" -->
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Payment status -->
            <validation-provider
              #default="validationContext"
              name="Payment status"
              rules="required"
            >
              <b-form-group
                label="Payment status"
                label-for="paid"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="paid"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :reduce="x => x.id"
                  :options="paidOptions.filter(el => el.id && !el.filtered)"
                  :clearable="false"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ !selectedStudent.id ? $t('Add') : 'Update' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                {{ $t('Cancel') }}
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
    <b-modal
      id="restore-modal-group"
      :title="restoreMessage"
      cancel-title="Restore old registration"
      ok-title="Create new registration"
      centered
      size="lg"
      @ok="onSubmit(false)"
      @cancel="onSubmit(true)"
    >
      <div class="d-flex align-items-center justify-content-start">
        <feather-icon
          icon="AlertTriangleIcon"
          class="mr-25 mb-50 text-warning"
        />
        <h5
          v-if="restoreMessage"
          class="text-secondary"
        >
          The Student <strong
            v-if="selectedStudent"
            class="text-nowrap"
          >{{ selectedStudent.name }}</strong>'s registration on the course <strong
            v-if="courses.length > 0 && selectedCourse"
            class="text-nowrap"
          >{{ courses.filter(el => el.id === selectedCourse)[0].group }}  </strong> is already deleted.
        </h5>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInvalidFeedback, BButton, BSpinner, VBTooltip,
  BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
// import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import axios from '@axios'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    // flatPickr,
    BSpinner,
    BFormInput,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'isAddNewStudentSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewStudentSidebarActive: {
      type: Boolean,
      required: true,
    },
    courses: {
      type: Array,
      required: true,
    },
    course: {
      type: Object,
      default: null,
    },
    methods: {
      type: Array,
      required: true,
    },
    paidOptions: {
      type: Array,
      required: true,
    },
    payerOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      query1: '',
      query2: '',
      students: [],
      payers: [{ label: 'Student', value: 'student' }, { label: 'Private person', value: 'payer' }, { label: 'Company', value: 'company' }],
      // courses: [],
      isTypingStudent: false,
      isTypingCourse: false,
      isAddNew: false,
      restoreMessage: null,
      countryOptions: [],

    }
  },
  created() {
    this.$http.get('/countries/list?perPage=600').then(res => { this.countryOptions = res.data.rows })
  },
  methods: {
    /* eslint-disable camelcase, no-unused-expressions, no-return-assign */
    autoCompleteStudent(search, role_name) {
      this.isTypingStudent = true
      axios.post('/autocomplete/user', { search, role_name })
      .then(res => {
        this.students = res.data
        this.isTypingStudent = false
      })
    },

    autoCompleteCourse(search) {
      this.isTypingCourse = true
      axios.post('/autocomplete/course', { search })
      .then(res => {
        this.courses = res.data
        this.isTypingCourse = false
      })
    },
  },
  setup(props, { emit }) {
    const toast = useToast()
    const selectedStudent = ref({ name: '', id: null })
    const selectedCourse = ref(null)
    const who_pays = ref(null)
    const payer_name = ref(null)
    const company_number = ref(null)
    const payer_address = ref(null)
    const responsible = ref(null)
    const payer_phone = ref(null)
    const payer_email = ref(null)
    const paid = ref(null)
    const selectedMethod = ref(null)
    if (props.course) {
      selectedCourse.value = { course_name: props.course.mastercourse.code, id: props.course.id, date_start: props.course.date_start }
    }
    // setTimeout(() => {
    //   selectedMethod.value = props.methods.find(el => el.name === 'Card')
    //   paid.value = props.paidOptions.find(el => el.name === 'Paid')
    // }, 5000)
    const regData = {}

    const resetRegistrationData = () => {
      selectedCourse.value = null
      selectedStudent.value = {}
      who_pays.value = null
      payer_name.value = null
      company_number.value = null
      payer_address.value = null
      responsible.value = null
      payer_phone.value = null
      payer_email.value = null
      selectedMethod.value = null
      paid.value = null
      // selectedWhoPays.value = ''
    }
    const resetPayerInfo = () => {
      payer_name.value = null
      company_number.value = null
      payer_address.value = null
      responsible.value = null
      payer_phone.value = null
      payer_email.value = null
    }
    // const isWhoPaysDisabled = ref(false)
    // const checkMethodWhopays = method => {
    //   if (method === 'Invoice') {
    //     who_pays.value = { label: 'Company', value: 'company' }
    //   }
    //   isWhoPaysDisabled.value = method === 'Invoice'
    // }
    const getStudentData = data => {
      data.user_data.forEach(el => {
        if (el.meta_key === 'phone') {
          selectedStudent.value.phone = el.meta_value
        }
        if (el.meta_key === 'dob') {
          selectedStudent.value.dob = el.meta_value
        }
        if (el.meta_key === 'country') {
          selectedStudent.value.country = el.meta_value
        }
        if (el.meta_key === 'postcode') {
          selectedStudent.value.postcode = el.meta_value
        }
        if (el.meta_key === 'address') {
          selectedStudent.value.address = el.meta_value
        }
        if (el.meta_key === 'nationality') {
          selectedStudent.value.nationality = el.meta_value
        }
      })
    }
    const errors = ref({ email: [] })
    // eslint-disable-next-line func-names
    const onSubmit = function (restore) {
      this.$refs.refRegForm.validate().then(success => {
        if (success) {
          regData.student_id = selectedStudent.value.id ? selectedStudent.value.id : null
          regData.name = selectedStudent.value.name
          regData.email = selectedStudent.value.email
          regData.phone = selectedStudent.value.phone
          regData.dob = selectedStudent.value.dob
          regData.country = selectedStudent.value.country
          regData.nationality = selectedStudent.value.nationality
          regData.postcode = selectedStudent.value.postcode
          regData.city = selectedStudent.value.city
          regData.address = selectedStudent.value.address
          regData.course_id = selectedCourse.value.id ? selectedCourse.value.id : selectedCourse.value
          regData.paid_method_id = selectedMethod.value
          regData.payer_id = who_pays.value ? who_pays.value.id : null
          regData.payer_name = payer_name.value
          regData.company_number = company_number.value
          regData.payer_address = payer_address.value
          regData.responsible = responsible.value
          regData.payer_phone = payer_phone.value
          regData.payer_email = payer_email.value
          regData.payment_status = paid.value
          regData.restore = restore
          if (!regData.student_id) { delete regData.student_id }
          store.dispatch('coursesStoreModule/addRegistration', regData)
            .then(res => {
              this.restoreMessage = null
              resetRegistrationData()
              // selectedMethod.value = { name: 'Card', id: 3 }
              toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'success',
                },
              })
              emit('refetch-data')
              emit('update:is-add-new-student-sidebar-active', false)
            }).catch(error => {
              if (error.response.data.data && error.response.data.data.deleted) {
                this.restoreMessage = error.response.data.message
                return this.$bvModal.show('restore-modal-group')
              }
              if (error.response.data.errors !== undefined) {
                errors.value = error.response.data.errors
              }
              return toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
          })
        }
      })
    }
    /* eslint-disable no-shadow */
    const studentName = ref(null)
    const email = ref(null)
    const password = ref(null)
    const phone = ref(null)
    const dob = ref(null)
    const city = ref(null)
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRegistrationData)

    return {
      resetRegistrationData,
      resetPayerInfo,
      // isWhoPaysDisabled,
      // checkMethodWhopays,

      errors,
      onSubmit,
      studentName,
      email,
      password,
      phone,
      dob,
      city,
      selectedStudent,
      selectedCourse,
      getStudentData,
      // selectedWhoPays,
      regData,

      paid,
      who_pays,
      payer_name,
      company_number,
      payer_address,
      responsible,
      payer_phone,
      payer_email,
      selectedMethod,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
// @import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
