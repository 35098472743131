<template>

  <div>

    <!-- <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="dayTimeOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    /> -->

    <!-- Filters -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Filters -->
          <b-col
            cols="12"
            md="6"
            class=""
          >
            <!-- <label class="mr-1">{{ $t('Filters') }}</label> -->
            <courses-list-filters
              :time-of-day-filter.sync="timeOfDayFilter"
              :type-filter.sync="typeFilter"
              :month-filter.sync="monthFilter"
              :level-filter.sync="levelFilter"
              :classroom-type.sync="classroomType"
              :day-time-options="dayTimeOptions"
              :type-options="typeOptions"
              :month-options="monthOptions"
              :level-options="levelOptions"
            />
          </b-col>
          <b-col cols="2" />
          <!-- Search -->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
                debounce="500"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchCourses"
        responsive
        :fields="(timeOfDayFilter === 'declined') ? tableColumns1 : (timeOfDayFilter === 'current' ? tableColumns2 : tableColumns)"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>
        <!-- Column: User -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.id }}
            </b-link>
          </b-media>
        </template>
        <template #cell(course_name)="data">
          <span
            v-b-tooltip.hover.top="data.item.course_name"
            class="d-inline-block text-truncate"
            style="max-width: 180px"
          >
            <div class="media-body">
              <div
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.course_code }}
              </div>
              <small
                class="text-muted"
                style=""
              >{{ data.item.course_group }}</small>
            </div>
          </span>
        </template>
        <template #cell(date)="data">
          {{ data.item.course_date_start }} - {{ data.item.course_date_end }}
        </template>

        <!-- Column: Start -->
        <template #cell(time)="data">
          {{ data.item.course_time_from }} - {{ data.item.course_time_to }}
        </template>

        <!-- Column: Classroom -->
        <template #cell(classroom)="data">
          <b-badge
            pill
            :variant="`light-${resolveClassroomTypeVariant(data.item.classroom_type)}`"
            class="text-capitalize mr-50"
            style="min-width: 75px;"
          >
            {{ data.item.classroom_type }}
          </b-badge>
          <b-badge
            v-if="data.item.classroom"
            pill
            variant="light-secondary"
            class="text-capitalize"
          >
            {{ data.item.classroom }}
          </b-badge>
        </template>

        <!-- Column: Hours -->
        <template #cell(hours)="data">
          {{ data.item.hours }}
        </template>
        <!-- Column: Start -->
        <template #cell(status)="data">
          <b-badge
            v-b-tooltip.hover.top="resolveToolTip(data.item.filter)"
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.filter)}`"
            class="text-capitalize"
          >
            {{ data.item.filter === 'Pending' ? 'awaiting approval' : (data.item.filter === 'Planned' ? 'Available course' : data.item.filter) }}
          </b-badge>
        </template>
        <!-- Column: Reason -->
        <template #cell(reason)="data">
        <div class="row">
          <span
            v-b-popover.hover.top="data.item.reason"
            title="Description"
            class="col-4 text-truncate"
          >
            {{ data.item.reason }}
          </span>
        </div>
      </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            v-if="data.item.filter !== 'Assigned' && data.item.filter !== 'Declined'"
            dropleft
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="data.item.filter === 'Pending'"
              @click="approve(data.item.course_id)"
            >
              <feather-icon icon="CheckCircleIcon" />
              <span class="align-middle ml-50">{{ $t('Accept') }}</span>
            </b-dropdown-item>
            <!-- :disabled="data.item.filter === 'current' || data.item.filter === 'finished' || data.item.filter === 'awaiting'" -->
            <b-dropdown-item
              v-if="data.item.filter === 'Planned'"
              @click="request(data.item.course_id)"
            >
              <feather-icon icon="NavigationIcon" />
              {{ }}
              <span class="align-middle ml-50">{{ $t('Request the course') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.filter === 'Pending'"
              v-b-modal="getModal1Id(data.item.course_id)"
              @click="reason = null"
            >
              <feather-icon icon="XCircleIcon" />
              <span class="align-middle ml-50">{{ ('Decline') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.filter === 'Requested'"
              @click="cancelRequest(data.item.course_id)"
            >
              <feather-icon icon="XCircleIcon" />
              <span class="align-middle ml-50">{{ ('Cancel request') }}</span>
            </b-dropdown-item>
            <b-modal
              :id="'modal1'+data.item.course_id"
              title="Decline reason"
              ok-only
              size="lg"
              centered
              ok-title="Decline the course"
              @ok="cancelRequest(data.item.course_id, reason)"
            >
              <b-form-textarea
                v-model="reason"
                placeholder="Message"
                maxlength="500"
              />
            </b-modal>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="!isBusy"
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BLink, BModal, BFormTextarea,
  BBadge, BDropdown, BDropdownItem, BPagination, BSpinner, VBTooltip, VBPopover,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import courseStoreModule from '@/views/courses/courseStoreModule'
import store from '@/store'
import CoursesListFilters from './CoursesListFilters.vue'
import useCoursesList from './useCoursesList'

// import UserListAddNew from './UserListAddNew.vue'

export default {
  components: {
    CoursesListFilters,
    // UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BModal,
    BFormTextarea,

    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    Ripple,
  },
  setup() {
    /* eslint-disable camelcase */
    const getModal1Id = id => `modal1${id}`
    const { t } = useI18nUtils()

    const USER_APP_STORE_MODULE_NAME = 'app-course'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, courseStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const dayTimeOptions = [
      { label: 'All statuses', value: 'all' },
      { label: t('Awaiting my approval'), value: 'awaiting_approval' },
      { label: t('Requested'), value: 'requested' },
      { label: t('Assigned'), value: 'current' },
      { label: t('Available Course'), value: 'planned' },
      { label: ('Declined'), value: 'declined' },
      { label: 'Expired', value: 'expired' },
    ]

    const typeOptions = [
      { label: 'All', value: 'all' },
      { label: 'Online', value: 'online' },
      { label: 'Individual', value: 'individual' },
      { label: 'Standard', value: 'standard' },
      { label: 'Intensive', value: 'intensive' },
      { label: 'Grammar', value: 'grammar' },
      { label: 'Exam', value: 'exam' },
      { label: 'Conversation', value: 'conversation' },
    ]

    const monthOptions = [
      { label: 'All', value: '' },
      { label: 'Jan', value: '01' },
      { label: 'Feb', value: '02' },
      { label: 'Mar', value: '03' },
      { label: 'Apr', value: '04' },
      { label: 'May', value: '05' },
      { label: 'Jun', value: '06' },
      { label: 'Jul', value: '07' },
      { label: 'Aug', value: '08' },
      { label: 'Sep', value: '09' },
      { label: 'Oct', value: '10' },
      { label: 'Nov', value: '11' },
      { label: 'Dec', value: '12' },
    ]

    const levelOptions = [
      { label: 'All', value: 'all' },
      { label: 'A1', value: 'A1' },
      { label: 'A2', value: 'A2' },
      { label: 'B1', value: 'A2' },
      { label: 'B2', value: 'A2' },
      { label: 'C1', value: 'A2' },
      { label: 'C2', value: 'A2' },
    ]

    const query1 = ref('')
    const query2 = ref('')
    const selectedTemplate = ref({})
    const selectedCourse = ref({})
    const templates = ref([])
    const courses = ref([])
    // Use toast
    const toast = useToast()

    const {
      classroomType,
      isBusy,
      resolveToolTip,
      fetchCourses,
      tableColumns,
      tableColumns1,
      tableColumns2,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveClassroomTypeVariant,

      // Extra Filters
      timeOfDayFilter,
      typeFilter,
      monthFilter,
      levelFilter,
    } = useCoursesList()

    const approve = course_id => {
      store.dispatch('app-course/approveCourse', course_id)
        .then(res => {
          refetchData()
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while approving course',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }

    const cancel = course_id => {
      store.dispatch('app-course/cancelCourse', course_id)
        .then(res => {
          refetchData()
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while canceling course',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const reason = ref(null)
    /* eslint-disable no-shadow */
    const cancelRequest = (course_id, reason) => {
      store.dispatch('app-course/cancelRequest', { course_id, reason })
        .then(res => {
          refetchData()
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while canceling course',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const request = course_id => {
      store.dispatch('app-course/requestCourse', course_id)
        .then(res => {
          refetchData()
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while requesting course',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    return {
      classroomType,
      isBusy,
      resolveToolTip,
      // Modal ids
      getModal1Id,

      // Actions
      approve,
      cancel,
      request,
      cancelRequest,

      // autocomplete
      query1,
      query2,
      selectedTemplate,
      selectedCourse,
      templates,
      courses,
      reason,

      fetchCourses,
      tableColumns,
      tableColumns1,
      tableColumns2,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveClassroomTypeVariant,

      dayTimeOptions,
      typeOptions,
      monthOptions,
      levelOptions,

      // Extra Filters
      timeOfDayFilter,
      typeFilter,
      monthFilter,
      levelFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
