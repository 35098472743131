import axios from '@axios'
import moment from 'moment'
// import fileSaver from 'file-saver'

export default {
  namespaced: true,
  state: {
    courses: {
      rows: [],
      totalRecords: 0,
    },
    Mcourses: [],
    classrooms: [],
    teachers: [],
    cities: [],
    singleCourse: {
      data: {},
    },
    Languages: [],
    Descriptions: [],
    Types: [],
    Groups: [],
    singleMasterCourse: {
      not_belong: [],
      single: {},
    },
  },
  getters: {},
  /* eslint-disable camelcase */
  mutations: {
    setSingleCourses(state, resp) {
      state.singleCourse = resp
    },
    setSingleMCourses(state, resp) {
      state.singleMasterCourse.single = resp.data
      state.singleMasterCourse.not_belong = resp.courses_not_belongs_to_this_master
    },
    setCourses(state, courses) {
      state.courses = courses
    },
    setCoursesAvailability(state, courses) {
      state.coursesAvailability = courses
    },
    deleteRegistration(state, userId) {
      const regData = {
        rows: state.registrations.rows.filter(reg => reg.id !== userId),
        totalRecords: state.registrations.totalRecords - 1,
      }
      state.registrations = regData
    },
    setCities(state, response) {
      state.cities = response.rows
    },
    setClassrooms(state, response) {
      state.classrooms = response.rows
    },
    setTeachers(state, res) {
      state.teachers = res.allTeachers
    },
    setSuggestTeachers(state, res) {
      state.suggestTeachers = res.available
    },
    setSuggestTeachersResp(state, res) {
      state.suggestTeachersResponse = res
    },
    setSuggestClassrooms(state, res) {
      state.suggestClassrooms = res.available
    },
    setSuggestClassroomsResp(state, res) {
      state.suggestClassroomsResponse = res
    },
    setCourseDiscountResp(state, res) {
      state.discountResponse = res
    },
    setMCourses(state, resp) {
      state.Mcourses = resp
    },
    setLanguages(state, Languages) {
      state.Languages = Languages.rows
    },
    setDescriptions(state, Descriptions) {
      state.Descriptions = Descriptions.rows
    },
    setTypes(state, Types) {
      state.Types = Types
    },
    setSingleCourseType(state, courseType) {
      state.singleCourseType = courseType
    },
    setGroups(state, Groups) {
      state.Groups = Groups.rows
    },
  },
  actions: {
    fetchCourses(store, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/course-list/data', { params: queryParams })
          .then(response => {
            store.commit('setCourses', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchCoursesAvailability(store, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/courses/group/data', { params: queryParams })
          .then(response => {
            store.commit('setCoursesAvailability', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    confirmCourse(store, course_id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`courses/confirme/${course_id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    moveGroup(store, course_id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/courses/merge', { course_id })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    approveTeacher(store, { teacher_id, course_id, reason }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/course/approve', { teacher_id, course_id, reason })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getReview(store, { id, filter }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/reviews/list', { params: { course_id: id, filter } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPaymentMethod() {
      return new Promise((resolve, reject) => {
        axios
          .get('/invoice/paid/method/list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getRegFilters() {
      return new Promise((resolve, reject) => {
        axios
          .get('/registration/filter/list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getExcelDoc(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
              '/course/export/excel',
              {
                params: payload,
                responseType: 'blob',
              },
          )
          .then(response => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data], {
              type: 'application/octet-stream',
            }))
            const fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', `courses_report_${moment().format('YYYY-MM-DD')}.xlsx`)
            document.body.appendChild(fileLink)
            fileLink.click()
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    printAttendance(store, course_id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/attendance/print', { course_id })
          .then(response => {
              const linkSource = `data:application/pdf;base64,${response.data.split(',')[0]}`
              const downloadLink = document.createElement('a')
              const fileName = `attendance_sheet_${moment().format('YYYY-MM-DD')}.pdf`
              downloadLink.href = linkSource
              downloadLink.download = fileName
              downloadLink.click()
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getSurveys(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/surveys/get', { course_id: id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveSurvey(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/survey/update', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    approveReview(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/review/${id}/approve`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeReview(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/review/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/get-user/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put('/user/update', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCourse(store, id) {
      let payload = { type: 'single', id }
      if (Array.isArray(id)) {
        payload = { type: 'multiple', id }
      }
      return new Promise((resolve, reject) => {
        axios
          .delete(`/courses/${id}`, payload)
          .then(response => {
            store.commit('deleteUser', id)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    archiveCourse(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/courses/archive/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelRegistration(store, cancelId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/registrations/cancel-reg/${cancelId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    activateCourseAction(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/courses/activate/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    hideCourse(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/courses/hide/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteMasterCourse(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/master-courses/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCoursesType(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/course-types/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    generateCertificate(store, { genId, template_id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/registrations/generate-certificate/${genId}`, { template_id }, { responseType: 'blob' })
          .then(response => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')

            fileLink.href = fileURL
            fileLink.setAttribute('download', `certificate_${moment().format('YYYY-MM-DD')}.pdf`)
            document.body.appendChild(fileLink)

            fileLink.click()
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    generateContract(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/registrations/generate-contract/${id}`, {}, { responseType: 'blob' })
          .then(response => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')

            fileLink.href = fileURL
            fileLink.setAttribute('download', `contract_${moment().format('YYYY-MM-DD')}.pdf`)
            document.body.appendChild(fileLink)

            fileLink.click()
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    sendPTest(store, pId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/registrations/send-p-test/${pId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    regToWaitingList(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/registrations/waiting-list/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    autoComplete(store, search) {
      return new Promise((resolve, reject) => {
        axios
          .post('/templates/autocomplete', search)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    autoCompleteCourses(store, search) {
      return new Promise((resolve, reject) => {
        axios
          .post('/autocomplete/course', search)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    async fetchClassrooms({ commit }, { course_id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/classroom-list/data', {
            params: {
              perPage: 400,
              sortBy: 'code',
              course_id,
              // days,
            },
        })
          .then(response => {
            commit('setClassrooms', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async suggestClassrooms(store, record) {
      return new Promise((resolve, reject) => {
        axios
          .get('/classroom/suggest', {
            params: {
              date_start: `${record.date_start} 00:00:00`,
              date_end: `${record.date_end} 00:00:00`,
              time_from: record.time_from,
              time_to: record.time_to,
              days: record.days,
              max_student: record.students_limit,

            },
          })
          .then(response => {
            // commit('setClassrooms', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async suggestTeacher(store, record) {
      return new Promise((resolve, reject) => {
        axios
          .get('/teacher/suggest', {
            params: {
              date_start: `${record.date_start} 00:00:00`,
              date_end: `${record.date_end} 00:00:00`,
              time_from: record.time_from,
              time_to: record.time_to,
              days: record.days,
              max_student: record.students_limit,
            },
          })
          .then(response => {
            // commit('setClassrooms', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async fetchTeachers({ commit }, { course_id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/teacher/suggest/by-course', { params: { course_id } })
          .then(response => {
            commit('setTeachers', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async fetchTeachersInit() {
      return new Promise((resolve, reject) => {
        axios
          .get('/teacher/list', { params: { perPage: 500 } })
          .then(response => {
            // commit('setTeachers', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async fetchSuggestTeachers({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/teacher/suggest/by-course?course_id=${params.course_id}`) // @todo : change api endpoint & params
          .then(response => {
            commit('setSuggestTeachers', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async setCourseTeacher({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/courses/teacher/update', params)
          .then(response => {
            commit('setSuggestTeachersResp', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async fetchSuggestClassrooms({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/classroom/suggest/by-course?course_id=${params.course_id}`)
          .then(response => {
            commit('setSuggestClassrooms', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async setCourseClassroom({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/courses/classroom/update', params)
          .then(response => {
            commit('setSuggestClassroomsResp', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async setCourseDiscount({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/courses/discount/update', params)
            .then(response => {
              commit('setCourseDiscountResp', response.data)
              resolve(response)
            })
            .catch(error => reject(error))
      })
    },
    getDiscountByCourse(ctx, course_id) {
      return new Promise((resolve, reject) => {
        axios
            .get('/courses/discount/by-course', { params: course_id })
            .then(response => resolve(response.data))
            .catch(error => reject(error))
      })
    },
    /* eslint-disable object-curly-newline */
    async fetchMCourses({ commit }, { group, perPage, search, sortBy, page, sortDesc }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/master-course-list/data', { params: { group, perPage, search, sortBy, page, sortDesc } })
          .then(response => {
            commit('setMCourses', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getSubData(store, course_id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/course/substitutions', { course_id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    async fetchCities({ commit }) {
      await axios.get('/city-list/data')
      .then(response => {
        commit('setCities', response.data)
      })
      .catch(error => {
        throw new Error(error)
      })
    },
    async fetchLanguages({ commit }) {
      await axios.get('/language-list/data')
      .then(response => {
        commit('setLanguages', response.data)
      })
      .catch(error => {
        throw new Error(error)
      })
    },
    async fetchDescriptions({ commit }) {
      await axios.get('/course-desc-list/data', { params: { perPage: 300 } })
      .then(response => {
        commit('setDescriptions', response.data)
      })
      .catch(error => {
        throw new Error(error)
      })
    },
    async fetchTypes({ commit }, params) {
      await axios.get('/course-types-list/data', { params })
      .then(response => {
        commit('setTypes', response.data)
      })
      .catch(error => {
        throw new Error(error)
      })
    },
    addRegistration(ctx, regData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/registrations', regData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addNewStudent(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/user/store', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storeCourse(store, course) {
      return new Promise((resolve, reject) => {
        axios
          .post('/courses', course)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createGroup(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/courses/create/group', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    async getSCourse({ commit }, id) {
      await axios.get(`/courses/${id}`)
        .then(res => {
          commit('setSingleCourses', res.data)
        }).catch(err => {
          throw new Error(err)
        })
    },
    updateCourse(store, { object }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/courses/${object.id}`, {
            mastercourse_id: object.master_course ? object.master_course.id : null,
            status: object.status,
            group: object.group,
            days: object.days,
            date_start: object.date_start,
            date_end: object.date_end,
            time_from: object.time_from.substring(0, 5),
            time_to: object.time_to.substring(0, 5),
            hours: object.hours,
            teachers: object.teachers,
            classroom_id: object.classroom_id,
            price: object.price,
            students_limit: object.students_limit,
            override: object.override,
            attendancecolumns: object.attendancecolumns,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    async newchat(store, object) {
      await axios.post(`/Addmsg/course/${object.id}`, { content: object.content.value })
        .then(() => {
        }).catch(err => {
          throw new Error(err)
        })
    },
    async AddUserToAConvo(store, object) {
      await axios.post('/conversation/add/users', { users: object.users, conversation_id: object.id })
        .then(() => {
        }).catch(err => {
          throw new Error(err)
        })
    },
    async UpdateMsg(store, object) {
      await axios.post(`/feedback/update/${object.msg.id}`, { message: object.msg.content, status: object.msg.status })
        .then(() => {
        }).catch(err => {
          throw new Error(err)
        })
    },
    async DeleteMsg(store, object) {
      await axios.delete(`/feedback/delete/${object.msgId}`)
        .then(() => {
        }).catch(err => {
          throw new Error(err)
        })
    },
    async DeleteUserFromConvo(store, object) {
      await axios.post('/conversation/delete/user', { user_id: object.userId, conversation_id: object.convoId })
        .then(() => {
        }).catch(err => {
          throw new Error(err)
        })
    },
    async AddNewM(store, master) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master-courses', master)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    async NewType(store, type) {
      await axios.post('/course-types', type)
      .then(() => {
      }).catch(err => {
        throw new Error(err)
      })
    },
    async getSCourseType({ commit }, id) {
      await axios.get(`/course-types/${id}`)
        .then(res => {
          commit('setSingleCourseType', res.data.data)
        }).catch(err => {
          throw new Error(err)
        })
    },
    async updateType(store, row) {
      await axios.put(`/course-types/${row.id}`, row)
        .then(() => {
        })
        .catch(error => {
          throw new Error(error)
        })
    },
    async NewGroup(store, group) {
      await axios.post('/course-groups', group)
      .then(() => {
      }).catch(err => {
        throw new Error(err)
      })
    },
    async getSMCourse({ commit }, id) {
      await axios.get(`/master-courses/${id}`)
        .then(res => {
          commit('setSingleMCourses', res.data)
        }).catch(err => {
          throw new Error(err)
        })
    },
    async atachOrDetachCourses(store, object) {
      await axios.post(`attach/course/${object.course_id}`, { option: object.option, master_id: object.master_id })
        .then(() => {
        }).catch(err => {
          throw new Error(err)
        })
    },
    updateMCourse(store, object) {
      return new Promise((resolve, reject) => {
        axios.put(`/master-courses/${object.id}`, object)
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    async deleteM(store, id) {
      await axios.delete(`/master-courses/${id}`)
        .then(() => {
        }).catch(err => {
          throw new Error(err)
        })
    },
    async deleteT(store, id) {
      await axios.delete(`/course-types/${id}`)
        .then(() => {
        }).catch(err => {
          throw new Error(err)
        })
    },
    async deleteG(store, id) {
      await axios.delete(`/course-groups/${id}`)
        .then(() => {
        }).catch(err => {
          throw new Error(err)
        })
    },
    async fetchGroups({ commit }) {
      await axios.get('/course-groups-list/data')
      .then(response => {
        commit('setGroups', response.data)
      })
      .catch(error => {
        throw new Error(error)
      })
    },
    async updateGroup(store, row) {
      await axios.put(`/course-groups/${row.id}`, row)
      .then(() => {
      })
      .catch(error => {
        throw new Error(error)
      })
    },
    postponeCourse(ctx, { date_start, group, new_group_name }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/postpone/course', { group, date_start, new_group_name })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getClassroomsByCourse(ctx, course_id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/classroom/suggest/by-course', { params: course_id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getClassroomsBySession(ctx, course_id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/classroom/suggest/by-session', { params: course_id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getTeachersByCourse(ctx, course_id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/teacher/suggest/by-course', { params: course_id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getTeachersBySession(ctx, sessions) {
      return new Promise((resolve, reject) => {
        axios
          .get('/teacher/suggest/by-session', { params: sessions })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
