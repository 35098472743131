import axios from '@axios'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    registrations: {
      rows: [],
      totalRecords: 0,
    },
  },
  getters: {},
  /* eslint-disable camelcase, no-plusplus, consistent-return, object-curly-newline */
  mutations: {
    setRegistrations(state, users) {
      state.registrations = users
    },
    deleteRegistration(state, userId) {
      const regData = {
        rows: state.registrations.rows.filter(reg => reg.id !== userId),
        totalRecords: state.registrations.totalRecords - 1,
      }
      state.registrations = regData
    },
  },
  actions: {
    fetchUsers(store, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/registration-list/data', { params: queryParams })
          .then(response => {
            store.commit('setRegistrations', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    restoreReg(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/registration/restore', { registration_id: id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    proofParticipation(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/registrations/proof-of-participation/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    multiAction(ctx, { registrations, action, course_id, subject, body, send_email, unselected, isAllSelected }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/registrations/multiselect/action', { registrations, action, course_id, subject, body, send_email, unselected, isAllSelected })
          .then(response => {
            if (action === 'generate-contract' || action === 'generate-certificate') {
              response.data.data.forEach(data => {
                const linkSource = `data:application/pdf;base64,${data.split(',')[0]}`
                const downloadLink = document.createElement('a')
                const fileName = `${action === 'generate-contract' ? 'contract' : 'certificate'}_${moment().format('YYYY-MM-DD')}.pdf`

                downloadLink.href = linkSource
                downloadLink.download = fileName
                downloadLink.click()
              })
            }
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getPlacementTest() {
      return new Promise((resolve, reject) => {
        axios
          .get('/placement-test/list', { params: { perPage: 200 } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRegistration(ctx, regData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/registrations', regData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    studentRegister(ctx, { user_data, companyPaymentMethod, courses }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/create-payment', {
              user_data,
              companyPaymentMethod,
              courses,
              checkouturl: process.env.VUE_APP_CHECKOUT,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addNewStudent(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/user/store', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRegistration(store, regId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/registrations/${regId}`)
          .then(response => {
            store.commit('deleteRegistration', regId)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    confirmRegistration(store, regId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/registrations/confirm-reg/${regId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelRegistration(store, { id, send_email, tobeRefunded }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/registrations/cancel-reg/${id}`, { send_email, tobeRefunded })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getExcelDoc(store, { student, course, date_start, date_end, payer_id, payment_status, paid_method_id, registration_status, search, selected, unselected, selectAll, search_dob }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/registration/export/excel', {
            params: {
              student,
              course,
              date_start,
              date_end,
              payer_id,
              payment_status,
              paid_method_id,
              registration_status,
              search,
              selected,
              unselected,
              selectAll,
              search_dob,
            },
            responseType: 'blob',
          })
          .then(response => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data], {
              type: 'application/octet-stream',
            }))
            const fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', `registrations_report_${moment().format('YYYY-MM-DD')}.xlsx`)
            document.body.appendChild(fileLink)
            fileLink.click()
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    generateCertificate(store, genId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/registrations/generate-certificate/${genId}`)
          .then(response => {
              const linkSource = `data:application/pdf;base64,${response.data.data.split(',')[0]}`
              const downloadLink = document.createElement('a')
              const fileName = `certificate_${moment().format('YYYY-MM-DD')}.pdf`
              downloadLink.href = linkSource
              downloadLink.download = fileName
              downloadLink.click()
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    generateContract(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/registrations/generate-contract/${id}`)
          .then(response => {
              const linkSource = `data:application/pdf;base64,${response.data.data.split(',')[0]}`
              const downloadLink = document.createElement('a')
              const fileName = `contract_${moment().format('YYYY-MM-DD')}.pdf`

              downloadLink.href = linkSource
              downloadLink.download = fileName
              downloadLink.click()
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getPaymentMethod() {
      return new Promise((resolve, reject) => {
        axios
          .get('/invoice/paid/method/list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getRegFilters() {
      return new Promise((resolve, reject) => {
        axios
          .get('/registration/filter/list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    moveStudent(store, { studentId, course_id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/registrations/move-student/${studentId}`, { course_id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendPTest(store, { students, placement_test_id, due_date }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/placement_test/send', { placement_test_id, students, due_date })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    regToWaitingList(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/registrations/waiting-list/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendAdmissionLetter(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/registrations/generate-letter-of-admission/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    autoComplete(store, search) {
      return new Promise((resolve, reject) => {
        axios
          .post('/templates/autocomplete', search)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    autoCompleteCourses(store, search) {
      return new Promise((resolve, reject) => {
        axios
          .post('/autocomplete/course', search)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    async suggestClassrooms(store, record) {
      return new Promise((resolve, reject) => {
        axios
          .get('/classroom/suggest', {
            params: {
              date_start: `${record.date_start} 00:00:00`,
              date_end: `${record.date_end} 00:00:00`,
              time_from: record.time_from,
              time_to: record.time_to,
              days: record.days,
              max_student: record.students_limit,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async suggestTeacher(store, record) {
      return new Promise((resolve, reject) => {
        axios
          .get('/teacher/suggest', {
            params: {
              date_start: `${record.date_start} 00:00:00`,
              date_end: `${record.date_end} 00:00:00`,
              time_from: record.time_from,
              time_to: record.time_to,
              days: record.days,
              max_student: record.students_limit,
            },
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async fetchTeachers() {
      return new Promise((resolve, reject) => {
        axios
          .get('/teacher/list')
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async fetchClassrooms() {
      return new Promise((resolve, reject) => {
        axios
          .get('/classroom-list/data')
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async fetchMCourses(store, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/master-course-list/data?perPage=100', { params })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchCities() {
      return new Promise((resolve, reject) => {
        axios
          .get('/city-list/data')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getSCourse(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/courses/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    refund(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/registrations/payment/refund', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    tobeRefunded(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/registrations/payment/to/be/refunded', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPayers() {
      return new Promise((resolve, reject) => {
        axios.get('/payers/list').then(response => resolve(response)).catch(error => reject(error))
      })
    },
  },
}
