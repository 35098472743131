<template>
  <div>
    <video
      height="180"
      controls
    >
      <source
        :src="content.media"
        type="video/mp4"
      >
      Your browser does not support the video tag.
    </video>
    <div>
      <b-form-input
        v-model="response"
        class="d-inline-flex"
        style="max-width: 20%"
        @keyup="$emit('responseevent', {id: idx, response: response})"
      />
    </div>
  </div>
</template>
<script>
import { BFormInput } from 'bootstrap-vue'

export default {
    name: 'VideoComponent',
    components: { BFormInput },
    props: {
        content: Object,
        idx: Number,
    },
    data() {
        return {
            response: null,
        }
    },
}
</script>

<style scoped>
  audio {
    max-height: 20px;
  }

</style>
