<template>
  <b-card>
    <validation-observer
      #default="{ handleSubmit }"
      ref="form"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(save)"
        @reset.prevent="resetForm"
      >
        <!-- Code -->
        <validation-provider
          #default="validationContext"
          name="Code"
          rules="required"
        >
          <b-form-group
            :formatter="formatter(professionalData.code)"
            :label="$t('Code')"
            label-for="week count"
          >
            <b-form-input
              v-model="professionalData.code"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              :placeholder="$t('Code')"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Email  -->
        <!-- <validation-provider
          #default="validationContext"
          name="Email"
          rules="required"
        >
          <b-form-group
            :label="$t('Email')"
            label-for="email"
          >
            <b-form-input
              v-model="emailValue"
              autofocus
              type="email"
              :state="getValidationState(validationContext)"
              trim
              :placeholder="('Email')"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider> -->
        <!-- Levels checkbox -->
        <b-form-group
          :label="$t('Levels')"
          label-for="levels"
        >
          <div class="demo-inline-spacing">
            <b-form-checkbox
              :checked="selectedLevels.length === professionalData.allLevels.length"
              class="custom-control-primary"
              @change="selectedLevels.length !== professionalData.allLevels.length ? selectedLevels = professionalData.allLevels: selectedLevels = []"
            >
              All levels
            </b-form-checkbox>
            <b-form-checkbox
              v-for="level in professionalData.allLevels"
              :key="level.code"
              v-model="selectedLevels"
              :value="level"
              class="custom-control-primary"
            >
              {{ level.code }}
            </b-form-checkbox>
          </div>
        </b-form-group>
        <!-- ONline? -->
        <b-form-group :label="('Type')">
          <v-select
            v-model="selectedType"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="onlineOptions"
            :clearable="false"
          />
        </b-form-group>

        <!-- Status -->
        <b-form-group :label="('Available')">
          <v-select
            v-model="selectedAvailable"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusOptions"
            :clearable="false"
          />
        </b-form-group>

        <!-- Days -->
        <b-form-group
          :label="$t('Days')"
          label-for="days"
        >
          <div class="demo-inline-spacing">
            <b-form-checkbox
              :checked="professionalData.days.length === 7"
              class="custom-control-primary"
              @change="professionalData.days.length !== 7 ? professionalData.days = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'] : professionalData.days = []"
            >
              All days
            </b-form-checkbox>
            <b-form-checkbox
              v-for="day in dayOptions"
              :key="day.value"
              v-model="professionalData.days"
              :value="day.value"
              class="custom-control-primary mr-1"
            >
              {{ day.label }}
            </b-form-checkbox>
          </div>
        </b-form-group>

        <b-form-group
          v-if="professionalData.days.includes('sa')"
          label="Saturday time"
          label-for="saturday-time"
          class="mt-2"
        >
          <b-row>
            <b-col cols="5">
              <flat-pickr
                v-model="saturdayTime[0].start"
                placeholder="From"
                class="form-control"
                :config="{ enableTime: true, noCalendar: true, time_24hr: true, minTime: '07:00' }"
              />
            </b-col>
            <b-col cols="5">
              <flat-pickr
                v-model="saturdayTime[0].end"
                placeholder="To"
                class="form-control"
                :config="{ enableTime: true, noCalendar: true, time_24hr: true, minTime: saturdayTo }"
              />
            </b-col>
          </b-row>
        </b-form-group>

        <!-- Time frames -->
        <b-form-group
          :label="$t('Time')"
          label-for="time"
        >
          <teacher-time-frame
            :set-default-hieght="true"
            :items-prop="professionalData.time_frames.filter(el => el.days === 'all')"
            @bind-time-frames="val => professionalData.time_frames = val"
          />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            {{ $t('Save changes') }}
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import {
  BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { required, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TeacherTimeFrame from '@/views/admin/teachers-list/TeacherTimeFrame.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    TeacherTimeFrame,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    flatPickr,
    vSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    professionalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      email,
      statusOptions: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
      onlineOptions: [{ label: 'Online', value: 'yes' }, { label: 'Classroom', value: 'no' }, { label: 'Hybrid', value: 'hybrid' }],
      selectedLevels: [],
      selectedType: {},
      selectedAvailable: {},
      saturdayTime: [{
        start: '',
        end: '',
        days: 'sa',
      }],
      dayOptions: [
        { label: 'Mo', value: 'mo' },
        { label: 'Tu', value: 'tu' },
        { label: 'We', value: 'we' },
        { label: 'Th', value: 'th' },
        { label: 'Fr', value: 'fr' },
        { label: 'Sa', value: 'sa' },
        { label: 'Su', value: 'su' },
      ],

    }
  },
  mounted() {
    this.selectedLevels = this.professionalData.allLevels ? this.professionalData.allLevels.filter(level => level.selected) : []
    if (this.professionalData.online) {
        if (this.professionalData.online === 'no') this.selectedType = { label: 'Classroom', value: 'no' }
        if (this.professionalData.online === 'yes') this.selectedType = { label: 'Online', value: 'yes' }
        if (this.professionalData.online === 'hybrid') this.selectedType = { label: 'Hybrid', value: 'hybrid' }
    }
    this.selectedAvailable = this.professionalData.available === 'yes' ? { label: 'Yes', value: 'yes' } : { label: 'No', value: 'no' }
    if (this.professionalData.time_frames.length > 0 && this.professionalData.time_frames.find(el => el.days === 'sa')) {
        this.saturdayTime = [{
          start: this.professionalData.time_frames.find(el => el.days === 'sa').start,
          end: this.professionalData.time_frames.find(el => el.days === 'sa').end,
          days: 'sa',
        }]
      }
  },
  methods: {
    formatter(input) { this.professionalData.code = input.toUpperCase() },
    save() {
      console.log(this.selectedLevels)
      if (this.$refs.form.flags.valid) {
        const teacherData = {
          code: this.professionalData.code,
          email: this.professionalData.emailValue,
          levels: this.selectedLevels.map(el => ({ code: el.code, id: el.id, selected: true })),
          time_frames: this.professionalData.days.includes('sa') ? this.professionalData.time_frames.concat(this.saturdayTime) : this.professionalData.time_frames.value,
          // time: this.professionalData.time.join(','),
          days: this.professionalData.days.join(','),
          online: this.selectedType ? this.selectedType.value : null,
          available: this.selectedAvailable ? this.selectedAvailable.value : null,
          // comments: comments.value,
        }
        this.$http.post('/account-setting/info', teacherData)
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                text: response.data.message,
                icon: 'CheckIcon',
              },
            })
          }).catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                text: error.response.data.message,
                icon: 'AlertTriangleIcon',
              },
            })
            this.$refs.generalData.setErrors(error.response.data.errors)
          })
      }
    },
  },
  /* eslint-disable camelcase */
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {})

    return {
      // Select
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
