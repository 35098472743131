<template>
  <b-card>
    <b-card-title class="mb-1">
      Teachers Work Time frames
    </b-card-title>
    <b-card-sub-title class="mb-2">
      Working periods during the day
    </b-card-sub-title>

    <vue-apex-charts
      type="donut"
      height="350"
      :options="donutChart.chartOptions"
      :series="donutChart.series"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    donutChart: {
      type: Object,
      required: true,
    },
  },
}
</script>
