<template>
  <div class="sidebar-left">
    <div class="sidebar">

      <!-- Logged In User Profile Sidebar -->
      <user-profile-sidebar
        :shall-show-user-profile-sidebar="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        @close-sidebar="$emit('update:shall-show-user-profile-sidebar', false)"
      />

      <!-- Sidebar Content -->
      <div
        class="sidebar-content"
        :class="{'show': mqShallShowLeftSidebar}"
      >

        <!-- Sidebar close icon -->
        <span class="sidebar-close-icon">
          <feather-icon
            icon="XIcon"
            size="16"
            @click="$emit('update:mq-shall-show-left-sidebar', false)"
          />
        </span>

        <!-- Header -->
        <div class="chat-fixed-search">
          <div class="d-flex align-items-center w-100">
            <div class="sidebar-profile-toggle">
              <b-avatar
                size="42"
                class="cursor-pointer badge-minimal avatar-border-2"
                :src="userData.avatar"
                variant="transparent"
                badge
                badge-variant="success"
              />
            </div>
            <!-- Search -->
            <b-input-group class="input-group-merge ml-1 w-100 round">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search..."
              />
            </b-input-group>
            <!-- Create conversation -->
            <b-button
              v-if="userData.role !== 'student' && $route.path !== '/apps/support_center'"
              v-b-modal.modal-default
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="btn-icon rounded-circle ml-1"
              @click="selected = []"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
            <!-- <b-button
              v-if="userData.role === 'student' "
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="btn-icon rounded-circle ml-1"
              @click="$emit('create-conversation', userData.id, null, 'support_center')"
            >
              <feather-icon icon="PlusIcon" />
            </b-button> -->
            <b-modal
              id="modal-default"
              no-close-on-backdrop
              centered
              title="Start a conversation"
              ok-only
              ok-title="Start"
              @ok="$emit('create-conversation', userData.id, selected)"
            >
              <b-form-group>
                <v-select
                  v-model="selected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="usersOptions"
                  multiple
                  @keyup.native="autocomplete($event.target.value)"
                  @click.native="autocomplete($event.target.value)"
                >
                  <span slot="no-options">
                    <div
                      v-if="isTyping"
                      class="text-center d-flex align-items-center justify-content-center"
                    >
                      <b-spinner
                        variant="primary"
                        label="Text Centered"
                      />
                    </div>
                    <div
                      v-if="!isTyping && !usersOptions.length"
                      class="text-center d-flex align-items-center justify-content-center"
                    >
                      No matching records found
                    </div>
                  </span>
                </v-select>
              </b-form-group>
            </b-modal>
          </div>
        </div>
        <div
          v-if="!isLoaded"
          class="text-center mt-2 d-flex align-items-center justify-content-center"
        >
          <b-spinner
            variant="primary"
            label="Text Centered"
            style="width: 3rem; height: 3rem;"
          />
          <strong class="ml-1">Loading...</strong>
        </div>
        <!-- ScrollArea: Chat & Contacts -->
        <vue-perfect-scrollbar
          v-if="isLoaded"
          :settings="perfectScrollbarSettings"
          class="chat-user-list-wrapper list-group scroll-area"
        >

          <!-- Chats Title -->
          <h4 class="chat-list-title">
            Chats
          </h4>

          <!-- Chats -->
          <ul class="chat-users-list chat-list media-list">
            <chat-contact
              v-for="contact in filteredChatsContacts"
              :key="contact.id+String(contact.name)"
              :user="contact"
              tag="li"
              :class="{'active': activeChatContactId === contact.id}"
              is-chat-contact
              @click="$emit('open-chat', contact.id)"
            />
          </ul>
          <!-- v-if="chatsContacts.length === 10" -->
          <ul
            v-if="shallShowMore"
            class="text-center mt-1 mb-1"
          >
            <b-button
              v-if="filteredChatsContacts.length >= 10"
              v-b-tooltip.hover.top="'Load more conversations'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon rounded-circle"
              @click="$emit('show-more', page++)"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
          </ul>
          <!-- Contacts Title -->
          <!-- <h4 class="chat-list-title">
            Contacts
          </h4> -->

          <!-- Contacts -->
          <!-- <ul class="chat-users-list contact-list media-list">
            <chat-contact
              v-for="contact in filteredContacts"
              :key="contact.id"
              :user="contact"
              tag="li"
              @click="$emit('open-chat', contact.id)"
            />
          </ul> -->
        </vue-perfect-scrollbar>
      </div>

    </div>
  </div>
</template>

<script>
import {
  BAvatar, BInputGroup, BInputGroupPrepend, BFormInput, BButton, BFormGroup, BSpinner, VBTooltip,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ref, computed } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import ChatContact from './ChatContact.vue'
import UserProfileSidebar from './UserProfileSidebar.vue'

export default {
  components: {
    vSelect,
    // BSV
    BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BFormGroup,
    BSpinner,

    // 3rd party
    VuePerfectScrollbar,

    // SFC
    ChatContact,
    UserProfileSidebar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    chatsContacts: {
      type: Array,
      required: true,
      default: () => [],
    },
    shallShowMore: {
      type: Boolean,
      default: true,
    },
    // contacts: {
    //   type: Array,
    //   required: true,
    // },
    shallShowUserProfileSidebar: {
      type: Boolean,
    },
    isLoaded: {
      type: Boolean,
    },
    profileUserData: {
      type: Object,
      required: true,
    },
    profileUserMinimalData: {
      type: Object,
      required: true,
    },
    activeChatContactId: {
      type: Number,
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      page: 2,
      query: '',
      selected: [],
      dir: 'ltr',
      userData: {},
      usersOptions: [],
      isTyping: false,
    }
  },
  mounted() {
    const userData = getUserData()
    this.userData = userData
  },
  methods: {
    autocomplete(query) {
      this.isTyping = true
      /* eslint-disable no-return-assign */
      if (this.userData.role === 'administrator') {
        return this.$http.post('/autocomplete/user', { search: query }).then(res => {
          this.isTyping = false
          this.usersOptions = res.data
        })
      }
      return this.$http.get(`/${this.userData.role}/autocomplete`, { params: { search: query } }).then(res => {
        this.isTyping = false
        this.usersOptions = res.data.data
      })
    },
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const resolveChatContact = userId => props.contacts.find(contact => contact.id === userId)

    // Search Query
    const searchQuery = ref('')

    const searchFilterFunction = contact => contact.name.toLowerCase().includes(searchQuery.value.toLowerCase())
    const filteredChatsContacts = computed(() => props.chatsContacts.filter(searchFilterFunction))

    return {
      // Search Query
      searchQuery,
      filteredChatsContacts,

      // UI
      resolveChatContact,
      perfectScrollbarSettings,
    }
  },
}
</script>
