<template>
  <b-modal
    id="modal-center"
    :visible="isAddActive || isAddGroupActive"
    footer-class="d-flex justify-content-between"
    size="lg"
    static
    scrollable
    hide-footer
    @change="(val) => updateVisibility(val)"
  >
    <template #modal-title>
      <h4>{{ courseAction === 'edit' ? 'Edit course' : isAddGroupActive ? 'Add New Group' : 'Add New Course' }}</h4>
    </template>

    <validation-observer
      ref="form"
      v-slot="{ handleSubmit }"
    >
      <b-form :key="errorMessageRefreshKey" @submit.prevent="handleSubmit(create)">
        <b-container>
          <b-row v-if="!isAddGroupActive">
            <b-col>
              <!-- Level -->
              <validation-provider
                #default="validationContext"
                name="Level"
              >
                <b-form-group
                  label="Level"
                  label-for="level"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    id="level"
                    v-model="record.level"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="['all', 'beginner', 'intermediate', 'advanced']"
                    :reduce="x => x"
                    :clearable="false"
                    label="name"
                    placeholder="choose a level"
                    @input="filterLevel(record.level)"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <!-- Mastercourse -->
              <validation-provider
                #default="validationContext"
                name="Mastercourse"
                rules="required"
              >
                <b-form-group
                  label="Mastercourse"
                  label-for="mastercourse"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    id="mastercourse"
                    v-model="record.master_course"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="filteredMastercourses"
                    :reduce="x => x"
                    label="code"
                    placeholder="choose course Master"
                    @input="generateGroupe()"
                  >
                    <template #option="{ name, code }">
                      <span class="d-block"> {{ code }}</span>
                      <small class="text-muted">{{ name }}</small>
                    </template>
                  </v-select>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <small
                    v-if="errors && errors.mastercourse_id"
                    class="text-danger"
                  >{{ errors.mastercourse_id[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col>
              <!-- Days -->
              <validation-provider
                #default="validationContext"
                name="Days"
                rules="required"
              >
                <b-form-group
                  label="Days"
                  label-for="days"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-checkbox-group
                    id="days"
                    v-model="selectedDays"
                    :options="days"
                    @change="generateGroupe()"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <small
                    v-if="errors && errors.days"
                    class="text-danger"
                  >{{ errors.days[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <!-- Date start -->
              <validation-provider
                #default="validationContext"
                name="Start date"
                rules="required"
              >
                <b-form-group
                  label="Start date"
                  label-for="dstart"
                  :state="getValidationState(validationContext)"
                >
                  <!-- <flat-pickr
                    id="dstart"
                    v-model="record.date_start"
                    placeholder="Date start of the course"
                    :config="{ altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 } }"
                    class="form-control input"
                    @input="generateGroupe()"
                  /> -->
                  <b-form-datepicker
                    id="dstart"
                    v-model="record.date_start"
                    :date-disabled-fn="dateDisabled"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en-UK"
                    :start-weekday="1"
                    @context="generateGroupe()"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <small
                    v-if="errors && errors.date_start"
                    class="text-danger"
                  >{{ errors.date_start[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6" v-if="!isAddGroupActive">
              <!-- Date end -->
              <validation-provider
                #default="validationContext"
                name="End date"
                rules="required"
              >
                <b-form-group
                  label="End date"
                  label-for="dend"
                  :state="getValidationState(validationContext)"
                >
                  <!-- <flat-pickr
                    id="dend"
                    v-model="record.date_end"
                    placeholder="Date end of the course"
                    :config="{ altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 } }"
                    class="form-control input"
                    @input="errors.date_end = []"
                  /> -->
                  <b-form-datepicker
                    id="dend"
                    v-model="record.date_end"
                    :date-disabled-fn="dateDisabled"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en-UK"
                    :start-weekday="1"
                    @context="errors.date_end = []"
                  />
                  <small
                    v-if="errors && errors.date_end"
                    class="text-danger"
                  >{{ errors.date_end[0] }}</small>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col :cols="isAddGroupActive ? '3' : '6'">
              <!-- Start time -->
              <validation-provider
                #default="validationContext"
                name="Start time"
                rules="required"
              >
                <b-form-group
                  label="Start time"
                  label-for="st"
                  :state="getValidationState(validationContext)"
                >
                  <flat-pickr
                    id="st"
                    v-model="record.time_from"
                    placeholder="When it starts"
                    class="form-control input"
                    :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
                    @input="generateGroupe()"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <small
                    v-if="errors && errors.time_from"
                    class="text-danger"
                  >{{ errors.time_from[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col :cols="isAddGroupActive ? '3' : '6'">
              <!-- End time -->
              <validation-provider
                #default="validationContext"
                name="End time"
                rules="required"
              >
                <b-form-group
                  label="End time"
                  label-for="et"
                  :state="getValidationState(validationContext)"
                >
                  <flat-pickr
                    id="et"
                    v-model="record.time_to"
                    placeholder="When it ends"
                    class="form-control input"
                    :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
                    @input="errors = {}"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <small
                    :key="errorMessageRefreshKey"
                    v-if="errors && errors.time_to"
                    class="text-danger"
                  >{{ errors.time_to[0] }} {{ errorMessageRefreshKey }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row v-if="!isAddGroupActive">
            <b-col>
              <!-- Sessions -->
              <validation-provider
                #default="validationContext"
                name="Sessions"
                rules="required"
              >
                <b-form-group
                  label="Sessions"
                  label-for="sessions"
                >
                  <b-form-input
                    id="sessions"
                    v-model="record.attendancecolumns"
                    type="number"
                    :state="getValidationState(validationContext)"
                    placeholder="Sessions Number (attendance columns)"
                    class="input"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <small
                    v-if="errors && errors.attendancecolumns"
                    class="text-danger"
                  >{{ errors.attendancecolumns[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <!-- Event name -->
              <validation-provider
                #default="validationContext"
                name="Hours"
                rules="required"
              >
                <b-form-group
                  label="Hours"
                  label-for="hours"
                >
                  <b-form-input
                    id="hours"
                    v-model="record.hours"
                    placeholder="Total hours"
                    class="input"
                    :state="getValidationState(validationContext)"
                    type="number"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <small
                    v-if="errors && errors.hours"
                    class="text-danger"
                  >{{ errors.hours[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <hr>
          <!-- <b-row v-if="courseAction === 'edit' || isAddGroupActive">
            <b-col>
              <validation-provider
                #default="validationContext"
                name="Classroom"
              >
                <b-form-group
                  label="Classroom"
                  label-for="classroom"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    id="classroom"
                    v-model="record.classroom_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="classrooms"
                    :reduce="x => x.id"
                    label="code"
                    placeholder="choose course classroom"
                    @input="(val) => record.students_limit = val"
                  >
                    <template #option="{ code, max_students }">
                      <div class="row">
                        <b class="col-3">{{ code }}</b>
                        <span v-if="max_students" class="col">Stuednts limit: <b>{{ max_students }}</b></span>
                      </div>
                    </template>
                  </v-select>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row> -->
          <!-- <b-row v-if="courseAction === 'edit' || isAddGroupActive">
            <b-col>
              <validation-provider
                #default="validationContext"
                name="Teacher"
              >
                <b-form-group
                  label="Teacher"
                  label-for="teacher"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    id="teacher"
                    v-model="record.teachers"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    multiple
                    class="selectedTeachersBg"
                    :options="teachers"
                    :reduce="x => x.id"
                    placeholder="choose course teacher"
                  >
                    <template #option="{ name, code }">
                      <span class="d-block"> {{ code }}</span>
                      <small class="text-muted">{{ name }}</small>
                    </template>
                    <template #selected-option="{ name, by }">
                      <div v-if="by !== 'current'" class="flex">
                        <feather-icon
                          :icon="by === 'teacher' ? 'StarIcon' : 'ClockIcon'"
                          size="16"
                          :class="by === 'teacher' ? 'text-indigo' : 'text-warning'"
                          class="align-middle mr-50"
                        />
                        <span :class="by === 'teacher' ? 'pending-by-teacher' : 'pending-by-admin'" class="mt-25">{{ name }}</span>
                      </div>
                      <div v-if="by === 'current'" class="flex">
                        <feather-icon
                          icon="CheckCircleIcon"
                          size="16"
                          class="align-middle mr-50 text-success"
                        />
                        <span class="text-success">{{ name }}</span>
                      </div>
                    </template>
                  </v-select>
                  <div class="d-flex ml-1">
                    <div class="d-flex align-items-center mr-1">
                      <feather-icon
                        icon="ClockIcon"
                        size="16"
                        class="align-middle mr-50 text-warning"
                      />
                      <label class="text-warning mt-25">Suggested by administration</label>
                    </div>
                    <div class="d-flex align-items-center mr-1">
                      <feather-icon
                        icon="StarIcon"
                        size="16"
                        class="align-middle mr-50 text-indigo"
                      />
                      <label class="text-indigo mt-25">Suggested by teacher</label>
                    </div>
                    <div class="d-flex align-items-center mr-1">
                      <feather-icon
                        icon="CheckCircleIcon"
                        size="16"
                        class="align-middle mr-50 text-success"
                      />
                      <label class="text-success mt-25">Current teacher</label>
                    </div>
                  </div>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row> -->
          <b-row>
            <b-col>
              <!-- Students limit -->
              <validation-provider
                #default="validationContext"
                name="Students limit"
                rules="required"
              >
                <b-form-group
                  label="Students limit"
                  label-for="studentslimit"
                >
                  <b-form-input
                    id="studentslimit"
                    v-model="record.students_limit"
                    type="number"
                    :state="getValidationState(validationContext)"
                    placeholder="Students limit"
                    class="input"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <small
                    v-if="errors && errors.students_limit"
                    class="text-danger"
                  >{{ errors.students_limit[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="!isAddGroupActive">
              <!-- Price -->
              <validation-provider
                #default="validationContext"
                name="Price"
                rules="required"
              >
                <b-form-group
                  label="Price"
                  label-for="price"
                >
                  <b-form-input
                    id="price"
                    v-model="record.price"
                    type="number"
                    :state="getValidationState(validationContext)"
                    placeholder="Course price"
                    class="input"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <small
                    v-if="errors && errors.price"
                    class="text-danger"
                  >{{ errors.price[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <!-- Status -->
              <validation-provider
                #default="validationContext"
                name="Status"
                rules="required"
              >
                <b-form-group
                  label="Status"
                  label-for="status"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    id="status"
                    v-model="record.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="statusArray.filter(el => el.value === 'active' || el.value === 'hidden')"
                    :reduce="x => x.value"
                    :clearable="false"
                    placeholder="Status"
                    @input="generateGroupe()"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <small
                    v-if="errors && errors.status"
                    class="text-danger"
                  >{{ errors.status[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <!-- Group -->
              <validation-provider
                #default="validationContext"
                name="Group"
                rules="required"
              >
                <b-form-group>
                  <b-form-input
                    id="group"
                    v-model="record.group"
                    :state="getValidationState(validationContext)"
                    placeholder="Course group"
                    class="input"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <small
                    v-if="errors && errors.group"
                    class="text-danger"
                  >{{ errors.group[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <hr>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
          >
            {{ courseAction === 'edit' ? 'Update' : 'Create' }}
          </b-button>
        </b-container>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow, BCol, BFormCheckboxGroup, BContainer, BForm, BFormGroup, BModal, BFormInput, BButton, BFormInvalidFeedback,
  BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
// import { onMounted } from 'vue'
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BFormCheckboxGroup,
    BContainer,
    BForm,
    BFormGroup,
    BModal,
    BFormInput,
    BButton,
    BFormDatepicker,
    vSelect,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    isAddActive: {
      type: Boolean,
      required: true,
    },
    isAddGroupActive: {
      type: Boolean,
      required: true,
    },
    courseAction: {
      type: String,
      default: 'add',
    },
    masters: {
      type: Array,
      required: true,
    },
    // cities: {
    //   type: Array,
    //   required: true,
    // },
    classrooms: {
      type: Array,
      required: true,
    },
    teachers: {
      type: Array,
      required: true,
    },
    statusArray: {
      type: Array,
      required: true,
    },
    TofDArray: {
      type: Array,
      required: true,
    },
    currentCourseData: {
      type: Object,
    },
    groupInfos: {
      type: Object,
    },
    fromModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      filteredMastercourses: [],
    }
  },
  mounted() {
    if (this.isAddGroupActive) this.getLevels()
    if (!this.isAddGroupActive) this.filteredMastercourses = this.masters
    if (this.fromModal) {
      this.selectedDays = (typeof this.$store.state.coursesStoreModule.singleCourse.days !== 'undefined') ? this.$store.state.coursesStoreModule.singleCourse.days.toString().split(',') : []
      this.record.group = (typeof this.$store.state.coursesStoreModule.singleCourse.data.group !== 'undefined') ? this.$store.state.coursesStoreModule.singleCourse.data.group : ''
    }
    if (this.currentCourseData) {
      this.record = this.currentCourseData.data
      this.record.teachers = this.currentCourseData.currentTeacher ? [this.currentCourseData.currentTeacher] : this.currentCourseData.pendingTeachers
      this.record.price = Math.round(this.record.price)
      this.selectedDays = this.currentCourseData.data.days.toString().split(',')
      this.isAddSimilar = true
    }
  },
  methods: {
    getLevels() {
      this.$http.get('get/whole/levels').then(res => {
        this.filteredMastercourses = res.data
      }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
            title: error.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    updateVisibility(val) {
      this.$emit('update:isAddActive', val)
      this.$emit('update:isAddGroupActive', val)
    },
    dateDisabled(ymd, date) {
      // disable weekdays
      const weekday = date.getDay()
      return weekday === (!this.selectedDays.includes('mo') ? 1 : null)
          || weekday === (!this.selectedDays.includes('tu') ? 2 : null)
          || weekday === (!this.selectedDays.includes('we') ? 3 : null)
          || weekday === (!this.selectedDays.includes('th') ? 4 : null)
          || weekday === (!this.selectedDays.includes('fr') ? 5 : null)
          || weekday === (!this.selectedDays.includes('sa') ? 6 : null)
          || weekday === (!this.selectedDays.includes('su') ? 0 : null)
    },
    filterLevel(level) {
      this.filteredMastercourses = this.masters
      if (level === 'all') return
      this.filteredMastercourses = this.masters.filter(mCourse => mCourse.level === level)
    },
  },
  setup(props, { emit }) {
    const toast = useToast()
    const record = ref({
      group: null,
      master_course: null,
      classroom_id: null,
      date_start: null,
      date_end: null,
      time_from: null,
      time_to: null,
      days: null,
      hours: null,
      teachers: [],
      price: null,
      status: null,
      approved: 'pending',
      attendancecolumns: null,
      students_limit: null,
      level: 'all',
    })
    const isAddSimilar = ref(false)
    const selectedDays = ref([])
    const days = ref([
      { text: 'Mon', value: 'mo' },
      { text: 'Tue', value: 'tu' },
      { text: 'Wed', value: 'we' },
      { text: 'Thu', value: 'th' },
      { text: 'Fri', value: 'fr' },
      { text: 'Sat', value: 'sa' },
      { text: 'Sun', value: 'su' },
    ])
    const errors = ref({})
    const errorMessageRefreshKey = ref(0)
    const handleBackendValidatiob = err => {
      // console.log(!!err)
      const fields = [
        'time_from', 'time_to', 'date_start', 'date_end', 'group', 'mastercourse_id',
        'days', 'students_limit', 'status', 'hours', 'price', 'attendancecolumns',
      ]
      if (err) {
        fields.forEach(field => {
          errors.value[field] = err[field] || []
        })
        setTimeout(() => {
          errorMessageRefreshKey.value += 1
        }, 100)
      }
    }
    const generateGroupe = function () {
      const payload = {
        days: selectedDays.value.length > 0 ? selectedDays.value.join(',') : null,
        code: record.value.master_course ? record.value.master_course.code : null,
        date_start: record.value.date_start,
        time_from: record.value.time_from,
      }
      if (!isAddSimilar.value && props.courseAction === 'add' && payload.days && payload.code && payload.date_start && payload.time_from) {
        this.$http.post('/course/generate/group', payload).then(res => { record.value.group = res.data })
      }
      // ${record.date_start ? record.date_start.split('-')[0] : ''}${record.date_start ? record.date_start.split('-')[1] : ''}
      // record.value.group = `${record.value.master_course ? record.value.master_course.code.charAt(0) : ''}${record.value.date_start ? record.value.date_start.split('-')[2] : ''}${record.value.date_start ? record.value.date_start.split('-')[1] : ''}${selectedDays.value.length > 2 ? 'i' : 's'}${record.value.time_from ? record.value.time_from.split(':')[0] : ''}${record.value.time_from ? record.value.time_from.split(':')[1] : ''}`
    }
    const create = () => {
      if (props.isAddGroupActive) createGroup()
      else createCourse()
    }
    // eslint-disable-next-line func-names
    const createGroup = function () {
      record.value.days = selectedDays.value.toString()
      const payload = {
        mastercourse_id: record.value.master_course.id,
        days: record.value.days,
        date_start: record.value.date_start,
        time_from: record.value.time_from,
        time_to: record.value.time_to,
        students_limit: record.value.students_limit,
        status: record.value.status,
        group: record.value.group,
      }
      store.dispatch('coursesStoreModule/createGroup', payload)
      .then(res => {
        emit('refetch-data')
        emit('update:isAddActive', false)
        emit('update:isAddGroupActive', false)
        emit('got-to-group', record.value.group)
        toast({
            component: ToastificationContent,
            props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
            },
        })
      }).catch(error => {
          toast({
              component: ToastificationContent,
              props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          handleBackendValidatiob(error.response.data.errors)
          // errors.value = error.response.data.errors
          // if (error.response.data.errors) {
          //   f
          //   errors.value = {
          //     time_from: error.response.data.errors.time_from ? error.response.data.errors.time_from : [],
          //     time_to: error.response.data.errors.time_to ? error.response.data.errors.time_to : [],
          //     date_start: error.response.data.errors.date_start ? error.response.data.errors.date_start : [],
          //     date_end: [],
          //     group: [],
          //     mastercourse_id: [],
          //     days: [],
          //     students_limit: [],
          //     status: [],
          //     hours: [],
          //     price: [],
          //   }
          // }
      })
    }
    // eslint-disable-next-line func-names
    const createCourse = function () {
          record.value.days = selectedDays.value.toString()
          /* eslint-disable no-unused-expressions */
          const payload = {
            mastercourse_id: record.value.master_course.id,
            days: record.value.days,
            date_start: record.value.date_start,
            date_end: record.value.date_end,
            time_from: record.value.time_from,
            time_to: record.value.time_to,
            attendancecolumns: record.value.attendancecolumns,
            hours: record.value.hours,
            students_limit: record.value.students_limit,
            price: record.value.price,
            status: record.value.status,
            group: record.value.group,
            duplicate: isAddSimilar.value,
          }
          if (props.courseAction === 'add' || props.courseAction === 'addFromModal') {
            store.dispatch('coursesStoreModule/storeCourse', payload)
            .then(res => {
            emit('refetch-data')
            emit('update:isAddActive', false)
            emit('update:isAddGroupActive', false)
            toast({
                component: ToastificationContent,
                props: {
                title: res.data.message,
                icon: 'CheckIcon',
                variant: 'success',
                },
            })
            }).catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                    title: error.response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    },
                })
                handleBackendValidatiob(error.response.data.errors)
            })
          }
      // if (this.$refs.form.flags.valid) {
      // }
    }
    const updateCourse = () => {
      record.value.days = selectedDays.value.toString()
      record.value.teachers.map(teacher => (teacher.id ? teacher.id : teacher.teacher_id))
      const object = record.value
      store.dispatch('app-courses/updateCourse', { object })
      .then(res => {
        if (res.data.message === 'some Sessions would be overridden') {
          emit('open-override-modal', record.value)
          // isOverrideModalActive.value = true
        }
        emit('refetch-data')
        emit('update:isBigModalActive', false)
        toast({
          component: ToastificationContent,
          props: {
          title: res.data.message,
          icon: 'CheckIcon',
          variant: 'success',
          },
        })
      })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
            title: error.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            },
          })
          errors.value = error.response.data.errors
      })
    }
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(() => {})
    return {
      errorMessageRefreshKey,
      create,
      createGroup,
      refFormObserver,
      getValidationState,
      createCourse,
      updateCourse,
      selectedDays,
      isAddSimilar,
      days,
      record,
      errors,
      generateGroupe,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
  label{
    font-weight: bold;
  }
  .modal-title {
    font-size: 16px;
  }
</style>
