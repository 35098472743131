<template>
  <div>
    <b-sidebar
      v-show="!eventLocal.extendedProps.session_id"
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">
            {{ $t('Details') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <b-card>
          <div class="mt-2 row">
            <strong class="text-capitalize mb-75 col-4">
              {{ $t('Teacher') }}:
            </strong>
            <span v-if="eventLocal.extendedProps.teacher">
              {{ eventLocal.extendedProps.teacher.teacher_name }}
            </span>
          </div>
          <div class="mt-2 row">
            <strong class="text-capitalize col-4 mb-75">
              {{ $t('Classroom') }}:
            </strong>
            <span v-if="eventLocal.extendedProps.classroom">
              {{ eventLocal.extendedProps.classroom.classroom_name }}
            </span>
          </div>
          <div v-if="eventLocal.extendedProps.classroom && eventLocal.extendedProps.classroom.zoom_link" class="mt-2 row">
            <strong class="text-capitalize col-4 mb-75">
              {{ $t('Zoom link') }}:
            </strong>
            <b-link v-if="eventLocal.extendedProps.classroom" :href="eventLocal.extendedProps.classroom.zoom_link" target="_blank">
              {{ 'Classroom link' }}
              <feather-icon icon="ExternalLinkIcon" class="mb-25" />
            </b-link>
          </div>
          <div
            v-if="eventLocal.extendedProps.homework_link"
            class="mt-2 row"
          >
            <h5 class="text-capitalize mb-75 col-4">
              {{ $t('Homework') }}
            </h5>
            <b-link v-if="eventLocal.extendedProps.homework_link" :href="eventLocal.extendedProps.homework_link.link">
              {{ eventLocal.extendedProps.homework_link.link }}
            </b-link>
          </div>
        </b-card>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, VBModal, BCard, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs, watch } from '@vue/composition-api'
import useCalendarEventHandler from './useCalendarEventHandler'

export default {
  components: {
    // BButton,
    BSidebar,
    BCard,
    BLink,
    // ValidationProvider,
    // BFormInvalidFeedback,
    // ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,

      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    watch(eventLocal, () => {
      if (eventLocal.value.extendedProps.session_id) {
        emit('show-session-substitute-modal', eventLocal.value.extendedProps.session_id)
      }
    })
    clearFormData.value = clearForm

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      guestsOptions,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>
