<template>
  <!-- eslint-disable vue/mustache-interpolation-spacing -->
  <div>

    <!-- <city-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    /> -->

    <!-- Filters -->
    <!-- <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            lg="6"
          >
            <v-select
              v-model="selectedFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filters"
              lebel="label"
              :clearable="false"
              class="courses-filter d-inline-block mx-50"
              style="min-width: 150px;"
            />
          </b-col>
          <!-- Search -->
          <!-- <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
              />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">{{$t('Add city')}}</span>
              </b-button>
            </div>
          </b-col> -->
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        :busy.sync="isBusy"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
              style="width: 3rem; height: 3rem;"
            />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>
        <!-- Column: User -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.id }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Name -->
        <template #cell(name)="data">
          <span
            v-b-tooltip.hover.top="data.item.name"
            class="d-inline-block text-truncate"
            style="max-width: 300px;"
          >{{ data.item.name }}</span>
        </template>
        <!-- Column: Ordering -->
        <template #cell(ordering)="data">
          {{ data.item.ordering }}
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            dropleft
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="userRole === 'administrator'"
              v-b-modal="getModal2Id(data.item.id)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{$t('Edit')}}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="userRole === 'administrator'"
              v-b-modal="getModal1Id(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{$t('Delete')}}</span>
            </b-dropdown-item>
            <!-- basic modal -->
            <b-modal
              :id="'modal1'+data.item.id"
              title="Delete City"
              :ok-title="$t('Accept')"
              @ok="remove(data.item.id)"
            >
              <b-card-text>
                <h5>Are you sure you want to delete </h5><strong>{{ data.item.name }}</strong>
              </b-card-text>
            </b-modal>

            <!-- basic modal -->
            <b-modal
              :id="'modal2'+data.item.id"
              :title="$t('Edit City')"
              :ok-title="$t('Update')"
              ok-only
              no-close-on-backdrop
              hide-footer
            >
              <validation-observer
                #default="{ handleSubmit }"
                ref="form"
              >
                <!-- Form -->
                <b-form
                  class="p-2"
                  @submit.prevent="handleSubmit(edit(data.item.id, data.item.name, data.item.ordering))"
                >
                  <validation-provider
                    #default="validationContext"
                    name="City name"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('City name')"
                      label-for="name"
                    >
                      <b-form-input
                        v-model="data.item.name"
                        autofocus
                        :state="getValidationState(validationContext)"
                        :placeholder="$t('Name')"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="validationContext"
                    name="Ordering"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Ordeting')"
                      label-for="Ordering"
                    >
                      <b-form-input
                        v-model="data.item.ordering"
                        :state="getValidationState(validationContext)"
                        autofocus
                        :placeholder="$t('Name')"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="validationContext"
                    name="Status"
                    rules="required"
                  >
                    <b-form-group
                      :state="getValidationState(validationContext)"
                      label="Status"
                    >
                      <v-select
                        v-model="selectedStatus"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statusOptions"
                        :clearable="false"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                  >
                    {{ $t('Add') }}
                  </b-button>
                </b-form>
              </validation-observer>
            </b-modal>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{$t('Show')}}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{$t('entries')}}</label>
          </b-col>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{$t('Showing')}} {{ dataMeta.from }} {{$t('to')}} {{ dataMeta.to }} {{$t('of')}} {{ dataMeta.of }} {{$t('entries')}}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCities"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BFormInput, BFormGroup, BButton, BTable, BMedia, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, BSpinner, VBTooltip, BFormInvalidFeedback, BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// import UsersListFilters from './UsersListFilters.vue'
import useApprovalList from './useApprovalList'
import approvalStoreModule from './approvalStoreModule'
// import CityListAddNew from './CityListAddNew.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    // UsersListFilters,
    // CityListAddNew,

    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BSpinner,
    BFormInvalidFeedback,
    BForm,

    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      statusOptions: ['Active', 'Deleted'],
      isBusy: false,
      required,
    }
  },
  setup() {
    /* eslint-disable camelcase */
    const getModal1Id = id => `modal1${id}`
    const getModal2Id = id => `modal2${id}`

    const APPROVAL_STORE_MODULE_NAME = 'approval'

    // Register module
    if (!store.hasModule(APPROVAL_STORE_MODULE_NAME)) store.registerModule(APPROVAL_STORE_MODULE_NAME, approvalStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APPROVAL_STORE_MODULE_NAME)) store.unregisterModule(APPROVAL_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'administrator' },
      { label: 'Student', value: 'student' },
      { label: 'Teacher', value: 'teacher' },
      { label: 'Agent', value: 'agent' },
      { label: 'Company', value: 'company' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Deleted', value: 'deleted' },
    ]
    const filters = [
      { label: 'All', value: null },
      { label: 'Active', value: 'Active' },
      { label: 'Deleted', value: 'Deleted' },
    ]
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userRole = userData.role
    const selectedStatus = ref({ label: 'Active', value: 'Active' })

    // Use toast
    const toast = useToast()

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalCities,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      selectedFilter,
    } = useApprovalList()

    const remove = id => {
      store.dispatch('approval/deleteCity', id)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'City Has Been removed',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while removing City',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const edit = function (id, name, ordering) {
      if (this.$refs.form.flags.valid) {
        const payload = {
          name,
          ordering,
          status: selectedStatus.value.value,
        }
        store.dispatch('approval/editCity', { id, payload })
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'City Has Been edited',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            refetchData()
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error while editing City',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          })
      }
    }
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(() => {})

    return {
      refFormObserver,
      getValidationState,
      // Modal ids
      getModal1Id,
      getModal2Id,

      // Actions
      remove,
      edit,

      userRole,
      selectedStatus,

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalCities,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      filters,
      selectedFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
