<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="p-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        aria-controls="sidebar-add-new-event"
        :aria-expanded="String(isEventHandlerSidebarActive)"
        variant="primary"
        block
        @click="$emit('update:isEventHandlerSidebarActive', true)"
      >
        {{ 'Request a time Off' }}
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="secondary"
        block
        @click="showTeacherEvents = true"
      >
        {{ 'My requests' }}
      </b-button>
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">Calendars</span>
        </h5>
        <b-form-checkbox
          v-model="checkAll"
          class="mb-1"
        >
          {{ $t('View All') }}
        </b-form-checkbox>
        <b-form-group>
          <b-form-checkbox-group
            v-model="selectedCalendars"
            name="event-filter"
            stacked
          >
            <b-form-checkbox
              v-for="item in calendarOptions"
              :key="item.label"
              name="event-filter"
              :value="item.label"
              class="mb-1"
              :class="`custom-control-${item.color}`"
            >
              {{ item.label }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" />
    <b-modal
      v-model="showTeacherEvents"
      title="My requests"
      ok-title="Accept"
      size="xl"
      centered
      no-close-on-backdrop
      hide-footer
      body-class="events-modal"
    >
      <teacher-events :teacherView="true" />
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BImg, BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import TeacherEvents from '@/views/admin/teachers-list/TeacherEvents.vue'
import useCalendarSidebar from './useCalendarSidebar'

export default {
  directives: {
    Ripple,
  },
  components: {
    TeacherEvents,
    BButton,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    BModal,
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      showTeacherEvents: false,
    }
  },
  setup() {
    const {
      calendarOptions,
      selectedCalendars,
      checkAll,
      levelsOptions,
      selectedLevels,
      checkAllLevels,
    } = useCalendarSidebar()

    return {
      calendarOptions,
      selectedCalendars,
      checkAll,
      levelsOptions,
      selectedLevels,
      checkAllLevels,
    }
  },
}
</script>

<style>

</style>
