<template>
  <b-sidebar
    id="sidebar"
    :visible="isFilterActive"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    right
    backdrop
    shadow
    no-header
    width="450px"
    @change="(val) => $emit('update:isFilterActive', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex mb-2 justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Course Filter
        </h5>
        <div>
          <feather-icon
            icon="RefreshCcwIcon"
            class="cursor-pointer"
            @click="$emit('reset-filter')"
          />
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
      </div>
      <b-form @submit.prevent>
        <b-container>
          <b-row>
            <b-col>
              <b-form-group
                label="Status"
                label-for="status"
              >
                <v-select
                  :value="filterDefault"
                  label="label"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="coursesStatusOptions"
                  :clearable="false"
                  @input="(val) => $emit('update:filterDefault', val)"
                >
                  <template #option="{ code, label }">
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`${code}-filter`"
                    />
                    <span> {{ label }}</span>
                  </template>

                  <template #selected-option="{ code, label }">
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`${code}-filter`"
                    />
                    <span>{{ label }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Group code"
                label-for="gc"
              >
                <v-select
                  :value="group"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="group"
                  :options="groups"
                  :clearable="false"
                  placeholder="Choose a course group"
                  @keyup.native="autoCompleteGroup($event.target.value)"
                  @click.native="autoCompleteGroup('')"
                  @input="(val) => $emit('update:group', val.group)"
                >
                  <span slot="no-options">
                    <div
                      v-if="isTyping"
                      class="text-center d-flex align-items-center justify-content-center"
                    >
                      <b-spinner
                        variant="primary"
                        label="Text Centered"
                      />
                      <div
                        v-if="!isTyping && !groups.length"
                        class="text-center d-flex align-items-center justify-content-center"
                      >
                        No matching records found
                      </div>
                    </div>
                  </span>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Mastercourse"
                label-for="mastercourse"
              >
                <v-select
                  id="mastercourse"
                  :value="mastercourse_ids"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="masters"
                  :reduce="x => x.id"
                  multiple
                  label="code"
                  placeholder="choose a course Master"
                  @input="(val) => $emit('update:mastercourse_ids', val)"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Classroom"
                label-for="classroom"
              >
                <v-select
                  id="classroom"
                  :value="classroom_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="classrooms"
                  :reduce="x => x.id"
                  label="code"
                  placeholder="choose a course classroom"
                  @input="(val) => $emit('update:classroom_id', val)"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Teacher"
                label-for="teacher"
              >
                <v-select
                  id="teacher"
                  :value="teacher_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="teachers"
                  :reduce="x => x.id"
                  placeholder="choose a course teacher"
                  @input="(val) => $emit('update:teacher_id', val)"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col>
              <b-form-group
                label="Status"
                label-for="status"
              >
                <v-select
                  id="status"
                  :value="fStatus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="statusArray"
                  :reduce="x => x.value"
                  placeholder="Status"
                  @input="(val) => $emit('update:fStatus', val)"
                />
              </b-form-group>
            </b-col>
          </b-row> -->
          <!-- <b-row>
            <b-col>
              <b-form-group
                label="Time of the day"
                label-for="tod"
              >
                <v-select
                  id="tod"
                  :value="time_of_day"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="TofDArray"
                  :reduce="x => x.value"
                  placeholder="Time of the day"
                  @input="(val) => $emit('update:time_of_day', val)"
                />
              </b-form-group>
            </b-col>
          </b-row> -->
          <b-row>
            <b-col>
              <b-form-group
                label="Date start from"
                label-for="dsf"
              >
                <flat-pickr
                  id="dsf"
                  placeholder="From"
                  :value="date_start_from"
                  :config="{ altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 }}"
                  class="form-control input"
                  @input="(val) => $emit('update:date_start_from', val)"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Date start to"
                label-for="dst"
              >
                <flat-pickr
                  id="dst"
                  placeholder="To"
                  :value="date_start_to"
                  :config="{ altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 }}"
                  class="form-control input"
                  @input="(val) => $emit('update:date_start_to', val)"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Date end from"
                label-for="def"
              >
                <flat-pickr
                  id="def"
                  placeholder="From"
                  :value="date_end_from"
                  :config="{ altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 }}"
                  class="form-control input"
                  @input="(val) => $emit('update:date_end_from', val)"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Date end to"
                label-for="det"
              >
                <flat-pickr
                  id="det"
                  placeholder="To"
                  :value="date_end_to"
                  :config="{ altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 }}"
                  class="form-control input"
                  @input="(val) => $emit('update:date_end_to', val)"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Time from"
                label-for="time-from"
              >
                <flat-pickr
                  placeholder="From"
                  :value="time_from"
                  class="form-control"
                  :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
                  @input="(val) => $emit('update:time_from', val)"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Time to"
                label-for="time-to"
                >
                <flat-pickr
                  placeholder="To"
                  class="form-control"
                  :value="time_to"
                  :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
                  @input="(val) => $emit('update:time_to', val)"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
          <b-button
            variant="primary"
            class="ml-auto mr-1"
            @click="$emit('export-course')"
          >
            <div class="d-flex">
              <b-spinner v-if="isLoadingExcelDoc" class="mr-25" small label="Loading..." />
              <span v-if="!isLoadingExcelDoc">Export </span>
            </div>
          </b-button>
          </b-row>
        </b-container>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BSpinner, BButton, /* BFormInvalidFeedback,  */BRow, BCol, BContainer,
} from 'bootstrap-vue'
/* import { ValidationProvider, ValidationObserver } from 'vee-validate' */
/* import { ref } from '@vue/composition-api' */
/* import formValidation from '@core/comp-functions/forms/form-validation' */
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
/* import flatPickr from 'vue-flatpickr-component' */

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BSpinner,
    BContainer,
    BButton,
    BRow,
    BCol,
    /* BFormInvalidFeedback, */
    vSelect,
    flatPickr,

    // Form Validation
    /* ValidationProvider,
    ValidationObserver, */
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isFilterActive',
    event: 'update:isFilterActive',
  },
  props: {
    isLoadingExcelDoc: {
      type: Boolean,
      default: false,
    },
    isFilterActive: {
      type: Boolean,
      required: true,
    },
    coursesStatusOptions: {
      type: Array,
      required: true,
    },
    masters: {
      type: Array,
      required: true,
    },
    // cities: {
    //   type: Array,
    //   required: true,
    // },
    classrooms: {
      type: Array,
      required: true,
    },
    teachers: {
      type: Array,
      required: true,
    },
    // statusArray: {
    //   type: Array,
    //   required: true,
    // },
    TofDArray: {
      type: Array,
      required: true,
    },
    filterDefault: {
      type: [Object, null],
      default: null,
    },
    group: {
      type: [String, null],
      default: null,
    },
    mastercourse_ids: {
      type: [Array, null],
      default: null,
    },
    classroom_id: {
      type: [Number, null],
      default: null,
    },
    date_start_from: {
      type: [String, null],
      default: null,
    },
    date_start_to: {
      type: [String, null],
      default: null,
    },
    date_end_from: {
      type: [String, null],
      default: null,
    },
    date_end_to: {
      type: [String, null],
      default: null,
    },
    time_from: {
      type: [String, null],
      default: null,
    },
    time_to: {
      type: [String, null],
      default: null,
    },
    teacher_id: {
      type: [Number, null],
      default: null,
    },
    // fStatus: {
    //   type: [String, null],
    //   default: null,
    // },
    // time_of_day: {
    //   type: [String, null],
    //   default: null,
    // },
  },
  data() {
    return {
      groups: [],
      isTyping: false,
    }
  },
  methods: {
    autoCompleteGroup(group) {
      this.isTyping = true
      this.$http.post('/group/autocomplete', { group })
      .then(res => {
        this.isTyping = false
        this.groups = res.data
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  /* background-color: #f7f7f7; */
}
.input:focus {
  outline: none;
}
</style>
