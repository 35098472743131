import axios from '@axios'

export default {
  namespaced: true,
  state: {
    suggestedClassrooms: [],
    suggestedTeachers: [],
    calendarOptions: [
      {
        color: 'success',
        label: 'Needs Teacher',
      },
      {
        color: 'danger',
        label: 'Needs Classroom',
      },

    ],
    selectedCalendars: ['Needs Teacher', 'Needs Classroom'],
  },
  getters: {},
  mutations: {
    SET_EVENTS(state, response) {
      state.events = response
    },
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
    setCalendarTeachers(state, val) {
      state.selectedCalendars.teachersList = val
    },
    setCalendarClassrooms(state, val) {
      state.selectedCalendars.classroomsList = val
    },
    setCalendarMCourses(state, val) {
      state.selectedCalendars.mastercoursesList = val
    },
    setClassroomsSuggestions(state, val) {
      state.suggestedClassrooms = val
    },
    setTeachersSuggestions(state, val) {
      state.suggestedTeachers = val
    },
  },
  actions: {
    /* eslint-disable-next-line */
    fetchEvents(store, { date_start, date_end, params, fStatus }) {
      console.log(params)
      return new Promise((resolve, reject) => {
        axios
          .get('/courses/calendar/data', {
            params: {
              // status: statuss,
              // calendars: calendars.join(','),
              date_start,
              date_end,
              params,
              fStatus,
              // classroom_id,
              // status: 'available',
            },
          })
          .then(response => {
            store.commit('SET_EVENTS', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async getClassrooms(store) {
      return new Promise((resolve, reject) => {
        axios
          .get('/classroom-list/data?perPage=100&status=Active')
          .then(response => {
            store.commit('setCalendarClassrooms', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async getTeachers(store) {
      return new Promise((resolve, reject) => {
        axios
          .get('/teacher/list?perPage=100&status=Active')
          .then(response => {
            store.commit('setCalendarTeachers', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    /* eslint-disable camelcase */
    async updateSuggestClassrooms({ commit }, course_id) {
      console.log(course_id)
      return new Promise((resolve, reject) => {
        axios
          .get(`/classroom/suggest/by-course?course_id=${course_id}`)
          .then(response => {
            commit('setClassroomsSuggestions', response.data.available)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async updateSuggestTeachers({ commit }, course_id) {
      console.log(course_id)
      return new Promise((resolve, reject) => {
        axios
          .get(`/teacher/suggest/by-course?course_id=${course_id}`)
          .then(response => {
            commit('setTeachersSuggestions', response.data.allTeachers)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async fetchMCourses({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/master-course-list/data?perPage=100&status=Active', { params })
          .then(response => {
            commit('setCalendarMCourses', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/calendar/events/${event.id}`, { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCoursesSession(ctx, { event }) {
      const payload = {
        homework: event.extendedProps.homework,
        teacher_id: event.extendedProps.teacher ? event.extendedProps.teacher.id : null,
        classroom_id: event.extendedProps.classroom ? event.extendedProps.classroom.id : null,
        start: event.start,
        end: event.end,
        postpone: event.postpone,
        collides: event.collides,
      }
      /* eslint-disable */
      if (!payload.classroom_id) delete payload.classroom_id
      if (!payload.teacher_id) delete payload.teacher_id
      return new Promise((resolve, reject) => {
        axios
          .post(`/session/${event.id}/update`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
