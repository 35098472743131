<template>
  <div>
    <!-- Date and filters -->
    <b-row class="mb-1">
      <b-col cols="6" />
      <b-col cols="3">
        <div>
          <v-select
            v-model="dateOption"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="dateOptions"
            @input="fetchStatistics()"
          />
        </div>
      </b-col>
      <b-col cols="3">
        <!-- datepicker -->
        <div class="d-flex align-items-center">
          <feather-icon
            icon="CalendarIcon"
            size="16"
          />
          <flat-pickr
            v-model="rangePicker"
            :config="{ mode: 'range', dateFormat: 'Y-m-d', altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 }}"
            class="form-control flat-picker bg-transparent border-0 shadow-none"
            placeholder="YYYY-MM-DD"
            @input="foo"
          />
        </div>
        <!-- datepicker -->
      </b-col>
    </b-row>
    <!-- Statistics -->
    <b-row>
      <b-col cols="12">
        <created-courses
          :key="refreshKey+String('created_courses')"
          :revenue-comparison-line="apexChartData.revenueComparisonLine"
          :report="report"
        />
      </b-col>
      <b-col cols="12">
        <courses-attendance
          :key="refreshKey+String('courses_attendance')"
          :column-chart="apexChartData.columnChart"
          :initial-range-picker="initialRangePicker"
        />
      </b-col>
      <b-col cols="4">
        <el-to-download-certificate
          :key="refreshKey+String('certificate')"
          :sessions-by-device-donut="apexChartData.sessionsByDeviceDonut"
        />
      </b-col>
      <b-col cols="4">
        <students
          :key="refreshKey+String('students')"
          :customers-pie="apexChartData.customersPie"
        />
      </b-col>
      <b-col cols="4">
        <aVG-Students-attendance
          :key="refreshKey+String('avg')"
          :goal-overview-radial-bar="apexChartData.goalOverviewRadialBar"
          :avg="avg"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import CreatedCourses from './CreatedCourses.vue'
import CoursesAttendance from './CoursesAttendance.vue'
import ElToDownloadCertificate from './ElToDownloadCertificate.vue'
import Students from './Students.vue'
import AVGStudentsAttendance from './AVGStudentsAttendance.vue'
import apexChartData from '../apexChartData'

export default {
  components: {
    BRow,
    BCol,
    vSelect,
    flatPickr,
    CreatedCourses,
    CoursesAttendance,
    ElToDownloadCertificate,
    Students,
    AVGStudentsAttendance,
  },
  data() {
    return {
      apexChartData,
      refreshKey: 0,
      rangePicker: [],
      initialRangePicker: [],
      start: '',
      end: '',
      report: {
        thisMonth: null,
        lastMonth: null,
      },
      avg: {
        completed: null,
        left: null,
      },
      dateOption: { title: 'Daily', value: 'daily' },
      dateOptions: [
        { title: 'Daily', value: 'daily' },
        { title: 'Weekly', value: 'weekly' },
        { title: 'Monthly', value: 'monthly' },
      ],
    }
  },
  created() {
    this.start = moment(new Date()).format('YYYY-MM-DD')
    const nextMonth = new Date(new Date(this.start).setMonth(new Date(this.start).getMonth() + 1))
    this.end = moment(nextMonth).format('YYYY-MM-DD')
    this.rangePicker.push(this.start, this.end)
    this.initialRangePicker.push(this.start, this.end)
    this.fetchStatistics()
    // this.$http.get('/statistics/admin/charts', {  })
  },
  methods: {
    /* eslint-disable prefer-destructuring */
    foo() {
      if (this.rangePicker.split(' ').length > 1) {
        this.start = this.rangePicker.split(' ')[0]
        this.end = this.rangePicker.split(' ')[2]
        this.fetchStatistics()
      }
    },
    fetchStatistics() {
      this.$http.get('/statistics/admin/charts', {
        params: {
          date_start: this.start,
          date_end: this.end,
          filter: this.dateOption.value,
        },
      }).then(res => {
        apexChartData.revenueComparisonLine.chartOptions.xaxis.categories = res.data.courseCreated.dates
        apexChartData.revenueComparisonLine.series[0].data = res.data.courseCreated.this_period.data
        apexChartData.revenueComparisonLine.series[1].data = res.data.courseCreated.last_period_data.data
        this.report.thisMonth = res.data.courseCreated.this_period.value
        this.report.lastMonth = res.data.courseCreated.last_period_data.value
        apexChartData.columnChart.series[0].data = res.data.courseAttendance.attendance.data
        apexChartData.columnChart.series[1].data = res.data.courseAttendance.free_spots.data
        apexChartData.sessionsByDeviceDonut.series = res.data.eligible_to_download_certificate
        apexChartData.customersPie.series = res.data.new_returning_students.percent
        apexChartData.goalOverviewRadialBar.series = [res.data.avg_students_attendance_hours.avg]
        this.avg.completed = res.data.avg_students_attendance_hours.completed_hours
        this.avg.left = res.data.avg_students_attendance_hours.remaining_hours

        this.refreshKey += 1
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
