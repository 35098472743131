<template>
  <b-card>
    <b-form>
      <b-row>
        <b-col
          class="d-flex justify-content-between align-items-center"
          cols="12"
        >
          <div class="d-flex align-items-center mb-2">
            <feather-icon
              icon="LinkIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              {{ 'Link my slack' }}
            </h4>
          </div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            pill
            class="mb-2"
            @click="getLink()"
          >
            {{ !localOptions.socialLinks.isSlackLinked ? 'Link' : 'Unlink' }}
          </b-button>
        </b-col>
        <!-- buttons -->
        <!-- <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click.prevent="save()"
          >
            {{ $t('settings.Save Changes') }}
          </b-button>
        </b-col> -->
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BRow, BCol, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {
    socialData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localOptions: JSON.parse(JSON.stringify(this.socialData)),
      userData: JSON.parse(localStorage.userData),
    }
  },
  methods: {
    getLink() {
      if (!this.localOptions.socialLinks.isSlackLinked) {
        return window.open(`${process.env.VUE_APP_SLACK_CLIENT_LINK}${this.userData.id}`)
      }
      return this.$http.post('slack/unlink').then(() => {
        this.localOptions.socialLinks.isSlackLinked = false
        window.open(`${process.env.VUE_APP_SLACK_UNLINK}`)
      })
    },
    save() {
      const body = {
        twitter: this.localOptions.socialLinks.twitter,
        facebook: this.localOptions.socialLinks.facebook,
        google: this.localOptions.socialLinks.google,
        linkedIn: this.localOptions.socialLinks.linkedIn,
        instagram: this.localOptions.socialLinks.instagram,
        quora: this.localOptions.socialLinks.quora,
      }
      this.$http.post('/account-setting/info', body, {
        headers: {
          Accept: 'application/json',
        },
      }).then(response => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            text: response.data.message,
            icon: 'CheckIcon',
          },
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            text: error.response.data.message,
            icon: 'AlertTriangleIcon',
          },
        })
        this.$refs.generalData.setErrors(error.response.data.errors)
      })
    },
  },
}
</script>
