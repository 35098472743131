<template>
  <div><img
         :src="content.media"
         style="max-height: 120px;"
         class="pb-50"
       >
    <div>
      <b-form-input
        v-model="response"
        class="d-inline-flex"
        style="max-width: 20%"
        @keyup="$emit('responseevent', {id: idx, response: response})"
      />
    </div>
  </div>
</template>
<script>
import { BFormInput } from 'bootstrap-vue'

export default {
  name: 'ImageComponent',
  components: { BFormInput },
  props: {
    content: Object,
    idx: Number,
  },
  data() {
      return {
          response: null,
      }
  },
  created() {
      console.log(this.content)
  },
}
</script>

<style scoped>
img{
  max-height: 35px;
}
</style>
