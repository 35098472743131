<template>
  <b-card
    no-body
    class="card-revenue-budget"
  >
    <b-row class="mx-0">
      <b-col
        md="8"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">
            Classroom Usage
          </h4>
          <!-- <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
              <span>Substitution</span>
            </div>
            <div class="d-flex align-items-center ml-75">
              <span class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer" />
              <span>Off</span>
            </div>
          </div> -->
        </div>

        <!-- chart -->
        <vue-apex-charts
          id="revenue-report-chart"
          :key="refreshKey"
          type="bar"
          height="230"
          :options="revenueReport.chartOptions"
          :series="revenueReport.series"
        />
      </b-col>

      <b-col
        md="4"
        class="budget-wrapper"
      >
        <b-dropdown
          :text="classroomName"
          size="sm"
          class="budget-dropdown"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="classroom in classrooms"
            :key="classroom.id"
            @click="getClassroomStats(classroom)"
          >
            {{ classroom.name }}
          </b-dropdown-item>
        </b-dropdown>

        <!-- <h2 class="mb-25">
          {{ 89723 }} Days Off
        </h2>
        <div class="d-flex justify-content-center">
          <span class="font-weight-bolder mr-25">Substitutions:</span>
          <span>56,800</span>
        </div> -->
        <vue-apex-charts
          id="budget-chart"
          :key="refreshKey"
          type="line"
          height="80"
          :options="budgetChart.options"
          :series="budgetChart.series"
        />

        <!-- <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
        >
          Increase Budget
        </b-button> -->
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    revenueReport: {
      type: Object,
      required: true,
    },
    budgetChart: {
      type: Object,
      required: true,
    },
    start: {
      type: String,
      required: true,
    },
    end: {
      type: String,
      required: true,
    },
    filter: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      classrooms: [],
      classroomName: 'All Classrooms',
      revenue_report: {},
      refreshKey: 0,
    }
  },
  created() {
    this.$http.get('/classroom-list/data').then(res => {
      this.classrooms = res.data.rows
      this.classrooms.unshift({ name: 'All Classrooms' })
    })
  },
  methods: {
    getClassroomStats(classroom) {
      this.$http.get('/statistics/admin/3/charts', {
        params: {
          date_start: this.start,
          date_end: this.end,
          filter: this.filter,
          classroom_id: (classroom.id ? classroom.id : 'all'),
        },
      }).then(res => {
        this.classroomName = classroom.name
        this.revenueReport.series[0].data = res.data.classroomUsage.this_period.data
        this.revenueReport.chartOptions.xaxis.categories = res.data.classroomUsage.dates
        this.budgetChart.series[0].data = res.data.classroomUsage.this_period.data
        this.refreshKey += 1
      })
    },
  },
}
</script>
