export default [
  // {
  //   path: '/admin/courses/list',
  //   name: 'admin-courses',
  //   component: () => import('@/views/courses/Course.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'email-application',
  //   },
  // },
  {
    path: '/courses/calendar',
    name: 'courses-calendar',
    component: () => import('@/views/admin/courses-calendar/Calendar.vue'),
  },
  {
    path: '/admin/courses/list',
    name: 'admin-courses',
    component: () => import('@/views/courses/admin/courses-list/CoursesList.vue'),
  },
  {
    path: '/admin/courses/teachers/approval',
    name: 'admin-courses-teachers-approval',
    component: () => import('@/views/courses/admin/approval-list/ApprovalList.vue'),
  },
  {
    path: '/admin/courses/calendar',
    name: 'admin-courses-calendar',
    component: () => import('@/views/courses/admin/calendar/CourseCalendar.vue'),
  },
  {
    path: '/admin/attendance/calendar',
    name: 'admin-attendance-calendar',
    component: () => import('@/views/courses/admin/calendar/CourseCalendar.vue'),
  },
  {
    path: '/admin/courses/masters',
    name: 'admin-courses-masters',
    component: () => import('@/views/courses/admin/masters-list/MastersList_.vue'),
  },
  {
    path: '/admin/courses/masterss',
    name: 'admin-courses-masterss',
    component: () => import('@/views/courses/admin/masters-list/MastersList.vue'),
  },
  // {
  //   path: '/admin/courses/types',
  //   name: 'admin-courses-types',
  //   component: () => import('@/views/courses/admin/types-list/TypeList_.vue'),
  // },
  {
    path: '/admin/courses/groups',
    name: 'admin-courses-groups',
    component: () => import('@/views/courses/admin/groups-list/GroupList.vue'),
  },
  {
    path: '/student/courses/list',
    name: 'student-courses',
    component: () => import('@/views/courses/student/CoursesList.vue'),
  },
  {
    path: '/admin/courses/substitutions',
    name: 'admin-courses-substitutions',
    component: () => import('@/views/admin/courses-substitute/CoursesList.vue'),
  },
  // {
  //   path: '/apps/calendar',
  //   name: 'apps-calendar',
  //   component: () => import('@/views/apps/calendar/Calendar.vue'),
  // },
  {
    path: '/admin/registrations',
    name: 'registrations',
    component: () => import('@/views/courses/registrations/registrations-list/RegistrationsList.vue'),
  },
  {
    path: '/admin/unfulfilled/registrations',
    name: 'registrations-unfulfilled',
    component: () => import('@/views/courses/unfulfilled-list/UnfulfilledList.vue'),
  },
  // {
  //   path: '/student/registrations/list',
  //   name: 'student-registrations',
  //   component: () => import('@/views/courses/registrations/student/StudentRegistration.vue'),
  // },
  {
    path: '/admin/invoices/list',
    name: 'admin-invoices',
    component: () => import('@/views/courses/invoices/InvoicesList.vue'),
  },
  {
    path: '/student/invoices/list',
    name: 'students-invoices',
    component: () => import('@/views/courses/invoices/InvoicesList.vue'),
  },
]
