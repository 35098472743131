<template>
  <div>
    <b-form-group
      label="Course Name"
      label-for="course-name"
    >
      <validation-provider
        #default="{ errors }"
        name="Course Name"
        rules="required"
      >
        <b-form-input
          v-if="!courses.length"
          v-model="course_name"
          :state="errors.length > 0 ? false:null"
          placeholder="Course name..."
          @keyup="autoCompleteCourse(course_name)"
        />
        <v-select
          v-if="courses.length"
          v-model="selectedCourse"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="course_name"
          :options="courses"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <div>
      <validation-observer ref="simpleRules">
        <b-form
          ref="form"
          :style="{height: trHeight}"
          class="repeater-form"
          @submit.prevent="repeateAgain"
        >

          <!-- Row Loop -->
          <b-row
            v-for="(item, index) in items"
            :id="item.id"
            :key="item.id"
            ref="row"
          >
            <!-- Email Address -->
            <b-col md="4">
              <b-form-group
                label="Email Address"
                label-for="email-address"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email Address"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="item.emailValue"
                    :state="errors.length > 0 ? false:null"
                    placeholder="example@alfa.dev"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Remove Button -->
            <b-col
              lg="2"
              md="3"
              class="mb-50"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>Delete</span>
              </b-button>
            </b-col>
            <!-- Remove Button -->
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="mr-1"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add New</span>
    </b-button>
    <b-button
      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
      variant="success"
      @click="submitItem(items)"
    >
      <feather-icon
        icon="CheckIcon"
        class="mr-25"
      />
      <span>Submit</span>
    </b-button>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, /* BFormSelect, BCardCode */
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import vSelect from 'vue-select'

/* eslint-disable no-param-reassign */
export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
    // BFormSelect,
    // BCardCode,

    // Form validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      email,
      courses: [],
      course_name: '',
      selectedCourse: {},
      items: [
        {
          id: 1,
          emailValue: '',
          prevHeight: 0,
          submitted: true,
        },
      ],
      nextTodoId: 2,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    submitItem(items) {
      const regData = {
        course_id: this.selectedCourse.id,
        emails: [],
      }
      items.forEach(item => {
        regData.emails.push(item.emailValue)
      })
      axios.post('/register/invite/employe', regData)
      .then(res => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: res.data.message,
            icon: 'SendIcon',
            variant: 'success',
          },
        })
        // this.$router.push({ name: 'invitation', params: { code: res.data.codes[0].code, email: res.data.codes[0].email } })
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: err.message,
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      })
    },
    autoCompleteCourse(search) {
      axios.post('/autocomplete/course', search)
      .then(res => {
        this.courses = []
        this.courses = res.data
      })
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          alert('form submitted!')
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
