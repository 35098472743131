export default [
  {
    title: 'Student',
    icon: 'UserIcon',
    noCollapsible: true,
    children: [
      {
        title: 'My sessions',
        route: 'student-calendar',
        icon: 'ClipboardIcon',
        roles: ['student'],
      },
      {
        title: 'My courses',
        route: 'student-list-courses',
        icon: 'BookIcon',
        roles: ['student'],
      },
      {
        title: 'My payments',
        route: 'student-invoices',
        icon: 'ClipboardIcon',
        roles: ['student'],
      },
      {
        title: 'Statistics',
        route: 'student-statistics',
        // icon: 'MailIcon',
        roles: ['student'],
      },
      {
        title: 'Upcoming courses',
        route: 'student-home',
        icon: 'ClipboardIcon',
        roles: ['student'],
      },
    ],
  },
]
