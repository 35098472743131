<template>
  <div>
    <div class="card app-calendar overflow-hidden border">
      <div
        :class="isBusyCalendar ? 'spinnerr-expand mt-2' : 'spinnerr'"
        class="text-center bg-transparent mt-3"
      >
        <b-spinner
          variant="primary"
          label="Text Centered"
        />
        <strong class="ml-1 pt-1">{{ $t('Loading...') }}</strong>
      </div>
      <div class="row no-gutters">
        <!-- Sidebar -->
        <div
          class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
          :class="{'show': isCalendarOverlaySidebarActive}"
        >
          <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" />
        </div>

        <!-- Calendar -->
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
              >
                <template #eventContent="{ event }">
                  <div class="my-event">
                    <div v-if="!event.extendedProps.day_off" class="my-event-title">
                      <b>{{ event.extendedProps.lesson_number }}: </b>
                      <span>{{ event.extendedProps.course ? event.extendedProps.course.course_master_code : '' }}</span> (<small>{{ event.extendedProps.course ? event.extendedProps.course.course_group : '' }}</small>)
                    </div>
                    <span v-if="event.extendedProps.day_off">{{ event.title }}</span>
                  </div>
                </template>
              </full-calendar>
            </div>
          </div>
        </div>
        <b-modal
          id="modal-1"
          ref="modal-2"
          :title="$t('Add Substitute')"
          hide-footer
        >
          <add-substitute
            :teacher-id="teacherId"
          />
        </b-modal>
        <!-- Sidebar Overlay -->
        <div
          class="body-content-overlay"
          :class="{'show': isCalendarOverlaySidebarActive}"
          @click="isCalendarOverlaySidebarActive = false"
        />
        <calendar-event-handler
          v-model="isEventHandlerSidebarActive"
          :event="event"
          :clear-event-data="clearEventData"
          @remove-event="removeEvent"
          @add-event="addEvent"
          @update-event="updateEvent"
          @show-substitute-modal="showSubstituteModal"
          @show-session-substitute-modal="showSessionSubstituteModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline, camelcase */
import { VBModal, BSpinner } from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import calendarStoreModule from './calendarStoreModule'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import AddSubstitute from '../../admin/teacher-calendar/calendar-event-handler/AddSubstitute.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
// import CourseTable from './calendar-event-handler/CourseTable.vue'
import useCalendar from './useCalendar'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    AddSubstitute,
    CalendarEventHandler,
    BSpinner,
    // CourseTable,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      query: '',
      dateStart: '',
      dateEnd: '',
      sessionId: null,
      teacherId: null,
    }
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.teacherId = userData.id
  },
  methods: {
    hideModal() {
      this.$refs['modal-2'].hide()
    },
    showSubstituteModal(dateStart, dateEnd, sessionId) {
      this.dateStart = dateStart
      this.dateEnd = dateEnd
      this.sessionId = sessionId
      this.$bvModal.show('modal-1')
    },
    showSessionSubstituteModal(sessionId) {
      this.sessionId = sessionId
      this.$bvModal.show('modal-1')
    },
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,
      teachersOptions,
      selectedTeacher,
      levelsOptions,
      selectedClassroom,
      teacher_id,
      isBusyCalendar,
      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventModalActive,
      isModalActive,
    } = useCalendar()

    fetchEvents()
    const autoComplete = (search, role_name) => {
      store.dispatch('calendar/teacherAutoComplete', { search, role_name })
      .then(res => {
        teachersOptions.value = []
        teachersOptions.value = res.data
      })
    }
    const getTeacherId = teacherId => {
      teacher_id.value = teacherId
    }

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,
      levelsOptions,
      selectedTeacher,
      selectedClassroom,
      teacher_id,
      isBusyCalendar,

      autoComplete,
      getTeacherId,

      teachersOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventModalActive,
      isModalActive,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
