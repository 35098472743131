<template>
  <div>

    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon
        icon="UserIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        {{ $t('Personal Information') }}
      </h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>

        <!-- Field: Birth Date -->
        <b-col
          cols="12"
          md="4"
          lg="4"
        >
          <b-form-group
            :label="$t('Birth Date')"
            label-for="birth-date"
          >
            <flat-pickr
              v-model="userData.dob"
              class="form-control"
              :config="{ altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 } }"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Mobile -->
        <b-col
          cols="12"
          md="4"
          lg="4"
        >
          <b-form-group
            :label="$t('phone')"
            label-for="phone"
          >
            <b-form-input
              id="mobile"
              v-model="userData.phone"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Nationality -->
        <b-col
          cols="12"
          md="4"
          lg="4"
        >
          <b-form-group
            :label="$t('Nationality')"
            label-for="nationality"
          >
            <v-select
              v-model="userData.nationality"
              :reduce="x => x.nationality"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="nationality"
              :options="countryOptions"
            />
          </b-form-group>
        </b-col>
        <!--/ Nationality -->
        <!-- Field: Gender -->
        <!-- <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Gender"
            label-for="gender"
            label-class="mb-1"
          >
            <b-form-radio-group
              id="gender"
              v-model="userData.gender"
              :options="genderOptions"
              value="male"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Contact Options -->
        <!-- <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Contact Options"
            label-for="contact-options"
            label-class="mb-1"
          >
            <b-form-checkbox-group
              id="contact-options"
              v-model="userData.contactOptions"
              :options="contactOptionsOptions"
            />
          </b-form-group>
        </b-col> -->
      </b-row>

      <!-- Header: Personal Info -->
      <div
        v-if="userData.role === 'teacher'"
        class="d-flex"
      >
        <feather-icon
          icon="AwardIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Professional informations
        </h4>
      </div>

      <b-row
        v-if="userData.role === 'teacher'"
        class="mt-1"
      >
        <b-col>
          <!-- Levels checkbox -->
          <!-- <b-form-group
            :label="$t('Levels')"
            label-for="levels"
          >
            <div class="d-flex align-items-center">
              <b-form-checkbox
                :checked="userData.levels.length === 4"
                class="custom-control-primary mr-1"
                @change="userData.levels.length !== 4 ? userData.levels = data.item.Alllevels : userData.levels = []"
              >
                All levels
              </b-form-checkbox>
              <b-form-checkbox
                v-for="level in levelOptions"
                :key="level.id"
                v-model="userData.levels"
                :value="level.id"
                class="custom-control-primary mr-1"
              >
                {{ level.code }}
              </b-form-checkbox>
            </div>
          </b-form-group> -->
          <b-form-group
            :label="$t('Levels')"
            label-for="levels"
          >
            <div class="d-flex">
              <b-form-checkbox
                class="custom-control-primary mr-1"
                :checked="selectedLevels.length === levelOptions.length"
                @change="selectedLevels.length !== levelOptions.length ? selectedLevels = levelOptions : selectedLevels = []"
              >
                All levels
              </b-form-checkbox>
              <b-form-checkbox
                v-for="(level, index) in levelOptions"
                :key="index"
                v-model="selectedLevels"
                :value="level"
                class="custom-control-primary mr-1"
              >
                {{ level.code }}
              </b-form-checkbox>
            </div>
          </b-form-group>
          <!-- Days -->
          <b-form-group
            :label="$t('Days')"
            label-for="days"
          >
            <div class="d-flex">
              <b-form-checkbox
                class="custom-control-primary mr-1"
                :checked="selectedDays.length === 7"
                @change="selectedDays.length !== 7 ? selectedDays = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'] : selectedDays = []"
              >
                All days
              </b-form-checkbox>
              <b-form-checkbox
                v-for="day in dayOptions"
                :key="day.value"
                v-model="selectedDays"
                :value="day.value"
                class="custom-control-primary mr-1"
              >
                {{ day.label }}
              </b-form-checkbox>
            </div>
          </b-form-group>
          <b-form-group
            v-if="selectedDays.includes('sa')"
            label="Saturday time"
            label-for="saturday-time"
            class="mt-2"
          >
            <b-row>
              <b-col cols="5">
                <flat-pickr
                  v-model="saturdayTime[0].start"
                  placeholder="From"
                  class="form-control"
                  :config="{ enableTime: true, noCalendar: true, time_24hr: true, minTime: '07:00' }"
                />
              </b-col>
              <b-col cols="5">
                <flat-pickr
                  v-model="saturdayTime[0].end"
                  placeholder="To"
                  class="form-control"
                  :config="{ enableTime: true, noCalendar: true, time_24hr: true, minTime: saturdayTime[0].start }"
                />
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
        <b-col>
          <!-- Time frames -->
          <b-form-group
            :label="$t('Time')"
            label-for="time"
          >
            <teacher-time-frame
              :set-default-hieght="true"
              :items-prop="userData.time_frames.filter(el => el.days === 'all')"
              @bind-time-frames="val => userData.time_frames = val"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Header: Personal Info -->
      <div class="d-flex mt-2">
        <feather-icon
          icon="MapPinIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          {{ $t('Address') }}
        </h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-row class="mt-1">

        <!-- Field: Address Line 1 -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Address Line"
            label-for="address-line"
          >
            <b-form-input
              id="address-line"
              v-model="userData.address"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Address Line 2 -->
        <!-- <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Address Line 2"
            label-for="address-line-2"
          >
            <b-form-input
              id="address-line-2"
              v-model="userData.addressLine2"
              placeholder="Los Santos"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Postcode -->
        <!-- <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('Postcode')"
            label-for="postcode"
          >
            <b-form-input
              id="postcode"
              v-model="userData.postcode"
              type="number"
              placeholder="597626"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: City -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="('Postcode - City')"
            label-for="postcode"
          >
            <div class="d-flex justify-content-start">
              <b-form-input
                id="postcode"
                v-model="userData.postcode"
                style="width: 30%"
                class="mr-25"
              />
              <b-form-input
                id="city"
                v-model="userData.city"
                style="width: 70%"
              />
            </div>
          </b-form-group>
          <!-- <b-form-group
              :label="$t('City')"
              label-for="city"
            >
              <b-form-input
                id="city"
                v-model="userData.city"
                style="width: 70%"
              />
            </b-form-group> -->
        </b-col>

        <!-- Field: State -->
        <!-- <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="State"
            label-for="state"
          >
            <b-form-input
              id="state"
              v-model="userData.state"
              placeholder="Manhattan"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Country -->
        <!-- Country -->
        <b-col
          cols="12"
          md="4"
          lg="4"
        >
          <b-form-group
            label-for="Country"
            label="country"
          >
            <v-select
              v-model="userData.country"
              :reduce="x => x.name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="countryOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSave"
          >
            {{ $t('Save Changes') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import VSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TeacherTimeFrame from '@/views/admin/teachers-list/TeacherTimeFrame.vue'
import { useToast } from 'vue-toastification/composition'
import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, flatPickr, BFormInput, BButton, BFormCheckbox, TeacherTimeFrame, VSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      levelOptions: [],
      countryOptions: [],
    }
  },
  mounted() {
    this.getCountries()
    if (this.userData.role === 'teacher') {
      this.getLevels()
      this.selectedDays = this.userData.days.split(',')
      if (this.userData.time_frames.length > 0 && this.userData.time_frames.find(el => el.days === 'sa')) {
        this.saturdayTime = [{
          start: this.userData.time_frames.find(el => el.days === 'sa').start,
          end: this.userData.time_frames.find(el => el.days === 'sa').end,
          days: 'sa',
        }]
      }
    }
  },
  methods: {
    getLevels() {
      store.dispatch('teacherStoreModule/getLevels')
      .then(res => {
        this.levelOptions = res.data
        const codes = this.userData.levels.split(',')
        this.selectedLevels = this.levelOptions.filter(level => codes.includes(level.code))
        console.log(this.selectedLevels)
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    getCountries() {
      this.$http.get('/countries/list?perPage=600').then(res => { this.countryOptions = res.data.rows })
    },
  },
  setup(props, { emit }) {
    const dayOptions = [
      { label: 'Mo', value: 'mo' },
      { label: 'Tu', value: 'tu' },
      { label: 'We', value: 'we' },
      { label: 'Th', value: 'th' },
      { label: 'Fr', value: 'fr' },
      { label: 'Sa', value: 'sa' },
      { label: 'Su', value: 'su' },
    ]

    const toast = useToast()

    const languageOptions = [
      'English',
      'Spanish',
      'French',
      'Russian',
      'German',
      'Arabic',
      'Sanskrit',
    ]

    const genderOptions = [
      { text: 'Male', value: 'male' },
      { text: 'Female', value: 'female' },
    ]
    const saturdayTime = ref([{
      start: '',
      end: '',
      days: 'sa',
    }])

    const contactOptionsOptions = ['Email', 'Message', 'Phone']
    const selectedLevels = ref([])
    const selectedDays = ref([])
    const onSave = () => {
      const payload = { ...props.userData }
      payload.user_id = router.currentRoute.params.id
      if (payload.time_frames.length && payload.time_frames.length > 0) payload.time_frames = payload.days.includes('sa') ? payload.time_frames.concat(saturdayTime.value) : payload.time_frames.value
      if (payload.days) payload.days = selectedDays.value.join(',')
      if (payload.levels) payload.levels = selectedLevels.value
      delete payload.avatar
      store.dispatch('userStoreModule/updateUser', payload)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'User informations had been updated',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          // router.back()
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    return {
      selectedDays,
      selectedLevels,
      saturdayTime,
      dayOptions,
      languageOptions,
      genderOptions,
      contactOptionsOptions,
      onSave,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
