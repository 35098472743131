<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('Add New Teacher') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ invalid }"
        ref="addTeacherForm"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit()"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              :label="$t('Name')"
              label-for="week count"
            >
              <b-form-input
                v-model="name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('Name')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Code -->
          <validation-provider
            #default="validationContext"
            name="Code"
            rules="required|max:6"
          >
            <b-form-group
              :formatter="formatter(code)"
              :label="$t('Code')"
              label-for="week count"
            >
              <b-form-input
                v-model="code"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('Code')"
                :class="errors.code[0] ? 'is-invalid' : ''"
                @keyup="errors.code = []"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <small
                v-if="errors"
                class="text-danger"
              >{{ errors.code[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Phone  -->
          <validation-provider
            #default="validationContext"
            name="Phone"
            rules="required"
          >
            <b-form-group
              :label="$t('Phone')"
              label-for="phone"
            >
              <b-form-input
                v-model="phone"
                autofocus
                type="number"
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('Phone')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email  -->
          <validation-provider
            #default="validationContext"
            name="Email"
            :rules="emailDomainName ? 'required' : 'required|email'"
          >
            <b-form-group
              :label="$t('Email')"
              label-for="email"
            >
              <div :class="emailDomainName ? 'input-group' : ''">
                <b-form-input
                  v-model="emailValue"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  :placeholder="('Email')"
                  :class="errors.email[0] ? 'is-invalid' : ''"
                  @keyup="errors.email = []"
                />
                <!-- <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2"> -->
                <span v-if="emailDomainName" class="input-group-text">{{ emailDomainName }}</span>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <small
                  v-if="errors"
                  class="text-danger"
                >{{ errors.email[0] }}</small>
              </div>
            </b-form-group>
          </validation-provider>

          <!-- Levels checkbox -->
          <b-form-group
            :label="$t('Levels')"
            label-for="levels"
          >
            <div class="d-flex align-items-center">
              <b-form-checkbox
                :checked="selectedLevel.length === 4"
                class="custom-control-primary mr-1"
                @change="selectedLevel.length !== 4 ? selectedLevel = levels: selectedLevel = []"
              >
                All levels
              </b-form-checkbox>
              <b-form-checkbox
                v-for="level in levels"
                :key="level.code"
                v-model="selectedLevel"
                :value="level"
                class="custom-control-primary mr-1"
              >
                {{ level.code }}
              </b-form-checkbox>
            </div>
          </b-form-group>

          <!-- ONline? -->
          <b-form-group
            :label="('Type')"
            label-for="type"
          >
            <v-select
              v-model="selectedOnline"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="onlineOptions"
              :clearable="false"
            />
          </b-form-group>

          <!-- Status -->
          <b-form-group
            :label="('Available')"
            label-for="available"
          >
            <v-select
              v-model="selectedStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :clearable="false"
            />
          </b-form-group>
          <!-- Days -->
          <b-form-group
            :label="$t('Days')"
            label-for="days"
          >
            <div class="demo-inline-spacing">
              <b-form-checkbox
                :checked="selectedDay.length === 7"
                class="custom-control-primary mr-1"
                @change="selectedDay.length !== 7 ? selectedDay = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'] : selectedDay = []"
              >
                All days
              </b-form-checkbox>
              <b-form-checkbox
                v-for="day in dayOptions"
                :key="day.value"
                v-model="selectedDay"
                :value="day.value"
                class="custom-control-primary mr-1"
              >
                {{ day.label }}
              </b-form-checkbox>
            </div>
          </b-form-group>
          <b-form-group
            v-if="selectedDay.includes('sa')"
            label="Saturday time"
            label-for="saturday-time"
            class="mt-2"
          >
            <b-row>
              <b-col cols="5">
                <validation-provider
                  #default="validationContext"
                  name="Time from"
                  rules="required"
                >
                  <flat-pickr
                    v-model="saturdayTime.from"
                    placeholder="From"
                    class="form-control"
                    :config="{ enableTime: true, noCalendar: true, time_24hr: true, minTime: '07:00' }"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col cols="5">
                <validation-provider
                  #default="validationContext"
                  name="Time from"
                  rules="required"
                >
                  <flat-pickr
                    v-model="saturdayTime.to"
                    placeholder="To"
                    class="form-control"
                    :config="{ enableTime: true, noCalendar: true, time_24hr: true, minTime: saturdayTo }"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form-group>

          <!-- Time frames -->
          <b-form-group
            :label="$t('Time')"
            label-for="time"
          >
            <teacher-time-frame @bind-time-frames="val => selectedTime = val" />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="invalid"
            >
              {{ $t('Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox, BRow, BCol,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, alphaNum, email, max,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import TeacherTimeFrame from './TeacherTimeFrame.vue'

export default {
  components: {
    TeacherTimeFrame,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    BRow,
    BCol,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    levels: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      max,
      alphaNum,
      email,
      countries,
      teacherAvailability: ['Yes', 'No'],
      statusOptions: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
      onlineOptions: [{ label: 'Online', value: 'yes' }, { label: 'Classroom', value: 'no' }, { label: 'Hybrid', value: 'hybrid' }],
      emailDomainName: process.env.VUE_APP_EMAIL_DOMAIN,
    }
  },
  /* eslint-disable camelcase */
  setup(props, { emit }) {
    const selectedOnline = ref({ label: 'Classroom', value: 'no' })
    const selectedStatus = ref({ label: 'Yes', value: 'yes' })
    const selectedLevel = ref([])
    const selectedDay = ref([])
    const selectedTime = ref([])
    const name = ref('')
    const code = ref('')
    const phone = ref('')
    const emailValue = ref('')
    const errors = ref({
      email: [],
      code: [],
    })
    const saturdayTime = ref({
      from: '',
      to: '',
      days: 'sa',
    })
    const dayOptions = ref([
      { label: 'Mo', value: 'mo' },
      { label: 'Tu', value: 'tu' },
      { label: 'We', value: 'we' },
      { label: 'Th', value: 'th' },
      { label: 'Fr', value: 'fr' },
      { label: 'Sa', value: 'sa' },
      { label: 'Su', value: 'su' },
    ])
    // const comments = ref('')

    const formatter = input => {
      code.value = input.toUpperCase()
    }
    // const getSuffix = value => {
    //   if (value.charAt(value.length - 1) === '@') return
    // }

    const resetRegistrationData = () => {
      name.value = null
      code.value = ''
      phone.value = null
      emailValue.value = null
      selectedLevel.value = []
      selectedOnline.value = { label: 'Classroom', value: 'no' }
      selectedStatus.value = { label: 'Yes', value: 'yes' }
      selectedDay.value = []
      selectedTime.value = []
    }
    // eslint-disable-next-line func-names
    const onSubmit = function () {
      this.$refs.addTeacherForm.validate().then(success => {
        if (success) {
          const teacherData = {
          name: name.value,
          code: code.value,
          phone: phone.value,
          email: this.emailDomainName ? `${emailValue.value}${this.emailDomainName}` : emailValue.value,
          levels: selectedLevel.value,
          days: selectedDay.value.join(','),
          time_frames: selectedDay.value.includes('sa') ? selectedTime.value.concat(saturdayTime.value) : selectedTime.value,
          online: selectedOnline.value.value,
          available: selectedStatus.value.value,
          // comments: comments.value,
        }
        store.dispatch('teacher/addTeacher', teacherData)
          .then(() => {
            resetRegistrationData()
            emit('refetch-data')
            emit('update:is-add-new-user-sidebar-active', false)
          }).catch(error => {
            errors.value = error.response.data.errors
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRegistrationData)

    return {
      saturdayTime,
      dayOptions,
      onSubmit,
      name,
      code,
      phone,
      emailValue,
      // comments,

      // Select
      selectedOnline,
      selectedStatus,
      selectedLevel,
      selectedDay,
      selectedTime,

      formatter,
      // getSuffix,

      refFormObserver,
      getValidationState,
      resetForm,
      errors,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
