<template>

  <div>

    <!-- Filters -->
    <courses-list-add
      v-if="isAddActive"
      :isAddActive.sync="isAddActive"
      :masters="masters.rows"
      :cities="cities"
      :classrooms="classrooms"
      :teachers="teachers"
      :TofDArray="TofDArray"
      :statusArray="statusArray"
      @refetch-data="refetchData"
    />
    <!-- edit -->
    <courses-list-add
      v-if="isAddsimilarActive"
      :isAddActive.sync="isAddsimilarActive"
      :masters="masters.rows"
      :cities="cities"
      :classrooms="classrooms"
      :teachers="teachers"
      :TofDArray="TofDArray"
      :statusArray="statusArray"
      :currentCourseData="currentCourseData"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <!-- one modal for all multiple rows actions -->
            <b-modal
              :id="'modalquestion'"
              :title="capitalizeFirstLetter(modalAction) + ' courses'"
              ok-title="Accept"
              @ok="multipleRowActions(selectedRows, modalAction)"
            >
              <b-card-text>
                <h5>Are you sure you want to {{ modalAction }} <strong> this courses ?</strong></h5>
              </b-card-text>
            </b-modal>

            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-if="selectedRows.length !== 0"
                v-b-modal.modalquestion
                variant="primary"
                class="d-inline-block mr-1"
                @click="modalAction = 'delete'"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>

              <b-button
                v-if="selectedRows.length !== 0"
                v-b-modal.modalquestion
                variant="primary"
                class="d-inline-block mr-1"
                @click="modalAction = 'archive'"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>

              <b-button
                v-if="selectedRows.length !== 0"
                v-b-modal.modalquestion
                variant="primary"
                class="d-inline-block mr-1"
                @click="modalAction = 'hide'"
              >
                <feather-icon icon="XIcon" />
              </b-button>

              <b-button
                v-if="selectedRows.length !== 0"
                v-b-modal.modalquestion
                variant="primary"
                class="d-inline-block mr-1"
                @click="modalAction = 'activate'"
              >
                <feather-icon icon="MoveIcon" />
              </b-button>
              <b-button
                variant="primary"
                class="d-inline-block mr-1"
                @click="isAddActive = true"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refCourseListTable"
        class="position-relative"
        :items="fetchCourses"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Selected -->
        <template #cell(selected)="data">
          <b-form-checkbox
            v-model="selectedRows"
            :value="data.item.id"
            name="selected-rows"
            @change="onRowSelected"
          />
        </template>

        <!-- Column: User -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
            <!--            <b-link-->
            <!--              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"-->
            <!--              class="font-weight-bold d-block text-nowrap"-->
            <!--            >-->
            {{ data.item.id }}
            <!--            </b-link>-->
          </b-media>
        </template>

        <!-- Column: visa -->
        <template #cell(course)="data">
          <b>{{ data.item.course_name }}</b>
        </template>
        <!-- Column: course dates -->
        <template #cell(date_start)="data">
          <span class="badge badge-pill badge-light-secondary">{{ formatDateTime(data.item.date_end) }} - {{ formatDateTime(data.item.date_end) }}</span>
        </template>

        <!-- Column: course times -->
        <template #cell(time_start)="data">
          <span class="badge badge-pill badge-light-secondary">{{ data.item.time_from }} - {{ data.item.time_to }}</span>
        </template>

        <!-- Column: teacher -->
        <template #cell(teachers_name)="data">
          <div
            v-if="data.item['Teacher.name']"
            class="avatar bg-light-danger mr-1"
          >
            <div class="avatar-content">
              {{ formatTeacher(data.item['Teacher.name']) }}
            </div>
          </div>
        </template>

        <!-- Column: Role -->
        <template #cell(student)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.student_name }}</span>
          </div>
        </template>

        <!-- Column: visa -->
        <template #cell(visa)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.visa_needed }}
          </b-badge>
        </template>

        <!-- Column: who pays -->
        <template #cell(who_pays)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.who_pays }}
          </b-badge>
        </template>

        <!-- Column: who pays -->
        <template #cell(payer_confirmed)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.payer_confirmed }}
          </b-badge>
        </template>
        <!-- Column: who pays -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: who pays -->
        <template #cell(attendance)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.attendance }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            dropleft
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-b-modal="getModal3Id(data.item.id)"
            >
              <feather-icon icon="PrinterIcon" />
              <span
                class="align-middle ml-50"
                @click="addSimilar(data.item.id)"
              >Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-b-modal="getModal1Id(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

            <!-- basic modal delete-->
            <b-modal
              :id="'modal1'+data.item.id"
              title="Delete Course"
              ok-title="Accept"
              @ok="remove(data.item.id)"
            >
              <b-card-text>
                <h5>Are you sure you want to delete  <strong>{{ data.item.course_name }} ?</strong></h5>
              </b-card-text>
            </b-modal>

            <!-- basic modal archive-->
            <b-modal
              :id="'modal2'+data.item.id"
              title="Basic Modal"
              ok-title="Accept"
              @ok="archive(data.item.id)"
            >
              <b-card-text>
                <h5>Are you sure you want to archive <strong>{{ data.item.course_name }} ?</strong></h5>
              </b-card-text>
            </b-modal>

            <!-- basic modal hide-->
            <b-modal
              :id="'modal4'+data.item.id"
              title="Hide course"
              ok-title="Accept"
              @ok="hideCourse(data.item.id)"
            >
              <b-card-text>
                <h5>Are you sure you want to cancel <strong>{{ data.item.student_name }}'s registration ?</strong></h5>
              </b-card-text>
            </b-modal>

            <!-- basic modal activate-->
            <b-modal
              :id="'modal5'+data.item.id"
              title="Activate Course"
              ok-title="Accept"
              @ok="activateCourse(data.item.id)"
            >
              <b-card-text>
                <h5>Are you sure you want to activate <strong>{{ data.item.course_name }} ?</strong></h5>
              </b-card-text>
            </b-modal>

            <!-- basic modal -->
            <b-modal
              :id="'moda33'+data.item.id"
              title="Basic Modal"
              ok-title="Accept"
              @ok="archive(data.item.id)"
            >
              <b-card-text>
                <b-form-group
                  label="Template"
                  label-for="template"
                >
                  <b-form-input
                    v-model="query1"
                    autofocus
                    placeholder="Template"
                    @keyup="autoCompleteTemplate(query1)"
                  />
                  <v-select
                    v-if="templates.length"
                    v-model="selectedTemplate"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="templates"
                  />
                </b-form-group>
              </b-card-text>
            </b-modal>

            <!-- basic modal not used-->
            <b-modal
              :id="'modal333'+data.item.id"
              title="Hide course"
              ok-title="Accept"
              @ok="hideCourse(data.item.id)"
            >
              <b-card-text>
                <b-form-group
                  label="Course"
                  label-for="course"
                >
                  <b-form-input
                    v-model="query2"
                    autofocus
                    placeholder="Course"
                    @keyup="autoCompleteCourse(query2)"
                  />
                  <v-select
                    v-if="courses.length"
                    v-model="selectedCourse"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="course_name"
                    :options="courses"
                  />
                </b-form-group>
              </b-card-text>
            </b-modal>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BFormInput, BButton, BTable, BMedia,
  // BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import CoursesListAdd from './PackagesListAdd.vue'
// import ModalCourse from '../courses-view/ModalCourse.vue'
import usePackagesList from './usePackagesList'
import coursesStoreModule from '../coursesStoreModule'
// import Course from '../../Course.vue'
// import CourseListSideFilter from './PackageListFilter.vue'

export default {
  components: {
    // Course,
    CoursesListAdd,
    // CourseListSideFilter,
    // ModalCourse,
    BFormCheckbox,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    // BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormGroup,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    /* eslint-disable camelcase */
    const getModal1Id = id => `modal1${id}`
    const getModal2Id = id => `modal2${id}`
    const getModal3Id = id => `modal3${id}`
    const getModal4Id = id => `modal4${id}`
    const getModal5Id = id => `modal5${id}`

    const USER_APP_STORE_MODULE_NAME = 'app-courses'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, coursesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const onRowSelected = data => {
      console.log(data)
    }

    const multipleRowActions = (ids, action) => {
      console.log(action)
      if (action === 'activate') {
        activateCourse(ids)
      }
      if (action === 'delete') {
        remove(ids)
      }
      if (action === 'archive') {
        archive(ids)
      }
      if (action === 'hide') {
        hideCourse(ids)
      }
    }

    const modalAction = ref('')
    const capitalizeFirstLetter = string => {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const isFilterActive = ref(false)
    const isAddActive = ref(false)
    const isAddsimilarActive = ref(false)
    const currentCourseData = ref(null)
    const isModalActive = ref(false)
    const singleData = ref({})
    const spinner = ref(false)
    const msgs = ref([])
    const UsersToAdd = ref([])
    const UsersAlreadyAdded = ref([])
    const ModalData = id => {
      isModalActive.value = true
      store.dispatch('app-courses/getSCourse', id)
        .then(() => {
          singleData.value = store.state.coursesStoreModule.singleCourse
          const sDays = singleData.value.days
          const daysArr = sDays.split(',')
          singleData.value.days = daysArr
          if (singleData.value.conversation === null) {
            msgs.value = []
            UsersToAdd.value = []
            UsersAlreadyAdded.value = []
          } else {
            if (typeof singleData.value.conversation !== 'undefined') {
              msgs.value = singleData.value.conversation.messages
            }
            UsersToAdd.value = singleData.value.users_To_Add
            UsersAlreadyAdded.value = singleData.value.users_Already_In
          }
        }).catch(error => console.log(error))
    }

    const addSimilar = id => {
        store.dispatch('app-courses/getSCourse', id)
          .then(() => {
            currentCourseData.value = store.state.coursesStoreModule.singleCourse
          })
          .then(() => {
            isAddsimilarActive.value = true
          })
          .catch(error => console.log(error))
    }

    const loadSpinner = () => {
      // open
      spinner.value = true
      setTimeout(() => {
        spinner.value = false
      }, 1000)
    }

    const roleOptions = [
      { label: 'Admin', value: 'administrator' },
      { label: 'Student', value: 'student' },
      { label: 'Teacher', value: 'teacher' },
      { label: 'Agent', value: 'agent' },
      { label: 'Company', value: 'company' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]
    const query1 = ref('')
    const query2 = ref('')
    const selectedTemplate = ref({})
    const selectedCourse = ref({})
    const templates = ref([])
    const courses = ref([])
    // Use toast
    const toast = useToast()
    const {
      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCourseListTable,
      refetchData,
      number,
      group,
      mastercourse_id,
      teacher_id,
      classroom_id,
      date_start_from,
      date_start_to,
      date_end_from,
      date_end_to,
      fStatus,
      time_of_day,
      cities,
      masters,
      classrooms,
      teachers,
      statusArray,
      TofDArray,
      currentUser,
      selectedRows,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = usePackagesList()
    const regToWaitingList = id => {
      store.dispatch('app-courses/regToWaitingList', id)
      .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while confirming registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const formatDateTime = dateTime => {
      const timestamp = new Date(dateTime).getTime()
      const todate = new Date(timestamp).getDate()
      const tomonth = new Date(timestamp).getMonth() + 1
      const toyear = new Date(timestamp).getFullYear()
      const original_date = `${todate}-${tomonth}-${toyear}`
      return original_date
    }
    const formatTeacher = teacherName => {
      const tName = teacherName.split(' ')
      let name = ''
      tName.forEach(s => {
        name += s.charAt(0)
      })
      // const timestamp = new Date(dateTime).getTime()
      // const todate = new Date(timestamp).getDate()
      // const tomonth = new Date(timestamp).getMonth() + 1
      // const toyear = new Date(timestamp).getFullYear()
      // const original_date = `${tomonth}-${todate}-${toyear}`
      return name
    }
    const sendPTest = pId => {
      store.dispatch('app-courses/sendPTest', pId)
      .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while confirming registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const hideCourse = id => {
      // const course_id = selectedCourse.value.id
      store.dispatch('app-courses/hideCourse', id)
      .then(response => {
        refetchData()
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while hiding course',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const autoCompleteCourse = search => {
      courses.value = []
      store.dispatch('app-courses/autoCompleteCourses', search)
      .then(response => { courses.value = response.data })
    }
    const autoCompleteTemplate = search => {
      templates.value = []
      store.dispatch('app-courses/autoComplete', search)
      .then(response => {
        templates.value = response.data
      })
    }

    const generateContract = id => {
      store.dispatch('app-courses/generateContract', id)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while creating contract registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const activateCourse = id => {
      store.dispatch('app-courses/activateCourseAction', id)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while activating course',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const archive = confId => {
      refetchData()
      store.dispatch('app-courses/archiveCourse', confId)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Course Has Been Archived',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while archiving course',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }

    const cancel = cancelId => {
      refetchData()
      store.dispatch('app-courses/cancelRegistration', cancelId)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Registration Has Been canceled',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while canceling registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const remove = regId => {
      store.dispatch('app-courses/deleteCourse', regId)
        .then(() => {
          refetchData()
          toast({
            component: ToastificationContent,
            props: {
              title: 'Course Has Been removed',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while removing course',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }

    /* console.log(masters) */

    return {
      // Modal ids
      getModal1Id,
      getModal2Id,
      getModal3Id,
      getModal4Id,
      getModal5Id,
      ModalData,
      addSimilar,
      singleData,
      loadSpinner,
      spinner,

      // Actions
      remove,
      cancel,
      archive,
      activateCourse,
      generateContract,
      hideCourse,
      sendPTest,
      regToWaitingList,

      // autocomplete
      query1,
      query2,
      selectedTemplate,
      selectedCourse,
      templates,
      courses,
      autoCompleteTemplate,
      autoCompleteCourse,
      formatDateTime,
      formatTeacher,

      // Sidebar
      isFilterActive,
      // Add modal
      isAddActive,
      // infos modal
      isModalActive,
      isAddsimilarActive,
      currentCourseData,

      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCourseListTable,
      refetchData,
      number,
      group,
      mastercourse_id,
      teacher_id,
      classroom_id,
      date_start_from,
      date_start_to,
      date_end_from,
      date_end_to,
      fStatus,
      time_of_day,
      cities,
      masters,
      classrooms,
      teachers,
      statusArray,
      TofDArray,
      msgs,
      UsersToAdd,
      UsersAlreadyAdded,
      currentUser,
      selectedRows,
      modalAction,
      capitalizeFirstLetter,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      onRowSelected,
      multipleRowActions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  /* background-color: #f7f7f7; */
}
.input:focus {
  outline: none;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
