<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    <!-- Left Col -->
    <b-navbar-nav class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!-- <b-button
        v-if="$store.state.appConfig.pendingRegistrationsList.length !== 0"
        v-b-modal.reg-modal
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mr-1"
        variant="danger"
        pill
        size="sm"
      >
        <feather-icon
          class="mr-50"
          icon="LoaderIcon"
        />
        <span>You have some pending registrations</span>
      </b-button> -->
      <b-button
        v-if="$store.state.appConfig.otherAccount && (userData.role === 'administrator' || (userData.role !== 'administrator' && uniqueSession === 'true'))"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="dark"
        pill
        size="sm"
        class="mr-50"
        @click="login($store.state.appConfig.otherAccount.id)"
      >
        <b-spinner
          v-if="isBusy"
          class="mr-50"
          small
        />
        <feather-icon
          v-if="!isBusy"
          class="mr-50"
          icon="LogInIcon"
        />
        <span>Login as {{ $store.state.appConfig.otherAccount.role }}</span>
      </b-button>
      <b-button
        v-if="$store.state.appConfig.studentPayments.availableCount > 0"
        v-b-modal.payment-modal
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        pill
        size="sm"
        style="background: #ffe9d9 !important; border: #ffe9d9"
      >
        <feather-icon
          class="text-danger mr-50"
          icon="AlertCircleIcon"
        />
        <span class="text-secondary">Are you still interested in this course?</span>
      </b-button>
      <b-button
        v-if="isSurvey"
        v-b-modal.survey-modal
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="ml-1"
        pill
        size="sm"
        style="background: #ffe9d9 !important; border: #ffe9d9"
      >
        <feather-icon
          class="mr-50"
          icon="CheckSquareIcon"
        />
        <span>Survey reminder</span>
      </b-button>
      <!-- <b-modal
        id="reg-modal"
        title="Please confirm your registration for the following courses :"
        centered
        no-close-on-backdrop
        hide-footer
        size="xl"
      >
        <student-registration />
      </b-modal> -->
      <b-modal
        v-if="$store.state.appConfig.studentPayments.availableCount > 0"
        id="payment-modal"
        centered
        no-close-on-backdrop
        hide-footer
        size="xl"
      >
        <template #modal-title>
          <strong class="m-1">Are you still interested in these courses?</strong>
        </template>
        <!-- <h4>Thank you for your interest in our Norwegian course. In order to secure your place in the group, please fullfill your purchase.</h4> -->
          <h5 class="p-1 mb-1">
            <feather-icon
              icon="CheckCircleIcon"
              class="mx-50 text-success"
            />
            <span>
              Thank you for your interest in our course. In order to secure your place in the group, please fullfill your purchase.
            </span>
            </h5>
          <!-- alert -->
          <b-alert
            class="p-1"
            show
            style="background: #f1f4ff !important"
          >
            <div class="alert-body">
              <feather-icon
                icon="InfoIcon"
                class="mr-25 text-info"
              />
              <span class="text-secondary">
                Link to payment is active as long as there are still available seats in the group. Hurry up, do not miss the chance of participating in your selected course!
              </span>
            </div>
          </b-alert>
          <b-alert
            class="p-1"
            show
            style="background: #f1f4ff !important"
          >
            <div class="alert-body">
              <feather-icon
                icon="InfoIcon"
                class="mr-25 text-info"
              />
              <span class="text-secondary">
                If you already have paid or your company is paying for you, please let our dedicated reception team know - they will secure your place in the course.
              </span>
            </div>
          </b-alert>
        <student-payments />
      </b-modal>
      <b-modal
        id="survey-modal"
        title="Please take time to fill our survey to improve our courses"
        centered
        no-close-on-backdrop
        hide-footer
      >
        <b-tabs>
          <b-tab
            v-if="survey.survey1.length !== 0"
            title="First survey"
          >
            <b-list-group flush>
              <b-list-group-item
                v-for="survey in survey.survey1"
                :key="survey.survey.id"
                @click="getSurvey(survey, 'survey1')"
                style="cursor: pointer"
              >
                <span>
                  {{ survey.course.code }}({{ survey.course.group }})
                </span>
                <small class="d-block text-secondary">{{ survey.course.teacher.name }}</small>
              </b-list-group-item>
            </b-list-group>
          </b-tab>
          <b-tab
            v-if="survey.survey2.length !== 0"
            title="End course survey"
          >
            <b-list-group flush>
              <b-list-group-item
                v-for="survey in survey.survey2"
                :key="survey.survey.id"
                @click="getSurvey(survey, 'survey2')"
                style="cursor: pointer"
              >
                <span>
                  {{ survey.course.code }}({{ survey.course.group }})
                </span>
              </b-list-group-item>
            </b-list-group>
          </b-tab>
        </b-tabs>
      </b-modal>
      <b-modal
        id="survey-modal-questions"
        :title="$t('Help up make your course even better!')"
        centered
        no-close-on-backdrop
        hide-footer
        size="xl"
      >
        <student-survey
          :surveyData="surveyData"
          @close-survey-modal="$bvModal.hide('survey-modal-questions')"
        />
      </b-modal>
      <!-- <bookmarks /> -->
    </b-navbar-nav>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <notification-dropdown />
      <messages-dropdown />
      <!-- <bookmarks /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BButton, BSpinner, BAlert,
  BTabs, BTab, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import jwt from 'jsonwebtoken'
// import StudentRegistration from '@/views/student/dashboard/StudentRegistration.vue'
import StudentSurvey from '@/views/student/dashboard/StudentSurvey.vue'
import StudentPayments from '@/views/student/dashboard/StudentPayments.vue'
// import DarkToggler from './components/DarkToggler.vue'
// import Bookmarks from './components/Bookmarks.vue'
import UserDropdown from './components/UserDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import MessagesDropdown from './components/MessagesDropdown.vue'
// import Locale from './components/Locale.vue'

export default {
  components: {
    // StudentRegistration,
    StudentPayments,
    StudentSurvey,
    BLink,
    BButton,
    BSpinner,
    BAlert,

    // Navbar Components
    // Locale,
    // Bookmarks,
    NotificationDropdown,
    MessagesDropdown,
    BNavbarNav,
    // DarkToggler,
    UserDropdown,
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      altToken: localStorage.altToken,
      survey: {
        survey1: [],
        survey2: [],
      },
      surveyData: {},
      isSurvey: false,
      userData: getUserData(),
      uniqueSession: localStorage.uniqueSession,
      isBusy: false,
    }
  },
  created() {
    this.getStuff()
    if (this.userData.role === 'student') {
      this.$http.post('/surveys/list').then(res => {
        this.survey = res.data
        if (this.survey.survey1.length !== 0 || this.survey.survey2.length !== 0) { this.isSurvey = true }
      })
    }
  },
  mounted() {
    if (localStorage.otherAccount) this.$store.state.appConfig.otherAccount = JSON.parse(localStorage.otherAccount)
  },
  methods: {
    login(id) {
      this.isBusy = true
      this.$http.post(`/login/as/student/${id}`).then(res => {
        const decodedToken = jwt.decode(res.data.accessToken)
        localStorage.accessToken = res.data.accessToken
        localStorage.uniqueSession = 'true'
        if (typeof decodedToken !== 'undefined') {
          localStorage.userData = JSON.stringify(decodedToken)
          if (decodedToken.language && decodedToken.language.length) {
            this.$i18n.locale = decodedToken.language
          }
          this.$ability.update(decodedToken.ability)
          this.$router.replace(getHomeRouteForLoggedInUser(decodedToken.role, 'fromLogin'))
          .then(() => {
            if (decodedToken.otherAccount) {
              this.$store.state.appConfig.otherAccount = decodedToken.otherAccount
              localStorage.otherAccount = JSON.stringify(this.$store.state.appConfig.otherAccount)
            }
            this.isBusy = false
            // store.state.appConfig.isLoggedIn = true
            // store.state.appConfig.adminLoggedAsStudent = true
            window.location.reload()
          })
        }
      })
    },
    getStuff() {
      if (this.userData.role === 'student') {
        // this.$http.get('/registrations/awaiting/confirmation').then(res => {
        //   if (this.$route.query.params === 'fromLogin' && res.data.length !== 0) { this.$bvModal.show('reg-modal') }
        //   this.$store.state.appConfig.pendingRegistrationsList = res.data
        // })
        this.$http.get('/invoices/awaiting/paid').then(res => {
          if (this.$route.query.params === 'fromLogin' && res.data.length !== 0) { this.$bvModal.show('payment-modal') }
          console.log(res.data.available_count)
          this.$store.state.appConfig.studentPayments.pendingPaymentsList = res.data.payments
          this.$store.state.appConfig.studentPayments.availableCount = res.data.available_count
        })
      }
    },
    getSurvey(survey, type) {
      this.surveyData = { type, data: survey }
      this.$bvModal.show('survey-modal-questions')
    },
  },
}
</script>
