import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import userStoreModule from '@/views/apps/user/userStoreModule'
import reviewStoreModule from '@/views/admin/reviews-list/reviewStoreModule'
import chatStoreModule from '../views/apps/chat/chatStoreModule'
import emailStoreModule from '../views/apps/email/emailStoreModule'
import courseStoreModule from '../views/courses/courseStoreModule'
import coursesStoreModule from '../views/courses/admin/coursesStoreModule'
import registerStoreModule from '../views/courses/registrations/registerStoreModule'
import invoiceStoreModule from '../views/courses/invoices/invoiceStoreModule'
import cityStoreModule from '../views/admin/cities-list/cityStoreModule'
import teacherStoreModule from '../views/admin/teachers-list/teacherStoreModule'
import eventStoreModule from '../views/admin/events-list/eventStoreModule'
import classroomStoreModule from '../views/admin/classrooms-list/classroomStoreModule'
import testsStoreModule from '../views/admin/tests/tests-list/testStoreModule'
import approveHistoryStoreModule from '../views/admin/courses-approval/approveHistoryStoreModule'
import logStoreModule from '../views/admin/log-list/logStoreModule'
import ApprovalStoreModule from '../views/admin/courses-approval/courseStoreModule'
import claimStoreModule from '../views/admin/claim-list/claimStoreModule'

import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    userStoreModule,
    chatStoreModule,
    emailStoreModule,
    courseStoreModule,
    coursesStoreModule,
    registerStoreModule,
    invoiceStoreModule,
    cityStoreModule,
    teacherStoreModule,
    eventStoreModule,
    classroomStoreModule,
    testsStoreModule,
    reviewStoreModule,
    approveHistoryStoreModule,
    logStoreModule,
    ApprovalStoreModule,
    claimStoreModule,
  },
  strict: process.env.DEV,
})
