import axios from '@axios'

export default {
  namespaced: true,
  state: {
    reviews: {
      rows: [],
      totalRecords: 0,
    },
  },
  getters: {},
  /* eslint-disable camelcase */
  mutations: {
    setReviews(state, users) {
      state.reviews = users
    },
    deleteCity(state, userId) {
      const regData = {
        rows: state.reviews.rows.filter(reg => reg.id !== userId),
        totalRecords: state.reviews.totalRecords - 1,
      }
      state.reviews = regData
    },
  },
  actions: {
    fetchUsers(store, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/reviews/list', { params: queryParams })
          .then(response => {
            store.commit('setReviews', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    approveReview(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/review/${id}/approve`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeReview(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/review/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
