<template>
  <span
    variant="transparent"
    @click="skin = isDark ? 'light' : 'dark'"
  >
    <feather-icon
      size="21"
      :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
    />
  </span>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
// import { BButton } from 'bootstrap-vue'

export default {
  components: {
    // BButton,
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
}
</script>
