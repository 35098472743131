<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <courses-list-big-modal
        v-if="isBigModalActive"
        :isBigModalActive.sync="isBigModalActive"
        :classrooms="classrooms"
        :teachers="teachers"
        :masters="masters.rows"
        :cities="cities"
        :TofDArray="TofDArray"
        :currentCourseData="currentCourseData"
        :msgs="msgs"
        :statusArray="statusArray"
        :UsersToAdd="UsersToAdd"
        :UsersAlreadyAdded="UsersAlreadyAdded"
        :currentUser="currentUser"
        :loading="spinner"
        @refetch-data="refetchData"
        @initilize="ModalData($event)"
        @loading="loadSpinner"
        @suggest-classrooms="suggestClassrooms"
        @suggest-teacher="suggestTeacher"
      />

      <div class="m-2">

        <!-- Table Top -->
        <b-row v-if="!studentID">

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          />

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
                debounce="500"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="studentID ? tableColumns : tableColumns1"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>
        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.id }}
            </b-link>
          </b-media>
        </template>
        <!-- Column: Group -->
        <template #cell(group)="data">
          <b-button
            v-if="userData.role === 'administrator'"
            variant="flat-primary"
            :disabled="!data.item.group"
            @click="ModalData(data.item.id, 'group')"
          >
            {{ data.item.group }}
          </b-button>
          <span v-else>{{ data.item.group }}</span>
        </template>
        <!-- Column: Code -->
        <template #cell(code)="data">
          <span>{{ data.item.code }}</span>
        </template>
        <!-- Column: Classroom -->
        <template #cell(classroom)="data">
          <div
            v-if="data.item.classroom"
            class="d-flex align-items-center"
          >
            <span>{{ data.item.classroom.name }}</span>
            <b-button
              v-if="data.item.classroom.link"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat-primary"
              size="sm"
              class="btn-icon p-0 pb-0 ml-50 mb-25"
              target="_blank"
              :href="data.item.classroom.link"
            >
              <feather-icon icon="ExternalLinkIcon" />
            </b-button>
          </div>
        </template>
        <!-- Column: Date -->
        <template #cell(date)="data">
          <div class="font-weight-bold d-block text-nowrap text-capitalize">
            {{ data.item.formated_date_start }} | {{ data.item.formated_date_end }}
          </div>
          <small>{{ data.item.time_from }} - {{ data.item.time_to }}</small>
        </template>
        <!-- Column: Attendance -->
        <template #cell(attendance)="data">
          <div class="font-weight-bold d-block text-nowrap text-capitalize">
            {{ data.item.attendances_hours ? data.item.attendances_hours : 0 }}
          </div>
          <small v-if="data.item.attendances_hours !== 'N/A'">{{ data.item.attendance_percent }}%</small>
        </template>
        <template #cell(status)="data">
          <b-badge
            v-if="!studentID"
            v-b-tooltip.hover.top=""
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.course_status)}`"
            class="text-capitalize"
          >
            {{ data.item.course_status }}
          </b-badge>
          <b-badge
            v-if="studentID"
            v-b-tooltip.hover.top="data.item.status"
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>
        <!-- Column: course notes -->
        <template #cell(comments)="data">
          <b-button
            :id="`popover-target-${data.item.id}`"
            variant="flat-primary"
            size="sm"
            class="btn-icon rounded-circle"
            @click="handleChatClick({model:'registration', id: data.item.registration_id})"
          >
            <feather-icon
              icon="ClipboardIcon"
              class="align-middle text-body"
              badge-classes="bg-danger"
              :badge="data.item.comments_number > 0 ? data.item.comments_number : ''"
              size="16"
            />
          </b-button>
        </template>
        <!-- Column: course Reviews -->
        <template #cell(reviews)="data">
          <span
            v-b-modal="getModalReview(data.item.id)"
            @click="getReview(data.item.id, data.item.my_review)"
          >
            <b-form-rating
              v-model="data.item.reviews"
              size="sm"
              inline
              no-border
              readonly
              variant="warning"
            />
            <!-- @change="addReview(data.item.id, data.item.reviews, null)" -->
          </span>
          <b-modal
            :id="'modalReview'+data.item.id"
            :title="`${data.item.group}`"
            ok-title="Close"
            hide-footer
            centered
            size="lg"
            no-close-on-backdrop
          >
            <div
              v-if="isBusyReview"
              class="text-center d-flex align-items-center justify-content-center mt-3 mb-3"
            >
              <b-spinner
                variant="primary"
                label="Text Centered"
              />
              <strong class="ml-1">{{ $t('Loading...') }}</strong>
            </div>
            <course-reviews
              v-if="!isBusyReview"
              :reviews="reviews"
              :course-id="data.item.id"
              :my-review="data.item.my_review"
              @add-review="addReview"
              @remove-review="removeReview"
              @refetch-reviews="getReview(data.item.id)"
            />
          </b-modal>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            dropleft
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-b-modal="getModal1Id(data.item.id)"
              class="btn-icon rounded-circle"
              :variant="resolveAttendanceVariant(data.item.attendances_hours)"
              :disabled="!data.item.attendance_percent || data.item.attendance_percent === '0'"
            >
              <feather-icon icon="UserCheckIcon" />
              <span class="align-middle ml-50">{{ $t('Check attendance') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="userData.role === 'administrator'"
              v-b-modal="'SessionModal'+data.item.id"
              @click="getTeachers(data.item.id)"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Show sessions</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="userData.role === 'administrator'"
              @click="printAttendance(data.item.id)"
            >
              <feather-icon icon="PrinterIcon" />
              <span class="align-middle ml-50">Print attendance sheet</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="userData.role === 'administrator'"
              @click="ModalData(data.item.id, 'edit')"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="userData.role === 'administrator' && data.item.course_status !== 'Past'"
              v-b-modal="'HideCourseModal'+data.item.id"
            >
              <feather-icon icon="EyeOffIcon" />
              <span class="align-middle ml-50">Hide</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="userData.role === 'administrator' && data.item.status === 'hidden' && data.item.course_status !== 'Past'"
              v-b-modal="'ShowCourseModal'+data.item.id"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Make visible</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item
              v-if="userData.role === 'administrator' && data.item.status !== 'past'"
              v-b-modal="'ArchiveCourseModal'+data.item.id"
            >
              <feather-icon icon="ArchiveIcon" />
              <span class="align-middle ml-50">Archive</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if=" userData.role === 'administrator' && data.item.status === 'past'"
              v-b-modal="'ActivateCourseModal'+data.item.id"
            >
              <feather-icon icon="CheckCircleIcon" />
              <span class="align-middle ml-50">Activate</span>
            </b-dropdown-item> -->
            <b-dropdown-item
              v-if="userData.role === 'student'"
              class="btn-icon rounded-circle"
              :disabled="!data.item.attendance_percent || data.item.attendance_percent === '0' || data.item.attendance_percent < 80"
              @click="downloadCertificate(data.item.registration_id)"
            >
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">{{ $t('Download certificate') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="userData.role === 'student' && data.item.course_status !== 'Past' && data.item.status !== 'pending' && data.item.status !== 'canceled'"
              v-b-modal="getModal2Id(data.item.id)"
              :disabled="data.item.resignation === 1"
              variant="danger"
            >
              <feather-icon icon="SlashIcon" />
              <span class="align-middle ml-50">{{ $t('Cancel course') }}</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item @click="handleChatClick1(data.item.student_id)">
              <feather-icon icon="MessageSquareIcon" />
              <span class="align-middle ml-50">Contact</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="userData.role === 'administrator'"
              v-b-modal="getModal3Id(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('Delete') }}</span>
            </b-dropdown-item> -->
            <!-- <b-dropdown-item
              v-if="userData.role === 'administrator' && data.item.status !== 'confirmed'"
              v-b-modal="getModal4Id(data.item.id)"
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">{{ $t('Confirm registration') }}</span>
            </b-dropdown-item> -->
            <!-- <b-dropdown-item
              v-if="userData.role === 'administrator'"
              v-b-modal="getModal5Id(data.item.id)"
            >
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">{{ $t('Cancel registration') }}</span>
            </b-dropdown-item> -->
            <!-- <b-dropdown-item
              v-if="userData.role === 'administrator'"
              v-b-modal="getModal7Id(data.item.id)"
              @click="getPlacementTest"
            >
              <feather-icon icon="SendIcon" />
              <span class="align-middle ml-50">{{ $t('Send Placement test') }}</span>
            </b-dropdown-item> -->
            <!-- <b-dropdown-item
              v-if="userData.role === 'administrator'"
              v-b-modal="getModal6Id(data.item.id)"
            >
              <feather-icon icon="MoveIcon" />
              <span class="align-middle ml-50">{{ $t('Move student') }}</span>
            </b-dropdown-item> -->
            <!-- <b-dropdown-item
              v-if="userData.role === 'administrator' && (!data.item.attendance_percent || data.item.attendance_percent === '0')"
              @click="generateCertificate(data.item.registration_id)"
            >
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">{{ $t('Download certificate') }}</span>
            </b-dropdown-item> -->
            <!-- <b-dropdown-item
              v-if="userData.role === 'administrator'"
              @click="generateContract(data.item.registration_id)"
            >
              <feather-icon icon="PrinterIcon" />
              <span class="align-middle ml-50">{{ $t('Generate contract') }}</span>
            </b-dropdown-item> -->
            <!-- <b-dropdown-item
              v-if="userData.role === 'administrator'"
              @click="regToWaitingList(data.item.registration_id)"
            >
              <feather-icon icon="ClipboardIcon" />
              <span class="align-middle ml-50">{{ $t('Register to waiting list') }}</span>
            </b-dropdown-item> -->
            <b-modal
              :id="'SessionModal'+data.item.id"
              class="substitute-modal"
              ok-title="Close"
              ok-only
              centered
              size="xl"
              body-class="substitute-modal"
              scrollable
            >
              <template #modal-title>
                <h5> Course : {{ data.item.course_name }} ({{ data.item.group }})
                  <small v-if="data.item.current_teacher"> Current teacher: {{ data.item.current_teacher.name }}</small>
                </h5>
              </template>
              <substitute-list
                :courseId="data.item.id"
                allSessions
                :has-loaded-teachers="hasLoadedTeachers"
              />
            </b-modal>
            <b-modal
              :id="'HideCourseModal'+data.item.id"
              title="Hide course"
              ok-title="Yes"
              cancel-title="No"
              centered
              @ok="hideCourse(data.item.id)"
            >
              <b-card-text>
                <h5>Do you want to hide <strong>{{ data.item.code }} ({{ data.item.group }})</strong> from the website?</h5>
              </b-card-text>
            </b-modal>

            <!-- basic modal activate-->
            <b-modal
              :id="'ActivateCourseModal'+data.item.id"
              title="Activate Course"
              ok-title="Yes"
              cancel-title="No"
              centered
              @ok="activateCourse(data.item.id)"
            >
              <b-card-text>
                <h5>Are you sure you want to activate <strong>{{ data.item.code }} ?</strong></h5>
              </b-card-text>
            </b-modal>
            <!-- basic modal -->
            <b-modal
              :id="'ArchiveCourseModal'+data.item.id"
              title="Archive course"
              centered
              ok-title="Yes"
              cancel-title="No"
              @ok="archive(data.item.id)"
            >
              <b-card-text>
                <h5>Are you sure you want to archive <strong>{{ data.item.code }} ?</strong></h5>
              </b-card-text>
            </b-modal>
            <!-- Detete registration modal -->
            <!-- <b-modal
              :id="'modal3'+data.item.id"
              :title="$t('Delete Registration')"
              ok-title="Accept"
              centered
              @ok="remove(data.item.registration_id)"
            >
              <b-card-text>
                <h5>{{ $t('Are you sure you want to delete') }} <strong>{{ data.item.student_name }}</strong></h5> {{ $t('from') }} <strong>{{ data.item.course_name }}</strong>
              </b-card-text>
            </b-modal> -->
            <!-- <b-modal
              :id="'modal7'+data.item.id"
              title="Send placement test"
              :ok-title="$t('Send')"
              cancel-title="Cancel"
              centered
              size="lg"
              no-close-on-backdrop
              @ok="sendPTest([studentID], selectedTest.id, due_date)"
            >
              <div
                v-if="!isLoadedTests"
                class="text-center d-flex align-items-center justify-content-center"
              >
                <b-spinner
                  variant="primary"
                  label="Text Centered"
                />
              </div>
              <div v-if="isLoadedTests">
                <b-form-group
                  :label="$t('Test')"
                  label-for="test"
                >
                  <v-select
                    v-model="selectedTest"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="tests"
                    :clearable="false"
                  />
                </b-form-group>
                <b-form-group
                  :label="$t('Due date')"
                  label-for="due-date"
                >
                  <flat-pickr
                    v-model="due_date"
                    class="form-control"
                    name="date"
                    :placeholder="$t('Due date')"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss', locale: { firstDayOfWeek: 1 }}"
                  />
                </b-form-group>
              </div>
            </b-modal> -->
            <!-- Confirm registration modal -->
            <!-- <b-modal
              :id="'modal4'+data.item.id"
              title="Confirm registration"
              ok-title="Accept"
              centered
              @ok="confirm(data.item.registration_id)"
            >
              <b-card-text>
                <h5>{{ $t('Are you sure you want to confirm') }} <strong>{{ data.item.student_name }} {{ $t('registration') }}</strong></h5>
              </b-card-text>
            </b-modal> -->
            <!-- Cancel registration modal -->
            <!-- <b-modal
              :id="'modal5'+data.item.id"
              title="Cancel Registration"
              :ok-title="$t('Yes')"
              cancel-title="No"
              centered
              @ok="cancelRegistration(data.item.registration_id, sendEmail)"
            >
              <b-card-text>
                <h5>{{ $t('Are you sure you want to cancel registration of') }} <strong>{{ data.item.student_name }} ?</strong></h5>
              </b-card-text>
              <b-form-checkbox
                v-model="sendEmail"
              >
                Send email
              </b-form-checkbox>
            </b-modal> -->
            <!-- Move student modal -->
            <!-- <b-modal
              :id="'modal6'+data.item.id"
              :title="$t('Move Student')"
              ok-title="Accept"
              centered
              @ok="moveStudent(data.item.registration_id)"
            >
              <b-card-text>
                <b-form-group
                  :label="$t('Course')"
                  label-for="course"
                >
                  <v-select
                    v-model="selectedCourse"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="courses"
                    @keyup.native="autoCompleteCourse($event.target.value)"
                    @click.native="autoCompleteCourse('')"
                  >
                    <span slot="no-options">
                      <div
                        v-if="isTypingCourse"
                        class="text-center d-flex align-items-center justify-content-center"
                      >
                        <b-spinner
                          variant="primary"
                          label="Text Centered"
                        />
                      </div>
                      <div
                        v-if="!isTypingCourse && !courses.length"
                        class="text-center d-flex align-items-center justify-content-center"
                      >
                        No matching records found
                      </div>
                    </span>
                  </v-select>
                </b-form-group>
              </b-card-text>
            </b-modal> -->
          </b-dropdown>
          <!-- Attendance modal -->
          <b-modal
            :id="'modal1'+data.item.id"
            :title="userData.role === 'student' ? 'My Attendance for course' : 'Attendance list'"
            ok-title="Save"
            centered
            size="xl"
            body-class="attendance-modal"
            scrollable
            hide-footer
          >
            <course-student-attendance
              role="admin"
              :course-id="data.item.id"
              :student-id="studentID"
            />
          </b-modal>
          <!-- basic modal -->
          <b-modal
            :id="'modal2'+data.item.id"
            title="Cancel Course Enrollment"
            centered
            ok-title="Confirm"
            :ok-disabled="!reason"
            size="lg"
            no-close-on-backdrop
            @ok="cancel(data.item.id, reason.value, anotherReason)"
          >
            <b-card-text>
              Dear <b>{{ userData.fullName }}</b> ,<br>
              We regret to hear that you would like to cancel your enrollment in <strong>{{ data.item.code }}</strong>.<br>
              Before we proceed with the cancellation, could you please let us know the reason for your decision?
            </b-card-text>
            <b-form-group
              label="Reason for cancellation"
              label-for="reason"
            >
              <v-select
                v-model="reason"
                label="name"
                placeholder="Reason for cancellation"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="reasons"
                class="mb-1"
              />
            </b-form-group>
            <b-form-group
              v-if="reason && reason.value === 'other'"
              label="Other reason"
              label-for="label-reason"
            >
              <b-form-textarea
                v-model="anotherReason"
                class="d-inline-block mr-1"
                :placeholder="$t('Another reason')"
                rows="4"
                maxlength="500"
              />
            </b-form-group>
          </b-modal>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="!isBusy"
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <div
      v-if="isChatActive"
      class="notesModule shadow-lg"
    >
      <div
        style="background: #fff"
        class="pb-1"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="light"
          size="sm"
          class="btn-icon shadow-sm ml-auto "
          style="display: block; transform: translate(8px, -2px);"
          @click="isChatActive = false"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
      <chat-module
        :key="chatModelId.id"
        :model-id="chatModelId.id"
        model-type="registration"
        @decrease-notes="refetchData"
        @notes-increament="refetchData"
      />
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BLink,
  BBadge, BPagination, BModal, BDropdown, BDropdownItem, BSpinner, BFormTextarea, VBTooltip,
  BFormRating, BCardText, BFormGroup, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted, ref, watch } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ChatModule from '@/views/admin/ChatModule.vue'
import CourseReviews from '@/views/courses/CourseReviews.vue'
import CoursesListBigModal from '@/views/courses/admin/courses-list/CoursesListBigModal.vue'
import SubstituteList from '@/views/admin/courses-approval/SubstitutesList.vue'
import useCoursesList from './useCoursesList'
import courseStoreModule from './courseStoreModule'
import CourseStudentAttendance from './CourseStudentAttendance.vue'

export default {
  components: {
    SubstituteList,
    CoursesListBigModal,
    CourseStudentAttendance,
    CourseReviews,
    ChatModule,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    BModal,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BFormTextarea,
    BFormRating,
    BCardText,
    BFormGroup,
    BButton,

    vSelect,
  },
  /* eslint-disable camelcase, vue/prop-name-casing */
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    studentID: {
      type: Number,
      default: null,
    },
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      reason: null,
      anotherReason: '',
      reasons: [
        { name: 'I will register later', value: 'I will register later' },
        { name: 'I can\'t attend', value: 'I can\'t attend' },
        { name: 'The service is bad', value: 'The service is bad' },
        { name: 'Other reason', value: 'Other reason' },
      ],
    }
  },
  methods: {
    closeAttendanceModal(id) {
      this.$bvModal.hide(`modal1${id}`)
    },
  },
  setup(props) {
    const getModal1Id = id => `modal1${id}`
    const getModal2Id = id => `modal2${id}`
    const getModal3Id = id => `modal3${id}`
    const getModal4Id = id => `modal4${id}`
    const getModal5Id = id => `modal5${id}`
    const getModal6Id = id => `modal6${id}`
    const getModal7Id = id => `modal7${id}`
    const getModalReview = id => `modalReview${id}`

    const CLASSROOM_STORE_MODULE_NAME = 'classroom'

    // Register module
    if (!store.hasModule(CLASSROOM_STORE_MODULE_NAME)) store.registerModule(CLASSROOM_STORE_MODULE_NAME, courseStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLASSROOM_STORE_MODULE_NAME)) store.unregisterModule(CLASSROOM_STORE_MODULE_NAME)
    })
    const selectedCourse = ref({})
    const templates = ref([])
    const courses = ref([])
    const isTypingTemplate = ref(false)
    const isTypingCourse = ref(false)

    const isChatActive = ref(false)
    const chatModelId = ref(null)
    const handleChatClick = data => {
      isChatActive.value = true
      chatModelId.value = data
    }
    // Use toast
    const toast = useToast()

    const {
      isBusy,
      userData,
      fetchUsers,
      tableColumns,
      tableColumns1,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      StudentId,
      suggestClassrooms,
      suggestTeacher,
      classrooms,
      teachers,
      masters,
      cities,
      TofDArray,
      statusArray,
      currentUser,

      resolveAttendanceVariant,
      resolveUserStatusVariant,
      resolveStatusVariant,
    } = useCoursesList()
    const isBigModalActive = ref(false)
    const spinner = ref(false)
    const msgs = ref([])
    const UsersToAdd = ref([])
    const currentCourseData = ref(null)
    const UsersAlreadyAdded = ref([])
    watch(() => props.refreshKey, () => {
      refetchData()
    })
    const loadSpinner = () => {
      // open
      spinner.value = true
      setTimeout(() => {
        spinner.value = false
      }, 1000)
    }
    const ModalData = (id, tab) => {
      store.dispatch('coursesStoreModule/getSCourse', id)
        .then(() => {
          currentCourseData.value = store.state.coursesStoreModule.singleCourse.data
          currentCourseData.value.pendingTeachers = store.state.coursesStoreModule.singleCourse.pendingTeachers
          if (store.state.coursesStoreModule.singleCourse.currentTeacher) { currentCourseData.value.currentTeacher = store.state.coursesStoreModule.singleCourse.currentTeacher }
          currentCourseData.value.tab = tab
          const sDays = currentCourseData.value.days
          const daysArr = sDays.split(',')
          currentCourseData.value.days = daysArr
          if (currentCourseData.value.conversation === null) {
            msgs.value = []
            UsersToAdd.value = []
            UsersAlreadyAdded.value = []
          } else {
            if (typeof currentCourseData.value.conversation !== 'undefined') {
              msgs.value = currentCourseData.value.conversation.messages
            }
            UsersToAdd.value = currentCourseData.value.users_To_Add
            UsersAlreadyAdded.value = currentCourseData.value.users_Already_In
          }
          isBigModalActive.value = true
        }).catch(error => console.log(error))
    }
    const hasLoadedTeachers = ref(false)
    const getTeachers = id => {
      hasLoadedTeachers.value = false
      store.dispatch('coursesStoreModule/fetchTeachers', { course_id: id }).then(() => {
        hasLoadedTeachers.value = true
        teachers.value = store.state.coursesStoreModule.teachers
      })
    }
    const tests = ref([])
    const selectedTest = ref({})
    const due_date = ref(null)
    // const isLoadedTests = ref(false)
    // const getPlacementTest = () => {
    //   store.dispatch('registerStoreModule/getPlacementTest')
    //   .then(response => {
    //     tests.value = response.data.rows
    //     isLoadedTests.value = true
    //   }).catch(() => {
    //     toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Error while confirming registration',
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //     })
    // }
    // const regToWaitingList = id => {
    //   store.dispatch('registerStoreModule/regToWaitingList', id)
    //   .then(response => {
    //     toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: response.data.message,
    //         icon: 'AlertTriangleIcon',
    //         variant: 'success',
    //       },
    //     })
    //     refetchData()
    //   }).catch(() => {
    //     toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Error while confirming registration',
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //     })
    // }
    const printAttendance = course_id => {
      store.dispatch('coursesStoreModule/printAttendance', course_id).catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
    const hideCourse = id => {
      // const course_id = selectedCourse.value.id
      store.dispatch('coursesStoreModule/hideCourse', id)
      .then(response => {
        refetchData()
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while hiding course',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const activateCourse = id => {
      store.dispatch('coursesStoreModule/activateCourseAction', id).then(res => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while activating course',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const archive = confId => {
      store.dispatch('coursesStoreModule/archiveCourse', confId)
        .then(res => {
          refetchData()
          toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while archiving course',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    /* eslint-disable no-shadow */
    // const sendPTest = (students, placement_test_id, due_date) => {
    //   store.dispatch('registerStoreModule/sendPTest', { students, placement_test_id, due_date })
    //   .then(response => {
    //       toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: response.data.message,
    //           icon: 'AlertTriangleIcon',
    //           variant: 'success',
    //         },
    //       })
    //       refetchData()
    //   }).catch(() => {
    //     toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Error while confirming registration',
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //     })
    // }
    // const moveStudent = studentId => {
    //   const course_id = selectedCourse.value.id
    //   store.dispatch('registerStoreModule/moveStudent', { studentId, course_id })
    //   .then(response => {
    //       toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: response.data.message,
    //           icon: 'AlertTriangleIcon',
    //           variant: 'success',
    //         },
    //       })
    //       refetchData()
    //   }).catch(() => {
    //     toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Error while confirming registration',
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //     })
    // }
    // const autoCompleteCourse = search => {
    //   isTypingCourse.value = true
    //   store.dispatch('registerStoreModule/autoCompleteCourses', search)
    //   .then(response => {
    //     courses.value = response.data
    //     isTypingCourse.value = false
    //     })
    // }

    // const generateContract = id => {
    //   store.dispatch('registerStoreModule/generateContract', id)
    //   .catch(() => {
    //     toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Error while creating contract registration',
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //     })
    // }
    // const generateCertificate = genId => {
    //   store.dispatch('registerStoreModule/generateCertificate', genId)
    //   .then(res => {
    //     toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: res.data.message,
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //   }).catch(() => {
    //     toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Error while creating certificate registration',
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //     })
    // }
    // const confirm = confId => {
    //   store.dispatch('registerStoreModule/confirmRegistration', confId)
    //     .then(() => {
    //       toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Registration Has Been confirmed',
    //           icon: 'AlertTriangleIcon',
    //           variant: 'success',
    //         },
    //       })
    //       refetchData()
    //   }).catch(() => {
    //     toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Error while confirming registration',
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //     })
    // }
    // const sendEmail = ref(false)
    // const cancelRegistration = (cancelId, send_email) => {
    //   console.log(cancelId)
    //   store.dispatch('registerStoreModule/cancelRegistration', { cancelId, send_email })
    //     .then(() => {
    //       toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Registration Has Been canceled',
    //           icon: 'AlertTriangleIcon',
    //           variant: 'success',
    //         },
    //       })
    //       refetchData()
    //   }).catch(() => {
    //     toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Error while canceling registration',
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //     })
    // }
    // const remove = regId => {
    //   store.dispatch('registerStoreModule/deleteRegistration', regId)
    //     .then(() => {
    //       toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Registration Has Been removed',
    //           icon: 'AlertTriangleIcon',
    //           variant: 'success',
    //         },
    //       })
    //       refetchData()
    //   }).catch(() => {
    //     toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Error while removing registration',
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //     })
    // }
    if (props.studentID) { StudentId.value = props.studentID }
    const downloadCertificate = id => {
      store.dispatch('classroom/downloadCertificate', id)
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error while downloading certificate',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    /* eslint-disable camelcase */
    const cancel = (course_id, reason, another_reason) => {
      const reason_other = another_reason
      // console.log(course_id, reason, another_reason)
      store.dispatch('classroom/cancelCourse', { course_id, reason, reason_other })
        .then(res => {
          refetchData()
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    const reviews = ref({})
    const isBusyReview = ref(true)
    const getReview = (id, myReview) => {
      isBusyReview.value = true
      store.dispatch('classroom/getReview', id)
        .then(res => {
          isBusyReview.value = false
          reviews.value = res.data
          if (myReview) {
            /* eslint-disable */
            reviews.value.rows.unshift(myReview)
            reviews.value.totalRecords += 1
          }
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while fetching reviews',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const addReview = (course_id, rating, comment) => {
      store.dispatch('classroom/addReview', { course_id, rating, comment })
        .then(res => {
          refetchData()
          reviews.value = res.data
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while adding review',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const removeReview = id => {
      store.dispatch('classroom/removeReview', id)
      .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error while removing review',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      })
    }

    return {
      archive,
      activateCourse,
      hideCourse,
      printAttendance,
      hasLoadedTeachers,
      getTeachers,
      isBusy,
      isBigModalActive,
      ModalData,
      loadSpinner,
      suggestClassrooms,
      suggestTeacher,
      classrooms,
      teachers,
      masters,
      cities,
      TofDArray,
      statusArray,
      currentUser,

      // sendEmail,

      spinner,
      currentCourseData,
      msgs,
      UsersToAdd,
      UsersAlreadyAdded,

      // Modal ids
      getModal1Id,
      getModal2Id,
      getModal3Id,
      getModal4Id,
      getModal5Id,
      getModal6Id,
      getModal7Id,
      getModalReview,
      isChatActive,
      chatModelId,
      handleChatClick,

      tests,
      selectedTest,
      due_date,
      // isLoadedTests,
      // getPlacementTest,

      // remove,
      // cancelRegistration,
      // confirm,
      // generateCertificate,
      // generateContract,
      // moveStudent,
      // sendPTest,
      // regToWaitingList,
      // autoCompleteCourse,

      reviews,
      getReview,
      addReview,
      removeReview,
      isBusyReview,

      selectedCourse,
      templates,
      courses,
      isTypingTemplate,
      isTypingCourse,

      // Actions
      downloadCertificate,
      cancel,

      userData,
      StudentId,
      fetchUsers,
      tableColumns,
      tableColumns1,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      resolveAttendanceVariant,
      resolveUserStatusVariant,
      resolveStatusVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
