<template>
  <div>
    <div class="mb-2">
      <b-row>
        <b-col md-4>
          <b-form-group>
            <v-select
              v-model="selected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="classroomOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md-4>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="refresh(selected)"
          >
            <feather-icon
              icon="SearchIcon"
              class="mr-50"
            />
            Find classroom
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div class="app-calendar overflow-hidden border">
      <div class="row no-gutters">
        <!-- Sidebar -->
        <div
          class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
          :class="{'show': isCalendarOverlaySidebarActive}"
        >
          <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" />
        </div>

        <!-- Calendar -->
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
              />
            </div>
            <!-- <div
              v-html="calendarOptions"
            /> -->
            <course-table
              v-if="isEventModalActive && selected.name !== 'All'"
              v-model="isEventModalActive"
              @hide-modal="isEventModalActive = false"
            />
          </div>
        </div>
        <b-modal
          ref="my-modal"
          title="Basic Modal"
          ok-only
          ok-title="Accept"
        />

        <!-- Sidebar Overlay -->
        <div
          class="body-content-overlay"
          :class="{'show': isCalendarOverlaySidebarActive}"
          @click="isCalendarOverlaySidebarActive = false"
        />
        <!-- <calendar-event-handler
          v-model="isEventHandlerSidebarActive"
          :event="event"
          :clear-event-data="clearEventData"
          @remove-event="removeEvent"
          @add-event="addEvent"
          @update-event="updateEvent"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable-next-line */
import { BButton, BRow, BCol, BFormGroup, VBModal } from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import { onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import calendarStoreModule from './calendarStoreModule'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
// import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import CourseTable from './calendar-event-handler/CourseTable.vue'
import useCalendar from './useCalendar'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    // CalendarEventHandler,
    CourseTable,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      selected: { name: 'All' },
    }
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,
      getClassrooms,
      classroomOptions,
      selectedClassroom,
      classroom_id,
      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventModalActive,
      isModalActive,
    } = useCalendar()

    fetchEvents()
    getClassrooms()
    const refresh = selected => {
      selectedClassroom.value = selected.name
      classroom_id.value = selected.id
      if (selected.name === 'All') { isEventModalActive.value = false }
    }

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,
      selectedClassroom,
      classroom_id,
      getClassrooms,
      refresh,

      classroomOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventModalActive,
      isModalActive,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
@import '@core/scss/vue/libs/vue-select.scss';
</style>
