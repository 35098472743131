<template>
  <div>
    <session-update
      v-if="isSessionHandlerSidebarActive"
      v-model="isSessionHandlerSidebarActive"
      :session-id="sessionId"
      :teachers="teachersBySession"
      :classrooms="classroomsBySession"
      :hasLoadedTeachers="hasLoadedTeachers"
      :hasLoadedClassrooms="hasLoadedClassrooms"
      @is-session-handler-sidebar-active="isSessionHandlerSidebarActive = false"
      @refetch-data="getSessionData()"
    />
    <div class="d-flex align-items-center justify-content-between mb-1">
      <b-button
        v-if="selectedRows.length > 0"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="clearSelected"
      >
        Clear selected
      </b-button>
      <b-button
        v-else
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="selectAllRows"
      >
        Select all
      </b-button>
      <div v-if="selectedRows.length > 0">
        <b-button
          v-b-modal.assign-substitute-modal
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="btn-icon ml-1"
          @click="getTeachersBySession(selectedRows)"
        >
          Assign substitute
          <!-- <feather-icon icon="UserPlusIcon" /> -->
        </b-button>
        <b-button
          v-b-modal.cancel-substitute-modal
          v-b-tooltip.hover.top="'Cancel substitute'"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="btn-icon ml-1"
        >
          Cancel substitute
          <!-- <feather-icon icon="SlashIcon" /> -->
        </b-button>
        <!-- <b-button
          v-b-modal.delete-substitute-modal
          v-b-tooltip.hover.top="'Delete'"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="btn-icon ml-1"
        >
          Delete session
        </b-button> -->
          <!-- <feather-icon icon="SlashIcon" /> -->
      </div>
    </div>
    <b-modal
      id="assign-substitute-modal"
      title="Assign a substitute teacher"
      centered
      no-close-on-backdrop
      size="lg"
      ok-only
      ok-title="Assign"
      @show="selectedTeachers = []"
      @ok="assignSubtitutionMulti('assign', selectedRows, selectedTeachers.map(teacher => teacher.id))"
    >
      <b-form-group
        label="Teachers"
        label-for="teachers"
      >
        <v-select
          v-model="selectedTeachers"
          multiple
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          :options="teachersBySession"
          :clearable="false"
          :selectable="el => el.available === 'available'"
        >
          <span slot="no-options">
            <div
              v-if="!hasLoadedTeachers"
              class="text-center d-flex align-items-center justify-content-center"
            >
              <b-spinner
                variant="primary"
                label="Text Centered"
              />
            </div>
            <div
              v-if="hasLoadedTeachers && teachersBySession.length === 0"
              class="text-center d-flex align-items-center justify-content-center"
            >
              No matching records found
            </div>
          </span>
          <template #option="{ name, code, available }">
            <div class="d-flex align-items-center justify-content-between">
              <span class="d-block"> {{ code }}</span>
              <small class="ml-50">
                <b-badge
                  pill
                  :variant="`light-${available === 'available' ? 'success' : 'danger'}`"
                  class="text-capitalize"
                >
                  {{ available === 'available' ? 'Available' : 'Unavailable' }}
                </b-badge>
              </small>
              <!-- <small class="ml-50" :class="available ? 'text-success' : 'text-danger'">{{ available ? 'Available' : 'Unavailable' }}</small> -->
            </div>
            <small class="text-muted">{{ name }}</small>
          </template>
        </v-select>
      </b-form-group>
    </b-modal>
    <b-modal
      id="cancel-substitute-modal"
      title="Cancel all requests"
      centered
      no-close-on-backdrop
      ok-title="Yes"
      hide-body
      cancel-title="No"
      @ok="assignSubtitutionMulti('cancel', selectedRows)"
    >
      <div class="d-flex align-items-center justify-content-start">
        <feather-icon class="text-warning mr-50" icon="AlertTriangleIcon" size="16" />
        <strong>Are you sure you want to cancel all requests?</strong>
      </div>
    </b-modal>
    <b-modal
      id="cancel-delete-modal"
      title="Delete session"
      centered
      no-close-on-backdrop
      ok-title="Yes"
      hide-body
      cancel-title="No"
      @ok="assignSubtitutionMulti('delete', selectedRows)"
    >
      <div class="d-flex align-items-center justify-content-start">
        <feather-icon class="text-danger mr-50" icon="AlertTriangleIcon" size="16" />
        <strong>Are you sure you want to delete this session</strong>
      </div>
    </b-modal>
    <b-table
      ref="refSubstituteListTable"
      class="position-relative"
      :items="substitutions"
      responsive
      :fields="allSessions ? substitutionsColumns : substitutionsColumns.filter(column => column.key !== 'course')"
      :primary-key="'session_id'+Math.random()"
      show-empty
      empty-text="No matching records found"
      selectable
      @row-selected="onRowSelected"
      :busy.sync="isBusy"
    >
      <template #table-busy>
        <div
          v-if="isBusy"
          class="text-center d-flex align-items-center justify-content-center"
        >
          <b-spinner
            variant="primary"
            label="Text Centered"
          />
          <strong class="ml-1">Loading...</strong>
        </div>
      </template>
      <template #cell(id)="data">
        <b-form-checkbox
          v-model="selectedRows"
          :value="data.item.session_id"
          disabled
        />
        <small>{{ data.item.session_id }}</small>
      </template>
      <!-- Column: Id -->
      <template #cell(date)="data">
        <span
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.item.session_date }}
        </span>
        <small class="d-block text-nowrap">{{ data.item.start_time }} - {{ data.item.end_time }}</small>
      </template>
      <template #cell(course)="data">
        <span
          class="font-weight-bold d-block text-nowrap text-primary"
        >
          {{ data.item.code }}
        </span>
      </template>
      <template #cell(group)="data">
        <span
          class="font-weight-bold d-block text-nowrap text-primary"
        >
          {{ data.item.group }}
        </span>
      </template>
      <template #cell(teacher)="data">
        <b-avatar-group
          v-if="data.item.current_teacher"
          size="38px"
        >
          <b-avatar
            v-if="data.item.current_teacher"
            v-b-tooltip.hover
            class="pull-up"
            button
            :title="data.item.current_teacher.name"
            :src="data.item.current_teacher.avatar && data.item.current_teacher.avatar.split('/')[5] === 'default-avatar.jpg' ? null : data.item.current_teacher.avatar"
            :text="avatarText(data.item.current_teacher.name)"
            @click="$router.push({ name: 'admin-teacher-calendar', params: { current_teacher } })"
          />
        </b-avatar-group>
      </template>
      <template #cell(substitutes)="data">
        <b-avatar-group
          size="38px"
        >
          <b-avatar
            v-for="substitute in data.item.substitutes.filter(sub => sub.status !== 'Canceled')"
            :key="substitute.id"
            v-b-tooltip.hover
            class="pull-up"
            :class="substitute.status === 'Approved' ? 'active-teacher' : substitute.by === 'admin' ? 'pending-admin' : 'pending-teacher'"
            button
            :badge="substitute.showCancel"
            badge-variant="light"
            badge-top
            badge-offset="-2px"
            :title="substitute.name"
            :src="substitute.avatar.split('/')[5] === 'default-avatar.jpg' ? null : substitute.avatar"
            :text="avatarText(substitute.name)"
            @mouseover.native="substitute.showCancel = substitute.by === 'admin'"
            @mouseleave.native="substitute.showCancel = false"
          >
            <!-- @click="$router.push({ name: 'admin-teacher-calendar', params: { teacher: substitute } })" -->
            <template v-if="substitute.showCancel" #badge>
              <b-button
                class="btn-icon p-0"
                variant="flat-dark"
                @click="cancelRequest(data.item.session_id, substitute.id)"
              >
                <feather-icon icon="XIcon" size="10" />
              </b-button>
            </template>
          </b-avatar>
        </b-avatar-group>
      </template>
      <template #cell(classroom)="data">
        <span
          v-if="data.item.classroom"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.item.classroom.name }}
        </span>
      </template>
      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item
            v-b-modal="getAttendanceModal(data.item.session_id)"
          >
            <feather-icon :icon="'UserCheckIcon'" />
            <span class="align-middle ml-50">Attendance list</span>
          </b-dropdown-item>
          <b-dropdown-item
            :disabled="!data.item.shall_update"
            @click="activateUpdateSession(data.item)"
          >
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Update session</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="data.item.substitutes.filter(el => el.by !== 'admin').length > 0"
            :disabled="!data.item.shall_update"
            @click="reason = null; approvedTeacher = {}; data.item.substitutes.length > 1 ? $bvModal.show(`approveModal${data.item.session_id}`) : approve(data.item.substitutes[0].id, data.item.session_id)"
          >
            <!-- @click="getSessionTeachers(data.item.session_id)" -->
            <feather-icon icon="UserCheckIcon" />
            <span class="align-middle ml-50">Approve substitute</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="data.item.substitutes.filter(el => el.by !== 'admin').length > 0"
            :disabled="!data.item.shall_update"
            @click="reason = null; data.item.substitutes.length > 1 ? $bvModal.show(`declineModal${data.item.session_id}`) : decline(data.item.session_id)"
          >
            <!-- @click="getSessionTeachers(data.item.session_id)" -->
            <feather-icon icon="UserXIcon" />
            <span class="align-middle ml-50">Decline {{ data.item.substitutes.length > 1 ? 'all' : '' }}</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-b-modal="getSubModal(data.item.session_id)"
            :disabled="!data.item.shall_update"
            @click="getTeachersBySession([data.item.session_id]); selectedTeachers = data.item.substitutes.filter(sub => sub.status === 'Pending').filter(el => el.by === 'admin')"
          >
            <!-- @click="getSessionTeachers(data.item.session_id)" -->
            <feather-icon :icon="'UserPlusIcon'" />
            <span class="align-middle ml-50">Assign substitute</span>
          </b-dropdown-item>
          <b-dropdown-item
            :disabled="!data.item.shall_update"
            @click="cancelRequest(data.item.session_id)"
          >
            <feather-icon :icon="'SlashIcon'" />
            <span class="align-middle ml-50">Cancel substitute</span>
          </b-dropdown-item>
          <!-- <b-dropdown-item
            v-b-modal="getSubModal1(data.item.session_id)"
          >
            <feather-icon :icon="'TrashIcon'" />
            <span class="align-middle ml-50">Delete session</span>
          </b-dropdown-item> -->
        </b-dropdown>
        <b-modal
          :id="'attendanceModal'+data.item.session_id"
          centered
          size="xl"
          no-close-on-backdrop
          hide-footer
        >
          <template #modal-title>
            <span
              v-if="!attendanceModalTitle.lesson"
              class="text-center d-flex align-items-center justify-content-center"
            >
              <b-spinner
                variant="secondary"
                label="Text Centered"
              />
            </span>
            <b v-if="attendanceModalTitle.lesson">Lesson {{ attendanceModalTitle.lesson }}</b>
            <span class="d-inline ml-1" v-if="attendanceModalTitle.date">{{ attendanceModalTitle.date }}</span>
          </template>
          <students-attendance
            :session-id="data.item.session_id"
            @close-modal="$bvModal.hide('attendanceModal'+data.item.session_id)"
            @modal-title="val => attendanceModalTitle = val"
          />
        </b-modal>
        <b-modal
          :id="'declineModal'+data.item.session_id"
          title="Denial reason"
          ok-title="Decline"
          ok-only
          centered
          size="lg"
          no-close-on-backdrop
          @ok="decline(data.item.session_id, reason)"
        >
          <b-form-group
            label="Reason"
            label-for="reason"
          >
            <b-form-textarea
              v-model="reason"
              placeholder="Reason"
              rows="3"
              maxlength="500"
            />
          </b-form-group>
        </b-modal>
        <b-modal
          :id="'approveModal'+data.item.session_id"
          title="Approve teacher"
          centered
          no-close-on-backdrop
          ok-title="Approve"
          @ok="approve(approvedTeacher.id, data.item.session_id)"
        >
          <b-form-group
            label="Select teacher"
            label-for="select-teacher"
          >
            <v-select
              v-model="approvedTeacher"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="data.item.substitutes.filter(sub => sub.by !== 'admin')"
              :clearable="false"
            >
              <template #option="{ name, avatar }">
                <b-avatar
                  size="22"
                  class="pull-up"
                  button
                  :title="name"
                  :src="avatar"
                />
                <span class="ml-1">{{ name }}</span>
              </template>
            </v-select>
          </b-form-group>
          <b-form-group
            label="Reason"
            label-for="reason"
          >
            <b-form-textarea
              v-model="reason"
              placeholder="Reason"
              rows="3"
              maxlength="500"
            />
          </b-form-group>
        </b-modal>
        <b-modal
          :id="'subModal'+data.item.session_id"
          centered
          no-close-on-backdrop
          size="lg"
          ok-only
          ok-title="Assign"
          @show="selectedTeachers = data.item.substitutes.filter(el => el.status !== 'Canceled')"
          @ok="assignSubtitution(data.item.session_id, selectedTeachers.filter(el => el.status !== 'Approved').map(teacher => teacher.id))"
        >
          <template #modal-title>
            <p class="d-inline">
              Assign a substitute teacher for session <b>{{ data.item.session_id }}</b>
            </p>
          </template>
          <b-form-group
            label="Teachers"
            label-for="teachers"
          >
            <v-select
              v-model="selectedTeachers"
              multiple
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="teachersBySession.filter(option => !selectedTeachers.some(selectedOption => selectedOption.id === option.id))"
              :clearable="false"
              :selectable="el => el.available === 'available'"
              class="selectedTeachers"
            >
              <template #selected-option="{ code, by, status }">
                <div v-if="status !== 'Approved'" class="flex">
                  <feather-icon
                    :icon="by === 'teacher' ? 'StarIcon' : 'ClockIcon'"
                    size="14"
                    :class="by === 'teacher' ? 'text-indigo' : 'text-warning'"
                    class="align-middle mr-50"
                  />
                  <span :class="by === 'teacher' ? 'pending-by-teacher' : 'pending-by-admin'" class="mt-25">{{ code }}</span>
                </div>
                <div v-if="status === 'Approved'" class="flex">
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="16"
                    class="align-middle mr-50 text-success"
                  />
                  <span class="text-success">{{ code }}</span>
                </div>
              </template>
              <span slot="no-options">
                <div
                  v-if="!hasLoadedTeachers"
                  class="text-center d-flex align-items-center justify-content-center"
                >
                  <b-spinner
                    variant="primary"
                    label="Text Centered"
                  />
                </div>
                <div
                  v-if="hasLoadedTeachers && teachersBySession.length === 0"
                  class="text-center d-flex align-items-center justify-content-center"
                >
                  No matching records found
                </div>
              </span>
              <template #option="{ name, code, available }">
                <div class="d-flex align-items-center justify-content-between">
                  <span class="d-block"> {{ code }}</span>
                  <small class="ml-50">
                    <b-badge
                      pill
                      :variant="`light-${available === 'available' ? 'success' : 'danger'}`"
                      class="text-capitalize"
                    >
                      {{ available === 'available' ? 'Available' : 'Unavailable' }}
                    </b-badge>
                  </small>
                  <!-- <small class="ml-50" :class="available ? 'text-success' : 'text-danger'">{{ available ? 'Available' : 'Unavailable' }}</small> -->
                </div>
                <small class="text-muted">{{ name }}</small>
              </template>
            </v-select>
          </b-form-group>
        </b-modal>
        <b-modal
          :id="'subModalDelete'+data.item.session_id"
          title="Delete session"
          centered
          no-close-on-backdrop
          ok-title="Yes"
          hide-body
          cancel-title="No"
          @ok="deleteSession(data.item.session_id)"
        >
          <div class="d-flex align-items-center justify-content-start">
            <feather-icon class="text-danger mr-50" icon="AlertTriangleIcon" size="16" />
            <strong>Are you sure you want to delete this session</strong>
          </div>
        </b-modal>
      </template>
    </b-table>
    <div class="d-flex justify-content-end align-items-center">
      <span class="text-muted mr-5">Showing {{ substitutions.length }} of {{ totalRecords }} entries</span>
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRecords"
          per-page="100"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @change="getSessionData(currentPage)"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable object-property-newline */
import {
  BTable, BModal, BFormCheckbox, BAvatar, BAvatarGroup, BFormGroup, BDropdown, BDropdownItem, BSpinner, BButton, VBTooltip, BBadge, BFormTextarea, BPagination,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import { avatarText } from '@core/utils/filter'
  import Ripple from 'vue-ripple-directive'
  import { ref } from '@vue/composition-api'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import StudentsAttendance from '@/views/teacher/attendance-calendar/calendar-event-handler/StudentsAttendance.vue'
  import { useToast } from 'vue-toastification/composition'
  import store from '@/store'
  import SessionUpdate from './SessionUpdate.vue'

export default {
  components: {
    BTable, BModal, BFormCheckbox, BAvatar, BAvatarGroup, BFormGroup, BDropdown, BDropdownItem, BSpinner, BButton, BBadge, vSelect, BFormTextarea, BPagination,
    SessionUpdate, StudentsAttendance,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    courseId: {
      type: Number,
      required: true,
    },
    allSessions: {
      type: Boolean,
    },
  },
  data() {
    return {
      attendanceModalTitle: {},
      isSessionHandlerSidebarActive: false,
      avatarText,
      teachersBySession: [],
      teachersByCourse: [],
      hasLoadedTeachers: false,
      classroomsBySession: [],
      hasLoadedClassrooms: false,
      loadingData: false,
      sessionId: null,
    }
  },
  /* eslint-disable camelcase */
  methods: {
    activateUpdateSession(item) {
      this.sessionId = item.session_id
      this.loadingData = true
      // this.getClassroomsBySession(item.session_id)
      // this.getTeachersBySession([item.session_id])
      this.isSessionHandlerSidebarActive = true
    },
    selectAllRows() { this.$refs.refSubstituteListTable.selectAllRows() },
    clearSelected() { this.$refs.refSubstituteListTable.clearSelected() },
    getTeachersBySession(sessions) {
      store.dispatch('coursesStoreModule/getTeachersBySession', { sessions })
      .then(res => {
        this.hasLoadedTeachers = true
        this.teachersBySession = res.data.allTeachers
      })
    },
    // getTeachersByCourse() {
    //   this.selectedTeachers = []
    //   this.$http.get('/teacher/suggest/by-course', { params: { course_id: this.courseId, sessions: this.selectedRows } }).then(res => {
    //     this.hasLoadedTeachers = true
    //     this.teachersByCourse = res.data.allTeachers
    //   })
    // },
    // getClassroomsBySession(session_id) {
    //   store.dispatch('coursesStoreModule/getClassroomsBySession', { session_id })
    //   .then(res => {
    //     this.hasLoadedClassrooms = true
    //     this.classroomsBySession = res.data.allClassrooms
    //   })
    // },
  },
  setup(props) {
    const toast = useToast()
    const isBusy = ref(false)
    const getSubModal = id => `subModal${id}`
    const getSubModal1 = id => `subModalDelete${id}`
    const getAttendanceModal = id => `attendanceModal${id}`
    const substitutionsColumns = [
      // { key: 'selected', label: '' },
      { key: 'id' },
      { key: 'lesson_number' },
      { key: 'course' },
      { key: 'teacher' },
      { key: 'substitutes' },
      { key: 'date', label: 'date & time' },
      { key: 'classroom' },
      { key: 'actions' },
    ]
    /* eslint-disable camelcase */
    const substitutions = ref([])
    const totalRecords = ref(null)
    const currentPage = ref(1)
    const getSessionData = () => {
      isBusy.value = true
      store.dispatch(`ApprovalStoreModule/${props.allSessions ? 'getAllSessionData' : 'getSessionData'}`, { course_id: props.courseId, page: currentPage.value })
      .then(res => {
        isBusy.value = false
        substitutions.value = props.allSessions ? res.data : res.data.rows
        totalRecords.value = res.data.totalRecords
      }).catch(error => catchError(error))
    }
    getSessionData()
    const selectedRows = ref([])
    const onRowSelected = items => {
      selectedRows.value = items.map(item => item.session_id)
    }
    const selectedTeachers = ref([])
    const approvedTeacher = ref({})
    const reason = ref(null)
    const catchError = error => {
      toast({
        component: ToastificationContent,
        props: {
          title: error.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
    const handleResponse = res => {
      getSessionData()
      toast({
        component: ToastificationContent,
        props: {
          title: res.data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    }
    const assignSubtitution = (session_id, teachers) => {
      store.dispatch('ApprovalStoreModule/assignSubtitution', { session_id, teachers })
      .then(res => handleResponse(res))
      .catch(error => catchError(error))
    }
    const assignSubtitutionMulti = (action, session_id, teachers) => {
      store.dispatch('ApprovalStoreModule/assignSubtitutionMulti', { action, session_id, teachers })
      .then(res => handleResponse(res))
      .catch(error => catchError(error))
    }
    const cancelRequest = (session_id, teacher_id) => {
      store.dispatch('ApprovalStoreModule/cancelRequest', { session_id, teacher_id })
      .then(res => handleResponse(res))
      .catch(error => catchError(error))
    }
    const deleteSession = session_id => {
      store.dispatch('ApprovalStoreModule/deleteSession', session_id)
      .then(res => handleResponse(res))
      .catch(error => catchError(error))
    }
    const sessionTeachers = ref([])
    const getSessionTeachers = session_id => {
      store.dispatch('ApprovalStoreModule/getSessionTeachers', { session_id })
      .then(res => { sessionTeachers.value = res.data })
      .catch(error => catchError(error))
    }
    const approve = (teacher_id, session_id) => {
      store.dispatch('ApprovalStoreModule/approveSession', { teacher_id, session_id, reason: reason.value })
      .then(res => handleResponse(res))
      .catch(error => catchError(error))
    }
    const decline = id => {
      store.dispatch('ApprovalStoreModule/denySession', { id, reason: reason.value })
      .then(res => handleResponse(res))
      .catch(error => catchError(error))
    }
    return {
      isBusy,
      reason,
      approve,
      decline,
      catchError,
      getSubModal,
      currentPage,
      totalRecords,
      getSubModal1,
      selectedRows,
      cancelRequest,
      deleteSession,
      onRowSelected,
      substitutions,
      handleResponse,
      getSessionData,
      sessionTeachers,
      approvedTeacher,
      selectedTeachers,
      assignSubtitution,
      getSessionTeachers,
      getAttendanceModal,
      substitutionsColumns,
      assignSubtitutionMulti,
    }
  },
}
</script>
<style>
.Approved-counter .b-avatar-img {
  border: 1px solid #28c76f;
}
.Pending-counter .b-avatar-img {
  border: 1px solid #ff9f43;
}
</style>
