<template>
  <b-card>
    <!-- about -->
    <div
      v-for="(data,key) in aboutData"
      :key="key"
      class="mt-2"
    >
      <h5 class="text-capitalize mb-75">
        {{ key }}
      </h5>
      <b-card-text>
        {{ data }}
      </b-card-text>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
  props: {
    aboutData: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    /* eslint-disable */
    delete this.aboutData.avatar
    delete this.aboutData.city
    delete this.aboutData.role
  },
}
</script>
