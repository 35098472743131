import { ref, watch, computed } from '@vue/composition-api'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import store from '@/store'
import router from '@/router'

export default function useUsersList() {
  // Use toast
  const toast = useToast()
  const { t } = useI18nUtils()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: t('id'), sortable: true },
    { key: 'number', label: 'Reg id' },
    { key: 'student', label: t('student'), sortable: true },
    { key: 'course', label: t('course'), sortable: true },
    { key: 'group', label: t('group'), sortable: true },
    { key: 'paid', label: 'payment method', sortable: true },
    { key: 'date', label: t('payment date'), sortable: true },
    { key: 'payer' },
    { key: 'payment_status', label: t('status') },
    { key: 'comments', label: t('notes') },
    { key: 'actions', label: t('actions'), tdClass: 'table-actions' },
  ]
  const tableColumns1 = [
    // { key: 'id', label: t('id'), sortable: true },
    { key: 'number', label: t('number'), sortable: true },
    // { key: 'student', label: t('student'), sortable: true },
    { key: 'course', label: t('course'), sortable: true },
    { key: 'group', label: t('group'), sortable: true },
    { key: 'paid', label: t('payment method') },
    { key: 'date', label: t('paid & sent date'), sortable: true },
    { key: 'payment_status', label: t('status') },
    { key: 'comments', label: t('notes') },
    { key: 'actions', label: t('actions'), tdClass: 'table-actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const userData = JSON.parse(localStorage.getItem('userData'))
  const studentId = ref(userData.id)
  /* eslint-disable camelcase */
  const role_name = ref(window.location.href.split('/')[3])
  const selectedFilter = ref('Active')
  const student_id = ref(null)
  const payerOptions = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }
  const urlParams = new URLSearchParams(window.location.search)
  const urlQueries = router.currentRoute.query
  if (urlQueries.search) searchQuery.value = urlQueries.search
  if (urlQueries.page) currentPage.value = urlQueries.page
  if (urlQueries.perPage) perPage.value = urlQueries.perPage
  if (urlQueries.filter) selectedFilter.value = urlQueries.filter
  /* eslint-disable-next-line */
  watch([currentPage, perPage, roleFilter, planFilter, statusFilter, role_name, selectedFilter, student_id, searchQuery],  () => {
    urlParams.set('search', searchQuery.value ? searchQuery.value : '')
    urlParams.set('page', currentPage.value ? currentPage.value : '')
    urlParams.set('perPage', perPage.value ? perPage.value : '')
    urlParams.set('filter', selectedFilter.value ? selectedFilter.value : '')
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams.toString()}`)
    refetchData()
  })
  const isBusy = ref(false)

  const fetchUsers = (ctx, callback) => {
    const payload = {
      search: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      role_name: roleFilter.value,
      plan: planFilter.value,
      status: statusFilter.value,
      filter: selectedFilter.value,
      student_id: student_id.value,
      // role_name: role_name.value,
    }
    if (userData.role === 'student') {
      payload.student_id = studentId.value
    }
    store
      .dispatch('invoice/fetchUsers', payload)
      .then(() => {
        const users = store.state.invoiceStoreModule.invoices.rows
        const total = store.state.invoiceStoreModule.invoices.totalRecords
        callback(users)
        totalUsers.value = total
      })
      .catch(() => {
        isBusy.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const fetchPayers = () => {
    store.dispatch('invoice/fetchPayers').then(res => { payerOptions.value = res.data.rows })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'Paid') return 'success'
    if (status === 'Unpaid') return 'warning'
    if (status === 'Transferred') return 'info'
    if (status === 'Refunded') return 'danger'
    if (status === 'To be refunded') return 'danger'
    if (status === 'Settled') return 'primary'
    if (status === 'Ported') return 'secondary'
    if (status === 'Subsidized') return 'secondary'
    return 'secondary'
  }
  // const resolvePaidVariant = status => {
  //   console.log(status)
  //   if (status === true) return 'success'
  //   if (status === false) return 'danger'
  //   return 'primary'
  // }
  const resolvePaidVariant = status => {
    if (status === false) return '<span class="badge text-capitalize badge-light-danger badge-pill">Not paid</span>'
    if (status === true) return '<span class="badge text-capitalize badge-light-success badge-pill">Paid</span>'
    return '<span class="light-danger"></span>'
  }

  return {
    payerOptions,
    fetchPayers,
    isBusy,
    fetchUsers,
    tableColumns,
    tableColumns1,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    role_name,
    studentId,
    student_id,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolvePaidVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    selectedFilter,
  }
}
