<template>
  <div>

    <b-nav-item-dropdown
      ref="notifDropdown"
      :key="refreshKey"
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media"
      right
    >
      <template #button-content>
        <feather-icon
          :badge="notifCount"
          badge-classes="bg-danger"
          class="text-body"
          icon="BellIcon"
          size="21"
        />
      </template>

      <!-- Notifications -->
      <vue-perfect-scrollbar
        v-once
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
        @scroll="handleScroll"
      >
        <!-- Account Notification -->
        <NotifcationElement
          :notifications="nootifications"
          @mark-notification-as-read="markNotificationAsRead"
          @load-more="getNotifications"
          @decrease-notificatiopns-count="decreaseNotifCount"
        />
      </vue-perfect-scrollbar>
      <div
        class="d-flex justify-content-center"
        style="position: absolute; bottom: 80px; left: 50%"
      >
        <b-spinner
          class="mb-50 mt-50"
          label="Loading..."
          :style="[hasScrolledToBottom || isLoading ? {} : { 'color': 'transparent' }]"
        />
      </div>
      <li
        v-if="nootifications.length === 0 && !isLoading"
        class="dropdown-menu-footer text-center"
      >
        {{ $t('No new notifications') }}
      </li>
      <!-- Cart Footer -->
      <li v-if="nootifications.length > 0" class="dropdown-menu-footer row">
        <b-button
        class="col-6"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="flat-primary"
        small
        :to="{ name: 'notifications' }"
        @click="$refs.notifDropdown.hide()"
        >Read all notifications
        </b-button>
        <b-button
        class="col-6"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="flat-primary"
        @click="readAll"
        small
        >Mark all as read
        </b-button>
      </li>
    </b-nav-item-dropdown>
    <b-modal
      id="survey1-modal"
      title="Help us make your course even better!"
      centered
      no-close-on-backdrop
      hide-footer
      size="xl"
    >
      <student-survey
        :surveyData="surveyData"
        @close-survey-modal="$bvModal.hide('survey1-modal')"
      />
    </b-modal>
    <b-modal
      id="survey2-modal"
      title="You have already filled this survey"
      centered
      no-close-on-backdrop
      ok-only
      ok-title="Close"
    >
      <b-card-text>Thank you for compliting our survey!</b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BNavItemDropdown, BSpinner, BCardText, BButton, /* BFormCheckbox */
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { filterTags } from '@core/utils/filter'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
// import axios from '@axios'
import { ref } from '@vue/composition-api'
import StudentSurvey from '@/views/student/dashboard/StudentSurvey.vue'
import NotifcationElement from './NotifcationElement.vue'

export default {
  components: {
    StudentSurvey,
    BNavItemDropdown,
    VuePerfectScrollbar,
    // BButton,
    NotifcationElement,
    BSpinner,
    BCardText,
    BButton,
    // BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      nootifications: [],
      hasScrolledToBottom: false,
      isLoading: false,
      page: 2,
      notifCount: 0,
    }
  },
  created() {
    this.getNotifications(1)
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    window.Echo.private(`App.Models.User.${userData.id}`)
    .notification(notification => {
      if (notification.sender_id !== userData.id && (notification.notification_type !== 'chat' && notification.notification_type !== 'support_center')) {
        this.notifCount += 1
        this.$store.state.appConfig.notificationRefreshKey += 1
        this.$toast({
          component: ToastificationContent,
          props: {
            title: filterTags(notification.message),
            icon: 'BellIcon',
            variant: 'dark',
          },
        })
        this.nootifications.unshift(
          {
            id: notification.id,
            title: notification.message,
            conversation_id: notification.conversation_id,
            type: notification.notification_type,
            avatar: notification.avatar,
            date: notification.date,
            isReaded: notification.is_readed,
            data: notification.data,
          },
        )
        this.$forceUpdate()
      }
    })
  },
  methods: {
    /* eslint-disable no-plusplus, no-return-assign, no-param-reassign */
    decreaseNotifCount(isReaded) {
      this.$refs.notifDropdown.hide()
      this.notifCount = !isReaded ? this.notifCount -= 1 : this.notifCount
    },
    readAll() {
      this.isLoading = true
      this.$http.post('notification/mark/all-as-read').then(() => {
        this.isLoading = false
        this.notifCount = 0
        this.nootifications.map(notification => notification.isReaded = true)
      })
    },
    handleScroll(el) {
      if ((el.srcElement.offsetHeight + el.srcElement.scrollTop + 10) >= el.srcElement.scrollHeight) {
        this.getNotifications(this.page++)
        this.hasScrolledToBottom = true
      }
    },
    getNotifications(page) {
      this.isLoading = true
      this.$http.get('/notifications', { params: { page } })
        .then(response => {
          this.hasScrolledToBottom = false
          this.isLoading = false
          this.notifCount = response.data.unread_notifications
          response.data.Notifications.forEach(notification => {
            this.nootifications.push(
                {
                  id: notification.notification_id,
                  title: notification.text,
                  avatar: notification.avatar,
                  isReaded: notification.is_readed,
                  type: notification.type,
                  date: notification.date,
                  data: notification.data,
                  // subtitle: notification.body,
                  // createdAt: notification.created_at,
                },
            )
            this.$forceUpdate()
          })
        })
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }
    const refreshKey = ref(0)
    const surveyData = ref({})
    const shallClose = ref(false)
    // eslint-disable-next-line func-names
    const markNotificationAsRead = function (notificationId, type, data) {
      // shallClose.value = true
      if (type === 'survey1' || type === 'survey2') {
        this.$http.post('survey/check', { survey_id: data.survey.id }).then(res => {
          if (res.data.message === 'pending') {
            surveyData.value = { type, data }
            this.$bvModal.show('survey1-modal')
          } else {
            this.$bvModal.show('survey2-modal')
          }
        })
      }
      this.$http.post(`/notification/mark-as-read/${notificationId}`)
    }

    return {
      markNotificationAsRead,
      // systemNotifications,
      perfectScrollbarSettings,
      refreshKey,
      surveyData,
      shallClose,
    }
  },
}
</script>

<style>

</style>
