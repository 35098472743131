import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  const token = window.location.href.split('login/')[1]
  if (token !== undefined) {
    const tokens = token.split('.')
    const decodedToken = JSON.parse(atob(tokens[1]))
    localStorage.accessToken = token
    localStorage.userData = JSON.stringify(decodedToken)
    axios.get('/registrations/awaiting/confirmation').then(res => { store.state.appConfig.pendingRegistrationsList = res.data })
    axios.get('/invoices/awaiting/paid').then(res => { store.state.appConfig.pendingPaymentsList = res.data })
  }
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
const userData = getUserData()
export const userRolee = () => userData.role

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole, from) => {
  if (userRole == null || typeof userRole === 'undefined') return { name: 'student' }
  if (userRole === 'administrator') return { name: 'admin' }
  if (userRole === 'student') return { name: 'student', query: { params: from } }
  if (userRole === 'teacher') return { name: 'teacher' }
  if (userRole === 'agent') return { name: 'agent' }
  if (userRole === 'company') return { name: 'company' }
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'example1' }
}
