<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Eligible to download the certificate</b-card-title>
      <!-- <b-dropdown
        text="Last 7 Days"
        variant="transparent"
        class="chart-dropdown "
        right
        no-caret
        toggle-class="p-0 mb-25"
        size="sm"
      >
        <b-dropdown-item
          v-for="day in chartData.lastDays"
          :key="day"
        >
          {{ day }}
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>

    <b-card-body>

      <!-- apex chart -->
      <vue-apex-charts
        type="donut"
        height="300"
        class="my-1"
        :options="sessionsByDeviceDonut.chartOptions"
        :series="sessionsByDeviceDonut.series"
      />

      <!-- chart info -->
      <div
        v-for="(data,index) in sessionsByDeviceDonut.series"
        :key="index"
        class="d-flex justify-content-between"
        :class="index === sessionsByDeviceDonut.series.length - 1 ? 'mb-0':'mb-1'"
      >
        <div class="series-info d-flex align-items-center">
          <feather-icon
            :icon="(index === 0 ? 'AwardIcon' : 'XOctagonIcon')"
            size="16"
            :class="(index === 0 ? 'text-success' : 'text-warning')"
          />
          <span class="font-weight-bolder ml-75 mr-25">{{ (index === 0 ? 'Eligible' : 'Not Eligible') }}</span>
          <span>- {{ data }}%</span>
        </div>
        <!-- <div>
          <span>{{ data.upDown }}%</span>
          <feather-icon
            :icon="data.upDown > 0 ? 'ArrowUpIcon':'ArrowDownIcon'"
            :class="data.upDown > 0 ? 'text-success':'text-danger'"
            class="mb-25 ml-25"
          />
        </div> -->
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
// import { $themeColors } from '@themeConfig'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    // BDropdown,
    // BDropdownItem,
    BCardBody,
    VueApexCharts,
  },
  props: {
    sessionsByDeviceDonut: {
      type: Object,
      required: true,
    },
  },
}
</script>
