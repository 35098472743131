<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('Add New Registration') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Student -->
          <validation-provider
            #default="validationContext"
            name="Student"
            rules="required"
          >
            <b-form-group
              :label="$t('Student')"
              label-for="student"
              :state="getValidationState(validationContext)"
            >
              <div class="d-flex">
                <div
                  class="mr-50"
                  style="width: 93%"
                >
                  <v-select
                    v-model="selectedStudent"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="students"
                    :clearable="false"
                    @keyup.native="autoCompleteStudent($event.target.value, 'student')"
                    @click.native="autoCompleteStudent('', 'student')"
                  >
                    <span slot="no-options">
                      <div
                        v-if="isTypingStudent"
                        class="text-center d-flex align-items-center justify-content-center"
                      >
                        <b-spinner
                          variant="primary"
                          label="Text Centered"
                        />
                      </div>
                      <div
                        v-if="!isTypingStudent && !students.length"
                        class="text-center d-flex align-items-center justify-content-center"
                      >
                        No matching records found
                      </div>
                    </span>
                  </v-select>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </div>
                <b-button
                  v-b-modal.new-student-modal1
                  v-b-tooltip.hover.top="'Add new student'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                >
                  <feather-icon icon="UserPlusIcon" />
                </b-button>
              </div>
            </b-form-group>
          </validation-provider>
          <!-- Course -->
          <validation-provider
            #default="validationContext"
            name="course"
            rules="required"
          >
            <b-form-group
              :label="$t('Course')"
              label-for="course"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="selectedCourse"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="courses"
                :clearable="false"
                @keyup.native="autoCompleteCourse($event.target.value)"
                @click.native="autoCompleteCourse('')"
              >
                <span slot="no-options">
                  <div
                    v-if="isTypingCourse"
                    class="text-center d-flex align-items-center justify-content-center"
                  >
                    <b-spinner
                      variant="primary"
                      label="Text Centered"
                    />
                    <div
                      v-if="!isTypingCourse && !courses.length"
                      class="text-center d-flex align-items-center justify-content-center"
                    >
                      No matching records found
                    </div>
                  </div>
                </span>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
      <!-- Add student modal -->
      <b-modal
        id="new-student-modal1"
        title="New student informations"
        ok-only
        centered
        size="lg"
        no-close-on-backdrop
        ok-title="Add"
        hide-footer
      >
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormAdd"
        >
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(registerNewStudent)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col cols="6">
                <!-- Name -->
                <validation-provider
                  #default="validationContext"
                  name="Name"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Name')"
                    label-for="name"
                  >
                    <b-form-input
                      v-model="studentName"
                      autofocus
                      :state="getValidationState(validationContext)"
                      trim
                      :placeholder="$t('Name')"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6">
                <!-- Password -->
                <validation-provider
                  #default="validationContext"
                  name="Password"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Password')"
                    label-for="password"
                  >
                    <b-form-input
                      v-model="password"
                      autofocus
                      type="password"
                      :state="getValidationState(validationContext)"
                      trim
                      :placeholder="$t('Password')"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6">
                <!-- Email  -->
                <validation-provider
                  #default="validationContext"
                  name="Email"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Email')"
                    label-for="email"
                  >
                    <b-form-input
                      v-model="email"
                      autofocus
                      type="email"
                      :state="getValidationState(validationContext)"
                      trim
                      :placeholder="('Email')"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6">
                <!-- Phone  -->
                <validation-provider
                  #default="validationContext"
                  name="Phone"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Phone')"
                    label-for="phone"
                  >
                    <b-form-input
                      v-model="phone"
                      autofocus
                      type="number"
                      :state="getValidationState(validationContext)"
                      trim
                      :placeholder="$t('Phone')"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6">
                <!-- Date start -->
                <validation-provider
                  #default="validationContext"
                  name="Date of birth"
                >
                  <b-form-group
                    label="Date of birth"
                    label-for="date start"
                    :state="getValidationState(validationContext)"
                  >
                    <flat-pickr
                      v-model="dob"
                      class="form-control"
                      placeholder="Date of birth"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss', altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 } }"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6">
                <!-- City -->
                <validation-provider
                  #default="validationContext"
                  name="City"
                >
                  <b-form-group
                    :label="$t('City')"
                    label-for="city"
                  >
                    <b-form-input
                      v-model="city"
                      autofocus
                      :state="getValidationState(validationContext)"
                      trim
                      :placeholder="$t('City')"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('Add') }}
            </b-button>
          </b-form>
        </validation-observer>
      </b-modal>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInvalidFeedback, BButton, BSpinner, VBTooltip,
  BModal, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import axios from '@axios'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    BSpinner,
    BModal,
    BFormInput,
    BRow,
    BCol,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      query1: '',
      query2: '',
      students: [],
      courses: [],
      // whoPays: ['student', 'payer'],
      isTypingStudent: false,
      isTypingCourse: false,

    }
  },
  methods: {
    /* eslint-disable camelcase, no-unused-expressions */
    autoCompleteStudent(search, role_name) {
      this.isTypingStudent = true
      axios.post('/autocomplete/user', { search, role_name })
      .then(res => {
        this.students = res.data
        this.isTypingStudent = false
      })
    },

    autoCompleteCourse(search) {
      this.isTypingCourse = true
      axios.post('/autocomplete/course', { search })
      .then(res => {
        this.courses = res.data
        this.isTypingCourse = false
      })
    },
  },
  setup(props, { emit }) {
    const toast = useToast()
    const selectedStudent = ref({ name: '', id: null })
    const selectedCourse = ref({ name: '', id: null })
    // const selectedWhoPays = ref('student')

    const regData = {}

    const resetRegistrationData = () => {
      selectedStudent.value = {}
      selectedCourse.value = {}
      // selectedWhoPays.value = ''
    }
    const onSubmit = () => {
      regData.student_id = selectedStudent.value.id
      regData.course_id = selectedCourse.value.id
      // regData.who_pays = selectedWhoPays.value

      store.dispatch('app-user/addRegistration', regData)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }
    /* eslint-disable no-shadow */
    const studentName = ref(null)
    const email = ref(null)
    const password = ref(null)
    const phone = ref(null)
    const dob = ref(null)
    const city = ref(null)
    const registerNewStudent = function () {
      const payload = {
        name: studentName.value,
        email: email.value,
        password: password.value,
        phone: phone.value,
        dob: dob.value,
        city: city.value,
        role: 'student',
      }
      store.dispatch('registrations/addNewStudent', payload)
      .then(res => {
        selectedStudent.value = { name: res.data.userData.fullName, id: res.data.userData.id }
        this.$bvModal.hide('new-student-modal1')
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.errors,
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      })
    }
    const {
      refFormObserver,
      refFormAdd,
      getValidationState,
      resetForm,
    } = formValidation(resetRegistrationData)

    return {
      onSubmit,
      registerNewStudent,
      studentName,
      email,
      password,
      phone,
      dob,
      city,
      selectedStudent,
      selectedCourse,
      // selectedWhoPays,
      regData,

      refFormObserver,
      refFormAdd,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
