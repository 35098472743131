<template>
  <span>{{ content.text }}</span>
</template>

<script>
    export default {
        name: 'TextComponent',
        props: {
            content: Object,
        },
    }
</script>

<style scoped>

</style>
