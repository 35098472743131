<template>
  <b-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="$i18n.locale = localeObj.locale; sendLanguage(localeObj.locale)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
// import { getUserData } from '@/auth/utils'
// import store from '@/store'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
  created() {
    console.log(this.$i18n.locale)
  },
  methods: {
    sendLanguage(language) {
      // const userData = getUserData()
      this.$http.post('/user/change/language', { language }).then(() => {
        const userData = JSON.parse(localStorage.userData)
        userData.language = language
        localStorage.setItem('userData', JSON.stringify(userData))
      })
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      },
      {
        locale: 'fr',
        img: require('@/assets/images/flags/fr.png'),
        name: 'French',
      },
      // {
      //   locale: 'de',
      //   img: require('@/assets/images/flags/de.png'),
      //   name: 'German',
      // },
      {
        locale: 'pt',
        img: require('@/assets/images/flags/pt.png'),
        name: 'Portuguese',
      },
    ]
    /* eslint-disable global-require */
    // const sendLanguage = local => {
    //   this.$http.post('/kfljsdfjsd', local)
    //   console.log(local)
    //   store.state.appConfig.local = local
    //   this.$http.post('/locals/lan', local).then(res => console.log(res.data))
    //   console.log(store.state.appConfig.local)
    // }
    return {
      locales,
      // sendLanguage,
    }
  },
}
</script>

<style>

</style>
