<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ eventLocal.id ? 'Update': 'Add' }} Session (lesson: {{ eventLocal.extendedProps.lesson_number }})
          </h5>
          <div>
            <!-- <feather-icon
              v-if="eventLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-event'); hide();"
            /> -->
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Title -->
            <validation-provider
              #default="validationContext"
              name="Title"
              rules="required"
            >
              <b-form-group
                label="Title"
                label-for="event-title"
              >
                <b-form-input
                  id="event-title"
                  v-model="eventLocal.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Event Title"
                  disabled
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Start Date -->
            <b-form-group
              label="Date"
              label-for="date"
            >
              <flat-pickr
                v-model="eventLocal.date"
                class="form-control"
                :config="{ dateFormat: 'Y-m-d', altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 } }"
                @input="val => {eventLocal.start = `${eventLocal.date} ${eventLocal.time_from}`; eventLocal.end = `${eventLocal.date} ${eventLocal.time_to}`; $emit('reset-date-error')}"
              />
              <small class="text-danger" v-if="errors">{{ errors.start[0] ? errors.start[0].split('.')[0] : '' }}</small>
            </b-form-group>
            <b-modal
              v-model="shallPostponeCourses"
              title="Warning!"
              ok-title="Yes"
              cancel-title="No"
              centered
              @ok="onSubmit(true, errors.start && errors.start.length > 1 && errors.start[1] === 'collides')"
            >
              <b-card-text>{{ errors.start[0] }}</b-card-text>
            </b-modal>
            <!-- {{ eventLocal }} -->
            <b-row>
              <b-col cols="6">
                <!-- From -->
                <validation-provider
                  #default="validationContext"
                  name="From"
                  rules="required"
                >

                  <b-form-group
                    label="From"
                    label-for="time-to"
                    :state="getValidationState(validationContext)"
                  >
                    <flat-pickr
                      v-model="eventLocal.time_from"
                      class="form-control"
                      :config="{ noCalendar: true, enableTime: true, minTime: '07:00', time_24hr: true }"
                      @input="val => eventLocal.start = `${eventLocal.date} ${eventLocal.time_from}:00`"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6">
                <!-- To -->
                <validation-provider
                  #default="validationContext"
                  name="To"
                  rules="required"
                >

                  <b-form-group
                    label="To"
                    label-for="time-to"
                    :state="getValidationState(validationContext)"
                  >
                    <flat-pickr
                      v-model="eventLocal.time_to"
                      class="form-control"
                      :config="{ noCalendar: true, enableTime: true, time_24hr: true, minTime: eventLocal.time_from }"
                      @input="val => eventLocal.end = `${eventLocal.date} ${eventLocal.time_to}:00`"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- All Day -->
            <!--            <b-form-group>-->
            <!--              <b-form-checkbox-->
            <!--                v-model="eventLocal.allDay"-->
            <!--                name="check-button"-->
            <!--                switch-->
            <!--                inline-->
            <!--              >-->
            <!--                All Day-->
            <!--              </b-form-checkbox>-->
            <!--            </b-form-group>-->

            <!-- Event URL -->
            <validation-provider
              #default="validationContext"
              name="HomeWork URL"
              rules="url"
            >

              <b-form-group
                label="Homework URL"
                label-for="event-url"
              >
                <b-form-input
                  id="event-url"
                  v-model="eventLocal.extendedProps.homework.link"
                  type="url"
                  :state="getValidationState(validationContext)"
                  placeholder="htttps://www.example.com"
                  trim
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              :name="eventLocal.extendedProps.old_teacher ? 'Substitute teacher' : 'Teacher'"
            >
              <!-- Teacher -->
              <b-form-group
                :label="eventLocal.extendedProps.old_teacher ? 'Substitute teacher' : 'Teacher'"
                label-for="teacher"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventLocal.extendedProps.teacher"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :close-on-select="true"
                  :options="teachersBySession"
                  label="name"
                  input-id="add-guests"
                  :disabled="!eventLocal.extendedProps.shall_update"
                  :selectable="el => el.available === 'available'"
                >
                  <span slot="no-options">
                    <div
                      v-if="!hasLoadedTeachers"
                      class="text-center d-flex align-items-center justify-content-center"
                    >
                      <b-spinner
                        variant="primary"
                        label="Text Centered"
                      />
                    </div>
                    <div
                      v-if="hasLoadedTeachers && teachersBySession.length === 0"
                      class="text-center d-flex align-items-center justify-content-center"
                    >
                      No matching records found
                    </div>
                  </span>
                  <template #option="{ avatar, name, available }">
                    <div class="d-flex justify-content-between">
                      <div>
                        <b-avatar
                          size="sm"
                          :src="avatar"
                        />
                        <span class="d-inline ml-50 align-middle">{{ name }}</span>
                      </div>
                      <small>
                        <b-badge
                          pill
                          :variant="`light-${available === 'available' ? 'success' : 'danger'}`"
                          class="text-capitalize"
                        >
                          {{ available === 'available' ? 'Available' : 'Unavailable' }}
                        </b-badge>
                      </small>
                      <!-- <b-badge pill :variant="available === 'available' ? 'success' : 'danger'" class="text-capitalize">{{ available }}</b-badge> -->
                    </div>
                  </template>

                  <template #selected-option="{ avatar, name }">
                    <b-avatar
                      size="sm"
                      class="border border-white"
                      :src="avatar"
                    />
                    <span class="ml-50 align-middle"> {{ name }}</span>
                  </template>
                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Substitute -->
            <validation-provider
              #default="validationContext"
              :name="eventLocal.extendedProps.old_teacher ? 'Substitute teacher' : 'Teacher'"
            >
              <b-form-group
                v-if="eventLocal.extendedProps.old_teacher"
                label="Teacher"
                label-for="add-guests"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventLocal.extendedProps.old_teacher"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :close-on-select="true"
                  :clearable="false"
                  :options="[eventLocal.extendedProps.old_teacher]"
                  label="name"
                  input-id="add-guests"
                >
                  <template #option="{ avatar, name, available }">
                    <div class="d-flex justify-content-between">
                      <div>
                        <b-avatar
                          size="sm"
                          :src="avatar"
                        />
                        <span class="ml-50 align-middle"> {{ name }}</span>
                      </div>
                      <small class="badge badge-pill" :class="available === 'available' ? 'badge-success' : 'badge-danger'">{{ available }}</small>
                    </div>
                  </template>

                  <template #selected-option="{ avatar, name }">
                    <b-avatar
                      size="sm"
                      class="border border-white"
                      :src="avatar"
                    />
                    <span class="ml-50 align-middle"> {{ name }}</span>
                  </template>
                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Classrooms -->
            <validation-provider
              #default="validationContext"
              :name="eventLocal.extendedProps.old_teacher ? 'Substitute teacher' : 'Teacher'"
            >
              <b-form-group
                label="Update Classroom"
                label-for="add-guests"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventLocal.extendedProps.classroom"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :close-on-select="true"
                  :options="classroomsBySession"
                  label="name"
                  :selectable="el => el.available === 'available'"
                >
                  <span slot="no-options">
                    <div
                      v-if="!hasLoadedClassrooms"
                      class="text-center d-flex align-items-center justify-content-center"
                    >
                      <b-spinner
                        variant="primary"
                        label="Text Centered"
                      />
                    </div>
                    <div
                      v-if="hasLoadedClassrooms && classroomsBySession.length === 0"
                      class="text-center d-flex align-items-center justify-content-center"
                    >
                      No matching records found
                    </div>
                  </span>
                  <template #option="{ code, available, max_students }">
                    <div class="row">
                      <strong class="col-3 d-block"> {{ code }}</strong>
                      <small class="col-6 text-secondary">Students limit: <strong>{{ max_students }}</strong></small>
                      <small class="col-3 d-flex justify-content-end">
                        <b-badge
                          pill
                          :variant="`light-${available === 'available' ? 'success' : 'danger'}`"
                          class="text-capitalize"
                        >
                          {{ available === 'available' ? 'Available' : 'Unavailable' }}
                        </b-badge>
                      </small>
                      <!-- <small class="ml-50" :class="available ? 'text-success' : 'text-danger'">{{ available ? 'Available' : 'Unavailable' }}</small> -->
                    </div>
                  </template>
                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Location -->
            <!--            <b-form-group-->
            <!--              label="Location"-->
            <!--              label-for="event-location"-->
            <!--            >-->
            <!--              <b-form-input-->
            <!--                id="event-location"-->
            <!--                v-model="eventLocal.extendedProps.location"-->
            <!--                trim-->
            <!--                placeholder="Event Location"-->
            <!--              />-->
            <!--            </b-form-group>-->

            <!-- Textarea -->
            <!--            <b-form-group-->
            <!--              label="Description"-->
            <!--              label-for="event-description"-->
            <!--            >-->
            <!--              <b-form-textarea-->
            <!--                id="event-description"-->
            <!--                v-model="eventLocal.extendedProps.description"-->
            <!--              />-->
            <!--            </b-form-group>-->

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="!eventLocal.date"
              >
                {{ eventLocal.id ? 'Update' : 'Add ' }}
              </b-button>
              <b-button
                v-if="eventLocal.id"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="secondary"
                @click="$emit('show-attendance-modal')"
              >
                <!-- <feather-icon
                  icon="CheckSquareIcon"
                  class="cursor-pointer"
                /> -->
                List attendance
              </b-button>
              <!-- <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button> -->
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BSpinner,
  BAvatar, BModal, BCardText, BBadge,
  // BFormTextarea,
  BButton, BFormInvalidFeedback, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs, watch } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import useCalendarEventHandler from './useCalendarEventHandler'

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BModal,
    BCardText,
    BSpinner,
    BBadge,
    // BFormCheckbox,
    // BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
    errors: {
      type: Object,
    },
  },
  data() {
    return {
      avatarText,
      required,
      email,
      url,
      teachersBySession: [],
      hasLoadedTeachers: false,
      classroomsBySession: [],
      hasLoadedClassrooms: false,
    }
  },
  watch: {
    '$store.state.calendar.suggestedTeachers': {
      handler() {
        if (this.eventLocal.extendedProps.teacher_id) this.eventLocal.extendedProps.teacher = this.$store.state.calendar.suggestedTeachers.find(el => el.id === this.eventLocal.extendedProps.teacher_id)
      },
      deep: true,
    },
    // '$store.state.calendar.suggestedTeachers': {
    //   handler() {
    //     console.log(this.$store.state.calendar.suggestedTeachers)
    //     if (this.eventLocal.extendedProps.teacher_id) this.eventLocal.extendedProps.teacher = this.$store.state.calendar.suggestedTeachers.find(el => el.id === this.eventLocal.extendedProps.teacher_id)
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    this.getTeachersBySession()
    this.getClassroomsBySession()
    setTimeout(() => {
      // this.teachersList = store.state.calendar.selectedCalendars.teachersList.rows
      this.classroomsList = store.state.calendar.selectedCalendars.classroomsList.rows
    }, 1200)
  },
  created() {
    // if (this.eventLocal.extendedProps.classroom_id) this.eventLocal.extendedProps.classroom = this.$store.state.calendar.suggestedClassrooms.find(el => el.id === this.eventLocal.extendedProps.classroom_id)
    if (!this.eventLocal.extendedProps.homework) {
      this.eventLocal.extendedProps.homework = { link: null }
    }
  },
  /* eslint-disable camelcase */
  methods: {
    getTeachersBySession() {
      store.dispatch('coursesStoreModule/getTeachersBySession', { sessions: [this.event.id] })
      .then(res => {
        this.hasLoadedTeachers = true
        this.teachersBySession = res.data.allTeachers
      })
    },
    getClassroomsBySession() {
      store.dispatch('coursesStoreModule/getClassroomsBySession', { session_id: this.event.id })
      .then(res => {
        this.hasLoadedClassrooms = true
        this.classroomsBySession = res.data.allClassrooms
      })
    },
  },
  setup(props, { emit }) {
    const shallPostponeCourses = ref(false)
    watch(props.errors, () => {
      shallPostponeCourses.value = props.errors.start.length > 1
    })
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)
    const teachersList = ref([])
    const classroomsList = ref([])

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,
      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      guestsOptions,
      teachersList,
      classroomsList,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
      shallPostponeCourses,

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
