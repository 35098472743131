<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- filter status -->
          <b-col
            cols="12"
            md="6"
            class="mb-1"
          >
            <!-- <b-button
              v-if="selectedRows.length > 0"
              variant="primary"
              @click="createGroup(selectedRows)"
            >
              Create new group
          </b-button> -->
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
                debounce="500"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refgroupTable"
        class="position-relative"
        :items="fetchElements"
        responsive="sm"
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
        striped
      >
        <!-- selectable -->
        <!-- @row-selected="onRowSelected" -->
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>
        <template #cell(selected)="data">
          <b-form-checkbox
            v-model="selectedRows"
            :value="data.item.group"
            name="selected-rows"
            disabled
          />
          <!-- @change="onRowSelected" -->
        </template>
        <template #cell(student_name)=data>
          <b-link
            v-b-tooltip.hover.top="data.item.student_name"
            :to="{ name: 'apps-users-view',params: { id: data.item.student_id } }"
            style="max-width: 180px"
            class="d-block font-weight-bold text-truncate mr-1"
          >
            {{ data.item.student_name }}
          </b-link>
          <small class="text-secondary">{{ data.item.student_id }}</small>
        </template>
        <template #cell(group)=data>
          <strong class="d-block">{{ data.item.group }}</strong>
          <!-- <small class="text-secondary text-nowrap">{{ data.item.session_date }}</small> -->
        </template>
        <template #cell(course)=data>
          <span v-if="data.item.course" class="d-block text-nowrap">{{ data.item.course.name }}</span>
        </template>
        <template #cell(period)=data>
          <span v-if="data.item.course" class="d-block text-nowrap">{{ data.item.course.date_start }} | {{ data.item.course.date_end }}</span>
          <small v-if="data.item.course" class="text-secondary">{{ data.item.course.time_from }} | {{ data.item.course.time_to }}</small>
        </template>
        <template #cell(days)=data>
          <span v-if="data.item.course" class="text-secondary">{{ data.item.course.days }}</span>
        </template>
        <!-- Column: Comment -->
        <template #cell(comment)="data">
          <span
            v-b-popover.hover.focus="data.item.claim_message"
            style="max-width: 140px"
            class="d-block text-truncate"
            title="Details"
          >
            {{ data.item.claim_message }}
          </span>
        </template>
        <!-- Column: Date -->
        <template #cell(claim_date)="data">
            <span class="d-block text-nowrap">
              {{ data.item.claim_date }}
            </span>
        </template>
        <template #cell(teacher)="data">
          <b-avatar
            v-if="data.item.teacher"
            v-b-tooltip.hover.top="data.item.teacher.name"
            class="pull-up pending-teachers"
            button
            :title="data.item.teacher.name"
            :src="data.item.teacher.avatar.split('/')[5] !== 'default-avatar.jpg' ? data.item.teacher.avatar : null"
            :text="avatarText(data.item.teacher.name)"
          />
        </template>
        <template #cell(attendance)="data">
          <strong>{{ data.item.claim_old_hours ? data.item.claim_old_hours : 0 }} h</strong>
        </template>
        <template #cell(notes)="data">
          <b-button
            :id="`popover-target-${data.item.id}`"
            variant="flat-primary"
            size="sm"
            class="btn-icon rounded-circle"
            @click="handleChatClick({model:'attendance', id: data.item.attendance_id})"
          >
            <feather-icon
              icon="ClipboardIcon"
              class="align-middle text-body"
              badge-classes="bg-danger"
              :badge="data.item.comments_number > 0 ? data.item.comments_number : ''"
              size="16"
            />
          </b-button>
        </template>
        <template #cell(actions)="data">
          <div class="d-flex justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              :disabled="data.item.claim_status === 'approved'"
              @click="createGroup(data.item.group)"
            >
              Create new group
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="btn-icon ml-1"
              :disabled="data.item.claim_status === 'approved'"
              @click="data.toggleDetails"
            >
              <feather-icon size="10" v-if="!data.detailsShowing" icon="PlusIcon" />
              <feather-icon size="10" v-if="data.detailsShowing" icon="MinusIcon" />
            </b-button>
          </div>
        </template>
        <template #row-details="row">
          <b-card class="details-container p-0" no-body>
            <courses-list-group
              details
              :singleCourseGroup="row.item.group"
              @suggest-classrooms="$emit('suggest-classrooms', record, selectedDays)"
              @suggest-teacher="$emit('suggest-teacher', record, selectedDays)"
            />
          </b-card>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="!isBusy"
              v-model="currentPage"
              :total-rows="totalElements"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <div
      v-if="isChatActive"
      class="notesModule shadow-lg"
    >
      <div style="background: #fff" class="pb-1">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="light"
          size="sm"
          class="btn-icon shadow-sm ml-auto "
          @click="isChatActive = false"
          style="display: block; transform: translate(8px, -2px);"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
      <chat-module
        :key="chatModelId.id"
        @decrease-notes="refetchData"
        @notes-increament="refetchData"
        :model-id="chatModelId.id"
        model-type="attendance"
      />
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, VBPopover, BFormCheckbox,
  BPagination, BSpinner, VBTooltip, BAvatar, BButton, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import CoursesListGroup from '@/views/courses/admin/courses-list/CoursesListGroup.vue'
import ChatModule from '@/views/admin/ChatModule.vue'
import useGroupList from './useGroupList'
import groupStoreModule from './groupStoreModule'

export default {
  components: {
    CoursesListGroup,
    BFormCheckbox,
    ChatModule,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BSpinner,
    BAvatar,
    BLink,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      showAllPendingTeachers: false,
    }
  },
  setup() {
    const GROUP_STORE_MODULE_NAME = 'group'
    // Register module
    if (!store.hasModule(GROUP_STORE_MODULE_NAME)) store.registerModule(GROUP_STORE_MODULE_NAME, groupStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(GROUP_STORE_MODULE_NAME)) store.unregisterModule(GROUP_STORE_MODULE_NAME)
    })
    const {
      createGroup,
      details,
      selectedFilter,
      filters,
      approveClaim,
      rejectClaim,
      isBusy,
      isBusyDetail,
      fetchElements,
      tableColumns,
      detailsColumns,
      perPage,
      currentPage,
      totalElements,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refgroupTable,
      refetchData,
      handleChatClick,
      isChatActive,
      chatModelId,
      selectedRows,
    } = useGroupList()

    const onRowSelected = items => {
      selectedRows.value = items.map(item => item.group)
    }

    return {
      selectedRows,
      onRowSelected,
      createGroup,
      details,
      handleChatClick,
      isChatActive,
      chatModelId,
      // Filters
      selectedFilter,
      filters,
      avatarText,

      // Actions
      fetchElements,
      approveClaim,
      rejectClaim,

      // Loader
      isBusy,
      isBusyDetail,

      tableColumns,
      detailsColumns,
      perPage,
      currentPage,
      totalElements,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refgroupTable,
      refetchData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.details-container td {
  padding-top: 0.5px !important;
  padding-bottom: 0.5px !important;
}
.details-container .table-responsive {
  min-height: 100% !important;
}
</style>
