<template>
  <b-card
    class="profile-header mb-2"
  >
    <!-- profile picture -->
    <div class="position-relative mt-5 pt-5 mb-5">
      <div class="profile-img-container d-flex align-items-center">
        <div class="profile-img">
          <b-img
            :src="headerData.avatar"
            rounded
            fluid
            alt="profile photo"
          />
        </div>
        <div class="d-flex flex-column ml-1">
          <div class="mb-1">
            <h4 class="mb-0">
              {{ headerData.fullName }}
            </h4>
            <span class="card-text">{{ headerData.email }}</span>
          </div>
          <div class="d-flex flex-wrap">
            <b-button
              :to="{ name: 'pages-account-setting', params: { id: headerData.id } }"
              variant="primary"
              class="mr-1"
            >
              {{ $t('Edit account') }}
            </b-button>
            <b-button
              v-if="userRole === 'student'"
              v-b-modal.modal-1
              variant="outline-danger"
            >
              {{ $t('Delete account') }}
            </b-button>
            <b-modal
              id="modal-1"
              title="Are you sure you want to delete your account"
              ok-only
              centered
              ok-title="Accept"
              @ok="deleteAccount"
            >
              <b-card-text>
                <h5>Deleting your account is permanent and will remove all content including certificates, attendance, invoices, avatars and profile settings. Are you sure you want to delete your account?</h5>
              </b-card-text>
            </b-modal>
          </div>
        </div>
        <!--/ profile title -->
      </div>
    </div>
    <!--/ profile picture -->
  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BCard, BImg, BButton, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default {
  components: {
    BCard,
    BImg,
    BButton,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
    userRole: {
      type: String,
      required: true,
    },
  },
  methods: {
    deleteAccount() {
      this.$http.post('/account/delete').then(() => {
        useJwt.logout()

        // logout from api
        useJwt.logout()
        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

        // Remove userData from localStorage
        localStorage.removeItem('userData')

        // Reset ability
        this.$ability.update(initialAbility)

        // Redirect to login page
        this.$router.push({ name: 'auth-login' })
      })
    },
  },
}
</script>
