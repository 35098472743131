import {
  ref, watch, computed, onMounted,
} from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import store from '@/store'
import router from '@/router'

export default function useUsersList() {
  // Use toast
  const toast = useToast()
  const { t } = useI18nUtils()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'selected', label: 'reg id' },
    // { key: 'id', label: t('id'), sortable: false },
    // { key: 'id', label: t('Student id'), sortable: false },
    { key: 'student', label: t('name'), sortable: true },
    { key: 'course_group', label: t('course'), sortable: true },
    { key: 'date', label: t('registration date'), sortable: true },
    // { key: 'visa', label: 'visa', sortable: true },
    { key: 'who_pays', label: t('payer'), sortable: true },
    { key: 'payment_method', label: ('payment method') },
    { key: 'payment_status', label: ('payment status') },
    { key: 'status', label: t('status'), sortable: true },
    { key: 'attendance', label: t('attendance') },
    { key: 'comment', label: t('notes') },
    { key: 'actions', label: t('actions'), tdClass: 'table-actions' },
  ]
  const tableColumns1 = [
    { key: 'selected', label: 'reg id' },
    // { key: 'id', label: t('Student id'), sortable: false },
    { key: 'student', label: t('name'), sortable: true },
    { key: 'course_group', label: t('course'), sortable: true },
    { key: 'date', label: t('creation date'), sortable: true },
    // { key: 'visa', label: 'visa', sortable: true },
    { key: 'who_pays', label: t('payer'), sortable: true },
    { key: 'payment_method', label: ('payment method') },
    { key: 'payment_status', label: ('payment status') },
    // { key: 'payer_confirmed', label: t('payer_confirmed') },
    { key: 'status', label: t('status'), sortable: true },
    { key: 'comment', label: t('notes') },
    { key: 'actions', label: t('actions'), tdClass: 'table-actions' },
  ]
  const tableColumns2 = [
    { key: 'selected', label: 'reg id' },
    // { key: 'id', label: t('id'), sortable: false },
    // { key: 'student', label: t('student'), sortable: true },
    { key: 'course_group', label: t('course'), sortable: true },
    { key: 'date', label: t('creation date'), sortable: true },
    // { key: 'visa', label: 'visa', sortable: true },
    { key: 'who_pays', label: 'payer', sortable: true },
    { key: 'payment_method', label: ('payment method') },
    { key: 'payment_status', label: ('payment status') },
    // { key: 'payer_confirmed', label: t('payer_confirmed') },
    { key: 'status', label: t('status'), sortable: true },
    { key: 'comment', label: t('notes') },
    { key: 'actions', label: t('actions'), tdClass: 'table-actions' },
  ]
  /* eslint-disable camelcase */
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const selectedRows = ref([])
  const unselectedRows = ref([])
  const courseId = ref(null)
  const student = ref(null)
  const course = ref(null)
  const student_id = ref(null)
  const initialDate = ref(null)
  const dob = ref('')
  // const statuss = ref(null)
  const visa = ref(null)
  const who_pays = ref({ name: 'All payers', id: null })
  const payerOptions = ref([])
  const statusOptions = ref([
    // { label: 'All', value: 'all' },
    // { label: 'Confirmed', value: 'confirmed' },
    // { label: 'Canceled', value: 'canceled' },
    // { label: 'Pending', value: 'pending' },
    // { label: 'Deleted', value: 'deleted' },
  ])
  const regStatusOptions = ref([
    { label: 'All registration statuses', value: 'all' },
    { label: 'Confirmed', value: 'confirmed' },
    { label: 'Canceled', value: 'canceled' },
    { label: 'Pending', value: 'pending' },
    { label: 'Deleted', value: 'deleted' },
  ])
  const defaultStatus = ref({ name: 'All payment statuses', id: null })
  const paymentFilter = ref({ name: 'All methods', id: null })
  const regDefaultStatus = ref({ label: 'All registration statuses', value: 'all' })
  /* eslint-disable-next-line */
  const role_name = ref(window.location.href.split('/')[6])
  const dateStart = ref(null)
  const dateEnd = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  const urlParams = ref(new URLSearchParams())
  if (router.currentRoute.query.search) searchQuery.value = router.currentRoute.query.search
  if (router.currentRoute.query.page) currentPage.value = router.currentRoute.query.page
  if (router.currentRoute.query.perPage) perPage.value = router.currentRoute.query.perPage
  if (router.currentRoute.query.dob) { dob.value = router.currentRoute.query.dob; initialDate.value = `${dob.value.split('-')[2]}/${dob.value.split('-')[1]}/${dob.value.split('-')[0]}` }
  if (router.currentRoute.query.reg_status) regDefaultStatus.value = regStatusOptions.value.find(el => el.value === router.currentRoute.query.reg_status)
  // if (router.currentRoute.query.p_status) defaultStatus.value = router.currentRoute.query.p_status
  // if (router.currentRoute.query.p_method) paymentFilter.value.id = router.currentRoute.query.p_method
  if (router.currentRoute.query.student) student.value = { id: router.currentRoute.query.student.split('|')[0], name: router.currentRoute.query.student.split('|')[1], email: router.currentRoute.query.student.split('|')[2] }
  if (router.currentRoute.query.course) course.value = { id: router.currentRoute.query.course.split('|')[0], code: router.currentRoute.query.course.split('|')[1], group: router.currentRoute.query.course.split('|').length === 3 ? router.currentRoute.query.course.split('|')[2] : null }
  if (router.currentRoute.query.start) {
    dateStart.value = router.currentRoute.query.start
    dateStart.value = router.currentRoute.query.start
  }
  if (router.currentRoute.query.end) dateEnd.value = router.currentRoute.query.end
  const recheckLoading = () => {
    if (!isBusy.value) return refetchData()
    return setTimeout(() => recheckLoading(), 1000)
  }

  /* eslint-disable-next-line */
  watch([currentPage, perPage, dob, roleFilter, planFilter, statusFilter, role_name, student, course, defaultStatus, paymentFilter, regDefaultStatus, visa, who_pays, student_id, dateStart, dateEnd],  () => {
    router.currentRoute.query.refresh = 1
    urlParams.value.set('?search', searchQuery.value ? searchQuery.value : '')
    urlParams.value.set('dob', dob.value ? dob.value : '')
    urlParams.value.set('page', currentPage.value ? currentPage.value : '')
    urlParams.value.set('perPage', perPage.value ? perPage.value : '')
    urlParams.value.set('reg_status', regDefaultStatus.value.value ? regDefaultStatus.value.value : '')
    urlParams.value.set('p_status', defaultStatus.value.id ? defaultStatus.value.id : '')
    urlParams.value.set('p_method', paymentFilter.value.id ? paymentFilter.value.id : '')
    urlParams.value.set('payer', who_pays.value.value ? who_pays.value.value : '')
    urlParams.value.set('student', student.value ? `${student.value.id}|${student.value.name}|${student.value.email}` : '')
    // console.log(course.value)
    urlParams.value.set('course', course.value ? `${course.value.id}|${course.value.code}|${course.value.group}` : '')
    urlParams.value.set('start', dateStart.value ? dateStart.value : '')
    urlParams.value.set('end', dateEnd.value ? dateEnd.value : '')
    window.history.replaceState(null, null, decodeURIComponent(urlParams.value))
    recheckLoading()
  })
  watch(initialDate, () => {
    setTimeout(() => {
      // Check whether the date is fully typed and is valid
      if (initialDate.value === '') {
        dob.value = null
      }
      if ((initialDate.value.length === 10 && document.getElementById('dobInput').classList.contains('is-valid'))) {
        // Reformate date
        dob.value = `${initialDate.value.split('/')[2]}-${initialDate.value.split('/')[1]}-${initialDate.value.split('/')[0]}`
      }
    }, 500)
  })
  watch(searchQuery, () => {
    urlParams.value.set('?search', searchQuery.value ? searchQuery.value : '')
    window.history.replaceState(null, null, decodeURIComponent(urlParams.value))
    refetchData()
  })
  const isBusy = ref(false)
  const selectAll = ref(false)

  /* eslint-disable no-param-reassign, no-dupe-keys, no-nested-ternary */
  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('registerStoreModule/fetchUsers', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role_name: roleFilter.value,
        plan: planFilter.value,
        // status: statusFilter.value,
        single_course_id: courseId.value,
        status: regDefaultStatus.value.value,
        student: student_id.value ? student_id.value : student.value ? student.value.id : student.value,
        course: course.value ? course.value.id : course.value,
        payment_status: defaultStatus.value.id,
        visa: visa.value,
        payer_id: who_pays.value.id,
        paid_method_id: paymentFilter.value.id,
        date_start: dateStart.value,
        date_end: dateEnd.value,
        selectAll: selectAll.value,
        search_dob: dob.value,
        // role_name: role_name.value,
      })
      .then(() => {
        const users = store.state.registerStoreModule.registrations.rows
        const total = store.state.registerStoreModule.registrations.totalRecords
        if (selectedRows.value.length > 0) {
          selectedRows.value = selectedRows.value.map(row => {
            const matchedRow = users.find(u => u.id === row.id)
            return matchedRow || row
          })
        }
        if (selectAll.value) {
          users.forEach(el => {
            if (!selectedRows.value.some(row => row.id === el.id) && !unselectedRows.value.some(row => row.id === el.id)) selectedRows.value.push(el)
          })
        }
        callback(users)
        totalUsers.value = total
        currentUser.value = JSON.parse(localStorage.getItem('userData'))
      })
      .catch(() => {
        isBusy.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching registrations list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const resetFilter = () => {
    student.value = null
    course.value = null
    dateStart.value = null
    dateEnd.value = null
    who_pays.value = { name: 'All payers', id: null }
    defaultStatus.value = { name: 'All payment statuses', id: null }
    paymentFilter.value = { name: 'All methods', id: null }
    regDefaultStatus.value = { label: 'All registration statuses', value: 'all' }
  }
  const isLoadingExcelDoc = ref(false)
  /* eslint-disable no-shadow, prefer-destructuring */
  const getExcelDoc = (student, course, date_start, date_end, payer_id, payment_status, paid_method_id, registration_status, selected, selectAll, unselected) => {
    isLoadingExcelDoc.value = true
    store.dispatch('registrations/getExcelDoc', {
      student: student ? student.id : null,
      course: course ? course.id : null,
      date_start,
      date_end,
      payer_id,
      payment_status,
      paid_method_id,
      registration_status,
      search: searchQuery.value,
      selected: selected.length > 0 ? selected.map(el => el.id) : [],
      unselected: unselected.length > 0 ? unselected.map(el => el.id) : [],
      selectAll,
      search_dob: dob.value,
    }).then(() => {
      isLoadingExcelDoc.value = false
    }).catch(err => {
      isLoadingExcelDoc.value = false
      toast({
        component: ToastificationContent,
        props: {
          title: err.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }
  const isBigModalActive = ref(false)
  const UsersToAdd = ref([])
  const UsersAlreadyAdded = ref([])
  const spinner = ref(false)
  const currentCourseData = ref(null)
  const msgs = ref([])
  // const cities = ref([])
  const masters = ref([])
  const teachers = ref([])
  const classrooms = ref([])
  const currentUser = ref({})
  const statusArray = ref([
    { name: 'Active', value: 'active', color: 'primary' },
    { name: 'Hidden', value: 'hidden', color: 'primary' },
    { name: 'Past', value: 'past', color: 'primary' },
    { name: 'Deleted', value: 'deleted', color: 'primary' },
    { name: 'Planned', value: 'planned', color: 'primary' },
  ])
  const TofDArray = ref([
    { name: 'daytime', value: 'daytime' },
    { name: 'anytime', value: 'anytime' },
    { name: 'evening', value: 'evening' },
  ])
  const ModalData = (id, tab) => {
    store.dispatch('registrations/getSCourse', id)
      .then(res => {
        currentCourseData.value = res.data.data
        currentCourseData.value.pendingTeachers = res.data.pendingTeachers
        currentCourseData.value.currentTeacher = res.data.currentTeacher
        currentCourseData.value.tab = tab
        const sDays = currentCourseData.value.days
        const daysArr = sDays.split(',')
        currentCourseData.value.days = daysArr
        if (currentCourseData.value.conversation === null) {
          msgs.value = []
          UsersToAdd.value = []
          UsersAlreadyAdded.value = []
        } else {
          if (typeof currentCourseData.value.conversation !== 'undefined') {
            msgs.value = currentCourseData.value.conversation.messages
          }
          UsersToAdd.value = currentCourseData.value.users_To_Add
          UsersAlreadyAdded.value = currentCourseData.value.users_Already_In
        }
        isBigModalActive.value = true
      }).catch(error => console.log(error))
  }
  // const fetchCities = () => {
  //   store.dispatch('registrations/fetchCities')
  //     .then(res => {
  //       cities.value = res.data.rows
  //     })
  // }
  const fetchMCourses = () => {
    store.dispatch('registerStoreModule/fetchMCourses')
      .then(res => {
        masters.value = res.data.rows
      })
  }
  const fetchTeachers = () => {
    store.dispatch('registerStoreModule/fetchTeachers')
      .then(res => {
        teachers.value = res.data.rows
      })
  }
  const fetchClassrooms = () => {
    store.dispatch('registerStoreModule/fetchClassrooms')
      .then(res => {
        classrooms.value = res.data.rows
      })
  }
  const suggestClassrooms = (record, Days) => {
    record.days = Days.join(',')
    store.dispatch('registerStoreModule/suggestClassrooms', record)
      .then(res => {
        classrooms.value = res.data.available
      })
  }
  const suggestTeacher = (record, Days) => {
    /* eslint-disable no-param-reassign */
    record.days = Days.join(',')
    store.dispatch('registerStoreModule/suggestTeacher', record)
      .then(res => {
        teachers.value = res.data.available
      })
  }
  const fetchPayers = () => {
    store.dispatch('registerStoreModule/fetchPayers').then(res => {
      payerOptions.value = res.data.rows
      payerOptions.value.unshift({ name: 'All payers', id: null })
      if (router.currentRoute.query.payer) who_pays.value = payerOptions.value.find(el => el.code === router.currentRoute.query.payer)
    })
  }
  // call on mounted
  onMounted(fetchMCourses)
  onMounted(fetchTeachers)
  onMounted(fetchClassrooms)
  onMounted(fetchPayers)
  // onMounted(fetchCities)

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'Paid') return 'success'
    if (status === 'Unpaid') return 'warning'
    if (status === 'Refunded') return 'danger'
    if (status === 'To be refunded') return 'danger'
    return 'secondary'
  }
  const resolveVisaStatusVariant = status => {
    if (status === false) return '<span class="badge text-capitalize badge-light-danger badge-pill">No visa</span>'
    if (status === true) return '<span class="badge text-capitalize badge-light-success badge-pill">Visa</span>'
    return '<span class="light-danger"></span>'
  }
  // const resolveStatusIcon = status => {
  //   if (status === 'Pending') return 'LoaderIcon'
  //   if (status === 'Active') return 'CheckCircleIcon'
  //   if (status === 'Deleted') return 'SlashIcon'
  //   return 'primary'
  // }
  const resolveInvoiceStatusVariantAndIcon = status => {
    if (status === 'pending') return { variant: 'warning', icon: 'LoaderIcon' }
    if (status === 'confirmed') return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === 'canceled') return { variant: 'danger', icon: 'SlashIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  return {
    payerOptions,
    fetchPayers,
    resetFilter,
    dateStart,
    dateEnd,
    initialDate,
    dob,
    unselectedRows,
    selectAll,
    isBusy,
    regStatusOptions,
    regDefaultStatus,
    isLoadingExcelDoc,
    getExcelDoc,
    paymentFilter,
    fetchUsers,
    tableColumns,
    tableColumns1,
    tableColumns2,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    selectedRows,
    courseId,
    role_name,
    student,
    course,
    student_id,
    // statuss,
    visa,
    who_pays,
    statusOptions,
    defaultStatus,

    // Course modal
    // fetchCities,
    fetchClassrooms,
    fetchTeachers,
    fetchMCourses,
    suggestClassrooms,
    suggestTeacher,
    isBigModalActive,
    UsersToAdd,
    UsersAlreadyAdded,
    spinner,
    currentCourseData,
    msgs,
    classrooms,
    teachers,
    masters,
    // cities,
    TofDArray,
    statusArray,
    currentUser,
    ModalData,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveVisaStatusVariant,
    refetchData,
    // resolveStatusIcon,
    resolveInvoiceStatusVariantAndIcon,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
