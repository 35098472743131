import axios from '@axios'

export default {
  namespaced: true,
  state: {
    classrooms: {
      rows: [],
      totalRecords: 0,
    },
  },
  getters: {},
  /* eslint-disable camelcase */
  mutations: {
    setClassrooms(state, users) {
      state.classrooms = users
    },
  },
  actions: {
    fetchCourses(store, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/resignation/list', { params: queryParams })
          .then(response => {
            store.commit('setClassrooms', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    confirmResignation(store, { id, refund, to_be_refunded }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/resignation/${id}/confirm`, { refund, to_be_refunded })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    rejectWithdrawal(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/resignation/${id}/reject`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
