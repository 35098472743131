import axios from '@axios'

export default {
  namespaced: true,
  state: {
    events: {
      rows: [],
      totalRecords: 0,
    },
  },
  getters: {},
  /* eslint-disable camelcase, no-param-reassign */
  mutations: {
    setEvents(state, events) {
      state.events = events
    },
    deleteEvent(state, eventId) {
      const eventData = {
        rows: state.events.rows.filter(event => event.id !== eventId),
        totalRecords: state.events.totalRecords - 1,
      }
      state.events = eventData
    },
  },
  actions: {
    fetchEvents(store, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/calendar/list/data', { params: queryParams })
          .then(response => {
            store.commit('setEvents', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, eventData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/calendar/event/create', eventData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteEvent(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`calendar/event/${id}/delete`)
          .then(response => {
            store.commit('deleteEvent', id)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    editEvent(store, item) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/calendar/event/${item.id}/update`, item)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
