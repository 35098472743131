<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-sub-title class="mb-25">
          Teachers work hours
        </b-card-sub-title>
        <b-card-title
          v-if="totalHours"
          class="font-weight-bolder"
        >
          {{ totalHours }}
        </b-card-title>
      </div>
      <!--/ title and subtitle -->

      <!-- datepicker -->
      <!-- <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="initialRangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div> -->
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="bar"
        height="350"
        :options="columnChart1.chartOptions"
        :series="columnChart1.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
// import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    // flatPickr,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
  },
  props: {
    columnChart1: {
      type: Object,
      required: true,
    },
    totalHours: {
      type: Number,
      default: null,
    },
    initialRangePicker: {
      type: Array,
      required: true,
    },
  },
}
</script>
