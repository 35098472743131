import {
  ref, watch, computed,
} from '@vue/composition-api'
// import { title } from '@core/utils/filter'
// import { datetimeToDate } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function useCoursesGroupListAvailability() {
  // Use toast
  const toast = useToast()

  const isOverViewList = ref(false)
  const refCourseListTable = ref(null)
  const selectedRows = ref([])
  const viewType = ref('group')

  // Table Handlers
  const tableColumns = [
    // { key: 'selected', sortable: false, label: '' },
    // {
    //   key: 'course_id',
    //   sortable: true,
    //   label: 'id',
    //   // sortKey: true,
    //   // sortDirection: 'asc',
    // },
    // { key: 'student', sortable: true },
    { key: 'course', label: 'Course name', sortable: true },
    {
      key: 'reg_conf_cnt',
      sortable: true,
      // formatter: datetimeToDate,
      label: 'Conf',
    },
    {
      key: 'reg_uncnf_cnt',
      sortable: true,
      label: 'Unconf',
    },
    {
      key: 'reg_cancel_cnt',
      sortable: true,
      label: 'Canceled',
    },
    {
      key: 'students_limit',
      sortable: true,
      label: 'Max',
    },
    {
      key: 'available',
      sortable: true,
      label: 'Available',
    },
  ]
  // lists
  const number = ref(null)
  const group = ref(null)
  /* eslint-disable camelcase */
  const mastercourse_id = ref(null)
  const classroom_id = ref(null)
  const teacher_id = ref(null)
  const date_start_from = ref(null)
  const date_start_to = ref(null)
  const date_end_from = ref(null)
  const date_end_to = ref(null)
  const fStatus = ref(null)
  const time_of_day = ref(null)
  // const cities = ref([])
  const masters = ref([])
  const classrooms = ref([])
  const teachers = ref([])
  const courseGroup = ref(null)
  const statusArray = ref([
    { name: 'Active', value: 'active', color: 'primary' },
    { name: 'Hidden', value: 'hidden', color: 'primary' },
    { name: 'Past', value: 'past', color: 'primary' },
    { name: 'Deleted', value: 'deleted', color: 'primary' },
    { name: 'Planned', value: 'planned', color: 'primary' },
  ])
  const TofDArray = ref([
    { name: 'daytime', value: 'daytime' },
    { name: 'anytime', value: 'anytime' },
    { name: 'evening', value: 'evening' },
  ])
  //
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const currentUser = ref({})
  const coursesStatusOptions = [
    { label: 'All', code: 'all', color: 'secondary' },
    { label: 'Planned', code: 'planned', color: 'secondary' },
    { label: 'To confirm', code: 'toconfirm', color: 'primary' },
    { label: 'Confirmed', code: 'active', color: 'success' },
    { label: 'To watch', code: 'towatch', color: 'info' },
    { label: 'Waiting list', code: 'waitinglist', color: 'warning' },
    { label: 'Finished', code: 'past', color: 'danger' },
    { label: 'Suspended', code: 'suspended', color: 'danger' },
    { label: 'Deleted', code: 'deleted', color: 'danger' },
    { label: 'Hidden', code: 'hidden', color: 'danger' },
  ]
  const filterDefault = ref('All')
  /* eslint-disable-next-line */
  const role_name = ref(window.location.href.split('/')[6])

  const dataMeta = computed(() => {
    const localItemsCount = refCourseListTable.value ? refCourseListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refCourseListTable.value.refresh()
  }

  const fetchCourses = (ctx, callback) => {
    store
      .dispatch('coursesStoreModule/fetchCoursesAvailability', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        number: number.value,
        group: group.value,
        filterDefault: filterDefault.value,
        mastercourse_id: mastercourse_id.value,
        teacher_id: teacher_id.value,
        classroom_id: classroom_id.value,
        date_start_from: date_start_from.value,
        date_start_to: date_start_to.value,
        date_end_from: date_end_from.value,
        date_end_to: date_end_to.value,
        status: fStatus.value,
        time_of_day: time_of_day.value,
        group_course_name: courseGroup.value,
        viewtype: viewType.value,
        view: 'availability',
        /* role_name: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value, */
        // role_name: role_name.value,
      })
        .then(() => {
          const users = store.state.coursesStoreModule.coursesAvailability
          const total = store.state.coursesStoreModule.coursesAvailability.length
          // console.log(store.state.coursesStoreModule)
          callback(users)
          totalUsers.value = total
          currentUser.value = JSON.parse(localStorage.getItem('userData'))
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching courses group availability',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
      })
  }

  /* eslint-disable-next-line */
  watch([currentPage, perPage, filterDefault, searchQuery, number, group, mastercourse_id, teacher_id, classroom_id, date_start_from, date_start_to, date_end_from, date_end_to, fStatus, time_of_day],  () => {
    // fetchCourses()
    refetchData()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  const resolveCourseStatusVariant = status => {
    const state = coursesStatusOptions.filter(stat => {
      return stat.code === status
    })
    if (typeof state[0].color !== 'undefined') {
      return state[0].color
    }
    return 'primary'
  }

  return {
    fetchCourses,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    filterDefault,
    coursesStatusOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCourseListTable,
    role_name,
    number,
    group,
    mastercourse_id,
    teacher_id,
    classroom_id,
    date_start_from,
    date_start_to,
    date_end_from,
    date_end_to,
    fStatus,
    time_of_day,
    // cities,
    masters,
    classrooms,
    teachers,
    statusArray,
    TofDArray,
    currentUser,
    selectedRows,
    courseGroup,
    isOverViewList,
    viewType,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveCourseStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
