<template>
  <div class="container m-5">
    <h2 class="mb-1">
      Cours infos
    </h2>
    <b-list-group
      class="mb-2"
      flush
    >
      <b-list-group-item>
        <b-row>
          <b-col md="4">
            Course name
          </b-col>
          <b-col>{{ course.course_name }}</b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row>
          <b-col md="4">
            City
          </b-col>
          <b-col>{{ course.course_city }}</b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row>
          <b-col md="4">
            Time
          </b-col>
          <b-col>{{ course.course_days }}</b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row>
          <b-col md="4">
            Duration
          </b-col>
          <b-col>{{ course.course_time_from }} - {{ course.course_time_to }}</b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row>
          <b-col md="4">
            Hours
          </b-col>
          <b-col>{{ course.course_hours }}</b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row>
          <b-col md="4">
            Price
          </b-col>
          <b-col>NOK{{ course.course_price }}</b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <h2 class="mb-1">
      Register new employee
    </h2>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  v-model="emailValue"
                  disabled
                  :state="errors.length > 0 ? false:null"
                  type="email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  v-model="nameValue"
                  placeholder="Your name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required"
              >
                <b-form-input
                  v-model="passwordValue"
                  type="password"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Your Password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col md="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
            >
              Register
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import {
  required, email, confirmed, password,
} from '@validations'
import axios from '@axios'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      nameValue: '',
      emailValue: this.$router.currentRoute.params.email,
      codeValue: this.$router.currentRoute.params.code,
      passwordValue: '',
      course: {},
      required,
      password,
      email,
      confirmed,
    }
  },
  mounted() {
    /* eslint-disable-next-line */
   axios.post('/get-data/code', { code: this.$router.currentRoute.params.code }).then(res => this.course = res.data.data.course)
  //  console.log(this.$router.currentRoute.params.code, this.$router.currentRoute.params.email)
  },
  methods: {
    submitForm() {
      /* eslint-disable-next-line */
      axios.post('register/employee', { code: this.codeValue, email: this.emailValue, name: this.nameValue, password: this.passwordValue })
      .then(response => {
        const tokens = response.data.accessToken.split('.')
        const decodedToken = JSON.parse(atob(tokens[1]))
        localStorage.accessToken = response.data.accessToken
        localStorage.setItem('userData', JSON.stringify(decodedToken))
        // this.$ability.update(decodedToken.ability)
        this.$router.push('/student')
      })
    },
  },
}
</script>
