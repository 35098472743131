<template>
  <div>
    <!-- // modal 3 -->
    <b-modal
      id="modal-center4"
      centered
      hide-footer
      size="lg"
      title="Add new type"
    >
      <b-form>
        <b-row>
          <b-col>
            <b-form-group label="Code">
              <b-form-input
                v-model="Model3.code"
                placeholder="Code"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Name">
              <b-form-input
                v-model="Model3.name"
                placeholder="Name"
                type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Route">
              <b-form-input
                v-model="Model3.route"
                placeholder="Route"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Proirity">
              <b-form-input
                v-model="Model3.priority"
                placeholder="proirity"
                type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Status"
              label-for="status"
            >
              <v-select
                id="status"
                v-model="Model3.status"
                label="name"
                :options="statusArray"
                :reduce="x => x.value"
                placeholder="Status"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group>
          <b-button
            variant="primary"
            @click="addNewGroup"
          >
            Add new group
          </b-button>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-card>
      <b-row>
        <b-col md="12">
          <h5>Course Groups</h5>
          <b-row>
            <b-col md="8">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-center4
                variant="primary"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Search</label>
                  <b-form-input
                    v-model="searchTerm3"
                    placeholder="Search"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <vue-good-table
            :columns="columnsGroups"
            :rows="Groups"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm3 }"
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    dropleft
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item @click="reverse2(props.row.originalIndex)">
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span class="align-middle ml-50">Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="deleteGroup(props.row.id)">
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
              <span v-else-if="props.column.field === 'status' && edit2[props.row.originalIndex] === false">
                <b-badge
                  v-if="props.row.status == 'active'"
                  variant="success"
                >
                  Active
                </b-badge>
                <b-badge
                  v-else
                  variant="danger"
                >
                  Deleted
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'status' && edit2[props.row.originalIndex] === true">
                <v-select
                  v-model="props.row.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="statusArray"
                  :reduce="x => x.value"
                  placeholder="Select"
                  @input="change2(props.row, props.row.originalIndex)"
                />
              </span>
              <span v-else-if="props.column.field === 'code' && edit2[props.row.originalIndex] === false">
                <span>{{ props.row.code }}</span>
              </span>
              <span v-else-if="props.column.field === 'code' && edit2[props.row.originalIndex] === true">
                <b-form-input
                  v-model="props.row.code"
                  @change="change2(props.row, props.row.originalIndex)"
                />
              </span>
              <span v-else-if="props.column.field === 'name' && edit2[props.row.originalIndex] === false">
                <span>{{ props.row.name }}</span>
              </span>
              <span v-else-if="props.column.field === 'name' && edit2[props.row.originalIndex] === true">
                <b-form-input
                  v-model="props.row.name"
                  @change="change2(props.row, props.row.originalIndex)"
                />
              </span>
              <span v-else-if="props.column.field === 'route' && edit2[props.row.originalIndex] === false">
                <span>{{ props.row.route }}</span>
              </span>
              <span v-else-if="props.column.field === 'route' && edit2[props.row.originalIndex] === true">
                <b-form-input
                  v-model="props.row.route"
                  @change="change2(props.row, props.row.originalIndex)"
                />
              </span>
              <span v-else-if="props.column.field === 'priority' && edit2[props.row.originalIndex] === false">
                <span>{{ props.row.priority }}</span>
              </span>
              <span v-else-if="props.column.field === 'priority' && edit2[props.row.originalIndex] === true">
                <b-form-input
                  v-model="props.row.priority"
                  @change="change2(props.row, props.row.originalIndex)"
                />
              </span>
              <span v-else>
                Empty
              </span>
            </template>
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BButton,
  BDropdown,
  BDropdownItem,
  BCard,
  BBadge,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import { ref, onMounted, onUnmounted } from '@vue/composition-api'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import coursesStoreModule from '../coursesStoreModule'

export default {
    components: {
        BRow,
        BCol,
        BForm,
        BPagination,
        BFormSelect,
        BFormGroup,
        BFormInput,
        BButton,
        BDropdown,
        BDropdownItem,
        BCard,
        BBadge,
        BModal,

        // third party
        VueGoodTable,
        vSelect,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    setup() {
        const USER_APP_STORE_MODULE_NAME = 'app-Groups'

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, coursesStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })
        //
        const toast = useToast()
        const Model3 = ref({})
        const Groups = ref([])
        const edit2 = ref([])
        const pageLength = ref(3)
        const searchTerm3 = ref('')
        const columnsGroups = ref([
            {
                label: 'Code',
                field: 'code',
            },
            {
                label: 'Name',
                field: 'name',
            },
            {
                label: 'Route',
                field: 'route',
            },
            {
                label: 'Priority',
                field: 'priority',
            },
            {
                label: 'Status',
                field: 'status',
            },
            {
                label: 'Action',
                field: 'action',
                tdClass: 'table-actions',
            },
        ])
        const statusArray = ref([
            { name: 'Active', value: 'active' },
            { name: 'Deleted', value: 'deleted' },
        ])
        const yesNoArray = ref([
            { name: 'Yes', value: true },
            { name: 'No', value: false },
        ])
        const fetchGroups = () => {
            store.dispatch('app-Groups/fetchGroups')
            .then(() => {
                Groups.value = store.state.coursesStoreModule.Groups
                Groups.value.forEach(() => {
                edit2.value.push(false)
                })
            })
        }
        const change2 = (row, index) => {
            store.dispatch('app-Groups/updateGroup', row)
            .then(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                    title: 'Updated successfully',
                    icon: 'CheckIcon',
                    variant: 'success',
                    },
                })
                fetchGroups()
                edit2.value[index] = false
            }).catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                    title: error,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    },
                })
                edit2.value[index] = false
            })
        }
        const deleteGroup = id => {
            store.dispatch('app-Groups/deleteG', id)
            .then(() => {
                fetchGroups()
                toast({
                    component: ToastificationContent,
                    props: {
                    title: 'Deleted successfully',
                    icon: 'CheckIcon',
                    variant: 'success',
                    },
                })
            }).catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                    title: error,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    },
                })
            })
        }
        const addNewGroup = () => {
            store.dispatch('app-Groups/NewGroup', Model3.value)
            .then(() => {
                fetchGroups()
                Model3.value = {}
                toast({
                    component: ToastificationContent,
                    props: {
                    title: 'Group added successfully',
                    icon: 'CheckIcon',
                    variant: 'success',
                    },
                })
            })
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                    title: error,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    },
                })
            })
        }
        const reverse2 = index => {
            // edit2.value[index] = !edit2.value[index] this not working cuz dom not updating after array item changes
            edit2.value.splice(index, 1, !edit2.value[index]) // this is an equivalent
        }
        onMounted(fetchGroups)
        return {
            Groups,
            columnsGroups,
            edit2,
            searchTerm3,
            Model3,
            statusArray,
            yesNoArray,
            pageLength,
            reverse2,
            deleteGroup,
            change2,
            addNewGroup,
        }
    },
}
</script>
