import axios from '@axios'

export default {
  namespaced: true,
  state: {
    classrooms: {
      rows: [],
      totalRecords: 0,
    },
  },
  getters: {},
  /* eslint-disable camelcase */
  mutations: {
    setClassrooms(state, users) {
      state.classrooms = users
    },
    deleteClassroom(state, userId) {
      const regData = {
        rows: state.classrooms.rows.filter(reg => reg.id !== userId),
        totalRecords: state.classrooms.totalRecords - 1,
      }
      state.classrooms = regData
    },
  },
  actions: {
    fetchClassrooms(store, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/student/attendance/courses', { params: queryParams })
          .then(response => {
            store.commit('setClassrooms', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getReview(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/reviews/list', { params: { course_id: id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addReview(store, { course_id, rating, comment }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/course/add/review', { course_id, rating, comment })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeReview(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/review/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelCourse(store, { course_id, reason, reason_other }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/student/course/cancel', { course_id, reason, reason_other })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    downloadCertificate(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/registrations/generate-certificate/${id}`)
          .then(response => {
            const linkSource = `data:application/pdf;base64,${response.data.data.split(',')[0]}`
            const downloadLink = document.createElement('a')
            const fileName = `course-${id}-certificate.pdf`
            downloadLink.href = linkSource
            downloadLink.download = fileName
            downloadLink.click()
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
