<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Classroom
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- City -->
          <!-- <validation-provider
            #default="validationContext"
            name="City"
            rules="required"
          >
            <b-form-group
              label="City"
              label-for="city"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="selectedCity"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="cities"
                placeholder="City"
                @keyup.native="autoCompleteCity($event.target.value)"
                @click.native="autoCompleteCity('')"
              >
                <span slot="no-options">
                  <div
                    v-if="isTyping"
                    class="text-center d-flex align-items-center justify-content-center"
                  >
                    <b-spinner
                      variant="primary"
                      label="Text Centered"
                    />
                  </div>
                  <div
                    v-if="!isTyping && !cities.length"
                    class="text-center d-flex align-items-center justify-content-center"
                  >
                    No matching records found
                  </div>
                </span>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <!-- Code -->
          <validation-provider
            #default="validationContext"
            name="Code"
            rules="required"
          >
            <b-form-group
              label="Code"
              label-for="code"
            >
              <b-form-input
                v-model="code"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Code"
                :class="errors.code[0] ? 'is-invalid' : ''"
                @keyup="errors.code = []"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <small
                v-if="errors"
                class="text-danger"
              >{{ errors.code[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Mx Students -->
          <validation-provider
            #default="validationContext"
            name="Max students"
            rules="required"
          >
            <b-form-group
              label="Max students"
              label-for="max students"
            >
              <b-form-input
                v-model="maxStudents"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                type="number"
                placeholder="Max students"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- External data -->
          <validation-provider
            #default="validationContext"
            name="External data"
          >
            <b-form-group
              label="External data"
              label-for="external data"
            >
              <b-form-input
                v-model="externalData"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="External data"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Classroom type -->
          <validation-provider
            #default="validationContext"
            name="Classroom type"
            rules="required"
          >
            <b-form-group
              :state="getValidationState(validationContext)"
              label="Classroom type"
            >
              <v-select
                v-model="selectedType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :options="classroomTypeOptions"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Status -->
          <validation-provider
            #default="validationContext"
            name="Status"
            rules="required"
          >
            <b-form-group
              :state="getValidationState(validationContext)"
              label="Status"
            >
              <v-select
                v-model="selectedStatus"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :options="statusOptions"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // BSpinner,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      query: '',
      cities: [],
      courses: [],
      classroomTypeOptions: ['online', 'classroom'],
      statusOptions: ['Active', 'Deleted'],
      isTyping: false,

    }
  },
  methods: {
    /* eslint-disable camelcase */
    // autoCompleteCity(search) {
    //   this.isTyping = true
    //   axios.post('/city/autocomplete', search)
    //   .then(res => {
    //     this.isTyping = false
    //     this.cities = res.data
    //   })
    // },
  },
  setup(props, { emit }) {
    const toast = useToast()
    // const selectedCity = ref({})
    const selectedCourse = ref({})
    const selectedType = ref('online')
    const selectedStatus = ref('Active')
    const name = ref('')
    const code = ref('')
    const maxStudents = ref('')
    const externalData = ref('')
    const comments = ref('')

    const classroomData = {}

    const resetRegistrationData = () => {
      // selectedCity.value = {}
      selectedCourse.value = {}
      selectedType.value = ''
      selectedStatus.value = ''
    }
    const onSubmit = () => {
      classroomData.status = selectedStatus.value
      // classroomData.city_id = selectedCity.value.id
      classroomData.name = name.value
      classroomData.comment = comments.value
      classroomData.code = code.value
      classroomData.max_students = maxStudents.value
      classroomData.external_data = externalData.value
      classroomData.classroom_type = selectedType.value

      store.dispatch('classroom/addClassroom', classroomData)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        }).catch(err => {
          if (err.response.data.errors && err.response.data.errors.code) {
            errors.value.code = err.response.data.errors.code
          }
          toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
        })
    }
    const errors = ref({
      code: [],
    })
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRegistrationData)

    return {
      errors,
      onSubmit,
      name,
      code,
      maxStudents,
      externalData,
      comments,
      // selectedCity,
      selectedCourse,
      selectedType,
      selectedStatus,
      classroomData,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
