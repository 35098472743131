<template>
  <div>
    <!-- Date and filters -->
    <b-row class="mb-1">
      <b-col cols="6" />
      <b-col cols="3">
        <div>
          <v-select
            v-model="dateOption"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="dateOptions"
            @input="fetchStatistics()"
          />
        </div>
      </b-col>
      <b-col cols="3">
        <!-- datepicker -->
        <div class="d-flex align-items-center">
          <feather-icon
            icon="CalendarIcon"
            size="16"
          />
          <flat-pickr
            v-model="rangePicker"
            :config="{ mode: 'range', dateFormat: 'Y-m-d', altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 }}"
            class="form-control flat-picker bg-transparent border-0 shadow-none"
            placeholder="YYYY-MM-DD"
            @input="foo"
          />
        </div>
        <!-- datepicker -->
      </b-col>
    </b-row>
    <!-- Statistics -->
    <b-row>
      <b-col cols="6">
        <teachers-work-hours
          :key="refreshKey+String('teachers_work_hours')"
          :column-chart1="apexChartData.columnChart1"
          :total-hours="totalHours"
          :initial-range-picker="initialRangePicker"
        />
      </b-col>
      <b-col cols="6">
        <teachers-current-courses
          :key="refreshKey+String('teachers_cuttent_courses')"
          :bar-chart="apexChartData.barChart2"
          :total-current-courses="totalCurrentCourses"
          :initial-range-picker="initialRangePicker"
        />
      </b-col>
      <b-col cols="6">
        <teachers-work-days
          :key="refreshKey+String('teachers_work_days')"
          :radar-chart="apexChartData.radarChart"
        />
      </b-col>
      <b-col cols="6">
        <teachers-work-timeframes
          :key="refreshKey+String('teachers_work_timeframes')"
          :donut-chart="apexChartData.donutChart"
        />
      </b-col>
      <b-col cols="12">
        <teachers-days-off
          :key="refreshKey+String('teachers_days_off')"
          :revenue-report="apexChartData.revenueReport"
          :budget-chart="apexChartData.budgetChart"
        />
      </b-col>
      <!-- <b-col cols="4">
        <attendance-by-courses />
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import TeachersWorkHours from './TeachersWorkHours.vue'
import TeachersCurrentCourses from './TeachersCurrentCourses.vue'
import TeachersWorkDays from './TeachersWorkDays.vue'
import TeachersWorkTimeframes from './TeachersWorkTimeframes.vue'
import TeachersDaysOff from './TeachersDaysOff.vue'
import apexChartData from '../apexChartData'

export default {
  components: {
    BRow,
    BCol,
    vSelect,
    flatPickr,
    TeachersWorkHours,
    TeachersCurrentCourses,
    TeachersWorkDays,
    TeachersWorkTimeframes,
    TeachersDaysOff,
  },
  props: {
    teacherId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      apexChartData,
      refreshKey: 0,
      rangePicker: [],
      initialRangePicker: [],
      start: '',
      end: '',
      totalHours: null,
      totalCurrentCourses: null,
      dateOption: { title: 'Daily', value: 'daily' },
      dateOptions: [
        { title: 'Daily', value: 'daily' },
        { title: 'Weekly', value: 'weekly' },
        { title: 'Monthly', value: 'monthly' },
        { title: 'Yearly', value: 'yearly' },
      ],
    }
  },
  created() {
    this.start = moment(new Date()).format('YYYY-MM-DD')
    const nextMonth = new Date(new Date(this.start).setMonth(new Date(this.start).getMonth() + 1))
    this.end = moment(nextMonth).format('YYYY-MM-DD')
    this.rangePicker.push(this.start, this.end)
    this.initialRangePicker.push(this.start, this.end)
    this.fetchStatistics()
    // this.$http.get('/statistics/admin/charts', {  })
  },
  methods: {
    /* eslint-disable prefer-destructuring */
    foo() {
      if (this.rangePicker.split(' ').length > 1) {
        this.start = this.rangePicker.split(' ')[0]
        this.end = this.rangePicker.split(' ')[2]
        this.fetchStatistics()
      }
    },
    fetchStatistics() {
      this.$http.get('/statistics/admin/2/charts', {
        params: {
          date_start: this.start,
          date_end: this.end,
          filter: this.dateOption.value,
          teacher_id: this.teacherId,
        },
      }).then(res => {
        apexChartData.columnChart1.chartOptions.xaxis.categories = res.data.teachersWorkHours.teachers
        apexChartData.columnChart1.series[0].data = res.data.teachersWorkHours.hours
        this.totalHours = res.data.teachersWorkHours.totalHours

        apexChartData.barChart2.chartOptions.xaxis.categories = res.data.teacherCurrentCourses.teachers
        apexChartData.barChart2.series[0].data = res.data.teacherCurrentCourses.number_of_courses
        this.totalCurrentCourses = res.data.teacherCurrentCourses.total_current_courses

        apexChartData.radarChart.chartOptions.xaxis.categories = res.data.teacherWorkDays.days
        apexChartData.radarChart.series[0].data = res.data.teacherWorkDays.data

        apexChartData.donutChart.chartOptions.labels = res.data.teacherWorkFrame.frames
        apexChartData.donutChart.series = res.data.teacherWorkFrame.data

        apexChartData.revenueReport.series[0].data = res.data.teacherDaysOffVsSubstitute.substitute
        apexChartData.revenueReport.chartOptions.xaxis.categories = res.data.teacherDaysOffVsSubstitute.dates
        apexChartData.budgetChart.series[0].data = res.data.teacherDaysOffVsSubstitute.substitute

        this.refreshKey += 1
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
