// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

export default function userCalendar() {
  /* eslint-disable camelcase, brace-style */
  // Use toast
  const toast = useToast()
  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null)

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null
  onMounted(() => {
    calendarApi = refCalendar.value.getApi()
  })

  // ------------------------------------------------
  // calendars
  // ------------------------------------------------
  const calendarsColor = {
    // available: 'success',
    // day_off: 'success',
    // Personal: 'danger',
    // Family: 'warning',
    unavailable: 'danger',
  }

  // ------------------------------------------------
  // event
  // ------------------------------------------------
  const blankEvent = {
    id: null,
    title: '',
    start: '',
    end: '',
    allDay: false,
    url: '',
    extendedProps: {
      calendar: '',
      teacher_id: '',
      guests: [],
      location: '',
      description: '',
    },
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  // *===========================================================================---*
  // *--------- Calendar API Function/Utils --------------------------------------------*
  // Template Future Update: We might move this utils function in its own file
  // *===========================================================================---*

  // ------------------------------------------------
  // (UI) addEventInCalendar
  // ? This is useless because this just add event in calendar and not in our data
  // * If we try to call it on new event then callback & try to toggle from calendar we get two events => One from UI and one from data
  // ------------------------------------------------
  // const addEventInCalendar = eventData => {
  //   toast({
  //     component: ToastificationContent,
  //     position: 'bottom-right',
  //     props: {
  //       title: 'Event Added',
  //       icon: 'CheckIcon',
  //       variant: 'success',
  //     },
  //   })
  //   calendarApi.addEvent(eventData)
  // }

  // ------------------------------------------------
  // (UI) updateEventInCalendar
  // ------------------------------------------------
  const updateEventInCalendar = (updatedEventData, propsToUpdate, extendedPropsToUpdate) => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Updated',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })

    const existingEvent = calendarApi.getEventById(updatedEventData.id)

    // --- Set event properties except date related ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setProp
    // dateRelatedProps => ['start', 'end', 'allDay']
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < propsToUpdate.length; index++) {
      const propName = propsToUpdate[index]
      existingEvent.setProp(propName, updatedEventData[propName])
    }

    // --- Set date related props ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setDates
    existingEvent.setDates(updatedEventData.start, updatedEventData.end, { allDay: updatedEventData.allDay })

    // --- Set event's extendedProps ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < extendedPropsToUpdate.length; index++) {
      const propName = extendedPropsToUpdate[index]
      existingEvent.setExtendedProp(propName, updatedEventData.extendedProps[propName])
    }
  }

  // ------------------------------------------------
  // (UI) removeEventInCalendar
  // ------------------------------------------------
  const removeEventInCalendar = eventId => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Removed',
        icon: 'TrashIcon',
        variant: 'danger',
      },
    })
    calendarApi.getEventById(eventId).remove()
  }

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = eventApi => {
    const {
      id,
      title,
      start,
      end,
      // eslint-disable-next-line object-curly-newline
      extendedProps: { calendar, guests, location, description, teacher_id },
      allDay,
    } = eventApi

    return {
      id,
      title,
      start,
      end,
      extendedProps: {
        calendar,
        guests,
        location,
        description,
        teacher_id,
      },
      allDay,
    }
  }

  // ------------------------------------------------
  // addEvent
  // ------------------------------------------------
  const refreshKey = ref(0)
  /* eslint-disable object-curly-newline */
  const eventAdd = (teacher_id, name, date_start, date_end) => {
    store.dispatch('calendar/eventAdd', { teacher_id, name, date_start, date_end })
    .then(res => {
      refreshKey.value += 1
      // const eventData = {
      //   title: name,
      //   start: date_start,
      //   end: date_end,
      //   extendedProps: {
      //     calendar: 'Teacher',
      //   },
      // }
      // if (res.data.data.day_off) {
      //   calendarApi.addEvent(eventData)
      // }
      toast({
        component: ToastificationContent,
        props: {
          title: res.data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    }).catch(err => {
      toast({
        component: ToastificationContent,
        props: {
          title: err.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    })
  }
  const eventUpdate = (id, teacher_id, name, date_start, date_end) => {
    store.dispatch('calendar/eventUpdate', { id, teacher_id, name, date_start, date_end })
    .then(res => {
      refreshKey.value += 1
      // const updatedEvent = {
      //   id: res.data.date.id,
      //   title: res.data.date.name,
      //   start: res.data.date.date_start,
      //   end: res.data.date.date_end,
      // }
      // calendarApi.addEvent(updatedEvent)
      // const propsToUpdate = ['id', 'title', 'start', 'end']
      // const extendedPropsToUpdate = []
      /* eslint-disable-next-line no-use-before-define */
      // updateEventInCalendar(updatedEvent, propsToUpdate, extendedPropsToUpdate)
      toast({
        component: ToastificationContent,
        props: {
          title: res.data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    }).catch(err => {
      toast({
        component: ToastificationContent,
        props: {
          title: err.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    })
  }
  const eventDelete = id => {
    // const eventId = event.value.id
    store.dispatch('calendar/eventDelete', id).then(() => {
      refreshKey.value += 1
      /* eslint-disable no-use-before-define */
      // removeEventInCalendar(eventId)
    })
  }
  const addEvent = eventData => {
    store.dispatch('calendar/addEvent', { event: eventData }).then(() => {
      // eslint-disable-next-line no-use-before-define
      refetchEvents()
    })
  }

  // ------------------------------------------------
  // updateEvent
  // ------------------------------------------------
  const updateEvent = eventData => {
    store.dispatch('calendar/updateEvent', { event: eventData }).then(response => {
      const updatedEvent = response.data.event

      const propsToUpdate = ['id', 'title', 'url']
      const extendedPropsToUpdate = ['calendar', 'guests', 'location', 'description']

      updateEventInCalendar(updatedEvent, propsToUpdate, extendedPropsToUpdate)
    })
  }

  // ------------------------------------------------
  // removeEvent
  // ------------------------------------------------
  const removeEvent = () => {
    const eventId = event.value.id
    store.dispatch('calendar/removeEvent', { id: eventId }).then(() => {
      removeEventInCalendar(eventId)
    })
  }

  // ------------------------------------------------
  // refetchEvents
  // ------------------------------------------------
  const refetchEvents = () => {
    calendarApi.refetchEvents()
  }

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed(() => store.state.calendar.selectedCalendars)
  // const selectedLevels = computed(() => store.state.calendar.selectedLevels)
  const teachersOptions = ref([])
  const levelsOptions = ref([])
  // const selectedClassroom = ref('All')
  const selectedTeacher = ref({ name: 'All teachers' })
  const teacher_id = ref(null)
  const items = ref([])
  const isBusyCalendar = ref(true)
  const filters = ref([{ name: 'All', value: 'all' }, { name: 'Available', value: 'available' }, { name: 'Unavailable', value: 'unavailable' }])
  const selectedFilter = ref({ name: 'Unavailable', value: 'unavailable' })
  const selectedLevel = ref({ code: 'all' })
  const isSessionModalActive = ref(false)
  const sessionData = ref(null)
  const courseGroup = ref(null)
  const isLoadingTeachers = ref(false)
  if (router.currentRoute.params.teacher) {
    selectedTeacher.value = router.currentRoute.params.teacher
    teacher_id.value = router.currentRoute.params.teacher.id
  }
  const urlQueries = router.currentRoute.query
  if (urlQueries.filter) selectedFilter.value = filters.value.find(el => el.value === urlQueries.filter)
  const getLevels = () => {
    store.dispatch('calendar/getLevels').then(res => {
      levelsOptions.value = res.data
      levelsOptions.value.unshift({ code: 'all' })
      if (urlQueries.level) selectedLevel.value = levelsOptions.value.find(el => el.code === urlQueries.level)
    })
  }
  const fetchTeachers = () => {
    isLoadingTeachers.value = true
    store.dispatch('coursesStoreModule/fetchTeachersInit')
      .then(res => {
        isLoadingTeachers.value = false
        teachersOptions.value = res.data.rows
        teachersOptions.value.unshift({ name: 'All teachers' })
        if (urlQueries.teacher_id) {
          selectedTeacher.value = teachersOptions.value.find(el => el.id === parseInt(urlQueries.teacher_id, 0))
          teacher_id.value = parseInt(urlQueries.teacher_id, 0)
        }
      })
  }
  // --------------------------------------------------------------------------------------------------
  // AXIOS: fetchEvents
  // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
  // --------------------------------------------------------------------------------------------------
  const urlParams = new URLSearchParams()
  const fetchEvents = (info, successCallback) => {
    isBusyCalendar.value = true
    // If there's no info => Don't make useless API call
    if (!info) return
    urlParams.set('?date_start', info.startStr ? info.startStr : '')
    urlParams.set('filter', selectedFilter.value ? selectedFilter.value.value : '')
    urlParams.set('level', selectedLevel.value.code ? selectedLevel.value.code : '')
    urlParams.set('teacher_id', teacher_id.value ? teacher_id.value : '')
    window.history.replaceState(null, null, decodeURIComponent(urlParams))
    isBusyCalendar.value = true
    /* eslint-disable prefer-template */
    const dateStart = info.startStr.split('T')[0] + ' ' + info.startStr.split('T')[1].split('+')[0]
    const dateEnd = info.endStr.split('T')[0] + ' ' + info.endStr.split('T')[1].split('+')[0]

    // Fetch Events from API endpoint
    store
      .dispatch('calendar/fetchEvents', {
        // status: selectedCalendars.value,
        date_start: dateStart,
        date_end: dateEnd,
        teacher_id: teacher_id.value,
        filter: selectedFilter.value.value,
        level: selectedLevel.value.code,
      })
      .then(response => {
        /* eslint-disable no-use-before-define */
        isBusyCalendar.value = false
        successCallback(response.data)
      })
      .catch(() => {
        isBusyCalendar.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching teachers calendar',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: 'dayGridWeek',
    headerToolbar: {
      start: 'sidebarToggle, prev,next, title',
      end: 'dateScroller, dayGridMonth,listMonth',
    },
    firstDay: [1],
    hiddenDays: [0],
    slotMinTime: '08:00',
    slotMaxTime: '23:00',
    events: fetchEvents,
    selectable: true,
    eventContent: arg => {
      return {
        html: `${arg.event.title}`,
      }
    },
    initialDate: router.currentRoute.query.date_start ? router.currentRoute.query.date_start.split('T')[0] : new Date(),
    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    // editable: true,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 5,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]

      return [
        // Background Color
        `bg-light-${colorName}`,
      ]
    },
    eventClick({ event: clickedEvent }) {
      // console.log(selectedTeacher.value)
      /* eslint-disable-next-line no-unused-expressions */
      (selectedTeacher.value.name === undefined ? isEventModalActive.value = false : isEventModalActive.value = true)
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      event.value = grabEventDataFromEventApi(clickedEvent)
      if (event.value.title.split('<')[2].split('"')[1] !== 'timeEvent' && event.value.title.split('span')[1].substr(2, 9) !== 'Free from') {
        /* eslint-disable prefer-destructuring */
        store.dispatch('calendar/getSession', { id: event.value.title.split('"')[5].replaceAll('"', '') }).then(res => {
          sessionData.value = res.data
          isSessionModalActive.value = true
        })
        // courseGroup.value = event.value.title.split('"')[5]
      } else {
        isSessionModalActive.value = false
      }
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
      // isModalActive = true
    },
    eventMouseEnter(hoveredEvent) {
      hoveredEvent.el.classList.add('fc-event-default')
    },
    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'sidebar',
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
        },
      },
      dateScroller: {
        text: 'Today',
        click() {
          calendarApi.today()
          let today = new Date()
          const dd = String(today.getDate()).padStart(2, '0')
          const mm = String(today.getMonth() + 1).padStart(2, '0')
          const yyyy = today.getFullYear()

          today = `${yyyy}-${mm}-${dd}`
          const dateAttr = `[data-date="${today}"]`
          const element = document.querySelector(dateAttr)
          element.scrollIntoView()
        },
      },
    },

    dateClick(info) {
      /*
        ! Vue3 Change
        Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */
      event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { start: info.date })))
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
      isEventModalActive.value = true
      // isModalActive = true
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      updateEvent(grabEventDataFromEventApi(droppedEvent))
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      updateEvent(grabEventDataFromEventApi(resizedEvent))
    },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,
  })
  watch([selectedCalendars], () => {
    refetchEvents()
  })
  watch([selectedLevel, selectedFilter], () => {
    refetchEvents()
  })
  watch(teacher_id, () => {
    // selectedCalendars.value = store.state.calendar.selectedCalendars
    store.commit('calendar/SET_SELECTED_EVENTS', ['Available', 'Unavailable'])
    calendarOptions.value.events = fetchEvents
  })

  // ------------------------------------------------------------------------

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const isEventHandlerSidebarActive = ref(false)
  const isEventModalActive = ref(false)
  const isModalActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)

  return {
    isLoadingTeachers,
    refCalendar,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    teachersOptions,
    levelsOptions,

    isSessionModalActive,
    sessionData,
    courseGroup,

    // selectedClassroom,
    selectedTeacher,
    selectedLevel,
    teacher_id,
    items,
    event,
    clearEventData,
    addEvent,
    updateEvent,
    removeEvent,
    refetchEvents,
    fetchEvents,
    isBusyCalendar,
    getLevels,
    fetchTeachers,

    refreshKey,

    eventAdd,
    eventUpdate,
    eventDelete,

    selectedFilter,
    filters,

    // ----- UI ----- //
    isEventHandlerSidebarActive,
    isEventModalActive,
    isModalActive,
  }
}
