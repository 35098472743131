import axios from '@axios'

export default {
  namespaced: true,
  state: {
    tests: {
      rows: [],
      totalRecords: 0,
    },
  },
  getters: {},
  /* eslint-disable camelcase */
  mutations: {
    setTests(state, tests) {
      state.tests = tests
    },
  },
  actions: {
    fetchCourses(store, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/placement-test/list', { params: queryParams })
          .then(response => {
            store.commit('setTests', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    autoCompleteCourse(store, search) {
      return new Promise((resolve, reject) => {
        axios
          .post('/autocomplete/course', { search })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getStudents(store, course_id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/course/get/students', { params: { course_id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendTest(store, { payload }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/placement_test/send', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getTest(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/placement-test/edit/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTest(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/placement-test/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
