import { ref, watch, computed } from '@vue/composition-api'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', sortable: true },
    { key: 'code', label: 'name & code', sortable: true },
    { key: 'email', label: 'email & phone', sortable: true },
    // { key: 'name', sortable: true },
    { key: 'levels' },
    { key: 'days', label: 'days & time' },
    // { key: 'time', sortable: true },
    { key: 'type', sortable: true },
    { key: 'available', sortable: true },
    // { key: 'phone', sortable: true },
    // { key: 'comments' },
    // { key: 'status', sortable: true },
    { key: 'comment', label: 'notes' },
    { key: 'actions', tdClass: 'table-actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('code')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const userData = JSON.parse(localStorage.getItem('userData'))
  const studentId = ref(userData.id)
  const refreshKey = ref(0)
  /* eslint-disable-next-line */
  const role_name = ref(window.location.href.split('/')[3])

  const selectedFilter = ref({ label: 'Active', value: 'Active' })
  const selectedAvFilter = ref({ label: 'All', value: null })
  const selectedClassroomFilter = ref({ label: 'All', value: null })

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })
  const filters = ref([
    { label: 'All', value: 'All' },
    { label: 'Active teachers', value: 'Active' },
    { label: 'Deleted teachers', value: 'Deleted' },
  ])
  const avFilters = ref([
    { label: 'All', value: null },
    { label: 'Available', value: 'available' },
    { label: 'Unavailable', value: 'unavailable' },
  ])
  const classroomFilters = ref([
    { label: 'All', value: null },
    { label: 'Classroom', value: 'classroom' },
    { label: 'Online', value: 'online' },
    { label: 'Hybrid', value: 'hybrid' },
  ])

  const refetchData = () => {
    refUserListTable.value.refresh()
  }
  const urlParams = ref(new URLSearchParams())
  if (router.currentRoute.query.search) searchQuery.value = router.currentRoute.query.search
  if (router.currentRoute.query.page) currentPage.value = router.currentRoute.query.page
  if (router.currentRoute.query.perPage) perPage.value = router.currentRoute.query.perPage
  if (router.currentRoute.query.status) selectedFilter.value = filters.value.find(el => el.value === router.currentRoute.query.status)
  if (router.currentRoute.query.available) selectedAvFilter.value = router.currentRoute.query.available !== 'all' ? avFilters.value.find(el => el.value === router.currentRoute.query.available) : avFilters.value.find(el => !el.value)
  if (router.currentRoute.query.type) selectedClassroomFilter.value = router.currentRoute.query.type !== 'all' ? classroomFilters.value.find(el => el.value === router.currentRoute.query.type) : classroomFilters.value.find(el => !el.value)
  const recheckLoading = () => {
    if (!isBusy.value) return refetchData()
    return setTimeout(() => recheckLoading(), 1000)
  }
  /* eslint-disable-next-line */
  watch([currentPage, perPage, roleFilter, planFilter, statusFilter, role_name, selectedFilter, selectedAvFilter, selectedClassroomFilter],  () => {
    urlParams.value.set('?search', searchQuery.value ? searchQuery.value : '')
    urlParams.value.set('page', currentPage.value ? currentPage.value : '')
    urlParams.value.set('perPage', perPage.value ? perPage.value : '')
    urlParams.value.set('status', selectedFilter.value.value ? selectedFilter.value.value : 'all')
    urlParams.value.set('available', selectedAvFilter.value.value ? selectedAvFilter.value.value : 'all')
    urlParams.value.set('type', selectedClassroomFilter.value.value ? selectedClassroomFilter.value.value : 'all')
    window.history.replaceState(null, null, decodeURIComponent(urlParams.value))
    recheckLoading()
  })
  watch(searchQuery, () => {
    urlParams.value.set('?search', searchQuery.value ? searchQuery.value : '')
    window.history.replaceState(null, null, decodeURIComponent(urlParams.value))
    refetchData()
  })
  const isBusy = ref(false)

  const fetchUsers = (ctx, callback) => {
    const payload = {
      search: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      role_name: roleFilter.value,
      plan: planFilter.value,
      status: selectedFilter.value.value,
      available: selectedAvFilter.value.value,
      type: selectedClassroomFilter.value.value,
      // role_name: role_name.value,
    }
    if (userData.role === 'student') {
      payload.student_id = studentId.value
    }
    store
      .dispatch('teacher/fetchTeachers', payload)
      .then(() => {
        const users = store.state.teacherStoreModule.teachers.rows
        const total = store.state.teacherStoreModule.teachers.totalRecords
        callback(users)
        totalUsers.value = total
      })
      .catch(() => {
        isBusy.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching teachers list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }
  const resolveTypeVariant = type => {
    if (type === 'Available') return 'success'
    if (type === 'Unavailable') return 'danger'
    return 'primary'
  }

  const resolveTeacherAvailability = status => {
    if (!status) return { class: 'danger', text: 'Unavailable' }
    if (status) return { class: 'success', text: 'Available' }
    return { class: 'secondary', text: status }
  }

  const renderTimeframes = day => {
    const days = ['mo', 'tu', 'we', 'th', 'fr']
    const elements = []
    if (typeof day !== 'undefined' && day !== null) {
      const workingDays = day.toLowerCase().split(',')
      days.forEach(d => {
        let free = 'fee'
        if (workingDays.includes(d)) {
          free = 'work'
        }
        elements.push({ free })
      })
      return elements
    }
    return ''
  }

  return {
    filters,
    avFilters,
    classroomFilters,

    isBusy,
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    role_name,
    studentId,
    refreshKey,
    renderTimeframes,

    selectedFilter,
    selectedAvFilter,
    selectedClassroomFilter,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    resolveTeacherAvailability,
    resolveTypeVariant,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
