import axios from '@axios'

export default {
  namespaced: true,
  state: {
    teachers: {
      rows: [],
      totalRecords: 0,
    },
    events: {
      rows: [],
      totalRecords: 0,
    },
  },
  getters: {},
  /* eslint-disable camelcase, object-curly-newline, no-param-reassign, no-unused-expressions */
  mutations: {
    setTeachers(state, teachers) {
      state.teachers = teachers
    },
    setEvents(state, events) {
      state.events = events
    },
    deleteTeacher(state, id) {
      const data = {
        rows: state.teachers.rows.filter(row => row.id !== id),
        totalRecords: state.teachers.totalRecords - 1,
      }
      state.teachers = data
    },
    // deleteUser(state, userId) {
    //   const usersData = {
    //     rows: state.users.rows.filter(user => user.id !== userId),
    //     totalRecords: state.users.totalRecords - 1,
    //   }
    //   state.users = usersData
    // },
  },
  actions: {
    fetchTeachers(store, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/teacher/list', { params: queryParams })
          .then(response => {
            store.commit('setTeachers', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchEvents(store, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/teacher/event/list', { params: queryParams })
          .then(response => {
            store.commit('setEvents', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addTeacher(store, teacherData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/teacher/store', teacherData)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    editTeacher(store, teacherData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/teacher/update', teacherData)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    deleteTeacher(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/user/delete', { data: { user_id: id } })
          .then(response => {
            // store.commit('deleteUser', userData)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    // fetchEvents(store, id) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/teacher/event/list', { params: { teacher_id: id } })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    addEvent(store, { teacher_id, name, description, date_start, date_end }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/teacher/event/create', { teacher_id, name, description, date_start, date_end })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    restoreTeacher(store, teacher_id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/teacher/restore', { teacher_id })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getLevels() {
      return new Promise((resolve, reject) => {
        axios
          .get('/levels/get')
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateEvent(store, { id, teacher_id, name, description, date_start, date_end }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/teacher/event/update/${id}`, { teacher_id, name, description, date_start, date_end })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    approveEvent(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/teacher/event/approve/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    rejectEvent(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/teacher/event/reject/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    deleteEvent(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`teacher/event/delete/${id}`, id)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
