<template>
  <b-nav-item-dropdown
    ref="msgDropdown"
    :key="refreshKey"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="messagesCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="MessageSquareIcon"
        size="21"
        @click="readAll"
      />
    </template>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-once
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
      @scroll="handleScroll"
    >
      <!-- Account Notification -->
      <MessageElement
        :messages="nootifications"
        @close-notification="$refs.msgDropdown.hide()"
      />
        <!-- @mark-notification-as-read="markMessageAsRead" -->

    </vue-perfect-scrollbar>
    <div
      class="d-flex justify-content-center"
      style="position: absolute; bottom: 80px; left: 50%"
    >
      <b-spinner
        class="mb-50"
        label="Loading..."
        :style="[hasScrolledToBottom || isLoading ? {} : { 'color': 'transparent' }]"
      />
    </div>
    <li
      v-if="nootifications.length === 0 && !isLoading"
      class="dropdown-menu-footer text-center"
    >
      {{ $t('No new messages') }}
    </li>
    <!-- Cart Footer -->
    <b-dropdown-item><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      :to="{ name: 'apps-chat' }"
      @click="$refs.msgDropdown.hide()"
    >Read all messages</b-button>
    </b-dropdown-item>
    <!-- <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
    >Read all notifications</b-button>
    </li> -->
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BButton, BDropdownItem, BSpinner, /* BFormCheckbox */
} from 'bootstrap-vue'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { useToast } from 'vue-toastification/composition'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
// import store from '@/store'
import MessageElement from './MessageElement.vue'

export default {
  components: {
    BNavItemDropdown,
    VuePerfectScrollbar,
    BButton,
    BDropdownItem,
    MessageElement,
    BSpinner,
    // BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      nootifications: [],
      unreadMessages: 1,
      messagesCount: 0,
      hasScrolledToBottom: false,
      page: 2,
      isLoading: false,
      // realNotifications: [],
    }
  },
  methods: {
    /* eslint-disable no-plusplus, no-return-assign, no-param-reassign */
    handleScroll(el) {
      if ((el.srcElement.offsetHeight + el.srcElement.scrollTop) >= el.srcElement.scrollHeight) {
        this.getMessages(this.page++)
        this.hasScrolledToBottom = true
      }
    },
    readAll() {
      if (this.messagesCount > 0) {
        this.$http.post('all/messages/mark/as-read').then(() => {
          this.nootifications.map(el => el.unread = false)
          this.isLoading = false
          this.messagesCount = 0
        })
      }
    },
    getMessages(page) {
      this.isLoading = true
      this.$http.get('/conversations', { params: { page } }).then(res => {
        this.isLoading = false
        this.hasScrolledToBottom = false
        if (res.data.conversations.length !== 0) {
          for (let i = 0; i < res.data.conversations.data.length; i += 1) {
              this.messagesCount = res.data.unreadMessagesCount
              if (res.data.conversations.data[i]) {
                this.nootifications.push(
                  {
                    // id: res.data.id,
                    title: res.data.conversations.data[i].name,
                    conversation_id: res.data.conversations.data[i].id,
                    avatar: res.data.conversations.data[i].avatar,
                    sender_id: res.data.conversations.data[i].latest_message.sender.id, //
                    subtitle: res.data.conversations.data[i].latest_message.message,
                    date: res.data.conversations.data[i].latest_message.time,
                    unread: res.data.conversations.data[i].unread_messages,
                    // createdAt: notification.created_at,
                  },
                )
              }
          }
        }
      })
    },
  },
  created() {
    this.getMessages(1)
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    window.Echo.private(`App.Models.User.${userData.id}`)
    // let oneSenderNotifications = []
    .notification(notification => {
      if (notification.sender_id !== userData.id && notification.notification_type === 'chat') {
        this.messagesCount += 1
        this.$store.state.appConfig.chatRefreshKey += 1
        this.nootifications.forEach(notif => {
          if (notif.sender_id === notification.sender_id) {
            this.unreadMessages = notif.unread + 1
            this.nootifications.splice(this.nootifications.indexOf(notif), 1)
          }
        })
        this.nootifications.unshift(
          {
            id: notification.id,
            title: notification.title,
            conversation_id: notification.conversation_id,
            avatar: notification.avatar,
            sender_id: notification.sender_id,
            subtitle: notification.message,
            date: notification.date,
            unread: this.unreadMessages,
          },
        )
        this.$forceUpdate()
      }
    })
    // this.nootifications.unshift(oneSenderNotifications[0])
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }
    const refreshKey = ref(0)
    return {
      // systemNotifications,
      perfectScrollbarSettings,
      refreshKey,
    }
  },
}
</script>

<style>
</style>
