<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => { $emit('update:is-event-handler-sidebar-active', val); range = null }"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">
            Request a time off
          </h5>
          <div>
            <feather-icon
              v-if="eventLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-event'); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ invalid }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="onSubmit()"
            @reset.prevent="resetForm"
          >

            <!-- Title -->
            <validation-provider
              #default="validationContext"
              name="Subject"
              rules="required"
            >
              <b-form-group
                label="Subject"
                label-for="event-title"
              >
                <b-form-input
                  id="event-title"
                  v-model="eventLocal.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Subject"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Title -->
            <validation-provider
              #default="validationContext"
              name="Description"
              rules="required"
            >
              <b-form-group
                label="Description"
                label-for="description"
              >
                <b-form-textarea
                  id="event-description"
                  v-model="eventLocal.description"
                  placeholder="Description"
                  autofocus
                  :state="getValidationState(validationContext)"
                  rows="3"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Range date -->
            <b-form-group
              label="Date range"
              label-for="date-range"
            >
              <flat-pickr
                v-model="range"
                class="form-control"
                :config="{
                  dateFormat: 'Y-m-d', altFormat: 'd-m-Y', altInput: true,
                  locale: { firstDayOfWeek: 1 }, mode: 'range', minDate: new Date(),
                  onClose: dates => setDates(dates),
                }"
              />
            </b-form-group>
            <b-row>
              <b-col cols="6">
                <!-- Start Date -->
                <validation-provider
                  #default="validationContext"
                  name="Start time"
                  rules="required"
                >
                  <b-form-group
                    label="From"
                    label-for="start-time"
                    :state="getValidationState(validationContext)"
                  >
                    <flat-pickr
                      v-model="eventLocal.from"
                      class="form-control"
                      :config="{ noCalendar: true, enableTime: true, minTime: '07:00', time_24hr: true }"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col class="6">
                <!-- End Date -->
                <validation-provider
                  #default="validationContext"
                  name="End time"
                  rules="required"
                >
                  <b-form-group
                    label="To"
                    label-for="end-time"
                    :state="getValidationState(validationContext)"
                  >
                    <flat-pickr
                      v-model="eventLocal.to"
                      class="form-control"
                      :config="{ noCalendar: true, enableTime: true, minTime: eventLocal.to, time_24hr: true }"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="invalid || !range"
              >
                Apply
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, VBModal, BFormTextarea, BRow, BCol,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import moment from 'moment'
import useCalendarEventHandler from './useCalendarEventHandler'

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
      altEvent: {},
      range: null,
    }
  },
  methods: {
    setDates(dates) {
      this.eventLocal.start = moment(dates[0]).format('YYYY-MM-DD H:mm:ss')
      this.eventLocal.end = moment(dates[1]).format('YYYY-MM-DD H:mm:ss')
    },
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,

      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    // watch(eventLocal, () => {
    //   if (eventLocal.value.extendedProps.session_id) {
    //     emit('show-session-substitute-modal', eventLocal.value.extendedProps.session_id)
    //   }
    // })
    clearFormData.value = clearForm

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      guestsOptions,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
