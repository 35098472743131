// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
// import { formatDate } from '@fullcalendar/core'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

export default function userCalendar() {
  /* eslint-disable camelcase */
  // Use toast
  const toast = useToast()
  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null)

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null
  onMounted(() => {
    calendarApi = refCalendar.value.getApi()
  })

  // ------------------------------------------------
  // calendars
  // ------------------------------------------------
  const calendarsColor = {
    available: 'success',
    // Holiday: 'success',
    // Personal: 'danger',
    // Family: 'warning',
    unavailable: 'danger',
  }

  // ------------------------------------------------
  // event
  // ------------------------------------------------
  const blankEvent = {
    id: null,
    title: '',
    start: '',
    end: '',
    allDay: false,
    url: '',
    extendedProps: {
      calendar: '',
      guests: [],
      location: '',
      homework: null,
      description: '',
      old_teacher: null,
      teacher_id: null,
      shall_update: null,
    },
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }
  const urlParams = ref(new URLSearchParams())

  // *===========================================================================---*
  // *--------- Calendar API Function/Utils --------------------------------------------*
  // Template Future Update: We might move this utils function in its own file
  // *===========================================================================---*

  // ------------------------------------------------
  // (UI) addEventInCalendar
  // ? This is useless because this just add event in calendar and not in our data
  // * If we try to call it on new event then callback & try to toggle from calendar we get two events => One from UI and one from data
  // ------------------------------------------------
  // const addEventInCalendar = eventData => {
  //   toast({
  //     component: ToastificationContent,
  //     position: 'bottom-right',
  //     props: {
  //       title: 'Event Added',
  //       icon: 'CheckIcon',
  //       variant: 'success',
  //     },
  //   })
  //   calendarApi.addEvent(eventData)
  // }

  // ------------------------------------------------
  // (UI) updateEventInCalendar
  // ------------------------------------------------
  // const updateEventInCalendar = (updatedEventData, propsToUpdate, extendedPropsToUpdate) => {
  //   toast({
  //     component: ToastificationContent,
  //     props: {
  //       title: 'Event Updated',
  //       icon: 'CheckIcon',
  //       variant: 'success',
  //     },
  //   })

  //   const existingEvent = calendarApi.getEventById(updatedEventData.id)

  //   // --- Set event properties except date related ----- //
  //   // ? Docs: https://fullcalendar.io/docs/Event-setProp
  //   // dateRelatedProps => ['start', 'end', 'allDay']
  //   // eslint-disable-next-line no-plusplus
  //   for (let index = 0; index < propsToUpdate.length; index++) {
  //     const propName = propsToUpdate[index]
  //     existingEvent.setProp(propName, updatedEventData[propName])
  //   }

  //   // --- Set date related props ----- //
  //   // ? Docs: https://fullcalendar.io/docs/Event-setDates
  //   existingEvent.setDates(updatedEventData.start, updatedEventData.end, { allDay: updatedEventData.allDay })

  //   // --- Set event's extendedProps ----- //
  //   // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
  //   // eslint-disable-next-line no-plusplus
  //   for (let index = 0; index < extendedPropsToUpdate.length; index++) {
  //     const propName = extendedPropsToUpdate[index]
  //     existingEvent.setExtendedProp(propName, updatedEventData.extendedProps[propName])
  //   }
  // }

  // ------------------------------------------------
  // (UI) removeEventInCalendar
  // ------------------------------------------------
  const removeEventInCalendar = eventId => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Removed',
        icon: 'TrashIcon',
        variant: 'danger',
      },
    })
    calendarApi.getEventById(eventId).remove()
  }

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = eventApi => {
    const {
      id,
      title,
      start,
      end,
      // eslint-disable-next-line object-curly-newline
      extendedProps: { calendar, guests, location, description, teacher_id, classroom_id, course_id, teacher, classroom, session_id, homework, old_teacher, lesson_number, shall_update },
      allDay,
    } = eventApi
    return {
      id,
      title,
      start,
      end,
      extendedProps: {
        calendar,
        guests,
        location,
        description,
        teacher_id,
        classroom_id,
        course_id,
        teacher,
        classroom,
        session_id,
        homework,
        old_teacher,
        lesson_number,
        shall_update,
      },
      allDay,
    }
  }

  // ------------------------------------------------
  // addEvent
  // ------------------------------------------------
  const addEvent = eventData => {
    store.dispatch('calendar/addEvent', { event: eventData }).then(() => {
      // eslint-disable-next-line no-use-before-define
      refetchEvents()
    })
  }

  // ------------------------------------------------
  // updateEvent
  // ------------------------------------------------
  const errors = ref({
    start: [],
  })
  const updateEvent = eventData => {
    /* eslint-disable */
    console.log(eventData)
    store.dispatch('calendar/updateCoursesSession', { event: eventData }).then(res => {
      refetchEvents()
      isEventHandlerSidebarActive.value = false
      toast({
        component: ToastificationContent,
        props: {
          title: res.data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      // const updatedEvent = eventData

      // const propsToUpdate = ['id', 'title', 'url']
      // const extendedPropsToUpdate = ['calendar', 'guests', 'location', 'description']

      // updateEventInCalendar(updatedEvent, propsToUpdate, extendedPropsToUpdate)
    }).catch(error => {
      if (error.response.data.errors !== undefined) {
        errors.value.start = error.response.data.errors.start
      }
      toast({
        component: ToastificationContent,
        props: {
          title: error.response.data.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }

  // ------------------------------------------------
  // removeEvent
  // ------------------------------------------------
  const removeEvent = () => {
    const eventId = event.value.id
    store.dispatch('calendar/removeEvent', { id: eventId }).then(() => {
      removeEventInCalendar(eventId)
    })
  }

  // ------------------------------------------------
  // refetchEvents
  // ------------------------------------------------
  const refetchEvents = () => {
    calendarApi.refetchEvents()
  }

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed(() => store.state.calendar.selectedCalendars)

  const classroomOptions = ref([{ name: 'All' }])
  const teachersOptions = ref([{ name: 'All' }])
  const selectedClassroom = ref('All')
  const classroom_id = ref(null)
  const isBusyCalendar = ref(true)
  const fStatus = ref({ name: 'Confirmed', value: 'confirmed', color: 'success' })
  const statusArray = [
    { name: 'Confirmed', value: 'confirmed', color: 'success' },
    { name: 'On going', value: 'ongoing', color: 'success' },
    { name: 'All', value: 'all', color: 'secondary' },
    { name: 'Planned', value: 'planned', color: 'secondary' },
    { name: 'To confirm', value: 'toconfirm', color: 'primary' },
    { name: 'To watch', value: 'towatch', color: 'info' },
    { name: 'Finished', value: 'past', color: 'danger' },
    { name: 'Suspended', value: 'suspended', color: 'danger' },
    { name: 'Deleted', value: 'deleted', color: 'danger' },
    { name: 'Hidden', value: 'hidden', color: 'danger' },
  ]
  const TofDArray = [
    { name: 'daytime', value: 'daytime' },
    { name: 'anytime', value: 'anytime' },
    { name: 'evening', value: 'evening' },
  ]
  const time_of_day = ref(null)
  const date_start_from = ref(null)
  const date_start_to = ref(null)
  const date_end_from = ref(null)
  const date_end_to = ref(null)
  const time_from = ref(null)
  const time_to = ref(null)
  const number = ref(null)
  const group = ref(null)
  const mastercourse_id = ref(null)
  const teacher_id = ref(null)

  // --------------------------------------------------------------------------------------------------
  // AXIOS: fetchEvents
  // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
  // --------------------------------------------------------------------------------------------------

  const getClassrooms = () => {
    store.dispatch('calendar/getClassrooms').then(() => {
      // res.data.rows.forEach(row => classroomOptions.value.push(row))
      // classroomOptions.value = res.data.rows
    })
  }
  const getTeachers = () => {
    store.dispatch('calendar/getTeachers').then(() => {
      // res.data.rows.forEach(row => teachersOptions.value.push(row))
      // teachersOptions.value = res.data.rows
    })
  }
  const fetchMCourses = () => {
    store.dispatch('calendar/fetchMCourses')
  }
  const getSuggestions = session => {
    store.dispatch('calendar/updateSuggestClassrooms', session.extendedProps.course_id)
    store.dispatch('calendar/updateSuggestTeachers', session.extendedProps.course_id)
  }

  const fetchEvents = (info, successCallback) => {
    // If there's no info => Don't make useless API call
    if (!info) return
    urlParams.value.set('?c_date_start', info.startStr)
    if (calendarOptions.value.params) {
      Object.keys(calendarOptions.value.params).forEach(key => {
          const value = calendarOptions.value.params[key]
          urlParams.value.set(key, value || '')
      })
    }
    // urlParams.value.set('param', fStatus.value)
    window.history.replaceState(null, null, decodeURIComponent(urlParams.value))
    // Fetch Events from API endpoint
    isBusyCalendar.value = true
    store
      .dispatch('calendar/fetchEvents', {
        calendars: selectedCalendars.value,
        date_start: info.startStr.split('T')[0],
        date_end: info.endStr.split('T')[0],
        params: calendarOptions.value.params,
      })
      .then(response => {
        isBusyCalendar.value = false
        successCallback(response.data)
      })
      .catch(() => {
        isBusyCalendar.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching calendar events',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const formatDateTime = dateTime => {
    if (typeof dateTime !== 'undefined') {
      const timestamp = dateTime.split(' ')
      // const fullTime = (typeof timestamp[1] !== 'undefined') ? timestamp[1] : ''
      const fullDate = (typeof timestamp[0] !== 'undefined') ? timestamp[0] : ''
      let dateArr
      let dateStr = null
      if (fullDate !== '') {
        dateArr = fullDate.split('-')
        dateStr = `${dateArr[2]}-${dateArr[1]}-${dateArr[0]} ${timestamp[1]}`
      }
      return `${dateStr}`
    }
    return ''
  }
  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: 'listWeek',
    headerToolbar: {
      start: 'sidebarToggle prev,today,next, title',
      center: '',
      end: 'AdvancedFilterButton , dayGridMonth,listWeek',
    },
    firstDay: [1],
    hiddenDays: [0],
    slotMinTime: '08:00',
    slotMaxTime: '23:00',
    events: fetchEvents,
    selectable: true,
    initialDate: router.currentRoute.query.c_date_start ? router.currentRoute.query.c_date_start.split('T')[0] : new Date(),
    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: true,
    params: null,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 5,
    locale: 'en-GB',

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]

      return [
        // Background Color
        `bg-light-${colorName}`,
      ]
    },
    /* eslint-disable no-unused-expressions, consistent-return */
    eventClick({ event: clickedEvent }) {
      (selectedClassroom.value === 'All' ? isEventModalActive.value = false : isEventModalActive.value = true)
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      event.value = grabEventDataFromEventApi(clickedEvent)
      /* eslint-disable object-curly-newline, prefer-destructuring */
      event.value.start = formatDateTime(calendarApi.formatDate(event.value.start, { year: 'numeric', month: '2-digit', day: '2-digit', hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' }).replace('/', '-').replace('/', '-').replace(',', ''))
      event.value.end = formatDateTime(calendarApi.formatDate(event.value.end, { year: 'numeric', month: '2-digit', day: '2-digit', hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' }).replace('/', '-').replace('/', '-').replace(',', ''))
      event.value.time_from = event.value.start.split(' ')[1]
      event.value.time_to = event.value.end.split(' ')[1]
      event.value.date = event.value.start.split(' ')[0]
      // event.value.start = `${event.value.start.split('T')[0]} ${event.value.start.split('T')[1].split('.')[0].substr(0, 5)}`
      // event.value.end = `${event.value.end.split('T')[0]} ${event.value.end.split('T')[1].split('.')[0].substr(0, 5)}`
      if (window.location.pathname === '/admin/attendance/calendar' && event.value.extendedProps.calendar !== 'Day_off') {
        isAttendanceModalActive.value = true
        // isSessionHandlerSidebarActive.value = true
      }
      if (window.location.pathname !== '/admin/attendance/calendar' && event.value.extendedProps.calendar !== 'Day_off') {
        // eslint-disable-next-line no-use-before-define
        // isEventHandlerSidebarActive.value = true
        isSessionHandlerSidebarActive.value = true
      }
      // isModalActive = true
    },
    eventMouseEnter(hoveredEvent) {
      if (hoveredEvent.event.extendedProps.day_off) {
        hoveredEvent.el.classList.add('fc-event-default')
        const eventElements = hoveredEvent.el.querySelectorAll('*')
        eventElements.forEach(element => {
          element.classList.add('fc-event-default')
        })
      }
    },

    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'sidebar',
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
        },
      },
      AdvancedFilterButton: {
        text: 'Filter',
        click() {
          // eslint-disable-next-line no-use-before-define
          isFilterHandlerSidebarActive.value = true
        },
      },
      dateScroller: {
        text: 'Today',
        click() {
          calendarApi.today()
          let today = new Date()
          const dd = String(today.getDate()).padStart(2, '0')
          const mm = String(today.getMonth() + 1).padStart(2, '0')
          const yyyy = today.getFullYear()

          today = `${yyyy}-${mm}-${dd}`
          const dateAttr = `[data-date="${today}"]`
          const element = document.querySelector(dateAttr)
          element.scrollIntoView()
        },
      },
    },

    dateClick(info) {
      /*
        ! Vue3 Change
        Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */
      event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { start: info.date })))
      // eslint-disable-next-line no-use-before-define
      // isEventHandlerSidebarActive.value = true
      // isEventModalActive.value = true
      // isModalActive = true
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      updateEvent(grabEventDataFromEventApi(droppedEvent))
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      updateEvent(grabEventDataFromEventApi(resizedEvent))
    },

    eventContent(eventData) {
      let html
      if (eventData.event.extendedProps !== null && eventData.event.extendedProps.calendar === 'singleSession') {
        const start = eventData.event.start ? `${eventData.event.start.getHours()}:${eventData.event.start.getMinutes()}` : ''
        const end = eventData.event.end ? `${eventData.event.end.getHours()}:${eventData.event.end.getMinutes()}` : ''
        const title = eventData.event.title ? eventData.event.title : ''
        const lesson = eventData.event.extendedProps.lesson_number ? eventData.event.extendedProps.lesson_number : ''
        const teacher = eventData.event.extendedProps.teacher ? eventData.event.extendedProps.teacher : ''
        const classroom = eventData.event.extendedProps.classroom ? eventData.event.extendedProps.classroom : ''
        const color = eventData.event.extendedProps.bullColor ? eventData.event.extendedProps.bullColor : 'none'
        html = `<div class="textBox">
                      <div class="c-session id-${eventData.event.id}">
                      <span class="bullet bullet-sm mr-1" style="background-color: ${color}"></span> 
                      <span class="c-start">${start}</span> - <span class="c-end">${end}</span> | 
                      <span>${lesson}</span> |
                      <span class="c-title">${title}</span> | 
                      <span class="c-teacher">${teacher}</span> |
                      <span class="classroom">${classroom}</span> 
                      </div>
                    </div>
                  `
      } else {
        const title = eventData.event.title ? eventData.event.title : ''
        html = `<div class="textBoxDays">
                      <span class="c-title">${title}</span> 
                      </div>
                    </div>
                  `
      }

      return {
        html,
      }
    },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,
  })

  delete router.currentRoute.query.c_date_start
  calendarOptions.value.params = router.currentRoute.query
  if (router.currentRoute.query.fStatus) fStatus.value = statusArray.find(el => el.value === router.currentRoute.query.fStatus)
  if (router.currentRoute.query.classroom_id) classroom_id.value = parseInt(router.currentRoute.query.classroom_id, 0)
  if (router.currentRoute.query.date_end_from) date_end_from.value = router.currentRoute.query.date_end_from
  if (router.currentRoute.query.date_end_to) date_end_to.value = router.currentRoute.query.date_end_to
  if (router.currentRoute.query.date_start_from) date_start_from.value = router.currentRoute.query.date_start_from
  if (router.currentRoute.query.date_start_to) date_start_to.value = router.currentRoute.query.date_start_to
  if (router.currentRoute.query.group) group.value = router.currentRoute.query.group
  if (router.currentRoute.query.mastercourse_id) mastercourse_id.value = parseInt(router.currentRoute.query.mastercourse_id, 0)
  if (router.currentRoute.query.number) number.value = parseInt(router.currentRoute.query.number, 0)
  if (router.currentRoute.query.teacher_id) teacher_id.value = parseInt(router.currentRoute.query.teacher_id, 0)
  if (router.currentRoute.query.time_from) time_from.value = router.currentRoute.query.time_from
  if (router.currentRoute.query.time_to) time_to.value = router.currentRoute.query.time_to
  if (router.currentRoute.query.time_of_day) time_of_day.value = router.currentRoute.query.time_of_day

  watch(selectedCalendars, () => {
    if (selectedCalendars.value.length === 2) { calendarOptions.value.events = store.state.calendar.events }
    if (selectedCalendars.value.length === 0) { calendarOptions.value.events = [] }
    if (selectedCalendars.value.length === 1) {
      const result = calendarOptions.value.events.filter(ev => ev.extendedProps.calendar === selectedCalendars.value[0].toLowerCase())
      calendarOptions.value.events = result
    }
  })
  watch(selectedCalendars, () => {
    refetchEvents()
    // calendarOptions.value.events.test = 'tiiiset'
  })
  watch(classroom_id, () => {
    // selectedCalendars.value = store.state.calendar.selectedCalendars
    store.commit('calendar/SET_SELECTED_EVENTS', ['Available', 'Unavailable'])
    calendarOptions.value.events = fetchEvents
  })

  // ------------------------------------------------------------------------

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const isEventHandlerSidebarActive = ref(false)
  const isFilterHandlerSidebarActive = ref(false)
  const isEventModalActive = ref(false)
  const isModalActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)
  const isAttendanceModalActive = ref(false)
  const isSessionHandlerSidebarActive = ref(false)

  return {
    number,
    group,
    mastercourse_id,
    teacher_id,
    date_start_from,
    date_start_to,
    date_end_from,
    date_end_to,
    time_from,
    time_to,
    time_of_day,
    TofDArray,
    statusArray,
    fStatus,
    urlParams,
    errors,
    refCalendar,
    formatDateTime,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    classroomOptions,
    teachersOptions,
    selectedClassroom,
    classroom_id,
    event,
    clearEventData,
    addEvent,
    updateEvent,
    removeEvent,
    refetchEvents,
    fetchEvents,
    getClassrooms,
    getTeachers,
    fetchMCourses,
    isBusyCalendar,
    getSuggestions,

    isAttendanceModalActive,
    isSessionHandlerSidebarActive,

    // ----- UI ----- //
    isEventHandlerSidebarActive,
    isFilterHandlerSidebarActive,
    isEventModalActive,
    isModalActive,
  }
}
