<template>

  <div class="container my-2">

    <!-- <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="dayTimeOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    /> -->

    <!-- Filters -->
    <courses-list-filters
      :time-of-day-filter.sync="timeOfDayFilter"
      :type-filter.sync="typeFilter"
      :month-filter.sync="monthFilter"
      :level-filter.sync="levelFilter"
      :day-time-options="dayTimeOptions"
      :type-options="typeOptions"
      :month-options="monthOptions"
      :level-options="levelOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                debounce="500"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchCourses"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.id }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: visa -->
        <template #cell(name)="data">
          {{ data.item.course_name }}
        </template>

        <!-- Column: Days -->
        <template #cell(days)="data">
          {{ data.item.days }}
        </template>

        <!-- Column: Start -->
        <template #cell(start)="data">
          {{ data.item.date_start }}
        </template>

        <!-- Column: End -->
        <template #cell(end)="data">
          {{ data.item.date_end }}
        </template>

        <!-- Column: Start -->
        <template #cell(time)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.time_from }} - {{ data.item.time_to }}
          </b-badge>
        </template>

        <!-- Column: Hours -->
        <template #cell(hours)="data">
          {{ data.item.hours }}
        </template>
        <!-- Column: Start -->
        <template #cell(price)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            $ {{ data.item.price }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-b-modal="getModal1Id(data.item.id)"
            >
              <feather-icon icon="LogInIcon" />
              <span class="align-middle ml-50">Sign up</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-b-modal="getModal2Id(data.item.id)"
            >
              <feather-icon icon="FlagIcon" />
              <span class="align-middle ml-50">Claim your spot</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-b-modal="getModal3Id(data.item.id)"
            >
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">Cancel course</span>
            </b-dropdown-item>
            <b-dropdown-item disabled>
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Waiting list - Contact us!</span>
            </b-dropdown-item>

            <!-- basic modal -->
            <b-modal
              :id="'modal1'+data.item.id"
              title="Basic Modal"
              ok-title="Accept"
              @ok="signUp(data.item.id)"
            >
              <b-card-text>
                <h5>Sign up!</h5>
              </b-card-text>
            </b-modal>

            <!-- basic modal -->
            <b-modal
              :id="'modal2'+data.item.id"
              title="Basic Modal"
              ok-title="Accept"
              @ok="claimSpot(data.item.id)"
            >
              <b-card-text>
                <h5>Claim spot! </h5>
              </b-card-text>
            </b-modal>

            <!-- basic modal -->
            <b-modal
              :id="'modal3'+data.item.id"
              title="Basic Modal"
              ok-title="Accept"
              @ok="cancel(data.item.id)"
            >
              <b-card-text>
                <h5>Are you sure you want to cancel this course?</h5>
              </b-card-text>
            </b-modal>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BFormInput, BTable, BMedia, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { /* ref, */ onUnmounted } from '@vue/composition-api'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import courseStoreModule from '@/views/courses/courseStoreModule'
import CoursesListFilters from './CoursesListFilters.vue'
import useCoursesList from './useCoursesList'
// import UserListAddNew from './UserListAddNew.vue'

export default {
  components: {
    CoursesListFilters,
    // UserListAddNew,

    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,

    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    /* eslint-disable camelcase */
    const getModal1Id = id => `modal1${id}`
    const getModal2Id = id => `modal2${id}`
    const getModal3Id = id => `modal3${id}`

    const USER_APP_STORE_MODULE_NAME = 'app-course'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, courseStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const dayTimeOptions = [
      { label: 'Anytime', value: 'anytime' },
      { label: 'Daytime', value: 'daytime' },
      { label: 'Evening', value: 'evening' },
    ]

    const typeOptions = [
      { label: 'All', value: 'all' },
      { label: 'Online', value: 'online' },
      { label: 'Individual', value: 'individual' },
      { label: 'Standard', value: 'standard' },
      { label: 'Intensive', value: 'intensive' },
      { label: 'Grammar', value: 'grammar' },
      { label: 'Exam', value: 'exam' },
      { label: 'Conversation', value: 'conversation' },
    ]

    const monthOptions = [
      { label: 'All', value: '' },
      { label: 'Jan', value: '01' },
      { label: 'Feb', value: '02' },
      { label: 'Mar', value: '03' },
      { label: 'Apr', value: '04' },
      { label: 'May', value: '05' },
      { label: 'Jun', value: '06' },
      { label: 'Jul', value: '07' },
      { label: 'Aug', value: '08' },
      { label: 'Sep', value: '09' },
      { label: 'Oct', value: '10' },
      { label: 'Nov', value: '11' },
      { label: 'Dec', value: '12' },
    ]

    const levelOptions = [
      { label: 'All', value: 'all' },
      { label: 'A1', value: 'A1' },
      { label: 'A2', value: 'A2' },
      { label: 'B1', value: 'A2' },
      { label: 'B2', value: 'A2' },
      { label: 'C1', value: 'A2' },
      { label: 'C2', value: 'A2' },
    ]

    // Use toast
    // const toast = useToast()

    const {
      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      timeOfDayFilter,
      typeFilter,
      monthFilter,
      levelFilter,
    } = useCoursesList()

    const cancel = (/* cancelId */) => {
      // store.dispatch('app-course/cancelRegistration', cancelId)
      //   .then(() => {
      //     toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: 'Registration Has Been canceled',
      //         icon: 'AlertTriangleIcon',
      //         variant: 'success',
      //       },
      //     })
      // }).catch(() => {
      //   toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'Error while canceling registration',
      //       icon: 'AlertTriangleIcon',
      //       variant: 'danger',
      //     },
      //   })
      //   })
    }
    const signUp = (/* regId */) => {
      // console.log(regId)
      // store.dispatch('app-course/deleteRegistration', regId)
      //   .then(() => {
      //     toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: 'Succeffuly signed up.',
      //         icon: 'AlertTriangleIcon',
      //         variant: 'success',
      //       },
      //     })
      // }).catch(() => {
      //   toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'Error while signing up to this course.',
      //       icon: 'AlertTriangleIcon',
      //       variant: 'danger',
      //     },
      //   })
      //   })
    }
    const claimSpot = (/* regId */) => {
      // console.log(regId)
      // store.dispatch('app-course/deleteRegistration', regId)
      //   .then(() => {
      //     toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: 'Succeffuly signed up.',
      //         icon: 'AlertTriangleIcon',
      //         variant: 'success',
      //       },
      //     })
      // }).catch(() => {
      //   toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'Error while signing up to this course.',
      //       icon: 'AlertTriangleIcon',
      //       variant: 'danger',
      //     },
      //   })
      //   })
    }

    return {
      // Modal ids
      getModal1Id,
      getModal2Id,
      getModal3Id,

      // Actions
      signUp,
      cancel,
      claimSpot,

      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      dayTimeOptions,
      typeOptions,
      monthOptions,
      levelOptions,

      // Extra Filters
      timeOfDayFilter,
      typeFilter,
      monthFilter,
      levelFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
