import axios from '@axios'

export default {
  namespaced: true,
  state: {
    cities: {
      rows: [],
      totalRecords: 0,
    },
  },
  getters: {},
  /* eslint-disable camelcase */
  mutations: {
    setTests(state, users) {
      state.cities = users
    },
    deleteCity(state, userId) {
      const regData = {
        rows: state.cities.rows.filter(reg => reg.id !== userId),
        totalRecords: state.cities.totalRecords - 1,
      }
      state.cities = regData
    },
  },
  actions: {
    fetchUsers(store, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/placement_test/student/list', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    prolong(store, { new_due_date, testId }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/placement_test/student/${testId}/prolong`, { new_due_date: new_due_date.value })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    StudentProlong(store, testId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/placement_test/student/${testId}/request_prolong`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/get-user/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCity(ctx, cityData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cities', cityData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put('/user/update', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCity(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/cities/${id}`)
          .then(response => {
            store.commit('deleteCity', id)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    editCity(store, { id, payload }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/cities/${id}`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    autoComplete(store, search) {
      return new Promise((resolve, reject) => {
        axios
          .post('/templates/autocomplete', search)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    autoCompleteCourses(store, search) {
      return new Promise((resolve, reject) => {
        axios
          .post('/autocomplete/course', search)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
