import { ref, watch, computed } from '@vue/composition-api'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function useCitiesList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'name', sortable: true },
    { key: 'ordering', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions', tdClass: 'table-actions' },
  ]
  const perPage = ref(10)
  const totalCities = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const userData = JSON.parse(localStorage.getItem('userData'))
  const studentId = ref(userData.id)
  /* eslint-disable-next-line */
  const role_name = ref(window.location.href.split('/')[3])

  const selectedFilter = ref({ label: 'Active', value: 'Active' })

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCities.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }
  /* eslint-disable-next-line */
  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter, role_name, selectedFilter],  () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    const payload = {
      search: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      role_name: roleFilter.value,
      plan: planFilter.value,
      status: statusFilter.value,
      filter: selectedFilter.value.value,
      // role_name: role_name.value,
    }
    if (userData.role === 'student') {
      payload.student_id = studentId.value
    }
    store
      .dispatch('city/fetchUsers', payload)
      .then(() => {
        const users = store.state.cityStoreModule.cities.rows
        const total = store.state.cityStoreModule.cities.totalRecords
        callback(users)
        totalCities.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching cities list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'Pending') return 'warning'
    if (status === 'Active') return 'success'
    if (status === 'Deleted') return 'danger'
    return 'primary'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalCities,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    role_name,
    studentId,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    selectedFilter,
  }
}
