<template>
  <div class="mb-1">
    <span>{{ QuestionData.number_of_question }}.</span>
    <component
      :is="item.type"
      v-for="(item, i) in question"
      :key="i"
      :content="item.content"
      :idx="item.idx"
      @responseevent="listenResponses"
    />
  </div>
</template>

<script>
    import InputComponent from './InputComponent.vue'
    import CheckboxComponent from './CheckboxComponent.vue'
    import RadioComponent from './RadioComponent.vue'
    import textComponent from './textComponent.vue'
    import SelectComponent from './SelectComponent.vue'
    import ImageComponent from './ImageComponent.vue'
    import AudioComponent from './AudioComponent.vue'
    import VideoComponent from './VideoComponent.vue'

    export default {
        name: 'QuestionComponent',
        components: {
 InputComponent, textComponent, CheckboxComponent, RadioComponent, SelectComponent, ImageComponent, AudioComponent, VideoComponent,
},
        props: {
            QuestionData: Object,
            questionId: Number,
        },
        data() {
            return {
                question: {},
            }
        },
        computed: {
        },
        mounted() {
            this.withInputFields(this.$props.QuestionData.question)
        },
        methods: {
            // beakdown questions to an array of text and inputs
            /* eslint-disable no-param-reassign */
            withInputFields(text) {
                const { fields } = this.$props.QuestionData
                const splt = text.split(/s*({.*?})s*/g)
                let reslt = splt.map(element => {
                    const matched = element.match(/{([^}]+)}/)
                    if (matched) {
                        fields.forEach(field => {
                            if (field.id === parseInt(matched[1].split('-').pop(), 10)) {
                                element = {
                                    type: `${field.formType}Component`,
                                    content: field,
                                    idx: parseInt(matched[1].split('-').pop(), 10),
                                }
                            }
                        })
                    }
                    if (!matched && element.length > 0) {
                        element = {
                            type: 'textComponent',
                            content: {
                                text: element,
                            },
                        }
                    }
                    return element
                })
                reslt = reslt.filter(element => {
                    return element !== ''
                })
                this.$data.question = reslt
            },
            listenResponses(response) {
                console.log(response)
                const { QuestionData } = this.$props
                QuestionData.fields.forEach(field => {
                    if (field.id === response.id) {
                        field.response = response.response
                    }
                })
                this.$emit('question_responses', { QuestionData, questionId: this.$props.questionId })
            },

        },
    }
</script>

<style scoped>
* {
  text-align: left;
}
</style>
