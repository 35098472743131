import { ref, watch, computed } from '@vue/composition-api'
// import { title } from '@core/utils/filter'
// Notification

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { useUtils as useI18nUtils } from '@core/libs/i18n'
import store from '@/store'
import router from '@/router'

export default function useCoursesList() {
  // Use toast
  const toast = useToast()
  // const { t } = useI18nUtils()

  const refgroupTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'selected', label: '', sortable: true },
    { key: 'group', sortable: true },
    { key: 'course' },
    { key: 'period' },
    { key: 'days' },
    { key: 'actions', tdClass: 'table-actions' },
  ]
  const detailsColumns = [
    { key: 'course_name' },
    { key: 'period' },
    { key: 'days' },
    { key: 'teachers_name', label: 'teacher' },
    { key: 'classroom' },
    { key: 'available', label: 'conf/inconf/av' },
    { key: 'notes' },
  ]
  const selectedRows = ref([])
  const perPage = ref(10)
  const totalElements = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('period')
  const isSortDirDesc = ref(true)
  const filters = ref([
    { label: 'Pending claims', value: 'pending' },
    { label: 'Approved claims', value: 'approved' },
    { label: 'Rejected claims', value: 'rejected' },
  ])
  const selectedFilter = ref('pending')
  const dataMeta = computed(() => {
    const localItemsCount = refgroupTable.value ? refgroupTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalElements.value,
    }
  })
  /* eslint-disable camelcase */
  const refetchData = () => {
    refgroupTable.value.refresh()
  }
  const urlParams = ref(new URLSearchParams())
  if (router.currentRoute.query.search) searchQuery.value = router.currentRoute.query.search
  if (router.currentRoute.query.page) currentPage.value = router.currentRoute.query.page
  if (router.currentRoute.query.perPage) perPage.value = router.currentRoute.query.perPage

  watch([currentPage, perPage, selectedFilter, searchQuery], () => {
    urlParams.value.set('?search', searchQuery.value ? searchQuery.value : '')
    urlParams.value.set('page', currentPage.value ? currentPage.value : '')
    urlParams.value.set('perPage', perPage.value ? perPage.value : '')
    window.history.replaceState(null, null, decodeURIComponent(urlParams.value))
    refetchData()
  })
  const isBusy = ref(false)
  const isBusyDetail = ref(false)
  const isChatActive = ref(false)
  const chatModelId = ref(null)
  const handleChatClick = data => {
    isChatActive.value = true
    chatModelId.value = data
  }

  const fetchElements = (ctx, callback) => {
    const payload = {
      search: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      filter: selectedFilter.value,
    }
    store
      .dispatch('group/fetchGroups', payload)
      .then(res => {
        const users = res.data.rows
        const total = res.data.totalRecords
        callback(users)
        totalElements.value = total
      })
      .catch(() => {
        isBusy.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching group list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const details = ref([])
  const createGroup = group => {
    store.dispatch('group/createGroup', { group })
      .then(res => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
      .catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    selectedRows,
    createGroup,
    details,
    selectedFilter,
    filters,
    fetchElements,
    tableColumns,
    detailsColumns,
    perPage,
    currentPage,
    totalElements,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refgroupTable,
    refetchData,
    handleChatClick,
    isChatActive,
    chatModelId,
    isBusy,
    isBusyDetail,
  }
}
