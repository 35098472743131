import {
  ref, watch, computed,
} from '@vue/composition-api'
// import { title } from '@core/utils/filter'
// import { datetimeToDate } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'

export default function useMastersList() {
  // Use toast
  const toast = useToast()

  const refCourseListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    // { key: 'student', sortable: true },
    { key: 'name', sortable: true },
    {
      key: 'code',
      sortable: true,
      label: 'code',
    },
    {
      key: 'classroom_type',
      label: 'classroom   type',
      sortable: true,
    },
    {
      key: 'status',
      label: 'Status',
    },
    { key: 'actions', tdClass: 'table-actions' },
  ]
  // lists
  const number = ref(null)
  const group = ref(null)
  /* eslint-disable camelcase */
  const mastercourse_id = ref(null)
  const classroom_id = ref(null)
  const teacher_id = ref(null)
  const date_start_from = ref(null)
  const date_start_to = ref(null)
  const date_end_from = ref(null)
  const date_end_to = ref(null)
  const fStatus = ref(null)
  const time_of_day = ref(null)
  const cities = ref([])
  const masters = ref([])
  const classrooms = ref([])
  const teachers = ref([])
  const statusArray = ref([
    { name: 'Active', value: 'active' },
    { name: 'Hidden', value: 'hidden' },
    { name: 'Past', value: 'past' },
    { name: 'Deleted', value: 'deleted' },
    { name: 'Planned', value: 'planned' },
  ])
  const TofDArray = ref([
    { name: 'daytime', value: 'daytime' },
    { name: 'anytime', value: 'anytime' },
    { name: 'evening', value: 'evening' },
  ])
  //
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('code')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const currentUser = ref({})
  /* eslint-disable-next-line */
  const role_name = ref(window.location.href.split('/')[6])

  const dataMeta = computed(() => {
    const localItemsCount = refCourseListTable.value ? refCourseListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })
  // lists
  // const fetchCities = () => {
  //   store.dispatch('app-courses/fetchCities')
  //     .then(() => {
  //       cities.value = store.state.coursesStoreModule.cities
  //     })
  // }
  // const fetchMCourses = () => {
  //   store.dispatch('app-courses/fetchMCourses')
  //     .then(() => {
  //       masters.value = store.state.coursesStoreModule.Mcourses
  //     })
  // }
  // const fetchTeachers = () => {
  //   store.dispatch('app-courses/fetchTeachers')
  //     .then(() => {
  //       teachers.value = store.state.coursesStoreModule.teachers
  //     })
  // }
  // const fetchClassrooms = () => {
  //   store.dispatch('app-courses/fetchClassrooms')
  //     .then(() => {
  //       classrooms.value = store.state.coursesStoreModule.classrooms
  //     })
  // }
  // call on mounted
  // onMounted(fetchMCourses)
  // onMounted(fetchTeachers)
  // onMounted(fetchClassrooms)
  // onMounted(fetchCities)
  //
  const refetchData = () => {
    refCourseListTable.value.refresh()
  }
  const isBusy = ref(false)
  // const Types = ref([])
  // const edit = ref([])

  const fetchTypes = (context, callback) => {
    store.dispatch('app-Masters/fetchMCourses', {
      search: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    })
      .then(() => {
        masters.value = store.state.coursesStoreModule.Mcourses.rows
        callback(masters.value)
        totalUsers.value = store.state.coursesStoreModule.Mcourses.totalRecords
      })
        .catch(() => {
          isBusy.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching master courses list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
      })
  }
  const urlParams = ref(new URLSearchParams())
  if (router.currentRoute.query.search) searchQuery.value = router.currentRoute.query.search
  if (router.currentRoute.query.page) currentPage.value = router.currentRoute.query.page
  if (router.currentRoute.query.perPage) perPage.value = router.currentRoute.query.perPage
  const recheckLoading = () => {
    if (!isBusy.value) return refetchData()
    return setTimeout(() => recheckLoading(), 1000)
  }

  /* eslint-disable-next-line */
  watch([currentPage, perPage, number, group, mastercourse_id, teacher_id, classroom_id, date_start_from, date_start_to, date_end_from, date_end_to, fStatus, time_of_day],  () => {
    urlParams.value.set('?search', searchQuery.value ? searchQuery.value : '')
    urlParams.value.set('page', currentPage.value ? currentPage.value : '')
    urlParams.value.set('perPage', perPage.value ? perPage.value : '')
    window.history.replaceState(null, null, decodeURIComponent(urlParams.value))
    recheckLoading()
  })
  watch(searchQuery, () => {
    urlParams.value.set('?search', searchQuery.value ? searchQuery.value : '')
    window.history.replaceState(null, null, decodeURIComponent(urlParams.value))
    refetchData()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'Deleted' || status === 'deleted') return 'danger'
    if (status === 'Active' || status === 'active') return 'success'
    return 'primary'
  }

  return {
    isBusy,
    fetchTypes,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCourseListTable,
    role_name,
    number,
    group,
    mastercourse_id,
    teacher_id,
    classroom_id,
    date_start_from,
    date_start_to,
    date_end_from,
    date_end_to,
    fStatus,
    time_of_day,
    cities,
    masters,
    classrooms,
    teachers,
    statusArray,
    TofDArray,
    currentUser,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
