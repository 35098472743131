<template>
  <div>

    <b-table
      ref="refUserListTable"
      responsive
      :items="$store.state.appConfig.studentPayments.pendingPaymentsList"
      show-empty
      :fields="fields"
      empty-text="No matching records found"
      :busy.sync="isBusy"
    >
      <template #table-busy>
        <div
          v-if="isBusy"
          class="text-center"
        >
          <b-spinner
            variant="primary"
            label="Text Centered"
          />
          <strong class="ml-1">{{ $t('Loading...') }}</strong>
        </div>
      </template>

      <template #cell(courses)="data">
        <b-row
          v-for="course in data.item.courses"
          :key="course.id"
        >
          <b-col cols="12" md="6"><strong v-b-tooltip.hover.top="course.name" style="max-width: 300px" class="mr-25 text-truncate d-block text-nowrap">{{ course.name }}</strong><small class="mr-25">({{ course.group }})</small></b-col>
          <b-col cols="12" md="3"><span class="text-nowrap">{{ formatDateTime(course.date_start.split(' ')[0]) }} - {{ formatDateTime(course.date_end.split(' ')[0]) }}</span></b-col>
          <b-col cols="12" md="3"><span class="text-nowrap">{{ course.time_from }} - {{ course.time_to }}</span></b-col>
        </b-row>
      </template>

      <template #cell(amount)="data">
        <span v-if="data.item.amount" class="text-nowrap">{{ data.item.amount }} {{ $store.state.appConfig.currency }}</span>
      </template>

      <template #cell(actions)="data">
        <b-row>
          <b-col cols="12" md="5">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="col-5 text-nowrap mb-50"
              :variant="data.item.availableC ? 'success' : 'danger'"
              target="_blank"
              style="min-width: 110px"
              size="sm"
              :disabled="!data.item.availableC"
              :href="data.item.checkout_url ? data.item.checkout_url : `${checkoutUrl}?paymentId=${data.item.payment_id}`"
            >
              <!-- <feather-icon icon="CheckCircleIcon" /> -->
              <span>{{ data.item.availableC ? 'Pay now' : 'Expired' }}</span>
            </b-button>
          </b-col>
          <!-- <b-col cols="0" md="1"></b-col> -->
          <b-col cols="12" md="5">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              class="col-5 text-nowrap"
              style="min-width: 110px"
              size="sm"
              @click="notInterested(data.item.payment_id)"
            >
              <span>{{ data.item.availableC ? 'No, Thanks' : 'Hide' }}</span>
            </b-button>
          </b-col>
        </b-row>
      </template>

    </b-table>
  </div>
</template>

<script>
/* eslint-disable camelcase, object-curly-newline */
import {
  BTable, BSpinner, BButton, BRow, BCol, VBTooltip,
  } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTable,
    BSpinner,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      checkoutUrl: process.env.VUE_APP_CHECKOUT,
      // perPage: 10,
      isBusy: false,
      fields: [
        { key: 'courses', label: 'courses' },
        { key: 'amount', label: 'amount' },
        // { key: 'time', label: 'Timetable' },
        { key: 'actions', label: 'actions', tdClass: 'table-actions' },
      ],
    }
  },
  methods: {
    notInterested(paymentId) {
      this.$http.post('/course/mark/not-interested', { paymentId })
      .then(() => {
        this.fetchItems()
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    fetchItems() {
      this.isBusy = true
      this.$http.get('/invoices/awaiting/paid')
      .then(res => {
        this.isBusy = false
        this.$store.state.appConfig.studentPayments.pendingPaymentsList = res.data.payments
        this.$store.state.appConfig.studentPayments.availableCount = res.data.available_count
      })
    },
    // confirm(id) {
    //   this.isBusy = true
    //   this.$http.post(`/registrations/confirm-reg-student/${id}`).then(() => this.fetchItems())
    // },
    // cancel(id) {
    //   this.isBusy = true
    //   this.$http.post(`/registrations/cancel-reg-student/${id}`).then(() => this.fetchItems())
    // },
  },
  setup() {
    const formatDateTime = dateTime => {
      if (typeof dateTime !== 'undefined') {
        const timestamp = new Date(dateTime).getTime()
        const todate = new Date(timestamp).getDate()
        const tomonth = new Date(timestamp).getMonth() + 1
        const toyear = new Date(timestamp).getFullYear()

        const today = `0${todate.toString().slice(-2)}`
        const thisMonth = `0${tomonth.toString().slice(-2)}`
        const day = (today.length === 2 ? today : today.substr(1))
        const month = (thisMonth.length === 2 ? thisMonth : thisMonth.substr(1))

        const original_date = `${day}-${month}-${toyear}`
        return original_date
      }
      return ''
    }
    return {
      formatDateTime,
    }
  },
}
</script>
<style scoped>
.table-light {
  opacity: 0.6;
}
.modal-title {
  margin-left: auto;
}
</style>
