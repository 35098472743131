<template>
  <div>
    <b-card-text>
      <h5>
        Do you want to request a substitute for <strong>{{ title }}</strong> on <strong class="text-nowrap">{{ dateStart }}</strong>?
      </h5>
    </b-card-text>
    <!-- <div
      v-if="isBusy"
      class="d-flex justify-content-center p-2 align-items-center"
    >
      <b-spinner
        variant="primary"
        label="Loading..."
      />
    </div>
    <div v-if="!isBusy && teacherOptions.length === 0" class="d-flex align-items-center justify-content-between">
      <h5>No teacher available</h5>
    </div>
    <b-form
      v-if="!isBusy && teacherOptions.length !== 0"
      class="p-2"
      @submit.prevent="AddSubstitute"
    >
      <b-list-group style="columns: 2; display: inherit;">
        <b-list-group-item
          v-for="teacher in teacherOptions"
          :key="teacher.value"
          style="padding: 0.3rem 1.25rem; width: 100%; display: inline-block;"
          class="border-0"
        >
          <b-form-checkbox
            v-model="selectedTeacher"
            :value="teacher.id"
          >
            {{ teacher.name }}
          </b-form-checkbox>
        </b-list-group-item>
      </b-list-group> -->
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="secondary"
          type="submit"
          @click="$emit('hide-modal')"
        >
          No
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="ml-50"
          type="submit"
          @click="AddSubstitute"
        >
          Yes
        </b-button>
      </div>
    <!-- </b-form> -->
  </div>
</template>
<script>
/* eslint-disable-next-line object-curly-newline */
import {
  BCardText, BButton,
  /* BForm, BFormCheckbox, BButton, BListGroup, BListGroupItem, BSpinner, */
  } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardText,
    // BForm,
    // BFormCheckbox,
    // BListGroup,
    // BListGroupItem,
    BButton,
    // BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    sessionId: {
      type: Number,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    dateStart: {
      type: String,
      required: false,
    },
    dateEnd: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      selectedLevel: [],
      selectedDay: [],
      selectedTime: [],
      teacherOptions: [],
      isBusy: false,
    }
  },
  created() {
    // this.findSubstitute()
  },
  methods: {
    AddSubstitute() {
      const payload = { session_id: this.sessionId }
      this.$http.post('/session/teacher/request/substitute', payload)
        .then(res => {
          this.$emit('hide-modal')
          this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: res.data.message,
            icon: 'CopyIcon',
            variant: 'success',
          },
        })
        })
        .catch(err => {
          this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    },
    // findSubstitute() {
    //   this.isBusy = true
    //   this.$http.post('/teacher/substitute/find-by-session', { session_id: this.sessionId })
    //   .then(res => {
    //     this.isBusy = false
    //     this.teacherOptions = res.data
    //   })
    // },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
