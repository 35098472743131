import axios from '@axios'

export default {
  namespaced: true,
  state: {
    classrooms: {
      rows: [],
      totalRecords: 0,
    },
  },
  getters: {},
  /* eslint-disable camelcase */
  mutations: {
    setClassrooms(state, users) {
      state.classrooms = users
    },
  },
  actions: {
    fetchCourses(store, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/attendance/courses', { params: queryParams })
          .then(response => {
            store.commit('setClassrooms', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getReview(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/reviews/list', { params: { course_id: id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
