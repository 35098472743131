<template>
  <div>
    <div
      v-if="isLoading"
      class="text-center d-flex align-items-center justify-content-center"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
      <strong class="ml-1">{{ $t('Loading...') }}</strong>
    </div>
    <div
      v-if="courseChart && !isLoading"
      class="courses"
    >
      <app-echart-doughnut :series="series" />
      <!-- <vue-apex-charts
        ref="donut"
        height="280px"
        type="pie"
        :options="chartOptions"
        :series="series"
      /> -->
    </div>
  </div>
</template>

<script>
  import { BSpinner } from 'bootstrap-vue'
  import AppEchartDoughnut from '@core/components/charts/echart/AppEchartDoughnut.vue'
  // import VueApexCharts from 'vue-apexcharts'
  import store from '@/store'

  export default {
    name: 'DonutCourses',
    components: {
      // VueApexCharts,
      AppEchartDoughnut,
      BSpinner,
    },
    props: {
      courseChart: {
        type: Object,
      },
    },
    data() {
      return {
        chartOptions: {
          labels: [],
        },
        series: [{
          name: 'Confirmed registrations',
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['50%', '40%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 40,
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data: [],
        }],
        isLoading: false,
      }
    },
    mounted() {
      // this.chartOptions.labels = this.courseChart.labels
      // this.series = this.courseChart.values
      // const labels = []
      this.isLoading = true
      setTimeout(() => {
        store.state.coursesStoreModule.coursesAvailability.forEach(c => {
          this.series[0].data.push({ value: c.reg_conf_cnt, name: c.course_name })
        })
        this.isLoading = false
      }, 3000)
    },
    methods: {
    },
  }
</script>
