<template>
  <div>
    <div class="mb-3">
      <h4 class="text-center">
        {{ $t('Help up make your course even better!') }}
      </h4>
      <div v-if="surveyData.type === 'survey1'">
        <!-- {{ surveyData.data.course.code }} -->
        <h5 class="text-center">
          {{ $t('Course') }}: {{ surveyData.data.course.code }} ({{ surveyData.data.course.group }})
        </h5>
        <h5 class="text-center">
          {{ $t('Teacher') }}: {{ surveyData.data.course.teacher.name }}
        </h5>
        <h5 class="text-center">
          {{ $t('Date') }}: {{ surveyData.data.course.date_start.split(' ')[0] }} - {{ surveyData.data.course.date_end.split(' ')[0] }}
        </h5>
      </div>
      <div
        v-if="surveyData.type === 'survey2'"
        class="text-center"
      >
        <small>This survey is anonymous and directly addressed to the school management.</small>
      </div>
    </div>
    <validation-observer
      #default="{ handleSubmit }"
      ref="surveyForm1"
    >
      <b-form
        v-if="surveyData.type === 'survey1'"
        @submit.prevent="handleSubmit(submitSurvey1(survey1, surveyData.data.survey.id))"
      >
        <b-row>
          <!-- first name -->
          <b-col
            v-for="(question, index) in survey1"
            :key="question.label"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              rules="required"
            >
              <b-form-group
                v-if="index !== 7 && index !== 6"
                :label="`${index+1}. ${question.label}`"
              >
                <b-form-input
                  v-model="question.value"
                  placeholder="..."
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
            >
              <b-form-group
                v-if="index === 7 || index === 6"
                :label="`${index}. ${question.label}`"
              >
                <b-form-textarea
                  v-model="question.value"
                  placeholder="..."
                  :state="getValidationState(validationContext)"
                  rows="3"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- submit and reset -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <validation-observer
      #default="{ handleSubmit }"
      ref="surveyForm2"
    >
      <b-form
        v-if="surveyData.type === 'survey2'"
        @submit.prevent="handleSubmit(submitSurvey2(survey2, surveyData.data.survey.id))"
      >
        <b-row>
          <!-- first name -->
          <b-col
            v-for="(question, index) in survey2"
            :key="question.label"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              rules="required"
            >
              <b-form-group
                v-if="question.options && index !== 2"
                :label="`${index+1}. ${question.label}`"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="question.value"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="label"
                  :clearable="false"
                  :options="question.options"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="Details"
            >
              <b-form-group
                v-if="index !== 2 && question.value.hasDetails"
                class="mb-1"
                :label="$t('Details')"
                label-for="details"
              >
                <b-form-input
                  v-model="question.value.details"
                  placeholder="..."
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              rules="required"
            >
              <b-form-group
                v-if="!question.options && index !== 2"
                :label="`${index}. ${question.label}`"
              >
                <b-form-textarea
                  v-model="question.value"
                  placeholder="..."
                  :state="getValidationState(validationContext)"
                  rows="3"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group
              v-if="index === 2"
              :label="$t('Your opinion')"
            >
              <b-list-group>
                <b-list-group-item
                  v-for="option in question.value"
                  :key="option.label"
                >
                  <div class="row">
                    <span class="col-6">{{ option.label }}</span>
                    <div class="demo-inline-spacing col-6">
                      <b-form-checkbox
                        v-for="rate in question.rateOptions"
                        :key="rate"
                        v-model="option.value"
                        :value="rate"
                      >
                        {{ rate }}
                      </b-form-checkbox>
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Submit
            </b-button>
            <!-- <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Reset
            </b-button> -->
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormTextarea, BListGroup, BListGroupItem, BFormCheckbox,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormTextarea,
    vSelect,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    surveyData: {
      type: Object,
    },
  },
  data() {
    return {
      required,
      survey1: [
        { label: this.$t('What is your goal by learning Norwegian? (f.eg.  to pass bergenstest; to find a better job etc.)'), value: '' },
        { label: this.$t('What is the best method for you to learn a foreign language? (How do you learn best? f.eg. reading,; listening to people; working with grammar)'), value: '' },
        { label: this.$t('What do you like about your course?'), value: '' },
        { label: this.$t('What else would you like to work with during your course?'), value: '' },
        { label: this.$t('How would you describe your course so far?'), value: '' },
        { label: this.$t('What do you like about your teacher?'), value: '' },
        { label: this.$t('Do you have any suggestions for how your teacher can improve the lessons?'), value: '' },
        { label: this.$t('Any additional comments?'), value: '' },
      ],
      survey2: [
        {
          label: this.$t('How did you get to know about us?'),
          options: [
            { label: this.$t('Google advert'), hasDetails: false },
            { label: this.$t('Facebook'), hasDetails: false },
            { label: this.$t('Internet (which website?)'), hasDetails: true, details: '' },
            { label: this.$t('A brochure / flyer (where did you get it from?)'), hasDetails: true, details: '' },
            { label: this.$t('A friend / family'), hasDetails: false },
            { label: this.$t('Other (please specify)'), hasDetails: true, details: '' },
          ],
          value: '',
        },
        {
          label: this.$t('Where did you attend your previous Norwegiancourse(s)?'),
          options: [
            { label: this.$t('This is my first Norwegian course'), hasDetails: false },
            { label: this.$t('Alfaskolen'), hasDetails: false },
            { label: this.$t('Another school (please specify)'), hasDetails: true, details: '' },
          ],
          value: '',
        },
        {
          rateOptions: [this.$t('Bad'), this.$t('Not bad'), this.$t('Good'), this.$t('Very good'), this.$t('I don\'t know')],
          label: this.$t('Your opinion'),
          value: [
            { label: this.$t('Teacher\'s professionality'), value: '' },
            { label: this.$t('Teacher\'s structure'), value: '' },
            { label: this.$t('Your contact with the teacher'), value: '' },
            { label: this.$t('Group atmosphere'), value: '' },
          ],
        },
        { label: this.$t('What do you like about your teacher?'), value: '' },
        { label: this.$t('What do you not like about your teacher?'), value: '' },
        {
          label: this.$t('Would you like to continue learning with Alfaskolen?'),
          options: [
            { label: this.$t('Yes (go to 9)'), hasDetails: false },
            { label: this.$t('No (why not?)'), hasDetails: true, details: '' },
            { label: this.$t('I don\'t know'), hasDetails: false },
          ],
          value: '',
        },
        {
          label: this.$t('Is it alright for you if it would be the same teacher on your next course?'),
          options: [{ label: this.$t('Yes') }, { label: this.$t('No') }],
          value: '',
        },
        { label: this.$t('Your additional comment:'), value: '' },
      ],
    }
  },
  setup(props, { emit }) {
    const toast = useToast()
    const submitSurvey1 = function (survey, id) {
      // if (this.$refs.surveyForm1.flags.valid) {
        this.$http.post('survey/response', { responses: survey, survey_id: id }).then(res => {
          emit('close-survey-modal')
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'BellIcon',
              variant: 'dark',
            },
          })
        }).catche(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error while sending the survey',
              icon: 'BellIcon',
              variant: 'dark',
            },
          })
        })
        // console.log(survey)
      // }
    }
    const submitSurvey2 = function (survey, id) {
      // if (this.$refs.surveyForm2.flags.valid) {
        this.$http.post('survey/response', { responses: survey, survey_id: id }).then(res => {
          emit('close-survey-modal')
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }).catche(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error while sending the survey',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        })
      // }
    }
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(() => {})
    return {
      refFormObserver,
      getValidationState,
      submitSurvey1,
      submitSurvey2,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
