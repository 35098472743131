<template>

  <div>

    <event-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <events-list-filters
      :role-filter.sync="roleFilter"
      :date-start-from-filter.sync="dateStartFromFilter"
      :date-start-to-filter.sync="dateStartToFilter"
      :date-end-from-filter.sync="dateEndFromFilter"
      :date-end-to-filter.sync="dateEndToFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="dayOffOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            lg="6"
          >
            <v-select
              v-model="selectedFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filters"
              lebel="label"
              :clearable="false"
              :reduce="x => x.value"
              class="courses-filter d-inline-block mx-50"
              style="min-width: 150px;"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
                debounce="500"
              />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">{{ $t('Add event') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchEvents"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">{{ $t('Loading...') }}</strong>
          </div>
        </template>
        <!-- Column: Day off -->
        <template #cell(name)="data">
          <span
            v-b-tooltip.hover.top="data.item.name"
            class="d-inline-block text-truncate"
            style="max-width: 300px;"
          >{{ data.item.name }}</span>
        </template>
        <template #cell(day_off)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.day_off)}`"
            class="text-capitalize"
          >
            {{ data.item.day_off }}
          </b-badge>
        </template>
        <template #cell(date_start)="data">
          <span class="badge badge-pill badge-light-secondary">{{ formatDateTime(data.item.date_start) }}</span>
        </template>
        <template #cell(date_end)="data">
          <span class="badge badge-pill badge-light-secondary">{{ formatDateTime(data.item.date_end) }}</span>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            dropleft
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-b-modal="getModal2Id(data.item.id)"
              @click="data.item.day_off ? selectedDayOff = { label: 'Yes', value: true } : selectedDayOff = { label: 'No', value: false }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('Edit') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-b-modal="getModal1Id(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('Delete') }}</span>
            </b-dropdown-item>
            <!-- Remove modal -->
            <b-modal
              :id="'modal1'+data.item.id"
              :title="$t('Delete Event')"
              :ok-title="$t('Accept')"
              centered
              @ok="remove(data.item.id)"
            >
              <b-card-text>
                <h5>{{ $t('Are you sure you want to delete') }} </h5><strong>{{ data.item.name }}</strong>
              </b-card-text>
            </b-modal>

            <!-- Edit modal -->
            <b-modal
              :id="'modal2'+data.item.id"
              :title="data.item.name"
              :ok-title="$t('Update')"
              ok-only
              hide-footer
              centered
              no-close-on-backdrop
              @ok="edit(data.item)"
            >
              <validation-observer
                #default="{ handleSubmit }"
                ref="form"
              >
                <!-- Form -->
                <b-form
                  class="p-2"
                  @submit.prevent="handleSubmit(edit(data.item))"
                  @reset.prevent="resetForm"
                >
                  <!-- Name -->
                  <validation-provider
                    #default="validationContext"
                    name="Event name"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Event name')"
                      label-for="name"
                    >
                      <b-form-input
                        v-model="data.item.name"
                        autofocus
                        :state="getValidationState(validationContext)"
                        :placeholder="$t('Name')"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <!-- Date start -->
                  <validation-provider
                    #default="validationContext"
                    name="Start date"
                    rules="required"
                  >
                    <b-form-group
                      label="Start date"
                      label-for="start-date"
                      :state="getValidationState(validationContext)"
                    >
                      <flat-pickr
                        v-model="data.item.date_start"
                        class="form-control"
                        :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss', altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 }}"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <!-- Date end -->
                  <validation-provider
                    #default="validationContext"
                    name="Date end"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Date end')"
                      label-for="date_end"
                      :state="getValidationState(validationContext)"
                    >
                      <flat-pickr
                        v-model="data.item.date_end"
                        class="form-control"
                        :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss', altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 }}"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="validationContext"
                    name="Day off"
                    rules="required"
                  >
                    <b-form-group
                      :state="getValidationState(validationContext)"
                      :label="$t('Day off')"
                    >
                      <v-select
                        v-model="selectedDayOff"
                        label="label"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="dayOffOptions"
                        @click.native="errorMessage = ''"
                      />
                      <small class="text-danger">
                        {{ errorMessage }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                  <div class="d-flex mt-2">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      {{ $t('Update') }}
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-modal>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="3"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              class="per-page-selector d-inline-block mx-50"
              style="width: 100px"
              :clearable="false"
              menu-props="auto"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>
          <b-col
            cols="3"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} to {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="!isBusy"
              v-model="currentPage"
              :total-rows="totalEvents"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BFormInput, BFormGroup, BButton, BTable,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, BSpinner, VBTooltip, BFormInvalidFeedback, BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import EventsListFilters from './EventsListFilters.vue'
import useEventsList from './useEventsList'
import eventStoreModule from './eventStoreModule'
import EventListAddNew from './EventListAddNew.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    EventsListFilters,
    EventListAddNew,

    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BSpinner,
    BFormInvalidFeedback,
    BForm,

    vSelect,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    /* eslint-disable camelcase */
    const getModal1Id = id => `modal1${id}`
    const getModal2Id = id => `modal2${id}`
    const getChatId = id => `popover-target-${id}`

    const EVENT_STORE_MODULE_NAME = 'event'

    // Register module
    if (!store.hasModule(EVENT_STORE_MODULE_NAME)) store.registerModule(EVENT_STORE_MODULE_NAME, eventStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENT_STORE_MODULE_NAME)) store.unregisterModule(EVENT_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'administrator' },
      { label: 'Student', value: 'student' },
      { label: 'Teacher', value: 'teacher' },
      { label: 'Agent', value: 'agent' },
      { label: 'Company', value: 'company' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const dayOffOptions = [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ]
    const filters = [
      { label: 'All', value: null },
      { label: 'Active', value: 'Active' },
      { label: 'Deleted', value: 'Deleted' },
    ]
    const selectedDayOff = ref({})

    // Use toast
    const toast = useToast()

    const isChatActive = ref(false)
    const chatModelId = ref(null)
    const handleChatClick = data => {
      isChatActive.value = true
      chatModelId.value = data
    }

    const {
      isBusy,
      fetchEvents,
      tableColumns,
      perPage,
      currentPage,
      totalEvents,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      dateStartFromFilter,
      dateStartToFilter,
      dateEndFromFilter,
      dateEndToFilter,
      planFilter,
      statusFilter,
      selectedFilter,
    } = useEventsList()

    const remove = id => {
      store.dispatch('event/deleteEvent', id)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Event Has Been removed',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while removing Event',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const errorMessage = ref(null)
      /* eslint-disable no-param-reassign, no-unused-expressions, func-names */
    const edit = function (item) {
      item.day_off = selectedDayOff.value.value
      if (this.$refs.form.flags.valid) {
        if (selectedDayOff.value.value === undefined) {
          errorMessage.value = 'The Day off field is required'
        } else {
          store.dispatch('event/editEvent', item)
            .then(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Event Has Been edited',
                  icon: 'AlertTriangleIcon',
                  variant: 'success',
                },
              })
              refetchData()
          }).catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error while editing Event',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            })
        }
      }
    }
    const formatDateTime = dateTime => {
      if (typeof dateTime !== 'undefined') {
        const timestamp = new Date(dateTime).getTime()
        const todate = new Date(timestamp).getDate()
        const tomonth = new Date(timestamp).getMonth() + 1
        const toyear = new Date(timestamp).getFullYear()

        const today = `0${todate.toString().slice(-2)}`
        const thisMonth = `0${tomonth.toString().slice(-2)}`
        const day = (today.length === 2 ? today : today.substr(1))
        const month = (thisMonth.length === 2 ? thisMonth : thisMonth.substr(1))

        const original_date = `${day}-${month}-${toyear}`
        return original_date
      }
      return ''
    }
    // const blankUserData = {
    //   name: '',
    //   day_off: '',
    //   date_start: '',
    //   date_end: '',
    // }
    // const Data = ref(JSON.parse(JSON.stringify(blankUserData)))
    // const resetuserData = () => {
    //   Data.value = JSON.parse(JSON.stringify(blankUserData))
    // }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {})

    return {
      isBusy,
      refFormObserver,
      getValidationState,
      resetForm,
      errorMessage,
      // Modal ids
      getModal1Id,
      getModal2Id,
      getChatId,

      formatDateTime,

      // Actions
      remove,
      edit,

      selectedDayOff,

      // Sidebar
      isAddNewUserSidebarActive,

      fetchEvents,
      tableColumns,
      perPage,
      currentPage,
      totalEvents,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      isChatActive,
      chatModelId,
      handleChatClick,

      roleOptions,
      planOptions,
      dayOffOptions,

      // Extra Filters
      roleFilter,
      dateStartFromFilter,
      dateStartToFilter,
      dateEndFromFilter,
      dateEndToFilter,
      planFilter,
      statusFilter,
      selectedFilter,
      filters,
    }
  },
}
</script>

<style lang="scss" scoped>
// .per-page-selector {
//   width: 90px;
// }

// .commentschats .div {
//   background: #f3f2f7;
// }
// .commentschats {
//   position: fixed;
//   width: 380px;
//   height: 502px;
//   display: block;
//   right: 80px;
//   bottom: 2px;
//   z-index: 100;
// }
// .close-btn {
//   position: absolute;
//   right: 0;
//   top: 0;
//   z-index: 102;
//   cursor: pointer;
// }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
