<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('Add New Invoice') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Student -->
          <validation-provider
            #default="validationContext"
            name="Student"
            rules="required"
          >
            <b-form-group
              :label="$t('Student')"
              label-for="student"
            >
              <b-form-input
                id="student"
                v-model="query1"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('Student')"
                @keyup="autoCompleteStudent('student')"
              />
              <v-select
                v-if="students.length"
                v-model="selectedStudent"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="students"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Course -->
          <validation-provider
            #default="validationContext"
            name="course"
            rules="required"
          >
            <b-form-group
              :label="$t('Course')"
              label-for="course"
            >
              <b-form-input
                v-model="query2"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="course"
                @keyup="autoCompleteCourse()"
              />
              <v-select
                v-if="courses.length"
                v-model="selectedCourse"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="courses"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Number"
            rules="required|min:3"
          >
            <b-form-group
              :label="$t('Number')"
              label-for="week count"
            >
              <b-form-input
                v-model="number"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Number"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Created date -->
          <b-form-group
            label-for="example-datePicker1"
            :label="$t('Created date')"
          >
            <flat-pickr
              v-model="datePicker1"
              class="form-control"
              name="date"
              :placeholder="$t('Created at...')"
            />
          </b-form-group>

          <!-- Sent date -->
          <b-form-group
            label-for="example-datePicker2"
            :label="$t('Sent date')"
          >
            <flat-pickr
              v-model="datePicker2"
              class="form-control"
              name="date"
              :placeholder="$t('Sent at...')"
            />
          </b-form-group>

          <!-- Sent date -->
          <b-form-group
            label-for="example-datePicker3"
            :label="$t('Sent date')"
          >
            <flat-pickr
              v-model="datePicker3"
              class="form-control"
              name="date"
              :placeholder="$t('Paid at...')"
            />
          </b-form-group>

          <!-- Paid -->
          <b-form-group :label="$t('Paid')">
            <v-select
              v-model="selectedPaid"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="paidOptions"
            />
          </b-form-group>

          <!-- Status -->
          <b-form-group :label="$t('Paid method')">
            <v-select
              v-model="selectedPaidMethod"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="paidMethodOptions"
            />
          </b-form-group>

          <!-- Status -->
          <b-form-group :label="$t('Status')">
            <v-select
              v-model="selectedStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
            />
          </b-form-group>

          <validation-provider
            #default="validationContext"
            name="Comments"
            rules="required"
          >
            <b-form-group
              :label="$t('School comments')"
              label-for="School comments"
            >
              <textarea
                id="exampleFormControlTextarea1"
                v-model="comments"
                class="form-control"
                autofocus
                :placeholder="$t('Comments')"
                :state="getValidationState(validationContext)"
                rows="3"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, alphaNum, email, min,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import axios from '@axios'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      min,
      countries,
      query1: '',
      query2: '',
      number: '',
      students: [],
      courses: [],
      paidOptions: ['No', 'Yes'],
      paidMethodOptions: ['Cash', 'Card', 'Wire transfer', 'Vipps'],
      statusOptions: ['Active', 'Deleted'],
    }
  },
  methods: {
    /* eslint-disable camelcase */
    autoCompleteStudent(role_name) {
      axios.post('/autocomplete/user', { search: this.query1, role_name })
      .then(res => {
        this.students = []
        this.students = res.data
      })
    },

    autoCompleteCourse() {
      axios.post('/autocomplete/course', { search: this.query2 })
      .then(res => {
        this.courses = []
        this.courses = res.data
      })
    },
  },
  setup(props, { emit }) {
    const selectedStudent = ref({})
    const selectedCourse = ref({})
    const selectedPaid = ref('No')
    const selectedPaidMethod = ref('Select')
    const selectedStatus = ref('Active')
    const datePicker1 = ref('')
    const datePicker2 = ref('')
    const datePicker3 = ref('')
    const number = ref('')
    const comments = ref('')

    const invoiceData = {}

    const resetRegistrationData = () => {
      selectedStudent.value = {}
      selectedCourse.value = {}
      selectedPaid.value = ''
      selectedPaidMethod.value = ''
      selectedStatus.value = ''
      datePicker1.value = ''
      datePicker2.value = ''
      datePicker3.value = ''
    }
    const onSubmit = () => {
      if (selectedPaid.value === 'No') { invoiceData.paid = 0 }
      if (selectedPaid.value === 'Yes') { invoiceData.paid = 1 }
      if (selectedPaidMethod.value === 'No') { invoiceData.paid_method = 0 }
      if (selectedPaidMethod.value === 'Yes') { invoiceData.paid_method = 1 }
      invoiceData.status = selectedStatus.value
      invoiceData.student_id = selectedStudent.value.id
      invoiceData.course_id = selectedCourse.value.id
      invoiceData.number = number.value
      invoiceData.school_comments = comments.value
      invoiceData.created_date = datePicker1.value
      invoiceData.sent_date = datePicker2.value
      invoiceData.paid_date = datePicker3.value

      store.dispatch('invoice/addInvoice', invoiceData)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRegistrationData)

    return {
      onSubmit,
      number,
      comments,
      selectedStudent,
      selectedCourse,
      selectedPaid,
      selectedPaidMethod,
      selectedStatus,
      invoiceData,
      datePicker1,
      datePicker2,
      datePicker3,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
