import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { getUserData } from '@/auth/utils'

Vue.use(VueI18n)
const userData = getUserData()
// console.log(userData)
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}
export default new VueI18n({
  locale: (userData ? userData.language : 'en'),
  fallbackLocale: (userData ? userData.language : 'en'),
  messages: loadLocaleMessages(),
  silentTranslationWarn: true, // temporary silent vueI18 from console
})
