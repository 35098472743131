<template>
  <div>
    <!-- warning modal-->
    <b-modal
      :id="'modalwarning'"
      title="Yours sessions will be overriden"
      ok-title="Accept"
      @ok="handleOk(selected, courseId, true)"
    >
      <b-card-text>
        <h5>Some sessions belonging to this course have a different classroom than the one specified, <strong> override ?</strong></h5>
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-center"
      :visible="isModalSActive"
      :title="'Classroom suggestion'"
      size="lg"
      hide-footer
      centered
      no-close-on-backdrop
      @change="(val) => $emit('update:isModalSActive', val)"
    >
      <b-row class="mb-1">
        <b-col
          md="10"
        >
          <v-select
            v-model="selected"
            :options="options"
            label="name"
            :selectable="el => el.available === 'available'"
          >
            <template #option="{ code, available, max_students }">
              <div class="row">
                <strong class="col-4 d-block"> {{ code }}</strong>
                <small class="col-5 text-secondary">Students limit: <strong>{{ max_students }}</strong></small>
                <small class="col-3 d-flex justify-content-end">
                  <b-badge
                    pill
                    :variant="`light-${available === 'available' ? 'success' : 'danger'}`"
                    class="text-capitalize"
                  >
                    {{ available === 'available' ? 'Available' : 'Unavailable' }}
                  </b-badge>
                </small>
                <!-- <small class="ml-50" :class="available ? 'text-success' : 'text-danger'">{{ available ? 'Available' : 'Unavailable' }}</small> -->
              </div>
            </template>
            <span slot="no-options">
              <div
                v-if="spinner"
                class="text-center d-flex align-items-center justify-content-center"
              >
                <b-spinner
                  variant="primary"
                  label="Text Centered"
                />
              </div>
              <div
                v-if="!spinner && options.length === 0"
                class="text-center d-flex align-items-center justify-content-center"
              >
                No matching records found
              </div>
            </span>
          </v-select>
        </b-col>
        <b-col
          md="2"
        >
          <b-button
            @click="handleOk(selected, courseId, false)"
          >
            OK
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
  import {
    BModal,
    BRow,
    BCol,
    BButton,
    BCardText,
    BBadge,
    BSpinner,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
  import coursesStoreModule from '../coursesStoreModule'

  export default {
    name: 'SuggestClassroom',
    components: {
      BModal,
      BRow,
      BCol,
      BButton,
      BCardText,
      BBadge,
      BSpinner,
      vSelect,
    },
    model: {
      prop: 'isModalSActive',
      event: 'update:isModalSActive',
    },
    props: {
      courseId: {
        type: Number,
        default: 99,
      },
      isModalSActive: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isModalActive: true,
        selected: null,
        options: [],
        spinner: true,
      }
    },
    mounted() {
      const USER_APP_STORE_MODULE_NAME = 'app-courses'
      // Register module
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, coursesStoreModule)
      this.fetchClassrooms()
    },
    methods: {
      /* eslint-disable object-shorthand */
      fetchClassrooms() {
        this.spinner = true
        store.dispatch('app-courses/getClassroomsByCourse', { course_id: this.courseId })
          .then(res => {
            this.options = res.data.allClassrooms
            this.spinner = false
          })
      },
      /* eslint-disable object-shorthand */
      handleOk(selected, courseId, override) {
        store.dispatch('app-courses/setCourseClassroom', { // setCourseClassroom
          classroom_id: selected.id,
          course_id: courseId,
          override: override,
        })
          .then(() => {
            const resp = store.state.coursesStoreModule.suggestClassroomsResponse.response
            if (resp === 'warning') {
              this.$bvModal.show('modalwarning')
            }
            if (resp === 'success') {
              this.$emit('refetch-data')
              this.$emit('update:isModalSActive', false)
              this.selected = null
            }
          })
      },
    },
  }
</script>

<style scoped>

</style>
