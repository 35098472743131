<template>
  <div>
    <b-table
      responsive="sm"
      :items="items"
      :fields="tableColumns"
      show-empty
      empty-text="No attendance recorded yet."
      :busy.sync="isBusy"
    >
      <template #table-busy>
        <div
          v-if="isBusy"
          class="text-center d-flex align-items-center justify-content-center"
        >
          <b-spinner
            variant="primary"
            label="Text Centered"
          />
          <strong class="ml-1">Loading...</strong>
        </div>
      </template>
      <template #cell(student_name)="data">
        <feather-icon
          v-if="movedFrom"
          v-b-popover.hover.top="`Moved from ${movedFrom.course} (${movedFrom.group})`"
          icon="CornerRightDownIcon"
          class="text-warning mr-50 b-avatar b-avatar-sm badge-light-warning rounded-circle p-25"
        />
        <feather-icon
          v-if="movedTo"
          v-b-popover.hover.top="`Moved to ${movedTo.course} (${movedTo.group})`"
          icon="CornerRightUpIcon"
          class="text-info mr-50 b-avatar b-avatar-sm badge-light-info rounded-circle p-25"
        />
        <feather-icon
          v-if="registrationStatus === 'canceled'"
          v-b-popover.hover.top="'Canceled registration'"
          icon="SlashIcon"
          class="text-danger mr-50 b-avatar b-avatar-sm badge-light-danger rounded-circle p-25"
        />
        {{ data.item.student_name }}
      </template>
      <template #cell(present)="data">
        <!-- Levels checkbox -->
        <b-form-group>
          <div class="d-flex align-items-center justify-content-start">
            <b-form-checkbox
              v-model="data.item.present"
              value="0"
              class="custom-control-primary d-inline mr-1"
              :disabled="!!movedTo"
            >
              {{ 0 }}h
            </b-form-checkbox>
            <b-form-checkbox
              v-for="index in data.item.max_hours"
              :key="index"
              v-model="data.item.present"
              :value="index"
              :disabled="!!movedTo"
              class="custom-control-primary mr-1"
            >
              {{ index }}h
            </b-form-checkbox>
          </div>
        </b-form-group>
      </template>
    </b-table>
    <!-- Modal actiions -->
    <div class="d-flex justify-content-end">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="secondary"
        @click="$emit('hide-modal')"
      >
        <!-- <feather-icon
          icon="XCircleIcon"
          class="mr-50"
        /> -->
        {{ $t('Close') }}
      </b-button>
      <b-button
        v-if="!movedTo"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="ml-1"
        variant="primary"
        @click="update"
      >
        <!-- <feather-icon
          icon="RefreshCwIcon"
          class="mr-50"
        /> -->
        {{ $t('update') }}
      </b-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
import { BTable, BFormGroup, BFormCheckbox, BButton, BSpinner, VBPopover } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTable,
    BButton,
    BSpinner,
    BFormGroup,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  props: {
    courseId: {
      default: null,
      type: Number,
    },
    studentId: {
      default: null,
      type: Number,
    },
    movedFrom: {
      default: null,
      type: Object,
    },
    movedTo: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      tableColumns: [
        // { key: 'student_name' },
        // { key: 'course_id' },
        { key: 'lesson_number', label: 'lesson' },
        { key: 'course_code' },
        { key: 'course_date', label: 'session date' },
        { key: 'present' },
      ],
      items: [],
      isBusy: false,
    }
  },
  created() {
    this.isBusy = true
    this.$http.get('/attendance/hours/student', {
      params: {
        student_id: this.studentId,
        course_id: this.courseId,
      },
    })
    .then(res => {
      this.isBusy = false
      this.items = res.data
    })
  },
  methods: {
    update() {
      this.$http.post('/attendance/student/update', { attendances: this.items }).then(res => {
        this.$emit('hide-modal')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: res.data.message,
            icon: 'CoffeeIcon',
            variant: 'success',
          },
        })
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: err.message,
            icon: 'CoffeeIcon',
            variant: 'success',
          },
        })
      })
    },
  },
}
</script>
