export default [
  {
    path: '/company',
    name: 'company',
    component: () => import('@/views/company/Company.vue'),
    meta: {
      pageTitle: 'Company',
      breadcrumb: [
        {
          text: 'Company',
          active: true,
        },
      ],
    },
  },
  {
    path: '/invitation/:code/:email',
    name: 'invitation',
    component: () => import('@/views/company/Invitation.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/company/send/invitation',
    name: 'send-invitation',
    component: () => import('@/views/company/sendInvitation.vue'),
  },
]
