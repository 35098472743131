<template>

  <div>

    <teacher-list-add-new
      v-if="isAddNewUserSidebarActive"
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :levels="levels"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <!-- <teachers-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            lg="3"
          >
              <v-select
                v-model="selectedFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="filters"
                lebel="label"
                :clearable="false"
                class="courses-filter d-inline-block mx-50 mb-1"
              />
          </b-col>
          <b-col
            cols="12"
            lg="2"
          >
              <v-select
                v-model="selectedAvFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="avFilters"
                lebel="label"
                :clearable="false"
                class="courses-filter d-inline-block mx-50 mb-1"
              />
          </b-col>
          <b-col
            cols="12"
            lg="2"
          >
              <v-select
                v-model="selectedClassroomFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="classroomFilters"
                lebel="label"
                :clearable="false"
                class="courses-filter d-inline-block mx-50 mb-1"
              />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            lg="5"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mx-50"
                :placeholder="$t('Search...')"
                debounce="500"
              />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">{{ $t('Add Teacher') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>
        <!-- Column: User -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.id }}
            </b-link>
          </b-media>
        </template>
        <template #cell(levels)="data">
          <span v-b-tooltip.hover.top="data.item.levels">
            {{ data.item.levels }}
          </span>
        </template>
        <!-- Column: code & Name -->
        <template #cell(code)="data">
          <div class="media-body">
            <b-link
              v-b-tooltip.hover.top="data.item.name"
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ `${data.item.name.split(' ')[0]} ${data.item.name.split(' ')[1] ? data.item.name.split(' ')[1].charAt(0).toUpperCase() : ''}.` }}
            </b-link>
            <small class="text-muted">{{ data.item.code }}</small>
          </div>
        </template>

        <!-- Column: email & phone -->
        <template #cell(email)="data">
          <div class="media-body d-flex justify-content-start align-items-center">
            <div
              v-b-tooltip.hover.top="data.item.email"
              class="font-weight-bold d-block text-nowrap text-truncate"
              style="max-width: 150px"
            >
              {{ data.item.email }}
            </div>
            <b-button
              v-b-tooltip.hover.right="'Copy Teacher\'s email'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-primary"
              class="btn-icon rounded-circle mr-50"
              @click="doCopy(data.item.email, 'Teacher\'s email copied successfully')"
            >
              <feather-icon icon="CopyIcon" />
            </b-button>
          </div>
          <small class="text-muted">{{ data.item.phone }}</small>
        </template>

        <!-- Column: max_student -->
        <template #cell(max_student)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.max_students }}
          </b-badge>
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: available -->
        <template #cell(available)="data">
          <b-badge
            pill
            :variant="`light-${resolveTeacherAvailability(data.item.available).class}`"
            class="text-capitalize"
          >
            {{ resolveTeacherAvailability(data.item.available).text }}
          </b-badge>
        </template>

        <!-- Column: online -->
        <template #cell(online)="data">
          <b-badge
            pill
            :variant="`light-${resolveTypeVariant(data.item.type)}`"
            class="text-capitalize"
          >
            {{ data.item.type }}
          </b-badge>
          <!-- <span
            v-if="data.item.online !== 'n'"
            class="b-avatar mr-1 badge-light-success rounded-circle"
          >
            <span class="b-avatar-custom">
              <feather-icon
                icon="CheckCircleIcon"
                size="16"
                class="align-middle text-body"
              />
            </span>
          </span> -->
        </template>

        <!-- Column: days & time -->
        <template #cell(days)="data">
          <b-media vertical-align="center">
            <div
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.days }}
            </div>
          </b-media>
          <small class="text-muted text-nowrap">
            <div
              v-for="(tf, index) in renderTimeframes(data.item.days)"
              :key="index"
              :class="`timeframe ${tf.free}`"
            />
          </small>
        </template>
        <template #cell(comment)="data">
          <b-button
            :id="`popover-target-${data.item.id}`"
            variant="flat-primary"
            size="sm"
            class="btn-icon rounded-circle"
            @click="handleChatClick({model:'teacher', id: data.item.id})"
          >
            <feather-icon
              icon="ClipboardIcon"
              class="align-middle text-body"
              badge-classes="bg-danger"
              :badge="data.item.comments_number > 0 ? data.item.comments_number : ''"
              size="16"
            />
          </b-button>
        </template>

        <!-- Column: ---------- Actions --------->
        <template #cell(actions)="data">
          <b-button
            v-if="data.item.deleted"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mr-2"
            @click="restoreTeacher(data.item.id)"
          >
            Restore
          </b-button>
          <b-dropdown
            v-if="!data.item.deleted"
            dropleft
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="handleChatClick1(data.item.id)">
              <feather-icon icon="MessageSquareIcon" />
              <span class="align-middle ml-50">Contact</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="userRole === 'administrator'"
              v-b-modal="getModal3Id(data.item.id)"
              @click="fetchEvents(data.item.id)"
            >
              <feather-icon icon="CalendarIcon" />
              <span class="align-middle ml-50">Days off</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="userRole === 'administrator'"
              v-b-modal="getModal2Id(data.item.id)"
              @click="getData(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('Edit') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="userRole === 'administrator' && !data.item.deleted"
              v-b-modal="getModal1Id(data.item.id)"
              @click="fetchEvents(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('Delete') }}</span>
            </b-dropdown-item>
            <!-- basic modal -->
            <b-modal
              :id="'modal3'+data.item.id"
              :key="refreshKey"
              :title="'Days off of '+data.item.name"
              hide-footer
              ok-only
              centered
              no-close-on-backdrop
              size="xl"
              @ok="viewEvents(data.item.id)"
            >
              <div
                v-if="isBusyModal"
                class="text-center d-flex align-items-center justify-content-center"
              >
                <b-spinner
                  variant="primary"
                  label="Text Centered"
                  style="width: 3rem; height: 3rem;"
                />
                <strong class="ml-1">{{ $t('Loading...') }}</strong>
              </div>
              <teacher-events
                v-if="data.item.id && !isBusyModal"
                :teacher-id="data.item.id"
              />
              <!-- :itemss="items"
                @add-event="addEvent"
                @update-event="updateEvent"
                @delete-event="deleteEvent"
                @approve-event="approveEvent" -->
            </b-modal>

            <b-modal
              :id="'modal1'+data.item.id"
              title="Delete teacher"
              ok-title="Yes"
              cancel-title="No"
              centered
              @ok="remove(data.item.id)"
            >
              <b-card-text>
                <h5>{{ $t('Are you sure you want to delete') }} </h5><strong>{{ data.item.name }}</strong> ?
              </b-card-text>
            </b-modal>

            <b-modal
              :id="'modal2'+data.item.id"
              :title="$t('Update teacher data')"
              ok-title="Accept"
              hide-footer
              centered
              no-close-on-backdrop
              size="xl"
            >
              <validation-observer
                #default="{ invalid }"
                ref="editTeacherForm"
              >
                <b-form
                  class="p-2"
                  @submit.prevent="edit(payload, data.item.status)"
                >
                  <b-row>
                    <b-col
                      sm="6"
                      cols="12"
                    >
                      <!-- Name -->
                      <validation-provider
                        #default="validationContext"
                        name="Teacher name"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Name')"
                          label-for="name"
                        >
                          <b-form-input
                            v-model="payload.name"
                            autofocus
                            trim
                            :state="getValidationState(validationContext)"
                            :placeholder="$t('Name')"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      sm="6"
                      cols="12"
                    >
                      <!-- Code -->
                      <validation-provider
                        #default="validationContext"
                        name="Code"
                        rules="required|max:6"
                      >
                        <b-form-group
                          :label="$t('Code')"
                          label-for="code"
                        >
                          <b-form-input
                            v-model="payload.code"
                            autofocus
                            :state="getValidationState(validationContext)"
                            :placeholder="$t('Code')"
                            style="text-transform:uppercase"
                            :class="errors.code[0] ? 'is-invalid' : ''"
                            @keyup="errors.code = []"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                          <small
                            v-if="errors"
                            class="text-danger"
                          >{{ errors.code[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      sm="6"
                      cols="12"
                    >
                      <!-- Email -->
                      <validation-provider
                        #default="validationContext"
                        name="Email"
                        :rules="emailDomainName ? 'required' : 'required|email'"
                      >
                        <b-form-group
                          :label="$t('Email')"
                          label-for="email"
                        >
                          <div :class="emailDomainName ? 'input-group' : ''">
                            <b-form-input
                              v-model="payload.emailValue"
                              autofocus
                              :state="getValidationState(validationContext)"
                              trim
                              :placeholder="('Email')"
                              :class="errors.email[0] ? 'is-invalid' : ''"
                              @keyup="errors.email = []"
                            />
                            <!-- <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2"> -->
                            <span v-if="emailDomainName" class="input-group-text">{{ emailDomainName }}</span>
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                            <small
                              v-if="errors"
                              class="text-danger"
                            >{{ errors.email[0] }}</small>
                          </div>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      sm="6"
                      cols="12"
                    >
                      <!-- Email -->
                      <validation-provider
                        #default="validationContext"
                        name="Email"
                        rules="email"
                      >
                        <b-form-group
                          label="Secondary email"
                          label-for="secondary-email"
                        >
                          <b-form-input
                            v-model="payload.personal_email"
                            type="email"
                            autofocus
                            trim
                            :state="getValidationState(validationContext)"
                            placeholder="Secondary email"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      sm="6"
                      cols="12"
                    >
                      <!-- Phone -->
                      <validation-provider
                        #default="validationContext"
                        name="Phone"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Phone')"
                          label-for="phone"
                        >
                          <b-form-input
                            v-model="payload.phone"
                            type="number"
                            autofocus
                            trim
                            :state="getValidationState(validationContext)"
                            :placeholder="$t('Phone')"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      sm="6"
                      cols="12"
                    >
                      <!-- Levels checkbox -->
                      <b-form-group
                        :label="$t('Levels')"
                        label-for="levels"
                      >
                        <div class="d-flex align-items-center">
                          <b-form-checkbox
                            :checked="selectedLevel.length === 4"
                            class="custom-control-primary mr-1"
                            @change="selectedLevel.length !== 4 ? selectedLevel = data.item.Alllevels: selectedLevel = []"
                          >
                            All levels
                          </b-form-checkbox>
                          <b-form-checkbox
                            v-for="level in data.item.Alllevels"
                            :key="level.id"
                            v-model="selectedLevel"
                            :value="level"
                            class="custom-control-primary mr-1"
                          >
                            {{ level.code }}
                          </b-form-checkbox>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col
                      sm="6"
                      cols="12"
                    >
                      <!-- Available -->
                      <validation-provider
                        #default="validationContext"
                        name="Available"
                        rules="required"
                      >
                        <b-form-group
                          :state="getValidationState(validationContext)"
                          :label="$t('Available')"
                          label-for="available"
                        >
                          <v-select
                            v-model="payload.available"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="teacherAvailability"
                            :clearable="false"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      sm="6"
                      cols="12"
                    >
                      <!-- ONline? -->
                      <validation-provider
                        #default="validationContext"
                        name="Type"
                        rules="required"
                      >
                        <b-form-group
                          :state="getValidationState(validationContext)"
                          :label="$t('Type')"
                          label-for="type"
                        >
                          <v-select
                            v-model="payload.type"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="onlineOptions"
                            :clearable="false"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      sm="6"
                      cols="12"
                    >
                      <!-- Days -->
                      <b-form-group
                        :label="$t('Days')"
                        label-for="days"
                      >
                        <div class="d-flex align-items-center">
                          <b-form-checkbox
                            :checked="selectedDay.length === 7"
                            class="custom-control-primary mr-1"
                            @change="selectedDay.length !== 7 ? selectedDay = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'] : selectedDay = []"
                          >
                            All days
                          </b-form-checkbox>
                          <b-form-checkbox
                            v-for="day in dayOptions"
                            :key="day.value"
                            v-model="selectedDay"
                            :value="day.value"
                            class="custom-control-primary mr-1"
                          >
                            {{ day.label }}
                          </b-form-checkbox>
                        </div>
                      </b-form-group>
                      <b-form-group
                        v-if="selectedDay.includes('sa')"
                        label="Saturday time"
                        label-for="saturday-time"
                        class="mt-2"
                      >
                        <b-row>
                          <b-col>
                            <validation-provider
                              #default="validationContext"
                              name="Time from"
                            >
                              <flat-pickr
                                v-model="saturdayTime[0].start"
                                placeholder="From"
                                class="form-control"
                                :config="{ enableTime: true, noCalendar: true, time_24hr: true, minTime: '07:00' }"
                              />
                              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                          <b-col>
                            <validation-provider
                              #default="validationContext"
                              name="Time to"
                            >
                              <flat-pickr
                                v-model="saturdayTime[0].end"
                                placeholder="To"
                                class="form-control"
                                :config="{ enableTime: true, noCalendar: true, time_24hr: true, minTime: saturdayTime[0].start }"
                              />
                              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col>
                    <b-col
                      sm="6"
                      cols="12"
                    >
                      <b-form-group
                        label="Time"
                        label-for="time"
                      >
                        <teacher-time-frame
                          :items-prop="selectedTime.filter(el => el.days === 'all')"
                          @bind-time-frames="val => selectedTime = val"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- <b-col
                      sm="4"
                      cols="12"
                    > -->
                      <!-- Status -->
                      <!-- <validation-provider
                        #default="validationContext"
                        name="Status"
                        rules="required"
                      >
                        <b-form-group
                          :state="getValidationState(validationContext)"
                          :label="$t('Status')"
                          label-for="status"
                        >
                          <v-select
                            v-model="data.item.status"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="statusPicker"
                            :clearable="false"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col> -->
                  </b-row>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                    :disabled="invalid"
                  >
                    Update
                  </b-button>
                </b-form>
              </validation-observer>
            </b-modal>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="3"
            lg="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <b-col
            cols="3"
            lg="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="6"
            lg="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="!isBusy"
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <div
      v-if="isChatActive"
      class="notesModule shadow-lg"
    >
      <div style="background: #fff" class="pb-1">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="light"
          size="sm"
          class="btn-icon shadow-sm ml-auto"
          @click="isChatActive = false"
          style="display: block; transform: translate(8px, -2px);"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
      <chat-module
        :key="chatModelId.id"
        @notes-increament="refetchData"
        @decrease-notes="refetchData"
        :model-id="chatModelId.id"
        model-type="teacher"
      />
    </div>
    <div
      v-if="isChatActive1"
      class="commentschats"
    >
      <feather-icon
        icon="XSquareIcon"
        size="22"
        class="align-middle text-body close-btn"
        @click="isChatActive1 = false"
      />
      <chat-module
        :conversation="conversation"
        :messages="messages"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase, object-curly-newline */
import {
  BCard, BCardText, BRow, BCol, BFormInput, BFormGroup, BButton, BTable, BMedia, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, BFormCheckbox, BSpinner, VBTooltip, BFormInvalidFeedback, BForm,
  // BPopover,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
// import TeachersListFilters from './TeachersListFilters.vue'
// import ChatModule from '@/views/admin/ChatModule.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import ChatModule from '@/views/admin/ChatModule.vue'
import store from '@/store'
import useTeachersList from './useTeachersList'
import teacherStoreModule from './teacherStoreModule'
import teacherListAddNew from './TeacherListAddNew.vue'
import teacherEvents from './TeacherEvents.vue'
import TeacherTimeFrame from './TeacherTimeFrame.vue'

export default {
  components: {
    // ChatModule,
    // TeachersListFilters,
    TeacherTimeFrame,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BForm,
    teacherListAddNew,
    teacherEvents,
    ChatModule,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormCheckbox,
    BSpinner,
    flatPickr,
    // BPopover,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      required,
      max,
      email,
      isChatActive1: false,
      conversation: {},
      messages: {},
      // emailDomainName: null,
    }
  },
  methods: {
    doCopy(data, message) {
      this.$copyText(data).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: (typeof message !== 'undefined') ? message : 'Copied successfully',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    handleChatClick1(id) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.$http.post('/conversation', { participants: [id], user_id: userData.id })
      .then(res => {
        this.isChatActive1 = true
        this.conversation = {
          id: res.data.id,
          avatar: res.data.avatar,
          name: res.data.name,
        }
        this.messages = {
          data: res.data.messages,
        }
      })
    },
  },
  setup() {
    const getModal1Id = id => `modal1${id}`
    const getModal2Id = id => `modal2${id}`
    const getModal3Id = id => `modal3${id}`
    const getChatId = id => `popover-target-${id}`
    const refreshKey = ref(0)
    const isBusyModal = ref(true)
    const TEACHER_STORE_MODULE_NAME = 'teacher'
    // Register module
    if (!store.hasModule(TEACHER_STORE_MODULE_NAME)) store.registerModule(TEACHER_STORE_MODULE_NAME, teacherStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TEACHER_STORE_MODULE_NAME)) store.unregisterModule(TEACHER_STORE_MODULE_NAME)
    })
    const isAddNewUserSidebarActive = ref(false)
    const roleOptions = [
      { label: 'Admin', value: 'administrator' },
      { label: 'Student', value: 'student' },
      { label: 'Teacher', value: 'teacher' },
      { label: 'Agent', value: 'agent' },
      { label: 'Company', value: 'company' },
    ]
    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]
    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]
    const comments = ref('')
    const teacherTypeOptions = ['online', 'physic']
    const selectedType = ref('online')
    const external_data = ref('')
    const statusPicker = ['Active', 'Deleted']
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userRole = userData.role
    const onlineOptions = ref([{ label: 'Online', value: 'yes' }, { label: 'Classroom', value: 'no' }, { label: 'Hybrid', value: 'hybrid' }])
    const selectedLevel = ref([])
    const selectedDay = ref([])
    const saturdayTime = ref([{
      start: '',
      end: '',
      days: 'sa',
    }])
    const payload = ref({
      name: null,
      phone: null,
      code: null,
      email: null,
      personal_email: [],
      // selectedLevel: null,
      // time: null,
      // selectedDay: [],
      available: null,
      online: null,
      status: null,
      prefix_email: null,
    })
    const selectedTime = ref([])
    const teacherAvailability = ref([{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }])
    const items = ref([])
    const emailDomainName = ref(process.env.VUE_APP_EMAIL_DOMAIN)
    /* eslint-disable no-param-reassign, consistent-return */
    const getData = item => {
      payload.value = { ...item }
      payload.value.emailValue = emailDomainName.value ? payload.value.prefix_email : payload.value.email
      selectedDay.value = item.days ? item.days.split(',') : []
      selectedTime.value = item.time_frames.filter(el => el.days === 'all')
      if (item.time_frames.length > 0 && item.time_frames.find(el => el.days === 'sa')) {
        saturdayTime.value = [{
          start: item.time_frames.find(el => el.days === 'sa').start,
          end: item.time_frames.find(el => el.days === 'sa').end,
          days: 'sa',
        }]
      }
      console.log(saturdayTime.value)
      if (payload.value.type) {
        if (payload.value.type === 'Classroom') payload.value.type = { label: 'Classroom', value: 'no' }
        if (payload.value.type === 'Online') payload.value.type = { label: 'Online', value: 'yes' }
        if (payload.value.type === 'Hybrid') payload.value.type = { label: 'Hybrid', value: 'hybrid' }
      }
      selectedLevel.value = payload.value.Alllevels ? payload.value.Alllevels.filter(level => level.selected) : []
      payload.value.available = payload.value.available || payload.value.available === 'yes' ? { label: 'Yes', value: 'yes' } : { label: 'No', value: 'no' }
    }
    // Use toast
    const toast = useToast()
    const {
      filters,
      avFilters,
      classroomFilters,

      isBusy,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      selectedFilter,
      selectedAvFilter,
      selectedClassroomFilter,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      renderTimeframes,
      resolveTeacherAvailability,
      resolveTypeVariant,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useTeachersList()
    const fetchEvents = id => {
      store.dispatch('teacher/fetchEvents', id)
      .then(res => {
        isBusyModal.value = false
        items.value = res.data.rows
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while fetching teachers events',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const levels = ref([])
    const getLevels = () => {
      store.dispatch('teacher/getLevels').then(res => {
        levels.value = res.data
      })
    }
    getLevels()
    const viewEvents = id => {
      store.dispatch('teacher/deleteTeacher', id)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Teacher Has Been removed',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while removing Teacher',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const remove = id => {
      store.dispatch('teacher/deleteTeacher', id)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Teacher Has Been removed',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while removing Teacher',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const errors = ref({
      email: [],
      code: [],
    })
    const dayOptions = ref([
      { label: 'Mo', value: 'mo' },
      { label: 'Tu', value: 'tu' },
      { label: 'We', value: 'we' },
      { label: 'Th', value: 'th' },
      { label: 'Fr', value: 'fr' },
      { label: 'Sa', value: 'sa' },
      { label: 'Su', value: 'su' },
    ])
    /* eslint-disable no-shadow */
    /* eslint-disable */
    const edit = function (record, status) {
      this.$refs.editTeacherForm.validate().then(success => {
        if (success) {
          const payload = {
            user_id: record.id,
            code: record.code,
            name: record.name,
            email: this.emailDomainName ? `${record.emailValue}${this.emailDomainName}` : record.emailValue,
            personalEmail: record.personal_email,
            phone: record.phone,
            status,
            levels: selectedLevel.value,
            days: selectedDay.value.join(','),
            time_frames: selectedDay.value.includes('sa') ? selectedTime.value.concat(saturdayTime.value) : selectedTime.value,
            available: record.available ? record.available.value : null,
            online: record.type ? record.type.value : null,
          }
          store.dispatch('teacher/editTeacher', payload)
            .then(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Teacher Has Been edited',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              refetchData()
          }).catch(error => {
            toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            errors.value = error.response.data.errors
          })
        }
      })
    }
    const restoreTeacher = teacher_id => {
      store.dispatch('teacher/restoreTeacher', teacher_id)
        .then(res => {
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        errors.value = error.response.data.errors
      })
    }
    const isChatActive = ref(false)
    const chatModelId = ref(null)
    const handleChatClick = data => {
      isChatActive.value = true
      chatModelId.value = data
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(() => {})
    return {
      emailDomainName,
      dayOptions,
      saturdayTime,
      isBusy,
      payload,
      restoreTeacher,
      refFormObserver,
      getValidationState,
      errors,
      // Modal ids
      getModal1Id,
      getModal2Id,
      getModal3Id,
      getChatId,
      refreshKey,
      items,
      isBusyModal,
      getData,
      handleChatClick,
      isChatActive,
      chatModelId,
      // Event actions
      viewEvents,
      fetchEvents,
      getLevels,
      levels,
      selectedFilter,
      selectedAvFilter,
      selectedClassroomFilter,
      // Actions
      remove,
      edit,
      comments,
      userRole,
      teacherTypeOptions,
      selectedType,
      external_data,
      statusPicker,
      selectedLevel,
      selectedDay,
      selectedTime,
      teacherAvailability,
      onlineOptions,
      filters,
      avFilters,
      classroomFilters,
      // Sidebar
      isAddNewUserSidebarActive,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      renderTimeframes,
      resolveTeacherAvailability,
      resolveTypeVariant,
      roleOptions,
      planOptions,
      statusOptions,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.timeframe {
  background-color: #82868b;
  height: 10px;
  width: 10px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 3px;
}
.timeframe.free {
  background-color: #ccd1d6;
}
.timeframe.work {
  background-color: #28c76f;
}
.per-page-selector {
  width: 90px;
}
.input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  /* background-color: #f7f7f7; */
}
.input:focus {
  outline: none;
}
  .courses-filter {
    min-width: 100%;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
