import axios from '@axios'
/* eslint-disable */
/* eslint-disable camelcase */
export default {
  namespaced: true,
  state: {
    courses: {
      rows: [],
      totalRecords: 0,
    },
  },
  getters: {},
  mutations: {
    setCourses(state, response) {
      state.courses.rows = response.rows
      state.courses.totalRecords = response.totalRecords
    },
    setAllCourses(state, response) {
      state.courses.rows = response.data
      state.courses.totalRecords = response.total
    },
  },
  actions: {
    fetchCourses(store, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/course-list/data', { params: queryParams })
          .then(response => {
            store.commit('setCourses', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchAllCourses(store, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`teacher/courses/${queryParams.time_of_day}`, { params: queryParams })
          .then(response => {
            store.commit('setAllCourses', response.data.courses)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    requestCourse(ctx, course_id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/teacher/courses/join', { course_id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    approveCourse(ctx, course_id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/teacher/courses/approve', { course_id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelCourse(ctx, course_id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/teacher/courses/cancel', { course_id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelRequest(ctx, { course_id, reason }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/teacher/courses/cancel', { course_id, reason })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
