<template>
  <div>
    <div class="mb-2">
      <b-modal
        v-if="sessionData"
        id="session-data-modal"
        v-model="isSessionModalActive"
        ok-only
        ok-title="Close"
        hide-footer
        centered
        no-close-on-backdrop
        size="lg"
      >
        <template #modal-title>
          <div v-if="sessionData.session">
            Lesson <b>{{ sessionData.session.lesson_number }}</b> - <b>{{ sessionData.session.session_date }}</b>
          </div>
        </template>
        <session-data
          :session-data="sessionData"
        />
      </b-modal>
      <b-row>
        <b-col
          cols="12"
          sm="4"
        >
          <b-form-group
            label="Teachers"
            label-for="teachers"
          >
            <v-select
              v-model="selectedTeacher"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="teachersOptions"
              :clearable="false"
              @input="getTeacherId(selectedTeacher.id)"
            >
              <!-- @click.native="autoComplete($event.target.value, 'teacher')" -->
              <!-- @keyup.native="autoComplete($event.target.value, 'teacher')" -->
              <span slot="no-options">
                <div
                  v-if="isLoadingTeachers"
                  class="text-center d-flex align-items-center justify-content-center"
                >
                  <b-spinner
                    variant="primary"
                    label="Text Centered"
                  />
                </div>
                <div
                  v-if="!isLoadingTeachers"
                  class="text-center d-flex align-items-center justify-content-center"
                >
                  No matching records found
                </div>
              </span>
            </v-select>
          </b-form-group>
        </b-col>
        <!-- <b-col
          class="d-flex justify-content-start mt-2"
          cols="6"
        >
          <b-form-group
            v-if="teacher_id"
            class="mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-1
              variant="primary"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              Add substitute
            </b-button>
          </b-form-group>
          <b-form-group v-if="teacher_id">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-2
              variant="primary"
              @click="getEventsManagment"
            >
              <feather-icon
                icon="ToolIcon"
                class="mr-50"
              />
              Events managment
            </b-button>
          </b-form-group>
        </b-col> -->
        <b-col
          cols="12"
          sm="4"
        >
          <b-form-group
            label="filter"
            label-for="filter"
          >
            <v-select
              v-model="selectedFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :clearable="false"
              :options="filters"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="4"
        >
          <b-form-group
            label="Level"
            label-for="level"
          >
            <v-select
              v-model="selectedLevel"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              label="code"
              :options="levelsOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div class="card app-calendar overflow-hidden border teacher-calendar">
      <div
        :class="isBusyCalendar ? 'spinnerr-expand' : 'spinnerr'"
        class="text-center bg-transparent"
      >
        <b-spinner
          variant="primary"
          label="Text Centered"
        />
        <strong class="ml-1 pt-1">{{ $t('Loading...') }}</strong>
      </div>
      <div class="row no-gutters">
        <!-- Calendar -->
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline, camelcase */
import { BRow, BCol, BFormGroup, VBModal, BSpinner } from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import { onUnmounted, ref, watch } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import SessionData from './calendar-event-handler/SessionData.vue'
// import CourseListGroup from '@/views/courses/admin/courses-list/CoursesListGroup.vue'
import calendarStoreModule from './calendarStoreModule'
// import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
// import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
// import CourseTable from './calendar-event-handler/CourseTable.vue'
import useCalendar from './useCalendar'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    // BButton,
    FullCalendar, // make the <FullCalendar> tag available
    // CalendarSidebar,
    // CalendarEventHandler,
    SessionData,
    // CourseListGroup,
    // CourseTable,
    BSpinner,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      query: '',
    }
  },
  mounted() {
    // let today = new Date()
    // const dd = String(today.getDate()).padStart(2, '0')
    // const mm = String(today.getMonth() + 1).padStart(2, '0')
    // const yyyy = today.getFullYear()

    // today = `${yyyy}-${mm}-${dd}`
    // setTimeout(() => {
    //   const dateAttr = `[data-date="${today}"]`
    //   console.log(dateAttr)
    //   const element = document.querySelector(dateAttr)
    //   element.scrollIntoView()
    // }, 10000)
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      isLoadingTeachers,
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,
      teachersOptions,
      selectedTeacher,
      levelsOptions,
      selectedClassroom,
      teacher_id,
      items,
      isBusyCalendar,

      selectedFilter,
      filters,
      selectedLevel,
      getLevels,
      fetchTeachers,

      refreshKey,

      eventAdd,
      eventUpdate,
      eventDelete,

      isSessionModalActive,
      sessionData,
      courseGroup,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventModalActive,
      isModalActive,
    } = useCalendar()

    getLevels()
    fetchTeachers()
    fetchEvents()
    const isTyping = ref(false)
    // const autoComplete = (search, role_name) => {
    //   isTyping.value = true
    //   store.dispatch('calendar/teacherAutoComplete', { search, role_name })
    //   .then(res => {
    //     isTyping.value = false
    //     teachersOptions.value = [{ name: 'All teachers' }]
    //     res.data.forEach(teacher => teachersOptions.value.push(teacher))
    //   })
    // }
    const getTeacherId = teacherId => {
      isBusyCalendar.value = true
      isEventHandlerSidebarActive.value = false
      teacher_id.value = teacherId
    }
    const isBusy = ref(true)
    const getEventsManagment = () => {
      store.dispatch('calendar/getEventsManagment', teacher_id.value)
      .then(res => {
        isBusy.value = false
        items.value = res.data.rows
      })
    }
    watch([isBusyCalendar], () => {
      if (selectedTeacher.value.name === 'All teachers') {
        // document.write(today)
        // after waiting downloaded data from api, definitely we have to add some time to render data (1s)
        // dont forget to remove event Listener onUnmount ;)
        setTimeout(() => {
          const statfulls = document.querySelectorAll('.teacher')
          statfulls.forEach(statfullDiv => {
            statfullDiv.addEventListener('click', () => {
              // console.log(statfullDiv.getAttribute('data-classroom'))
              // @todo : should implement course selection via normal vuejs state thing, this.dataset.classroom is the classroom id
              // console.log(this.dataset.classroom)
              if (statfullDiv.getAttribute('data-teacher')) {
                teacher_id.value = statfullDiv.getAttribute('data-teacher')
                selectedTeacher.value = { id: statfullDiv.getAttribute('data-teacher'), name: statfullDiv.getAttribute('data-teacherName') }
              }
              // selectedTeacher.value = { id: statfullDiv.getAttribute('data-teacher'), name: statfullDiv.textContent }
              // console.log('hey')
            })
          })
        }, 1000)
      }
    })

    return {
      isLoadingTeachers,
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,
      getLevels,
      levelsOptions,
      selectedTeacher,
      selectedClassroom,
      teacher_id,
      items,
      isBusyCalendar,
      isTyping,

      isBusy,

      refreshKey,

      eventAdd,
      eventUpdate,
      eventDelete,

      // autoComplete,
      getTeacherId,
      getEventsManagment,

      isSessionModalActive,
      sessionData,
      courseGroup,

      selectedFilter,
      filters,
      selectedLevel,

      teachersOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventModalActive,
      isModalActive,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
@import '@core/scss/vue/libs/vue-select.scss';
.teacher-calendar .fc-daygrid-event:hover {
  background-color: transparent;
  cursor: default;
}

.teacher-calendar .classroomsstatus span {
  cursor: pointer;
}
.fc-dateScroller-button {
  margin-right: 1rem !important;
}
// .teacher-calendar .classroomsstatus span.statfull {
//   cursor: pointer;
// }
</style>
