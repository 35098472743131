import axios from '@axios'

let conversationId = null
export default {
  namespaced: true,
  state: {
    newConversation: null,
    data: {
      users: [],
      conversations: [],
    },
    activeChat: {},
  },
  getters: {},
  mutations: {
    /* eslint-disable consistent-return, camelcase */
    setData(state, { response, page }) {
      // console.log(response.allusers)
      // response.allusers.forEach(user => {
      //   state.data.users.push(user)
      // })
      // state.data.users = response.allusers
      if (response.data.conversations.length === 0) return
      if (response.data.conversations.data && page > 1) {
        response.data.conversations.data.forEach(el => {
          state.data.conversations.push(el)
        })
      } else {
        state.data.conversations = (response.data.conversations.data ? response.data.conversations.data : response.data.conversations)
      }
    },
    setActiveChat(state, response) {
      if (response.messages.current_page === 1 || state.activeChat.conversation.id !== conversationId) {
        state.activeChat = response
        return state.activeChat
      }
      response.messages.data.forEach(el => {
        state.activeChat.messages.data.push(el)
      })
      state.activeChat.messages.current_page = response.messages.current_page
    },
  },
  actions: {
    deleteMsg(store, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`feedback/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchChatsAndContacts(store, { page, conversation_type }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/conversations', { params: { page, conversation_type } })
          .then(response => {
            store.commit('setData', { response, page })
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchSupportConversations(store, { page }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/support_center/conversations', { params: { page } })
          .then(response => {
            store.commit('setData', { response, page })
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getProfileUser() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/chat/users/profile-user')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    renameConversation(store, { id, name }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/conversation/${id}`, { name })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getChat(store, { userId, page }) {
      conversationId = userId
      return new Promise((resolve, reject) => {
        axios
          .get(`/conversation/get/messages/${userId}`, { params: { page } })
          .then(response => {
            store.commit('setActiveChat', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    sendMessage(ctx, { conversation_id, content, user_id }) {
      return new Promise((resolve, reject) => {
        axios
          .post('conversation/message/send', { conversation_id, content, user_id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createConversation(store, { user_id, participants, conversation_type }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/conversation', { user_id, participants, conversation_type })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addToConversation(store, { conversation_id, users }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/conversation/add/users', { conversation_id, users })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteConversation(store, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/conversation/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    markAsRead(store, conversation_id) {
      return new Promise((resolve, reject) => {
        axios
          .post('conversation/mark/as-read', conversation_id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
