<template>
  <b-card>
    <h4 class="mb-2">
      Scheduled sessions
    </h4>
    <b-tabs
      content-class="pt-1"
      fill
    >
      <b-tab>
        <template #title>
          <span>{{ !isStudent ? $t('Yesterday') : $t('Past sessions') }}</span>
          <b-badge
            v-if="courses.yesterday || courses.lastSession"
            pill
            class="ml-50"
            variant="danger"
          >
            {{ courses.yesterday ? courses.yesterday.length : (courses.lastSession ? courses.lastSession.length : '0') }}
          </b-badge>
        </template>
        <day-session-list
          v-if="courses.yesterday || courses.lastSession"
          :sessions="courses.yesterday ? courses.yesterday : courses.lastSession"
        />
      </b-tab>
      <b-tab active>
        <template #title>
          <span>{{ !isStudent ? $t('Today') : $t('Current session') }}</span>
          <b-badge
            v-if="courses.today"
            pill
            class="ml-50"
            variant="danger"
          >
            {{ courses.today.length }}
          </b-badge>
        </template>
        <day-session-list
          v-if="courses.today"
          :sessions="courses.today"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <span>{{ !isStudent ? $t('Tomorrow') : $t('Upcoming sessions') }}</span>
          <b-badge
            v-if="courses.nextDay || courses.nextSession"
            pill
            class="ml-50"
            variant="danger"
          >
            {{ courses.nextDay ? courses.nextDay.length : (courses.nextSession ? courses.nextSession.length : '0') }}
          </b-badge>
        </template>
        <day-session-list
          v-if="courses.nextDay || courses.nextSession"
          :sessions="courses.nextDay ? courses.nextDay : courses.nextSession"
        />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
/* eslint-disable object-curly-newline */
import { BTabs, BTab, BCard, BBadge } from 'bootstrap-vue'
import DaySessionList from './DaySessionList.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BBadge,

    DaySessionList,
  },
  props: {
    isStudent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sessions: {
        today: [],
        nextDay: [],
        lastDay: [],
      },
      courses: {},
      userData: JSON.parse(localStorage.userData),
    }
  },
  watch: {
  '$store.state.appConfig.notificationRefreshKey': function () {
      if (this.userData.role === 'teacher') {
        this.$http.get('/teacher/dashboard/quick-overview').then(res => {
          this.courses = res.data
        })
      } else {
        this.$http.get('/student/dashboard/quick-overview').then(res => {
          this.courses = res.data
        })
      }
    },
  },
  created() {
    if (this.userData.role === 'teacher') {
      this.$http.get('/teacher/dashboard/quick-overview').then(res => {
        this.courses = res.data
      })
    } else {
      this.$http.get('/student/dashboard/quick-overview').then(res => {
        this.courses = res.data
      })
    }
  },
}
</script>
