/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from './dashboard'
import courses from './courses'
import calendars from './calendars'
import inviteEmployee from './inviteEmployee'
// import appsAndPages from './apps-and-pages'
import admin from './admin'
import teacher from './teacher'
import student from './student'
// import test from './test'
// import others from './others'
// import chartsAndMaps from './charts-and-maps'
// import uiElements from './ui-elements'
// import formAndTable from './forms-and-table'

// Array of sections
export default [/* ...dashboard, */...dashboard, ...student, ...teacher, ...courses, ...calendars, ...inviteEmployee, ...admin] /*  ...test ...uiElements, ...formAndTable, ...chartsAndMaps, ...others */
