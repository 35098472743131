import { ref, watch, computed } from '@vue/composition-api'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'

export default function useEventsList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'name', sortable: true },
    { key: 'date_start', sortable: true },
    { key: 'date_end', sortable: true },
    { key: 'day_off' },
    { key: 'actions', tdClass: 'table-actions' },
  ]
  const perPage = ref(10)
  const totalEvents = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const dateStartFromFilter = ref('')
  const dateStartToFilter = ref('')
  const dateEndFromFilter = ref('')
  const dateEndToFilter = ref('')
  const planFilter = ref('')
  const statusFilter = ref('')
  const userData = JSON.parse(localStorage.getItem('userData'))
  const studentId = ref(userData.id)
  const selectedFilter = ref('Active')
  /* eslint-disable-next-line */
  const role_name = ref(window.location.href.split('/')[3])

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalEvents.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }
  const urlParams = new URLSearchParams(window.location.search)
  const urlQueries = router.currentRoute.query
  if (urlQueries.search) searchQuery.value = urlQueries.search
  if (urlQueries.page) currentPage.value = urlQueries.page
  if (urlQueries.perPage) perPage.value = urlQueries.perPage
  if (urlQueries.filter) selectedFilter.value = urlQueries.filter
  if (urlQueries.date_start_from) dateStartFromFilter.value = urlQueries.date_start_from
  if (urlQueries.date_start_to) dateStartToFilter.value = urlQueries.date_start_to
  if (urlQueries.date_end_from) dateEndFromFilter.value = urlQueries.date_end_from
  if (urlQueries.date_end_to) dateEndToFilter.value = urlQueries.date_end_to
  /* eslint-disable-next-line */
  watch([currentPage, perPage, roleFilter, dateStartFromFilter, dateStartToFilter, dateEndFromFilter, dateEndToFilter, planFilter, statusFilter, role_name, selectedFilter, searchQuery],  () => {
    urlParams.set('search', searchQuery.value ? searchQuery.value : '')
    urlParams.set('page', currentPage.value ? currentPage.value : '')
    urlParams.set('perPage', perPage.value ? perPage.value : '')
    urlParams.set('filter', selectedFilter.value ? selectedFilter.value : '')
    urlParams.set('date_start_from', dateStartFromFilter.value ? dateStartFromFilter.value : '')
    urlParams.set('date_start_to', dateStartToFilter.value ? dateStartToFilter.value : '')
    urlParams.set('date_end_from', dateEndFromFilter.value ? dateEndFromFilter.value : '')
    urlParams.set('date_end_to', dateEndToFilter.value ? dateEndToFilter.value : '')
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams.toString()}`)
    refetchData()
  })
  const isBusy = ref(false)

  const fetchEvents = (ctx, callback) => {
    const payload = {
      name: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      role_name: roleFilter.value,
      date_start_from: dateStartFromFilter.value,
      date_start_to: dateStartToFilter.value,
      date_end_from: dateEndFromFilter.value,
      date_end_to: dateEndToFilter.value,
      plan: planFilter.value,
      status: statusFilter.value,
      filter: selectedFilter.value,
      // role_name: role_name.value,
    }
    if (!dateStartFromFilter.value.length) {
      delete payload.date_start_from
    }
    if (!dateStartToFilter.value.length) {
      delete payload.date_start_to
    }
    if (!dateEndFromFilter.value.length) {
      delete payload.date_end_from
    }
    if (!dateEndToFilter.value.length) {
      delete payload.date_end_to
    }
    store
      .dispatch('event/fetchEvents', payload)
      .then(() => {
        const users = store.state.eventStoreModule.events.rows
        const total = store.state.eventStoreModule.events.totalRecords
        callback(users)
        totalEvents.value = total
      })
      .catch(() => {
        isBusy.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching events list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status.toString() === 'false') return 'danger'
    if (status.toString() === 'true') return 'success'
    if (status.toString() === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchEvents,
    tableColumns,
    perPage,
    currentPage,
    totalEvents,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    role_name,
    studentId,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    dateStartFromFilter,
    dateStartToFilter,
    dateEndFromFilter,
    dateEndToFilter,
    planFilter,
    statusFilter,
    selectedFilter,
  }
}
