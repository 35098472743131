import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
// import { canNavigate } from '@/libs/acl/routeProtection'
/* eslint-disable-next-line */
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser, userRolee } from '@/auth/utils'
import apps from './routes/apps'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import courses from './routes/courses'
import admin from './routes/admin'
import student from './routes/student'
import agent from './routes/agent'
import company from './routes/company'
import teacher from './routes/teacher'
import home from './routes/home'
import calendars from './routes/calendars'
import test from './routes/test'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { path: !isUserLoggedIn() && document.querySelector('#app').dataset.courseslist === 'yes' ? 'courses' : 'login' } },
    ...admin,
    ...student,
    ...agent,
    ...company,
    ...teacher,
    ...courses,
    ...apps,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    ...home,
    ...calendars,
    ...test,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  // console.log(to.path.split('token=')[1])
  const isLoggedIn = isUserLoggedIn()
  if (!isLoggedIn && to.name !== 'auth-login' && to.name !== 'auth-register' && to.name !== 'auth-forgot-password' && to.name !== 'invitation' && to.name !== 'home') {
    localStorage.routeName = to.name
    if (document.querySelector('#app').dataset.courseslist === 'yes') {
      return next({ name: 'home' })
    }
    return next({ name: 'auth-login' })
  }
  // if (!canNavigate(to)) {
  //   // Redirect to login if not logged in
  //   if (!isLoggedIn) return next({ name: 'auth-login' })

  //   // If logged in => not authorized
  //   return next({ name: 'misc-not-authorized' })
  // }

  const userData = getUserData()
  if (to.name !== 'error-404' && isLoggedIn) {
    if (userData.role === 'administrator') {
      if (to.path.startsWith('/student') || to.path.startsWith('/teacher') || to.path.startsWith('/agent') || to.path.startsWith('/company')) {
        next('/error-404')
      }
    }
    if (userData.role === 'student') {
      if (to.path.startsWith('/admin') || to.path.startsWith('/teacher') || to.path.startsWith('/agent') || to.path.startsWith('/company')) {
        next('/error-404')
      }
    }
    if (userData.role === 'teacher') {
      if (to.path.startsWith('/admin') || to.path.startsWith('/student') || to.path.startsWith('/agent') || to.path.startsWith('/company')) {
        next('/error-404')
      }
    }
    if (userData.role === 'agent') {
      if (to.path.startsWith('/admin') || to.path.startsWith('/student') || to.path.startsWith('/teacher') || to.path.startsWith('/company')) {
        next('/error-404')
      }
    }
    if (userData.role === 'company') {
      if (to.path.startsWith('/admin') || to.path.startsWith('/student') || to.path.startsWith('/teacher') || to.path.startsWith('/agnet')) {
        next('/error-404')
      }
    }
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
