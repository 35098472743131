<template>
  <div>
    <b-row class="mb-1">
      <b-col cols="6" />
      <b-col cols="3">
        <div>
          <v-select
            v-model="dateOption"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="dateOptions"
            @input="fetchStatistics()"
          />
        </div>
      </b-col>
      <b-col cols="3">
        <!-- datepicker -->
        <div class="d-flex align-items-center">
          <feather-icon
            icon="CalendarIcon"
            size="16"
          />
          <flat-pickr
            v-model="rangePicker"
            :config="{ mode: 'range', dateFormat: 'Y-m-d', altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 }}"
            class="form-control flat-picker bg-transparent border-0 shadow-none"
            placeholder="YYYY-MM-DD"
            @input="foo"
          />
        </div>
        <!-- datepicker -->
      </b-col>
    </b-row>
    <worked-hours
      :key="refreshKey+String('worked_hours')"
      :line-chart-simple="apexChartData.lineChartSimple"
    />
    <courses-attendance
      :key="refreshKey+String('attendance')"
      :column-chart="apexChartData.columnChart"
      :initial-range-picker="initialRangePicker"
    />
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import WorkedHours from './WorkedHours.vue'
import CoursesAttendance from './CoursesAttendance.vue'

import apexChartData from './apexChartData'

export default {
  components: {
    WorkedHours,
    CoursesAttendance,
    BRow,
    BCol,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      apexChartData,
      refreshKey: 0,
      rangePicker: [],
      initialRangePicker: [],
      start: '',
      end: '',
      dateOption: { title: 'Daily', value: 'daily' },
      dateOptions: [
        { title: 'Daily', value: 'daily' },
        { title: 'Weekly', value: 'weekly' },
        { title: 'Monthly', value: 'monthly' },
      ],
    }
  },
  created() {
    this.start = moment(new Date()).format('YYYY-MM-DD')
    const nextMonth = new Date(new Date(this.start).setMonth(new Date(this.start).getMonth() + 1))
    this.end = moment(nextMonth).format('YYYY-MM-DD')
    this.rangePicker.push(this.start, this.end)
    this.initialRangePicker.push(this.start, this.end)
    this.fetchStatistics()
    // this.$http.get('/statistics/admin/charts', {  })
  },
  methods: {
    /* eslint-disable prefer-destructuring */
    foo() {
      if (this.rangePicker.split(' ').length > 1) {
        this.start = this.rangePicker.split(' ')[0]
        this.end = this.rangePicker.split(' ')[2]
        this.fetchStatistics()
      }
    },
    fetchStatistics() {
      this.$http.get('/statistics/teacher/charts', {
        params: {
          date_start: this.start,
          date_end: this.end,
          filter: this.dateOption.value,
        },
      }).then(res => {
        apexChartData.lineChartSimple.chartOptions.xaxis.categories = res.data.teacherWorkHours.dates
        apexChartData.lineChartSimple.series[0].data = res.data.teacherWorkHours.leads

        apexChartData.columnChart.series[0].data = res.data.courseAttendance.attendance.data
        apexChartData.columnChart.series[1].data = res.data.courseAttendance.free_spots.data
        this.refreshKey += 1
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
