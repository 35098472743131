<template>

  <div>

    <invoice-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
      :methods="methods"
    />

    <!-- Filters -->
    <!-- <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row v-if="!studentId">
          <b-col
            cols="12"
            lg="6"
          >
            <v-select
              v-model="selectedFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filters"
              lebel="label"
              :reduce="x => x.value"
              :clearable="false"
              class="courses-filter d-inline-block mx-50"
              style="min-width: 150px;"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
                debounce="500"
              />
              <!-- <b-button
                variant="primary"
                @click="getPaymentMethod"
              >
                <span class="text-nowrap">{{ ('Add receipt') }}</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="studentId ? tableColumns1 : tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
        :tbody-tr-class="rowClass"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>
        <!-- Column: User -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.id }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: visa -->
        <template #cell(course)="data">
          <span class="text-primary">{{ data.item.course_name }}</span>
          <small class="d-block" :class="data.item.payment_status === 'refunded' ? 'text-danger' : 'text-muted'">
            {{ data.item.course_price }} {{ data.item.course_price ? $store.state.appConfig.currency : '' }}
          </small>
        </template>
        <template #cell(group)="data">
          {{ data.item.group }}
        </template>

        <template #cell(student)="data">
          <div class="media-body">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-primary"
              :to="{ name: 'apps-users-view', params: { id: data.item.student_id } }"
              class="font-weight-bold d-block text-nowrap text-capitalize"
              :style="[data.item.waiting_list ? {'postition': 'relative', 'color': '#005160', 'background-color': '#cff4fc', 'border-color': '#b6effb'} : {}]"
            >
              <span class="d-flex">
                {{ data.item.student_name ? data.item.student_name.substring(0,20) + (data.item.student_name.length >= 20 ? '...' : '') : '' }}
              </span>
              <div class="d-flex align-items-center">
                <small class="d-block mt-50 mr-1 d-flex text-muted" style="color: #055160">{{ data.item.student_id }}</small>
                <small
                  v-if="data.item.country"
                  class="mt-50 mr-50 d-flex text-muted"
                >
                  <span class="badge text-capitalize badge-light-secondary badge-pill">{{ data.item.country }}</span>
                </small>
              </div>
            </b-button>
          </div>
        </template>

        <!-- Column: number -->
        <template #cell(number)="data">
          {{ data.item.number }}
        </template>

        <!-- Column: who pays -->
        <template #cell(paid)="data">
          <div class="media-body">
            <div class="font-weight-bold d-block text-nowrap text-capitalize">
              {{ data.item.paymentMethod ? data.item.paymentMethod.name : data.item.paymentMethod }}
            </div>
            <!-- <small
              class="text-muted"
              v-html="resolvePaidVariant((data.item.paid.value !== undefined ? data.item.paid.value : data.item.paid))"
            /> -->
          </div>
        </template>
        <!-- <template #cell(paid)="data">
          <b-badge
            pill
            :variant="`light-${resolvePaidVariant(data.item.paid)}`"
            class="text-capitalize"
          >
            {{ data.item.paid }}
          </b-badge>
        </template> -->

        <!-- Column: who pays -->
        <template #cell(payment_status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.payment_status)}`"
            class="text-capitalize"
          >
            {{ data.item.payment_status }}
          </b-badge>
        </template>

        <!-- Column: who pays -->
        <template #cell(date)="data">
          <div class="font-weight-bold d-block text-nowrap text-capitalize">
            {{ data.item.payment_date }}
          </div>
        </template>
        <template #cell(payer)="data">
          <div v-if="data.item.payer" class="font-weight-bold d-block text-nowrap text-capitalize">
            {{ data.item.payer.name }}
          </div>
        </template>
        <template #cell(comments)="data">
          <b-button
            :id="`popover-target-${data.item.id}`"
            variant="flat-primary"
            size="sm"
            class="btn-icon rounded-circle"
            :disabled="data.item.deleted_at"
            @click="handleChatClick({model:'invoice', id: data.item.id})"
          >
            <feather-icon
              icon="ClipboardIcon"
              class="align-middle text-body"
              badge-classes="bg-danger"
              :badge="data.item.comments_number > 0 ? data.item.comments_number : ''"
              size="16"
            />
          </b-button>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-if="data.item.deleted_at"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mr-2"
            @click="restorePayment(data.item.id)"
          >
            Restore
          </b-button>
          <b-dropdown
            v-if="!(data.item.payment_status === 'Refunded' && !data.item.view_receipt) && !data.item.deleted_at"
            dropleft
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- <b-dropdown-item
              v-if="userRole === 'administrator' && data.item.payment_status === 'pending'"
              @click="markAsPaidPending(data.item.id, true)"
            >
              <feather-icon icon="CheckCircleIcon" />
              <span class="align-middle ml-50">Mark as unpaid</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="userRole === 'administrator' && data.item.payment_status === 'paid'"
              @click="markAsPaidPending(data.item.id, false)"
            >
              <feather-icon icon="LoaderIcon" />
              <span class="align-middle ml-50">Mark as pending</span>
            </b-dropdown-item> -->
            <b-dropdown-item
              v-if="userRole === 'administrator' && data.item.payment_status !== 'refunded' && data.item.registrationFilter && data.item.registrationFilter.code === 'paid'"
              @click="getData(data.item)"
              v-b-modal="getModal2Id(data.item.id)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.payment_status !== 'pending'"
              @click="printInvoice(data.item.id)"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Preview</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="userRole === 'administrator' && data.item.payment_status !== 'refunded'"
              v-b-modal="getModal1Id(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
            <!-- basic modal -->
            <!-- basic modal -->
            <b-modal
              :id="'modal1'+data.item.id"
              title="Delete receipt"
              ok-title="Yes"
              cancel-title="No"
              centered
              @ok="remove(data.item.id)"
            >
              <b-card-text>
                <h5>{{ $t('Are you sure you want to delete') }} <strong>{{ data.item.student_name }}</strong>'s {{ ('receipt') }}</h5>
              </b-card-text>
            </b-modal>
            <!-- basic modal -->
            <b-modal
              :id="'modal2'+data.item.id"
              :title="('Update payment')"
              :ok-title="$t('Edit')"
              hide-footer
              size="lg"
              centered
              no-close-on-backdrop
            >
              <validation-observer
                #default="{ handleSubmit }"
                ref="form"
              >
                <!-- Form -->
                <b-form
                  class="p-2"
                  @submit.prevent="handleSubmit(edit(payload))"
                  @reset.prevent="resetForm"
                >
                  <b-row>
                    <!-- Student -->
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Student"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Student')"
                          label-for="student"
                          :state="getValidationState(validationContext)"
                        >
                          <v-select
                            v-model="payload.student_name"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :options="students"
                            :clearable="false"
                            disabled
                            @keyup.native="autoCompleteStudent($event.target.value, 'student')"
                            @click.native="autoCompleteStudent('', 'student')"
                          >
                            <span slot="no-options">
                              <div
                                v-if="isTypingStudent"
                                class="text-center d-flex align-items-center justify-content-center"
                              >
                                <b-spinner
                                  variant="primary"
                                  label="Text Centered"
                                />
                              </div>
                              <div
                                v-if="!isTypingStudent && !students.length"
                                class="text-center d-flex align-items-center justify-content-center"
                              >
                                No matching records found
                              </div>
                            </span>
                          </v-select>
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Course -->
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="course"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Course')"
                          label-for="course"
                          :state="getValidationState(validationContext)"
                        >
                          <v-select
                            v-model="payload.course_name"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :clearable="false"
                            :options="courses"
                            disabled
                            @keyup.native="autoCompleteCourse($event.target.value)"
                            @click.native="autoCompleteCourse('')"
                          >
                            <span slot="no-options">
                              <div
                                v-if="isTypingCourse"
                                class="text-center d-flex align-items-center justify-content-center"
                              >
                                <b-spinner
                                  variant="primary"
                                  label="Text Centered"
                                />
                              </div>
                              <div
                                v-if="!isTypingCourse && !courses.length"
                                class="text-center d-flex align-items-center justify-content-center"
                              >
                                No matching records found
                              </div>
                            </span>
                          </v-select>
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Sent date -->
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Sent date"
                      >
                        <b-form-group
                          :label="$t('Sent date')"
                          label-for="example-datePicker2"
                          :state="getValidationState(validationContext)"
                        >
                          <flat-pickr
                            v-model="payload.sent_date"
                            class="form-control sent-date"
                            name="date"
                            :config="{ altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 } }"
                            :placeholder="$t('Sent at...')"
                            disabled
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Sent date -->
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Paid date"
                      >
                        <b-form-group
                          label-for="example-datePicker3"
                          :label="$t('Paid date')"
                        >
                          <flat-pickr
                            v-model="payload.paid_date"
                            class="form-control"
                            name="date"
                            :config="{ altFormat: 'd-m-Y', altInput: true}"
                            :placeholder="$t('Paid at...')"
                            @input="errors.paid_date = []"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                          <small
                            v-if="errors"
                            class="text-danger"
                          >{{ errors.paid_date[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Method -->
                    <b-col
                      cols="12"
                      sm="4"
                    >
                      <b-form-group
                        label="Payment method"
                        label-for="payment-method"
                      >
                        <v-select
                          v-model="payload.paymentMethod"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="methods"
                          label="name"
                          :clearable="false"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Paiment status -->
                    <b-col
                      cols="12"
                      sm="4"
                    >
                      <b-form-group
                        label="Payment status"
                        label-for="payment-status"
                      >
                        <v-select
                          v-model="payload.registrationFilter"
                          label="name"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="statusOptions.filter(el => el.id && !el.filtered)"
                          :clearable="false"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Who pays -->
                    <b-col
                      cols="12"
                      sm="4"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Payer"
                      >
                        <b-form-group
                          label="Payer"
                          label-for="who-pays"
                          :state="getValidationState(validationContext)"
                        >
                          <v-select
                            v-model="payload.who_pays"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :options="payerOptions"
                            :clearable="false"
                            @input="payload.registration.payer_tel = null; payload.registration.payer_name = null; payload.registration.payer_email = null; payload.registration.payer_address = null; payload.registration.company_number; payload.registration.company_number; payload.registration.responsible = null"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <!-- Payer name -->
                      <validation-provider
                        v-if="payload.who_pays && (payload.who_pays.code === 'payer' || payload.who_pays.code === 'company')"
                        #default="validationContext"
                        name="Payer name"
                      >
                        <b-form-group
                          :label="('Payer name')"
                          label-for="payer-name"
                        >
                          <b-form-input
                            v-model="payload.registration.payer_name"
                            autofocus
                            :state="getValidationState(validationContext)"
                            :placeholder="('Payer name')"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="6"
                      >
                        <!-- Payer address -->
                        <validation-provider
                          v-if="payload.who_pays && (payload.who_pays.code === 'payer' || payload.who_pays.code === 'company')"
                          #default="validationContext"
                          name="Invoicing address"
                        >
                          <b-form-group
                            :label="$t('Invoicing address')"
                            label-for="invoicing-address"
                          >
                            <b-form-input
                              v-model="payload.registration.payer_address"
                              autofocus
                              :state="getValidationState(validationContext)"
                              :placeholder="('Invoicing address')"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col
                    cols="12"
                    sm="6"
                    >
                      <!-- Company number -->
                      <validation-provider
                        v-if="payload.who_pays && payload.who_pays.code === 'company'"
                        #default="validationContext"
                        name="Company org.number"
                      >
                        <b-form-group
                          :label="$t('Company org.number')"
                          label-for="Company-org.number"
                        >
                          <b-form-input
                            v-model="payload.registration.company_number"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            :placeholder="('Company org.number')"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                    cols="12"
                    sm="6"
                    >
                      <!-- Responsible -->
                      <validation-provider
                        v-if="payload.who_pays && payload.who_pays.code === 'company'"
                        #default="validationContext"
                        name="Responsible / contact person"
                      >
                        <b-form-group
                          :label="('Responsible / contact person')"
                          label-for="Resposible-contact-person"
                        >
                          <b-form-input
                            v-model="payload.registration.responsible"
                            autofocus
                            :state="getValidationState(validationContext)"
                            :placeholder="('Responsible / contact person')"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <!-- Phone number -->
                      <validation-provider
                        v-if="payload.who_pays && (payload.who_pays.code === 'payer' || payload.who_pays.code === 'company')"
                        #default="validationContext"
                        name="Phone number"
                      >
                        <b-form-group
                          :label="('Phone number')"
                          label-for="Phone-number"
                        >
                          <b-form-input
                            v-model="payload.registration.payer_tel"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            :placeholder="('Phone number')"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <!-- Email address -->
                      <validation-provider
                        v-if="payload.who_pays && (payload.who_pays.code === 'payer' || payload.who_pays.code === 'company')"
                        #default="validationContext"
                        name="Email address"
                        rules="email"
                      >
                        <b-form-group
                          :label="('Email address')"
                          label-for="Email-address"
                        >
                          <b-form-input
                            v-model="payload.registration.payer_email"
                            autofocus
                            type="email"
                            :state="getValidationState(validationContext)"
                            trim
                            :placeholder="('Email address')"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <!-- Status -->
                  <!-- <b-form-group
                    :label="$t('Status')"
                    label-for="status"
                  >
                    <v-select
                      v-model="data.item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :clearable="false"
                    />
                  </b-form-group> -->

                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      {{ $t('Update') }}
                    </b-button>
                  </div>

                </b-form>
              </validation-observer>
            </b-modal>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="!isBusy"
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <div
      v-if="isChatActive"
      class="notesModule shadow-lg"
    >
      <div style="background: #fff" class="pb-1">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="light"
          size="sm"
          class="btn-icon shadow-sm ml-auto "
          @click="isChatActive = false"
          style="display: block; transform: translate(8px, -2px);"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
      <chat-module
        :key="chatModelId.id"
        :model-id="chatModelId.id"
        model-type="invoice"
        @notes-increament="refetchData"
        @decrease-notes="refetchData"
      />
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, BSpinner, BFormInvalidFeedback, BForm, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, alphaNum, email, min,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, onUnmounted, watch } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ChatModule from '@/views/admin/ChatModule.vue'
import useUsersList from './useInvoicesList'
import invoiceStoreModule from './invoiceStoreModule'
import InvoiceListAddNew from './InvoiceListAddNew.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    // UsersListFilters,
    InvoiceListAddNew,
    ChatModule,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BSpinner,
    BFormInvalidFeedback,
    BForm,
    BFormGroup,

    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    studentId: {
      type: Number,
      default: null,
    },
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      min,
      query1: '',
      query2: '',
      students: [],
      courses: [],
      // paidOptions: ['paid', 'pending'],
      paidMethodOptions: ['Card', 'Cash', 'Online', 'Wire transfer'],
      // statusOptions: ['Active', 'Deleted'],
      isTypingStudent: false,
      isTypingCourse: false,

    }
  },
  methods: {
    /* eslint-disable camelcase, consistent-return */
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.registrationFilter && item.registrationFilter.code !== 'paid' && item.registrationFilter.code !== 'processing' && item.payment_status === 'paid') return 'table-light'
    },
    autoCompleteStudent(search, role_name) {
      this.isTypingStudent = true
      this.$http.post('/autocomplete/user', { search, role_name })
      .then(res => {
        this.isTypingStudent = false
        this.students = res.data
      })
    },

    autoCompleteCourse(search) {
      this.isTypingCourse = true
      this.$http.post('/autocomplete/course', { search })
      .then(res => {
        this.isTypingCourse = false
        this.courses = res.data
      })
    },
  },
  setup(props) {
    /* eslint-disable camelcase */
    const getModal1Id = id => `modal1${id}`
    const getModal2Id = id => `modal2${id}`

    const INVOICE_STORE_MODULE_NAME = 'invoice'

    // Register module
    if (!store.hasModule(INVOICE_STORE_MODULE_NAME)) store.registerModule(INVOICE_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_STORE_MODULE_NAME)
    })

    watch(() => props.refreshKey, () => {
      refetchData()
    })
    const statusOptions = ref([])
    const getStatusOptions = () => {
      store.dispatch('invoice/getStatusOptions').then(res => {
        statusOptions.value = res.data.rows
      })
    }
    getStatusOptions()

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'administrator' },
      { label: 'Student', value: 'student' },
      { label: 'Teacher', value: 'teacher' },
      { label: 'Agent', value: 'agent' },
      { label: 'Company', value: 'company' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const filters = [
      { label: 'All', value: null },
      { label: 'Active', value: 'Active' },
      { label: 'Deleted', value: 'Deleted' },
    ]
    const query1 = ref('')
    const query2 = ref('')
    const selectedTemplate = ref({})
    const selectedCourse = ref({})
    const templates = ref([])
    const courses = ref([])
    const comments = ref('')
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userRole = userData.role

    const payload = ref({})
    const methods = ref([])
    const getPaymentMethod = () => {
      store.dispatch('invoice/getPaymentMethod').then(res => {
        methods.value = res.data.rows
        isAddNewUserSidebarActive.value = true
      })
    }
    const getData = item => {
      fetchPayers()
      store.dispatch('invoice/getPaymentMethod').then(res => {
        methods.value = res.data.rows
      })
      payload.value = { ...item }
      payload.value.who_pays = payload.value.payer
    }

    // Use toast
    const toast = useToast()

    const {
      payerOptions,
      fetchPayers,
      isBusy,
      fetchUsers,
      tableColumns,
      tableColumns1,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      resolvePaidVariant,
      refetchData,
      student_id,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      selectedFilter,
    } = useUsersList()
    if (props.studentId) { student_id.value = props.studentId }
    const invoiceFile = ref(null)
    const restorePayment = id => {
      store.dispatch('invoice/restorePayment', id)
      .then(res => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
    const printInvoice = id => {
      store.dispatch('invoice/printInvoice', id).then(res => { invoiceFile.value = res.data })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while creating contract registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }

    const remove = invId => {
      store.dispatch('invoice/deleteInvoice', invId)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Invoice Has Been removed',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while removing Invoice',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const markAsPaidPending = (id, paid) => {
      const item = { id, paid }
      store.dispatch('invoice/editInvoice', { item })
        .then(res => {
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const errors = ref({
      paid_date: [],
    })
    const edit = function (element) {
      /* eslint-disable no-nested-ternary */
      if (this.$refs.form.flags.valid) {
        const item = {
          id: element.id,
          student_id: element.student_name.id === undefined ? element.student_id : element.student_name.id,
          course_id: element.course_name.id === undefined ? element.course_id : element.course_name.id,
          sent_date: element.sent_date,
          paid_date: element.paid_date,
          paid_method_id: element.paymentMethod ? element.paymentMethod.id : null,
          // paid: element.paid ? element.paid.value : null,
          payer_id: element.who_pays ? element.who_pays.id : null,
          payer_name: element.registration.payer_name,
          company_number: element.registration.company_number,
          payer_address: element.registration.payer_address,
          responsible: element.registration.responsible,
          payer_phone: element.registration.payer_tel,
          payer_email: element.registration.payer_email,
          payment_status_id: element.registrationFilter.id,
          // payment_method_id: element.paymentMethod.id,

        }
        store.dispatch('invoice/editInvoice', { item })
          .then(res => {
            toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            refetchData()
        }).catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          errors.value = error.response.data.errors
        })
      }
    }
    const isChatActive = ref(false)
    const chatModelId = ref(null)
    const handleChatClick = data => {
      isChatActive.value = true
      chatModelId.value = data
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation({})

    return {
      restorePayment,
      payerOptions,
      fetchPayers,
      invoiceFile,
      getStatusOptions,
      statusOptions,
      methods,
      getPaymentMethod,
      getData,
      payload,
      errors,
      markAsPaidPending,
      // Modal ids
      getModal1Id,
      getModal2Id,

      refFormObserver,
      getValidationState,
      resetForm,

      isChatActive,
      chatModelId,
      handleChatClick,
      isBusy,

      // Actions
      remove,
      printInvoice,
      edit,

      // autocomplete
      query1,
      query2,
      selectedTemplate,
      selectedCourse,
      templates,
      courses,

      comments,
      userRole,

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      tableColumns1,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolvePaidVariant,

      roleOptions,
      planOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      selectedFilter,
      filters,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.sent-date .form-control {
  background-color: #f8f8f8 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
