<template>
  <!-- eslint-disable vue/singleline-html-element-content-newline -->
  <b-card :key="refreshKey">
    <div class="d-flex justify-content-between align-items-center">
      <h4 v-if="approvalType === 'course'" class="mb-2">Courses to teach</h4>
      <h4 v-if="approvalType === 'substitution'" class="mb-2">Course substitution</h4>
      <div>
        <div class="d-flex">
          <span
            class="bullet bullet-warning bullet-sm mr-1"
          />
          <label>{{ approvalType === 'substitution' ? 'Admin requests' : 'Suggested by administration' }}</label>
        </div>
        <div class="d-flex">
          <span
            class="bullet bullet-secondary bullet-sm mr-1"
          />
          <label>{{ approvalType === 'substitution' ? 'Needing substitute' : 'Upcoming courses' }}</label>
        </div>
      </div>
    </div>
    <div class="text-center mt-2">
      <span
        v-if="!items.length"
      >
        {{ approvalType === 'substitution' ? 'There are no substitute teachers needed at the moment :-)' : 'There are no courses to teach at the moment :-)' }}
      </span>
    </div>
    <app-timeline
      v-for="(item, index) in items"
      :key="item.course_id+String(index)"
    >
      <div
        class="d-flex justify-content-between"
      >
        <app-timeline-item
          style="max-width: 245px;"
          :title="item.course_name"
          :variant="(item.filter === 'awaiting' ? 'warning' : 'secondary')"
        >
            <!-- :id="'popover'+index+approvalType" -->
          <div
            v-b-modal="getDetailsModal(index)"
            variant="flat-primary"
            class="d-flex align-items-center cursor-pointer p-25"
            @click="approvalType === 'substitution' ? '' : getDetails(item.course_id)"
          >
            <b class="text-dark mr-50 text-nowrap" v-if="item.lesson_number">Lesson {{ item.lesson_number }}</b>
            <b class="text-nowrap text-dark">{{ item.course_code }}</b>
            <p v-b-tooltip.hover.top="item.group_name" class="text-secondary ml-25 text-nowrap mb-0 text-truncate" style="max-width: 100%">({{ item.group_name }})</p>
          </div>
          <b-modal
            v-if="approvalType === 'course'"
            :id="'modal'+index+approvalType"
            title="Course details"
            ok-only
            size="lg"
            centered
            ok-title="Close"
          >
            <b-row
              v-for="(data, key) in details"
              :key="key"
              class="mt-2"
            >
              <b-col cols="2">
                <h5 class="text-capitalize mb-75">
                  {{ key }}
                </h5>
              </b-col>
              <b-col cols="1">:</b-col>
              <b-col cols="3">
                <b-card-text v-if="key !== 'date'">
                  {{ data }}
                </b-card-text>
                <div
                  v-else
                  pill
                  variant="light-primary"
                  class="text-capitalize"
                >
                  {{ data.split(' - ')[0].split(' ')[0] }} - {{ data.split(' - ')[1].split(' ')[0] }}
                </div>
              </b-col>
            </b-row>
          </b-modal>
          <!-- <b-popover
            :target="'popover'+index+approvalType"
            triggers="hover"
            title="Course details"
            placement="auto"
            container="my-container"
          >
            <div class="text-primary">
              <span>Classroom: </span><b>{{ item.classroom_code ? item.classroom_code : '-' }}</b>
            </div>
            <div class="text-primary">
              <span>Teacher: </span><b>{{ item.classroom_code ? item.teacher : '-' }}</b>
            </div>
          </b-popover> -->
        </app-timeline-item>
        <div v-if="item.filter === 'awaiting'">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-success"
            size="sm"
            class="mr-50"
            @click="approvalType === 'substitution' ? approveSubstitute(item.teacher_substitute_id) : approve(item.course_id)"
          >
            {{ $t('Accept') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            size="sm"
            @click="approvalType === 'substitution' ? declineSubstitute(item.session_id) : $bvModal.show(`modal1${index}${approvalType}`); reason = null"
          >
            {{ $t('Decline') }}
          </b-button>
          <b-modal
            :id="'modal1'+index+approvalType"
            title="Decline reason"
            ok-only
            size="lg"
            centered
            ok-title="Decline the course"
            @ok="cancel(item.course_id, reason)"
          >
            <b-form-textarea
              v-model="reason"
              placeholder="Message"
              maxlength="500"
            />
          </b-modal>
        </div>
        <div v-if="item.filter === 'planned' && item">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-info"
            size="sm"
            class="ml-25"
            @click="approvalType === 'substitution' ? applySubstitute(item.session_id) : request(item.course_id)"
          >
            {{ approvalType === 'substitution' ? 'Apply' : 'Teach' }}
          </b-button>
        </div>
      </div>
      <div class="d-flex">
        <p
          v-if="!item.already_sent && approvalType === 'course'"
          class="ml-3"
        >{{ item.course_date_start.split(' ')[0] }} - {{ item.course_date_end.split(' ')[0] }}<small v-b-tooltip.hover.top="'Classroom: '+item.classroom" v-if="item.classroom"> | CR {{ item.classroom }}</small></p>
      </div>
      <div class="d-flex">
        <p
          v-if="!item.already_sent && approvalType === 'substitution'"
          class="ml-3"
        >{{ item.session_date }} | <small class="text-secondary">{{ item.session_time_start }} - {{ item.session_time_to }}</small> <small v-if="item.classroom" v-b-tooltip.hover.top="'Classroom: '+item.classroom"> | CR {{ item.classroom }}</small></p>
      </div>
    </app-timeline>
    <div class="text-center">
      <b-link
        class="text-center"
        :to="{name: approvalType === 'substitution' ? 'teacher-courses-substitute' : 'teacher-list-all-courses'}"
      >See all</b-link>
    </div>
  </b-card>
</template>

<script>
/* eslint-disable  object-curly-newline, no-return-assign */
import { BCard, BButton, VBTooltip, BLink, BFormTextarea, BRow, BCol, BCardText } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    AppTimeline,
    AppTimelineItem,
    BFormTextarea,
    BLink,
    BRow,
    BCol,
    BCardText,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    approvalType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      courses: {},
      refreshKey: 0,
      details: {},
      reason: null,
    }
  },
  watch: {
  '$store.state.appConfig.notificationRefreshKey': function () {
      this.fetchCourses()
    },
  },
  created() {
    this.fetchCourses()
  },
  methods: {
    applySubstitute(id) {
      this.$http.post('/session/substitute/apply', { session_id: id })
      .then(res => {
        this.fetchCourses()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    declineSubstitute(id) {
      this.$http.post('/session/substitute/cancel', { session_id: id })
      .then(res => {
        this.fetchCourses()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    approveSubstitute(id) {
      this.$http.post('/session/substitute/approve', { teacher_substitute_id: id })
      .then(res => {
        this.fetchCourses()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    fetchCourses() {
      this.$http.post('teacher/dashboard/awaiting-approval').then(res => {
        if (this.approvalType === 'course') this.items = res.data.awaiting.concat(res.data.planned).filter(e => !e.already_sent)
        if (this.approvalType === 'substitution') this.items = res.data.awaiting_sub.concat(res.data.needs_sub).filter(e => !e.already_sent)
      })
    },
    /* eslint-disable camelcase */
    approve(course_id) {
      this.$http.post('/teacher/courses/approve', { course_id })
      .then(res => {
        this.fetchCourses()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    },
    request(course_id) {
      this.$http.post('/teacher/courses/join', { course_id })
      .then(res => {
        this.fetchCourses()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    },
    cancel(course_id, reason) {
      this.$http.post('/teacher/courses/cancel', { course_id, reason })
      .then(res => {
          this.fetchCourses()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    },
    getDetails(id) {
      this.$http.get(`/courses/get/${id}`).then(res => {
        this.details = {
          name: res.data.data.Name,
          classroom: res.data.data.classroom ? res.data.data.classroom.code : '-',
          // city: res.data.data.city ? res.data.data.city : '-',
          date: `${res.data.data.date_start} - ${res.data.data.date_end}`,
          time: `${res.data.data.time_from} - ${res.data.data.time_to}`,
          days: res.data.data.days ? res.data.data.days : '-',
          hours: res.data.data.hours,
          students: res.data.data.students_limit,
        }
      })
    },
    getDetailsModal(idx) { return `modal${idx}${this.approvalType}` },
    getReasonModal(idx) { return `modal1${idx}${this.approvalType}` },
  },
}
</script>
