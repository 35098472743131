<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('Add New event') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              :label="$t('Name')"
              label-for="name"
            >
              <b-form-input
                v-model="name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('Name')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Date start -->
          <validation-provider
            #default="validationContext"
            name="Start date"
            rules="required"
          >
            <b-form-group
              label="Start date"
              label-for="start-date"
              :state="getValidationState(validationContext)"
            >
              <flat-pickr
                v-model="date_start "
                class="form-control"
                placeholder="Start date"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss', altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 }}"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Date End -->
          <validation-provider
            #default="validationContext"
            name="End date"
            rules="required"
          >
            <b-form-group
              label="End date"
              label-for="end-date"
              :state="getValidationState(validationContext)"
            >
              <flat-pickr
                v-model="date_end"
                class="form-control"
                :placeholder="$t('Pick a date')"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss', altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 }}"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- day off -->
          <validation-provider
            #default="validationContext"
            name="Day off"
            rules="required"
          >
            <b-form-group
              :state="getValidationState(validationContext)"
              :label="$t('Day off')"
            >
              <v-select
                v-model="selectedDayOff"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="label"
                :options="dayOffOptions"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInvalidFeedback, BButton, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
    BFormInput,
    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      dayOffOptions: [{ label: 'Yes', value: true }, { label: 'No', value: false }],

    }
  },
  setup(props, { emit }) {
    /* eslint-disable camelcase */
    const name = ref('')
    const selectedDayOff = ref({ label: 'No', value: false })
    const date_start = ref('')
    const date_end = ref('')

    // const resetRegistrationData = () => {
    //   selectedDayOff.value = 'no'
    //   selectedDayOff.value = ''
    //   date_start.value = ''
    //   date_end.value = ''
    // }
    const onSubmit = () => {
      const eventData = {}
      eventData.name = name.value
      eventData.day_off = selectedDayOff.value.value
      eventData.date_start = date_start.value
      eventData.date_end = date_end.value

      store.dispatch('event/addEvent', eventData)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }
    // const blankUserData = {
    //   name: '',
    //   day_off: '',
    //   date_start: '',
    //   date_end: '',
    // }
    // const Data = ref(JSON.parse(JSON.stringify(blankUserData)))
    // const resetuserData = () => {
    //   Data.value = JSON.parse(JSON.stringify(blankUserData))
    // }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {})

    return {
      onSubmit,
      name,
      date_start,
      date_end,
      selectedDayOff,
      // eventData,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
