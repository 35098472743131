import {
  ref, watch, computed, onMounted,
} from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import store from '@/store'

export default function useUsersList() {
  // Use toast
  const toast = useToast()
  const { t } = useI18nUtils()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'selected', label: 'reg id', sortable: true },
    // { key: 'id', label: t('id'), sortable: false },
    { key: 'id', label: t('Student id'), sortable: false },
    { key: 'student', label: t('name'), sortable: true },
    { key: 'course_group', label: t('course'), sortable: true },
    // { key: 'visa', label: 'visa', sortable: true },
    { key: 'who_pays', label: t('payer'), sortable: true },
    { key: 'payment_method', label: ('payment method') },
    { key: 'payment_status', label: ('payment status') },
    { key: 'status', label: t('status'), sortable: true },
    { key: 'attendance', label: t('attendance') },
    { key: 'comment', label: t('notes') },
    { key: 'actions', label: t('actions'), tdClass: 'table-actions' },
  ]
  const tableColumns1 = [
    { key: 'selected', label: 'reg id', sortable: true },
    { key: 'id', label: t('Student id'), sortable: false },
    { key: 'student', label: t('name'), sortable: true },
    { key: 'course_group', label: t('group code'), sortable: true },
    // { key: 'visa', label: 'visa', sortable: true },
    // { key: 'who_pays', label: t('payer'), sortable: true },
    // { key: 'payer_confirmed', label: t('payer_confirmed') },
    { key: 'payment_method', label: ('payment method') },
    { key: 'payment_status', label: ('payment status') },
    { key: 'status', label: t('status'), sortable: true },
    { key: 'comment', label: t('notes') },
    { key: 'actions', label: t('actions'), tdClass: 'table-actions' },
  ]
  const tableColumns2 = [
    { key: 'selected', label: 'reg id', sortable: true },
    { key: 'id', label: t('id'), sortable: false },
    // { key: 'student', label: t('student'), sortable: true },
    { key: 'course', label: t('course'), sortable: true },
    // { key: 'visa', label: 'visa', sortable: true },
    { key: 'who_pays', label: t('payer'), sortable: true },
    // { key: 'payer_confirmed', label: t('payer_confirmed') },
    // { key: 'status', label: t('status'), sortable: true },
    { key: 'comment', label: t('notes') },
    { key: 'actions', label: t('actions'), tdClass: 'table-actions' },
  ]
  /* eslint-disable camelcase */
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const selectedRows = ref([])
  const courseId = ref(null)
  const group_id = ref(null)
  const student = ref(null)
  const course = ref(null)
  const student_id = ref(null)
  const date_range = ref(null)
  // const statuss = ref(null)
  const payer_confirmed = ref(null)
  const visa = ref(null)
  const who_pays = ref({ name: 'All payers', id: null })
  const payerOptions = ref([])
  const statusOptions = ref([
    { label: 'All', value: 'all' },
    { label: 'Confirmed', value: 'confirmed' },
    { label: 'Canceled', value: 'canceled' },
    { label: 'Pending', value: 'pending' },
    { label: 'Deleted', value: 'deleted' },
  ])
  const defaultCourse = ref({ label: 'All', value: null })
  const paymentFilter = ref({ name: 'All methods', id: null })
  const defaultStatus = ref({ name: 'All payment statuses', id: null })
  const regDefaultStatus = ref({ label: 'All registration statuses', value: 'all' })
  /* eslint-disable-next-line */
  const role_name = ref(window.location.href.split('/')[6])
  const isLoadingExcelDoc = ref(false)
  const selectAll = ref(false)
  const unselectedRows = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }
  /* eslint-disable-next-line */
  watch([currentPage, perPage, roleFilter, planFilter, statusFilter, role_name, student, course, regDefaultStatus, defaultStatus, paymentFilter, defaultCourse, payer_confirmed, visa, who_pays, student_id, date_range],  () => {
    refetchData()
  })
  watch(searchQuery, () => {
    refetchData()
  })
  const isBusy = ref(false)

  /* eslint-disable no-param-reassign, no-dupe-keys, no-shadow, prefer-destructuring */
  const fetchUsers = (ctx, callback) => {
    store
    // Called other registration store because to make one store for registrations to work on every list (courses, registrations...)
      .dispatch('registerStoreModule/fetchUsers', {
        search: searchQuery.value,
        perPage: 100,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role_name: roleFilter.value,
        plan: planFilter.value,
        // status: statusFilter.value,
        // single_course_id: courseId.value,
        group: group_id.value,
        // status: statusFilter.value,
        status: regDefaultStatus.value.value,
        student: student.value ? student.value : student_id.value,
        course: course.value,
        payment_status: defaultStatus.value.id,
        paid_method_id: paymentFilter.value.id,
        single_course_id: defaultCourse.value.value,
        visa: visa.value,
        payer_id: who_pays.value.id,
        date_start: date_range.value ? date_range.value.split(' ')[0] : null,
        date_end: date_range.value ? date_range.value.split(' ')[2] : null,
        payer_confirmed: payer_confirmed.value,
        // student: student_id.value,
        // role_name: role_name.value,
      })
      .then(() => {
        const users = store.state.registerStoreModule.registrations.rows
        const total = store.state.registerStoreModule.registrations.totalRecords
        if (selectedRows.value.length > 0) {
          selectedRows.value = selectedRows.value.map(row => {
            const matchedRow = users.find(u => u.id === row.id)
            return matchedRow || row
          })
        }
        if (selectAll.value) {
          users.forEach(el => {
            if (!selectedRows.value.some(row => row.id === el.id) && !unselectedRows.value.some(row => row.id === el.id)) selectedRows.value.push(el)
          })
        }
        // console.log(array.filter((val, idx) => array.indexOf(val) === idx))
        callback(users)
        totalUsers.value = total
        currentUser.value = JSON.parse(localStorage.getItem('userData'))
      })
      .catch(() => {
        isBusy.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching registrations list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const getExcelDoc = (student, course, date_range, payer_id, payment_status, paid_method_id, registration_status, selected, selectAll, unselected) => {
    isLoadingExcelDoc.value = true
    let date_start = null
    let date_end = null
    console.log(date_range)
    if (date_range) {
      date_start = date_range.split(' ')[0]
      date_end = date_range.split(' ')[0]
    }
    // console.log(student, course, date_start, date_end, who_pays, payment_status, paid_method_id)
    store.dispatch('registrations_/getExcelDoc', {
      student,
      course,
      date_start,
      date_end,
      payer_id,
      payment_status,
      paid_method_id,
      group: group_id.value,
      registration_status,
      selected: selected.length > 0 ? selected.map(el => el.id) : [],
      selectAll,
      unselected: unselected.length > 0 ? unselected.map(el => el.id) : [],
    }).then(() => {
      isLoadingExcelDoc.value = false
    }).catch(err => {
      toast({
        component: ToastificationContent,
        props: {
          title: err.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }
  const isBigModalActive = ref(false)
  const UsersToAdd = ref([])
  const UsersAlreadyAdded = ref([])
  const spinner = ref(false)
  const currentCourseData = ref(null)
  const msgs = ref([])
  // const cities = ref([])
  const masters = ref([])
  const teachers = ref([])
  const classrooms = ref([])
  const currentUser = ref({})
  const statusArray = ref([
    { name: 'Active', value: 'active', color: 'primary' },
    { name: 'Hidden', value: 'hidden', color: 'primary' },
    { name: 'Past', value: 'past', color: 'primary' },
    { name: 'Deleted', value: 'deleted', color: 'primary' },
    { name: 'Planned', value: 'planned', color: 'primary' },
  ])
  const TofDArray = ref([
    { name: 'daytime', value: 'daytime' },
    { name: 'anytime', value: 'anytime' },
    { name: 'evening', value: 'evening' },
  ])
  const regStatusOptions = ref([
    { label: 'All registration statuses', value: 'all' },
    { label: 'Confirmed', value: 'confirmed' },
    { label: 'Canceled', value: 'canceled' },
    { label: 'Pending', value: 'pending' },
    { label: 'Deleted', value: 'deleted' },
  ])
  const ModalData = (id, tab) => {
    store.dispatch('coursesStoreModule/getSCourse', id)
      .then(res => {
        currentCourseData.value = res.data.data
        currentCourseData.value.tab = tab
        const sDays = currentCourseData.value.days
        const daysArr = sDays.split(',')
        currentCourseData.value.days = daysArr
        if (currentCourseData.value.conversation === null) {
          msgs.value = []
          UsersToAdd.value = []
          UsersAlreadyAdded.value = []
        } else {
          if (typeof currentCourseData.value.conversation !== 'undefined') {
            msgs.value = currentCourseData.value.conversation.messages
          }
          UsersToAdd.value = currentCourseData.value.users_To_Add
          UsersAlreadyAdded.value = currentCourseData.value.users_Already_In
        }
        isBigModalActive.value = true
      }).catch(error => console.log(error))
  }
  // const fetchCities = () => {
  //   store.dispatch('registrations_/fetchCities')
  //     .then(res => {
  //       cities.value = res.data.rows
  //     })
  // }
  const fetchMCourses = () => {
    store.dispatch('registrations_/fetchMCourses')
      .then(res => {
        masters.value = res.data.rows
      })
  }
  const fetchTeachers = () => {
    store.dispatch('registrations_/fetchTeachers')
      .then(res => {
        teachers.value = res.data.rows
      })
  }
  const fetchClassrooms = () => {
    store.dispatch('registrations_/fetchClassrooms')
      .then(res => {
        classrooms.value = res.data.rows
      })
  }
  const suggestClassrooms = (record, Days) => {
    record.days = Days.join(',')
    store.dispatch('registrations_/suggestClassrooms', record)
      .then(res => {
        classrooms.value = res.data.available
      })
  }
  const suggestTeacher = (record, Days) => {
    /* eslint-disable no-param-reassign */
    record.days = Days.join(',')
    store.dispatch('registrations_/suggestTeacher', record)
      .then(res => {
        teachers.value = res.data.available
      })
  }
  const fetchPayers = () => {
    store.dispatch('registrations_/fetchPayers').then(res => {
      payerOptions.value = res.data.rows
      payerOptions.value.unshift({ name: 'All payers', id: null })
    })
  }
  // call on mounted
  onMounted(fetchMCourses)
  onMounted(fetchTeachers)
  onMounted(fetchClassrooms)
  onMounted(fetchPayers)
  // onMounted(fetchCities)

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'Paid') return 'success'
    if (status === 'Unpaid') return 'warning'
    if (status === 'Refunded') return 'danger'
    if (status === 'To be refunded') return 'danger'
    return 'secondary'
  }
  const resolveVisaStatusVariant = status => {
    if (status === false) return '<span class="badge text-capitalize badge-light-danger badge-pill">No visa</span>'
    if (status === true) return '<span class="badge text-capitalize badge-light-success badge-pill">Visa</span>'
    return '<span class="light-danger"></span>'
  }
  // const resolveStatusIcon = status => {
  //   if (status === 'Pending') return 'LoaderIcon'
  //   if (status === 'Active') return 'CheckCircleIcon'
  //   if (status === 'Deleted') return 'SlashIcon'
  //   return 'primary'
  // }
  const resolveInvoiceStatusVariantAndIcon = status => {
    if (status === 'pending') return { variant: 'warning', icon: 'LoaderIcon' }
    if (status === 'confirmed') return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === 'canceled') return { variant: 'danger', icon: 'SlashIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  return {
    fetchPayers,
    payerOptions,
    selectAll,
    unselectedRows,
    isBusy,
    getExcelDoc,
    date_range,
    isLoadingExcelDoc,
    paymentFilter,
    regStatusOptions,
    regDefaultStatus,
    fetchUsers,
    tableColumns,
    tableColumns1,
    tableColumns2,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    selectedRows,
    courseId,
    group_id,
    role_name,
    student,
    course,
    student_id,
    // statuss,
    payer_confirmed,
    visa,
    who_pays,
    statusOptions,
    defaultStatus,
    defaultCourse,

    // Course modal
    // fetchCities,
    fetchClassrooms,
    fetchTeachers,
    fetchMCourses,
    suggestClassrooms,
    suggestTeacher,
    isBigModalActive,
    UsersToAdd,
    UsersAlreadyAdded,
    spinner,
    currentCourseData,
    msgs,
    classrooms,
    teachers,
    masters,
    // cities,
    TofDArray,
    statusArray,
    currentUser,
    ModalData,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveVisaStatusVariant,
    refetchData,
    // resolveStatusIcon,
    resolveInvoiceStatusVariantAndIcon,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
