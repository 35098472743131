import { $themeColors } from '@themeConfig'

// colors
const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series3: '#a4f8cd',
    series2: '#60f2ca',
    series1: '#2bdac7',
  },
}

export default {
  totalHours: null,
  totalCurrentCourses: null,
  lineAreaChartSpline: {
    series: [
      {
        name: 'Current',
        data: [],
      },
      {
        name: 'Planned',
        data: [],
      },
      {
        name: 'Active',
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: false,
        curve: 'straight',
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'left',
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        // opposite: isRtl
      },
      fill: {
        opacity: 1,
        type: 'solid',
      },
      tooltip: {
        shared: false,
      },
      colors: [chartColors.area.series3, chartColors.area.series2, chartColors.area.series1],
    },
  },
  goalOverviewRadialBar: {
    series: [],
    chartOptions: {
      chart: {
        sparkline: {
          enabled: true,
        },
        dropShadow: {
          enabled: true,
          blur: 3,
          left: 1,
          top: 1,
          opacity: 0.1,
        },
      },
      colors: ['#51e5a8'],
      plotOptions: {
        radialBar: {
          offsetY: -10,
          startAngle: -150,
          endAngle: 150,
          hollow: {
            size: '77%',
          },
          track: {
            background: '#ebe9f1',
            strokeWidth: '50%',
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              color: '#5e5873',
              fontSize: '2.86rem',
              fontWeight: '600',
            },
          },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: [$themeColors.success],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: 'round',
      },
      grid: {
        padding: {
          bottom: 30,
        },
      },
    },
  },
  customersPie: {
    series: [],
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      labels: ['New', 'Returning'],
      dataLabels: {
        enabled: false,
        formatter(val) {
          return `${val}%`
        },
      },
      legend: { show: false },
      stroke: {
        width: 4,
      },
      colors: [$themeColors.success, $themeColors.warning, $themeColors.secondary, $themeColors.danger, $themeColors.primary],
    },
  },
  sessionsByDeviceDonut: {
    series: [],
    chartOptions: {
      chart: {
        type: 'donut',
        // toolbar: {
        //   show: false,
        // },
      },
      labels: ['Eligible', 'Not Eligible'],
      dataLabels: {
        enabled: false,
        formatter(val) {
          return `${val}%`
        },
      },
      legend: { show: false },
      // comparedResult: [2, -3, 8],
      stroke: { width: 0 },
      colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger, $themeColors.secondary],
    },
  },
  revenueComparisonLine: {
    series: [
      {
        name: 'This Period',
        data: [],
      },
      {
        name: 'Last Period',
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        toolbar: { show: false },
        zoom: { enabled: false },
        type: 'line',
        offsetX: -10,
      },
      stroke: {
        curve: 'smooth',
        dashArray: [0, 12],
        width: [4, 3],
      },
      legend: {
        show: false,
      },
      colors: ['#d0ccff', '#ebe9f1'],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          inverseColors: false,
          gradientToColors: [$themeColors.primary, '#ebe9f1'],
          shadeIntensity: 1,
          type: 'horizontal',
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100],
        },
      },
      markers: {
        size: 0,
        hover: {
          size: 5,
        },
      },
      xaxis: {
        labels: {
          style: {
            colors: '#b9b9c3',
            fontSize: '1rem',
          },
        },
        axisTicks: {
          show: false,
        },
        categories: ['01', '05', '09', '13', '17', '21', '26', '31'],
        axisBorder: {
          show: false,
        },
        tickPlacement: 'on',
      },
      yaxis: {
        tickAmount: 5,
        labels: {
          style: {
            colors: '#b9b9c3',
            fontSize: '1rem',
          },
          formatter(val) {
            return val > 999 ? `${(val / 1000).toFixed(0)}k` : val
          },
        },
      },
      grid: {
        borderColor: '#e7eef7',
        padding: {
          top: -20,
          bottom: -10,
          left: 20,
        },
      },
      tooltip: {
        x: { show: false },
      },
    },
  },
  lineChartSimple: {
    series: [
      {
        name: 'Courses',
        data: [],
      },
      {
        name: 'Sessions',
        data: [],
      },
      {
        name: 'Hours',
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      markers: {
        strokeWidth: 7,
        strokeOpacity: 1,
        strokeColors: [$themeColors.light],
        colors: [$themeColors.warning, $themeColors.secondary, $themeColors.primary],
      },
      colors: [$themeColors.warning, $themeColors.secondary, $themeColors.primary],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      tooltip: {
        custom(data) {
          return `${'<div class="px-1 py-50"><span>'}${
            data.series[data.seriesIndex][data.dataPointIndex]
          }%</span></div>`
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        // opposite: isRtl,
      },
    },
  },

  columnChart: {
    series: [
      {
        name: 'Attendance',
        data: [],
      },
      {
        name: 'Free Spots',
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      colors: [chartColors.column.series1, chartColors.column.series2],
      plotOptions: {
        bar: {
          columnWidth: '15%',
          colors: {
            backgroundBarColors: [
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
            ],
            backgroundBarRadius: 10,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: 'top',
        fontSize: '14px',
        fontFamily: 'Montserrat',
        horizontalAlign: 'left',
      },
      stroke: {
        show: true,
        colors: ['transparent'],
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        // opposite: isRtl,
      },
      fill: {
        opacity: 1,
      },
    },
  },
  columnChart1: {
    series: [
      {
        name: 'Hours',
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      colors: [chartColors.column.series1, chartColors.column.series2],
      plotOptions: {
        bar: {
          columnWidth: '15%',
          horizontal: true,
          colors: {
            backgroundBarColors: [
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
            ],
            backgroundBarRadius: 10,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: 'top',
        fontSize: '14px',
        fontFamily: 'Montserrat',
        horizontalAlign: 'left',
      },
      stroke: {
        show: true,
        colors: ['transparent'],
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        // opposite: isRtl,
      },
      fill: {
        opacity: 1,
      },
    },
  },
  barChart2: {
    series: [
      {
        name: 'Courses',
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      colors: $themeColors.info,
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '30%',
          endingShape: 'rounded',
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        // opposite: isRtl,
      },
    },
  },

  donutChart: {
    series: [],
    chartOptions: {
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      colors: [
        chartColors.donut.series1,
        chartColors.donut.series5,
        chartColors.donut.series3,
        chartColors.donut.series2,
      ],
      dataLabels: {
        enabled: true,
        formatter(val) {
          // eslint-disable-next-line radix
          return `${parseInt(val)}%`
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontSize: '2rem',
                fontFamily: 'Montserrat',
              },
              value: {
                fontSize: '1rem',
                fontFamily: 'Montserrat',
                formatter(val) {
                  // eslint-disable-next-line radix
                  return `${parseInt(val)}%`
                },
              },
              // total: {
              //   show: true,
              //   fontSize: '1.5rem',
              //   label: 'Morning',
              //   formatter() {
              //     return '31%'
              //   },
              // },
            },
          },
        },
      },
      labels: ['Morning', 'Afternoon', 'Evening'],
      responsive: [
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 380,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            chart: {
              height: 320,
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      fontSize: '1.5rem',
                    },
                    value: {
                      fontSize: '1rem',
                    },
                    total: {
                      fontSize: '1.5rem',
                    },
                  },
                },
              },
            },
            legend: {
              show: true,
            },
          },
        },
      ],
    },
  },
  radarChart: {
    series: [
      {
        // name: 'Samsung s20',
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: false,
          blur: 8,
          left: 1,
          top: 1,
          opacity: 0.2,
        },
      },
      legend: {
        show: true,
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      yaxis: {
        show: false,
      },
      colors: [chartColors.donut.series3],
      xaxis: {
        categories: [],
      },
      fill: {
        opacity: [1, 0.8],
      },
      stroke: {
        show: false,
        width: 0,
      },
      markers: {
        size: 0,
      },
      grid: {
        show: false,
      },
    },
  },
  revenueReport: {
    series: [
      {
        name: 'Substitutes',
        data: [],
      },
      {
        name: 'Off',
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        stacked: true,
        type: 'bar',
        toolbar: { show: false },
      },
      grid: {
        padding: {
          top: -20,
          bottom: -10,
        },
        yaxis: {
          lines: { show: false },
        },
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            colors: '#6E6B7B',
            fontSize: '0.86rem',
            fontFamily: 'Montserrat',
          },
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      colors: [$themeColors.primary, $themeColors.warning],
      plotOptions: {
        bar: {
          columnWidth: '17%',
          endingShape: 'rounded',
        },
        distributed: true,
      },
      yaxis: {
        labels: {
          style: {
            colors: '#6E6B7B',
            fontSize: '0.86rem',
            fontFamily: 'Montserrat',
          },
        },
      },
    },
  },
  revenueReport1: {
    series: [
      {
        name: 'Last period',
        data: [],
      },
      {
        name: 'This period',
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        stacked: true,
        type: 'bar',
        toolbar: { show: false },
      },
      grid: {
        padding: {
          top: -20,
          bottom: -10,
        },
        yaxis: {
          lines: { show: false },
        },
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            colors: '#6E6B7B',
            fontSize: '0.86rem',
            fontFamily: 'Montserrat',
          },
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      colors: [$themeColors.primary, $themeColors.warning],
      plotOptions: {
        bar: {
          columnWidth: '17%',
          endingShape: 'rounded',
        },
        distributed: true,
      },
      yaxis: {
        labels: {
          style: {
            colors: '#6E6B7B',
            fontSize: '0.86rem',
            fontFamily: 'Montserrat',
          },
        },
      },
    },
  },
  // budget chart
  budgetChart: {
    series: [
      {
        data: [],
      },
    ],
    options: {
      chart: {
        height: 80,
        toolbar: { show: false },
        zoom: { enabled: false },
        type: 'line',
        sparkline: { enabled: true },
      },
      stroke: {
        curve: 'smooth',
        dashArray: [0, 5],
        width: [2],
      },
      colors: [$themeColors.primary, '#dcdae3'],
      tooltip: {
        enabled: false,
      },
    },
  },
}
