<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <!-- bio -->
        <b-col cols="12">
          <b-form-group
            label="Bio"
            label-for="bio-area"
          >
            <b-form-textarea
              id="bio-area"
              v-model="localOptions.bio"
              rows="4"
              placeholder="Your bio data here..."
              maxlength="500"
            />
          </b-form-group>
        </b-col>
        <!--/ bio -->
        <!-- Phone -->
        <b-col md="4">
          <b-form-group
            label-for="phone"
            :label="$t('settings.information.Phone')"
          >
            <b-form-input
              id="mobile"
              v-model="localOptions.phone"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43"
            />
            <!-- <cleave
              id="phone"
              v-model="localOptions.phone"
              class="form-control"
              :raw="false"
              :options="clevePhone"
              placeholder="Phone number"
            /> -->
          </b-form-group>
        </b-col>
        <!--/ Phone -->
        <!-- birth date -->
        <b-col md="4">
          <b-form-group
            label-for="example-datepicker"
            :label="$t('settings.information.Birth date')"
          >
            <flat-pickr
              v-model="localOptions.dob"
              class="form-control"
              name="date"
              placeholder="Birth date"
              :config="{ altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 }}"
            />
          </b-form-group>
        </b-col>
        <!--/ birth date -->
        <!-- phone -->
        <!-- birth Pronoun -->
        <b-col md="4">
          <b-form-group
            label-for="pronoun"
            :label="('Gender')"
          >
            <v-select
              v-model="localOptions.gender"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="label"
              :options="pronounOptions"
            />
          </b-form-group>
        </b-col>
        <!-- Nationality -->
        <b-col md="6">
          <b-form-group
            label-for="nationality"
            label="Nationality"
          >
            <v-select
              id="countryList"
              v-model="localOptions.nationality"
              :reduce="x => x.nationality"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="nationality"
              :options="countryOptions"
            />
          </b-form-group>
        </b-col>
        <!--/ Nationality -->
        <!-- Country -->
        <b-col md="6">
          <b-form-group
            label-for="countryList"
            :label="$t('settings.information.Country')"
          >
            <v-select
              id="countryList"
              v-model="localOptions.country"
              :reduce="x => x.name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="countryOptions"
            />
          </b-form-group>
        </b-col>
        <!--/ Country -->

        <!-- City -->
        <b-col md="6">
          <b-form-group
            :label="('Postcode - City')"
            label-for="city-postcode"
          >
            <div class="d-flex justify-content-start">
              <b-form-input
                id="postcode"
                v-model="localOptions.postcode"
                style="width: 30%"
                class="mr-25"
              />
              <b-form-input
                id="city"
                v-model="localOptions.city"
                placeholder="City"
                style="width: 70%"
              />
            </div>
          </b-form-group>
        </b-col>
        <!--/ City -->

        <!-- Address -->
        <b-col md="6">
          <b-form-group
            label-for="Address"
            label="address"
          >
            <b-form-input
              id="address"
              v-model="localOptions.address"
              placeholder="Address"
            />
          </b-form-group>
        </b-col>
        <!--/ City -->

        <!-- website -->
        <!-- <b-col md="6">
          <b-form-group
            label-for="website"
            label="Website"
          >
            <b-form-input
              id="website"
              v-model="localOptions.website"
              placeholder="Website address"
            />
          </b-form-group>
        </b-col> -->
        <!--/ website -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click.prevent="save()"
          >
            {{ $t('settings.Save Changes') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
// import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line import/no-extraneous-dependencies
// import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
    // Cleave,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      countryOptions: [],
      pronounOptions: [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
        { label: 'Other', value: 'other' },
      ],
      localOptions: JSON.parse(JSON.stringify(this.informationData)),
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
    }
  },
  mounted() {
    this.$http.get('/countries/list?perPage=600').then(res => { this.countryOptions = res.data.rows })
    this.localOptions.gender = this.pronounOptions.find(el => el.value === this.localOptions.gender)
  },
  methods: {
    save() {
      const body = {
        bio: this.localOptions.bio,
        dob: this.localOptions.dob,
        country: this.localOptions.country,
        nationality: this.localOptions.nationality,
        city: this.localOptions.city,
        address: this.localOptions.address,
        // website: this.localOptions.website,
        phone: this.localOptions.phone,
        gender: this.localOptions.gender ? this.localOptions.gender.value : null,
        postcode: this.localOptions.postcode,
        // pronoun: this.localOptions.pronoun ? this.localOptions.pronoun.value : null,
      }
      this.$http.post('/account-setting/info', body, {
        headers: {
          Accept: 'application/json',
        },
      }).then(response => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            text: response.data.message,
            icon: 'CheckIcon',
          },
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            text: error.response.data.message,
            icon: 'AlertTriangleIcon',
          },
        })
        this.$refs.generalData.setErrors(error.response.data.errors)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
