<template>
  <div>
    <div class="mb-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-modal.modal-1
        variant="primary"
      >
        <feather-icon
          icon="CalendarIcon"
          class="mr-50"
        />
        Add event
      </b-button>
    </div>
    <div class="card app-calendar overflow-hidden border daysoff-calendar">
      <div
        :class="isBusyCalendar ? 'spinnerr-expand mt-2' : 'spinnerr'"
        class="text-center bg-transparent mt-2"
      >
        <b-spinner
          variant="primary"
          label="Text Centered"
        />
        <strong class="ml-1 pt-1">{{ $t('Loading...') }}</strong>
      </div>
      <div class="row no-gutters">
        <!-- Sidebar -->

        <!-- Calendar -->
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                :key="refreshKey"
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
              />
            </div>
            <course-table
              v-if="isEventModalActive && selected.name !== 'All'"
              v-model="isEventModalActive"
              @hide-modal="isEventModalActive = false"
            />
          </div>
        </div>
        <b-modal
          id="modal-1"
          ref="modal-1"
          title="Add event"
          size="lg"
          hide-footer
          no-close-on-backdrop
        >
          <add-event
            @hide-modal="hideModal"
            @event-add="eventAdd"
          />
        </b-modal>

        <!-- Sidebar Overlay -->
        <div
          class="body-content-overlay"
          :class="{'show': isCalendarOverlaySidebarActive}"
          @click="isCalendarOverlaySidebarActive = false"
        />
        <!-- <calendar-event-handler
          v-model="isEventHandlerSidebarActive"
          :event="event"
          :clear-event-data="clearEventData"
          @remove-event="removeEvent"
          @add-event="addEvent"
          @update-event="updateEvent"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable-next-line */
import { BButton, BModal, VBModal, BSpinner } from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import calendarStoreModule from './calendarStoreModule'
// import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
// import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import AddEvent from './calendar-event-handler/AddEvent.vue'
import useCalendar from './useCalendar'

export default {
  components: {
    BButton,
    BModal,
    FullCalendar, // make the <FullCalendar> tag available
    // CalendarSidebar,
    BSpinner,
    // CalendarEventHandler,
    AddEvent,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      selected: { name: 'All' },
    }
  },
  methods: {
    hideModal() {
      this.$refs['modal-1'].hide()
    },
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,
      getClassrooms,
      classroomOptions,
      selectedClassroom,
      classroom_id,

      isBusyCalendar,

      eventAdd,
      refreshKey,
      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventModalActive,
      isModalActive,
    } = useCalendar()

    fetchEvents()
    getClassrooms()

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,
      selectedClassroom,
      classroom_id,
      getClassrooms,

      isBusyCalendar,

      eventAdd,
      refreshKey,

      classroomOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventModalActive,
      isModalActive,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
  .daysoff-calendar .fc-event-title.fc-sticky {
    color: #2d2d2d;
  }
  .daysoff-calendar .fc-event-time {
    color: #2a2e30;
    font-weight: bolder;
  }
</style>
