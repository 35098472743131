export default [
  {
    title: 'Teacher',
    icon: 'UserIcon',
    noCollapsible: true,
    children: [
      {
        title: 'My courses',
        route: 'teacher-list-courses',
        icon: 'BookIcon',
        roles: ['teacher'],
      },
      {
        title: 'Attendance list',
        route: 'teacher-attendance',
        icon: 'ClipboardIcon',
        roles: ['teacher'],
      },
      {
        title: 'Courses to teach',
        route: 'teacher-list-all-courses',
        icon: 'BookIcon',
        roles: ['teacher'],
      },
      {
        title: 'Course Substitution',
        route: 'teacher-courses-substitute',
        icon: 'BookIcon',
        roles: ['teacher'],
      },
      {
        title: 'My calendar',
        route: 'teacher-teacher-calendar',
        icon: 'ClipboardIcon',
        roles: ['teacher'],
      },
      {
        title: 'Statistics',
        route: 'teacher-statistics',
        icon: 'MonitorIcon',
        roles: ['teacher'],
      },
    ],
  },
]
