<template>
  <b-modal
    id="master-course-modal"
    :title="`${action} Master course`"
    centered
    size="lg"
    hide-footer
    no-close-on-backdrop
    @hidden="resetForm()"
  >
    <validation-observer
      v-slot="{ handleSubmit }"
    >
      <b-form
        :key="errorMessageRefreshKey"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <b-row>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group
                label="Name"
                label-for="name"
              >
                <b-form-input
                  v-model="name"
                  placeholder="Name"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <small
                  v-if="errors && errors.name"
                  class="text-danger"
                >{{ errors.name[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="code"
              rules="required"
            >
              <b-form-group
                :formatter="formatter(code)"
                label="Code"
                label-for="code"
              >
                <b-form-input
                  v-model="code"
                  placeholder="Code"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <small
                  v-if="errors && errors.code"
                  class="text-danger"
                >{{ errors.code[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="code"
              rules="required"
            >
              <b-form-group
                label="Classroom type"
                label-for="classroom-type"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="classroomType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Classroom type"
                  :reduce="x => x.value"
                  :options="classroomTypes"
                  :clearable="false"
                  :loading="isBusy"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <small
                  v-if="errors && errors.classroom_type"
                  class="text-danger"
                >{{ errors.classroom_type[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="Session type"
            >
              <b-form-group
                label="Session type"
                label-for="session-type"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="sessionType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Session type"
                  :options="sessionTypes"
                  :reduce="x => x.value"
                  :loading="isBusy"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <small
                  v-if="errors && errors.session_type"
                  class="text-danger"
                >{{ errors.session_type[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="Level"
              rules="required"
            >
              <b-form-group
                label="Level"
                label-for="level"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="level"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Level"
                  :reduce="x => x.value"
                  :options="levels"
                  :clearable="false"
                  :loading="isBusy"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <small
                  v-if="errors && errors.level"
                  class="text-danger"
                >{{ errors.level[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="CEFR"
            >
              <b-form-group
                label="CEFR"
                label-for="CEFR"
              >
                <b-form-input
                  v-model="CEFR"
                  placeholder="CEFR"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <small
                  v-if="errors && errors.cefr"
                  class="text-danger"
                >{{ errors.cefr[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="Parent Master course"
            >
              <b-form-group
                label="Parent Master course"
                label-for="parent-mc"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="parentMC"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="code"
                  :reduce="x => x.id"
                  placeholder="Parent Master course"
                  :options="mastercourses.filter(el => el.id !== nextMC && el.id !== mastercourseId)"
                  :loading="isLoadingMcourses"
                >
                  <template #option="{ code, level, classroom_type }">
                    <div class="d-flex align-items-center justify-content-between">
                      <span
                        v-if="code"
                        class="ml-1"
                      >{{ code }}
                        <small
                          v-if="level"
                          class="text-muted text-capitalize"
                        >({{ level }})</small>
                      </span>
                      <b-badge
                        v-if="classroom_type"
                        :variant="classroom_type === 'classroom' ? 'secondary' : 'success'"
                        class="text-capitalize"
                      >
                        {{ classroom_type }}
                      </b-badge>
                    </div>
                  </template>
                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <small
                  v-if="errors && errors.parent_master_course_id"
                  class="text-danger"
                >{{ errors.parent_master_course_id[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="Next Master course"
            >
              <b-form-group
                label="Next Master course"
                label-for="next-mc"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="nextMC"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Next Master course"
                  label="code"
                  :reduce="x => x.id"
                  :options="mastercourses.filter(el => el.id !== parentMC && el.id !== mastercourseId)"
                  :loading="isLoadingMcourses"
                >
                  <template #option="{ code, level, classroom_type }">
                    <div class="d-flex align-items-center justify-content-between">
                      <span
                        v-if="code"
                        class="ml-1"
                      >{{ code }}
                        <small
                          v-if="level"
                          class="text-muted text-capitalize"
                        >({{ level }})</small>
                      </span>
                      <b-badge
                        v-if="classroom_type"
                        :variant="classroom_type === 'classroom' ? 'secondary' : 'success'"
                        class="text-capitalize"
                      >
                        {{ classroom_type }}
                      </b-badge>
                    </div>
                  </template>
                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <small
                  v-if="errors && errors.next_master_course_id"
                  class="text-danger"
                >{{ errors.next_master_course_id[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="4">
            <validation-provider
              #default="validationContext"
              name="Sessions count"
              rules="required"
            >
              <b-form-group
                label="Sessions count"
                label-for="sessions-count"
              >
                <b-form-input
                  v-model="sessionsCount"
                  placeholder="Sessions count"
                  type="number"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <small
                  v-if="errors && errors.sessions"
                  class="text-danger"
                >{{ errors.sessions[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="4">
            <validation-provider
              #default="validationContext"
              name="Total hours"
              rules="required"
            >
              <b-form-group
                label="Total hours"
                label-for="total-hours"
              >
                <b-form-input
                  v-model="totalHours"
                  placeholder="Total hours"
                  type="number"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <small
                  v-if="errors && errors.hours"
                  class="text-danger"
                >{{ errors.hours[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="4">
            <validation-provider
              #default="validationContext"
              name="Price"
              rules="required"
            >
              <b-form-group
                label="Price"
                label-for="price"
              >
                <b-form-input
                  v-model="price"
                  placeholder="Sessions count"
                  type="number"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <small
                  v-if="errors && errors.price"
                  class="text-danger"
                >{{ errors.price[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <div class="mt-3 d-flex align-items-center justify-content-end">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="mt-50"
            @click="resetForm"
          >
            Cancel
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="mt-50 ml-1"
            type="submit"
          >
            {{ `${action} Master course` }}
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import {
  BModal, BForm, BRow, BCol, BFormGroup, BFormInvalidFeedback, BFormInput, BButton, BBadge,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    vSelect,
    BButton,
    BBadge,
    BModal,
    BForm,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    action: {
      type: String,
      required: true,
    },
    mastercourseId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      required,
      id: null,
      name: null,
      code: null,
      classroomType: null,
      sessionType: null,
      sessionsCount: null,
      totalHours: null,
      level: 'beginner',
      CEFR: null,
      parentMC: null,
      nextMC: null,
      price: null,

      // Options
      classroomTypes: [],
      sessionTypes: [],
      levels: [],
      mastercourses: [],

      errors: {},
      errorMessageRefreshKey: 0,
      isBusy: false,
      isLoadingMcourses: false,
    }
  },
  created() {
    this.isBusy = true
    this.isLoadingMcourses = true
    this.$http.get('/mastercourse/options').then(res => {
      this.isBusy = false
      this.levels = res.data.levels && res.data.levels.length > 0 ? res.data.levels.map(str => {
        return {
          label: str.charAt(0).toUpperCase() + str.slice(1),
          value: str,
        }
      }) : []
      this.sessionTypes = res.data.session_types && res.data.session_types.length > 0 ? res.data.session_types.map(str => {
        return {
          label: str.charAt(0).toUpperCase() + str.slice(1),
          value: str,
        }
      }) : []
      this.classroomTypes = res.data.classroom_types && res.data.classroom_types.length > 0 ? res.data.classroom_types.map(str => {
        return {
          label: str.charAt(0).toUpperCase() + str.slice(1),
          value: str,
        }
      }) : []
    })
    store.dispatch('coursesStoreModule/fetchMCourses', { group: null, perPage: 300 })
    .then(() => {
      this.isLoadingMcourses = false
      this.mastercourses = store.state.coursesStoreModule.Mcourses.rows
      if (this.action === 'Edit') {
        store.dispatch('coursesStoreModule/getSMCourse', this.mastercourseId)
        .then(() => {
          const data = store.state.coursesStoreModule.singleMasterCourse.single
          this.id = data.id
          this.name = data.name ? data.name.en : data.name
          this.code = data.code
          this.classroomType = data.classroom_type
          this.sessionType = data.session_type
          this.sessionsCount = data.sessions
          this.totalHours = data.hours
          this.level = data.level
          this.CEFR = data.cefr
          this.parentMC = data.parent_master_course_id
          this.nextMC = data.next_master_course_id
          this.price = data.price
        })
      }
    })
  },
  methods: {
    onSubmit() {
      const payload = {
        id: this.id,
        name: { en: this.name },
        code: this.code,
        classroom_type: this.classroomType,
        session_type: this.sessionType,
        sessions: this.sessionsCount,
        hours: this.totalHours,
        level: this.level,
        cefr: this.CEFR,
        parent_master_course_id: this.parentMC,
        next_master_course_id: this.nextMC,
        price: this.price,
      }
      const actionType = this.action === 'Add' ? 'AddNewM' : 'updateMCourse'
      store.dispatch(`coursesStoreModule/${actionType}`, payload)
      .then(res => {
        this.$bvModal.hide('master-course-modal')
        this.resetForm()
        this.$emit('refetch-data')
        this.$toast({
            component: ToastificationContent,
            props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
            },
        })
      }).catch(error => {
        if (error) {
          this.$toast({
              component: ToastificationContent,
              props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.handleBackendValidatiob(error.response.data.errors)
        }
      })
    },
    formatter(input) {
      if (this.code) this.code = input.toUpperCase()
    },
    resetForm() {
      this.$emit('reset-modal')
      this.id = null
      this.name = null
      this.code = null
      this.classroomType = null
      this.sessionType = null
      this.sessionsCount = null
      this.totalHours = null
      this.level = 'beginner'
      this.CEFR = null
      this.parentMC = null
      this.nextMC = null
      this.price = null
    },
    handleBackendValidatiob(err) {
      // console.log(!!err)
      const fields = [
        'name', 'code', 'classroom_type', 'session_type', 'sessions', 'hours',
        'level', 'cefr', 'parent_master_course_id', 'next_master_course_id', 'price',
      ]
      if (err) {
        fields.forEach(field => {
          this.errors[field] = err[field] || []
        })
        setTimeout(() => {
          this.errorMessageRefreshKey += 1
        }, 100)
      }
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(() => {})
    return {
      refFormObserver,
      getValidationState,
    }
  },
}
</script>
