<template>
  <div>
    <div class="card app-calendar overflow-hidden border teacher-c-daysoff">
      <div
        :class="isBusyCalendar ? 'spinnerr-expand mt-2' : 'spinnerr'"
        class="text-center bg-transparent mt-3"
      >
        <b-spinner
          variant="primary"
          label="Text Centered"
        />
        <strong class="ml-1 pt-1">{{ $t('Loading...') }}</strong>
      </div>
      <div class="row no-gutters">
        <!-- Sidebar -->
        <div
          class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
          :class="{'show': isCalendarOverlaySidebarActive}"
        >
          <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" />
        </div>

        <!-- Calendar -->
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
              />
            </div>
          </div>
        </div>
        <b-modal
          id="modal-1"
          ref="modal-2"
          v-model="isSubstituteModalActive"
          size="lg"
          centered
          title="Request a substitution"
          hide-footer
          class="suggets-teacher-modal big-modal-abdelatif"
        >
          <add-substitute
            :session-id="substituteData.extendedProps.session_id"
            @hide-modal="isSubstituteModalActive = false"
            :date-start="formatDateTime(substituteData.start)"
            :title="substituteData.title"
          />
        </b-modal>
        <!-- <b-modal
          id="modal-9"
          size="xl"
          centered
          title="Find a substitute teacher"
          hide-footer
        >
          <find-substitute
            :teacher-id="teacherId"
            @hide-modal="$bvModal.hide('modal-9')"
          />
        </b-modal> -->
        <!-- Sidebar Overlay -->
        <div
          class="body-content-overlay"
          :class="{'show': isCalendarOverlaySidebarActive}"
          @click="isCalendarOverlaySidebarActive = false"
        />
        <calendar-event-handler
          v-model="isEventHandlerSidebarActive"
          :event="event"
          :clear-event-data="clearEventData"
          @remove-event="removeEvent"
          @add-event="addEvent"
          @update-event="updateEvent"
          @show-session-substitute-modal="showSessionSubstituteModal"
        />
          <!-- @show-substitute-modal="showSubstituteModal" -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline, camelcase */
import { VBModal, BSpinner } from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import calendarStoreModule from './calendarStoreModule'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import AddSubstitute from './calendar-event-handler/AddSubstitute.vue'
// import FindSubstitute from '../../admin/teacher-calendar/calendar-event-handler/AddSubstitute.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
// import CourseTable from './calendar-event-handler/CourseTable.vue'
import useCalendar from './useCalendar'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    AddSubstitute,
    CalendarEventHandler,
    // FindSubstitute,
    BSpinner,
    // CourseTable,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      query: '',
      dateStart: '',
      dateEnd: '',
      sessionId: null,
      teacherId: null,
    }
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.teacherId = userData.id
  },
  methods: {
    hideModal() {
      this.$refs['modal-2'].hide()
    },
    // showSubstituteModal(dateStart, dateEnd, sessionId) {
    //   this.dateStart = dateStart
    //   this.dateEnd = dateEnd
    //   this.sessionId = sessionId
    //   this.$bvModal.show('modal-9')
    // },
    showSessionSubstituteModal(sessionId) {
      this.sessionId = sessionId
      this.$bvModal.show('modal-1')
    },
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      substituteData,
      formatDateTime,
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,
      teachersOptions,
      selectedTeacher,
      levelsOptions,
      selectedClassroom,
      teacher_id,

      isBusyCalendar,
      // ----- UI ----- //
      isSubstituteModalActive,
      isEventHandlerSidebarActive,
      isEventModalActive,
      isModalActive,
    } = useCalendar()

    fetchEvents()
    const autoComplete = (search, role_name) => {
      store.dispatch('calendar/teacherAutoComplete', { search, role_name })
      .then(res => {
        teachersOptions.value = []
        teachersOptions.value = res.data
      })
    }
    const getTeacherId = teacherId => {
      teacher_id.value = teacherId
    }

    return {
      substituteData,
      formatDateTime,
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,
      levelsOptions,
      selectedTeacher,
      selectedClassroom,
      teacher_id,

      isBusyCalendar,

      autoComplete,
      getTeacherId,

      teachersOptions,

      // ----- UI ----- //
      isSubstituteModalActive,
      isEventHandlerSidebarActive,
      isEventModalActive,
      isModalActive,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
.teacher-c-daysoff .fc-event-main {
  color: #3b4253!important;
  text-transform: capitalize;
}
.teacher-c-daysoff .fc-event-main .fc-event-time {
  display: none;
}
.teacher-c-daysoff .fc-h-event {
  border: 1px solid #ff9f43!important;
  border: 1px solid var(--fc-event-border-color, #ff9f43)!important;
  background-color: #ff9f43!important;
  background-color: var(--fc-event-bg-color, #ff9f43)!important;
}
.teacher-c-daysoff .fc-h-event {
  cursor: auto;
}
.teacher-c-daysoff .bg-light-warning a{
  cursor: auto;
}
</style>
