import { ref, watch, computed } from '@vue/composition-api'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'date_start', label: 'course', sortable: true },
    { key: 'classroom_type', label: 'type', sortable: true },
    { key: 'days', label: 'days & time' },
    { key: 'price', label: 'price', sortable: true },
    { key: 'action', label: '' },
    // { key: 'hours', sortable: true },
    // { key: 'actions' },
  ]
  /* eslint-disable object-curly-spacing, camelcase */
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('date_start')
  const isSortDirDesc = ref(false)
  const timeOfDayFilter = ref('anytime')
  const classroomTypeFilter = ref(null)
  const sessionTypeFilter = ref(null)
  const monthFilter = ref(null)
  const levelFilter = ref(null)
  // const time_of_day = ref(window.location.href.split('/')[6])
  if (router.currentRoute.query.day_part) timeOfDayFilter.value = router.currentRoute.query.day_part
  if (router.currentRoute.query.classroom_type) classroomTypeFilter.value = router.currentRoute.query.classroom_type
  if (router.currentRoute.query.session_type) sessionTypeFilter.value = router.currentRoute.query.session_type
  if (router.currentRoute.query.level) levelFilter.value = router.currentRoute.query.level
  if (router.currentRoute.query.month) monthFilter.value = router.currentRoute.query.month
  if (router.currentRoute.query.q) searchQuery.value = router.currentRoute.query.q

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }
  watch([currentPage, perPage, searchQuery, timeOfDayFilter, classroomTypeFilter, sessionTypeFilter, monthFilter, levelFilter], () => {
    router.push({
      query:
        {
          day_part: timeOfDayFilter.value,
          classroom_type: classroomTypeFilter.value,
          session_type: sessionTypeFilter.value,
          month: monthFilter.value,
          level: levelFilter.value,
          search: searchQuery.value,
        },
    }).catch(() => {})
    setTimeout(() => {
      refetchData()
    }, 500)
  })
  const isBusy = ref(false)

  const fetchCourses = (ctx, callback) => {
    store
      .dispatch('app-course/fetchCourses', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        time_of_day: timeOfDayFilter.value,
        classroom_type: classroomTypeFilter.value,
        session_type: sessionTypeFilter.value,
        yearMonth: monthFilter.value,
        rank: levelFilter.value,
        // role_name: role_name.value,
      })
      .then(() => {
        const users = store.state.courseStoreModule.courses.rows
        const total = store.state.courseStoreModule.courses.totalRecords
        callback(users)
        totalUsers.value = total
      })
      .catch(() => {
        isBusy.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    isBusy,
    fetchCourses,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    // time_of_day,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    timeOfDayFilter,
    classroomTypeFilter,
    sessionTypeFilter,
    monthFilter,
    levelFilter,
  }
}
