<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <!-- title and subtitle -->
        <div>
          <b-card-title class="mb-1">
            New Courses
          </b-card-title>
          <b-card-sub-title>Sessions Vs Courses Vs Hours Creation</b-card-sub-title>
        </div>
        <!--/ title and subtitle -->
      </b-card-header>

      <b-card-body>
        <vue-apex-charts
          type="line"
          height="400"
          :options="lineChartSimple.chartOptions"
          :series="lineChartSimple.series"
        />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
// import apexChatData from '../apexChartData'

export default {
  components: {
    VueApexCharts,

    BCardHeader,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    lineChartSimple: {
      type: Object,
      required: true,
    },
  },
  // data() {
  //   return {
  //     apexChatData,
  //   }
  // },
}
</script>
