<template>
  <section class="container">
    <!-- <h5>
        {{ sessionData.session.course_name }} ({{ sessionData.session.group }})
      </h5> -->
    <courses-list-big-modal
      v-if="isBigModalActive"
      :isBigModalActive.sync="isBigModalActive"
      :classrooms="classrooms"
      :teachers="teachers"
      :masters="masters.rows"
      :TofDArray="TofDArray"
      :currentCourseData="currentCourseData"
      :msgs="msgs"
      :statusArray="statusArray"
      :UsersToAdd="UsersToAdd"
      :UsersAlreadyAdded="UsersAlreadyAdded"
      :currentUser="currentUser"
      :loading="spinner"
      @initilize="ModalData($event)"
      @loading="loadSpinner"
      @suggest-classrooms="suggestClassrooms"
      @suggest-teacher="suggestTeacher"
    />
      <!-- :cities="cities" -->
    <div class="d-flex justify-content-between align-items-center">
      <div class="">
        <h3
          class="text-primary"
          style="cursor: pointer"
          @click="ModalData(sessionData.session.course_id, 'group')"
        >
          {{ sessionData.session.course_name }} ({{ sessionData.session.group }})
        </h3>
        <small class="text-secondary">
          {{ sessionData.session.date }} | {{ sessionData.session.time_from }} - {{ sessionData.session.time_to }}
          <!-- classroom: {{ sessionData.session.classroom.name }} -->
        </small>
        <small v-if="sessionData.session.classroom" class="d-block text-secondary">
          classroom: {{ sessionData.session.classroom.name }}
          <!-- {{ sessionData.session.date }} | {{ sessionData.session.time_from }} - {{ sessionData.session.time_to }} -->
        </small>
      </div>
      <b-button
        v-b-modal.session-modal
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="btn-icon"
        variant="primary"
        pill
      >
        <feather-icon icon="UserPlusIcon" />
        Assign a substitute
      </b-button>
    </div>
    <div>
      <b-modal
        id="session-modal"
        :title="$t('Select a teacher for substitution')"
        ok-title="Assign"
        ok-only
        centered
        size="lg"
        no-close-on-backdrop
        @ok="substitute(sessionData.session.id, selectedTeachers)"
      >
        <b-list-group style="columns: 2; display: inherit;">
          <b-list-group-item
            v-for="teacher in sessionData.suggestTeachers.original.available"
            :key="teacher.id"
            style="padding: 0.5rem 1.25rem"
            class="border-0"
          >
            <b-form-checkbox
              v-model="selectedTeachers"
              :value="teacher.id"
            >
              {{ teacher.name }}
            </b-form-checkbox>
          </b-list-group-item>
        </b-list-group>
        <!-- <v-select
            v-model="selectedTeachers"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            multiple
            :options="sessionData.suggestTeachers.original.available"
            :clearable="false"
          >
            <template #option="{ name }">
              <b-avatar
                size="sm"
                :src="avatar"
              />
              <span class="ml-50"> {{ name }}</span>
            </template>

            <template #selected-option="{ name }">
              <b-avatar
                size="sm"
                class="border border-white"
                :src="avatar"
              />
              <span class="ml-50"> {{ name }}</span>
            </template>
          </v-select> -->
      </b-modal>
    </div>
  </section>
</template>

<script>
/* eslint-disable object-curly-newline */
import { BModal, BButton, BListGroup, BListGroupItem, BFormCheckbox } from 'bootstrap-vue'
import { ref, onMounted } from '@vue/composition-api'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import CoursesListBigModal from '@/views/courses/admin/courses-list/CoursesListBigModal.vue'

export default {
  components: {
    BModal,
    BButton,
    BListGroup,
    BFormCheckbox,
    BListGroupItem,
    CoursesListBigModal,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    sessionData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: 'group', label: 'group' },
        { key: 'course_name', label: 'course name' },
        { key: 'lesson_number', label: 'lesson number' },
        { key: 'date', label: 'date' },
        { key: 'time', label: 'timetable' },
        { key: 'classroom', label: 'classroom' },
        { key: 'action', label: 'action' },
      ],
      selectedTeachers: [],
    }
  },
  methods: {
    /* eslint-disable camelcase */
    substitute(session_id, teachers) {
      this.$http.post('/session/substitute/apply', { session_id, teachers }).then(res => {
        this.$bvModal.hide('session-data-modal')
        this.$toast({
        component: ToastificationContent,
        props: {
          title: res.data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
    })
    },
  },
  setup(props) {
    const isBigModalActive = ref(false)
    const spinner = ref(false)
    const msgs = ref([])
    const UsersToAdd = ref([])
    const currentCourseData = ref(null)
    const UsersAlreadyAdded = ref([])
    // const cities = ref([])
    const masters = ref([])
    const classrooms = ref([])
    const teachers = ref([])
    const TofDArray = ref([
      { name: 'daytime', value: 'daytime' },
      { name: 'anytime', value: 'anytime' },
      { name: 'evening', value: 'evening' },
    ])
    const statusArray = ref([
      { name: 'Active', value: 'active', color: 'primary' },
      { name: 'Hidden', value: 'hidden', color: 'primary' },
      { name: 'Past', value: 'past', color: 'primary' },
      { name: 'Deleted', value: 'deleted', color: 'primary' },
      { name: 'Planned', value: 'planned', color: 'primary' },
    ])
    const currentUser = JSON.parse(localStorage.getItem('userData'))
    const loadSpinner = () => {
      // open
      spinner.value = true
      setTimeout(() => {
        spinner.value = false
      }, 1000)
    }
    const ModalData = (id, tab) => {
      store.dispatch('coursesStoreModule/getSCourse', id)
      .then(() => {
        currentCourseData.value = store.state.coursesStoreModule.singleCourse.data
        currentCourseData.value.pendingTeachers = store.state.coursesStoreModule.singleCourse.pendingTeachers
        if (store.state.coursesStoreModule.singleCourse.currentTeacher) { currentCourseData.value.currentTeacher = store.state.coursesStoreModule.singleCourse.currentTeacher }
        currentCourseData.value.tab = tab
        const sDays = currentCourseData.value.days
        const daysArr = sDays.split(',')
        currentCourseData.value.days = daysArr
        if (currentCourseData.value.conversation === null) {
          msgs.value = []
          UsersToAdd.value = []
          UsersAlreadyAdded.value = []
        } else {
          if (typeof currentCourseData.value.conversation !== 'undefined') {
            msgs.value = currentCourseData.value.conversation.messages
          }
          UsersToAdd.value = currentCourseData.value.users_To_Add
          UsersAlreadyAdded.value = currentCourseData.value.users_Already_In
        }
        isBigModalActive.value = true
      }).catch(error => console.log(error))
    }
    // lists
  // const fetchCities = () => {
  //   store.dispatch('coursesStoreModule/fetchCities')
  //     .then(() => {
  //       cities.value = store.state.coursesStoreModule.cities
  //     })
  // }
  const fetchMCourses = () => {
    store.dispatch('coursesStoreModule/fetchMCourses', { group: null, perPage: 100, sortBy: 'code' })
      .then(() => {
        masters.value = store.state.coursesStoreModule.Mcourses
      })
  }
  const fetchTeachers = () => {
    store.dispatch('coursesStoreModule/fetchTeachers', { course_id: props.sessionData.session.course_id })
      .then(() => {
        teachers.value = store.state.coursesStoreModule.teachers
      })
  }
  const suggestClassrooms = (record, Days) => {
    /* eslint-disable no-param-reassign */
    record.days = Days.join(',')
    store.dispatch('coursesStoreModule/suggestClassrooms', record)
      .then(res => {
        classrooms.value = res.data.available
      })
  }
  const suggestTeacher = (record, Days) => {
    /* eslint-disable no-param-reassign */
    record.days = Days.join(',')
    store.dispatch('coursesStoreModule/suggestTeacher', record)
      .then(res => {
        teachers.value = res.data.available
      })
  }
    // call on mounted
    onMounted(fetchMCourses)
    onMounted(fetchTeachers)
    // onMounted(fetchCities)
    //
    return {
      isBigModalActive,
      ModalData,
      loadSpinner,
      suggestClassrooms,
      suggestTeacher,
      classrooms,
      teachers,
      masters,
      // cities,
      TofDArray,
      statusArray,
      currentUser,

      spinner,
      currentCourseData,
      msgs,
      UsersToAdd,
      UsersAlreadyAdded,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
