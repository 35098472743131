<template>
  <b-card
    :key="$store.state.appConfig.chatRefreshKey"
    no-body
    class="mb-4"
    style="height: 345px; overflow: hidden"
  >
    <b-card-header>
      <div class="d-flex align-items-center">
        <h5 class="mb-0">
          {{ $t('Chat') }}
        </h5>
      </div>
      <b-button
          variant="primary"
          size="sm"
          class="d-inline-block"
          :to="{ name: 'apps-chat' }"
      >
        Start chat
      </b-button>

      <!-- <feather-icon
        icon="MoreVerticalIcon"
        size="18"
      /> -->
    </b-card-header>
    <b-list-group flush>
      <span
        v-if="!conversations.length"
        class="text-center mb-1"
      >{{ $t('No messages') }}</span>
      <b-list-group-item
        v-for="conversation in conversations"
        :key="conversation.id"
        class="d-flex justify-content-between"
        :to="{ name: 'apps-chat' }"
      >
        <div class="d-flex align-items-center">
          <b-avatar
            size="34"
            :src="conversation.avatar"
            class="mr-50 badge-minimal"
          />
          <div class="chat-info flex-grow-1">
            <h5 class="mb-0">
              {{ conversation.name }}
            </h5>
            <p class="card-text text-muted">
              {{ conversation.latest_message.message }}
            </p>
          </div>
        </div>
        <div
          class="chat-meta text-nowrap"
        >
          <small class="d-block chat-time">{{ conversation.latest_message.time }}</small>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem, BCardHeader, BAvatar, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BCardHeader,
    BAvatar,
    BButton,
  },
  data() {
    return {
      conversations: [],
      kitchenSinkList: [
        { text: 'Cras justo odio' },
        { text: 'Vestibulum at eros' },
      ],
    }
  },
  watch: {
  '$store.state.appConfig.chatRefreshKey': function () {
      this.$http.get('/teacher/latest-conversations').then(res => { this.conversations = res.data.conversations })
    },
  },
  created() {
    this.$http.get('/teacher/latest-conversations').then(res => { this.conversations = res.data.conversations })
  },
}
</script>
