<template>
  <!-- eslint-disable -->
  <div>
    <div
      v-if="isBusy"
      class="text-center d-flex align-items-center justify-content-center"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <div v-if="!isBusy">
      <validation-observer
        #default="{ handleSubmit }"
        ref="form"
      >
        <b-form>
          <b-tabs v-model="tabIndex">
            <b-tab title="Step 1">
              <b-row>
                <b-col>
                  <!-- Language -->
                  <validation-provider
                    #default="validationContext"
                    name="Language"
                    rules="required"
                  >
                    <b-form-group :state="getValidationState(validationContext)" label="Language (required)">
                      <v-select
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              placeholder="Select"
                              label="name"
                              :options="languages"
                              :clearable="false"
                              v-model="Model.language_id"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col>
                  <!-- Course type -->
                  <validation-provider
                    #default="validationContext"
                    name="Course type"
                    rules="required"
                  >
                    <b-form-group :state="getValidationState(validationContext)" label="Course type">
                      <v-select
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              placeholder="Select"
                              label="name"
                              :options="Types"
                              :clearable="false"
                              v-model="Model.course_type_id"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <!-- Parent master course -->
                  <validation-provider
                    #default="validationContext"
                    name="Parent master course"
                  >
                    <b-form-group :state="getValidationState(validationContext)" label="Parent master course">
                      <v-select
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              placeholder="Select"
                              label="code"
                              :options="masters.filter(c => c !== Model.next_master_course_id).filter(c => c.id !== currentMasterCourse)"
                              v-model="Model.parent_master_course_id"
                      >
                        <template #option="{ code, level, classroom_type }">
                          <div class="d-flex align-items-center justify-content-between">
                            <span v-if="code" class="ml-1">{{ code }} <small v-if="level" class="text-muted text-capitalize">({{ level }})</small></span>
                            <b-badge
                              v-if="classroom_type"
                              :variant="classroom_type === 'classroom' ? 'secondary' : 'success'"
                              class="text-capitalize"
                              >
                                {{ classroom_type }}
                            </b-badge>
                          </div>
                        </template>
                      </v-select>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <!-- Next -->
                  <validation-provider
                    #default="validationContext"
                    name="Next"
                  >
                    <b-form-group :state="getValidationState(validationContext)" label="Next">
                      <v-select
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              placeholder="Select"
                              label="code"
                              :options="masters.filter(c => c !== Model.parent_master_course_id).filter(c => c.id !== currentMasterCourse)"
                              v-model="Model.next_master_course_id"
                      >
                        <template #option="{ code, level, classroom_type }">
                          <div class="d-flex align-items-center justify-content-between">
                            <span v-if="code" class="ml-1">{{ code }} <small v-if="level" class=" text-muted text-capitalize">({{ level }})</small></span>
                            <b-badge
                              v-if="classroom_type"
                              :variant="classroom_type === 'classroom' ? 'secondary' : 'success'"
                              class="text-capitalize"
                              >
                                {{ classroom_type }}
                            </b-badge>
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Step 2">
              <b-row>
                <b-col>
                  <!-- Code -->
                  <validation-provider
                    #default="validationContext"
                    name="Code"
                    rules="required"
                  >
                    <b-form-group label="Code (required)">
                      <b-form-input
                              placeholder="Code"
                              type="text"
                              v-model="Model.code"
                              :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
                <b-tabs>
                  <b-tab title="English">
                    <!-- Name -->
                    <validation-provider
                      #default="validationContext"
                      name="Name"
                      rules="required"
                    >
                      <b-form-group label="Name (required)">
                        <b-form-input
                                placeholder="Name"
                                type="text"
                                v-model="Model.name.en"
                                :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <b-row>
                      <b-col>
                        <!-- Definition -->
                        <validation-provider
                          #default="validationContext"
                          name="Definition"
                        >
                          <b-form-group :state="getValidationState(validationContext)" label="Definition" style="height: 260px">
                            <quill-editor
                              style="height: 170px"
                              v-model="Model.definition.en"
                              :options="editorOption"
                            />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col>
                        <!-- Description -->
                        <validation-provider
                          #default="validationContext"
                          name="Description"
                        >
                          <b-form-group :state="getValidationState(validationContext)" label="Description" style="height: 260px">
                            <quill-editor
                                    v-model="Model.description.en"
                                    style="height: 170px"
                                    :options="editorOption"
                            />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-tab>
                  <b-tab title="French">
                    <!-- Name -->
                    <validation-provider
                      #default="validationContext"
                      name="Name"
                    >
                      <b-form-group label="Name">
                        <b-form-input
                                placeholder="Name"
                                type="text"
                                :state="getValidationState(validationContext)"
                                v-model="Model.name.fr"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <b-row>
                      <b-col>
                        <!-- Definition -->
                        <validation-provider
                          #default="validationContext"
                          name="Definition"
                        >
                          <b-form-group :state="getValidationState(validationContext)" label="Definition" style="height: 260px">
                            <quill-editor
                              style="height: 170px"
                              v-model="Model.definition.fr"
                              :options="editorOption"
                            />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col>
                        <!-- Description -->
                        <validation-provider
                          #default="validationContext"
                          name="Description"
                        >
                          <b-form-group :state="getValidationState(validationContext)" label="Description" style="height: 260px">
                            <quill-editor
                                    v-model="Model.description.fr"
                                    style="height: 170px"
                                    :options="editorOption"
                            />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-tab>
                  <b-tab title="German">
                    <!-- Description -->
                    <validation-provider
                      #default="validationContext"
                      name="Name"
                    >
                      <b-form-group label="Name">
                        <b-form-input
                                placeholder="Name"
                                type="text"
                                :state="getValidationState(validationContext)"
                                v-model="Model.name.de"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <b-row>
                      <b-col>
                        <!-- Definition -->
                        <validation-provider
                          #default="validationContext"
                          name="Definition"
                        >
                          <b-form-group :state="getValidationState(validationContext)" label="Definition" style="height: 260px">
                            <quill-editor
                              style="height: 170px"
                              v-model="Model.definition.de"
                              :options="editorOption"
                            />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col>
                        <!-- Description -->
                        <validation-provider
                          #default="validationContext"
                          name="Description"
                        >
                          <b-form-group :state="getValidationState(validationContext)" label="Description" style="height: 260px">
                            <quill-editor
                                    v-model="Model.description.de"
                                    style="height: 170px"
                                    :options="editorOption"
                            />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-tab>
                </b-tabs>
            </b-tab>
            <b-tab title="Step 3">
              <b-row>
                <b-col>
                  <!-- Visa required -->
                  <validation-provider
                    #default="validationContext"
                    name="Visa required"
                  >
                    <b-form-group :state="getValidationState(validationContext)" label="Visa required">
                      <v-select
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="name"
                              :options="yesNoArray"
                              placeholder="Select"
                              :clearable="false"
                              v-model="Model.visa_required"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <!-- Visa day limit -->
                  <validation-provider
                    #default="validationContext"
                    name="Visa day limit"
                  >
                    <b-form-group label="Visa day limit">
                      <b-form-input
                              placeholder="Visa day limit"
                              type="number"
                              :state="getValidationState(validationContext)"
                              v-model="Model.visa_day_limit"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <!-- Payment required -->
                  <validation-provider
                    #default="validationContext"
                    name="Payment required"
                  >
                    <b-form-group :state="getValidationState(validationContext)" label="Payment required">
                      <v-select
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="name"
                              :options="yesNoArray"
                              placeholder="Select"
                              :clearable="false"
                              v-model="Model.payment_required"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <!-- Show start -->
                  <validation-provider
                    #default="validationContext"
                    name="Show start"
                    rules="required"
                  >
                    <b-form-group :state="getValidationState(validationContext)" label="Show start">
                      <v-select
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="name"
                              :options="yesNoArray"
                              placeholder="Select"
                              :clearable="false"
                              v-model="Model.date_start_visible"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Step 4">
              <b-row>
                <b-col>
                  <!-- Show end -->
                  <validation-provider
                    #default="validationContext"
                    name="Show end"
                    rules="required"
                  >
                    <b-form-group :state="getValidationState(validationContext)" label="Show end">
                      <v-select
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="name"
                              :options="yesNoArray"
                              placeholder="Select"
                              :clearable="false"
                              v-model="Model.date_end_visible"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <!-- Days to hide -->
                  <validation-provider
                    #default="validationContext"
                    name="Days to hide"
                  >
                    <b-form-group
                            :state="getValidationState(validationContext)"
                            label="Days to hide"
                    >
                      <b-form-checkbox-group
                              :options="days"
                              v-model="Model.selected_days"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <!-- Always visible -->
                  <validation-provider
                    #default="validationContext"
                    name="Always visible"
                    rules="required"
                  >
                    <b-form-group
                            label="always visible"
                            label-for="visibility"
                            :state="getValidationState(validationContext)"
                    >
                      <v-select
                              id="visibility"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="name"
                              :options="yesNoArray"
                              placeholder="Select"
                              :clearable="false"
                              v-model="Model.always_visible"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- <b-col v-if="singleDatas"> -->
                  <!-- Status -->
                  <!-- <validation-provider
                    #default="validationContext"
                    name="Status"
                    rules="required"
                  >
                    <b-form-group
                            label="Status"
                            label-for="status"
                            :state="getValidationState(validationContext)"
                    >
                      <v-select
                              id="status"
                              label="name"
                              :options="statusArray"
                              :reduce="x => x.value"
                              placeholder="Status"
                              v-model="Model.status"
                              direction="top"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col> -->
                <b-col>
                  <!-- Level -->
                  <validation-provider
                    #default="validationContext"
                    name="Level"
                  >
                    <b-form-group
                            label="Level"
                            label-for="level"
                            :state="getValidationState(validationContext)"
                    >
                      <v-select
                              id="level"
                              v-model="Model.level"
                              label="label"
                              :clearable="false"
                              :options="levelOptions"
                              placeholder="Level"
                              direction="top"
                      >
                        <!-- <template #option="{ value }">
                          <span class="bullet bullet-sm mr-1" :style="`background : ${value}`"></span>
                          <span class="ml-50 align-middle">  {{ value }}</span>
                        </template> -->
                      </v-select>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <!-- Color -->
                  <validation-provider
                    #default="validationContext"
                    name="Session type"
                  >
                    <b-form-group
                            label="Session type"
                            label-for="session-type"
                            :state="getValidationState(validationContext)"
                    >
                      <v-select
                              id="session-type"
                              v-model="Model.session_type"
                              label="label"
                              :options="sessionOptions"
                              :reduce="x => x.value"
                              :clearable="false"
                              placeholder="Session type"
                              direction="top"
                      >
                        <!-- <template #option="{ value }">
                          <span class="bullet bullet-sm mr-1" :style="`background : ${value}`"></span>
                          <span class="ml-50 align-middle">  {{ value }}</span>
                        </template> -->
                      </v-select>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <!-- Color -->
                  <validation-provider
                    #default="validationContext"
                    name="Classroom type"
                    rules="required"
                  >
                    <b-form-group
                            label="Classroom"
                            label-for="classroom"
                            :state="getValidationState(validationContext)"
                    >
                      <v-select
                              id="classroom"
                              v-model="Model.classroom_type"
                              label="label"
                              :options="classroomOptions"
                              :reduce="x => x.value"
                              :clearable="false"
                              placeholder="Ex: Classroom"
                              direction="top"
                      >
                        <!-- <template #option="{ value }">
                          <span class="bullet bullet-sm mr-1" :style="`background : ${value}`"></span>
                          <span class="ml-50 align-middle">  {{ value }}</span>
                        </template> -->
                      </v-select>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-form-group>
                <b-button variant="primary" @click="ButtonAction">{{Model.buttonText}}</b-button>
              </b-form-group>
            </b-tab>
          </b-tabs>
          <div class="text-center">
            <b-button-group class="mt-1">
              <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      @click="tabIndex--"
                      v-if="tabIndex > 0"
              >
                Previous
              </b-button>
              <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      @click="tabIndex++"
                      v-if="tabIndex < 3"
              >
                Next
              </b-button>
            </b-button-group>
            <b-card-text>
    <!--          <small>Current Tab: {{ tabIndex }}</small>-->
            </b-card-text>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>
<script>
  import {
    BRow,
    BCol,
    BForm,
    // BPagination,
    // BFormSelect,
    BFormGroup,
    BFormInput,
    BFormCheckboxGroup,
    BButton,
    // BDropdown,
    // BDropdownItem,
    // BCard,
    BCardText,
    BButtonGroup,
    // BListGroup,
    // BListGroupItem,
    BBadge,
    // BModal,
    VBModal,
    BTabs,
    BTab,
    BFormInvalidFeedback,
    BSpinner,
  } from 'bootstrap-vue'
  import {
    ref,
    onMounted,
    onUnmounted,
  } from '@vue/composition-api'
  // import { VueGoodTable } from 'vue-good-table'
  import 'vue-good-table/dist/vue-good-table.css'
  import vSelect from 'vue-select'
  // eslint-disable-next-line
  import 'quill/dist/quill.core.css'
  // eslint-disable-next-line
  import 'quill/dist/quill.snow.css'
  // eslint-disable-next-line
  import 'quill/dist/quill.bubble.css'
  import { useToast } from 'vue-toastification/composition'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import Ripple from 'vue-ripple-directive'
  import { quillEditor } from 'vue-quill-editor'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
  import store from '@/store'
  import coursesStoreModule from '../coursesStoreModule'
  // import EditCourse from './EditCourse.vue'
  // import MasterFilter from './MasterListFilter.vue'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      BFormInvalidFeedback,
      // MasterFilter,
      // EditCourse,
      BRow,
      BCol,
      BForm,
      // BPagination,
      // BFormSelect,
      BFormGroup,
      BFormInput,
      BFormCheckboxGroup,
      BButton,
      // BDropdown,
      // BDropdownItem,
      // BCard,
      // BListGroup,
      // BListGroupItem,
      BBadge,
      // BModal,
      BTabs,
      BTab,
      BCardText,
      BButtonGroup,
      BSpinner,

      // third party
      // VueGoodTable,
      vSelect,
      quillEditor,
      // eslint-disable-next-line vue/no-unused-components
      ToastificationContent,
    },
    directives: {
      'b-modal': VBModal,
      Ripple,
    },
    props: {
      refetchDataMaster: {
        type: Object,
      },
      singleDatas: {
        type: Object,
      },
      modalType: {
        type: Boolean,
      },
      currentMasterCourse: {
        type: Number,
      },
    },
    data() {
      return {
        required,
        editorOption: {
          modules: {
            // toolbarOptions: [
            //   ['bold', 'italic', 'underline', 'strike'],
            // ],
          },
        },
      }
    },
    mounted() {
      if (typeof this.singleDatas !== 'undefined') {
        // this.Model.name = { en: this.singleDatas.name, fr: '', de: '' }
        // this.Modeldescription = { en: this.singleDatas.description, fr: '', de: '' }
        // this.Modeldefinition = { en: this.singleDatas.definition, fr: '', de: '' }
        /* eslint-disable no-unused-expressions */
        this.singleDatas.parentMasterCourse ? this.singleDatas.parentMasterCourse.name = this.singleDatas.parentMasterCourse.name.en : this.singleDatas.parentMasterCourse
        this.Model = {
          name: this.singleDatas.name,
          description: this.singleDatas.description,
          definition: this.singleDatas.definition,
          always_visible: this.singleDatas.always_visible ? { name: 'Yes', value: true } : { name: 'No', value: false },
          code: this.singleDatas.code,
          level: this.singleDatas.level,
          classroom_type: this.singleDatas.classroom_type,
          session_type: this.singleDatas.session_type,
          color: this.singleDatas.color,
          course_desc_id: this.singleDatas.parentMasterCourse,
          course_type_id: this.singleDatas.courseType,
          courses: this.singleDatas.courses,
          created_at: this.singleDatas.created_at,
          date_end_visible: this.singleDatas.date_end_visible ? { name: 'Yes', value: true } : { name: 'No', value: false },
          date_start_visible: this.singleDatas.date_start_visible ? { name: 'Yes', value: true } : { name: 'No', value: false },
          days_to_hide: this.singleDatas.days_to_hide,
          id: this.singleDatas.id,
          language_id: this.singleDatas.language,
          next_master_course_id: this.singleDatas.nextMasterCourse,
          parent_master_course_id: this.singleDatas.parentMasterCourse,
          payment_required: this.singleDatas.payment_required ? { name: 'Yes', value: true } : { name: 'No', value: false },
          selected_days: this.singleDatas.selected_days,
          status: this.singleDatas.status,
          updated_at: this.singleDatas.updated_at,
          visa_day_limit: this.singleDatas.visa_day_limit,
          visa_required: this.singleDatas.visa_required ? { name: 'Yes', value: true } : { name: 'No', value: false },

        }
        // console.log(this.singleDatas)
        this.Model.buttonText = 'Update master course'
        this.Model.action = 'updateMCourse'
      } else {
        this.Model.buttonText = 'Add new master course'
        this.Model.action = 'AddNewM'
      }
    },
    setup(props, { emit }) {
      const USER_APP_STORE_MODULE_NAME = 'app-tree'

      // Register module
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, coursesStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      })
      //
      const toast = useToast()
      const isSubmited = ref(false)

      const days = ref([
        { text: 'Mo', value: 'mo' },
        { text: 'Tu', value: 'tu' },
        { text: 'We', value: 'we' },
        { text: 'Th', value: 'th' },
        { text: 'Fr', value: 'fr' },
        { text: 'Sa', value: 'sa' },
        { text: 'Su', value: 'su' },
      ])
      const statusArray = ref([
        { name: 'Active', value: 'Active' },
        { name: 'Deleted', value: 'Deleted' },
      ])
      const levelOptions = ref([
        { label: 'Beginner', value: 'beginner' },
        { label: 'Intermediate', value: 'intermediate' },
        { label: 'Advanced', value: 'advanced' },
      ])
      const sessionOptions = ref([
        { label: 'Grammar', value: 'grammar' },
        { label: 'Individual', value: 'individual' },
        { label: 'Summer', value: 'summer' },
        { label: 'Conversation', value: 'conversation' },
      ])
      const classroomOptions = ref([
        { label: 'Online', value: 'online' },
        { label: 'Classroom', value: 'classroom' },
      ])
      const colorsArray = ref([
        { name: 'Red', value: 'Red' },
        { name: 'Black', value: 'black' },
        { name: 'Pink', value: 'Pink' },
        { name: 'Purple', value: 'Purple' },
        { name: 'Orange', value: 'Orange' },
        { name: 'Yellow', value: 'yellow' },
        { name: 'Aqua', value: 'aqua' },
      ])
      const yesNoArray = ref([
        { name: 'Yes', value: true },
        { name: 'No', value: false },
      ])
      const columns = ref([
        {
          label: 'Courses',
          field: 'courses',
        },
        {
          label: 'Language',
          field: 'language',
        },
        {
          label: 'Type',
          field: 'courseType',
        },
        {
          label: 'Description',
          field: 'CourseDesc',
        },
        {
          label: 'Next',
          field: 'NextMatserCourse',
        },
        {
          label: 'Code',
          field: 'code',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Payment R?',
          field: 'payment_required',
        },
        {
          label: 'Show start?',
          field: 'date_start_visible',
        },
        {
          label: 'Show end?',
          field: 'date_end_visible',
        },
        {
          label: 'Days to hide',
          field: 'days_to_hide',
        },
        {
          label: 'Always visible?',
          field: 'always_visible',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ])
      const isFilterActive = ref(false)
      const searchTerm = ref('')
      const pageLength = ref(3)
      const masters = ref([])
      const languages = ref([])
      const descriptions = ref([])
      const Model = ref({
        name: {
          en: null,
          fr: null,
          de: null,
        },
        description: {
          en: null,
          fr: null,
          de: null,

        },
        definition: {
          en: null,
          fr: null,
          de: null,
        },
        date_end_visible: { name: 'Yes', value: true },
        always_visible: { name: 'Yes', value: true },
        visa_required: { name: 'No', value: false },
        payment_required: { name: 'Yes', value: true },
        date_start_visible: { name: 'Yes', value: true },
        classroom_type: { label: 'Classroom', value: 'classroom' },
        visa_day_limit: 0,
        level: null,
      })
      const isModalActive = ref(false)
      const singleData = ref({})
      const notBelong = ref([])
      const spinner = ref(false)
      const tabIndex = ref(0)
      const Types = ref([])
      const name = ref('')
      const languageId = ref(null)
      const typeId = ref(null)
      const descriptionId = ref(null)
      const statusId = ref(null)
      const isBusy = ref(true)
      const fetchMCourses = () => {
        store.dispatch('app-tree/fetchMCourses', {
          group: null,
          perPage: 300,
          // name: name.value,
          // course_desc_id: descriptionId.value,
          // course_type_id: typeId.value,
          // language_id: languageId.value,
          // status: statusId.value,
        })
        .then(() => {
          masters.value = store.state.coursesStoreModule.Mcourses.rows
        })
      }
      const ModalData = id => {
        isModalActive.value = true
        store.dispatch('app-tree/getSMCourse', id)
          .then(() => {
            singleData.value = store.state.coursesStoreModule.singleMasterCourse.single
            notBelong.value = store.state.coursesStoreModule.singleMasterCourse.not_belong
            const sDays = singleData.value.days_to_hide
            if (sDays) {
              const daysArr = sDays.split(',')
              singleData.value.days_to_hide = daysArr
            } else {
              singleData.value.days_to_hide = []
            }
          }).catch(error => console.log(error))
      }
      /* eslint-disable prefer-destructuring */
      const fetchLanguages = () => {
        isBusy.value = true
        store.dispatch('app-tree/fetchLanguages')
          .then(() => {
            isBusy.value = false
            languages.value = store.state.coursesStoreModule.Languages
            if (!props.singleDatas) {
              Model.value.language_id = languages.value[2]
            }
          })
      }
      const fetchTypes = () => {
        isBusy.value = true
        store.dispatch('app-tree/fetchTypes')
          .then(() => {
            isBusy.value = false
            Types.value = store.state.coursesStoreModule.Types.rows
            if (props.singleDatas === undefined) {
              Model.value.course_type_id = Types.value[0]
            }
          })
      }
      // const fetchDescriptions = () => {
      //   isBusy.value = true
      //   store.dispatch('app-tree/fetchDescriptions')
      //     .then(() => {
      //       isBusy.value = false
      //       descriptions.value = store.state.coursesStoreModule.Descriptions
      //     })
      // }
      const addNewMaster = function () {
          // emit('refetch_master_courses', true)
        const payload = {
          always_visible: Model.value.always_visible.value,
          classroom_type: Model.value.classroom_type && Model.value.classroom_type.value ? Model.value.classroom_type.value : Model.value.classroom_type,
          code: Model.value.code,
          course_type_id: Model.value.course_type_id.id,
          date_end_visible: Model.value.date_end_visible.value,
          date_start_visible: Model.value.date_start_visible.value,
          days_to_hide: Model.value.selected_days ? Model.value.selected_days.join(',') : '',
          definition: Model.value.definition,
          description: Model.value.description,
          language_id: Model.value.language_id.id,
          name: Model.value.name,
          payment_required: Model.value.payment_required.value,
          visa_day_limit: Model.value.visa_day_limit ? Model.value.visa_day_limit : 0,
          visa_required: Model.value.visa_required.value,
          course_desc_id: 68,
          level: Model.value.level ? Model.value.level.value : Model.value.level,
          parent_master_course_id: Model.value.parent_master_course_id ? Model.value.parent_master_course_id.id : null,
          next_master_course_id: Model.value.next_master_course_id ? Model.value.next_master_course_id.id : null,
        }
        Model.value.days_to_hide = Model.value.selected_days ? Model.value.selected_days.join(',') : ''
        store.dispatch('app-tree/AddNewM', payload)
          .then(() => {
            emit('refetch_master_courses', true)
            emit('isEditModalActive', false)
            Model.value = {
              name: {
              en: null,
              fr: null,
              de: null,
            },
            description: {
              en: null,
              fr: null,
              de: null,

            },
            definition: {
              en: null,
              fr: null,
              de: null,
            },
              date_end_visible: { name: 'Yes', value: true },
              always_visible: { name: 'Yes', value: true },
              visa_required: { name: 'No', value: false },
              payment_required: { name: 'Yes', value: true },
              date_start_visible: { name: 'Yes', value: true },
              classroom_type: { label: 'Classroom', value: 'classroom' },
              visa_day_limit: 0,
              level: null,
            }
            toast({
              component: ToastificationContent,
              props: {
                title: 'Master added successfully',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }).catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      }
      const UpdateMaster = () => {
        const payload = { ...Model.value }
        // const payload = Model.value
        payload.days_to_hide = payload.selected_days ? Model.value.selected_days.toString() : null
        payload.date_end_visible = payload.date_end_visible ? payload.date_end_visible.value : null
        payload.always_visible = payload.always_visible ? payload.always_visible.value : null
        payload.visa_required = payload.visa_required ? payload.visa_required.value : null
        payload.payment_required = payload.payment_required ? payload.payment_required.value : null
        payload.date_start_visible = payload.date_start_visible ? payload.date_start_visible.value : null
        payload.language_id = payload.language_id ? payload.language_id.id : null
        payload.course_type_id = payload.course_type_id ? payload.course_type_id.id : null
        payload.next_master_course_id = payload.next_master_course_id ? payload.next_master_course_id.id : null
        payload.course_desc_id = 220
        payload.parent_master_course_id = payload.parent_master_course_id ? payload.parent_master_course_id.id : null
        payload.visa_day_limit = payload.visa_day_limit ? payload.visa_day_limit : 0
        store.dispatch('app-tree/updateMCourse', payload)
          .then(() => {
            emit('refetch_master_courses', true)
            emit('isEditModalActive', false)
            // fetchMCourses()
            // Model.isActive = false
            // Model.value = {}
            toast({
              component: ToastificationContent,
              props: {
                title: 'Master added successfully',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }).catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        // this.$emit('refetch_master_courses', true)
      }

      const ButtonAction = function () {
        this.$refs.form.validate().then(success => {
        if (success) {
          if (Model.value.action === 'AddNewM') {
            addNewMaster()
          }
          if (Model.value.action === 'updateMCourse') {
            UpdateMaster()
          }
        }
      })
      }

      const deleteMaster = id => {
        store.dispatch('app-tree/deleteM', id)
          .then(() => {
            fetchMCourses()
            toast({
              component: ToastificationContent,
              props: {
                title: 'Deleted successfully',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }).catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: error,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      }
      const {
      refFormObserver,
      getValidationState,
    } = formValidation(() => {})

      const loadSpinner = () => {
        // open
        spinner.value = true
        setTimeout(() => {
          spinner.value = false
        }, 2000)
      }

      onMounted(fetchMCourses)
      onMounted(fetchLanguages)
      // onMounted(fetchDescriptions)
      onMounted(fetchTypes)

      return {
        isBusy,
        refFormObserver,
        getValidationState,
        columns,
        searchTerm,
        pageLength,
        days,
        statusArray,
        yesNoArray,
        colorsArray,
        masters,
        Types,
        languages,
        descriptions,
        Model,
        spinner,
        singleData,
        isModalActive,
        notBelong,
        tabIndex,
        isFilterActive,
        name,
        languageId,
        typeId,
        descriptionId,
        statusId,
        addNewMaster,
        UpdateMaster,
        ModalData,
        loadSpinner,
        fetchMCourses,
        deleteMaster,
        isSubmited,
        ButtonAction,

        levelOptions,
        sessionOptions,
        classroomOptions,
      }
    },
  }
</script>
