<template>
  <!-- eslint-disable -->
  <div>

    <!-- Table Container Card -->
    <b-card
            no-body
            class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
                  cols="12"
                  md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
                           <b-form-input
                                   v-model="searchQuery"
                                   class="d-inline-block mr-1"
                                   placeholder="Search..."
                                   debounce="500"
                           />
              <!--              <b-button-->
              <!--                      variant="primary"-->
              <!--                      class="d-inline-block mr-1"-->
              <!--                      @click="isFilterActive = true"-->
              <!--              >-->
              <!--                <feather-icon icon="FilterIcon" />-->
              <!--              </b-button>-->
              <!--              <b-button-->
              <!--                      variant="primary"-->
              <!--                      class="d-inline-block mr-1"-->
              <!--                      @click="isAddActive = true"-->
              <!--              >-->
              <!--                <feather-icon icon="PlusIcon" />-->
              <!--              </b-button>-->
              <b-button
                      variant="primary"
                      class="d-inline-block mr-1 text-nowrap"
                      v-b-modal.master-course-modal
                      @click="action = 'Add'"
              >
                Add Master course
                <!-- <feather-icon icon="PlusIcon" /> -->
              </b-button>
              <mastercoruse-handler
                v-if="action"
                :key="mastercourseId"
                :action="action"
                :mastercourse-id="mastercourseId"
                @reset-modal="action = null"
                @refetch-data="refetchData"
              />
              <!-- <mastercourse-handler /> -->
            </div>
          </b-col>
        </b-row>

      </div>

      <!-- add master course add modal -->
      <b-modal
              id="modal-add-master-course"
              no-close-on-backdrop
              centered
              hide-footer
              size="lg"
              title="Add new Master course"
              v-model="isAddActive"
      >
        <modal-add-master-course
                @refetch_master_courses="refetchData"
                @isEditModalActive="isActiveEdit"
        />
      </b-modal>

      <!-- add master course edit modal -->
      <b-modal
              id="modal-edit-master-course"
              centered
              hide-footer
              no-close-on-backdrop
              size="lg"
              title="Update master course"
              v-model="isEditModalActive"
      >
        <modal-add-master-course
                :singleDatas="singleData"
                :currentMasterCourse="currentMasterCourse"
                @refetch_master_courses="refetchData"
                @isEditModalActive="isActiveEdit"
        />
      </b-modal>

      <b-table
              ref="refCourseListTable"
              class="position-relative"
              :items="fetchTypes"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="No matching records found"
              :sort-desc.sync="isSortDirDesc"
              :busy.sync="isBusy"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>

        <!-- Column: ID -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
<!--            <b-link-->
<!--                    :to="{ name: 'apps-users-view', params: { id: data.item.id } }"-->
<!--                    class="font-weight-bold d-block text-nowrap"-->
<!--            >-->
              {{ data.item.id }}
<!--            </b-link>-->
          </b-media>
        </template>

        <!-- Column: visa -->
        <template #cell(course)="data">
          <b>{{ data.item.course_name }}</b>
        </template>
        <template #cell(name)="data">
          <span
            v-b-tooltip.hover.top="data.item.name"
            class="d-inline-block text-truncate"
            style="max-width: 300px;">{{ data.item.name }}</span>
        </template>
        <!-- Column: course dates -->
        <template #cell(date_start)="data">
          <span class="badge badge-pill badge-light-secondary">{{ formatDateTime(data.item.date_end) }} - {{ formatDateTime(data.item.date_end) }}</span>
        </template>

        <!-- Column: course times -->
        <template #cell(time_start)="data">
          <span class="badge badge-pill badge-light-secondary">{{ data.item.time_from }} - {{ data.item.time_to }}</span>
        </template>

        <!-- Column: teacher -->
        <template #cell(teachers_name)="data">
          <div
                  v-if="data.item['Teacher.name']"
                  class="avatar bg-light-danger mr-1"
          >
            <div class="avatar-content">
              {{ formatTeacher(data.item['Teacher.name']) }}
            </div>
          </div>
        </template>

        <!-- Column: Role -->
        <template #cell(student)="data">
          <div class="text-nowrap">
            <feather-icon
                    :icon="resolveUserRoleIcon(data.item.role)"
                    size="18"
                    class="mr-50"
                    :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.student_name }}</span>
          </div>
        </template>

        <!-- Column: visa -->
        <template #cell(visa)="data">
          <b-badge
                  pill
                  :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                  class="text-capitalize"
          >
            {{ data.item.visa_needed }}
          </b-badge>
        </template>

        <!-- Column: who pays -->
        <template #cell(who_pays)="data">
          <b-badge
                  pill
                  :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                  class="text-capitalize"
          >
            {{ data.item.who_pays }}
          </b-badge>
        </template>

        <!-- Column: who pays -->
        <template #cell(payer_confirmed)="data">
          <b-badge
                  pill
                  :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                  class="text-capitalize"
          >
            {{ data.item.payer_confirmed }}
          </b-badge>
        </template>
        <!-- Column: who pays -->
        <template #cell(status)="data">
          <b-badge
                  pill
                  :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                  class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: who pays -->
        <template #cell(attendance)="data">
          <b-badge
                  pill
                  :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                  class="text-capitalize"
          >
            {{ data.item.attendance }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
                  dropleft
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-b-modal.master-course-modal
              @click="action = 'Edit'; mastercourseId = data.item.id"
            >
              <feather-icon icon="EditIcon" />
              <span
                      class="align-middle ml-50"
              >
                Edit
              </span>
            </b-dropdown-item>
            <b-dropdown-item
                    v-b-modal="getModal1Id(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

            <!-- basic modal -->
            <b-modal
                    :id="'modal1'+data.item.id"
                    title="Delete Mastercourse"
                    ok-title="Yes"
                    cancel-title="No"
                    centered
                    @ok="remove(data.item.id)"
            >
              <b-card-text>
                <h5>Are you sure you want to delete {{ data.item.code }} ? </h5>
              </b-card-text>
            </b-modal>


          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="!isBusy"
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
  import {
    BCard, BCardText, BRow, BCol, BButton, BTable, BMedia,
    BBadge, BDropdown, BDropdownItem, BPagination, BModal,
    BSpinner,
    VBTooltip,
    BFormInput,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import { ref, onUnmounted } from '@vue/composition-api'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { useToast } from 'vue-toastification/composition'
  import Ripple from 'vue-ripple-directive'
  import store from '@/store'
  import useMastersList from './useMastersList'
  import coursesStoreModule from '../coursesStoreModule'
  import modalAddMasterCourse from './ModalAddMasterCourse.vue'
  import MastercoruseHandler from './MastercoruseHandler.vue'

  export default {
    components: {
      MastercoruseHandler,
      modalAddMasterCourse,

      BCard,
      BCardText,
      BRow,
      BCol,
      // BFormInput,
      BButton,
      BTable,
      BMedia,
      // BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      BModal,
      BSpinner,
      BFormInput,
      // BFormGroup,
      vSelect,
    },
    data() {
      return {
        action: '',
        mastercourseId: null,
      }
    },
    directives: {
      'b-tooltip': VBTooltip,
      Ripple,
    },
    setup() {
      /* eslint-disable camelcase */
      const getModal1Id = id => `modal1${id}`
      const getModal2Id = id => `modal2${id}`
      const getModal3Id = id => `modal3${id}`
      const getModal4Id = id => `modal4${id}`
      const getModal5Id = id => `modal5${id}`

      const USER_APP_STORE_MODULE_NAME = 'app-Masters'

      // Register module
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, coursesStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      })
      const isFilterActive = ref(false)
      const isAddActive = ref(false)
      const isModalActive = ref(false)
      const isEditModalActive = ref(false)
      const singleData = ref({})
      const spinner = ref(false)
      const msgs = ref([])
      const UsersToAdd = ref([])
      const currentMasterCourse = ref(null)
      const UsersAlreadyAdded = ref([])
      const ModalData = id => {
        currentMasterCourse.value = id
        store.dispatch('app-Masters/getSMCourse', id)
          .then(() => {
            singleData.value = store.state.coursesStoreModule.singleMasterCourse.single
            const sDays = singleData.value.days_to_hide
            const daysArr = sDays.split(',')
            singleData.value.selected_days = daysArr
            if (singleData.value.conversation === null) {
              msgs.value = []
              UsersToAdd.value = []
              UsersAlreadyAdded.value = []
            } else {
              if (typeof singleData.value.conversation !== 'undefined') {
                msgs.value = singleData.value.conversation.messages
              }
              UsersToAdd.value = singleData.value.users_To_Add
              UsersAlreadyAdded.value = singleData.value.users_Already_In
            }
          })
          .then(() => {
            isEditModalActive.value = true
          })
          .catch(error => console.log(error))
      }
      const isActiveEdit = () => {
        isEditModalActive.value = false
        isAddActive.value = false
      }

      const loadSpinner = () => {
        // open
        spinner.value = true
        setTimeout(() => {
          spinner.value = false
        }, 2000)
      }

      const query1 = ref('')
      const query2 = ref('')
      const selectedTemplate = ref({})
      const selectedCourse = ref({})
      const templates = ref([])
      const courses = ref([])
      // Use toast
      const toast = useToast()

      const {
        isBusy,
        fetchTypes,
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refCourseListTable,
        refetchData,
        number,
        group,
        mastercourse_id,
        teacher_id,
        classroom_id,
        date_start_from,
        date_start_to,
        date_end_from,
        date_end_to,
        fStatus,
        time_of_day,
        cities,
        masters,
        classrooms,
        teachers,
        statusArray,
        TofDArray,
        currentUser,

        // UI
        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,

        // Extra Filters
        roleFilter,
        planFilter,
        statusFilter,
      } = useMastersList()

      const regToWaitingList = id => {
        store.dispatch('app-courses/regToWaitingList', id)
          .then(response => {
            toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error while confirming registration',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      }
      const formatDateTime = dateTime => {
        const timestamp = new Date(dateTime).getTime()
        const todate = new Date(timestamp).getDate()
        const tomonth = new Date(timestamp).getMonth() + 1
        const toyear = new Date(timestamp).getFullYear()
        const original_date = `${tomonth}-${todate}-${toyear}`
        return original_date
      }
      const formatTeacher = teacherName => {
        const tName = teacherName.split(' ')
        let name = ''
        tName.forEach(s => {
          name += s.charAt(0)
        })
        // const timestamp = new Date(dateTime).getTime()
        // const todate = new Date(timestamp).getDate()
        // const tomonth = new Date(timestamp).getMonth() + 1
        // const toyear = new Date(timestamp).getFullYear()
        // const original_date = `${tomonth}-${todate}-${toyear}`
        return name
      }
      const sendPTest = pId => {
        store.dispatch('app-courses/sendPTest', pId)
          .then(response => {
            toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error while confirming registration',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      }

      const autoCompleteCourse = search => {
        courses.value = []
        store.dispatch('app-courses/autoCompleteCourses', search)
          .then(response => { courses.value = response.data })
      }
      const autoCompleteTemplate = search => {
        templates.value = []
        store.dispatch('app-courses/autoComplete', search)
          .then(response => {
            templates.value = response.data
          })
      }
      const remove = regId => {
        store.dispatch('app-Masters/deleteMasterCourse', regId)
          .then(() => {
            refetchData()
            toast({
              component: ToastificationContent,
              props: {
                title: 'Registration Has Been removed',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error while removing registration',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      }

      /* console.log(masters) */

      return {
        currentMasterCourse,
        // Modal ids
        getModal1Id,
        getModal2Id,
        getModal3Id,
        getModal4Id,
        getModal5Id,
        ModalData,
        singleData,
        loadSpinner,
        spinner,
        isActiveEdit,

        // Actions
        remove,
        // cancel,
        // confirm,
        // generateCertificate,
        // generateContract,
        // moveStudent,
        sendPTest,
        regToWaitingList,

        // autocomplete
        query1,
        query2,
        selectedTemplate,
        selectedCourse,
        templates,
        courses,
        autoCompleteTemplate,
        autoCompleteCourse,
        formatDateTime,
        formatTeacher,

        // Sidebar
        isFilterActive,
        // Add modal
        isAddActive,
        // infos modal
        isModalActive,
        isEditModalActive,

        fetchTypes,
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refCourseListTable,
        refetchData,
        number,
        group,
        mastercourse_id,
        teacher_id,
        classroom_id,
        date_start_from,
        date_start_to,
        date_end_from,
        date_end_to,
        fStatus,
        time_of_day,
        cities,
        masters,
        classrooms,
        teachers,
        statusArray,
        TofDArray,
        msgs,
        UsersToAdd,
        UsersAlreadyAdded,
        currentUser,

        // UI
        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,
        isBusy,

        // Extra Filters
        roleFilter,
        planFilter,
        statusFilter,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  .input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    /* background-color: #f7f7f7; */
  }
  .input:focus {
    outline: none;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
