import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import Rollbar from 'vue-rollbar'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(Rollbar, {
  accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  root: 'webpack:///./',
  enabled: process.env.VUE_APP_ROLLBAR === 'true',
  source_map_enabled: true,
  environment: process.env.NODE_ENV ? process.env.NODE_ENV : 'staging',
  payload: {
    client: {
      javascript: {
        code_version: process.env.VUE_APP_COMMIT_HASH,
        source_map_enabled: true,
      },
    },
  },
})

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
// Vue.config.devtools = true

Vue.config.errorHandler = err => {
  console.error(err)
  // console.error(err, vm, info)
  Vue.rollbar.error(err)
}
Vue.config.warnHandler = err => {
  Vue.rollbar.warn(err)
}
Vue.config.renderError = err => {
  Vue.rollbar.error(err)
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
