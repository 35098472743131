<template>
  <div>
    <form-wizard
      color="#92434e"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="wizard-vertical mb-1 placement-test-wizard"
      @on-complete="createTest(placementTest ? 'update' : 'create')"
    >
      <!-- Test datails -->
      <tab-content
        title="Test details"
        :before-change="validateStep1"
      >
        <validation-observer ref="step1">
          <b-row>
            <b-col
              cols="12"
              class="mb-1"
            >
              <h5 class="mb-0">
                Test details
              </h5>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Test name"
                label-for="test-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="test name"
                  rules="required"
                >
                  <b-form-input
                    v-model="name"
                    placeholder="Test name"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Test description"
                label-for="test-description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <quill-editor
                    v-model="description"
                    :options="editorOption"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Levels -->
      <tab-content
        title="Levels"
        :before-change="validateStep2"
        style="margin-bottom: 20rem"
      >
        <validation-observer ref="step2">
          <b-row>
            <b-col
              cols="12"
              class="mb-1"
            >
              <h5 class="mb-0">
                Levels
              </h5>
              <!-- <small class="text-muted">Enter Your Personal Info.</small> -->
            </b-col>
            <b-col
              md="12"
            >
              <b-row
                v-for="(el, index) in stuff"
                :key="el.id"
                class="d-flex"
              >
                <b-col cols="7">
                  <b-form-group
                    label="Level"
                    label-for="level"
                  >
                    <validation-provider
                      name="level"
                      rules="required"
                    >
                      <v-select
                        v-model="el.level"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="code"
                        :options="levels"
                        placeholder="Level"
                        @keyup.native="autocompleteLevel($event.target.value)"
                        @click.native="autocompleteLevel('')"
                      >
                        <span slot="no-options">
                          <div
                            v-if="isTypingLevel"
                            class="text-center d-flex align-items-center justify-content-center"
                          >
                            <b-spinner
                              variant="primary"
                              label="Text Centered"
                            />
                          </div>
                          <div
                            v-if="!isTypingLevel && !levels.length"
                            class="text-center d-flex align-items-center justify-content-center"
                          >
                            No matching records found
                          </div>
                        </span>
                      </v-select>
                      <small class="text-danger">
                        {{ errorMessageLevel }}
                      </small>
                      <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group
                    label="Percentage"
                    label-for="percentage"
                  >
                    <b-form-spinbutton
                      id="demo-sb"
                      v-model="el.percent"
                      step="5"
                      min="0"
                      max="100"
                    />
                    <!-- <small class="text-danger">{{ errorMessageLevel }}</small> -->
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-button
                    v-if="stuff.length > 1"
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    v-b-tooltip.hover.top="'Remove element'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeEl(index)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-25"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="repeateAgainn"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>Add Level</span>
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Questions -->
      <tab-content
        title="Questions"
        :before-change="validateStep3"
        style="margin-bottom: 15rem"
      >
        <validation-observer ref="step3">
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Create the questions
              </h5>
              <!-- <small class="text-muted">Enter Your Address.</small> -->
            </b-col>
            <b-col md="12">
              <!-- draggable -->
              <draggable
                v-model="items"
                tag="ul"
                class="list-group list-group-flush cursor-move"
              >
                <!-- Row Loop -->
                <b-row
                  v-for="(item, index) in items"
                  :id="item.id"
                  :key="item.id"
                  ref="row"
                  tag="li"
                >
                  <b-col cols="12">
                    <b-row class="pb-2">
                      <b-col cols="12">
                        <b-form-group
                          label="The question"
                          label-for="the-question"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="question"
                            rules="required"
                          >
                            <b-form-input
                              id="item-name"
                              v-model="item.fieldLabel"
                              type="text"
                              :state="errors.length > 0 ? false:null"
                              @keyup.alt.67="$bvModal.show(`field-modal${item.id}`)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="7">
                        <b-form-group
                          label="Question group"
                          label-for="question-group"
                        >
                          <v-select
                            v-model="item.group"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :options.sync="groupOptions"
                            placeholder="Group"
                            @keyup.native="autocomplete($event.target.value)"
                            @click.native="autocomplete('')"
                          >
                            <span slot="no-options">
                              <div
                                v-if="isTypingGroup"
                                class="text-center d-flex align-items-center justify-content-center"
                              >
                                <b-spinner
                                  variant="primary"
                                  label="Text Centered"
                                />
                              </div>
                              <div
                                v-if="!isTypingGroup && !groupOptions.length"
                                class="text-center d-flex align-items-center justify-content-center"
                              >
                                No matching records found
                              </div>
                            </span>
                          </v-select>
                          <small class="text-danger">{{ errorMessageGroup }}</small>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="5"
                        class="d-flex align-items-center"
                      >
                        <!-- Add & Remove Button -->
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          v-b-tooltip.hover.top="'Add Group'"
                          variant="outline-primary"
                          class="mr-1 mt-50"
                          @click="$bvModal.show(`group-modal${item.id}`)"
                        >
                          <feather-icon
                            icon="PackageIcon"
                            class="mr-25"
                          />
                          <span>Add new group</span>
                        </b-button>
                        <!-- Fields Modal -->
                        <!-- @hide="reset(item)" -->
                        <b-modal
                          :id="`group-modal${item.id}`"
                          title="Create group"
                          ok-only
                          centered
                          ok-title="Create"
                          no-close-on-backdrop
                          @ok="createGroup(item.newGroup, index + 1)"
                        >
                          <b-form-group
                            label="Group name"
                            label-for="group-name"
                          >
                            <b-form-input
                              v-model="item.newGroup"
                              type="text"
                              placeholder="Group name"
                            />
                          </b-form-group>
                        </b-modal>
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          v-b-tooltip.hover.top="'Add element'"
                          variant="outline-success"
                          class="mt-50"
                          @click="$bvModal.show(`field-modal${item.id}`)"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                          />
                          <span>Add element</span>
                        </b-button>
                        <b-button
                          v-if="items.length > 1"
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          v-b-tooltip.hover.top="'Remove element'"
                          variant="outline-danger"
                          class="ml-1 mt-50"
                          @click="removeItem(index)"
                        >
                          <feather-icon
                            icon="TrashIcon"
                            class="mr-25"
                          />
                          <span>Delete</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                  <!-- Fields Modal -->
                  <b-modal
                    v-if="item.field"
                    :id="`field-modal${item.id}`"
                    title="Create field"
                    ok-only
                    centered
                    no-close-on-backdrop
                    ok-title="Create"
                    @hide="reset(item)"
                    @ok="createField(item.field, item.fieldLabel, item.id)"
                  >
                    <b-form-group
                      label="Field Name"
                      label-for="filed-name"
                    >
                      <b-form-input
                        v-model="item.field.name"
                        type="text"
                        placeholder="Filed name"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Form type"
                      label-for="form-type"
                    >
                      <v-select
                        v-model="item.field.selectedFormType"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="label"
                        :options="formTypeOptions"
                      >
                        <template #option="{ label, icon }">
                          <feather-icon
                            :icon="icon"
                            size="16"
                            class="align-middle mr-50"
                          />
                          <span> {{ label }}</span>
                        </template>
                      </v-select>
                    </b-form-group>
                    <!-- Styled -->
                    <b-form-group
                      v-if="item.field.selectedFormType.value === 'image'"
                      label="Image"
                      label-for="image"
                    >
                      <b-form-file
                        v-model="item.field.media"
                        placeholder="Choose image or drop it here..."
                        drop-placeholder="Drop image here..."
                        accept=".jpg, .png, .gif"
                      />
                    </b-form-group>
                    <b-form-group
                      v-if="item.field.selectedFormType.value === 'audio'"
                      label="Audio"
                      label-for="audio"
                    >
                      <b-form-file
                        v-model="item.field.media"
                        placeholder="Choose audio or drop it here..."
                        drop-placeholder="Drop audio here..."
                        accept="audio/*"
                      />
                    </b-form-group>
                    <b-form-group
                      v-if="item.field.selectedFormType.value === 'video'"
                      label="Video"
                      label-for="video"
                    >
                      <b-form-file
                        v-model="item.field.media"
                        placeholder="Choose video or drop it here..."
                        drop-placeholder="Drop video here..."
                        accept="video/*"
                      />
                    </b-form-group>
                    <b-form-group
                      v-if="item.field.selectedFormType.value === 'select' || item.field.selectedFormType.value === 'checkbox' || item.field.selectedFormType.value === 'radio'"
                      label="Options"
                      label-for="options"
                    >
                      <b-form-tags
                        v-model="item.field.options"
                        :tag-validator="tagValidator"
                        input-id="tags-basic"
                        placeholder="Add option"
                        class="mb-2"
                      />
                    </b-form-group>
                    <b-form-group
                      v-if="item.field.selectedFormType.value === 'checkbox' || item.field.selectedFormType.value === 'radio' || item.field.selectedFormType.value === 'image' || item.field.selectedFormType.value === 'video' || item.field.selectedFormType.value === 'audio'"
                      label="Question display"
                      label-for="question-display"
                    >
                      <v-select
                        v-model="item.field.selectedQuestionDisplay"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="label"
                        :options="questionDisplay"
                      />
                    </b-form-group>
                    <b-form-group
                      v-if="item.field.selectedFormType.label"
                      label="Response"
                      label-for="response"
                    >
                      <v-select
                        v-if="item.field.selectedFormType.value === 'checkbox' || item.field.selectedFormType.value === 'radio' || item.field.selectedFormType.value === 'select'"
                        v-model="item.field.response"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        placeholder="Response"
                        :options="item.field.options"
                      />
                      <b-form-input
                        v-else
                        v-model="item.field.response"
                        type="text"
                        placeholder="Response"
                      />
                    </b-form-group>
                  </b-modal>
                </b-row>
              </draggable>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="repeateAgain"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>Add Question</span>
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BModal,
  BFormSpinbutton,
  BFormTags,
  BFormFile,
  VBTooltip,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import draggable from 'vuedraggable'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    draggable,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    BFormSpinbutton,
    vSelect,
    BFormTags,
    quillEditor,
    BFormFile,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [heightTransition],
  props: {
    placementTest: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
      isTypingLevel: false,
      isTypingGroup: false,
      name: '',
      description: '',
      questionDisplay: [
        { label: 'Display above the question', value: 'above' },
        { label: 'Display under the question', value: 'under' },
      ],
      editorOption: {
        modules: {
          // toolbarOptions: ['bold', 'italic', 'underline', 'strike'],
        },
      },
      levels: [],
      groupOptions: [],
      formTypeOptions: [
        { label: 'Input field', value: 'input', icon: 'MinusIcon' },
        { label: 'Select', value: 'select', icon: 'ListIcon' },
        { label: 'Checkbox', value: 'checkbox', icon: 'CheckSquareIcon' },
        { label: 'Radio Button', value: 'radio', icon: 'CircleIcon' },
        { label: 'Image', value: 'image', icon: 'ImageIcon' },
        { label: 'Video', value: 'video', icon: 'VideoIcon' },
        { label: 'Audio', value: 'audio', icon: 'MicIcon' },
      ],
      stuff: [{
        id: 1,
        prevHeight: 0,
        level: {},
        percent: 0,
      }],
      items: [{
        field: {
          id: 1,
          response: null,
          name: null,
          value: null,
          selectedFormType: { label: null, value: null },
          selectedQuestionDisplay: { label: 'Display under the question', value: 'under' },
          options: [],
          media: null,
        },
        newGroup: '',
        group: {},
        fieldLabel: '',
        question_id: null,
        id: 1,
        prevHeight: 0,
      }],
      nextTodoId: 2,
      nextToIdStuff: 2,
      errorMessageLevel: '',
      errorMessageGroup: '',
    }
  },
  created() {
    /* eslint-disable no-param-reassign */
    window.addEventListener('resize', this.initTrHeight)
    if (this.placementTest) {
      this.stuff.shift()
      this.items.shift()
      this.name = this.placementTest.name
      this.description = this.placementTest.description
      this.placementTest.level.forEach(level => {
        this.stuff.push({
          id: this.nextToIdStuff += this.nextToIdStuff,
          level,
          percent: level.value,
        })
      })
      // this.removeItemLevel(0)
      this.placementTest.questions.forEach(question => {
          this.items.push({
            id: this.nextTodoId += this.nextTodoId,
            group: { name: question.group_name, id: question.group_id },
            fieldLabel: question.question,
            field: {
              id: 1,
              response: '',
              name: '',
              value: null,
              selectedFormType: {},
              options: [],
              media: null,
            },
            question_id: question.question_id,
          })
      })
    }
  },
  mounted() {
    // const nextSubmit = (document.getElementsByClassName('wizard-btn').length === 1 ? document.getElementsByClassName('wizard-btn')[0] : document.getElementsByClassName('wizard-btn')[1])
    // console.log(nextSubmit)
    this.initTrHeight()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    tagValidator(tag) {
      // Individual tag validator function
      return tag === tag.toLowerCase()
    },
    validateStep1() {
      return new Promise((resolve, reject) => {
        if (!this.name || !this.description) {
          this.$refs.step1.validate()
          // this.$validator.validate('test name', this.name)
          reject(new Error('Error'))
        } else {
          resolve(true)
        }
      })
    },
    validateStep2() {
      return new Promise((resolve, reject) => {
        // console.log(this.stuff[0].level.id, this.stuff[0].percent)
        if (this.stuff[0].level.id === undefined) {
          this.$refs.step2.validate()
          this.errorMessageLevel = 'Level is required'
          reject(new Error('Error'))
        } else {
          resolve(true)
        }
      })
    },
    validateStep3() {
      return new Promise((resolve, reject) => {
        if (!this.items[0].fieldLabel.length || this.items[0].group.id === undefined) {
          this.$refs.step3.validate()
          this.errorMessageGroup = 'Group is required'
          reject(new Error('Error'))
        } else {
          resolve(true)
        }
      })
    },
    reset(item) {
      item.field = {
        response: null,
        name: null,
        value: null,
        selectedFormType: { label: null, value: null },
        selectedQuestionDisplay: { label: 'Display under the question', value: 'under' },
        options: [],
        media: null,
      }
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    repeateAgainn() {
      this.stuff.push({
        id: this.nextToIdStuff += this.nextToIdStuff,
        level: {},
        percent: 0,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    repeateAgain(question) {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
        selectedGroupOption: { name: question.group_name, id: question.group_id },
        group: '',
        fieldLabel: question.question,
        field: {
          id: 1,
          response: '',
          name: '',
          value: null,
          selectedQuestionDisplay: { label: 'Display under the question', value: 'under' },
          selectedFormType: {},
          options: [],
          media: null,
        },
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    removeEl(index) {
      this.stuff.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    createGroup(name, index) {
      this.$http.post('/question-group/create', { name, priority: index })
      .then(res => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    },
    autocompleteLevel(search) {
      this.errorMessageLevel = ''
      this.isTypingLevel = true
      this.$http.get('/test-level/autocomplete', { params: { search } }).then(res => {
        this.isTypingLevel = false
        this.levels = res.data
      })
    },
    autocomplete(search) {
      this.errorMessageGroup = ''
      this.isTypingGroup = true
      this.$http.get('/question-group/autocomplete', { params: { search } }).then(res => {
        this.isTypingGroup = false
        this.groupOptions = res.data
      })
    },
    createField(field, label, id) {
      const formData = new FormData()
      formData.append('label', label)
      formData.append('media', field.media)
      formData.append('name', field.name)
      formData.append('formType', field.selectedFormType.value)
      formData.append('display', field.selectedQuestionDisplay.value)
      formData.append('response', field.response)
      formData.append('options', JSON.stringify(field.options))

      this.$http.post('/placement-test/field/create', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }).then(res => {
        this.items.forEach(item => {
          if (item.id === id) {
            this.reset(item)
            /* eslint-disable-next-line no-param-reassign */
            item.fieldLabel = `${label} {${field.selectedFormType.label}-${res.data.data.id}}`
          }
        })
      })
    },
    createTest(action) {
      const questions = []
      const level = []
      this.stuff.forEach(el => {
        level.push({
          id: el.level.id,
          code: el.level.code,
          value: el.percent,
        })
      })
      let URL = '/placement-test/create'
      this.items.forEach(item => {
        if (item.fieldLabel !== undefined && action === 'create') {
          questions.push({
            question: item.fieldLabel,
            // priority: parseInt(item.order, 10),
            priority: this.items.indexOf(item) + 1,
            group_id: item.group.id,
          })
        }
        if (item.fieldLabel !== undefined && action === 'update') {
          URL = `/placement-test/update/${this.placementTest.id}`
          questions.push({
            question: item.fieldLabel,
            question_priority: this.items.indexOf(item) + 1,
            group_id: item.group.id,
            question_id: item.question_id,
          })
        }
      })
      this.$http.post(URL, {
        name: this.name,
        description: this.description,
        questions,
        level,
      }).then(res => {
        this.$emit('close-modal')
        this.$emit('refetch-data')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: err.response.data.errors.name[0],
            icon: 'CheckIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  .repeater-form {
    overflow: hidden;
    transition: .35s height;
  }
  .ql-editor  {
    min-height: 150px;
  }
  #create-modal___BV_modal_content_ {
    // position: relative;
  }
  #vs15__listbox {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
  }
  /*#create-modal___BV_modal_body_ {*/
  /*  max-height: 80vh;*/
  /*  overflow-y: auto;*/
  /*}*/
  .placement-test-wizard {
    max-height: 80vh;
    overflow-y: auto;
    overflow: -webkit-paged-y;
  }
</style>
