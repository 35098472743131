<!-- master modal for all operations -->
<template>
  <b-modal
    id="modal-center"
    class="big-modal-abdelatif"
    :visible="isBigModalActive"
    footer-class="d-flex justify-content-between"
    :hide-footer="true"
    size="xl"
    static
    scrollable
    centered
    @change="(val) => $emit('update:isBigModalActive', val)"
  >
    <template #modal-title>
      <h4> Group code : {{ currentCourseData.group }}  <small> <span v-if="tabIndex == 4">Editing course ID : {{record.id}}</span></small></h4>
    </template>

    <div>
      <b-card no-body>
        <b-tabs v-model="tabIndex" card justified>

          <b-tab
            title="Overview"
            :active="currentCourseData.tab === 'overview'"
            @click="currentCourseData.tab = 'overview'"
          >
            <b-row v-if="currentCourseData.tab === 'overview' || isLoadedOverview">
              <b-col sm="5">
                <b-card>
                  <b-card-title class="mb-1">
                    Courses ratio
                  </b-card-title>
                  <b-card-sub-title>
                    Registrations on various levels
                  </b-card-sub-title>
                  <course-charts :courseChart="currentCourseData.registrations_pie" />
                </b-card>
              </b-col>
              <b-col sm="7">
                <courses-list-group-availlability :singleCourseGroup="currentCourseData.group" />
              </b-col>
            </b-row>
          </b-tab>

          <b-tab
            title="Courses Group"
            :active="currentCourseData.tab === 'group'"
            @click="currentCourseData.tab = 'group'; "
          >
            <courses-list-group
              v-if="currentCourseData.tab === 'group'"
              :singleCourseGroup="currentCourseData.group"
              :course="currentCourseData"
              @suggest-classrooms="$emit('suggest-classrooms', record, selectedDays)"
              @suggest-teacher="$emit('suggest-teacher', record, selectedDays)"
              @new-course-data="getEditData($store.state.coursesStoreModule.singleCourse)"
              @reset-group="$emit('reset-group', currentCourseData.id)"
            />
          </b-tab>

          <b-tab
            title="Registrations"
            @click="currentCourseData.tab = 'registration'"
          >
            <registrations-list
              v-if="currentCourseData.tab === 'registration'"
              key="(Math.random() + 1).toString(36).substring(7)"
              :groupId="currentCourseData.group"
              :group-courses="groupCourses"
            />
          </b-tab>

          <b-tab @click="currentCourseData.tab = 'note'" title="Notes">
            <chat-module
              :active="currentCourseData.tab === 'note'"
              :model-id="currentCourseData.id"
              model-type="course"
            />
          </b-tab>

          <b-tab
            no-body
            title="Edit"
            :active="currentCourseData.tab === 'edit'"
            @click="currentCourseData.tab = 'edit'"
          >
            <b-form v-if="currentCourseData.tab === 'edit'" @submit.prevent>
              <b-container>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Level"
                      label-for="level"
                    >
                      <v-select
                        id="level"
                        v-model="record.level"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="['all', 'beginner', 'intermediate', 'advanced']"
                        :reduce="x => x"
                        label="name"
                        placeholder="choose a level"
                        @input="filterLevel(record.level)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Mastercourse"
                      label-for="mastercourse"
                    >
                      <v-select
                        id="mastercourse"
                        v-model="record.master_course"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="filteredMastercourses.length > 0 ? filteredMastercourses : masters"
                        label="code"
                        placeholder="choose course Master"
                      >
                        <template #option="{ name, code }">
                          <span class="d-block"> {{ code }}</span>
                          <small class="text-muted">{{ name }}</small>
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors.mastercourse_id[0] }}</small>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Days"
                      label-for="days"
                    >
                      <b-form-checkbox-group
                        id="days"
                        v-model="selectedDays"
                        :options="days"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row />
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Start date"
                      label-for="dstart"
                    >
                      <b-form-datepicker
                        id="dstart"
                        v-model="record.date_start"
                        :date-disabled-fn="dateDisabled"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        locale="en-UK"
                        :start-weekday="1"
                      />
                      <!-- <flat-pickr
                        id="dstart"
                        v-model="record.date_start"
                        placeholder="Date start of the course"
                        class="form-control input"
                        :config="{ altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 } }"
                      /> -->
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="End date"
                      label-for="dend"
                    >
                      <b-form-datepicker
                        id="dend"
                        v-model="record.date_end"
                        :date-disabled-fn="dateDisabled"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        locale="en-UK"
                        :start-weekday="1"
                      />
                      <!-- <flat-pickr
                        v-model="record.date_end"
                        placeholder="Date end of the course"
                        class="form-control input"
                        :config="{ altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 } }"
                        @input="errors.date_end = []"
                      /> -->
                      <small
                        v-if="errors"
                        class="text-danger"
                      >{{ errors.date_end[0] }}</small>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Start time"
                      label-for="st"
                    >
                      <flat-pickr
                        id="st"
                        v-model="record.time_from"
                        placeholder="When it starts"
                        class="form-control input"
                        :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="End time"
                      label-for="et"
                    >
                      <flat-pickr
                        id="et"
                        v-model="record.time_to"
                        placeholder="When it ends"
                        class="form-control input"
                        :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
                        @input="errors.time_to = []"
                      />
                      <small
                        v-if="errors"
                        class="text-danger"
                      >{{ errors.time_to[0] }}</small>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Classroom"
                      label-for="classroom"
                    >
                      <div class="d-flex align-items-center">
                        <v-select
                          v-model="record.classroom_id"
                          style="width: 100%"
                          class="d-inline-block mr-50"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="availableOnly ? classrooms.filter(el => el.available === 'available') : classrooms"
                          label="code"
                          placeholder="choose course classroom"
                          :selectable="el => el.available === 'available'"
                          @input="(val) => record.students_limit = val ? val.max_students : ''"
                        >
                          <template #option="{ code, available, max_students }">
                            <div class="row">
                              <strong class="col-4 d-block"> {{ code }}</strong>
                              <small class="col-5 text-secondary">Students limit: <strong>{{ max_students }}</strong></small>
                              <small class="col-3 d-flex justify-content-end">
                                <b-badge
                                  pill
                                  :variant="`light-${available === 'available' ? 'success' : 'danger'}`"
                                  class="text-capitalize"
                                >
                                  {{ available === 'available' ? 'Available' : 'Unavailable' }}
                                </b-badge>
                              </small>
                              <!-- <small class="ml-50" :class="available ? 'text-success' : 'text-danger'">{{ available ? 'Available' : 'Unavailable' }}</small> -->
                            </div>
                          </template>
                        </v-select>
                        <!-- <b-button
                          v-model="availableOnly"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          :disabled="!record.date_start.length || !record.date_end.length || !record.time_from.length || !record.time_to.length || !selectedDays.length || record.students_limit.length === 0"
                          type="submit"
                          :variant="availableOnly ? 'success' : 'secondary'"
                          class="text-nowrap p-50 py-75"
                          @click="availableOnly = !availableOnly"
                        >
                          Available only
                        </b-button> -->
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Teachers"
                      label-for="teacher"
                    >
                      <div class="d-flex align-items-center">
                        <v-select
                          id="teacher"
                          v-model="selectedTeachers"
                          style="width: 100%"
                          class="d-inline-block mr-50 selectedTeachers"
                          multiple
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="teachers"
                          :disabled="!record.edit"
                          placeholder="choose course teacher"
                          :selectable="el => el.available === 'available'"
                        >
                          <template #option="{ name, code, available }">
                            <div class="d-flex align-items-center justify-content-between">
                              <span class="d-block"> {{ code }}</span>
                              <small class="ml-50">
                                <b-badge
                                  pill
                                  :variant="`light-${available === 'available' ? 'success' : 'danger'}`"
                                  class="text-capitalize"
                                >
                                  {{ available === 'available' ? 'Available' : 'Unavailable' }}
                                </b-badge>
                              </small>
                              <!-- <small class="ml-50" :class="available ? 'text-success' : 'text-danger'">{{ available ? 'Available' : 'Unavailable' }}</small> -->
                            </div>
                            <small class="text-muted">{{ name }}</small>
                          </template>
                          <template #selected-option="{ code, by }">
                            <div v-if="by !== 'current'" class="flex">
                              <feather-icon
                                :icon="by === 'teacher' ? 'StarIcon' : 'ClockIcon'"
                                size="14"
                                :class="by === 'teacher' ? 'text-indigo' : 'text-warning'"
                                class="align-middle mr-50"
                              />
                              <span :class="by === 'teacher' ? 'pending-by-teacher' : 'pending-by-admin'" class="mt-25">{{ code }}</span>
                            </div>
                            <div v-if="by === 'current'" class="flex">
                              <feather-icon
                                icon="CheckCircleIcon"
                                size="16"
                                class="align-middle mr-50 text-success"
                              />
                              <span class="text-success">{{ code }}</span>
                            </div>
                          </template>
                        </v-select>
                        <!-- <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          :disabled="!record.date_start.length || !record.date_end.length || !record.time_from.length || !record.time_to.length || !selectedDays.length || record.students_limit.length === 0"
                          type="submit"
                          variant="primary"
                          @click="$emit('suggest-teacher', record, selectedDays)"
                        >
                          Suggest
                        </b-button> -->
                      </div>
                      <div class="d-flex">
                        <div class="d-flex align-items-center mr-1">
                          <feather-icon
                            icon="ClockIcon"
                            size="16"
                            class="align-middle mr-50 text-warning"
                          />
                          <label class="text-warning mt-25">Suggested by administration</label>
                        </div>
                        <div class="d-flex align-items-center mr-1">
                          <feather-icon
                            icon="StarIcon"
                            size="16"
                            class="align-middle mr-50 text-indigo"
                          />
                          <label class="text-indigo mt-25">Suggested by teacher</label>
                        </div>
                        <div class="d-flex align-items-center mr-1">
                          <feather-icon
                            icon="CheckCircleIcon"
                            size="16"
                            class="align-middle mr-50 text-success"
                          />
                          <label class="text-success mt-25">Current teacher</label>
                        </div>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Students limit"
                      label-for="studentslimit"
                    >
                      <b-form-input
                        id="studentslimit"
                        v-model="record.students_limit"
                        type="number"
                        placeholder="Students limit"
                        class="input"
                      />
                      <small
                        v-if="record.classroom_id && record.students_limit > record.classroom_id.max_students"
                        class="text-danger"
                      >
                        The group registrations is higher than the classroom limit.
                      </small>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Sessions"
                      label-for="sessions"
                    >
                      <b-form-input
                        id="sessions"
                        v-model="record.attendancecolumns"
                        type="number"
                        placeholder="Sessions Number (attendance columns)"
                        class="input"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Status"
                      label-for="status"
                    >
                      <v-select
                        id="status"
                        v-model="record.status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="statusArray"
                        :reduce="x => x.value"
                        placeholder="Status"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Group"
                      label-for="group"
                    >
                      <b-form-input
                        id="group"
                        v-model="record.group"
                        placeholder="Course group"
                        class="input"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Price"
                      label-for="price"
                    >
                      <b-form-input
                        id="price"
                        v-model="record.price"
                        type="number"
                        placeholder="Course price"
                        class="input"
                      />
                      <small @click="modalPromoPrices(record.id)" style="cursor: pointer">Discount ?</small>
                      <!-- prices discount modal-->
                      <PromoPrices
                          v-if="promoPricesData.active"
                          :courseId="promoPricesData.courseId"
                          :isModalSActive.sync="promoPricesData.active"
                          @refetch-data="refetchData"
                      />
                      <small
                        v-if="errors"
                        class="text-danger"
                      >{{ errors.price[0] }}</small>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Hours"
                      label-for="hours"
                    >
                      <b-form-input
                        id="hours"
                        v-model="record.hours"
                        placeholder="Total hours"
                        class="input"
                        type="number"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      :disabled="limitMessage"
                      class="mr-1"
                      style="margin-bottom: 30px"
                      @click="updateCourse(null)"
                    >
                      Update
                    </b-button>
                  </b-col>
                </b-row>
              </b-container>
            </b-form>

          </b-tab>
          <!--          <b-tab title="Reviews">-->
          <!--            <reviews-list :groupID="currentCourseData.group" :group-courses="groupCourses" />-->
          <!--          </b-tab>-->
        </b-tabs>
      </b-card>
    </div>
    <b-modal
      id="override-modal"
      title="Are you sure you want to continue?"
      ok-title="Yes"
      cancel-title="No"
      centered
      @ok="updateCourse('yes')"
    >
      <h5>{{ overrideMesssage }}</h5>
    </b-modal>
  </b-modal>
</template>

<script>
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow, BCol, BFormCheckboxGroup, BContainer, BForm, BFormGroup, BModal, BFormInput, BButton, BCard,
  BTab, BTabs, BCardTitle, BCardSubTitle, BBadge, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import ChatModule from '@/views/admin/ChatModule.vue'
import RegistrationsList from '@/views/courses/registrations1/registrations-list/RegistrationsList.vue'
// import ReviewsList from '@/views/admin/reviews-list/ReviewsList.vue'
import PromoPrices from '@/views/courses/admin/courses-list/partials/PromoPrices.vue'
import CoursesListGroup from './CoursesListGroup.vue'
import CoursesListGroupAvaillability from './CoursesListGroupAvaillability.vue'
import CourseCharts from './courseCharts.vue'
// import { onMounted } from 'vue'

export default {
  name: 'CoursesBigModal',
  components: {
    CoursesListGroupAvaillability,
    CourseCharts,
    CoursesListGroup,
    // ReviewsList,
    BRow,
    BCol,
    BFormCheckboxGroup,
    BContainer,
    BForm,
    BFormGroup,
    BModal,
    BFormInput,
    BButton,
    BCard,
    BTab,
    BTabs,
    BCardTitle,
    BCardSubTitle,
    BBadge,
    BFormDatepicker,
    vSelect,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ChatModule,
    'registrations-list': RegistrationsList,
    PromoPrices,
  },
  directives: {
    Ripple,
  },
  props: {
    isBigModalActive: {
      type: Boolean,
      required: true,
    },
    masters: {
      type: Array,
      required: true,
    },
    // classrooms: {
    //   type: Array,
    //   required: true,
    // },
    // teachers: {
    //   type: Array,
    //   required: true,
    // },
    currentCourseData: {
      type: Object,
      required: true,
    },
    groupName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      availableOnly: false,
      groupCourses: [],
      filteredMastercourses: [],
      overrideMesssage: '',
      tabIndex: 1,
      statusArray: [
        { name: 'Active', value: 'active', color: 'primary' },
        { name: 'Hidden', value: 'hidden', color: 'primary' },
        { name: 'Past', value: 'past', color: 'primary' },
        { name: 'Deleted', value: 'deleted', color: 'primary' },
        { name: 'Planned', value: 'planned', color: 'primary' },
      ],
      classrooms: [],
      teachers: [],
    }
  },
  mounted() {
    if (typeof this.currentCourseData !== 'undefined') {
      this.record = this.currentCourseData
      this.selectedTeachers = this.currentCourseData.currentTeacher ? [this.currentCourseData.currentTeacher] : this.currentCourseData.pendingTeachers
      this.record.price = (this.record.price !== null) ? Math.round(this.record.price) : null
      this.record.classroom_id = this.currentCourseData.classroom
      this.selectedDays = (this.currentCourseData.days !== null) ? this.currentCourseData.days.toString().split(',') : null
      this.isAddSimilar = true
      this.$emit('get-classrooms', this.record.id)
    }
    this.$http.get('/course-list/data', { params: { group_course_name: this.currentCourseData.group, perPage: 100 } })
    .then(res => {
      this.groupCourses = res.data.rows.map(x => ({ label: x.course_name, value: x.id, date: x.date_start }))
      this.groupCourses.unshift({ label: 'All', value: null })
    })
    store.dispatch('coursesStoreModule/getClassroomsByCourse', { course_id: this.currentCourseData.id }).then(res => { this.classrooms = res.data.allClassrooms })
    store.dispatch('coursesStoreModule/getTeachersByCourse', { course_id: this.currentCourseData.id }).then(res => { this.teachers = res.data.allTeachers })
    //  @todo:check days
  },
  methods: {
    dateDisabled(ymd, date) {
      // disable weekdays
      const weekday = date.getDay()
      return weekday === (!this.selectedDays.includes('mo') ? 1 : null)
          || weekday === (!this.selectedDays.includes('tu') ? 2 : null)
          || weekday === (!this.selectedDays.includes('we') ? 3 : null)
          || weekday === (!this.selectedDays.includes('th') ? 4 : null)
          || weekday === (!this.selectedDays.includes('fr') ? 5 : null)
          || weekday === (!this.selectedDays.includes('sa') ? 6 : null)
          || weekday === (!this.selectedDays.includes('su') ? 0 : null)
    },
    filterLevel(level) {
      this.filteredMastercourses = this.masters
      if (level === 'all') return
      this.filteredMastercourses = this.masters.filter(mCourse => mCourse.level === level)
    },
    getEditData(data) {
      this.currentCourseData.tab = 'edit'
      this.tabIndex = 4
      this.record = data.data
      this.record.classroom_id = this.record.classroom
      this.selectedTeachers = data.currentTeacher ? [data.currentTeacher] : data.pendingTeachers
      this.record.price = (data.data.price !== null) ? Math.round(data.data.price) : null
      this.selectedDays = (data.data.days !== null) ? data.data.days.toString().split(',') : null
      // this.isAddSimilar = true
    },
  },
  setup(props, { emit }) {
    const toast = useToast()
    const record = ref({
      level: '',
      group: '',
      mastercourse_id: '',
      classroom_id: '',
      date_start: '',
      date_end: '',
      time_from: '',
      time_to: '',
      days: '',
      hours: '',
      teachers: [],
      price: '',
      status: '',
      approved: 'pending',
      attendancecolumns: '',
      students_limit: '',
    })
    const selectedTeachers = ref([])
    const singleCourseId = ref(1111)
    const isAddSimilar = ref(false)
    const selectedDays = ref([])
    const isOverView = ref(false)
    const days = ref([
      { text: 'Monday', value: 'mo' },
      { text: 'Tuesday', value: 'tu' },
      { text: 'Wednesday', value: 'we' },
      { text: 'Thursday', value: 'th' },
      { text: 'Friday', value: 'fr' },
      { text: 'Saturday', value: 'sa' },
      { text: 'Sunday', value: 'su' },
    ])
    const errors = ref({
      time_to: [],
      date_end: [],
      price: [],
      mastercourse_id: [],
    })
    const limitMessage = ref(null)
    /* eslint-disable no-unused-expressions */
    // eslint-disable-next-line func-names
    const updateCourse = function (override) {
      record.value.days = selectedDays.value.toString()
      const object = { ...record.value }
      object.teachers = selectedTeachers.value.length > 0 ? selectedTeachers.value.filter(el => el.by !== 'teacher').map(teacher => (teacher.id ? teacher.id : teacher.teacher_id)) : []
      if (object.classroom_id) {
        object.classroom_id = object.classroom_id.id ? object.classroom_id.id : object.classroom_id
      }
      if (!object.override) {
        if (override === 'yes') {
          object.override = override
        }
      } else {
          delete object.override
        }
      store.dispatch('coursesStoreModule/updateCourse', { object })
      .then(res => {
        toast({
            component: ToastificationContent,
            props: {
            title: res.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'warning',
            },
        })
        if (res.data.response === 'warning') {
          this.overrideMesssage = res.data.message
          return this.$bvModal.show('override-modal')
          // emit('open-override-modal', record.value)
          // isOverrideModalActive.value = true
        }
        emit('refetch-data')
        return emit('update:isBigModalActive', false)
      })
        .catch(error => {
          toast({
              component: ToastificationContent,
              props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              },
          })
          if (error.response.data.errors !== undefined) {
            errors.value.mastercourse_id = error.response.data.errors.mastercourse_id
          }
      })
    }
    const promoPricesData = ref({
      active: false,
      courseId: null,
    })
    const modalPromoPrices = id => {
      promoPricesData.value = {
        active: true,
        courseId: id,
      }
    }
    const refPromoData = ref(null)
    const refetchData = () => {
      refPromoData.value.refresh()
    }
    const formatDateTime = dateTime => {
      if (typeof dateTime !== 'undefined') {
        const timestamp = dateTime.split(' ')
        const fullTime = (typeof timestamp[1] !== 'undefined') ? timestamp[1] : ''
        const fullDate = (typeof timestamp[0] !== 'undefined') ? timestamp[0] : ''
        let dateArr
        let dateStr = null
        if (fullDate !== '') {
          dateArr = fullDate.split('-')
          dateStr = `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`
        }
        return `${dateStr} ${fullTime}`
      }
      return ''
    }

    return {
      updateCourse,
      selectedDays,
      isAddSimilar,
      days,
      record,
      singleCourseId,
      formatDateTime,
      isOverView,
      limitMessage,
      selectedTeachers,
      errors,
      promoPricesData,
      modalPromoPrices,
      refetchData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
  label{
    font-weight: bold;
  }
  .modal-title {
    font-size: 16px;
  }
  // .current-teacher .vs__selected {
  //   background-color: #28c76f !important;
  // }
  .selectedTeachers .vs__selected {
    background-color: transparent !important;
    border: #d3dfeb 1px solid !important;
  }
  .selectedTeachers .vs__selected-options {
    max-height: 100% !important;
  }
  .vs__deselect svg {
    color: #4b4b4b !important;
  }
  .pending-by-teacher, .text-indigo {
    color: #6610f2 !important
  }
  .pending-by-admin {
    color: #ff9f43 !important
  }
  .bullet-indigo {
    background-color: #6610f2 !important;
  }
  // .pending-by-teacher-icon {
  //   background-color: #6610f2 !important
  // }
  // .pending-by-admin-icon {
  //   background-color: #ff9f43 !important
  // }
</style>
