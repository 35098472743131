<!-- eslint-disable -->
<template>
  <b-card>

    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="optionsLocal.avatar"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          {{ $t('settings.general.Upload') }}
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        />
        <!--/ upload button -->
        
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <validation-observer
      ref="generalData"
    >
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <validation-provider
                    #default="{ errors }"
                    name="Username"
                    vid="username"
                    rules="required"
            >
            <b-form-group
              :label="$t('settings.general.Username')"
              label-for="account-username"
              :class="errors.length > 0 ? 'is-invalid':null"
            >

                <b-form-input
                  v-model="optionsLocal.username"
                  placeholder="Username"
                  :state="errors.length > 0 ? false:null"
                  name="username"
                  :disabled="userRole === 'teacher'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('settings.general.Name')"
              label-for="account-name"
            >
              <b-form-input
                v-model="optionsLocal.fullName"
                name="name"
                placeholder="Name"
              />
              <small
                v-if="userRole === 'student'"
                class="text-secondary">Requires admin approval before name actually changes
              </small>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="E-mail"
              label-for="account-e-mail"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  v-model="optionsLocal.email"
                  name="email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Email"
                  :disabled="userRole === 'teacher'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>
            <b-form-group
              label="Personal E-mail"
              label-for="account-e-mail"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="email"
              >
                <b-form-input
                  v-model="optionsLocal.personalEmail"
                  name="email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Personal Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('settings.general.Company')"
              label-for="account-company"
            >
              <b-form-input
                v-model="optionsLocal.company"
                name="company"
                placeholder="Company name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" />
          <b-col v-if="userRole !== 'student' || userRole !== 'teacher'" cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click.prevent="save"
            >
              {{ $t('settings.Save Changes') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
      userRole: '',
      oldName: '',
      // validation rules
      required,
      email,
    }
  },
  mounted() {
    const userData = getUserData()
    this.optionsLocal.company === 'null' ? this.optionsLocal.company = null : this.optionsLocal.company
    this.optionsLocal.personalEmail === 'null' ? this.optionsLocal.personalEmail = null : this.optionsLocal.personalEmail
    this.optionsLocal.avatar = `${this.optionsLocal.avatar}`
    this.userRole = userData.role
    this.oldName = userData.fullName
  },
  methods: {
    save() {
      var formData = new FormData();
      formData.append('avatar', this.$refs.refInputEl.files[0])
      if (this.$refs.refInputEl.files[0] === undefined) {
        formData.delete('avatar')
        delete this.optionsLocal.avatar
      }
      formData.append('name', this.optionsLocal['fullName'])
      Object.keys(this.optionsLocal).forEach(key => {
        formData.append(key, this.optionsLocal[key])
      })
      
      this.$http.post('/account-setting/general', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
        },
      }).then((response) => {
          this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          text: response.data.message,
                          icon: 'CheckIcon',
                        },
                    })
                    if (this.oldName !== this.optionsLocal.fullName && this.userRole === 'student') {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          text: `Your request is sent for approval`,
                          icon: 'LoaderIcon',
                        },
                      })
                    }
        }).catch(error => {
          this.$refs.generalData.setErrors(error.response.data.errors)
        })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { previewEl.value.src = base64 })
    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>