import axios from '@axios'

export default {
  namespaced: true,
  state: {
    elements: {
      rows: [],
      totalRecords: 0,
    },
  },
  getters: {},
  /* eslint-disable camelcase */
  mutations: {
    setElements(state, elements) {
      state.elements = elements
    },
  },
  actions: {
    fetchElements(store, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/awaiting/approvals/historical', { params: queryParams })
          .then(response => {
            store.commit('setElements', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
