<template>
  <b-list-group>
    <span
      v-if="!sessions.length"
      class="text-center"
    >
      {{ $t('Nothing to show') }}
    </span>
    <b-list-group-item
      v-for="session in sessions"
      :key="session.session_id"
      class="row"
      style="cursor: default"
    >
      <div class="row">
        <div class="col-12 col-md-5">
          <span
            v-b-tooltip.hover.left="`Lesson ${session.lesson_number}`"
            class="mr-50"
          >
            <b>{{ session.lesson_number }}</b>
          </span>
          <b
            v-if="session.course"
            v-b-tooltip.hover.left="`Lesson ${session.course.session_number}`">
            {{ session.course.session_number }}
          </b>
          <b-button
            v-if="session.course"
            v-b-tooltip.hover.left="`${session.course.course_name}`"
            v-b-modal="getModalId(session.session_id)"
            variant="flat-dark"
            class="text-nowrap"
            size="sm"
          >
            {{ session.course.course_master_code }} <b>{{ session.course.course_group }}</b>
          </b-button>
          <b-button
            v-if="userData.role === 'student' && session.zoom_link"
            class="ml-50"
            :href="session.zoom_link"
            target="_blank"
            size="sm"
            variant="flat-primary"
          >
            Classroom link
            <feather-icon icon="ExternalLinkIcon" />
          </b-button>
        </div>
        <div class="p-25 col-5 col-md-4 text-nowrap ml-1 ml-md-0">
          <b class="d-inline">{{ session.course ? session.course.session_date : '' }}</b>
          <span
            v-if="session.course"
            class="mr-1 text-truncate d-inline"
            style="max-width: 100px;"
          >
            <b v-if="session.session_date">{{ session.session_date }}</b> {{ session.course.course_time_from }} - {{ session.course.course_time_to }}
          </span>
        </div>
        <div class="col-6 col-md-3 d-flex align-items-center justify-content-end">
          <b-button
            v-if="session.homework && session.homework.link"
            v-b-tooltip.hover.left="'Homework'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :href="session.homework.link"
            target="_blank"
            class="btn-icon rounded-circle"
            variant="flat-info"
            style="padding: 0; margin-right: 15px;"
          >
            <feather-icon icon="BookOpenIcon" />
          </b-button>
          <b-badge
            v-if="session.classroom && session.classroom.classroom_name"
            v-b-tooltip.hover.right="`${session.classroom.classroom_name ? 'Classroom: '+ session.classroom.classroom_name : ''}`"
            class="mr-1 text-truncate"
            variant="light-secondary"
          >
            <!-- style="max-width: 100px;" -->
            {{ session.classroom.classroom_name }}
          </b-badge>
          <b-button
            v-if="userData.role === 'teacher' && session.classroom && session.classroom.zoom_link"
            v-b-tooltip.hover.top="'Zoom link'"
            :href="session.classroom.zoom_link"
            class="btn-icon p-25"
            target="_blank"
            size="sm"
            variant="flat-primary"
          >
            <feather-icon icon="ExternalLinkIcon" />
          </b-button>
        </div>
        <b-modal
          :id="`modal-${session.session_id}`"
          ok-only
          centered
          size="xl"
          hide-footer
          no-close-on-backdrop
        >
          <template #modal-title>
            Attendance list for group <b>{{ session.course.course_group }}</b> level <b>{{ session.course.course_master_code }}</b> Day <b>{{ session.session_date }}</b> ( Classroom : <b>{{ session.classroom.classroom_name }}</b>)
          </template>
          <students-attendance
            :session-id="session.session_id"
            :session-number="session.course.session_number"
            @close-modal="$bvModal.hide(`modal-${session.session_id}`)"
          />
        </b-modal>
      </div>
    </b-list-group-item>
  </b-list-group>
</template>
<script>
/* eslint-disable object-curly-newline */
import { BListGroup, BListGroupItem, BBadge, VBTooltip, BButton, BModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import StudentsAttendance from '@/views/teacher/attendance-calendar/calendar-event-handler/StudentsAttendance.vue'

export default {
  components: {
    StudentsAttendance,
    BListGroup,
    BListGroupItem,
    BBadge,
    BModal,
    BButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    sessions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.userData),
    }
  },
  setup() {
    const getModalId = id => `modal-${id}`
    /* eslint-disable camelcase */
    return {
      getModalId,
    }
  },
}
</script>
