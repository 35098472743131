<template>
  <b-form>
    <b-row>
      <b-col
        v-for="socialField in socialInputs"
        :key="socialField.dataField"
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          :label="socialField.label"
          :label-for="socialField.dataField"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                size="16"
                :icon="socialField.icon"
              />
            </b-input-group-prepend>
            <b-form-input
              :id="socialField.dataField"
              v-model="userDataSocial[socialField.dataField]"
              type="url"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col class="mt-2">
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="onSave"
        >
          {{ $t('Save Changes') }}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import router from '@/router'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const toast = useToast()

    const userDataSocial = {
      twitter: props.userData.twitter,
      facebook: props.userData.facebook,
      instagram: props.userData.instagram,
    }
    const onSave = () => {
      /* eslint-disable */
      userDataSocial.user_id = router.currentRoute.params.id
      delete props.userData.avatar
      store.dispatch('app-user/updateUser', userDataSocial)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'User informations had been updated',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    // ? dataField is field name of `userDataSocial` object
    const socialInputs = [
      {
        icon: 'TwitterIcon',
        dataField: 'twitter',
        label: 'Twitter',
      },
      {
        icon: 'FacebookIcon',
        dataField: 'facebook',
        label: 'facebook',
      },
      {
        icon: 'InstagramIcon',
        dataField: 'instagram',
        label: 'instagram',
      },
    ]

    return {
      userDataSocial,
      socialInputs,
      onSave,
    }
  },
}
</script>

<style>

</style>
