<template>
  <span>
    <v-select
      v-model="option"
      :value="option"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="title"
      :options="content.options"
      :clearable="false"
      class="test-select d-inline-block mx-50"
      @input="onChangeHandler"
    />
  </span>
</template>

<script>
// import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
    name: 'SelectComponent',
    components: {
      // BFormGroup,
      vSelect,
    },
    props: {
        content: Object,
        idx: Number,
    },
    data() {
        return {
            response: null,
            option: '--Please choose an option--',
        }
    },
    methods: {
        onChangeHandler(event) {
            console.log(event)
            this.$emit('responseevent', { id: this.$props.idx, response: event })
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  /* background-color: #f7f7f7; */
}
.input:focus {
  outline: none;
}
  .test-select {
    min-width: 300px;
  }
</style>
