export default [
  {
    title: 'Courses',
    icon: 'BookIcon',
    children: [
      {
        title: 'List',
        route: 'admin-courses',
        icon: 'BookIcon',
        roles: ['admin'],
      },
      {
        title: 'Groups',
        route: 'admin-group-list',
        icon: 'BookIcon',
        roles: ['admin'],
      },
      {
        title: 'Approval list',
        route: 'admin-course-approval',
        icon: 'BookIcon',
        roles: ['admin'],
      },
      {
        title: 'Calendar',
        route: 'admin-courses-calendar',
        icon: 'ClipboardIcon',
        roles: ['admin'],
      },
      {
        title: 'Master courses',
        route: 'admin-courses-masters',
        icon: 'ClipboardIcon',
        roles: ['admin'],
      },
      // {
      //   title: 'Types',
      //   route: 'admin-courses-types',
      //   roles: ['admin'],
      // },
      // {
      //   title: 'Packages',
      //   route: 'admin-courses-packages',
      //   icon: 'BookIcon',
      //   roles: ['admin'],
      // },
      // {
      //   title: 'All Courses',
      //   route: 'student-courses',
      //   icon: 'BookIcon',
      //   roles: ['student'],
      // },
      // {
      //   title: 'Invoices',
      //   route: 'student-invoices',
      //   icon: 'ClipboardIcon',
      //   roles: ['student'],
      // },
    ],
  },
]
