<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Part of day</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="timeOfDayFilter"
            :options="dayTimeOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:timeOfDayFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Classroom type</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="classroomTypeFilter"
            :options="classroomTypeOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:classroomTypeFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Session type</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="sessionTypeFilter"
            :options="sessionTypeOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:sessionTypeFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Level</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="levelFilter"
            :options="levelOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:levelFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Month</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="monthFilter"
            :options="monthOptions"
            :clearable="false"
            class="w-100"
            label="monthName"
            :reduce="val => val.value"
            @input="(val) => $emit('update:monthFilter', val)"
          >
            <template #option="{ monthName, year }">
              <div class="d-flex justify-content-between">
                <span> {{ monthName }}</span>
                <small v-if="year">{{ year }}</small>
              </div>
            </template>
          </v-select>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {
    timeOfDayFilter: {
      type: [String, null],
      default: null,
    },
    classroomTypeFilter: {
      type: [String, null],
      default: null,
    },
    sessionTypeFilter: {
      type: [String, null],
      default: null,
    },
    monthFilter: {
      type: [String, null],
      default: null,
    },
    levelFilter: {
      type: [String, null],
      default: null,
    },
    dayTimeOptions: {
      type: Array,
      required: true,
    },
    classroomTypeOptions: {
      type: Array,
      required: true,
    },
    sessionTypeOptions: {
      type: Array,
      required: true,
    },
    levelOptions: {
      type: Array,
      required: true,
    },
    monthOptions: {
      type: Array,
      required: true,
    },
  },
  // watch: {
  //   $route(to) {
  //     const role = to.name.split('-')[3]
  //     this.$nextTick(this.changeRole(role))
  //   },
  // },
  // created() {
  //   const role = this.$route.name.split('-')[3]
  //   this.$nextTick(this.changeRole(role))
  // },
  // methods: {
  //   changeRole(role) {
  //     this.$emit('update:timeOfDayFilter', role)
  //   },
  // },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
