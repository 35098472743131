<template>
  <b-card title="Teachers Work Days">
    <vue-apex-charts
      type="radar"
      height="400"
      :options="radarChart.chartOptions"
      :series="radarChart.series"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  props: {
    radarChart: {
      type: Object,
      required: true,
    },
  },
}
</script>
