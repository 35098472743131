<template>
  <div>
    <div
      class="mb-2"
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-modal.modal-1
        variant="primary"
      >
        <feather-icon
          icon="ToolIcon"
          class="mr-50"
        />
        {{ $t('Event Management') }}
      </b-button>
    </div>
    <div
      class="card app-calendar overflow-hidden border admins-calendar"
    >
      <div
        class="text-center bg-transparent"
        :class="isBusyCalendar ? 'spinnerr-expand mt-2' : 'spinnerr'"
      >
        <b-spinner
          variant="primary"
          label="Text Centered"
        />
        <strong class="ml-1 pt-1">{{ $t('Loading...') }}</strong>
      </div>
      <div class="row no-gutters">
        <!-- Sidebar -->
        <div
          class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
          :class="{'show': isCalendarOverlaySidebarActive}"
        >
          <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" />
        </div>

        <!-- Calendar -->
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body">
              <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
              />
            </div>
            <course-table
              v-if="isEventModalActive && selected.name !== 'All'"
              v-model="isEventModalActive"
              @hide-modal="isEventModalActive = false"
            />
          </div>
        </div>
        <b-modal
          id="modal-1"
          title="Event Managment"
          size="lg"
          ok-only
          ok-title="Close"
          no-close-on-backdrop
        >
          <teachers-events
            :itemss="items"
            @add-event="eventAdd"
            @update-event="eventUpdate"
            @delete-event="eventDelete"
          />
        </b-modal>

        <!-- Sidebar Overlay -->
        <div
          class="body-content-overlay"
          :class="{'show': isCalendarOverlaySidebarActive}"
          @click="isCalendarOverlaySidebarActive = false"
        />
        <!-- <calendar-event-handler
          v-model="isEventHandlerSidebarActive"
          :event="event"
          :clear-event-data="clearEventData"
          @remove-event="removeEvent"
          @add-event="addEvent"
          @update-event="updateEvent"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable-next-line */
import { BButton, BModal, VBModal, BSpinner } from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import TeachersEvents from '@/views/admin/teachers-list/TeacherEvents.vue'
import calendarStoreModule from './calendarStoreModule'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
// import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
// import EventsTable from './calendar-event-handler/EventsTable.vue'
import useCalendar from './useCalendar'

export default {
  components: {
    TeachersEvents,
    BButton,
    BModal,
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    BSpinner,
    // CalendarEventHandler,
    // EventsTable,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      selected: { name: 'All' },
      items: [],
    }
  },
  created() {
    this.$http.get('/teacher/event/list')
      /* eslint-disable-next-line no-return-assign */
      .then(res => {
        this.items = res.data.rows
      })
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,

      eventAdd,
      eventUpdate,
      eventDelete,

      isBusyCalendar,

      fetchEvents,
      refetchEvents,
      calendarOptions,
      getClassrooms,
      classroomOptions,
      selectedClassroom,
      classroom_id,
      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventModalActive,
      isModalActive,

    } = useCalendar()

    fetchEvents()
    getClassrooms()
    const refresh = selected => {
      selectedClassroom.value = selected.name
      classroom_id.value = selected.id
      if (selected.name === 'All') { isEventModalActive.value = false }
    }

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,
      selectedClassroom,
      classroom_id,
      getClassrooms,
      refresh,

      eventAdd,
      eventUpdate,
      eventDelete,

      classroomOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventModalActive,
      isModalActive,

      isBusyCalendar,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
.admins-calendar .fc-event-title.fc-sticky {
  color: #2d2d2d;
}
.admins-calendar .fc-event-time {
  color: #2a2e30;
  font-weight: bolder;
}
.spinnerr {
  height: 0px;
  margin: 0 !important;
  padding: 0 !important;
  transition: height 0.3s;
}
.spinnerr-expand {
  height: 75px;
  transition: height 0.3s;
}
</style>
