<template>
  <div>
    <b-modal
      id="modal-center"
      :visible="isModalActive"
      :title="data.name"
      size="xl"
      static
      hide-footer
      @change="(val) => $emit('update:isModalActive', val)"
    >
      <b-overlay
        :show="loading"
        rounded="lg"
        opacity="0.6"
      >
        <template v-slot:overlay>
          <div class="d-flex align-items-center">
            <b-spinner
              small
              type="grow"
              variant="secondary"
            />
            <b-spinner
              type="grow"
              variant="dark"
            />
            <b-spinner
              small
              type="grow"
              variant="secondary"
            />
            <!-- We add an SR only text for screen readers -->
            <span class="sr-only">Please wait...</span>
          </div>
        </template>
        <b-tabs>
          <b-tab
            title="Overview"
            @click="change"
          >
            <b-row>
              <b-col>
                <b-card
                  class="shadow-none"
                >
                  <b-card-title class="mb-1">
                    Expense Ratio
                  </b-card-title>
                  <b-card-sub-title class="mb-2">
                    Spending on various categories
                  </b-card-sub-title>
                  <vue-apex-charts
                    type="donut"
                    height="350"
                    :options="donutChart.chartOptions"
                    :series="donutChart.series"
                  />
                </b-card>
              </b-col>
              <b-col>
                <b-card
                  class="shadow-none"
                  title="General Informations"
                />
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            title="Edit"
            @click="change"
          >
            <b-row>
              <b-col>
                <h5>Edit {{ data.name }}</h5>
                <b-form>
                  <b-row>
                    <b-col>
                      <b-form-group label="Language">
                        <v-select
                          v-model="data.language_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          placeholder="Select"
                          label="name"
                          :options="languages"
                          :reduce="x => x.id"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Course type">
                        <v-select
                          v-model="data.course_type_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          placeholder="Select"
                          label="name"
                          :options="Types"
                          :reduce="x => x.id"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Course description">
                        <v-select
                          v-model="data.course_desc_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          placeholder="Select"
                          label="name"
                          :options="descriptions"
                          :reduce="x => x.id"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Next">
                        <v-select
                          v-model="data.next_master_course_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          placeholder="Select"
                          label="name"
                          :options="masters"
                          :reduce="x => x.id"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Code">
                        <b-form-input
                          v-model="data.code"
                          placeholder="Code"
                          type="text"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Name">
                        <b-form-input
                          v-model="data.name"
                          placeholder="Name"
                          type="text"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Definition">
                        <quill-editor
                          v-model="data.definition"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Description">
                        <quill-editor
                          v-model="data.description"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Visa required">
                        <v-select
                          v-model="data.visa_required"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="yesNoArray"
                          :reduce="x => x.value"
                          placeholder="Select"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Visa day limit">
                        <b-form-input
                          v-model="data.visa_day_limit"
                          placeholder="Visa day limit"
                          type="number"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Payment required">
                        <v-select
                          v-model="data.payment_required"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="yesNoArray"
                          :reduce="x => x.value"
                          placeholder="Select"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Show start">
                        <v-select
                          v-model="data.date_start_visible"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="yesNoArray"
                          :reduce="x => x.value"
                          placeholder="Select"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Show end">
                        <v-select
                          v-model="data.date_end_visible"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="yesNoArray"
                          :reduce="x => x.value"
                          placeholder="Select"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Days to hide"
                        label-for="daysInput"
                      >
                        <b-form-checkbox-group
                          id="daysInput"
                          v-model="data.days_to_hide"
                          :options="days"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="always visible">
                        <v-select
                          v-model="data.always_visible"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="yesNoArray"
                          :reduce="x => x.value"
                          placeholder="Select"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Status"
                        label-for="status"
                      >
                        <v-select
                          id="status"
                          v-model="data.status"
                          label="name"
                          :options="statusArray"
                          :reduce="x => x.value"
                          placeholder="Status"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-form-group>
                    <b-button @click="updateMaster">
                      Update {{ data.name }}
                    </b-button>
                  </b-form-group>
                  <hr>
                  <h5>Attach or detach a course</h5>
                  <b-form-group label="Courses belongs to this master">
                    <b-form-tags
                      size="lg"
                      add-on-change
                      no-outer-focus
                    >
                      <template v-slot="{ disabled }">
                        <ul
                          v-if="data.courses"
                          class="list-inline d-inline-block mb-1"
                        >
                          <li
                            v-for="(tag, index) in data.courses"
                            :key="index"
                            class="list-inline-item"
                          >
                            <b-form-tag
                              :title="tag.created_at"
                              :disabled="disabled"
                              variant="primary"
                              @remove="onOptionRemove(tag)"
                            >
                              {{ tag.created_at }}
                            </b-form-tag>
                          </li>
                        </ul>
                        <b-dropdown
                          size="sm"
                          variant="outline-secondary"
                          block
                          menu-class="w-100"
                        >
                          <template v-slot:button-content>
                            <b-icon icon="tag-fill" /> Choose courses to attach
                          </template>
                          <b-dropdown-item
                            v-for="(option, index) in notBelong"
                            :key="index"
                            @click="onOptionClick(option)"
                          >
                            {{ option.created_at }}
                          </b-dropdown-item>
                          <b-dropdown-text v-if="notBelong.length === 0">
                            There are no courses available to attach
                          </b-dropdown-text>
                        </b-dropdown>
                      </template>
                    </b-form-tags>
                  </b-form-group>
                </b-form>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import {
  BTabs,
  BCard,
  BTab,
  BRow,
  BCol,
  BForm,
  BOverlay,
  BSpinner,
  BCardTitle,
  BCardSubTitle,
  BFormInput,
  BFormCheckboxGroup,
  BButton,
  BFormGroup,
  BFormTags,
  BFormTag,
  BDropdown,
  BDropdownItem,
  BDropdownText,
  BIcon,
} from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    BTabs,
    BCard,
    BTab,
    BCol,
    BRow,
    BForm,
    BOverlay,
    BSpinner,
    BCardTitle,
    BCardSubTitle,
    VueApexCharts,
    BFormInput,
    BFormGroup,
    BFormCheckboxGroup,
    BButton,
    BFormTags,
    BDropdown,
    BFormTag,
    BDropdownItem,
    BDropdownText,
    BIcon,
    // third
    vSelect,
    quillEditor,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    masters: {
      type: Array,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    Types: {
      type: Array,
      required: true,
    },
    descriptions: {
      type: Array,
      required: true,
    },
    notBelong: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const toast = useToast()

    const editorOption = ref({
      modules: {
        toolbar: '#toolbar',
      },
    })
    const currentUser = ref({})
    currentUser.value = { id: '2' }
    const Model = ref({})
    const yesNoArray = ref([
      { name: 'Yes', value: true },
      { name: 'No', value: false },
    ])
    const chartColors = {
      column: {
        series1: '#826af9',
        series2: '#d2b0ff',
        bg: '#f8d3ff',
      },
      success: {
        shade_100: '#7eefc7',
        shade_200: '#06774f',
      },
      donut: {
        series1: '#ffe700',
        series2: '#00d4bd',
        series3: '#826bf8',
        series4: '#2b9bf4',
        series5: '#FFA1A1',
      },
      area: {
        series3: '#a4f8cd',
        series2: '#60f2ca',
        series1: '#2bdac7',
      },
    }
    const donutChart = ref({
      series: [85, 16, 50, 50],
      chartOptions: {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        colors: [
          chartColors.donut.series1,
          chartColors.donut.series5,
          chartColors.donut.series3,
          chartColors.donut.series2,
        ],
        dataLabels: {
          enabled: true,
          formatter(val) {
            // eslint-disable-next-line radix
            return `${parseInt(val)}%`
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}%`
                  },
                },
                total: {
                  show: true,
                  fontSize: '1.5rem',
                  label: 'Operational',
                  formatter() {
                    return '31%'
                  },
                },
              },
            },
          },
        },
        labels: ['Operational', 'Networking', 'Hiring', 'R&D'],
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '1.5rem',
                      },
                      value: {
                        fontSize: '1rem',
                      },
                      total: {
                        fontSize: '1.5rem',
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],
      },
    })
    const days = ref([
      { text: 'Su', value: 'su' },
      { text: 'Mo', value: 'mo' },
      { text: 'Tu', value: 'tu' },
      { text: 'We', value: 'we' },
      { text: 'Th', value: 'th' },
      { text: 'Fr', value: 'fr' },
      { text: 'Sa', value: 'sa' },
    ])
    const statusArray = ref([
      { name: 'Active', value: 'active' },
      { name: 'Deleted', value: 'deleted' },
    ])
    const columns = ref([
      {
        label: 'Name',
        field: 'Name',
      },
      {
        label: 'Nationality',
        field: 'Nationality',
      },
      {
        label: 'End Date',
        field: 'End_Date',
      },
      {
        label: 'Attendance Hours',
        field: 'Attendance_Hours',
      },
      {
        label: 'Attendance%',
        field: 'Attendance_percentage',
      },
    ])
    const searchTerm = ref('')
    const pageLength = ref(3)
    const content = ref('')
    const popoverShow = ref(false)
    const updateMaster = () => {
      const master = { ...props.data }
      master.days_to_hide = master.days_to_hide.toString()
      const { id } = props.data
      store.dispatch('app-tree/updateMCourse', { id, master })
        .then(() => {
          emit('loading')
          emit('refetch')
          toast({
              component: ToastificationContent,
              props: {
              title: 'Updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
              },
          })
        })
        .catch(error => {
            toast({
                component: ToastificationContent,
                props: {
                title: error,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                },
            })
        })
    }
    const change = () => {
      const { id } = props.data
        emit('loading')
        emit('initilize', id)
    }
    const onOptionClick = data => {
      const { id } = props.data
      store.dispatch('app-tree/atachOrDetachCourses', { option: 'attach', course_id: data.id, master_id: id })
      .then(() => {
        emit('initilize', id)
        emit('refetch')
        toast({
            component: ToastificationContent,
            props: {
            title: 'Attached successfully',
            icon: 'CheckIcon',
            variant: 'success',
            },
        })
      })
      .catch(error => {
            toast({
                component: ToastificationContent,
                props: {
                title: error,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                },
            })
        })
    }
    const onOptionRemove = data => {
      const { id } = props.data
      store.dispatch('app-tree/atachOrDetachCourses', { option: 'detach', course_id: data.id, master_id: id })
      .then(() => {
        emit('initilize', id)
        emit('refetch')
        toast({
            component: ToastificationContent,
            props: {
            title: 'Detached successfully',
            icon: 'CheckIcon',
            variant: 'success',
            },
        })
      })
      .catch(error => {
            toast({
                component: ToastificationContent,
                props: {
                title: error,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                },
            })
        })
    }
    return {
      days,
      donutChart,
      columns,
      searchTerm,
      pageLength,
      currentUser,
      popoverShow,
      editorOption,
      content,
      Model,
      yesNoArray,
      statusArray,
      change,
      updateMaster,
      onOptionClick,
      onOptionRemove,
    }
  },
}
</script>

<style>
.msg {
  float: left;
}
.msg-r {
  float: right;
}
.from{
  float: left;
  display: block;
  width: 100%;
  text-align: left;
  font-size: 11px;
}
.text{
  padding: 10px;
  background: #ecf1f8;
  font-size: 13px;
  border-radius: 5px;
  margin-bottom: 3px;
}
.text-blue{
  padding: 10px;
  background: #7367f0;
  font-size: 13px;
  color: #FFFFFF;
  border-radius: 5px;
  margin-bottom: 3px;
}
p {
  margin: 0;
  padding: 0;
}
</style>
