import { ref, watch, computed } from '@vue/composition-api'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import store from '@/store'
import router from '@/router'

export default function useCoursesList() {
  // Use toast
  const toast = useToast()
  const { t } = useI18nUtils()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: t('id'), sortable: true },
    { key: 'course_name', label: 'course name', sortable: true },
    { key: 'group', label: t('group'), sortable: true },
    { key: 'classroom' },
    { key: 'lesson_number' },
    { key: 'date', label: 'date & time', sortable: true },
    { key: 'students_number', label: 'registered students' },
    { key: 'actions', label: t('attendance'), tdClass: 'table-actions' },
    // { key: 'feedbacks', label: t('feedbacks'), sortable: true },
  ]
  const tableColumns1 = [
    // { key: 'id', label: t('id'), sortable: true },
    { key: 'course_name', label: 'course name', sortable: true },
    { key: 'group', label: t('group'), sortable: true },
    { key: 'classroom' },
    { key: 'lesson_number' },
    // { key: 'code', label: t('code'), sortable: true },
    { key: 'date', label: 'date & time', sortable: true },
    { key: 'students_number', label: t('registered students') },
    { key: 'actions', label: t('attendance'), tdClass: 'table-actions' },
    // { key: 'feedbacks', label: t('feedbacks'), sortable: true },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const selectedFilter = ref(null)
  const sortBy = ref('date')
  const isSortDirDesc = ref(true)
  const userData = ref(JSON.parse(localStorage.userData))
  const teacherID = ref(null)
  /* eslint-disable-next-line */
  const role_name = ref(window.location.href.split('/')[3])

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })
  const refetchData = () => {
    refUserListTable.value.refresh()
  }
  const urlParams = new URLSearchParams(window.location.search)
  const urlQueries = router.currentRoute.query
  if (urlQueries.search) searchQuery.value = urlQueries.search
  if (urlQueries.page) currentPage.value = urlQueries.page
  if (urlQueries.perPage) perPage.value = urlQueries.perPage
  if (urlQueries.filter) selectedFilter.value = urlQueries.filter
  /* eslint-disable-next-line */
  watch([currentPage, perPage, selectedFilter, searchQuery],  () => {
    urlParams.set('search', searchQuery.value ? searchQuery.value : '')
    urlParams.set('page', currentPage.value ? currentPage.value : '')
    urlParams.set('perPage', perPage.value ? perPage.value : '')
    urlParams.set('filter', selectedFilter.value ? selectedFilter.value : '')
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams.toString()}`)
    refetchData()
  })
  const isBusy = ref(false)

  const fetchUsers = (ctx, callback) => {
    const payload = {
      search: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      teacher_id: teacherID.value ? teacherID.value : userData.value.id,
      filter: selectedFilter.value,
      // role_name: role_name.value,
    }
    store
      .dispatch('course/fetchCourses', payload)
      .then(res => {
        const users = res.data.rows
        const total = res.data.totalRecords
        callback(users)
        totalUsers.value = total
      })
      .catch(() => {
        isBusy.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching courses list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }
  const resolveAttendanceVariant = attendance => {
    if (attendance > 0) return 'flat-warning'
    if (attendance > 80) return 'flat-success'
    return 'flat-secondary'
  }

  return {
    isBusy,
    fetchUsers,
    tableColumns,
    tableColumns1,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    selectedFilter,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    role_name,
    teacherID,
    userData,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveAttendanceVariant,
    refetchData,
  }
}
