import axios from '@axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'success',
        label: 'Available',
      },
      {
        color: 'danger',
        label: 'Unavailable',
      },
    ],
    levelsOptions: [
      {
        color: 'secondary',
        label: 'A1-A2',
      },
      {
        color: 'warning',
        label: 'B1',
      },
      {
        color: 'danger',
        label: 'B2',
      },
      {
        color: 'success',
        label: 'C1',
      },
    ],
    selectedCalendars: ['Available', 'Unavailable'],
    selectedLevels: ['A1-A2', 'B1', 'B2', 'C1'],
  },
  getters: {},
  mutations: {
    SET_EVENTS(state, response) {
      state.events = response
    },
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
    SET_SELECTED_LEVELS(state, val) {
      state.selectedLevels = val
    },
  },
  actions: {
    /* eslint-disable-next-line */
    fetchEvents(store, { date_start, date_end, teacher_id, filter, level }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/teacher/calendar/data', {
            params: {
              // status: statuss,
              // calendars: calendars.join(','),
              date_start,
              date_end,
              teacher_id,
              filter,
              level,
              // classroom_id,
              // status: 'available',
            },
          })
          .then(response => {
            store.commit('SET_EVENTS', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getClassrooms() {
      return new Promise((resolve, reject) => {
        axios
          .get('/classroom-list/data')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getSession(store, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/session/get', { params: { session_id: id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getLevels() {
      return new Promise((resolve, reject) => {
        axios
          .get('/levels/get')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/calendar/events/${event.id}`, { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    teacherAutoComplete(ctx, { search, role_name }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/autocomplete/user', { search, role_name, perPage: 100 })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    /* eslint-disable camelcase */
    getEventsManagment(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/teacher/event/list', { params: { teacher_id: id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    /* eslint-disable object-curly-newline */
    eventAdd(store, { teacher_id, name, date_start, date_end }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/teacher/event/create', { teacher_id, name, date_start, date_end })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    eventUpdate(store, { id, teacher_id, name, date_start, date_end }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/teacher/event/update/${id}`, { teacher_id, name, date_start, date_end })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    eventDelete(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`teacher/event/delete/${id}`, id)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
