<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewSubstitutionSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-substitution-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ 'Substitute Teacher Request' }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Date start -->
          <validation-provider
            #default="validationContext"
            name="Substitution Start date"
            rules="required"
          >
            <b-form-group
              :label="('Start Date of Absence')"
              label-for="date_start"
              :state="getValidationState(validationContext)"
            >
              <flat-pickr
                v-model="date_start"
                class="form-control"
                :placeholder="$t('Pick a date')"
                :config="{ enableTime: true, altFormat: 'd-m-Y H:i', altInput: true, locale: { firstDayOfWeek: 1 }}"
                @input="getSubsData()"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Date end -->
          <validation-provider
            #default="validationContext"
            name="End date"
            rules="required"
          >
            <b-form-group
              :label="('End Date of Absence')"
              label-for="date_end"
              :state="getValidationState(validationContext)"
            >
              <flat-pickr
                v-model="date_end"
                class="form-control"
                :placeholder="$t('Pick a date')"
                :config="{ enableTime: true, altFormat: 'd-m-Y H:i', altInput: true, locale: { firstDayOfWeek: 1 }}"
                @input="getSubsData()"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Teacher -->
          <validation-provider
            #default="validationContext"
            name="Teacher"
            rules="required"
          >
            <b-form-group
              :label="('Absent teacher')"
              label-for="teacher"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="selectedTeacher"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="teachers"
                :clearable="false"
                @keyup.native="autoComplete($event.target.value, 'teacher')"
                @click.native="autoComplete($event.target.value, 'teacher')"
              >
                <span slot="no-options">
                  <div
                    v-if="isTyping"
                    class="text-center d-flex align-items-center justify-content-center"
                  >
                    <b-spinner
                      variant="primary"
                      label="Text Centered"
                    />
                  </div>
                  <div
                    v-if="!isTyping && !teachers.length"
                    class="text-center d-flex align-items-center justify-content-center"
                  >
                    No matching records found
                  </div>
                </span>
                <template #option="{ name, email }">
                  <span> {{ name }}</span>
                  <small class="d-block text-secondary">{{ email }}</small>
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Course -->
          <validation-provider
            #default="validationContext"
            name="Course"
            rules="required"
          >
            <b-form-group
              label="Affected course"
              label-for="course"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="selectedCourse"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="code"
                :options="courses"
                :clearable="false"
                @keyup.native="autoCompleteCourse($event.target.value, selectedTeacher ? selectedTeacher.id : null)"
                @click.native="autoCompleteCourse('', selectedTeacher ? selectedTeacher.id : null)"
                @input="getSubsData(selectedCourse.id)"
              >
                <span slot="no-options">
                  <div
                    v-if="isTypingCourse"
                    class="text-center d-flex align-items-center justify-content-center"
                  >
                    <b-spinner
                      variant="primary"
                      label="Text Centered"
                    />
                  </div>
                  <div
                    v-if="!isTypingCourse && !courses.length"
                    class="text-center d-flex align-items-center justify-content-center"
                  >
                    No matching records found
                  </div>
                </span>
                <template #option="{ code, group, date_start }">
                  <div class="d-flex justify-content-between">
                    <div>
                      <b class="d-block"> {{ code }}</b>
                      <small v-if="group" class="text-secondary">{{ group }}</small>
                    </div>
                    <small v-if="date_start">{{ date_start }}</small>
                  </div>
                </template>
                <template #selected-option="{ code, group, date_start }">
                  <div class="d-flex justify-content-between">
                      <b class="d-block text-primary"> {{ code }} <small v-if="group" class="text-secondary">({{ group }})</small> <small v-if="date_start" class="ml-auto">{{ date_start }}</small></b>
                  </div>
                </template>
              </v-select>

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Course -->
          <validation-provider
            #default="validationContext"
            name="Substitutes"
          >
            <b-form-group
              :label="('Desired Substitutes')"
              label-for="Substitutes"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="selectedSubs"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="code"
                multiple
                :clearable="false"
                :options="substitutes"
                :selectable="el => el.available === 'available'"
              >
                <span slot="no-options">
                  <div
                    v-if="isLoadingSubstitutes"
                    class="text-center d-flex align-items-center justify-content-center"
                  >
                    <b-spinner
                      variant="primary"
                      label="Text Centered"
                    />
                  </div>
                  <div v-else>
                    Sorry, no matching options.
                  </div>
                </span>
                <template #option="{ name, code, available }">
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="d-block"> {{ code }}</span>
                    <small class="ml-50">
                      <b-badge
                        pill
                        :variant="`light-${available === 'available' ? 'success' : 'danger'}`"
                        class="text-capitalize"
                      >
                        {{ available === 'available' ? 'Available' : 'Unavailable' }}
                      </b-badge>
                    </small>
                    <!-- <small class="ml-50" :class="available ? 'text-success' : 'text-danger'">{{ available ? 'Available' : 'Unavailable' }}</small> -->
                  </div>
                  <small class="text-muted">{{ name }}</small>
                </template>
              </v-select>

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ ('Request') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInvalidFeedback, BButton, BSpinner, BBadge,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, alphaNum, email, min,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import axios from '@axios'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BBadge,
    BSpinner,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSubstitutionSidebarActive',
    event: 'update:is-add-new-substitution-sidebar-active',
  },
  props: {
    isAddNewSubstitutionSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      min,
      countries,
      query1: '',
      query2: '',
      students: [],
      courses: [],
      paidOptions: [{ label: 'No', value: 0 }, { label: 'Yes', value: 1 }],
      isTyping: false,
      isTypingCourse: false,
      methods: [
        { label: 'Card', value: 'card' },
        { label: 'Cash', value: 'cash' },
        { label: 'Online', value: 'online' },
        { label: 'Wire transfer', value: 'wire_transfer' },
      ],
    }
  },
  methods: {
    /* eslint-disable camelcase */
    autoComplete(search, role_name) {
      this.isTyping = true
      axios.post('/autocomplete/user', { search, role_name })
      .then(res => {
        this.isTyping = false
        this.teachers = res.data
      })
    },
    autoCompleteCourse(search, teacher_id) {
      this.isTypingCourse = true
      axios.post('/autocomplete/course', { search, teacher_id })
      .then(res => {
        this.isTypingCourse = false
        this.courses = res.data
      })
    },
  },
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()

    const selectedCourse = ref({})
    const selectedSubs = ref([])
    const substitutes = ref([])
    const selectedTeacher = ref({})
    const date_start = ref('')
    const date_end = ref('')
    const teachers = ref([])
    const subData = {}
    const isLoadingSubstitutes = ref(false)
    const getSubsData = () => {
      if (!selectedCourse.value.id) return
      isLoadingSubstitutes.value = true
        store.dispatch('coursesStoreModule/getTeachersByCourse', { course_id: selectedCourse.value.id, date_start: date_start.value, date_end: date_end.value }).then(res => {
          isLoadingSubstitutes.value = false
          substitutes.value = res.data.allTeachers
        })
      }
    const resetRegistrationData = () => {
      selectedTeacher.value = {}
      selectedCourse.value = {}
      selectedSubs.value = []
      date_start.value = ''
      date_end.value = ''
    }
    const onSubmit = () => {
      subData.teachers = selectedSubs.value.length > 0 ? selectedSubs.value.map(el => el.id) : []
      subData.course_id = selectedCourse.value.id
      subData.date_start = date_start.value
      subData.date_end = date_end.value
      store.dispatch('course/addSubstitute', subData)
        .then(res => {
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          emit('refetch-data')
          emit('update:is-add-new-substitution-sidebar-active', false)
        })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRegistrationData)
    return {
      onSubmit,
      getSubsData,
      // comments,
      selectedTeacher,
      selectedSubs,
      substitutes,
      selectedCourse,
      date_start,
      date_end,
      teachers,
      refFormObserver,
      getValidationState,
      resetForm,
      isLoadingSubstitutes,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
