<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- filter status -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          />
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
                debounce="500"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>
        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.id }}
            </b-link>
          </b-media>
        </template>
        <!-- Column: course -->
        <template #cell(course)="data">
          <span
            v-b-tooltip.hover.top="data.item.course"
            class="d-inline-block text-truncate"
            style="max-width: 180px"
          >
            {{ data.item.course }}
          </span>
        </template>
        <!-- Column: group -->
        <template #cell(course_group)="data">
          <span
            v-b-tooltip.hover.top="data.item.course_group"
            class="d-inline-block text-truncate"
            style="max-width: 180px"
          >
            {{ data.item.course_group }}
          </span>
        </template>
        <!-- Column: session number -->
        <template #cell(session_number)="data">
          <span
            v-b-tooltip.hover.top="data.item.session_number"
            class="d-inline-block text-truncate"
            style="max-width: 180px"
          >
            {{ data.item.session_number }}
          </span>
        </template>
        <template #cell(classroom)="data">
          <span
            v-b-tooltip.hover.top="data.item.classroom"
            class="d-inline-block text-truncate"
            style="max-width: 180px"
          >
            {{ data.item.classroom }}
          </span>
        </template>
        <!-- Column: Date -->
        <template #cell(date)="data">
          <div class="font-weight-bold d-block text-nowrap text-capitalize">
            {{ formatDateTime(data.item.date) }}
          </div>
          <small class="text-muted text-nowrap">{{ data.item.time_from }} - {{ data.item.time_to }}</small>
        </template>
        <template #cell(main_teacher)="data">
          <b-avatar
            v-b-tooltip.hover.top="data.item.current_teacher.name"
            class="pull-up pending-teachers"
            button
            :title="data.item.current_teacher.name"
            :src="data.item.current_teacher.avatar"
          />
        </template>
        <template #cell(substitute_teacher)="data">
          <b-avatar
            v-b-tooltip.hover.top="data.item.substitute_teacher.name"
            class="pull-up pending-teachers"
            button
            :title="data.item.substitute_teacher.name"
            :src="data.item.substitute_teacher.avatar"
          />
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>
          <b-col
            cols="3"
            lg="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BLink,
  BPagination, BSpinner, VBTooltip, BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import useCoursesList from './useCoursesList'
import courseStoreModule from './courseStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BLink,
    BPagination,
    BSpinner,
    BAvatar,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    teacherId: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    /* eslint-disable camelcase */
    const getModal1Id = id => `modal1${id}`
    const COURSE_STORE_MODULE_NAME = 'teachersubstitutes'
    // Register module
    if (!store.hasModule(COURSE_STORE_MODULE_NAME)) store.registerModule(COURSE_STORE_MODULE_NAME, courseStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COURSE_STORE_MODULE_NAME)) store.unregisterModule(COURSE_STORE_MODULE_NAME)
    })
    const {
      isBusy,
      userData,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      apply,
      approve,
      decline,
      teacherID,
    } = useCoursesList()
    if (props.teacherId) { teacherID.value = props.teacherId }
    const formatDateTime = dateTime => {
      if (typeof dateTime !== 'undefined') {
        const timestamp = dateTime.split(' ')
        const fullTime = (typeof timestamp[1] !== 'undefined') ? timestamp[1] : ''
        const fullDate = (typeof timestamp[0] !== 'undefined') ? timestamp[0] : ''
        let dateArr
        let dateStr = null
        if (fullDate !== '') {
          dateArr = fullDate.split('-')
          dateStr = `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`
        }
        return `${dateStr} ${fullTime}`
      }
      return ''
    }
    return {
      isBusy,
      // Modal ids
      getModal1Id,

      formatDateTime,

      userData,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      apply,
      approve,
      decline,
      teacherID,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
