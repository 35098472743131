import axios from '@axios'

export default {
  namespaced: true,
  state: {
    groups: {
      rows: [],
      totalRecords: 0,
    },
    details: {
      rows: [],
      totalRecords: 0,
    },
  },
  getters: {},
  /* eslint-disable camelcase */
  mutations: {
    setGroups(state, elements) {
      state.groups = elements
    },
    setDetails(state, elements) {
      state.details = elements
    },
  },
  actions: {
    fetchGroups(store, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/waiting/list/group/list', { params: queryParams })
          .then(response => {
            store.commit('setGroups', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getDetails(store, group_course_name) {
      return new Promise((resolve, reject) => {
        axios
          .get('/course-list/data', { params: group_course_name })
          .then(response => {
            store.commit('setDetails', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    approveClaim(store, attendance_id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/attendance/claim/approve', attendance_id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createGroup(store, group) {
      return new Promise((resolve, reject) => {
        axios
          .post('/courses/create/new/group', group)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
