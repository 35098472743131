<template>
  <div>
    <validation-observer
      #default="{ handleSubmit }"
      ref="form"
    >
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(eventAdd(item.name, item.date_start, item.date_end, item.day_off.value))"
      >
        <!-- Event name -->
        <validation-provider
          #default="validationContext"
          name="Event name"
          rules="required"
        >
          <b-form-group
            label="Event name"
            label-for="name"
          >
            <b-form-input
              v-model="item.name"
              autofocus
              :state="getValidationState(validationContext)"
              placeholder="Name"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- Date start -->
        <validation-provider
          #default="validationContext"
          name="Start date"
          rules="required"
        >
          <b-form-group
            label="Date start"
            label-for="date_start"
            :state="getValidationState(validationContext)"
          >
            <flat-pickr
              v-model="item.date_start"
              class="form-control"
              :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss', altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 }}"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- Date end -->
        <validation-provider
          #default="validationContext"
          name="End date"
          rules="required"
        >
          <b-form-group
            label="End date"
            label-for="end-date"
            :state="getValidationState(validationContext)"
          >
            <flat-pickr
              v-model="item.date_end"
              class="form-control"
              :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss', altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 }}"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- Day off -->
        <validation-provider
          #default="validationContext"
          name="Day off"
          rules="required"
        >
          <b-form-group
            :state="getValidationState(validationContext)"
            label="Day off"
          >
            <v-select
              v-model="item.day_off"
              label="label"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="dayOffOptions"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-2"
          type="submit"
        >
          Add
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
/* eslint-disable object-curly-newline */
import { BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
// import store from '@/store'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BButton,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      item: {
        name: '',
        date_start: '',
        date_end: '',
        day_off: {},
      },
      dayOffOptions: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
    }
  },
  methods: {
    eventAdd(name, dateStart, dateEnd, dayOff) {
      if (this.$refs.form.flags.valid) {
        this.$emit('event-add', name, dateStart, dateEnd, dayOff)
        this.$emit('hide-modal')
      }
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(() => {})
    return {
      refFormObserver,
      getValidationState,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
