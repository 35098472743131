export default [
  {
    path: '/test/list',
    name: 'test',
    component: () => import('@/views/test/TestList.vue'),
  },
  {
    path: '/test/requests',
    name: 'test-request',
    component: () => import('@/views/test/RequestsList.vue'),
  },
  {
    path: '/test/managment',
    name: 'test-managment',
    component: () => import('@/views/admin/tests/tests-list/Tests.vue'),
  },
]
