import Vue from 'vue'

// axios
import axios from 'axios'
/* eslint-disable  */
const axiosIns = axios.create({
  // You can add your headers here
  Accept: 'application/json',
  // ================================
  baseURL: `https://` + process.env.VUE_APP_DOMAIN + `/api`,
  // baseURL: 'http://localhost:8000/api',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})
/* eslint-disable */
axiosIns.interceptors.response.use((response) => {
  return response
}, error => {
  if (error.response.status === 401) {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('userData')
    window.location.href = '/login'
  }
    return Promise.reject(error)
},
)

Vue.prototype.$http = axiosIns

export default axiosIns
