import axios from '@axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'success',
        label: 'Event',
      },
      {
        color: 'danger',
        label: 'Day_off',
      },
      {
        color: 'info',
        label: 'Teacher',
      },
    ],
    selectedCalendars: ['Event', 'Day_off', 'Teacher'],
  },
  /* eslint-disable object-curly-newline */
  getters: {},
  mutations: {
    SET_EVENTS(state, response) {
      state.events = response
    },
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(store, { date_start, date_end, filter }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/calendar/all-events', {
            params: {
              // status: statuss,
              // calendars: calendars.join(','),
              date_start,
              date_end,
              filter: filter.join(),
              // classroom_id,
              // status: 'available',
            },
          })
          .then(response => {
            store.commit('SET_EVENTS', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getClassrooms() {
      return new Promise((resolve, reject) => {
        axios
          .get('/classroom-list/data')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    eventAdd(store, { teacher_id, name, date_start, date_end }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/teacher/event/create', { teacher_id, name, date_start, date_end })
          .then(response => {
            /* eslint-disable no-param-reassign */
            store.state.calendar.events = response.data
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    eventUpdate(store, { id, teacher_id, name, date_start, date_end }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/teacher/event/update/${id}`, { teacher_id, name, date_start, date_end })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    eventDelete(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`teacher/event/delete/${id}`, id)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
