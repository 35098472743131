<template>
  <div>
    <courses-list-big-modal
      v-if="isBigModalActive"
      :isBigModalActive.sync="isBigModalActive"
      :masters="masters.rows"
      :currentCourseData="currentCourseData"
      @refetch-data="refetchData"
      @initilize="ModalData($event)"
      @loading="loadSpinner"
      @suggest-classrooms="suggestClassrooms"
      @suggest-teacher="suggestTeacher"
      @get-classrooms="fetchClassrooms"
      @reset-group="reinitModal"
    />
    <div class="mb-2">
      <b-row>
        <b-col md-4>
          <b-form-group label="Filters">
            <v-select
              v-model="selectedFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :clearable="false"
              :options="classroomFilters"
              @input="isBusyCalendar = true"
            />
          </b-form-group>
        </b-col>
        <b-col md-4>
          <b-form-group label="Classrooms">
            <v-select
              v-model="selectedClassroom"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="code"
              :clearable="false"
              :options="classroomOptions"
              @input="classroom_id = selectedClassroom.id"
            >
              <template #option="{ code, max_students }">
                <div
                  v-if="code"
                  class="row"
                >
                  <strong class="col-2"> {{ code }}</strong>
                  <small class="col-10">Students limit: <strong>{{ max_students }}</strong></small>
                  <!-- <small class="ml-50" :class="available ? 'text-success' : 'text-danger'">{{ available ? 'Available' : 'Unavailable' }}</small> -->
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <!-- :id="selected.name === 'All' ? 'no-cursor' : 'cursor'" -->
    <div
      class="card app-calendar overflow-hidden border classroom-calendar"
    >
      <div
        class="text-center bg-transparent"
        :class="isBusyCalendar ? 'spinnerr-expand mt-2' : 'spinnerr'"
      >
        <b-spinner
          variant="primary"
          label="Text Centered"
        />
        <strong class="ml-1 pt-1">{{ $t('Loading...') }}</strong>
      </div>
      <div class="row no-gutters">
        <!-- Sidebar -->
        <!-- <div
          class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
          :class="{'show': isCalendarOverlaySidebarActive}"
        >
          <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" />
        </div> -->

        <!-- Calendar -->
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
              />
            </div>
            <!-- <course-table
              v-if="isEventModalActive && selected.name !== 'All'"
              v-model="isEventModalActive"
              @hide-modal="isEventModalActive = false"
            /> -->
          </div>
        </div>

        <!-- Sidebar Overlay -->
        <div
          class="body-content-overlay"
          :class="{'show': isCalendarOverlaySidebarActive}"
          @click="isCalendarOverlaySidebarActive = false"
        />
        <!-- <calendar-event-handler
          v-model="isEventHandlerSidebarActive"
          :event="event"
          :clear-event-data="clearEventData"
          @remove-event="removeEvent"
          @add-event="addEvent"
          @update-event="updateEvent"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable-next-line */
import { BRow, BCol, BFormGroup, VBModal, BSpinner } from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import { onUnmounted, watch } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import CoursesListBigModal from '@/views/courses/admin/courses-list/CoursesListBigModal.vue'
import calendarStoreModule from './calendarStoreModule'
// import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
// import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
// import CourseTable from './calendar-event-handler/CourseTable.vue'
import useCalendar from './useCalendar'

export default {
  components: {
    CoursesListBigModal,
    BRow,
    BCol,
    BFormGroup,
    FullCalendar, // make the <FullCalendar> tag available
    // CalendarSidebar,
    BSpinner,
    // CalendarEventHandler,
    // CourseTable,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      // selected: { name: 'All' },
    }
  },
  // mounted() {
  //   document.getElementsByClassName('statfull').addEventListener('click', this.getClassroom)
  //   // this.$bvModal.show('course-modal')
  // },
  // methods: {
  //   getClassroom() {
  //     const classData = document.getElementsByClassName('statfull')[0].getAttribute('data-classroom')
  //     console.log(classData)
  //   },
  // },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'
    // const spann = document.getElementsByTagName('span')[0]
    // const handleClick = () => {
    //   console.log('hey')
    // }
    // if (spann.addEventListener) spann.addEventListener('click', handleClick, false)
    // else spann.attachEvent('onclick', handleClick)
    // spann.dispatchEvent(new Event('click'))
    // spann.addEventListener('click', handleClick)
    // const spann = () => { document.getElementsByTagName('span').addEventListener('click', handleClick) }

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })
    const {
      currentCourseData,
      isBigModalActive,
      masters,
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,
      getClassrooms,
      classroomOptions,
      selectedClassroom,
      classroom_id,
      selectedFilter,
      classroomFilters,

      courseGroup,
      isGroupModalActive,

      isBusyCalendar,
      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventModalActive,
      isModalActive,
    } = useCalendar()

    fetchEvents()
    getClassrooms()
    // const selected = ref({ id: null, name: 'All' })
    watch([isBusyCalendar], () => {
      if (selectedClassroom.value.name === 'All') {
        // after waiting downloaded data from api, definitely we have to add some time to render data (1s)
        // dont forget to remove event Listener onUnmount ;)
        setTimeout(() => {
          const statfulls = document.querySelectorAll('.statfull')
          statfulls.forEach(statfullDiv => {
            statfullDiv.addEventListener('click', () => {
              // console.log(statfullDiv.getAttribute('data-classroom'))
              // @todo : should implement course selection via normal vuejs state thing, this.dataset.classroom is the classroom id
              // console.log(this.dataset.classroom)
              classroom_id.value = statfullDiv.getAttribute('data-classroom')
              selectedClassroom.value = { id: statfullDiv.getAttribute('data-classroom'), name: statfullDiv.textContent }
              // console.log('hey')
            })
          })
        }, 1000)
      }
    })

    return {
      currentCourseData,
      isBigModalActive,
      masters,
      refCalendar,
      // selected,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,
      selectedClassroom,
      classroom_id,
      getClassrooms,

      courseGroup,

      selectedFilter,
      classroomFilters,

      isBusyCalendar,
      isGroupModalActive,

      classroomOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventModalActive,
      isModalActive,
      // spann,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
@import '@core/scss/vue/libs/vue-select.scss';
.classroom-calendar .fc-daygrid-event:hover {
  background-color: transparent;
  /*cursor: default;*/
}
.classroom-calendar#no-cursor .fc-daygrid-event:hover {
  cursor: default;
}
.classroom-calendar .fc-daygrid-event:hover {
  background-color: transparent;
  cursor: default;
}
.spinnerr {
  height: 0px;
  margin: 0 !important;
  padding: 0 !important;
  transition: height 0.3s;
}

.classroom-calendar span {
  cursor: default;
}
.classroom-calendar span.statfull {
  cursor: pointer;
}
</style>
