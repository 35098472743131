<template>
  <div>
    <!-- User Info: Input Fields -->
    <!-- Media -->
    <div class="border-bottom pb-2">
      <h4>{{ userData.name }}</h4>
      <small>{{ userData.email }}</small>
      <div class="d-flex">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="secondary"
          class="btn-icon mt-50 mr-1 d-block"
          @click="$router.back()"
        >
          <feather-icon
            icon="ArrowLeftIcon"
            class="mr-50"
          />
          <span class="align-middle">Back</span>
        </b-button>
        <b-button
          v-b-modal.modal-1
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="btn-icon mt-50 d-block"
        >
          <feather-icon
            icon="TrashIcon"
            class="mr-50"
          />
          <span class="align-middle">Delete this account</span>
        </b-button>
      </div>
      <b-modal
        id="modal-1"
        :title="userData.name"
        ok-title="Confirm"
        ok-only
        centered
        @ok="remove(userData.student_id)"
      >
        <b-card-text>
          <h5>{{ $t('Are you sure you want to delete') }} <strong>{{ userData.name }}</strong></h5>
        </b-card-text>
      </b-modal>
    </div>
    <br>
    <b-form>

      <b-row>
        <!-- Field: Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('Name')"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="userData.name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('Email')"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.email"
              type="email"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Company -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('Company')"
            label-for="company"
          >
            <b-form-input
              id="company"
              v-model="userData.company"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <!-- <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      type="submit"
      @click="onSave"
    >
      {{ $t('Save Changes') }}
    </b-button> -->
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
// import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import router from '@/router'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BModal,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const toast = useToast()
    const remove = id => {
      store.dispatch('userStoreModule/deleteUser', id)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'User Has Been deleted',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          router.push({
            name: 'apps-users-list-student',
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while deleting user',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }

    const { resolveUserRoleVariant } = useUsersList()

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Student', value: 'student' },
      { label: 'Teacher', value: 'teacher' },
      { label: 'Agent', value: 'agent' },
      { label: 'Company', value: 'company' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })
    const onSave = () => {
      /* eslint-disable */
      props.userData.user_id = router.currentRoute.params.id
      delete props.userData.avatar
      store.dispatch('app-user/updateUser', props.userData)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'User informations had been updated',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      permissionsData,
      onSave,
      remove,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
// @import '@core/scss/vue/libs/vue-select.scss';
</style>
