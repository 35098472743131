<template>
  <div class="email-app-details">

    <!-- Email Header -->
    <div class="email-detail-header">

      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-email-view')"
          />
        </span>
        <h4 class="email-subject mb-0">
          {{ emailViewData.name }}
        </h4>
      </div>
    </div>

    <!-- Email Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="email-scroll-area scroll-area"
    >

      <!-- Label Row -->
      <b-row>
        <b-col cols="12">
          <div class="email-label">
            <b-badge
              v-for="(label) in emailViewData.labels"
              :key="label"
              pill
              class="text-capitalize mr-50"
              :variant="`light-${resolveLabelColor(label)}`"
            >
              {{ label }}
            </b-badge>
          </div>
        </b-col>
      </b-row>

      <!-- Action: Show Earlier Message -->
      <b-row
        v-if="!showWholeThread && emailViewData.messages && emailViewData.messages.length"
        class="mb-1"
      >
        <b-col cols="12">
          <b-link
            class="font-weight-bold"
            @click="showWholeThread = true; $emit('get-more-messages', userId)"
          >
            {{ emailViewData.messages.length === 10 ? 'View Earlier Message' : '' }}
          </b-link>
        </b-col>
      </b-row>

      <!-- Earlier Email Messages -->
      <template v-if="showWholeThread">
        <b-row
          v-for="threadMail in emailViewData.messages.slice().reverse()"
          :key="threadMail.id"
        >
          <b-col cols="12">
            <email-message-card
              :refreshKey="refreshKey"
              :message="emailViewData"
            />
          </b-col>
        </b-row>
      </template>

      <!-- Email Thread -->
      <b-row>
        <b-col
          v-if="!showWholeThread"
          cols="12"
        >
          <email-message-card :message="emailViewData" />
        </b-col>
      </b-row>

      <!-- Email Actions: Reply or Forward -->
      <b-row>
        <b-col cols="12">
          <b-card>
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">
                Click here to
                <b-link
                  v-b-modal.modal-1
                  @click="to = emailViewData.name"
                >
                  Reply
                </b-link>
              </h5>
              <b-modal
                id="modal-1"
                title="Reply"
                :ok-title="$t('Send Reply')"
                ok-only
                @ok="reply"
              >
                <b-form-group
                  label="to"
                  label-for="basicInput"
                >
                  <b-form-input
                    v-model="to"
                    disabled
                    placeholder="Enter Email"
                  />
                </b-form-group>
                <div>
                  <label for="textarea-default">{{ $t('Reply') }}</label>
                  <b-form-textarea
                    v-model="message"
                    placeholder="Message"
                    rows="3"
                  />
                </div>
              </b-modal>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {
  BRow, BCol, BBadge, BCard, BLink, BModal, BFormTextarea, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import useEmail from './useEmail'
import EmailMessageCard from './EmailMessageCard.vue'

export default {
  components: {

    // BSV
    BRow,
    BCol,
    BBadge,
    BCard,
    BLink,
    BModal,
    BFormTextarea,
    BFormGroup,
    BFormInput,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    EmailMessageCard,
  },
  props: {
    emailViewData: {
      type: Object,
      required: true,
    },
    opendedEmailMeta: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userId = userData.id
    const { resolveLabelColor } = useEmail()
    const to = ref('')
    const refreshKey = ref(0)
    const message = ref('')

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }
    const reply = () => {
      /* eslint-disable camelcase */
      const conversation_id = props.emailViewData.id
      const user_id = userId
      const content = message.value
      store.dispatch('app-email/sendMessage', { conversation_id, user_id, content })
      .then(() => {
        emit('close-email-view')
        refreshKey.value += 1
        // store.state.emailStoreModule.emails.forEach(email => {
        //   if (email.id === conversation_id) {
        //     /* eslint-disable no-param-reassign */
        //     props.emailViewData = email
        //     console.log(props.emailViewData)
        //     console.log(store.state.emailStoreModule.emails)
        //   }
        // })
        // console.log(props.emailViewData)
        // emailsMeta.value = response.data.emailsMeta
      })
    }

    const showWholeThread = ref(false)

    watch(() => props.emailViewData.id, () => {
      showWholeThread.value = false
    })

    return {
      userId,
      reply,
      to,
      refreshKey,
      message,

      // UI
      perfectScrollbarSettings,
      showWholeThread,

      // useEmail
      resolveLabelColor,
    }
  },
}
</script>

<style>

</style>
