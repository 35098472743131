import {
  ref, watch, computed, onMounted,
} from '@vue/composition-api'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'

export default function useCoursesList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'reg id', sortable: true },
    { key: 'student_name', sortable: true },
    { key: 'course_name', sortable: true },
    // { key: 'status', sortable: true },
    { key: 'date', sortable: true },
    { key: 'fee', label: 'fee & medium' },
    // { key: 'medium', sortable: true },
    { key: 'reason' },
    { key: 'p_status', label: 'payment status' },
    { key: 'payment_method', label: 'Payment method' },
    // { key: 'reason_other', sortable: true },
    // { key: 'comments', label: 'notes', sortable: true },
    { key: 'actions', tdClass: 'table-actions' },
  ]
  const tableColumns1 = [
    { key: 'id', sortable: true },
    // { key: 'student_name', sortable: true },
    { key: 'course_name', label: 'Course & status', sortable: true },
    // { key: 'status', sortable: true },
    { key: 'date', sortable: true },
    { key: 'fee', label: 'fee & medium' },
    // { key: 'medium', sortable: true },
    { key: 'reason' },
    // { key: 'reason_other', sortable: true },
    { key: 'comments' },
    { key: 'actions', tdClass: 'table-actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const userData = ref(JSON.parse(localStorage.userData))
  /* eslint-disable camelcase, no-shadow */
  const studentId = ref(userData.id)
  const role_name = ref(window.location.href.split('/')[3])
  const student_id = ref(null)
  const status = ref(null)
  const statusOptions = ref([
    { label: 'All', value: null },
    { label: 'Pending', value: 'pending' },
    { label: 'Confirmed', value: 'confirmed' },
    // { label: 'Rejected', value: 'rejected' },
  ])

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }
  const urlParams = new URLSearchParams(window.location.search)
  const urlQueries = router.currentRoute.query
  if (urlQueries.search) searchQuery.value = urlQueries.search
  if (urlQueries.page) currentPage.value = urlQueries.page
  if (urlQueries.perPage) perPage.value = urlQueries.perPage
  if (urlQueries.status) status.value = urlQueries.status
  /* eslint-disable-next-line */
  watch([currentPage, perPage, student_id, status, searchQuery],  () => {
    urlParams.set('search', searchQuery.value ? searchQuery.value : '')
    urlParams.set('page', currentPage.value ? currentPage.value : '')
    urlParams.set('perPage', perPage.value ? perPage.value : '')
    urlParams.set('status', status.value ? status.value : '')
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams.toString()}`)
    refetchData()
  })
  const isBusy = ref(false)

  const fetchUsers = (ctx, callback) => {
    const payload = {
      search: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      teacher_id: userData.value.id,
      student_id: student_id.value,
      status: status.value,
      // role_name: role_name.value,
    }
    store
      .dispatch('course/fetchCourses', payload)
      .then(res => {
        const users = res.data.rows
        const total = res.data.totalRecords
        callback(users)
        totalUsers.value = total
      })
      .catch(() => {
        isBusy.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching resignations list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const spinner = ref(false)
  const TofDArray = ref([
    { name: 'daytime', value: 'daytime' },
    { name: 'anytime', value: 'anytime' },
    { name: 'evening', value: 'evening' },
  ])
  const statusArray = ref([
    { name: 'Active', value: 'active', color: 'primary' },
    { name: 'Hidden', value: 'hidden', color: 'primary' },
    { name: 'Past', value: 'past', color: 'primary' },
    { name: 'Deleted', value: 'deleted', color: 'primary' },
    { name: 'Planned', value: 'planned', color: 'primary' },
  ])
  const currentUser = ref({})

  const currentCourseData = ref(null)
  const msgs = ref(null)
  const UsersToAdd = ref(null)
  const UsersAlreadyAdded = ref(null)
  const isBigModalActive = ref(false)
  const ModalData = (id, tab) => {
    store.dispatch('registerStoreModule/getSCourse', id)
      .then(res => {
        currentCourseData.value = res.data.data
        currentCourseData.value.pendingTeachers = res.data.pendingTeachers
        currentCourseData.value.currentTeacher = res.data.currentTeacher
        currentCourseData.value.tab = tab
        const sDays = currentCourseData.value.days
        const daysArr = sDays.split(',')
        currentCourseData.value.days = daysArr
        if (currentCourseData.value.conversation === null) {
          msgs.value = []
          UsersToAdd.value = []
          UsersAlreadyAdded.value = []
        } else {
          if (typeof currentCourseData.value.conversation !== 'undefined') {
            msgs.value = currentCourseData.value.conversation.messages
          }
          UsersToAdd.value = currentCourseData.value.users_To_Add
          UsersAlreadyAdded.value = currentCourseData.value.users_Already_In
        }
        isBigModalActive.value = true
      }).catch(error => console.log(error))
  }
  const classrooms = ref([])
  const teachers = ref([])
  const masters = ref([])
  /* eslint-disable no-param-reassign */
  const fetchMCourses = () => {
    store.dispatch('registerStoreModule/fetchMCourses')
      .then(res => {
        masters.value = res.data.rows
      })
  }
  const fetchTeachers = () => {
    store.dispatch('registerStoreModule/fetchTeachers')
      .then(res => {
        teachers.value = res.data.rows
      })
  }
  const fetchClassrooms = () => {
    store.dispatch('registerStoreModule/fetchClassrooms')
      .then(res => {
        classrooms.value = res.data.rows
      })
  }
  const suggestClassrooms = (record, Days) => {
    record.days = Days.join(',')
    store.dispatch('registerStoreModule/suggestClassrooms', record)
      .then(res => {
        classrooms.value = res.data.available
      })
  }
  const suggestTeacher = (record, Days) => {
    record.days = Days.join(',')
    store.dispatch('registerStoreModule/suggestTeacher', record)
      .then(res => {
        teachers.value = res.data.available
      })
  }
  // call on mounted
  onMounted(fetchMCourses)
  onMounted(fetchTeachers)
  onMounted(fetchClassrooms)

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveAttendanceVariant = attendance => {
    if (attendance > 0) return 'flat-warning'
    if (attendance > 80) return 'flat-success'
    return 'flat-secondary'
  }
  const resolveUserStatusVariant = status => {
    if (status === 'unpaid') return 'warning'
    if (status === 'paid') return 'success'
    if (status === 'canceled') return 'danger'
    if (status === 'refunded') return 'danger'
    if (status === 'to be refunded') return 'danger'
    return 'secondary'
  }

  return {
    // Modal
    spinner,
    currentCourseData,
    msgs,
    UsersToAdd,
    UsersAlreadyAdded,
    isBigModalActive,
    fetchTeachers,
    fetchClassrooms,
    suggestTeacher,
    suggestClassrooms,
    TofDArray,
    masters,
    teachers,
    classrooms,
    ModalData,
    status,
    statusOptions,
    statusArray,
    currentUser,

    isBusy,
    fetchUsers,
    tableColumns,
    tableColumns1,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    role_name,
    studentId,
    userData,
    student_id,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveAttendanceVariant,
    refetchData,
  }
}
