import {
  ref, watch, computed, onMounted,
} from '@vue/composition-api'
// import { title } from '@core/utils/filter'
// import { datetimeToDate } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'

export default function useCoursesList() {
  // Use toast
  const toast = useToast()

  const refCourseListTable = ref(null)
  const selectedRows = ref([])
  const unselectedRows = ref([])
  const selectAll = ref(false)

  // Table Handlers
  const tableColumns = [
    { key: 'selected', sortable: false, label: '' },
    // {
    //   key: 'id',
    //   sortable: true,
    //   // sortKey: true,
    //   // sortDirection: 'asc',
    // },
    // { key: 'student', sortable: true },
    { key: 'course', label: 'Course name', sortable: true },
    { key: 'course_group', label: 'Group', sortable: true },
    {
      key: 'date_start',
      sortable: true,
      // formatter: datetimeToDate,
      label: 'period',
    },
    // {
    //   key: 'available_places',
    //   sortable: true,
    //   // formatter: datetimeToDate,
    //   label: 'available places',
    // },
    // {
    //   key: 'time_start',
    //   sortable: true,
    //   label: 'timetable',
    // },
    // {
    //   key: 'hours',
    //   sortable: true,
    //   label: 'duration',
    // },
    {
      key: 'days',
      label: 'days',
    },
    {
      key: 'teachers_name',
      // sortable: true,
      label: 'Teacher',
    },
    {
      key: 'classroom_name',
      // sortable: true,
      label: 'Classroom',
    },
    {
      key: 'available',
      // sortable: true,
      label: 'conf/av',
    },
    {
      key: 'comment',
      // sortable: true,
      label: 'notes',
    },
    // {
    //   key: 'feedback',
    //   sortable: true,
    //   label: 'feedback',
    // },
    { key: 'actions', tdClass: 'table-actions' },
  ]
  // lists
  const number = ref(null)
  const group = ref(null)
  /* eslint-disable camelcase */
  const mastercourse_ids = ref(null)
  const classroom_id = ref(null)
  const teacher_id = ref(null)
  const date_start_from = ref(null)
  const date_start_to = ref(null)
  const date_end_from = ref(null)
  const date_end_to = ref(null)
  const time_from = ref(null)
  const time_to = ref(null)
  const fStatus = ref(null)
  const time_of_day = ref(null)
  // const cities = ref([])
  const masters = ref([])
  const classrooms = ref([])
  const teachers = ref([])
  const courseGroup = ref(null)
  const statusArray = ref([
    { name: 'Active', value: 'active', color: 'primary' },
    { name: 'Hidden', value: 'hidden', color: 'primary' },
    { name: 'Past', value: 'past', color: 'primary' },
    { name: 'Deleted', value: 'deleted', color: 'primary' },
    { name: 'Planned', value: 'planned', color: 'primary' },
  ])
  const TofDArray = ref([
    { name: 'daytime', value: 'daytime' },
    { name: 'anytime', value: 'anytime' },
    { name: 'evening', value: 'evening' },
  ])
  //
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('date_start')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const currentUser = ref({})
  const hasLoadedTeachers = ref(false)
  // const expDate = ref(null)
  const coursesStatusOptions = [
    { label: 'All', code: 'all', color: 'secondary' },
    { label: 'Planned', code: 'planned', color: 'secondary' },
    { label: 'To confirm', code: 'toconfirm', color: 'primary' },
    { label: 'On going', code: 'ongoing', color: 'success' },
    { label: 'Confirmed', code: 'confirmed', color: 'success' },
    { label: 'To watch', code: 'towatch', color: 'info' },
    { label: 'Waiting list Groups', code: 'w_copy', color: 'warning' },
    { label: 'Finished', code: 'past', color: 'danger' },
    { label: 'Suspended', code: 'suspended', color: 'danger' },
    { label: 'Deleted', code: 'deleted', color: 'danger' },
    { label: 'Hidden', code: 'hidden', color: 'danger' },
  ]
  const filterDefault = ref({ label: 'All', code: 'all', color: 'secondary' })
  if (router.currentRoute.params.code) {
    filterDefault.value = router.currentRoute.params
  }
  // const today = new Date()
  // const dd = String(today.getDate()).padStart(2, '0')
  // const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
  // const prevMm = String(today.getMonth())
  // const yyyy = today.getFullYear()
  // expDate.value = `${yyyy}-${prevMm}-${dd} to ${yyyy}-${mm}-${dd}`
  /* eslint-disable no-shadow */

  const dataMeta = computed(() => {
    const localItemsCount = refCourseListTable.value ? refCourseListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })
  const isLoadingExcelDoc = ref(false)
  /* eslint-disable no-return-assign */
  const getExcelDoc = () => {
    isLoadingExcelDoc.value = true
    const payload = {
      search: searchQuery.value,
      // number: number.value,
      filterDefault: filterDefault.value,
      group: group.value,
      mastercourse_ids: mastercourse_ids.value,
      classroom_id: classroom_id.value,
      teacher_id: teacher_id.value,
      status: fStatus.value,
      date_start_from: date_start_from.value,
      date_start_to: date_start_to.value,
      date_end_from: date_end_from.value,
      date_end_to: date_end_to.value,
      time_from: time_from.value,
      time_to: time_to.value,
      selected: selectedRows.value.length > 0 ? selectedRows.value.map(el => el.id) : [],
      unselected: unselectedRows.value.length > 0 ? unselectedRows.value.map(el => el.id) : [],
      selectAll: selectAll.value,
      // time_of_day: time_of_day.value,
      // group_course_name: courseGroup.value,
    }
    store.dispatch('coursesStoreModule/getExcelDoc', payload)
    .then(() => isLoadingExcelDoc.value = false)
    .catch(err => {
      isLoadingExcelDoc.value = false
      isBusy.value = false
      toast({
        component: ToastificationContent,
        props: {
          title: err.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }
  // lists
  // const fetchCities = () => {
  //   store.dispatch('coursesStoreModule/fetchCities')
  //     .then(() => {
  //       cities.value = store.state.coursesStoreModule.cities
  //     })
  // }
  const fetchMCourses = () => {
    store.dispatch('coursesStoreModule/fetchMCourses', { group: null, perPage: 500, sortBy: 'code' })
      .then(() => {
        /* eslint-disable */
        masters.value = store.state.coursesStoreModule.Mcourses
        if (router.currentRoute.query['mastercourse_ids[]']) {
          const Masters = router.currentRoute.query['mastercourse_ids[]'].split(',')
          mastercourse_ids.value = masters.value.rows.filter(master => Masters.includes((master.id.toString()))).map(el => el.id)
        }
      })
  }
  const fetchTeachers = () => {
    hasLoadedTeachers.value = false
    store.dispatch('coursesStoreModule/fetchTeachersInit')
      .then(res => {
        hasLoadedTeachers.value = true
        teachers.value = res.data.rows
        if (router.currentRoute.query.teacher) {
          teacher_id.value = parseInt(router.currentRoute.query.teacher, 0)
        }
      })
  }
  const fetchClassrooms = course_id => {
    store.dispatch('coursesStoreModule/fetchClassrooms', { course_id })
      .then(() => {
        classrooms.value = store.state.coursesStoreModule.classrooms
        if (router.currentRoute.query.classroom) {
          classroom_id.value = parseInt(router.currentRoute.query.classroom, 0)
        }
      })
  }
  const suggestClassrooms = (record, Days) => {
    /* eslint-disable no-param-reassign */
    record.days = Days.join(',')
    store.dispatch('coursesStoreModule/suggestClassrooms', record)
      .then(res => {
        classrooms.value = res.data.available
      })
  }
  const suggestTeacher = (record, Days) => {
    /* eslint-disable no-param-reassign */
    record.days = Days.join(',')
    store.dispatch('coursesStoreModule/suggestTeacher', record)
      .then(res => {
        teachers.value = res.data.available
      })
  }
  // call on mounted
  onMounted(fetchMCourses)
  onMounted(fetchTeachers)
  onMounted(fetchClassrooms)
  // onMounted(fetchCities)
  //
  const refetchData = () => {
    refCourseListTable.value.refresh()
  }
  const resetFilter = () => {
        group.value = null
        mastercourse_ids.value = null
        classroom_id.value = null
        teacher_id.value = null
        fStatus.value = null
        date_start_from.value = null
        date_start_to.value = null
        date_end_from.value = null
        date_end_to.value = null
        time_from.value = null
        time_to.value = null
        time_of_day.value = null
  }
  const isBusy = ref(false)
  const records = ref([])

  const fetchCourses = (ctx, callback) => {
    store
      .dispatch('coursesStoreModule/fetchCourses', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        number: number.value,
        filterDefault: filterDefault.value,
        group: group.value,
        mastercourse_ids: mastercourse_ids.value,
        classroom_id: classroom_id.value,
        teacher_id: teacher_id.value,
        status: fStatus.value,
        date_start_from: date_start_from.value,
        date_start_to: date_start_to.value,
        date_end_from: date_end_from.value,
        date_end_to: date_end_to.value,
        time_from: time_from.value,
        time_to: time_to.value,
        time_of_day: time_of_day.value,
        group_course_name: courseGroup.value,
        /* role_name: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value, */
        // role_name: role_name.value,
      })
        .then(() => {
          records.value = store.state.coursesStoreModule.courses.rows
          const total = store.state.coursesStoreModule.courses.totalRecords
          if (selectedRows.value.length > 0) {
            selectedRows.value = selectedRows.value.map(row => {
              const matchedRow = records.value.find(u => u.id === row.id)
              return matchedRow || row
            })
          }
          if (selectAll.value) {
            records.value.forEach(el => {
              if (!selectedRows.value.some(row => row.id === el.id) && !unselectedRows.value.some(row => row.id === el.id)) selectedRows.value.push(el)
            })
          }
          callback(records.value)
          totalUsers.value = total
          currentUser.value = JSON.parse(localStorage.getItem('userData'))
        })
        .catch(() => {
          isBusy.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching courses list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
      })
  }
  const urlParams = ref(new URLSearchParams())
  if (router.currentRoute.query.search) searchQuery.value = router.currentRoute.query.search
  if (router.currentRoute.query.page) currentPage.value = router.currentRoute.query.page
  if (router.currentRoute.query.perPage) perPage.value = router.currentRoute.query.perPage
  if (router.currentRoute.query.status) filterDefault.value = coursesStatusOptions.find(el => el.code === router.currentRoute.query.status)
  if (router.currentRoute.query.group) group.value = router.currentRoute.query.group
  if (router.currentRoute.query.date_start_from) date_start_from.value = router.currentRoute.query.date_start_from
  if (router.currentRoute.query.date_start_to) date_start_to.value = router.currentRoute.query.date_start_to
  if (router.currentRoute.query.date_end_from) date_end_from.value = router.currentRoute.query.date_end_from
  if (router.currentRoute.query.date_end_to) date_end_to.value = router.currentRoute.query.date_end_to
  if (router.currentRoute.query.time_from) time_from.value = router.currentRoute.query.time_from
  if (router.currentRoute.query.time_to) time_to.value = router.currentRoute.query.time_to

  const recheckLoading = () => {
    if (!isBusy.value) return refetchData()
    return setTimeout(() => recheckLoading(), 1000)
  }

  watch([currentPage, perPage, filterDefault, number, group, mastercourse_ids, teacher_id, classroom_id, date_start_from, date_start_to, date_end_from, date_end_to, time_from, time_to, fStatus, time_of_day],  () => {
    urlParams.value.set('?search', searchQuery.value ? searchQuery.value : '')
    urlParams.value.set('page', currentPage.value ? currentPage.value : '')
    urlParams.value.set('perPage', perPage.value ? perPage.value : '')
    urlParams.value.set('status', filterDefault.value.code ? filterDefault.value.code : '')
    urlParams.value.set('group', group.value ? group.value : '')
    urlParams.value.set('mastercourse_ids[]', mastercourse_ids.value ? mastercourse_ids.value : '')
    urlParams.value.set('classroom', classroom_id.value ? classroom_id.value : '')
    urlParams.value.set('teacher', teacher_id.value ? teacher_id.value : '')
    urlParams.value.set('date_start_from', date_start_from.value ? date_start_from.value : '')
    urlParams.value.set('date_start_to', date_start_to.value ? date_start_to.value : '')
    urlParams.value.set('date_end_from', date_end_from.value ? date_end_from.value : '')
    urlParams.value.set('date_end_to', date_end_to.value ? date_end_to.value : '')
    urlParams.value.set('time_from', time_from.value ? time_from.value : '')
    urlParams.value.set('time_to', time_to.value ? time_to.value : '')
    window.history.replaceState(null, null, decodeURIComponent(urlParams.value))
    recheckLoading()
  })
  watch(searchQuery, () => {
    urlParams.value.set('?search', searchQuery.value)
    window.history.replaceState(null, null, decodeURIComponent(urlParams.value))
    refetchData()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }
  const resolveCourseVariant = status => {
    // if (status === 'All') return 'warningsec'
    if (status === 'towatch') return 'info'
    if (status === 'confirmed') return 'success'
    if (status === 'ongoing') return 'success'
    if (status === 'planned') return 'secondary'
    if (status === 'toconfirm') return 'primary'
    if (status === 'waitinglist') return 'warning'
    if (status === 'past') return 'danger'
    if (status === 'suspended') return 'danger'
    if (status === 'deleted') return 'danger'
    if (status === 'hidden') return 'danger'
    return 'primary'
  }
  const resolveCourseStatusTooltip = status => {
    // if (status === 'All') return 'warningsec'
    if (status === 'towatch') return 'To watch'
    if (status === 'confirmed') return 'Confirmed'
    if (status === 'ongoing') return 'On going'
    if (status === 'planned') return 'planned'
    if (status === 'toconfirm') return 'To confirm'
    if (status === 'waitinglist') return 'Waiting list'
    if (status === 'past') return 'Past'
    if (status === 'suspended') return 'Suspended'
    if (status === 'deleted') return 'Deleted'
    if (status === 'hidden') return 'Hidden'
    if (status === 'w_copy') return 'Waiting list group'
    return 'Unlisted'
  }

  const resolveCourseStatusVariant = status => {
    const state = coursesStatusOptions.filter(stat => {
      return stat.code === status
    })
    if (typeof state[0].color !== 'undefined') {
      return state[0].color
    }
    return 'primary'
  }

  return {
    records,
    hasLoadedTeachers,
    fetchClassrooms,
    isLoadingExcelDoc,
    urlParams,
    isBusy,
    // Suggestions
    suggestClassrooms,
    suggestTeacher,

    fetchCourses,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    filterDefault,
    coursesStatusOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCourseListTable,
    number,
    group,
    mastercourse_ids,
    teacher_id,
    classroom_id,
    date_start_from,
    date_start_to,
    date_end_from,
    date_end_to,
    time_from,
    time_to,
    fStatus,
    time_of_day,
    // cities,
    masters,
    classrooms,
    teachers,
    statusArray,
    TofDArray,
    currentUser,
    courseGroup,

    // Select rows
    selectedRows,
    unselectedRows,
    selectAll,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveCourseStatusVariant,
    resolveCourseVariant,
    resolveCourseStatusTooltip,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    resetFilter,

    // expDate,
    getExcelDoc,
  }
}
