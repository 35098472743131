<template>

  <div>

    <!-- <user-list-add-new
      :is-add-new-student-sidebar-active.sync="isAddNewUserSidebarActive"
      @refetch-data="refetchData"
    /> -->

    <!-- Filters -->
    <!-- <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->
    <!-- <registrations-filter
      :isFilterActive.sync="isFilterActive"
      :student.sync="student"
      :course.sync="course"
      :defaultStatus.sync="defaultStatus"
      :payer_confirmed.sync="payer_confirmed"
      :visa.sync="visa"
      :who_pays.sync="who_pays"
    /> -->
    <!-- <user-select-actions
      v-if="selectedRows.length !== 0"
      @multi-action="multiAction"
      @show-move-student-modal="$bvModal.show('move-student')"
      @cancel-registration-modal="$bvModal.show('cancel-registration-modal')"
      @show-send-email-modal="$bvModal.show('send-email')"
      @copy-emails="copyEmails(emailsList.join('; '))"
    /> -->

    <courses-list-big-modal
      v-if="isBigModalActive"
      :isBigModalActive.sync="isBigModalActive"
      :classrooms="classrooms"
      :teachers="teachers"
      :masters="masters"
      :TofDArray="TofDArray"
      :currentCourseData="currentCourseData"
      :msgs="msgs"
      :statusArray="statusArray"
      :UsersToAdd="UsersToAdd"
      :UsersAlreadyAdded="UsersAlreadyAdded"
      :currentUser="currentUser"
      :loading="spinner"
      @refetch-data="refetchData"
      @loading="loadSpinner"
      @suggest-classrooms="suggestClassrooms"
      @suggest-teacher="suggestTeacher"
    />
    <!-- :cities="cities" -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row v-if="!studentId">

          <!-- filter status -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <b-button
              v-if="!allRowsSelected"
              @click="selectAllRows"
            >
              Select all
            </b-button>
            <b-button
              v-else
              @click="clearSelected"
            >
              Clear selected
            </b-button>
            <v-select
              :value="defaultStatus"
              label="label"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :clearable="false"
              class="list-filter d-inline-block mx-50"
              @input="(val) => { defaultStatus = val.value }"
            /> -->
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
                debounce="500"
              />
              <!-- <b-button
                v-b-tooltip.hover.top="'Filters'"
                variant="primary"
                class="d-inline-block mr-1"
                @click="isFilterActive = true"
              >
                <feather-icon icon="FilterIcon" />
              </b-button>

              <b-button
                v-b-tooltip.hover.top="'Add'"
                variant="primary"
                class="d-inline-block mr-1"
                @click="isAddNewUserSidebarActive = true"
              >
                <feather-icon icon="PlusIcon" />
              </b-button> -->
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        key="1545"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >
        <!-- selectable -->
        <!-- @row-selected="onRowSelected" -->
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>
        <!-- Selected -->
        <!-- <template #cell(selected)="data">
          <b-form-checkbox
            v-model="selectedRows"
            :value="data.item.id"
          />
        </template> -->
        <template #cell(selected)="data">
          <!-- <b-form-checkbox
            class="d-inline"
            v-model="selectedRows"
            :value="data.item.id"
            disabled
          /> -->
          <small class="text-secondary">{{ data.item.id }}</small>
        </template>
        <!-- <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template> -->
        <!-- Column: student Id, not registration id -->
        <template #cell(date)="data">
          <div class="media-body">
            <div class="d-block text-nowrap text-capitalize">
              {{ data.item.registration_date }}
            </div>
            <small class="text-muted text-nowrap">{{ data.item.registration_time }}</small>
          </div>

        </template>

        <!-- Column: visa -->
        <template #cell(course_group)="data">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            @click="ModalData(data.item.course_id, 'group')"
          >
            <b class="d-flex d-block text-nowrap">
              {{ data.item.course_group }}
            </b>
            <small class="d-flex mt-50 text-muted">
              {{ data.item.course_name }}
            </small>
          </b-button>
          <!-- style="cursor: pointer" -->
          <!-- @click="ModalData(data.item.course_id, 'group')" -->
          <!-- <b
            class="d-block"
          >
            {{ data.item.course_group }}
          </b>
          <small class="text-muted">
            {{ data.item.course_name }}
          </small> -->
        </template>

        <!-- Column: student name & visa -->
        <template #cell(student)="data">
          <div class="media-body">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-primary"
              :to="{ name: 'apps-users-view', params: { id: data.item.student_id } }"
              class="font-weight-bold d-block text-nowrap text-capitalize"
              :style="[data.item.waiting_list ? {'postition': 'relative', 'color': '#005160', 'background-color': '#cff4fc', 'border-color': '#b6effb'} : {}]"
            >
              <span class="d-flex">
                {{ data.item.student_name ? data.item.student_name.substring(0,20) + (data.item.student_name.length >= 20 ? '...' : '') : '' }}
              </span>
              <div class="d-flex align-items-center">
                <small
                  class="d-block mt-50 mr-1 d-flex text-muted"
                  style="color: #055160"
                >{{ data.item.student_id }}</small>
                <small
                  v-if="data.item.country"
                  class="mt-50 mr-50 d-flex text-muted"
                >
                  <span class="badge text-capitalize badge-light-secondary badge-pill">{{ data.item.country }}</span>
                </small>
              </div>
            </b-button>
          </div>
        </template>
        <!-- Column: who pays -->
        <template #cell(who_pays)="data">

          <div class="media-body">
            <div
              v-if="data.item.payer"
              class="font-weight-bold d-block text-nowrap text-capitalize"
            >
              {{ data.item.payer.name }}
            </div>
            <!-- <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.payer_confirmed)}`"
              class="text-capitalize"
              style="font-size: 10px"
            >
              {{ data.item.payer_confirmed ? 'Payer Confirmed' : 'Not confirmed' }}
            </b-badge> -->
          </div>
        </template>
        <template #cell(payment_method)="data">

          <div class="media-body">
            <div class="font-weight-bold d-block text-nowrap text-capitalize">
              {{ data.item.paimentMethod ? data.item.paimentMethod.name : '' }}
            </div>
          </div>
        </template>
        <!-- Column: who pays -->
        <template #cell(payment_status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.registrationFilter ? data.item.registrationFilter.name : '')}`"
            class="text-capitalize"
            style="font-size: 10px"
          >
            {{ data.item.registrationFilter ? data.item.registrationFilter.name : '' }}
          </b-badge>
          <!-- <span
            v-if="!data.item.paid"
            class="b-avatar ml-1 badge-light-warning rounded-circle d-flex align-items-center justify-content-center"
          >
            <div
              v-b-tooltip.hover.top="'Awaiting payment'"
              class="d-flex align-items-center justify-content-center"
            >
              <svg
                enable-background="new 0 0 700 700"
                class="ml-25"
                width="23"
                height="26"
                version="1.1"
                viewBox="0 0 700 700"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m422.86 173.64c-0.617 5.57-0.934 11.233-0.934 16.969 0 5.738 0.316 11.397 0.934 16.969h-351.64c-15.621 0-28.285 12.664-28.285 28.285v282.83c0 15.621 12.664 28.281 28.285 28.281h458.18c15.621 0 28.285-12.66 28.285-28.281v-176.29c5.57 0.613 11.233 0.93 16.969 0.93s11.398-0.316 16.969-0.93v176.29c0 34.363-27.854 62.223-62.223 62.223h-458.18c-34.363 0-62.223-27.858-62.223-62.223v-282.83c0-34.363 27.858-62.223 62.223-62.223h351.64zm17.902 90.504c14.613 26.551 36.891 48.285 63.854 62.223h-461.68v-62.223h397.82zm-397.83 62.223v-62.223 62.223zm531.72-254.55c65.605 0 118.79 53.184 118.79 118.79 0 65.605-53.184 118.79-118.79 118.79-65.605 0-118.79-53.185-118.79-118.79 0-65.606 53.184-118.79 118.79-118.79zm16.968 39.598c0-9.371-7.598-16.969-16.969-16.969s-16.969 7.598-16.969 16.969v92.066l61.733 61.733c6.625 6.625 17.371 6.625 23.996 0 6.629-6.629 6.629-17.37 0-24l-51.793-51.793 2e-3 -78.006z"
                  fill="#6e6b7b"
                />
              </svg>
            </div>
          </span>
          <span
            v-if="data.item.paid"
            v-b-tooltip.hover.top="'Paid'"
            class="b-avatar ml-1 badge-light-success rounded-circle d-flex align-items-center justify-content-center"
          >
            <span class="b-avatar-custom">
              <feather-icon
                icon="CreditCardIcon"
                size="16"
                class="align-middle text-body"
              />
            </span>
          </span> -->
        </template>
        <template #cell(status)="data">
          <!-- <span
            v-if="data.item.registration_status === 'awaiting payment'"
            class="b-avatar ml-1 badge-light-warning rounded-circle d-flex align-items-center justify-content-center"
          >
            <div
              v-b-tooltip.hover.top="data.item.registration_status"
              class="d-flex align-items-center justify-content-center"
            >
              <svg
                enable-background="new 0 0 700 700"
                class="ml-25"
                width="23"
                height="26"
                version="1.1"
                viewBox="0 0 700 700"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m422.86 173.64c-0.617 5.57-0.934 11.233-0.934 16.969 0 5.738 0.316 11.397 0.934 16.969h-351.64c-15.621 0-28.285 12.664-28.285 28.285v282.83c0 15.621 12.664 28.281 28.285 28.281h458.18c15.621 0 28.285-12.66 28.285-28.281v-176.29c5.57 0.613 11.233 0.93 16.969 0.93s11.398-0.316 16.969-0.93v176.29c0 34.363-27.854 62.223-62.223 62.223h-458.18c-34.363 0-62.223-27.858-62.223-62.223v-282.83c0-34.363 27.858-62.223 62.223-62.223h351.64zm17.902 90.504c14.613 26.551 36.891 48.285 63.854 62.223h-461.68v-62.223h397.82zm-397.83 62.223v-62.223 62.223zm531.72-254.55c65.605 0 118.79 53.184 118.79 118.79 0 65.605-53.184 118.79-118.79 118.79-65.605 0-118.79-53.185-118.79-118.79 0-65.606 53.184-118.79 118.79-118.79zm16.968 39.598c0-9.371-7.598-16.969-16.969-16.969s-16.969 7.598-16.969 16.969v92.066l61.733 61.733c6.625 6.625 17.371 6.625 23.996 0 6.629-6.629 6.629-17.37 0-24l-51.793-51.793 2e-3 -78.006z"
                  fill="#6e6b7b"
                />
              </svg>
            </div>
          </span> -->
          <span
            v-if="data.item.status === 'pending'"
            v-b-tooltip.hover.top="'Pending confirmation'"
            class="b-avatar ml-1 badge-light-warning rounded-circle d-flex align-items-center justify-content-center"
          >
            <span class="b-avatar-custom">
              <feather-icon
                icon="LoaderIcon"
                size="16"
                class="align-middle text-body"
              />
            </span>
          </span>

          <span
            v-if="data.item.status === 'confirmed'"
            v-b-tooltip.hover.top="'Confirmed'"
            class="b-avatar ml-1 badge-light-success rounded-circle d-flex align-items-center justify-content-center"
          >
            <span class="b-avatar-custom">
              <feather-icon
                icon="CheckCircleIcon"
                size="16"
                class="align-middle text-body"
              />
            </span>
          </span>
          <!-- <span
            v-if="data.item.registration_status === 'pending confirmation'"
            v-b-tooltip.hover.top="data.item.registration_status"
            class="b-avatar ml-1 badge-light-warning rounded-circle d-flex align-items-center justify-content-center"
          >
            <span class="b-avatar-custom">
              <feather-icon
                icon="LoaderIcon"
                size="16"
                class="align-middle text-body"
              />
            </span>
          </span> -->
          <!-- <span
            v-if="data.item.status === 'Cancellation pending approval'"
            v-b-tooltip.hover.top="data.item.registration_status"
            class="b-avatar ml-1 badge-light-danger rounded-circle d-flex align-items-center justify-content-center"
          >
            <span class="b-avatar-custom">
              <feather-icon
                icon="LoaderIcon"
                size="16"
                class="align-middle text-body"
              />
            </span>
          </span> -->
          <span
            v-if="data.item.status === 'canceled'"
            v-b-tooltip.hover.top="'Canceled'"
            class="b-avatar ml-1 badge-light-danger rounded-circle d-flex align-items-center justify-content-center"
          >
            <span class="b-avatar-custom">
              <feather-icon
                icon="SlashIcon"
                size="16"
                class="align-middle text-body"
              />
            </span>
          </span>
        </template>

        <!-- Column: who pays -->
        <template #cell(attendance)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.attendance)}`"
            class="text-capitalize"
          >
            {{ data.item.attendance }}%
          </b-badge>
        </template>
        <template #cell(comment)="data">
          <b-button
            :id="`popover-target-${data.item.id}`"
            variant="flat-primary"
            size="sm"
            class="btn-icon rounded-circle"
            @click="handleChatClick({model:'registration', id: data.item.id})"
          >
            <feather-icon
              icon="ClipboardIcon"
              class="align-middle text-body"
              badge-classes="bg-danger"
              :badge="data.item.comments_number > 0 ? data.item.comments_number : ''"
              size="16"
            />
          </b-button>
        </template>
        <!-- Column: Actionss -->
        <template #cell(actions)="data">
          <b-button
            v-if="data.item.deleted_at"
            variant="primary"
            size="sm"
            @click="restoreReg(data.item.id)"
          >
            Restore
          </b-button>
          <b-dropdown
            v-if="!data.item.deleted_at"
            dropleft
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <!-- <b-dropdown-item @click="handleChatClick1(data.item.student_id)">
              <feather-icon icon="MessageSquareIcon" />
              <span class="align-middle ml-50">Contact student</span>
            </b-dropdown-item> -->
            <!-- <b-dropdown-item
              v-if="data.item.status !== 'confirmed'"
              v-b-modal="getModal2Id(data.item.id)"
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">{{ $t('Confirm registration') }}</span>
            </b-dropdown-item> -->
            <!-- <b-dropdown-item
              v-if="data.item.status !== 'canceled'"
              v-b-modal="getModal4Id(data.item.id)"
            >
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">{{ $t('Cancel registration') }}</span>
            </b-dropdown-item> -->
            <!-- <b-dropdown-item
              v-b-modal="getModal3Id(data.item.id)"
              @click="getPlacementTest"
            >
              <feather-icon icon="SendIcon" />
              <span class="align-middle ml-50">{{ $t('Send Placement test') }}</span>
            </b-dropdown-item> -->
            <!-- <b-dropdown-item
              @click="regToWaitingList(data.item.id)"
            >
              <feather-icon icon="ClipboardIcon" />
              <span class="align-middle ml-50">{{ $t('Add to waiting list') }}</span>
            </b-dropdown-item> -->
            <!-- <b-dropdown-item
              v-if="!data.item.visa_needed"
              @click="sendAdmissionLetter(data.item.id)"
            >
              <feather-icon icon="FeatherIcon" />
              <span class="align-middle ml-50">{{ $t('Send admission letter') }}</span>
            </b-dropdown-item> -->
            <!-- <b-dropdown-item
              v-if="parseInt(data.item.attendance, 0) > 80"
              @click="generateCertificate(data.item.id)"
            >
              <feather-icon icon="AwardIcon" />
              <span class="align-middle ml-50">{{ $t('Generate certificate') }}</span>
            </b-dropdown-item> -->
            <!-- <b-dropdown-item
              @click="generateContract(data.item.id)"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">{{ $t('Generate contract') }}</span>
            </b-dropdown-item> -->
            <!-- <b-dropdown-item
              v-b-modal="getModal5Id(data.item.id)"
            >
              <feather-icon icon="MoveIcon" />
              <span class="align-middle ml-50">{{ $t('Move student') }}</span>
            </b-dropdown-item> -->
            <b-dropdown-item
              v-b-modal="getModal7Id(data.item.id)"
              @click="getData(data.item)"
            >
              <feather-icon icon="CreditCardIcon" />
              <span class="align-middle ml-50">Update payment</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.registrationFilter && data.item.registrationFilter.name !== 'Paid'"
              @click="markAs(data.item.invoice.id, 1)"
            >
              <feather-icon icon="CheckCircleIcon" />
              <span class="align-middle ml-50">Mark as paid</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.registrationFilter && data.item.registrationFilter.name === 'Paid'"
              @click="markAs(data.item.invoice.id, 2)"
            >
              <feather-icon icon="LoaderIcon" />
              <span class="align-middle ml-50">Mark as unpaid</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-b-modal="getModal6Id(data.item.id)"
            >
              <feather-icon icon="MailIcon" />
              <span class="align-middle ml-50">{{ ('Send email') }}</span>
            </b-dropdown-item>
<!--            <b-dropdown-item-->
<!--              v-if="!data.item.deleted_at"-->
<!--              v-b-modal="getModal1Id(data.item.id)"-->
<!--            >-->
<!--              <feather-icon icon="TrashIcon" />-->
<!--              <span class="align-middle ml-50">{{ $t('Delete') }}</span>-->
<!--            </b-dropdown-item>-->
            <!-- Update payment -->
            <b-modal
              :id="'modal7'+data.item.id"
              :title="('Update payment')"
              :ok-title="$t('Edit')"
              hide-footer
              size="lg"
              centered
              no-close-on-backdrop
            >
              <validation-observer
                #default="{ handleSubmit }"
                ref="form"
              >
                <!-- Form -->
                <b-form
                  class="p-2"
                  @submit.prevent="handleSubmit(updatePayment(payload))"
                  @reset.prevent="resetForm"
                >
                  <b-row>
                    <!-- Student -->
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Student"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Student')"
                          label-for="student"
                          :state="getValidationState(validationContext)"
                        >
                          <v-select
                            v-model="payload.student_name"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :options="students"
                            :clearable="false"
                            disabled
                            @keyup.native="autoCompleteStudent($event.target.value, 'student')"
                            @click.native="autoCompleteStudent('', 'student')"
                          >
                            <span slot="no-options">
                              <div
                                v-if="isTypingStudent"
                                class="text-center d-flex align-items-center justify-content-center"
                              >
                                <b-spinner
                                  variant="primary"
                                  label="Text Centered"
                                />
                              </div>
                              <div
                                v-if="!isTypingStudent && !students.length"
                                class="text-center d-flex align-items-center justify-content-center"
                              >
                                No matching records found
                              </div>
                            </span>
                          </v-select>
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Course -->
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="course"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Course')"
                          label-for="course"
                          :state="getValidationState(validationContext)"
                        >
                          <v-select
                            v-model="payload.course_name"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :clearable="false"
                            :options="courses"
                            disabled
                            @keyup.native="autoCompleteCourse($event.target.value)"
                            @click.native="autoCompleteCourse('')"
                          >
                            <span slot="no-options">
                              <div
                                v-if="isTypingCourse"
                                class="text-center d-flex align-items-center justify-content-center"
                              >
                                <b-spinner
                                  variant="primary"
                                  label="Text Centered"
                                />
                              </div>
                              <div
                                v-if="!isTypingCourse && !courses.length"
                                class="text-center d-flex align-items-center justify-content-center"
                              >
                                No matching records found
                              </div>
                            </span>
                          </v-select>
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Sent date -->
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Sent date"
                      >
                        <b-form-group
                          :label="$t('Sent date')"
                          label-for="example-datePicker2"
                          :state="getValidationState(validationContext)"
                        >
                          <flat-pickr
                            v-model="payload.invoice.sent_date"
                            class="form-control sent-date"
                            name="date"
                            :config="{ altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 } }"
                            :placeholder="$t('Sent at...')"
                            disabled
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Sent date -->
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Paid date"
                      >
                        <b-form-group
                          label-for="example-datePicker3"
                          :label="$t('Paid date')"
                        >
                          <flat-pickr
                            v-model="payload.paid_date"
                            class="form-control"
                            name="date"
                            :config="{ altFormat: 'd-m-Y', altInput: true}"
                            :placeholder="$t('Paid at...')"
                            @input="errors.paid_date = []"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                          <small
                            v-if="errors"
                            class="text-danger"
                          >{{ errors.paid_date[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Method -->
                    <b-col
                      cols="12"
                      sm="4"
                    >
                      <b-form-group
                        label="Payment method"
                        label-for="payment-method"
                      >
                        <v-select
                          v-model="payload.paimentMethod"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="methodOptions.filter(el => el.id)"
                          label="name"
                          :clearable="false"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Payment status -->
                    <b-col
                      cols="12"
                      sm="4"
                    >
                      <b-form-group
                        label="Payment status"
                        label-for="payment-status"
                      >
                        <v-select
                          v-model="payload.registrationFilter"
                          label="name"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="statusOptions.filter(el => el.id && !el.filtered)"
                          :clearable="false"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="4"
                    >
                      <!-- Who pays -->
                      <validation-provider
                        #default="validationContext"
                        name="Payer"
                      >
                        <b-form-group
                          label="Payer"
                          label-for="who-pays"
                          :state="getValidationState(validationContext)"
                        >
                          <v-select
                            v-model="payload.who_pays"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :options="payerOptions"
                            :clearable="false"
                            @input="payload.payer_name = null; payload.payer_email = null; payload.payer_address = null; payload.company_number; payload.company_number; payload.responsible = null; payload.payer_tel = null"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <!-- Payer name -->
                      <validation-provider
                        v-if="payload.who_pays && (payload.who_pays.code === 'payer' || payload.who_pays.code === 'company')"
                        #default="validationContext"
                        name="Payer name"
                      >
                        <b-form-group
                          :label="('Payer name')"
                          label-for="payer-name"
                        >
                          <b-form-input
                            v-model="payload.payer_name"
                            autofocus
                            :state="getValidationState(validationContext)"
                            :placeholder="('Payer name')"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <!-- Payer address -->
                      <validation-provider
                        v-if="payload.who_pays && (payload.who_pays.code === 'payer' || payload.who_pays.code === 'company')"
                        #default="validationContext"
                        name="Invoicing address"
                      >
                        <b-form-group
                          :label="$t('Invoicing address')"
                          label-for="invoicing-address"
                        >
                          <b-form-input
                            v-model="payload.payer_address"
                            autofocus
                            :state="getValidationState(validationContext)"
                            :placeholder="('Invoicing address')"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <!-- Company number -->
                      <validation-provider
                        v-if="payload.who_pays && payload.who_pays.code === 'company'"
                        #default="validationContext"
                        name="Company org.number"
                      >
                        <b-form-group
                          :label="$t('Company org.number')"
                          label-for="Company-org.number"
                        >
                          <b-form-input
                            v-model="payload.company_number"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            :placeholder="('Company org.number')"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <!-- Responsible -->
                      <validation-provider
                        v-if="payload.who_pays && payload.who_pays.code === 'company'"
                        #default="validationContext"
                        name="Responsible / contact person"
                      >
                        <b-form-group
                          :label="('Responsible / contact person')"
                          label-for="Resposible-contact-person"
                        >
                          <b-form-input
                            v-model="payload.responsible"
                            autofocus
                            :state="getValidationState(validationContext)"
                            :placeholder="('Responsible / contact person')"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <!-- Phone number -->
                      <validation-provider
                        v-if="payload.who_pays && (payload.who_pays.code === 'payer' || payload.who_pays.code === 'company')"
                        #default="validationContext"
                        name="Phone number"
                      >
                        <b-form-group
                          :label="('Phone number')"
                          label-for="Phone-number"
                        >
                          <b-form-input
                            v-model="payload.payer_tel"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            :placeholder="('Phone number')"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <!-- Email address -->
                      <validation-provider
                        v-if="payload.who_pays && (payload.who_pays.code === 'payer' || payload.who_pays.code === 'company')"
                        #default="validationContext"
                        name="Email address"
                        rules="email"
                      >
                        <b-form-group
                          :label="('Email address')"
                          label-for="Email-address"
                        >
                          <b-form-input
                            v-model="payload.payer_email"
                            autofocus
                            type="email"
                            :state="getValidationState(validationContext)"
                            trim
                            :placeholder="('Email address')"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>

                  <!-- Status -->
                  <!-- <b-form-group
                      :label="$t('Status')"
                      label-for="status"
                    >
                      <v-select
                        v-model="data.item.status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statusOptions"
                        :clearable="false"
                      />
                    </b-form-group> -->

                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      {{ $t('Update') }}
                    </b-button>
                  </div>

                </b-form>
              </validation-observer>
            </b-modal>
            <!-- send email modal -->
            <b-modal
              :id="'modal6'+data.item.id"
              :title="$t('Send email')"
              :ok-title="$t('Send')"
              centered
              size="lg"
              no-close-on-backdrop
              :ok-disabled="!emailValue.subject || !emailValue.body"
              @ok="sendEmailAction([data.item.id])"
            >
              <b-form-group
                label="Email subject"
                label-for="email-subject"
              >
                <b-form-input
                  v-model="emailValue.subject"
                  placeholder="Email subject"
                  type="text"
                />
              </b-form-group>
              <b-form-group
                label="Email body"
                label-for="email-body"
                style="height: 260px"
              >
                <quill-editor
                  v-model="emailValue.body"
                  style="height: 170px"
                  :options="{ modules: {} }"
                />
              </b-form-group>
            </b-modal>
            <!-- Delete registration -->
            <b-modal
              :id="'modal1'+data.item.id"
              :title="$t('Delete Registration')"
              ok-title="Yes"
              cancel-title="No"
              centered
              @ok="remove(data.item.id)"
            >
              <b-card-text>
                <h5>{{ $t('Are you sure you want to delete') }} <strong>{{ data.item.student_name }}</strong></h5> registration from <strong>{{ data.item.course_name }}?</strong>
              </b-card-text>
            </b-modal>

            <!-- basic modal -->
            <!-- <b-modal
              :id="'modal2'+data.item.id"
              title="Confirm registration"
              ok-title="Yes"
              cancel-title="No"
              centered
              @ok="confirm(data.item.id)"
            >
              <b-card-text>
                <h5>{{ $t('Are you sure you want to confirm registration of ') }} <strong>{{ data.item.student_name }}?</strong></h5>
              </b-card-text>
            </b-modal> -->
            <!-- <b-modal
              :id="'modal3'+data.item.id"
              title="Send placement test"
              :ok-title="$t('Send')"
              cancel-title="Cancel"
              centered
              size="lg"
              no-close-on-backdrop
              @ok="sendPTest([data.item.student_id], selectedTest.id, due_date)"
            >
              <div
                v-if="!isLoadedTests"
                class="text-center d-flex align-items-center justify-content-center"
              >
                <b-spinner
                  variant="primary"
                  label="Text Centered"
                />
              </div>
              <div v-if="isLoadedTests">
                <b-form-group
                  :label="$t('Test')"
                  label-for="test"
                >
                  <v-select
                    v-model="selectedTest"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="tests"
                    :clearable="false"
                  />
                </b-form-group>
                <b-form-group
                  :label="$t('Due date')"
                  label-for="due-date"
                >
                  <flat-pickr
                    v-model="due_date"
                    class="form-control"
                    name="date"
                    :placeholder="$t('Due date')"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss', locale: { firstDayOfWeek: 1 }}"
                  />
                </b-form-group>
              </div>
            </b-modal> -->

            <!-- <b-modal
              :id="'modal4'+data.item.id"
              title="Cancel Registration"
              :ok-title="$t('Yes')"
              cancel-title="No"
              centered
              @ok="cancel(data.item.id, sendEmail)"
            >
              <b-card-text>
                <h5>{{ $t('Are you sure you want to cancel registration of') }} <strong>{{ data.item.student_name }} ?</strong></h5>
              </b-card-text>
              <b-form-checkbox
                v-model="sendEmail"
              >
                Send email
              </b-form-checkbox>
            </b-modal> -->

            <!-- <b-modal
              :id="'modal5'+data.item.id"
              :title="$t('Move Student')"
              ok-title="Move"
              centered
              no-close-on-backdrop
              @ok="moveStudent(data.item.id)"
            >
              <b-card-text>
                <b-form-group
                  :label="$t('Course')"
                  label-for="course"
                >
                  <v-select
                    v-model="selectedCourse"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="courses"
                    @keyup.native="autoCompleteCourse($event.target.value)"
                    @click.native="autoCompleteCourse('')"
                  >
                    <span slot="no-options">
                      <div
                        v-if="isTypingCourse"
                        class="text-center d-flex align-items-center justify-content-center"
                      >
                        <b-spinner
                          variant="primary"
                          label="Text Centered"
                        />
                      </div>
                      <div
                        v-if="!isTypingCourse && !courses.length"
                        class="text-center d-flex align-items-center justify-content-center"
                      >
                        No matching records found
                      </div>
                    </span>
                  </v-select>
                </b-form-group>
              </b-card-text>
            </b-modal> -->
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="!isBusy"
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <div
      v-if="isChatActive"
      class="notesModule shadow-lg"
    >
      <div
        style="background: #fff"
        class="pb-1"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="light"
          size="sm"
          class="btn-icon shadow-sm ml-auto "
          style="display: block; transform: translate(8px, -2px);"
          @click="isChatActive = false"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
      <chat-module
        :key="chatModelId.id"
        :model-id="chatModelId.id"
        model-type="registration"
        @decrease-notes="refetchData"
        @notes-increament="refetchData"
      />
    </div>
    <!-- <div
      v-if="isChatActive1"
      class="commentschats"
    >
      <feather-icon
        icon="XSquareIcon"
        size="22"
        class="align-middle text-body close-btn"
        @click="isChatActive1 = false"
      />
      <chat-module
        :conversation="conversation"
        :messages="messages"
      />
    </div> -->
    <!-- send email modal -->
    <!-- <b-modal
      id="send-email"
      :title="$t('Send email')"
      :ok-title="$t('Send')"
      centered
      size="lg"
      no-close-on-backdrop
      :ok-disabled="!email.subject || !email.body"
      @ok="multiAction('send-email')"
    >
      <b-form-group label="Email subject" label-for="email-subject">
        <b-form-input
          placeholder="Email subject"
          type="text"
          v-model="email.subject"
        />
      </b-form-group>
      <b-form-group label="Email body" label-for="email-body" style="height: 260px">
        <quill-editor
          style="height: 170px"
          v-model="email.body"
          :options="{ modules: {} }"
        />
      </b-form-group>
    </b-modal>
    <b-modal
      id="cancel-registration-modal"
      title="Cancel Registration"
      :ok-title="$t('Yes')"
      cancel-title="No"
      centered
      @ok="multiAction('cancel-registration', sendEmail)"
    >
      <b-card-text>
        <h5>{{ $t('Are you sure you want to cancel registrations of') }}</h5>
      </b-card-text>
      <b-form-checkbox
        v-model="sendEmail"
      >
        Send email
      </b-form-checkbox>
    </b-modal>
    <b-modal
      id="move-student"
      :title="$t('Move Student')"
      :ok-title="$t('Move')"
      centered
      no-close-on-backdrop
      @ok="multiAction('move-student')"
    >
      <b-card-text>
        <b-form-group
          :label="$t('Course')"
          label-for="course"
        >
          <v-select
            v-model="selectedCourse"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="courses"
            @keyup.native="autoCompleteCourse($event.target.native)"
            @click.native="autoCompleteCourse('')"
          >
            <span slot="no-options">
              <div
                v-if="isTypingCourse"
                class="text-center d-flex align-items-center justify-content-center"
              >
                <b-spinner
                  variant="primary"
                  label="Text Centered"
                />
              </div>
              <div
                v-if="!isTypingCourse && !courses.length"
                class="text-center d-flex align-items-center justify-content-center"
              >
                No matching records found
              </div>
            </span>
          </v-select>
        </b-form-group>
      </b-card-text>
    </b-modal> -->
  </div>
</template>

<script>
import {
  BCard, /* BCardText, */ BRow, BCol, BFormInput, BButton, BTable,
  BBadge, BDropdown, BDropdownItem, BPagination, /* BModal, */ BFormGroup,
  /* BFormCheckbox, */ BSpinner, VBTooltip, BForm, BFormInvalidFeedback,
  BCardText,
} from 'bootstrap-vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// import UsersListFilters from './UsersListFilters.vue'
import ChatModule from '@/views/admin/ChatModule.vue'
import CoursesListBigModal from '@/views/courses/admin/courses-list/CoursesListBigModal.vue'
import useUsersList from './useUsersList'
import userStoreModule from '../registrations/registerStoreModule'
// import UserListAddNew from './UserListAddNew.vue'
// import userSelectActions from './UserSelectActions.vue'
// import RegistrationsFilter from './RegistrationsFilter.vue'

export default {
  name: 'RegistrationssList',
  components: {
    ValidationProvider,
    ValidationObserver,
    CoursesListBigModal,
    // UsersListFilters,
    // UserListAddNew,
    // RegistrationsFilter,
    // userSelectActions,
    ChatModule,
    BCard,
    // BCardText,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    // BModal,
    BFormGroup,
    // BFormCheckbox,
    BSpinner,
    BForm,
    BFormInvalidFeedback,
    BCardText,

    vSelect,
    flatPickr,
    quillEditor,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    singleCourseId: {
      type: Number,
      required: false,
      default: null,
    },
    // dashboard: {
    //   type: Boolean,
    //   default: false,
    // },
    studentId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      required,
      email,
      isChatActive1: false,
      conversation: {},
      messages: {},
      students: [],
      isTypingStudent: false,
      allRowsSelected: false,
    }
  },
  mounted() {
    if (typeof this.singleCourseId !== 'undefined') {
      this.courseId = this.singleCourseId
    }
  },
  methods: {
    /* eslint-disable camelcase */
    autoCompleteStudent(search, role_name) {
      this.isTypingStudent = true
      this.$http.post('/autocomplete/user', { search, role_name })
      .then(res => {
        this.isTypingStudent = false
        this.students = res.data
      })
    },
    selectAllRows() {
      this.$refs.refUserListTable.selectAllRows()
        this.allRowsSelected = true
      },
      copyEmails(data) {
      this.$copyText(data).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Emails copied',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    clearSelected() {
      this.$refs.refUserListTable.clearSelected()
      this.allRowsSelected = false
    },
    handleChatClick1(id) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.$http.post('/conversation', { participants: [id], user_id: userData.id })
      .then(res => {
        this.isChatActive1 = true
        this.conversation = {
          id: res.data.id,
          avatar: res.data.avatar,
          name: res.data.name,
        }
        this.messages = {
          data: res.data.messages,
        }
      })
    },
  },
  setup(props) {
    const getModal1Id = id => `modal1${id}`
    const getModal2Id = id => `modal2${id}`
    const getModal3Id = id => `modal3${id}`
    const getModal4Id = id => `modal4${id}`
    const getModal5Id = id => `modal5${id}`
    const getModal6Id = id => `modal6${id}`
    const getModal7Id = id => `modal7${id}`

    const USER_APP_STORE_MODULE_NAME = 'registrations'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const query1 = ref('')
    const query2 = ref('')
    const selectedCourse = ref({})
    const courses = ref([])
    const sendEmail = ref(false)
    // Use toast
    const toast = useToast()

    const {
      fetchPayers,
      payerOptions,
      isBusy,
      fetchUsers,
      tableColumns,
      // tableColumns1,
      // tableColumns2,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      selectedRows,
      student,
      course,
      student_id,
      // statuss,
      payer_confirmed,
      visa,
      who_pays,
      courseId,
      statusOptions,
      // defaultStatus,

      // Course modal
      classrooms,
      teachers,
      masters,
      // cities,
      TofDArray,
      statusArray,
      currentUser,
      suggestClassrooms,
      suggestTeacher,
      ModalData,
      currentCourseData,
      msgs,
      UsersToAdd,
      UsersAlreadyAdded,
      isBigModalActive,
      spinner,

      // UI
      resolveUserStatusVariant,
      resolveVisaStatusVariant,
    } = useUsersList()
    // if (props.dashboard) { defaultStatus.value = 'pending' }
    if (props.studentId) { student_id.value = props.studentId }
    const loadSpinner = () => {
      // open
      spinner.value = true
      setTimeout(() => {
        spinner.value = false
      }, 1000)
    }
    const restoreReg = id => {
      store.dispatch('registerStoreModule/restoreReg', id)
      .then(res => {
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
    const payload = ref({})
    const getData = item => {
      payload.value = { ...item }
      payload.value.invoice = payload.value.invoice ? payload.value.invoice : { sent_date: null }
      payload.value.who_pays = payload.value.payer
    }
    const errors = ref({
      paid_date: [],
    })
    const markAs = (id, payment_status_id) => {
      const item = { id, payment_status_id }
      store.dispatch('invoiceStoreModule/editInvoice', { item })
          .then(res => {
            toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            refetchData()
        }).catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          errors.value = error.response.data.errors
        })
    }
    const updatePayment = function (element) {
      /* eslint-disable no-nested-ternary */
      if (this.$refs.form.flags.valid) {
        const item = {
          id: element.invoice ? element.invoice.id : null,
          student_id: element.student_name.id === undefined ? element.student_id : element.student_name.id,
          course_id: element.course_name.id === undefined ? element.course_id : element.course_name.id,
          sent_date: element.sent_date,
          paid_date: element.paid_date,
          paid_method_id: element.paimentMethod ? element.paimentMethod.id : null,
          // paid: element.paid ? element.paid.value : null,
          payer_id: element.who_pays ? element.who_pays.id : null,
          payer_name: element.payer_name,
          company_number: element.company_number,
          payer_address: element.payer_address,
          responsible: element.responsible,
          payer_phone: element.payer_tel,
          payer_email: element.payer_email,
          payment_status_id: element.registrationFilter ? element.registrationFilter.id : null,
          // payment_method_id: element.paimentMethod.id,
        }
        store.dispatch('invoiceStoreModule/editInvoice', { item })
          .then(res => {
            toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            refetchData()
        }).catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          errors.value = error.response.data.errors
        })
      }
    }
    const emailsList = ref([])
    // const onRowSelected = items => {
    //   emailsList.value = items.map(item => `${item.student_name} <${item.student_email}>`)
    //   selectedRows.value = items.map(item => item.id)
    // }
    const emailValue = ref({})
    /* eslint-disable object-curly-newline */
    const sendEmailAction = registrations => {
      const action = 'send-email'
      const course_id = selectedCourse.value.id
      const { subject } = emailValue.value
      const { body } = emailValue.value
      store.dispatch('registerStoreModule/multiAction', { registrations, action, course_id, subject, body })
      .then(response => {
        emailValue.value = {}
        response.data.message.forEach(message => {
          toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        refetchData()
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'FrownIcon',
            variant: 'danger',
          },
        })
      })
    }
    const multiAction = (action, send_email) => {
      const registrations = selectedRows.value
      const course_id = selectedCourse.value.id
      const { subject } = emailValue.value
      const { body } = emailValue.value
      store.dispatch('registerStoreModule/multiAction', { registrations, action, course_id, subject, body, send_email })
      .then(response => {
        emailValue.value = {}
        response.data.message.forEach(message => {
          toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        refetchData()
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'FrownIcon',
            variant: 'danger',
          },
        })
      })
    }
    const markAsPaidPending = (id, paid) => {
      const item = { id, paid }
      store.dispatch('invoiceStoreModule/editInvoice', { item })
        .then(res => {
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const tests = ref([])
    const selectedTest = ref({})
    const due_date = ref(null)
    const isLoadedTests = ref(false)
    const methodOptions = ref([])
    const getPaymentMethod = () => {
      store.dispatch('registerStoreModule/getPaymentMethod').then(res => {
        methodOptions.value = res.data.rows
        methodOptions.value.unshift({ name: 'All methods', id: null })
      })
    }
    const getRegFilters = () => {
      store.dispatch('registerStoreModule/getRegFilters').then(res => {
        statusOptions.value = res.data.rows
        // defaultStatus.value = statusOptions.value.find(el => el.name === 'Paid')
        statusOptions.value.unshift({ name: 'All statuses', id: null })
      })
    }
    onMounted(getPaymentMethod)
    onMounted(getRegFilters)
    const getPlacementTest = () => {
      store.dispatch('registerStoreModule/getPlacementTest')
      .then(response => {
        tests.value = response.data.rows
        isLoadedTests.value = true
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while confirming registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const regToWaitingList = id => {
      store.dispatch('registerStoreModule/regToWaitingList', id)
      .then(response => {
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while confirming registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const sendAdmissionLetter = id => {
      store.dispatch('registerStoreModule/sendAdmissionLetter', id)
      .then(response => {
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        refetchData()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while sending admission letter',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    /* eslint-disable no-shadow */
    const sendPTest = (students, placement_test_id, due_date) => {
      store.dispatch('registerStoreModule/sendPTest', { students, placement_test_id, due_date })
      .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while confirming registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const moveStudent = studentId => {
      const course_id = selectedCourse.value.id
      store.dispatch('registerStoreModule/moveStudent', { studentId, course_id })
      .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while confirming registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const isTypingCourse = ref(false)
    const autoCompleteCourse = search => {
      isTypingCourse.value = true
      store.dispatch('registerStoreModule/autoCompleteCourses', search)
      .then(response => {
        courses.value = response.data
        isTypingCourse.value = false
        })
    }
    const generateContract = id => {
      store.dispatch('registerStoreModule/generateContract', id)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while creating contract ',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const generateCertificate = genId => {
      store.dispatch('registerStoreModule/generateCertificate', genId)
      .then(res => {
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while creating certificate',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const confirm = confId => {
      store.dispatch('registerStoreModule/confirmRegistration', confId)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Registration Has Been confirmed',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while confirming registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }

    const cancel = (cancelId, send_email) => {
      store.dispatch('registerStoreModule/cancelRegistration', { cancelId, send_email })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Registration Has Been canceled',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while canceling registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const remove = regId => {
      store.dispatch('registerStoreModule/deleteRegistration', regId)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Registration Has Been removed',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while removing registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const isChatActive = ref(false)
    const chatModelId = ref(null)
    const handleChatClick = data => {
      isChatActive.value = true
      chatModelId.value = data
    }
    const isFilterActive = ref(false)
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation({})

    const formatDateTime = dateTime => {
      if (typeof dateTime !== 'undefined') {
        const date_ob = new Date()

// adjust 0 before single digit date
        const date = (`0${date_ob.getDate()}`).slice(-2)
// current month
        const month = (`0${date_ob.getMonth() + 1}`).slice(-2)
// current year
        const year = date_ob.getFullYear()
// current hours
        const hours = date_ob.getHours() < 10 ? `0${date_ob.getHours()}` : date_ob.getHours()
// current minutes
        const minutes = date_ob.getMinutes() < 10 ? `0${date_ob.getMinutes()}` : date_ob.getMinutes()
// current seconds
//         const seconds = date_ob.getSeconds()
// prints date & time in YYYY-MM-DD HH:MM:SS format
        /* eslint-disable-next-line */
        // console.log(year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + seconds)
        return `${date}-${month}-${year} ${hours}:${minutes}`
      }
      return ''
    }
    return {
      payerOptions,
      fetchPayers,
      restoreReg,
      isBusy,
      refFormObserver,
      getValidationState,
      resetForm,

      errors,
      getPaymentMethod,
      getRegFilters,
      methodOptions,
      payload,
      getData,
      updatePayment,
      markAs,
      markAsPaidPending,
      emailValue,
      emailsList,
      sendEmailAction,
      getPlacementTest,
      tests,
      selectedTest,
      due_date,
      isLoadedTests,
      // course modal
      isBigModalActive,
      classrooms,
      teachers,
      masters,
      // cities,
      TofDArray,
      statusArray,
      UsersToAdd,
      currentUser,
      suggestClassrooms,
      suggestTeacher,
      ModalData,
      UsersAlreadyAdded,
      spinner,
      loadSpinner,
      currentCourseData,
      msgs,

      // Modal ids
      getModal1Id,
      getModal2Id,
      getModal3Id,
      getModal4Id,
      getModal5Id,
      getModal6Id,
      getModal7Id,

      student,
      course,
      // statuss,
      payer_confirmed,
      visa,
      who_pays,

      isChatActive,
      chatModelId,
      handleChatClick,

      sendEmail,

      // Actions..
      remove,
      cancel,
      confirm,
      generateCertificate,
      generateContract,
      moveStudent,
      sendPTest,
      regToWaitingList,
      sendAdmissionLetter,
      selectedRows,
      multiAction,
      // onRowSelected,

      isFilterActive,

      // autocomplete
      query1,
      query2,
      selectedCourse,
      courses,
      autoCompleteCourse,
      formatDateTime,

      isTypingCourse,

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      // tableColumns1,
      // tableColumns2,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      courseId,
      statusOptions,
      // defaultStatus,

      // UI
      resolveUserStatusVariant,
      resolveVisaStatusVariant,
    }
  },
}
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
// .list-filter {
//   width: 180px;
// }
.table-secondary {
  opacity: 65%;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
