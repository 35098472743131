<template>
  <b-card>
    <div
      v-if="isBusy"
      class="d-flex justify-content-center mb-1"
    >
      <b-spinner
        variant="primary"
        label="Loading..."
      />
    </div>
    <b-row v-if="!isBusy">
      <b-col
        v-for="type in localOptions"
        :key="type.id"
        cols="12"
        class="mb-2"
      >
        <b-row>
          <b-col cols="6">
            <label v-if="type.name">{{ type.name.replaceAll('_', ' ') }}</label>
          </b-col>
          <b-col cols="6">
            <b-form-checkbox
              v-for="(channel, ndx) in type.channels"
              :key="ndx"
              v-model="channel.value"
              :options="type.channels"
              switch
              inline
            >
              <span>{{ channel.label }}</span>
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-col>
      <!-- buttons -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1 mt-1"
          @click="save()"
        >
          {{ $t('settings.Save Changes') }}
        </b-button>
      </b-col>
      <!--/ buttons -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton, BRow, BCol, BCard, BFormCheckbox, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      localOptions: [],
      isBusy: false,
    }
  },
  created() {
    this.getNotifications()
  },
  methods: {
    getNotifications() {
      this.isBusy = true
      /* eslint-disable no-param-reassign, no-return-assign, no-unneeded-ternary */
    this.$http.get('notification-types-list/data', { params: { perPage: 200 } }).then(res => {
    this.localOptions = res.data.rows
    this.isBusy = false
    this.localOptions.map(el => el.channels = [
      { label: 'Push', code: 'broadcast', value: el.channels.includes('broadcast') ? true : false },
      { label: 'SMS', code: 'sms', value: el.channels.includes('sms') ? true : false },
      { label: 'Slack', code: 'slack', value: el.channels.includes('slack') ? true : false },
      { label: 'Email', code: 'mail', value: el.channels.includes('mail') ? true : false },
      ])
    })
    },
    save() {
      this.isBusy = true
      this.$http.post('/notification-types/set/channels', {
        notification_settings: this.localOptions.map(element => {
          return {
            id: element.id,
            channels: element.channels.filter(elmnt => elmnt.value).map(elm => elm.code),
          }
        }),
      })
      .then(response => {
        this.getNotifications()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            text: response.data.message,
            icon: 'CheckIcon',
          },
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            text: error.response.data.message,
            icon: 'AlertTriangleIcon',
          },
        })
        this.$refs.generalData.setErrors(error.response.data.errors)
      })
    },
  },
}
</script>
