export default [
  {
    path: '/teacher',
    name: 'teacher',
    component: () => import('@/views/teacher/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Teacher',
      breadcrumb: [
        {
          text: 'Teacher',
          active: true,
        },
      ],
    },
  },
  {
    path: '/teacher/calendar/teacher-calendar',
    name: 'teacher-teacher-calendar',
    component: () => import('@/views/teacher/teacher-calendar/Calendar.vue'),
  },
  {
    path: '/teacher/dashboard/attendance',
    name: 'teacher-attendance',
    component: () => import('@/views/teacher/attendance-calendar/Calendar.vue'),
  },
  {
    path: '/teacher/courses/courses-list',
    name: 'teacher-list-courses',
    component: () => import('@/views/teacher/courses-list/CoursesList.vue'),
  },
  {
    path: '/teacher/courses/substitute',
    name: 'teacher-courses-substitute',
    component: () => import('@/views/teacher/courses-substitute/CoursesList.vue'),
  },
  {
    path: '/teacher/course/all-courses',
    name: 'teacher-list-all-courses',
    component: () => import('@/views/teacher/course/Courses.vue'),
  },
  {
    path: '/teacher/statistics',
    name: 'teacher-statistics',
    component: () => import('@/views/teacher/statistics/Statistics.vue'),
  },
]
