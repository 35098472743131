<template>
  <div>
    <b-form
      class="p-2"
      @submit.prevent="AddSubstitute"
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <!-- Levels checkbox -->
          <b-form-group
            label="Levels"
            label-for="levels"
          >
            <div class="demo-inline-spacing">
              <b-form-checkbox
                v-model="selectedLevel"
                value="A1-A2"
                class="custom-control-primary"
              >
                A0-A2
              </b-form-checkbox>
              <b-form-checkbox
                v-model="selectedLevel"
                value="B1"
                class="custom-control-primary"
              >
                B1
              </b-form-checkbox>
              <b-form-checkbox
                v-model="selectedLevel"
                value="B2"
                class="custom-control-primary"
              >
                B2
              </b-form-checkbox>

              <b-form-checkbox
                v-model="selectedLevel"
                value="C1"
                class="custom-control-primary"
              >
                C1
              </b-form-checkbox>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <!-- Days -->
          <b-form-group
            label="Days"
            label-for="days"
          >
            <div class="demo-inline-spacing">
              <b-form-checkbox
                v-model="selectedDay"
                value="mo"
                class="custom-control-primary"
              >
                Mo
              </b-form-checkbox>
              <b-form-checkbox
                v-model="selectedDay"
                value="tu"
                class="custom-control-primary"
              >
                Tu
              </b-form-checkbox>
              <b-form-checkbox
                v-model="selectedDay"
                value="we"
                class="custom-control-primary"
              >
                We
              </b-form-checkbox>

              <b-form-checkbox
                v-model="selectedDay"
                value="Th"
                class="custom-control-primary"
              >
                Th
              </b-form-checkbox>

              <b-form-checkbox
                v-model="selectedDay"
                value="All levels"
                class="custom-control-primary"
              >
                Fr
              </b-form-checkbox>
              <b-form-checkbox
                v-model="selectedDay"
                value="fr"
                class="custom-control-primary"
              >
                Sa
              </b-form-checkbox>
              <b-form-checkbox
                v-model="selectedDay"
                value="su"
                class="custom-control-primary"
              >
                Su
              </b-form-checkbox>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="12"
        >
          <!-- Time frames -->
          <b-form-group
            label="Time"
            label-for="time"
          >
            <div class="demo-inline-spacing">
              <b-form-checkbox
                v-model="selectedTime"
                value="1"
                class="custom-control-primary"
              >
                08:30 - 10:45
              </b-form-checkbox>
              <b-form-checkbox
                v-model="selectedTime"
                value="2"
                class="custom-control-primary"
              >
                11:00 - 13:15
              </b-form-checkbox>
              <b-form-checkbox
                v-model="selectedTime"
                value="3"
                class="custom-control-primary"
              >
                13:30 - 15:45
              </b-form-checkbox>

              <b-form-checkbox
                v-model="selectedTime"
                value="4"
                class="custom-control-primary"
              >
                17:00 - 19:15
              </b-form-checkbox>

              <b-form-checkbox
                v-model="selectedTime"
                value="5"
                class="custom-control-primary"
              >
                19:30 - 21:45
              </b-form-checkbox>
              <b-form-checkbox
                v-model="selectedTime"
                value="6"
                class="custom-control-primary"
              >
                10:00 – 15:00 (sat)
              </b-form-checkbox>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <!-- Date range -->
          <b-form-group
            label="Date range"
            label-for="date_range"
          >
            <div class="d-flex align-items-center">
              <flat-pickr
                v-model="date_range"
                class="form-control"
                placeholder="Pick a date range..."
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss', mode: 'range', locale: { firstDayOfWeek: 1 }}"
                style="width: 60%"
              />
              <!-- Find substitute button -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="ml-1"
                :disabled="!date_range || !isLoaded"
                @click="findSubstitute"
              >
                <b-spinner
                  v-if="!isLoaded"
                  class="mr-25"
                  small
                />
                {{ $t('Find substitute') }}
              </b-button>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          v-if="teacherOptions.length !== 0"
          sm="12"
          md="6"
        >
          <!-- Teachers list -->
          <b-form-group
            label="Available teachers"
            label-for="available"
          >
            <v-select
              v-model="selectedTeacher"
              label="name"
              multiple
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="teacherOptions"
            />
          </b-form-group>
          <!-- Available -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            {{ $t('Add') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>
<script>
/* eslint-disable-next-line object-curly-newline */
import { BForm, BFormGroup, BFormCheckbox, BButton, BRow, BCol, BSpinner } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BRow,
    BCol,
    flatPickr,
    vSelect,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    teacherId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedLevel: [],
      selectedDay: [],
      selectedTime: [],
      selectedTeacher: [],
      teacherOptions: [],
      date_range: null,
      isLoaded: true,
    }
  },
  methods: {
    AddSubstitute() {
      const payload = {
        levels: this.selectedLevel.join(','),
        days: this.selectedDay.join(','),
        time: this.selectedTime.join(','),
        date_start: this.date_range.split('to')[0].trim(),
        date_end: this.date_range.split('to')[1].trim(),
        substitute_id: this.selectedTeacher.map(teacher => teacher.id),
        teacher_id: this.teacherId,
      }
      this.$http.post('/teacher/substitute/by-teacher/create', payload)
        .then(() => {
          this.$emit('hide-modal')
          this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Substitute has been created',
            icon: 'CopyIcon',
            variant: 'success',
          },
        })
        })
        .catch(err => {
          this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: err.message,
            icon: 'CoffeeIcon',
            variant: 'danger',
          },
        })
        })
    },
    findSubstitute() {
      this.isLoaded = false
      if (this.date_range) {
        const payload = {
          levels: this.selectedLevel.join(','),
          days: this.selectedDay.join(','),
          time: this.selectedTime.join(','),
          date_start: this.date_range.split('to')[0].trim(),
          date_end: this.date_range.split('to')[1].trim(),
          teacher_id: this.teacherId,
        }
        this.$http.post('/teacher/substitute/find', payload)
        .then(res => {
          this.isLoaded = true
          this.teacherOptions = res.data
        })
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
