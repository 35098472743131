<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('Add New reciept') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Student -->
          <validation-provider
            #default="validationContext"
            name="Student"
            rules="required"
          >
            <b-form-group
              :label="$t('Student')"
              label-for="student"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="selectedStudent"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="students"
                :clearable="false"
                @keyup.native="autoCompleteStudent($event.target.value, 'student')"
                @click.native="autoCompleteStudent('', 'student')"
              >
                <span slot="no-options">
                  <div
                    v-if="isTypingStudent"
                    class="text-center d-flex align-items-center justify-content-center"
                  >
                    <b-spinner
                      variant="primary"
                      label="Text Centered"
                    />
                  </div>
                  <div
                    v-if="!isTypingStudent && !students.length"
                    class="text-center d-flex align-items-center justify-content-center"
                  >
                    No matching records found
                  </div>
                </span>
                <template #option="{ name, email }">
                  <span> {{ name }}</span>
                  <small class="d-block">{{ email }}</small>
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Course -->
          <validation-provider
            #default="validationContext"
            name="course"
            rules="required"
          >
            <b-form-group
              :label="$t('Course')"
              label-for="course"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="selectedCourse"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="code"
                :clearable="false"
                :options="courses"
                @keyup.native="autoCompleteCourse($event.target.value)"
                @click.native="autoCompleteCourse('')"
              >
                <span slot="no-options">
                  <div
                    v-if="isTypingCourse"
                    class="text-center d-flex align-items-center justify-content-center"
                  >
                    <b-spinner
                      variant="primary"
                      label="Text Centered"
                    />
                  </div>
                  <div
                    v-if="!isTypingCourse && !courses.length"
                    class="text-center d-flex align-items-center justify-content-center"
                  >
                    No matching records found
                  </div>
                </span>
                <template #option="{ code, group, date_start }">
                  <div class="d-flex justify-content-between">
                    <p> {{ code }}</p>
                    <small class="d-block text-secondary">{{ group }}</small>
                    <p>{{ date_start }}</p>
                  </div>
                </template>
              </v-select>

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Sent date -->
          <validation-provider
            #default="validationContext"
            name="Start date"
            rules="required"
          >
            <b-form-group
              :label="$t('Sent date')"
              label-for="example-datePicker2"
              :state="getValidationState(validationContext)"
            >
              <flat-pickr
                v-model="datePicker2"
                class="form-control"
                name="date"
                :config="{ altFormat: 'd-m-Y', altInput: true}"
                :placeholder="$t('Sent at...')"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Sent date -->
          <validation-provider
            #default="validationContext"
            name="Paid date"
            rules="required"
          >
            <b-form-group
              label-for="example-datePicker3"
              :label="$t('Paid date')"
              :state="getValidationState(validationContext)"
            >
              <flat-pickr
                v-model="datePicker3"
                class="form-control"
                name="date"
                :config="{ altFormat: 'd-m-Y', altInput: true}"
                :placeholder="$t('Paid at...')"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Paid -->
          <b-form-group :label="$t('Paid')">
            <v-select
              v-model="selectedPaid"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="paidOptions"
              label="label"
              :clearable="false"
            />
          </b-form-group>

          <!-- Status -->
          <b-form-group v-if="selectedPaid.value" :label="('Payment method')">
            <v-select
              v-model="selectedPaidMethod"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="methods"
              label="name"
              :clearable="false"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInvalidFeedback, BButton, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, alphaNum, email, min,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import axios from '@axios'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BSpinner,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    methods: {
      type: Array,
      required: true,
    },
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      min,
      countries,
      query1: '',
      query2: '',
      students: [],
      courses: [],
      paidOptions: [{ label: 'No', value: false }, { label: 'Yes', value: true }],
      isTypingStudent: false,
      isTypingCourse: false,
      // methods: [
      //   { label: 'Card', value: 'card' },
      //   { label: 'Cash', value: 'cash' },
      //   // { label: 'Online', value: 'online' },
      //   { label: 'Wire transfer', value: 'wire_transfer' },
      // ],
    }
  },
  updated() {
    if (this.methods.length !== 0) {
      this.selectedPaidMethod = this.methods.find(el => el.name === 'Card')
    }
  },
  methods: {
    /* eslint-disable camelcase */
    autoCompleteStudent(search, role_name) {
      this.isTypingStudent = true
      axios.post('/autocomplete/user', { search, role_name })
      .then(res => {
        this.isTypingStudent = false
        this.students = res.data
      })
    },

    autoCompleteCourse(search) {
      this.isTypingCourse = true
      axios.post('/autocomplete/course', { search })
      .then(res => {
        this.isTypingCourse = false
        this.courses = res.data
      })
    },
  },
  setup(props, { emit }) {
    const selectedStudent = ref({})
    const selectedCourse = ref({})
    const selectedPaid = ref({ label: 'No', value: false })
    const selectedPaidMethod = ref({})
    const datePicker2 = ref('')
    const datePicker3 = ref('')

    const invoiceData = {}

    const resetRegistrationData = () => {
      selectedStudent.value = {}
      selectedCourse.value = {}
      selectedPaid.value = { label: 'No', value: false }
      // selectedPaidMethod.value = { name: 'Card', id: 3 }
      datePicker2.value = ''
      datePicker3.value = ''
    }
    const onSubmit = () => {
      invoiceData.paid_method_id = selectedPaidMethod.value.id
      invoiceData.paid = selectedPaid.value.value
      invoiceData.student_id = selectedStudent.value.id
      invoiceData.course_id = selectedCourse.value.id
      invoiceData.sent_date = datePicker2.value
      invoiceData.paid_date = datePicker3.value
      if (!invoiceData.paid) delete invoiceData.method

      store.dispatch('invoice/addInvoice', invoiceData)
        .then(() => {
          resetRegistrationData()
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRegistrationData)

    return {
      onSubmit,
      // comments,
      selectedStudent,
      selectedCourse,
      selectedPaid,
      selectedPaidMethod,
      invoiceData,
      datePicker2,
      datePicker3,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
