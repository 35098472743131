<template>
  <div>
    <b-card
      class="chat-widget"
      no-body
    >
      <div
        v-if="isBusy"
        class="d-flex"
        :style="modelId ? { 'height': '308px' } : { 'height': '502px' }"
      >
        <div class="m-auto">
          <b-spinner
            variant="primary"
            label="Text Centered"
          />
          <strong class="ml-1">Loading...</strong>
        </div>
      </div>
      <b-card-header v-if="!isBusy && !modelId">
        <div class="d-flex align-items-center">
          <b-avatar
            size="34"
            :src="chatData.conversation.avatar"
            class="mr-50 badge-minimal"
          />
          <h5 class="mb-0">
            {{ chatData.conversation.name }}
          </h5>
        </div>
      </b-card-header>

      <section v-if="!isBusy" class="chat-app-window">
        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
          :class="student ? 'chat-module' : 'normal'"
        >
          <chat-log
            v-if="chatData"
            :chat-data="chatData"
            :isDashboard="true"
            :profile-user-avatar="userData.avatar"
            :profile-user-data-minimale="userData"
            :profile-user-id="userData.id"
            @decrease-notes="$emit('decrease-notes')"
          />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form
          class="chat-app-form"
          @submit.prevent="sendMessage"
        >
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input
              v-model="chatInputMessage"
              placeholder="Enter your message"
            />
          </b-input-group>
          <b-button
            variant="primary"
            type="submit"
          >
            Send
          </b-button>
        </b-form>
      </section>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BAvatar, BForm, BFormInput, BInputGroup, BButton, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ChatLog from '@/views/apps/chat/ChatLog.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BAvatar,
    BForm,
    BFormInput,
    BInputGroup,
    BButton,
    BSpinner,
    // SFC
    ChatLog,
    // 3rd party
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  props: {
    modelId: {
      type: Number,
      // required: true,
    },
    modelType: {
      type: String,
      // required: true,
    },
    conversation: {
      type: Object,
    },
    messages: {
      type: Object,
    },
    student: {
      type: Boolean,
    },
  },
  data() {
    return {
      isBusy: true,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
      chatInputMessage: '',
      chatData: {
        conversation: {},
        messages: {
          data: [],
        },
      },
      userData: {},
    }
  },
  created() {
    /* eslint-disable no-return-assign */
    const queryParams = {
      model_type: this.modelType,
      model_id: this.modelId,
    }
    if (this.$route.params) {
      queryParams.user_id = this.$route.params.id
    }
    this.userData = JSON.parse(localStorage.userData)
    if (this.modelId) {
      this.$http.get('/model/comments/get', {
        params: queryParams,
      }).then(res => {
        this.chatData.conversation = {
          id: res.data.id,
          name: res.data.name,
          avatar: res.data.avatar,
        }
        // this.chatData.messages = res.data.messages
        if (res.data.messages) {
          res.data.messages.forEach(message => {
            this.chatData.messages.data.push(message)
          })
        }
        this.isBusy = false
      })
    } else {
      this.chatData = {
        conversation: this.conversation,
        messages: this.messages,
      }
      this.isBusy = false
    }
  },
  mounted() {
    if (!this.modelId) {
      this.$store.state.appConfig.conversationId = this.chatData.conversation.id
      window.Echo.channel(`conversation.${this.$store.state.appConfig.conversationId}`)
      .listen('.chat.message', e => {
        this.chatData.messages.data.unshift({
          message: e.message.message,
          time: e.message.time,
          sender: e.message.sender,
        })
        this.$nextTick(() => {
          this.psToBottom()
        })
      })
    }
    this.psToBottom()
  },
  methods: {
    sendMessage() {
      if (!this.chatInputMessage) return
      if (this.modelId) {
        let today = new Date()
        const dd = String(today.getDate()).padStart(2, '0')
        const mm = String(today.getMonth() + 1).padStart(2, '0')
        const yyyy = today.getFullYear()
        const hours = String(today.getHours()).padStart(2, '0')
        const minutes = String(today.getSeconds()).padStart(2, '0')
        today = `${dd}-${mm}-${yyyy} ${hours}:${minutes}`
        this.chatData.messages.data.unshift({
          message: this.chatInputMessage,
          time: today,
          sender: { id: this.userData.id },
          id: null,
        })
      }
      const payload = {
        // eslint-disable-next-line no-use-before-define
        model_type: this.modelType,
        model_id: this.modelId,
        message: this.chatInputMessage,
      }
      if (this.$route.params) {
        payload.user_id = this.$route.params.id
      }
      const payload1 = {
        conversation_id: this.chatData.conversation.id,
        user_id: this.userData.id,
        content: this.chatInputMessage,
      }
      const URL = this.modelId ? '/model/comment/add' : '/conversation/message/send'
      this.$http.post(URL, (this.modelId ? payload : payload1)).then(res => {
        this.chatData.messages.data[0].id = res.data.message.id
      })
      this.chatInputMessage = ''
      // Update scroll position
      // Scroll to bottom
      this.$nextTick(() => {
        this.$emit('notes-increament')
        this.psToBottom()
      })
    },
    psToBottom() {
      if (typeof this.$refs.refChatLogPS !== 'undefined') {
        const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS
        scrollEl.scrollTop = scrollEl.scrollHeight
      }
    },
  },
}
</script>

<style lang="scss">
.chat-module {
  height: 222px !important;
}
@import "@core/scss/base/pages/app-chat-list.scss";
</style>
