import axios from '@axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'warning',
        label: 'Event',
      },
      {
        color: 'primary',
        label: 'Course',
      },
    ],
    levelsOptions: [
      {
        color: 'secondary',
        label: 'A1-A2',
      },
      {
        color: 'warning',
        label: 'B1',
      },
      {
        color: 'danger',
        label: 'B2',
      },
      {
        color: 'success',
        label: 'C1',
      },
    ],
    selectedCalendars: ['Event', 'Course'],
    selectedLevels: ['A1-A2', 'B1', 'B2', 'C1'],
  },
  getters: {},
  mutations: {
    SET_EVENTS(state, response) {
      state.events = response
    },
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
    SET_SELECTED_LEVELS(state, val) {
      state.selectedLevels = val
    },
  },
  actions: {
    /* eslint-disable camelcase, object-curly-newline, no-unused-vars, object-curly-newline */
    fetchEvents(store, { date_start, date_end, filter }) {
      // console.log(filter)
      return new Promise((resolve, reject) => {
        axios
          .post('/calendar/teacher/events', {
              // status: statuss,
              // calendars: calendars.join(','),
              date_start,
              date_end,
              filter: filter.join(),
              // classroom_id,
              // status: 'available',
          })
          .then(response => {
            store.commit('SET_EVENTS', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getClassrooms() {
      return new Promise((resolve, reject) => {
        axios
          .get('/classroom-list/data')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { event }) {
      const name = event.title
      const date_start = `${event.start.split(' ')[0]} ${event.from}:00`
      const date_end = `${event.end.split(' ')[0]} ${event.to}:00`
      const { description } = event
      return new Promise((resolve, reject) => {
        axios
          .post('/teacher/event/create', { name, description, date_start, date_end })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/calendar/events/${event.id}`, { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    teacherAutoComplete(ctx, { search, role_name }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/autocomplete/user', { search, role_name })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
