<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        sm="6"
      >
        <b-form-group
          label="Course"
          label-for="course"
        >
          <v-select
            v-model="fStatus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :clearable="false"
            :options="statusArray"
          >
            <template #option="{ value, name }">
              <span
                class="bullet bullet-sm mr-1"
                :class="`${value}-filter`"
              />
              <span> {{ name }}</span>
            </template>

            <template #selected-option="{ value, name }">
              <span
                class="bullet bullet-sm mr-1"
                :class="`${value}-filter`"
              />
              <span>{{ name }}</span>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="card app-calendar overflow-hidden border">
      <div
        v-if="isBusyCalendar"
        class="text-center bg-transparent mt-3"
      >
        <b-spinner
          variant="primary"
          label="Text Centered"
        />
        <strong class="ml-1 pt-1">{{ $t('Loading...') }}</strong>
      </div>
      <div class="row no-gutters">

        <!-- Sidebar -->
        <!--        <div-->
        <!--          class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"-->
        <!--          :class="{'show': isCalendarOverlaySidebarActive}"-->
        <!--        >-->
        <!--          <calendar-sidebar-->
        <!--            :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"-->
        <!--            :is-filter-handler-sidebar-active.sync="isFilterHandlerSidebarActive"-->
        <!--            @open_filter="handleOpenFilter"-->
        <!--          />-->
        <!--        </div>-->

        <!-- Calendar -->
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar courses-calendar"
              />
            </div>
            <course-table
              v-if="isEventModalActive && selected.name !== 'All'"
              v-model="isEventModalActive"
              @hide-modal="isEventModalActive = false"
            />
          </div>
        </div>
        <b-modal
          ref="my-modal"
          title="Basic Modal"
          ok-only
          ok-title="Accept"
        />

        <!-- Sidebar Overlay -->
        <div
          class="body-content-overlay"
          :class="{'show': isCalendarOverlaySidebarActive}"
          @click="isCalendarOverlaySidebarActive = false"
        />
        <calendar-event-handler
          v-if="isEventHandlerSidebarActive"
          v-model="isEventHandlerSidebarActive"
          :event="event"
          :clear-event-data="clearEventData"
          @remove-event="removeEvent"
          @update-event="updateEvent"
          @get-suggestions="getSuggestions"
          @show-attendance-modal="isAttendanceModalActive = true"
          @reset-date-error="errors.start = []"
          :errors="errors"
        />
        <!-- Fff filter -->
        <calendar-filter-handler
          v-if="isFilterHandlerSidebarActive"
          v-model="isFilterHandlerSidebarActive"
          :event="event"
          :clear-event-data="clearEventData"
          :number.sync="number"
          :group.sync="group"
          :classroom_id.sync="classroom_id"
          :mastercourse_id.sync="mastercourse_id"
          :teacher_id.sync="teacher_id"
          :fStatus.sync="fStatus"
          :time_of_day.sync="time_of_day"
          :time_from.sync="time_from"
          :time_to.sync="time_to"
          :date_start_from.sync="date_start_from"
          :date_start_to.sync="date_start_to"
          :date_end_from.sync="date_end_from"
          :date_end_to.sync="date_end_to"
          :masters="$store.state.calendar.selectedCalendars.mastercoursesList ? $store.state.calendar.selectedCalendars.mastercoursesList.rows : []"
          :cities="[]"
          :classrooms="$store.state.calendar.selectedCalendars.classroomsList ? $store.state.calendar.selectedCalendars.classroomsList.rows : []"
          :teachers="$store.state.calendar.selectedCalendars.teachersList ? $store.state.calendar.selectedCalendars.teachersList.rows : []"
          :TofDArray="TofDArray"
          :statusArray="statusArray"
          @remove-event="removeEvent"
          @add-event="addEvent"
          @update-event="updateEvent"
          @reset-filter="resetFilter"
        />
      </div>
      <b-modal
        id="attendance-modal"
        v-model="isAttendanceModalActive"
        ok-title="close"
        hide-footer
        ok-only
        centered
        size="xl"
        body-class="attendance-modal"
        scrollable
      >
        <template #modal-title>
          <span
            v-if="!attendanceModalTitle.lesson"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="secondary"
              label="Text Centered"
            />
          </span>
          <b v-if="attendanceModalTitle.lesson">Lesson {{ attendanceModalTitle.lesson }}</b>
          <span class="d-inline ml-1" v-if="attendanceModalTitle.date">{{ attendanceModalTitle.date }}</span>
        </template>
        <students-attendance
          :session-id="event.extendedProps.session_id"
          @close-modal="$bvModal.hide('attendance-modal')"
          @modal-title="val => attendanceModalTitle = val"
        />
      </b-modal>
      <session-update
        v-if="event && event.id && isSessionHandlerSidebarActive"
        v-model="isSessionHandlerSidebarActive"
        :session-id="event.extendedProps.session_id"
        :teachers="teachersBySession"
        :classrooms="classroomsBySession"
        :hasLoadedTeachers="hasLoadedTeachers"
        :hasLoadedClassrooms="hasLoadedClassrooms"
        @is-session-handler-sidebar-active="isSessionHandlerSidebarActive = false"
        @refetch-data="refetchEvents"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable-next-line */
import { VBModal, BSpinner, BFormGroup, BRow, BCol } from 'bootstrap-vue'
import vSelect from 'vue-select'
import FullCalendar from '@fullcalendar/vue'
import {
  onUnmounted,
  // onMounted,
  watch,
  ref,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import StudentsAttendance from '@/views/teacher/attendance-calendar/calendar-event-handler/StudentsAttendance.vue'
import SessionUpdate from '@/views/admin/courses-approval/SessionUpdate.vue'
import calendarStoreModule from './courseCalendarStoreModule'
// import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import CourseTable from './calendar-event-handler/CourseTable.vue'
import useCalendar from './useCalendar'
import CalendarFilterHandler from './calendar-event-handler/CalendarFilterHandler.vue'

export default {
  components: {
    SessionUpdate,
    CalendarFilterHandler,
    StudentsAttendance,
    FullCalendar, // make the <FullCalendar> tag available
    // CalendarSidebar,
    CalendarEventHandler,
    CourseTable,
    BSpinner,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      attendanceModalTitle: {},
      selected: { name: 'All' },
      transition: '0s',
    }
  },
  updated() {
    this.$nextTick(() => {
      // Code that will run only after the
      // entire view has been re-rendered
      this.updateTransitionDom()
    })
  },
  methods: {
    handleOpenFilter() {
      // console.log('open filter')
      this.isFilterHandlerSidebarActive = !this.isFilterHandlerSidebarActive
    },
    handleMonthChange(data) {
      console.log(data)
    },
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    // onMounted(() => {
      // console.log(store)
      // console.log('mounted')
      // setTimeout(() => {
      //   // console.log(store.state.calendar.selectedCalendars.classroomsList.rows)
      //   console.log(store.state.calendar.selectedCalendars)
      // }, 12500)
    // })

    const updateTransitionDom = () => {
      setTimeout(() => {
        const sessionsdiv = document.querySelectorAll('.c-session')
        if (sessionsdiv) {
          // console.log('updateTransitionDom')
          sessionsdiv.forEach(ssDiv => {
            /* eslint-disable-next-line */
            ssDiv.style.transition = '6s'
          })
        }
      }, 500)
    }
    /* eslint-disable camelcase */
    // const classroom_id = ref(null)
    const cities = ref([])
    const masters = ref([])
    const classrooms = ref([])
    const teachers = ref([])
    const courseGroup = ref(null)
    //

    const {
      number,
      group,
      mastercourse_id,
      teacher_id,
      date_start_from,
      date_start_to,
      date_end_from,
      date_end_to,
      time_from,
      time_to,
      time_of_day,
      TofDArray,
      statusArray,
      fStatus,
      errors,
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,
      getClassrooms,
      getTeachers,
      fetchMCourses,
      classroomOptions,
      selectedClassroom,

      isAttendanceModalActive,
      isSessionHandlerSidebarActive,

      getSuggestions,
      classroom_id,
      isBusyCalendar,
      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isFilterHandlerSidebarActive,
      isEventModalActive,
      isModalActive,
    } = useCalendar()

    fetchEvents()
    getClassrooms()
    getTeachers()
    fetchMCourses()
    const refresh = selected => {
      selectedClassroom.value = selected.name
      // classroom_id.value = selected.id
      // if (selected.name === 'All') { isEventModalActive.value = false }
    }
    const resetFilter = () => {
        group.value = null
        mastercourse_id.value = null
        classroom_id.value = null
        teacher_id.value = null
        fStatus.value = null
        date_start_from.value = null
        date_start_to.value = null
        date_end_from.value = null
        date_end_to.value = null
        time_from.value = null
        time_to.value = null
        time_of_day.value = null
  }
    watch([
      number,
      group,
      mastercourse_id,
      teacher_id,
      classroom_id,
      date_start_from,
      date_start_to,
      date_end_from,
      date_end_to,
      fStatus,
      time_of_day,
      time_from,
      time_to,
    ], () => {
      store.state.calendar.selectedCalendars.test = 'dsdsd'
      calendarOptions.value.params = {
        number: number.value ? number.value : null,
        group: group.value ? group.value : null,
        mastercourse_id: mastercourse_id.value ? mastercourse_id.value : null,
        teacher_id: teacher_id.value ? teacher_id.value : null,
        classroom_id: classroom_id.value ? classroom_id.value : null,
        date_start_from: date_start_from.value ? date_start_from.value : null,
        date_start_to: date_start_to.value ? date_start_to.value : null,
        date_end_from: date_end_from.value ? date_end_from.value : null,
        date_end_to: date_end_to.value ? date_end_to.value : null,
        fStatus: fStatus.value.value ? fStatus.value.value : null,
        time_of_day: time_of_day.value ? time_of_day.value : null,
        time_from: time_from.value ? time_from.value : null,
        time_to: time_to.value ? time_to.value : null,
      }
      refetchEvents()
      // this.updateTransitionDom()
    })

    return {
      errors,
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,
      selectedClassroom,
      classroom_id,
      getClassrooms,
      getTeachers,
      fetchMCourses,
      refresh,
      isBusyCalendar,
      resetFilter,

      isAttendanceModalActive,
      isSessionHandlerSidebarActive,

      classroomOptions,
      getSuggestions,

      number,
      group,
      mastercourse_id,
      teacher_id,
      date_start_from,
      date_start_to,
      date_end_from,
      date_end_to,
      fStatus,
      time_of_day,
      time_from,
      time_to,
      cities,
      masters,
      classrooms,
      teachers,
      statusArray,
      TofDArray,
      courseGroup,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isFilterHandlerSidebarActive,
      isEventModalActive,
      isModalActive,
      updateTransitionDom,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
@import '@core/scss/vue/libs/vue-select.scss';
span.bullet.bullet-sm.mr-1 {
  margin-right: 0.5rem !important;
}
.fc-more-popover { top : 109px;}
.fc-popover-body {max-height: calc(93vh - 109px);}
.fc .fc-daygrid-event-harness .fc-event {
  font-weight: 500;
}
.textBox {
  width: 300px;
  height: 15px;
  /*border: 1px solid #000;*/
  overflow: hidden;
  /*line-height: 30px;*/
  padding: 0px;
  position: relative;
}
.textBox .c-session {
  position: absolute;
  white-space: nowrap;
  transform: translateX(0);
  /*transition: 4s;*/
  padding-right: 10px;
}
.textBox:hover .c-session {
  transform: translateX(calc(100px - 100%));
  padding-right: 10px;
}

.fc-popover-body .textBox {
  width: auto;
  display: block;
  overflow: initial;
}
.fc-popover-body .textBox .c-session {
  transform: none;
  position: relative;
}
.fc-popover-body .textBox:hover .c-session {
  transform: none;
}
[dir] .textBox .c-session {padding-right: 10px}
.fc-popover-body {
  overflow-y: scroll;
}
.c-start, .c-end {font-weight: 600 !important;}
.fc-popover-body  span.bullet.bullet-sm.mr-1 {
  margin-bottom: 0rem;
}
.courses-calendar  td.fc-list-event-time,
.courses-calendar  td.fc-list-event-graphic  {
  display: none;
}
.fc-list .textBox{
  width: 600px;
}
.fc-list .textBox:hover .c-session {
  transform: translateX(0);
  padding-right: 10px;
}
</style>
