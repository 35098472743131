<template>
  <div>
    <b-sidebar
      id="sidebar-add-session-handler"
      sidebar-class="sidebar-lg"
      :visible="isSessionHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-session-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div v-if="!loadingData" class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Update Session (lesson: {{ payload.lesson_number }})
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <div
          v-if="loadingData"
          class="text-center d-flex align-items-center justify-content-center"
          style="height: 100%"
        >
          <b-spinner
            variant="primary"
            label="Text Centered"
          />
        </div>
        <!-- Body -->
        <validation-observer
          v-if="!loadingData"
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- Course -->
            <validation-provider
              #default="validationContext"
              name="Course name"
              rules="required"
            >
              <b-form-group
                label="Course name"
                label-for="course-name"
              >
                <b-form-input
                  id="course-name"
                  v-model="payload.course"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Course name"
                  disabled
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Date -->
            <b-form-group
              label="Date"
              label-for="session-date"
            >
              <b-form-datepicker
                id="session-date"
                v-model="payload.date"
                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                locale="en-UK"
                :start-weekday="1"
                :date-info-fn="dateInfoFn"
              />
              <!-- <flat-pickr
                v-model="payload.date"
                class="form-control"
                :config="{ dateFormat: 'Y-m-d', altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 } }"
              /> -->
              <small class="text-danger" v-if="startErros.length > 0">{{ startErros[0].split('.')[0] }}</small>
            </b-form-group>
            <b-modal
              v-model="shallPostponeCourses"
              title="Warning!"
              ok-title="Yes"
              @ok="onSubmit(true)"
              cancel-title="No"
              centered
            >
              <b-card-text>{{ startErros[0] }}</b-card-text>
            </b-modal>
            <!-- {{ payload }} -->
            <b-row>
              <b-col cols="6">
                <!-- Time-from -->
                <validation-provider
                  #default="validationContext"
                  name="From"
                  rules="required"
                >

                  <b-form-group
                    label="From"
                    label-for="time-to"
                    :state="getValidationState(validationContext)"
                  >
                    <flat-pickr
                      v-model="payload.start_time"
                      class="form-control"
                      :config="{ noCalendar: true, enableTime: true, minTime: '07:00', time_24hr: true }"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6">
                <!-- time-to -->
                <validation-provider
                  #default="validationContext"
                  name="To"
                  rules="required"
                >

                  <b-form-group
                    label="To"
                    label-for="time-to"
                    :state="getValidationState(validationContext)"
                  >
                    <flat-pickr
                      v-model="payload.end_time"
                      class="form-control"
                      :config="{ noCalendar: true, enableTime: true, time_24hr: true, minTime: payload.start_time }"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <!-- Homework Url -->
            <validation-provider
              v-if="payload.homework"
              #default="validationContext"
              name="HomeWork URL"
              rules="url"
            >

              <b-form-group
                label="Homework URL"
                label-for="event-url"
              >
                <b-form-input
                  id="event-url"
                  v-model="payload.homework.link"
                  type="url"
                  :state="getValidationState(validationContext)"
                  placeholder="htttps://www.example.com"
                  trim
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Teacher -->
            <validation-provider
              #default="validationContext"
              name="Teacher"
            >
              <!-- Teacher -->
              <b-form-group
                label="teacher"
                label-for="teacher"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="payload.teacher"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :close-on-select="true"
                  :options="payload.substitutes && payload.substitutes.length > 0 ? teachersBySession.filter(teacher => !payload.substitutes.some(sub => sub.id === teacher.id)) : teachersBySession"
                  label="name"
                  :disabled="!payload.shall_update_teacher"
                  :selectable="el => el.available === 'available'"
                  :loading="!hasLoadedTeachers"
                >
                  <!-- :selectable="el => el.available === 'available'" -->
                  <template #option="{ avatar, name, available }">
                    <div class="d-flex justify-content-between">
                      <div>
                        <b-avatar
                          size="sm"
                          :src="avatar"
                        />
                        <span class="d-inline ml-50 align-middle">{{ name }}</span>
                      </div>
                      <small>
                        <b-badge
                          pill
                          :variant="`light-${available === 'available' ? 'success' : 'danger'}`"
                          class="text-capitalize"
                        >
                          {{ available === 'available' ? 'Available' : 'Unavailable' }}
                        </b-badge>
                      </small>
                      <!-- <b-badge pill :variant="available === 'available' ? 'success' : 'danger'" class="text-capitalize">{{ available }}</b-badge> -->
                    </div>
                  </template>

                  <template #selected-option="{ avatar, name }">
                    <b-avatar
                      size="sm"
                      class="border border-white"
                      :src="avatar"
                    />
                    <span class="ml-50 align-middle"> {{ name }}</span>
                  </template>
                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Substitute -->
            <validation-provider
              #default="validationContext"
              name="Teacher"
            >
              <!-- Substitute -->
              <b-form-group
                label="Substitute teacher"
                label-for="substitute"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="payload.substitutes"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :close-on-select="true"
                  label="name"
                  multiple
                  :options="payload.teacher ? teachersBySession.filter(el => el.id !== payload.teacher.id) : teachersBySession"
                  :select-button-class="'custom-select-button'"
                  :clearable="false"
                  class="selectedTeachers session-substitute"
                  :selectable="el => el.available === 'available'"
                  :loading="!hasLoadedTeachers"
                  @option:deselected="handleDeselect"
                >
                  <template #option="{ avatar, name, available }">
                    <div class="d-flex justify-content-between">
                      <div>
                        <b-avatar
                          size="sm"
                          :src="avatar"
                        />
                        <span class="d-inline ml-50 align-middle">{{ name }}</span>
                      </div>
                      <small>
                        <b-badge
                          pill
                          :variant="`light-${available === 'available' ? 'success' : 'danger'}`"
                          class="text-capitalize"
                        >
                          {{ available === 'available' ? 'Available' : 'Unavailable' }}
                        </b-badge>
                      </small>
                    </div>
                  </template>
                  <template #selected-option="{ code, by, status }">
                    <div v-if="status !== 'Approved'" class="flex">
                      <feather-icon
                        :icon="by === 'teacher' ? 'StarIcon' : 'ClockIcon'"
                        size="14"
                        :class="by === 'teacher' ? 'text-indigo' : 'text-warning'"
                        class="align-middle mr-50"
                      />
                      <span :class="by === 'teacher' ? 'pending-by-teacher' : 'pending-by-admin'" class="mt-25">{{ code }}</span>
                    </div>
                    <div v-if="status === 'Approved'" class="flex">
                      <feather-icon
                        icon="CheckCircleIcon"
                        size="16"
                        class="align-middle mr-50 text-success"
                      />
                      <span class="text-success">{{ code }}</span>
                    </div>
                  </template>
                </v-select>
                  <!-- <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon ml-50"
                    @click="$emit('update-substitute', payload.id, payload.substitutes)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button> -->
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Classroom -->
            <validation-provider
              #default="validationContext"
              name="Classroom"
            >
              <b-form-group
                label="Update Classroom"
                label-for="add-guests"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="payload.classroom"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :close-on-select="true"
                  :options="classroomsBySession"
                  label="code"
                  :selectable="el => el.available === 'available'"
                  :loading="!hasLoadedClassrooms"
                >
                  <span slot="no-options">
                    <div
                      v-if="classroomsBySession.length === 0"
                      class="text-center d-flex align-items-center justify-content-center"
                    >
                      No matching records found
                    </div>
                  </span>
                  <template #option="{ code, available, max_students }">
                    <div class="row">
                      <strong class="col-3 d-block"> {{ code }}</strong>
                      <small class="col-6 text-secondary">Students limit: <strong>{{ max_students }}</strong></small>
                      <small class="col-3 d-flex justify-content-end">
                        <b-badge
                          pill
                          :variant="`light-${available === 'available' ? 'success' : 'danger'}`"
                          class="text-capitalize"
                        >
                          {{ available === 'available' ? 'Available' : 'Unavailable' }}
                        </b-badge>
                      </small>
                      <!-- <small class="ml-50" :class="available ? 'text-success' : 'text-danger'">{{ available ? 'Available' : 'Unavailable' }}</small> -->
                    </div>
                  </template>
                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Form Actions -->
            <div class="d-flex justify-content-end mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
                :disabled="!payload.date"
              >
                {{ 'Update' }}
              </b-button>
              <!-- <b-button
                v-if="payload.id"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="secondary"
                @click="$emit('show-attendance-modal')"
              >
                List attendance
              </b-button> -->
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
    <b-modal
      id="cancelSubstituteRequestModal"
      title="Confirm Cancellation"
      centered
      no-close-on-backdrop
      ok-title="Yes"
      hide-body
      cancel-title="No"
      @ok="cancelRequest"
    >
      <div class="d-flex align-items-center justify-content-start">
        <feather-icon class="text-warning mr-50" icon="AlertTriangleIcon" size="16" />
        <strong>Do you want to cancel this substitute teacher?</strong>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BSpinner,
  BAvatar, BModal, BCardText, BFormDatepicker, BBadge,
  // BFormTextarea,
  BButton, BFormInvalidFeedback, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, watch } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BModal,
    BCardText,
    BFormDatepicker,
    BSpinner,
    BBadge,
    // BFormCheckbox,
    // BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isSessionHandlerSidebarActive',
    event: 'update:is-session-handler-sidebar-active',
  },
  props: {
    isSessionHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    sessionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      avatarText,
      required,
      email,
      url,
      sessionData: null,
      loadingData: false,
      teachersBySession: [],
      classroomsBySession: [],
      hasLoadedTeachers: false,
      hasLoadedClassrooms: false,
      isRemoving: false,
    }
  },
  created() {
    /* eslint-disable camelcase */
    this.loadingData = true
    this.getTeachersBySession([this.sessionId])
    this.getClassroomsBySession(this.sessionId)
    this.$http.get('session/get', { params: { session_id: this.sessionId } })
    .then(res => {
      this.sessionData = res.data.session
      this.payload = { ...this.sessionData }
      this.payload.substitutes = this.payload.substitutes && this.payload.substitutes.length > 0 ? this.payload.substitutes.filter(el => el.status !== 'Canceled') : []
      this.payload.date = this.payload.session_date ? `${this.payload.session_date.split('-')[2]}-${this.payload.session_date.split('-')[1]}-${this.payload.session_date.split('-')[0]}` : null
      this.payload.course = `${this.payload.code} (${this.payload.group})`
      if (!this.payload.homework) this.payload.homework = { link: null }
      this.loadingData = false
    }).catch(error => {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: error.response.data.errors,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  },
  methods: {
    // eslint-disable-next-line consistent-return
    dateInfoFn(date) {
      const highlightedDates = this.payload.days ? this.payload.days.split(',') : []
      const weekdays = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa']
      const dayOfWeek = weekdays[new Date(date).getDay()]
      console.log(dayOfWeek)
      console.log(!highlightedDates.includes(dayOfWeek))
      if (!highlightedDates.includes(dayOfWeek)) {
        return ['disabled-date-picker-dates']
      }
    },
    handleDeselect(deselectedOption) {
      if (deselectedOption.status === 'Approved') {
        this.payload.substitutes.unshift(deselectedOption)
        this.$bvModal.show('cancelSubstituteRequestModal')
      }
    },
    getTeachersBySession(sessions) {
      store.dispatch('coursesStoreModule/getTeachersBySession', { sessions })
      .then(res => {
        console.log(res.data)
        this.hasLoadedTeachers = true
        this.teachersBySession = res.data.allTeachers
      })
    },
    getClassroomsBySession(session_id) {
      store.dispatch('coursesStoreModule/getClassroomsBySession', { session_id })
      .then(res => {
        this.hasLoadedClassrooms = true
        this.classroomsBySession = res.data.allClassrooms
      })
    },
  },
  setup(props, { emit }) {
    /* eslint-disable camelcase, consistent-return, no-return-assign */
    const toast = useToast()
    const shallPostponeCourses = ref(false)
    const payload = ref({})
    const teachersList = ref([])
    // const classroomsList = ref([])
    const startErros = ref([])
    const classrooms = ref([])
    watch(startErros, () => {
      shallPostponeCourses.value = startErros.value.length > 1
    })
    // const fetchClassrooms = course_id => {
    // store.dispatch('coursesStoreModule/fetchClassrooms', { course_id })
    //   .then(() => { classrooms.value = store.state.coursesStoreModule.classrooms })
    // }
    // if (props.sessionData) fetchClassrooms(props.sessionData.course_id)

    const cancelRequest = () => {
      console.log(payload.value.substitutes)
      const substitute = payload.value.substitutes.find(el => el.status === 'Approved')
      store.dispatch('ApprovalStoreModule/cancelRequest', { session_id: props.sessionId, teacher_id: substitute.id })
      .then(res => {
        payload.value.substitutes = payload.value.substitutes.filter(el => el.id !== substitute.id)
        emit('refetch-data')
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }

    // eslint-disable-next-line func-names
    const onSubmit = function (postpone) {
      const data = {
        session_id: payload.value.id,
        homework: payload.value.homework,
        teacher_id: payload.value.teacher ? payload.value.teacher.id : null,
        substitutes: payload.value.substitutes.map(el => el.id),
        classroom_id: payload.value.classroom ? payload.value.classroom.id : null,
        start: `${payload.value.date} ${payload.value.start_time}:00`,
        end: `${payload.value.date} ${payload.value.end_time}:00`,
        postpone: !!postpone,
        collides: startErros.value && startErros.value.length > 1 && startErros.value[1] === 'collides',
      }
      Object.keys(data).filter(key => data[key] === null).forEach(key => delete data[key])
      store.dispatch('ApprovalStoreModule/updateCoursesSession', data).then(res => {
        emit('update:is-session-handler-sidebar-active', false)
        emit('refetch-data')
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(error => {
        if (error.response.data.errors !== undefined) {
          return startErros.value = error.response.data.errors.start
        }
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {})

    return {
      cancelRequest,
      classrooms,
      // fetchClassrooms,
      startErros,
      payload,
      onSubmit,
      teachersList,
      // classroomsList,
      shallPostponeCourses,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
// .session-substitute .vs__selected button {
//   display: none;
// }
</style>
