import {
  ref, watch, computed, onMounted,
} from '@vue/composition-api'
// import { title } from '@core/utils/filter'
// Notification

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'

export default function useCoursesList() {
  // Use toast
  const toast = useToast()
  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = ref([
    { key: 'lesson_number' },
    { key: 'course_name', sortable: true },
    { key: 'teachers' },
    { key: 'date', label: 'date & time', sortable: true },
    { key: 'classroom_name' },
    { key: 'actions', tdClass: 'table-actions' },
  ])
  const tableColumns1 = [
    { key: 'nearest_date', label: 'lesson number', sortable: true },
    { key: 'course_name', sortable: true },
    { key: 'teacher' },
    { key: 'substitutes' },
    // { key: 'date', label: 'date & time', sortable: true },
    { key: 'classroom_name' },
    { key: 'actions', tdClass: 'table-actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const searchQuery1 = ref('')
  const searchQuery2 = ref('')
  const sortBy = ref('nearest_date')
  const isSortDirDesc = ref(false)
  const userData = ref(JSON.parse(localStorage.userData))
  const studentId = ref(userData.id)
  /* eslint-disable-next-line */
  const role_name = ref(window.location.href.split('/')[3])
  const teachers = ref([])
  const approvedTeacher = ref(null)
  const selectedTeachers = ref([])
  const selectedDay = ref({})
  const selectedTime = ref({})
  const selectedFilter = ref('requests')
  if (router.currentRoute.params.value) {
    selectedFilter.value = router.currentRoute.params
  }
  const reason = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })
  const refetchData = () => {
    refUserListTable.value.refresh()
  }
  /* eslint-disable camelcase, no-trailing-spaces, no-shadow, object-curly-newline */ 
  const apply = (action, teacher_id, item, reason) => {
    if (item.approval_type === 'session') {
      const sessionPayload = { teacher_id, session_id: item.session_id, reason }
      store.dispatch('course/approveSession', sessionPayload)
      .then(res => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    } else {
      const coursePayload = { teacher_id, course_id: item.id, reason }
      store.dispatch(`course/${action}Course`, coursePayload)
      .then(res => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
  }
  const deny = (id, action, reason) => {
    const Action = action === 'course' ? 'course/denyCourse' : 'course/denySession'
    store.dispatch(Action, { id, reason })
      .then(res => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(err => {
          toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
      })
  }
  /* eslint-disable-next-line */
  const urlParams = ref(new URLSearchParams())
  if (router.currentRoute.query.tab) selectedFilter.value = router.currentRoute.query.tab
  if (router.currentRoute.query.search) searchQuery.value = router.currentRoute.query.search
  if (router.currentRoute.query.search1) searchQuery1.value = router.currentRoute.query.search1
  if (router.currentRoute.query.search2) searchQuery2.value = router.currentRoute.query.search2
  if (router.currentRoute.query.page) currentPage.value = router.currentRoute.query.page
  if (router.currentRoute.query.perPage) perPage.value = router.currentRoute.query.perPage
  watch([currentPage, perPage, selectedFilter], () => {
    urlParams.value.set('?tab', selectedFilter.value ? selectedFilter.value : '')
    urlParams.value.set('page', currentPage.value ? currentPage.value : '')
    urlParams.value.set('perPage', perPage.value ? perPage.value : '')
    window.history.replaceState(null, null, decodeURIComponent(urlParams.value))
    refetchData()
  })
  watch([searchQuery, searchQuery1, searchQuery2], () => {
    urlParams.value.set('?tab', selectedFilter.value ? selectedFilter.value : '')
    urlParams.value.set('search', searchQuery.value ? searchQuery.value : '')
    urlParams.value.set('search1', searchQuery1.value ? searchQuery1.value : '')
    urlParams.value.set('search2', searchQuery2.value ? searchQuery2.value : '')
    window.history.replaceState(null, null, decodeURIComponent(urlParams.value))
    refetchData()
  })
  const isBusy = ref(false)

  const fetchUsers = (ctx, callback) => {
    const payload = {
      /* eslint-disable no-nested-ternary */
      search: selectedFilter.value === 'requests' ? searchQuery.value : selectedFilter.value === 'needs_teacher' ? searchQuery1.value : searchQuery2.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      teacher_id: userData.value.id,
      filter: selectedFilter.value,
      // role_name: role_name.value,
    }
    store
      .dispatch('course/fetchCourses', payload)
      .then(res => {
        const users = res.data.rows
        const total = res.data.totalRecords
        callback(users)
        totalUsers.value = total
        currentUser.value = JSON.parse(localStorage.getItem('userData'))
      })
      .catch(() => {
        isBusy.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching approval list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const isBigModalActive = ref(false)
  const UsersAlreadyAdded = ref([])
  const spinner = ref(false)
  const currentCourseData = ref(null)
  // const cities = ref([])
  const masters = ref([])
  const teachersModal = ref([])
  const classrooms = ref([])
  const currentUser = ref({})
  const ModalData = (id, tab) => {
    store.dispatch('course/getSCourse', id)
      .then(res => {
        currentCourseData.value = res.data.data
        currentCourseData.value.pendingTeachers = res.data.pendingTeachers
        currentCourseData.value.currentTeacher = res.data.currentTeacher
        currentCourseData.value.tab = tab
        currentCourseData.value.days = currentCourseData.value.days ? currentCourseData.value.days.split(',') : []
        isBigModalActive.value = true
      }).catch(error => console.log(error))
  }
  // const fetchCities = () => {
  //   store.dispatch('course/fetchCities')
  //     .then(res => {
  //       cities.value = res.data.rows
  //     })
  // }
  const fetchMCourses = () => {
    store.dispatch('course/fetchMCourses')
      .then(res => {
        masters.value = res.data.rows
      })
  }
  const fetchTeachers = () => {
    store.dispatch('course/fetchTeachers')
      .then(res => {
        teachersModal.value = res.data.rows
      })
  }
  const fetchClassrooms = () => {
    store.dispatch('course/fetchClassrooms')
      .then(res => {
        classrooms.value = res.data.rows
      })
  }
  /* eslint-disable no-param-reassign, no-param-reassign */
  const suggestClassrooms = (record, Days) => {
    record.days = Days.join(',')
    store.dispatch('course/suggestClassrooms', record)
      .then(res => {
        classrooms.value = res.data.available
      })
  }
  const suggestTeacher = (record, Days) => {
    record.days = Days.join(',')
    store.dispatch('course/suggestTeacher', record)
      .then(res => {
        teachers.value = res.data.available
      })
  }
  // call on mounted
  onMounted(fetchMCourses)
  onMounted(fetchTeachers)
  // onMounted(fetchClassrooms)
  // onMounted(fetchCities)

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'Pending') return 'warning'
    if (status === 'Active') return 'success'
    if (status === 'Deleted') return 'danger'
    return 'primary'
  }
  const resolveAttendanceVariant = attendance => {
    if (attendance > 0) return 'flat-warning'
    if (attendance > 80) return 'flat-success'
    return 'flat-secondary'
  }

  return {
    approvedTeacher,
    teachers,
    selectedTeachers,
    selectedDay,
    selectedTime,
    fetchUsers,
    tableColumns,
    tableColumns1,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    searchQuery1,
    searchQuery2,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    role_name,
    studentId,
    userData,
    selectedFilter,

    // Course modal
    // fetchCities,
    fetchClassrooms,
    fetchTeachers,
    fetchMCourses,
    suggestClassrooms,
    suggestTeacher,
    isBigModalActive,
    UsersAlreadyAdded,
    spinner,
    currentCourseData,
    classrooms,
    teachersModal,
    masters,
    // cities,
    currentUser,
    ModalData,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveAttendanceVariant,
    refetchData,
    apply,
    reason,
    deny,
  }
}
