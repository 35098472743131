<template>
  <b-card
    no-body
    class="card-revenue-budget"
  >
    <b-row class="mx-0">
      <b-col
        md="8"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">
            {{ $t('Working hours') }}
          </h4>
        </div>

        <!-- chart -->
        <vue-apex-charts
          id="revenue-report-chart"
          :key="refreshKey"
          type="bar"
          height="230"
          :options="revenueReport.chartOptions"
          :series="revenueReport.series"
        />
      </b-col>

      <b-col
        md="4"
        class="budget-wrapper"
      >
        <b-dropdown
          :text="lastYear.toString()"
          size="sm"
          class="budget-dropdown"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="year in years"
            :key="year"
            @click="getYearReport(year)"
          >
            {{ year }}
          </b-dropdown-item>
        </b-dropdown>

        <h2 class="mb-25">
          {{ revenue_report.hours }} <p>Hours in total</p>
        </h2>
        <vue-apex-charts
          id="budget-chart"
          :key="refreshKey"
          type="line"
          height="80"
          :options="budgetChart.options"
          :series="revenueReport.series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      refreshKey: 0,
      years: [2022, 2021, 2020, 2019, 2018],
      lastYear: 2022,
      revenue_report: {},
      revenueReport: {
        series: [
          {
            name: 'Working hours',
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            stacked: true,
            type: 'bar',
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.86rem',
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.primary, $themeColors.warning],
          plotOptions: {
            bar: {
              columnWidth: '17%',
              endingShape: 'rounded',
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.86rem',
              },
            },
          },
        },
      },

      // budget chart
      budgetChart: {
        series: [
          {
            data: [],
          },
        ],
        options: {
          chart: {
            height: 80,
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            sparkline: { enabled: true },
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 5],
            width: [2],
          },
          colors: [$themeColors.primary, '#dcdae3'],
          tooltip: {
            enabled: false,
          },
        },
      },
    }
  },
  created() {
    this.getYearReport(new Date().getFullYear())
  },
  methods: {
    /* eslint-disable prefer-destructuring */
    getYearReport(year) {
      this.lastYear = year
      this.$http.post('/teacher/dashboard/hours-worked', { year: this.lastYear }).then(res => {
      this.years = res.data.years
      this.revenueReport.series[0].data = res.data.monthlyReport
      if (res.data.totalByYear.length !== 0) {
        this.revenue_report = res.data.totalByYear[0]
      }
      this.refreshKey += 1
      })
    },
  },
}
</script>
