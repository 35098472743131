var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"generalData"},[_c('b-form',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":"Role","vid":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"Role","label-for":"role"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"label","clearable":false,"disabled":_vm.$store.state.appConfig.hasOtherAccount || _vm.hasOtherAccount,"options":['teacher', 'student']},on:{"input":function($event){return _vm.getUsers('', _vm.localOptions.role)}},model:{value:(_vm.localOptions.role),callback:function ($$v) {_vm.$set(_vm.localOptions, "role", $$v)},expression:"localOptions.role"}})],1)]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":"Account","vid":"account","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"Associated account","label-for":"user-associated"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","clearable":false,"filterable":false,"options":_vm.usersList,"disabled":_vm.$store.state.appConfig.hasOtherAccount || _vm.hasOtherAccount},nativeOn:{"keyup":function($event){return _vm.getUsers($event.target.value, _vm.localOptions.role)},"click":function($event){return _vm.getUsers('', _vm.localOptions.role)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var email = ref.email;
return [_c('span',[_vm._v(" "+_vm._s(name))]),_c('small',{staticClass:"text-secondary d-block"},[_vm._v(_vm._s(email))])]}}],null,true),model:{value:(_vm.localOptions.user),callback:function ($$v) {_vm.$set(_vm.localOptions, "user", $$v)},expression:"localOptions.user"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[(_vm.isTypingUser)?_c('div',{staticClass:"text-center d-flex align-items-center justify-content-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Text Centered"}})],1):_vm._e(),(!_vm.isTypingUser && !_vm.usersList.length)?_c('div',{staticClass:"text-center d-flex align-items-center justify-content-center"},[_vm._v(" No matching records found ")]):_vm._e()])])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}}),_c('b-col',{attrs:{"cols":"12"}},[(!_vm.localOptions.id)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"primary","disabled":_vm.isBusy || (_vm.localOptions.user && !_vm.localOptions.user.id)},on:{"click":function($event){$event.preventDefault();return _vm.save(_vm.localOptions.user.id)}}},[(_vm.isBusy)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Associate account ")],1):_vm._e(),(_vm.localOptions.id)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"primary","disabled":_vm.isBusy},on:{"click":function($event){return _vm.remove(_vm.localOptions.id)}}},[(_vm.isBusy)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Dessociate account ")],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }