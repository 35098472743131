import axios from '@axios'

export default {
  namespaced: true,
  state: {
    classrooms: {
      rows: [],
      totalRecords: 0,
    },
    sessions: {
      rows: [],
      totalRecords: 0,
    },
  },
  getters: {},
  /* eslint-disable camelcase */
  mutations: {
    setClassrooms(state, users) {
      state.classrooms = users
    },
    setSessionData(state, res) {
      state.sessions = res
    },
  },
  actions: {
    fetchCourses(store, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/course/awaiting_approval', { params: queryParams })
          .then(response => {
            store.commit('setClassrooms', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    approveCourse(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/course/approve', { teacher_id: payload.teacher_id, course_id: payload.course_id, reason: payload.reason })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    approveSession(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/teacher/substitute/approve', { teacher_id: payload.teacher_id, session_id: payload.session_id, reason: payload.reason })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    denyCourse(store, { id, reason }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/requests/decline', { course_id: id, reason })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    denySession(store, { id, reason }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/teacher/substitute/decline', { session_id: id, reason })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    assignCourse(store, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/course/assign', { teachers: payload.teacher_id, course_id: payload.course_id })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    assignSubtitution(store, { session_id, teachers }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/teacher/substitute/assign', { session_id, teachers })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    assignSubtitutionMulti(store, { action, session_id, teachers }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/teacher/substitute/multiSelect', { action, session_id, teachers })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    cancelRequest(store, { session_id, teacher_id }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/teacher/substitute/cancel', { session_id, teacher_id })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    deleteSession(store, session_id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/session/${session_id}/delete`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getSCourse(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/courses/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    async fetchTeachers() {
      return new Promise((resolve, reject) => {
        axios
          .get('/teacher/list')
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async fetchClassrooms() {
      return new Promise((resolve, reject) => {
        axios
          .get('/classroom-list/data')
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async fetchMCourses(store, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/master-course-list/data?perPage=100', { params })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchCities() {
      return new Promise((resolve, reject) => {
        axios
          .get('/city-list/data')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSubstitute(store, subData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/teacher/substitute/add', subData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getSessionData(store, { course_id, page }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/course/substution/sessions/list', { params: { course_id, page, perPage: '100' } })
          .then(response => {
            // commit('setSessionData', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getAllSessionData(store, { course_id, page }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/course/get/sessions', { params: { course_id, page, perPage: '100' } })
          .then(response => {
            // commit('setSessionData', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchSubs(store, course_id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/teacher/suggest/by-course', { params: course_id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getSessionTeachers(store, session_id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/teacher/substitute/find-by-session', session_id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCoursesSession(store, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/session/${data.session_id}/update`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
