import axios from '@axios'

export default {
  namespaced: true,
  state: {
    invoices: {
      rows: [],
      totalRecords: 0,
    },
  },
  getters: {},
  /* eslint-disable camelcase */
  mutations: {
    setInvoices(state, users) {
      state.invoices = users
    },
    deleteInvoice(state, userId) {
      const regData = {
        rows: state.invoices.rows.filter(reg => reg.id !== userId),
        totalRecords: state.invoices.totalRecords - 1,
      }
      state.invoices = regData
    },
  },
  actions: {
    fetchUsers(store, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/invoice-list/data', { params: queryParams })
          .then(response => {
            store.commit('setInvoices', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/get-user/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addInvoice(ctx, invoiceData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/invoices', invoiceData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put('/user/update', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteInvoice(store, invId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/invoices/${invId}`)
          .then(response => {
            store.commit('deleteInvoice', invId)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    editInvoice(store, { invId, school_comments }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/invoices/${invId}`, { school_comments })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    confirmRegistration(store, regId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/invoices/confirm-reg/${regId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelRegistration(store, cancelId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/invoices/cancel-reg/${cancelId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    generateCertificate(store, { genId, template_id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/invoices/generate-certificate/${genId}`, { template_id }, { responseType: 'blob' })
          .then(response => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
            window.open(fileURL, '_blank')
            // const fileURL = window.URL.createObjectURL(new Blob([response.data]))
            // const fileLink = document.createElement('a')

            // fileLink.href = fileURL
            // fileLink.setAttribute('download', 'file.pdf')
            // document.body.appendChild(fileLink)

            // fileLink.click()
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    printInvoice(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/invoice/print/${id}`, {}, { responseType: 'blob' })
          .then(response => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
            window.open(fileURL, '_blank')
            // const fileURL = window.URL.createObjectURL(new Blob([response.data]))
            // const fileLink = document.createElement('a')

            // fileLink.href = fileURL
            // fileLink.setAttribute('download', 'file.pdf')
            // document.body.appendChild(fileLink)

            // fileLink.click()
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    moveStudent(store, { studentId, course_id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/invoices/move-student/${studentId}`, { course_id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendPTest(store, pId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/invoices/send-p-test/${pId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    regToWaitingList(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/invoices/waiting-list/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    autoComplete(store, search) {
      return new Promise((resolve, reject) => {
        axios
          .post('/templates/autocomplete', search)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    autoCompleteCourses(store, search) {
      return new Promise((resolve, reject) => {
        axios
          .post('/autocomplete/course', search)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
