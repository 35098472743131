<template>
  <div>
    <b-table
      responsive="sm"
      :items="items"
      :fields="tableColumns"
      show-empty
      empty-text="No matching records found"
      :busy.sync="isBusy"
    >
      <template #table-busy>
        <div
          v-if="isBusy"
          class="text-center d-flex align-items-center justify-content-center"
        >
          <b-spinner
            variant="primary"
            label="Text Centered"
          />
          <strong class="ml-1">Loading...</strong>
        </div>
      </template>
      <template #cell(course_date)="data">
        {{ data.item.course_date }}
      </template>
      <template #cell(timetable)="data">
        {{ data.item.time_from }} - {{ data.item.time_to }}
      </template>
      <template #cell(present)="data">
        <div class="d-flex align-items-center justify-content-start">
          <!-- Levels checkbox -->
          <b-form-group>
            <div class="demo-inline-spacing">
              <b-form-checkbox
                v-model="data.item.present"
                value="0"
                :disabled="userData.role === 'student'"
                class="custom-control-primary d-inline ml-25"
              >
                {{ 0 }}
              </b-form-checkbox>
              <b-form-checkbox
                v-for="index in data.item.max_hours"
                :key="index"
                v-model="data.item.present"
                :value="index"
                class="custom-control-primary d-inline ml-25"
                :disabled="userData.role === 'student'"
              >
                {{ index }}h
              </b-form-checkbox>
            </div>
          </b-form-group>
        </div>
      </template>
      <template #cell(claim)="data">
        <span v-if="data.item.claim_status">{{ data.item.claim_hours ? data.item.claim_hours + 'h' : '0h' }}</span>
        <b-button
          v-if="userData.role === 'administrator' && data.item.claim_status === 'pending'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mr-1 ml-1"
          size="sm"
          @click="approveClaim(data.item.attendance_id)"
        >
          Approve
        </b-button>
        <b-button
          v-if="userData.role === 'administrator' && data.item.claim_status === 'pending'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          size="sm"
          @click="rejectClaim(data.item.attendance_id)"
        >
          Reject {{ role }}
        </b-button>
        <b-button
          v-if="userData.role === 'student' && !data.item.claim_status"
          v-b-modal="getModalId(data.item.attendance_id)"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :variant="data.item.claim_status === 'pending' ? 'warning' : 'primary'"
          size="sm"
          class="ml-1"
          @click="attendanceHours = data.item.present; claimMessage = null"
        >
          Claim
        </b-button>
        <b-button
          v-if="data.item.claim_status === 'approved'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-success"
          size="sm"
          disabled
        >
          Approved
        </b-button>
        <b-button
          v-if="data.item.claim_status === 'pending'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-warning"
          size="sm"
          disabled
        >
          Pending
        </b-button>
        <b-button
          v-if="data.item.claim_status === 'rejected'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-danger"
          size="sm"
          disabled
        >
          Rejected
        </b-button>
        <b-modal
          :id="'claim'+data.item.attendance_id"
          title="Claim attendance"
          ok-only
          centered
          no-close-on-backdrop
          ok-title="send"
          hide-footer
          size="lg"
        >
          <validation-observer
            #default="{ handleSubmit }"
            ref="claimForm"
          >
            <!-- Form -->
            <b-form
              class="p-2"
              @submit.prevent="handleSubmit(claim(data.item.attendance_id, attendanceHours, claimMessage))"
              @reset.prevent="resetForm"
            >
              <validation-provider
                #default="validationContext"
                name="Attendance"
                rules="required"
              >
                <b-form-group
                  :label="$t('Attendance')"
                  label-for="attendance"
                  :state="getValidationState(validationContext)"
                >
                  <div class="d-flex">
                    <b-form-checkbox
                      v-for="index in data.item.max_hours"
                      :key="index"
                      v-model="attendanceHours"
                      :value="index"
                      class="custom-control-primary mr-1"
                      :disabled="index === data.item.present"
                    >
                      {{ index }}h
                    </b-form-checkbox>
                  </div>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="validationContext"
                name="Details"
                rules="required"
              >
                <b-form-group
                  :label="$t('Details')"
                  label-for="details"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-textarea
                    v-model="claimMessage"
                    placeholder="Details"
                    rows="3"
                    maxlength="500"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
                :disabled="attendanceHours === data.item.present"
              >
                {{ $t('Claim') }}
              </b-button>
            </b-form>
          </validation-observer>
        </b-modal>
      </template>
    </b-table>
    <hr v-if="userData.role === 'administrator'">
    <div
      v-if="userData.role === 'administrator'"
      class="d-flex justify-content-end"
    >
      <b-button
        variant="secondary"
        @click="$emit('close-attendance-modal')"
      >
        {{ 'Close' }}
      </b-button>
      <b-button
        variant="primary"
        class="ml-1"
        @click="update"
      >
        {{ 'Update attendance' }}
      </b-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline, camelcase */
import { BTable, BFormGroup, BFormCheckbox, BButton, BModal, BForm, BFormTextarea, BFormInvalidFeedback, BSpinner } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BTable,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BModal,
    BForm,
    BFormTextarea,
    BFormInvalidFeedback,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    courseId: {
      default: null,
      type: Number,
    },
    studentId: {
      default: null,
      type: Number,
    },
    role: {
      type: String,
      default: 'student',
    },
  },
  data() {
    return {
      isBusy: false,
      required,
      claimMessage: null,
      attendanceHours: null,
      tableColumns: [
        { key: 'course_id' },
        { key: 'course_group' },
        { key: 'course_code' },
        { key: 'course_date' },
        { key: 'timetable' },
        { key: 'present' },
        { key: 'claim' },
      ],
      items: [],
      userData: JSON.parse(localStorage.userData),
    }
  },
  created() {
    this.fetchItems()
  },
  methods: {
    fetchItems() {
      this.isBusy = true
      this.$http.post('/student/attendance/hours', { course_id: this.courseId, student_id: this.studentId })
      .then(res => {
        this.isBusy = false
        this.items = res.data
      })
    },
    approveClaim(attendance_id) {
      this.$store.dispatch('claimStoreModule/approveClaim', { attendance_id })
        .then(res => {
          this.fetchItems()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    rejectClaim(attendance_id) {
      this.$store.dispatch('claimStoreModule/rejectClaim', { attendance_id })
        .then(res => {
          this.fetchItems()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getModalId(id) {
      return `claim${id}`
    },
    update() {
      this.$http.post('/attendance/student/update', { attendances: this.items }).then(res => {
        this.fetchItems()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: err.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
  setup() {
    const toast = useToast()
    const claim = function (attendance_id, claim_hours, claim_message) {
      if (this.$refs.claimForm.flags.valid) {
        this.$http.post('/attendance/claim', { attendance_id, claim_hours, claim_message })
        .then(res => {
          this.fetchItems()
          this.claimMessage = null
          this.attendanceHours = null
          this.$bvModal.hide(`claim${attendance_id}`)
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        }).catch(err => {
          toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      }
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation({})
    return {
      refFormObserver,
      getValidationState,
      resetForm,
      claim,
    }
  },
}
</script>
