<template>
  <!-- Items Section -->
  <b-card-body class="invoice-padding form-item-section p-0">
    <div
      ref="form"
      class="repeater-form"
      :style="{height: trHeight}"
    >
      <b-row
        v-for="(item, index) in items"
        :key="index"
        ref="row"
      >
        <b-col>
          <b-form-group>
            <flat-pickr
              v-model="item.time_from"
              placeholder="From"
              class="form-control"
              :config="{ enableTime: true, noCalendar: true, time_24hr: true, minTime: '07:00' }"
              @input="getTimeFrames()"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <flat-pickr
              v-model="item.time_to"
              placeholder="To"
              class="form-control"
              :config="{ enableTime: true, noCalendar: true, time_24hr: true, minTime: item.time_from }"
              @input="getTimeFrames()"
            />
            <small
              v-if="item.time_from && item.time_from === item.time_to"
              class="text-danger"
            >Time must be greater than start time</small>
          </b-form-group>
        </b-col>
        <b-col
          lg="2"
          md="3"
        >
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="flat-danger"
            class="btn-icon"
            :disabled="items.length === 1"
            @click="removeItem(index)"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
          </b-button>
        </b-col>
      </b-row>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      size="sm"
      variant="primary"
      @click="repeateAgain"
    >
      Add time frame
    </b-button>
  </b-card-body>
</template>
<script>
import {
  BRow, BCol, BCardBody, BButton, BFormGroup,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow, BCol, BCardBody, BButton, BFormGroup, flatPickr,
  },
  mixins: [heightTransition],
  props: {
    itemsProp: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      items: [{
        id: 1,
        time_from: '',
        time_to: '',
      }],
      nextTodoId: 2,
    }
  },
  mounted() {
    /* eslint-disable no-return-assign */
    if (this.itemsProp.length > 0) {
      console.log()
      this.items = this.itemsProp.map(el => {
        return {
          id: this.nextTodoId += this.nextTodoId,
          time_from: el.start,
          time_to: el.end,
        }
      })
    }
    setTimeout(() => {
      this.initTrHeight()
    }, 200)
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    getTimeFrames() {
      const timeFrames = this.items.map(el => { return { start: el.time_from, end: el.time_to, days: 'all' } })
      this.$emit('bind-time-frames', timeFrames)
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>
