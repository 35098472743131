<template>
  <div>
    <div
      v-for="(option, index) in content.options"
      :key="index"
    >
      <b-form-radio
        :id="`option${index}`"
        name="contact"
        :value="option"
        @change="onChangeHandler"
      >
        <label :for="`option${index}`">{{ option }}</label>
      </b-form-radio>
    </div>
  </div>
</template>
<script>
import { BFormRadio } from 'bootstrap-vue'

export default {
    name: 'RadioComponent',
    components: {
      BFormRadio,
    },
    props: {
        content: Object,
        idx: Number,
    },
    methods: {
      onChangeHandler(event) {
        this.$emit('responseevent', { id: this.$props.idx, response: event })
      },
    },
}
</script>
