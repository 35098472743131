<template>
  <b-row>
    <b-col cols="6">
      <v-select
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :value="timeOfDayFilter"
        :options="dayTimeOptions"
        :reduce="val => val.value"
        :clearable="false"
        @input="(val) => $emit('update:timeOfDayFilter', val)"
      />
    </b-col>
    <b-col cols="6">
      <v-select
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :value="classroomType"
        :options="classroomOptions"
        :reduce="val => val.value"
        :clearable="false"
        @input="(val) => $emit('update:classroomType', val)"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    vSelect,
  },
  props: {
    timeOfDayFilter: {
      type: [String, null],
      default: null,
    },
    typeFilter: {
      type: [String, null],
      default: null,
    },
    monthFilter: {
      type: [String, null],
      default: null,
    },
    levelFilter: {
      type: [String, null],
      default: null,
    },
    dayTimeOptions: {
      type: Array,
      required: true,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
    levelOptions: {
      type: Array,
      required: true,
    },
    monthOptions: {
      type: Array,
      required: true,
    },
    classroomType: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      classroomOptions: [
        { label: 'All types', value: 'all' },
        { label: 'Classroom', value: 'classroom' },
        { label: 'Online', value: 'online' },
        // { label: 'Hybrid', value: 'hybrid' },
      ],
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
