import axios from '@axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'success',
        label: 'Available',
      },
      {
        color: 'danger',
        label: 'Unavailable',
      },
    ],
    selectedCalendars: ['Available', 'Unavailable'],
  },
  getters: {},
  mutations: {
    SET_EVENTS(state, response) {
      state.events = response
    },
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    /* eslint-disable-next-line */
    fetchEvents(store, { status, date_start, date_end, classroom_id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/classroom/calendar/data', {
            params: {
              // status: statuss,
              // calendars: calendars.join(','),
              date_start,
              date_end,
              classroom_id,
              // status: 'available',
            },
          })
          .then(response => {
            store.commit('SET_EVENTS', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getClassrooms() {
      return new Promise((resolve, reject) => {
        axios
          .get('/classroom-list/data')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/calendar/events/${event.id}`, { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
