<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Filters</b-card-title>
      <b-button
        variant="primary"
        @click="reset"
      >
        <span class="text-nowrap">{{ $t('Reset') }}</span>
      </b-button>
    </b-card-header>
    <b-card-body>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('Start date from') }}</label>
          <flat-pickr
            :value="dateStartFromFilter"
            class="form-control"
            :placeholder="$t('Pick a date')"
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss', altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 }}"
            @input="(val) => $emit('update:dateStartFromFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('Start date to') }}</label>
          <flat-pickr
            :value="dateStartToFilter"
            class="form-control"
            :placeholder="$t('Pick a date')"
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss', altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 }}"
            @input="(val) => $emit('update:dateStartToFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('End date from') }}</label>
          <flat-pickr
            :value="dateEndFromFilter"
            class="form-control"
            :placeholder="$t('Pick a date')"
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss', altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 }}"
            @input="(val) => $emit('update:dateEndFromFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('End date to') }}</label>
          <flat-pickr
            :value="dateEndToFilter"
            class="form-control"
            :placeholder="$t('Pick a date')"
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss', altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 }}"
            @input="(val) => $emit('update:dateEndToFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BCardTitle,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BCardTitle,
    flatPickr,
  },
  props: {
    roleFilter: {
      type: [String, null],
      default: null,
    },
    planFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    dateStartFromFilter: {
      type: [String, null],
      default: null,
    },
    dateStartToFilter: {
      type: [String, null],
      default: null,
    },
    dateEndFromFilter: {
      type: [String, null],
      default: null,
    },
    dateEndToFilter: {
      type: [String, null],
      default: null,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
  watch: {
    $route(to) {
      const role = to.name.split('-')[3]
      this.$nextTick(this.changeRole(role))
    },
  },
  created() {
    const role = this.$route.name.split('-')[3]
    this.$nextTick(this.changeRole(role))
  },
  methods: {
    changeRole(role) {
      this.$emit('update:roleFilter', role)
    },
    reset() {
      this.$emit('update:dateStartFromFilter', null)
      this.$emit('update:dateStartToFilter', null)
      this.$emit('update:dateEndFromFilter', null)
      this.$emit('update:dateEndToFilter', null)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
