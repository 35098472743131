<template>
  <!-- eslint-disable vue/singleline-html-element-content-newline -->
  <div class="chats">
    <b-link
      v-if="chatData.messages.current_page < chatData.messages.last_page || chatData.messages.current_page === undefined"
      class="font-weight-bold"
      @click="$emit('get-more-messages', chatData.messages.current_page + 1)"
    >
      <p
        v-if="!isDashboard && !isLoading && msgCount >= 5"
        class="text-center"
      >{{ $t('View Earlier Message') }}</p>
      <div
        v-if="isLoading"
        class="text-center mt-2 d-flex align-items-center justify-content-center"
      >
        <b-spinner
          variant="primary"
          label="Text Centered"
          style="width: 3rem; height: 3rem;"
        />
        <strong class="ml-1">Loading...</strong>
      </div>
    </b-link>
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog.slice().reverse()"
      :key="msgGrp.senderId+String(index)"
      class="chat"
      :class="{'chat-left': msgGrp.senderId !== profileUserId}"
    >
      <div
        v-if="msgGrp.messages.filter(msg => !msg.hide).length !== 0"
        class="chat-avatar"
      >
        <b-avatar
          v-b-tooltip.hover.top="msgGrp.name"
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="msgGrp.senderId !== profileUserId ? msgGrp.avatar : profileUserAvatar"
        />
      </div>
      <div class="chat-body">
        <div
          v-for="(msgData, i) in msgGrp.messages.slice().reverse()"
          :key="msgData.time+String(i)+refreshKey"
          class="chat-content"
          :class="msgData.hide ? 'd-none' : ''"
        >
          <div class="d-flex justify-content-between">
            <p v-html="msgData.msg"></p>
            <feather-icon @click="deleteMsg(msgData)" style="cursor: pointer" v-if="msgGrp.senderId === profileUserId" icon="XIcon" />
          </div>
          <small class="text-end">{{ msgData.time }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { filterTags } from '@core/utils/filter'
import {
  BAvatar, BLink, BSpinner, VBTooltip,
  } from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    BAvatar,
    BLink,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    fromChat: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDashboard: {
      type: Boolean,
      required: false,
    },
    profileUserData: {
      type: Object,
      default: null,
    },
    chatData: {
      type: Object,
      required: true,
    },
    profileUserAvatar: {
      type: String,
      required: true,
    },
    profileUserId: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const refreshKey = ref(0)
    const deleteMsg = msg => {
      /* eslint-disable no-param-reassign */
      msg.hide = true
      refreshKey.value += 1
      const { id } = msg
      store.dispatch('chatStoreModule/deleteMsg', { id })
    }
    const msgCount = ref(0)

    const formattedChatData = computed(() => {
      let chatLog = []
      if (props.chatData.messages.data) {
        chatLog = props.chatData.messages.data
        msgCount.value = chatLog.length
      }
      const conversation = {
        id: props.chatData.conversation.id,
        avatar: props.chatData.conversation.avatar,
      }
      const formattedChatLog = []
      let chatMessageSenderId = null
      let msgGroup = {
        sender: chatMessageSenderId,
        messages: [],
      }
      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.sender.id) {
          msgGroup.messages.push({
            msg: msg.message,
            time: msg.time,
            id: msg.id,
          })
        } else {
          chatMessageSenderId = msg.sender.id
          formattedChatLog.push(msgGroup)
          msgGroup = {
            senderId: msg.sender.id,
            avatar: msg.sender.avatar,
            name: msg.sender.name,
            messages: [{
              msg: msg.message,
              time: msg.time,
              id: msg.id,
            }],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })
      return {
        formattedChatLog,
        conversation,
        profileUserAvatar: props.profileUserAvatar,
      }
    })

    return {
      filterTags,
      formattedChatData,
      msgCount,
      refreshKey,
      deleteMsg,
      // onSent,
    }
  },
}
</script>

<style>

</style>
