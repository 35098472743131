<template>
  <div>
    <div v-if="userData.role === 'student'">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-form-rating
            v-if="!revIds.includes(userData.id)"
            v-model="review"
            no-border
            inline
            show-value
            size="lg"
            variant="warning"
            class="mb-1"
            :readonly="revIds.includes(userData.id) "
          />
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="First Name"
            >
              <b-form-textarea
                v-if="!revIds.includes(userData.id)"
                v-model="comment"
                :state="errors.length > 0 ? false:null"
                rows="2"
                placeholder="Add Comment"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            v-if="!revIds.includes(userData.id)"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="primary"
            type="submit"
            class="mb-2"
            :disabled="revIds.includes(userData.id)"
            @click.prevent="validationForm"
          >
            Post Comment
          </b-button>
        </b-form>
      </validation-observer>
    </div>
    <div v-if="reviews.totalRecords !== 0">
      <div
        v-for="(review,ind) in reviews.rows"
        :key="ind"
        class="d-flex align-items-start mb-1"
      >
        <b-avatar
          :src="review.avatar"
          size="34"
          class="mt-25 mr-75"
        />
        <div class="profile-user-info w-100">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <h6 class="mb-0">
                {{ review.student_name }}
              </h6>
              <small class="text-secondary ml-50">{{ review.date.split(' ')[0] }}</small>
              <b-form-rating
                id="rating-sm"
                v-model="review.rating"
                size="sm"
                inline
                no-border
                readonly
                class="d-block"
                variant="warning"
              />
            </div>
            <div
              v-if="userData.role !== 'teacher'"
              class="text-body"
            >
              <b-badge
                :variant="review.status === 'pending' ? 'light-warning' : 'light-success'"
                class="mr-1"
              >
                {{ review.status }}
              </b-badge>
              <feather-icon
                v-if="userData.role === 'administrator' && review.status === 'pending'"
                v-b-tooltip.hover.top="'Approve'"
                icon="CheckSquareIcon"
                size="18"
                class="mr-50"
                style="cursor: pointer;"
                @click="$emit('approve-review', review.id); $emit('refetch-reviews')"
              />
              <feather-icon
                v-if="review.student_id === userData.id || userData.role === 'administrator'"
                v-b-tooltip.hover.top="'Remove'"
                icon="TrashIcon"
                size="18"
                class="mr-50"
                style="cursor: pointer;"
                @click="$emit('remove-review', review.id); $emit('refetch-reviews')"
              />
              <!-- <span class="text-muted align-middle">{{ review.commentsLikes }}</span> -->
            </div>
          </div>
          <small>{{ review.comment }}</small>
        </div>
      </div>
      <br>
      <b-pagination
        v-model="currentPageReview"
        :total-rows="reviews.totalRecords"
        :per-page="10"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0 d-flex justify-content-end"
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
    <div
      v-else
      class="d-flex align-items-center justify-content-center p-2"
    >
      <h6>No reviews found</h6>
    </div>
  </div>
</template>
<script>
/* eslint-disable object-curly-newline */
import { BAvatar, BPagination, BFormRating, BFormGroup, BFormTextarea, BButton, BForm, BBadge, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { alphaDash } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BAvatar,
    BPagination,
    BFormRating,
    BFormGroup,
    BFormTextarea,
    BButton,
    BBadge,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    reviews: {
      type: Object,
      required: true,
    },
    courseId: {
      type: Number,
    },
    myReview: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      currentPageReview: 1,
      userData: JSON.parse(localStorage.userData),
      comment: null,
      review: 0,
      revIds: [],
      alphaDash,
    }
  },
  mounted() {
    if (this.reviews) {
      this.revIds = this.reviews.rows.map(rev => rev.student_id)
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$emit('add-review', this.courseId, this.review, this.comment)
        }
      })
    },
  },
}
</script>
