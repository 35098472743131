<template>

  <div>

    <invoice-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <!-- <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          />

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
                debounce="500"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>
        <!-- Column: User -->
        <template #cell(number)="data">
          {{ data.item.number }}
        </template>

        <!-- Column: Course -->
        <template #cell(course)="data">
          {{ data.item.course_name }}
        </template>
        <!-- Column: Course -->
        <template #cell(amount)="data">
          <span v-if="data.item.course_price" :class="data.item.payment_status === 'refunded' ? 'text-muted' : 'text-dark'">{{ data.item.course_price }} {{ $store.state.appConfig.currency }}</span>
        </template>

        <!-- Column: Role -->
        <!-- <template #cell(student)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.student_name }}</span>
          </div>
        </template> -->

        <!-- Column: Payment date -->
        <template #cell(date)="data">
          <div class="font-weight-bold d-block text-nowrap text-capitalize">
            {{ formatDateTime(data.item.sent_date) }}
          </div>
          <small>{{ formatDateTime(data.item.paid_date) }}</small>
        </template>

        <!-- Column: Payer -->
        <template #cell(payer)="data">
          <span v-if="data.item.payer">{{ data.item.payer.code }}</span>
        </template>

        <!-- Column: who pays -->
        <template #cell(paid)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.payment_status)}`"
            class="text-capitalize"
          >
            {{ data.item.payment_status }}
          </b-badge>
          <!-- <b-avatar
            v-b-tooltip.hover.top="data.item.paid ? 'paid' : 'not paid'"
            size="32"
            :variant="data.item.paid ? 'light-success' : 'light-danger'"
          >
            <feather-icon
              :icon="data.item.paid ? 'CheckCircleIcon' : 'XCircleIcon'"
            />
          </b-avatar> -->
        </template>

        <!-- Column: who pays -->
        <template #cell(method)="data">
          {{ data.item.paymentMethod }}
        </template>

        <template #cell(payer)="data">
          <span v-if="data.item.payer">{{ data.item.payer.code }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-if="userRole === 'student'
              && data.item.paymentMethod !== 'Subsidy (UKR)'
              && data.item.paymentMethod !== 'Subsidy (MDI)'
              && data.item.paymentMethod !== 'Free Entry'
              && data.item.registrationFilter
              && data.item.registrationFilter.code !== 'settled'
              && data.item.registrationFilter.code !== 'ported'
              && data.item.registrationFilter.code !== 'transferred'
              && data.item.registrationFilter.code !== 'subsidized'
              && !(data.item.registrationFilter.code === 'refunded' && !data.item.view_receipt)
              && !(data.item.paymentMethod === 'Invoice' && data.item.payer.code === 'company')
            "
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="primary"
            @click="printInvoice(data.item.id)"
          >
            <!-- <feather-icon icon="PrinterIcon" /> -->
            <span> View receipt</span>
          </b-button>
          <b-dropdown
            v-if="userRole === 'administrator'"
            dropleft
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="userRole === 'administrator'"
              v-b-modal="getModal2Id(data.item.id)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="printInvoice(data.item.id)"
            >
              <feather-icon icon="PrinterIcon" />
              <span class="align-middle ml-50">Print invoice</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="userRole === 'administrator'"
              v-b-modal="getModal1Id(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
            <!-- basic modal -->
            <b-modal
              :id="'modal1'+data.item.id"
              title="Delete invoice"
              ok-title="Accept"
              @ok="remove(data.item.id)"
            >
              <b-card-text>
                <h5>{{ $t('Are you sure you want to delete') }}<strong>{{ data.item.student_name }}</strong></h5> 's {{ $t('invoice') }}
              </b-card-text>
            </b-modal>

            <!-- basic modal -->
            <b-modal
              :id="'modal2'+data.item.id"
              title="Shcool comments"
              ok-title="Accept"
              @ok="edit(data.item.id)"
            >
              <b-form-group
                :label="$t('School comments')"
                label-for="School comments"
              >
                <textarea
                  v-model="comments"
                  class="form-control"
                  autofocus
                  :placeholder="$t('Comments')"
                  rows="3"
                />
              </b-form-group>
            </b-modal>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="!isBusy"
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BFormInput, BTable, BButton,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, BSpinner, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useInvoicesList'
import invoiceStoreModule from './invoiceStoreModule'
import InvoiceListAddNew from './InvoiceListAddNew.vue'

export default {
  components: {
    // UsersListFilters,
    InvoiceListAddNew,

    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BSpinner,
    BButton,

    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isBusy: false,
    }
  },
  setup() {
    /* eslint-disable camelcase */
    const getModal1Id = id => `modal1${id}`
    const getModal2Id = id => `modal2${id}`

    const INVOICE_STORE_MODULE_NAME = 'invoice'

    // Register module
    if (!store.hasModule(INVOICE_STORE_MODULE_NAME)) store.registerModule(INVOICE_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'administrator' },
      { label: 'Student', value: 'student' },
      { label: 'Teacher', value: 'teacher' },
      { label: 'Agent', value: 'agent' },
      { label: 'Company', value: 'company' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]
    const query1 = ref('')
    const query2 = ref('')
    const selectedTemplate = ref({})
    const selectedCourse = ref({})
    const templates = ref([])
    const courses = ref([])
    const comments = ref('')
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userRole = userData.role

    // Use toast
    const toast = useToast()

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useUsersList()

    const autoCompleteCourse = search => {
      courses.value = []
      store.dispatch('invoice/autoCompleteCourses', search)
      .then(response => { courses.value = response.data })
    }
    const autoCompleteTemplate = search => {
      templates.value = []
      store.dispatch('invoice/autoComplete', search)
      .then(response => {
        templates.value = response.data
      })
    }

    const printInvoice = id => {
      store.dispatch('invoice/printInvoice', id)
      .catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }

    const remove = invId => {
      store.dispatch('invoice/deleteInvoice', invId)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Invoice Has Been removed',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while removing Invoice',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const edit = invId => {
      const school_comments = comments.value
      store.dispatch('invoice/editInvoice', { invId, school_comments })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Invoice Has Been edited',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while editing Invoice',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const formatDateTime = dateTime => {
      if (dateTime) {
        const timestamp = new Date(dateTime).getTime()
        const todate = new Date(timestamp).getDate()
        const tomonth = new Date(timestamp).getMonth() + 1
        const toyear = new Date(timestamp).getFullYear()

        const today = `0${todate.toString().slice(-2)}`
        const thisMonth = `0${tomonth.toString().slice(-2)}`
        const day = (today.length === 2 ? today : today.substr(1))
        const month = (thisMonth.length === 2 ? thisMonth : thisMonth.substr(1))

        const original_date = `${day}-${month}-${toyear}`
        return original_date
      }
      return 'N/A'
    }

    return {
      formatDateTime,
      // Modal ids
      getModal1Id,
      getModal2Id,

      // Actions
      remove,
      printInvoice,
      edit,

      // autocomplete
      query1,
      query2,
      selectedTemplate,
      selectedCourse,
      templates,
      courses,
      autoCompleteTemplate,
      autoCompleteCourse,

      comments,
      userRole,

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
