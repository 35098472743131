<template>
  <div class="btn-toolbar flex-wrap-reverse">
    <!--2en group-->
    <b-button
      v-for="action in filteredActions2"
      :key="action.name"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-tooltip.hover.top="action.name"
      variant="outline-primary"
      size="md"
      class="mr-25 mb-1 flex-fill"
      @click="makeAction(action.action)"
    >
      <feather-icon
        :icon="action.icon"
      />
      <!--        <span class="align-middle">{{ action.name }}</span>-->
    </b-button>
    <!-- 1st group-->
    <b-button-group class="flex-fill">
      <b-button
        v-for="action in filteredActions1"
        :key="action.name"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="action.class"
        size="md"
        class="ml-25"
        @click="makeAction(action.action)"
      >
        <feather-icon
          :icon="action.icon"
        />
        <span class="align-middle">{{ action.name }}</span>
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
import { BButton, BButtonGroup, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BButtonGroup,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    selectedRows: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      actions1: [
        {
          name: 'Cancel',
          icon: 'XIcon',
          action: 'cancel-registration',
          group: 1,
          class: 'danger text-nowrap mb-1',
          notShow: this.selectedRows.length > 0 ? this.selectedRows.some(el => el.status === 'canceled' || el.status === 'deleted') : false,
        },
        {
          name: 'Confirm',
          icon: 'CheckIcon',
          action: 'confirm-registration',
          group: 1,
          class: 'success text-nowrap mb-1',
          notShow: this.selectedRows.length > 0 ? this.selectedRows.some(el => el.status === 'confirmed' || el.status === 'deleted' || (el.registrationFilter && el.registrationFilter.code === 'refunded')) : false,
        },
      ],
      actions2: [
        {
          name: 'Copy emails',
          icon: 'CopyIcon',
          action: 'copy-emails',
          group: 2,
          class: 'outline-primary mb-1',
          notShow: false,
        },
        {
          name: 'Send email',
          icon: 'MailIcon',
          action: 'send-email',
          group: 2,
          class: 'outline-primary',
          notShow: false,
        },
        // {
        //   name: 'Update payment',
        //   icon: 'CreditCardIcon',
        //   action: 'update-payment',
        //   group: 2,
        //   class: 'outline-primary',
        // },
        {
          name: 'Generate contracts',
          icon: 'FileTextIcon',
          action: 'generate-contract',
          group: 2,
          class: 'outline-primary',
          notShow: false,
        },
        // { name: 'Send emails(s)', icon: 'MailIcon', action: 'send-emails', group: 2 },
        {
          name: 'Generate certificate',
          icon: 'AwardIcon',
          action: 'generate-certificate',
          class: 'outline-primary',
          group: 2,
          notShow: this.selectedRows.length > 0 ? this.selectedRows.some(el => parseInt(el.attendance, 0) < 80) : false,
        },
        {
          name: 'Move students',
          icon: 'CodeIcon',
          action: 'move-student',
          class: 'outline-primary',
          group: 2,
          notShow: this.selectedRows.length > 0 ? this.selectedRows.some(el => el.moved_to_id || el.status !== 'confirmed') : false,
        },
        // {
        //   name: 'Send P test',
        //   icon: 'ClipboardIcon',
        //   action: 'send-test',
        //   class: 'outline-primary',
        //   group: 2,
        // },
        {
          name: 'Add to Waiting list',
          icon: 'LoaderIcon',
          action: 'waiting-list',
          class: 'outline-primary',
          group: 2,
          notShow: this.selectedRows.length > 0 ? this.selectedRows.some(el => el.waiting_list || (el.registrationFilter && el.registrationFilter.code === 'refunded')) : false,
        },
        {
          name: 'Send proof of participation',
          icon: 'ThumbsUpIcon',
          action: 'proof-participation',
          class: 'outline-primary',
          group: 2,
          notShow: this.selectedRows.length > 0 ? this.selectedRows.some(el => el.paimentMethod && (el.paimentMethod.name !== 'Subsidy (IMDI)' && el.paimentMethod.name !== 'Subsidy (UKR)')) : false,
        },
      ],
    }
  },
  computed: {
    filteredActions1() {
      return this.actions1.filter(el => !el.notShow)
    },
    filteredActions2() {
      return this.actions2.filter(el => !el.notShow)
    },
  },
  methods: {
    makeAction(action) {
      console.log(action)
      if (action === 'copy-emails') {
        return this.$emit('copy-emails')
      }
      if (action === 'update-payment') return this.$emit('show-update-payment-modal')
      if (action === 'move-student') return this.$emit('show-move-student-modal')
      if (action === 'cancel-registration') return this.$emit('cancel-registration-modal')
      if (action === 'send-email') return this.$emit('show-send-email-modal')
      if (action === 'proof-participation') return this.$emit('proof-participation')
      return this.$emit('multi-action', action)
    },
  },
}
</script>
