<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
          />
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
                debounce="500"
              />
              <b-button
                v-b-modal.modal-1
                centered
                variant="primary"
                size="xl"
                @click="reInitTmc"
              >
                <span class="text-nowrap">{{ $t('Add Template') }}</span>
              </b-button>
              <!-- basic modal -->
              <b-modal
                id="modal-1"
                :title="$t('Add Template')"
                ok-title="Add"
                size="xl"
                centered
                ok-only
                no-close-on-backdrop
                hide-footer
                @hidden="resetModal"
              >
                <validation-observer
                  #default="{ invalid }"
                  ref="emailTemplateFormAdd"
                >
                  <b-form
                    class="p-2"
                    @submit.prevent="add"
                    @reset.prevent="resetForm"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Name"
                      rules="required"
                    >
                      <b-form-group
                        label="Name"
                        label-for="name"
                      >
                        <b-form-input
                          v-model="EmailName"
                          autofocus
                          :state="getValidationState(validationContext)"
                          placeholder="Name"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <validation-provider
                      #default="validationContext"
                      name="Description"
                    >
                      <b-form-group
                        label="Description"
                        label-for="description"
                      >
                        <b-form-textarea
                          v-model="EmailDescription"
                          autofocus
                          :state="getValidationState(validationContext)"
                          placeholder="Description"
                          rows="2"
                          max-rows="6"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <b-tabs>
                      <b-tab
                        v-for="emailData in emailsData"
                        :key="emailData.lang"
                        :active="emailData.lang === 'English'"
                        :title="emailData.lang"
                      >
                        <validation-provider
                          #default="validationContext"
                          name="Subject"
                          :rules="emailData.lang === 'English' ? 'required' : null"
                        >
                          <b-form-group
                            label="Subject"
                            label-for="subject"
                          >
                            <b-form-input
                              v-model="emailData.subject"
                              :state="getValidationState(validationContext)"
                              autofocus
                              :placeholder="emailData.lang"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                        <validation-provider
                          #default="validationContext"
                          name="Body"
                          :rules="emailData.lang === 'English' ? 'required' : null"
                        >
                          <b-form-group
                            :label="isLoadingEditor ? 'Loading...' : 'Body'"
                            label-for="body"
                            :state="getValidationState(validationContext)"
                          >
                            <div @focusin.stop>
                              <editor
                                :key="refreshKey"
                                v-model="emailData.body"
                                :api-key="tinyMceApiKey"
                                :init="{
                                  plugins: 'lists link image table code help wordcount',
                                  height : '480',
                                  setup: onEditorSetup,
                                }"
                              />
                            </div>
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-tab>
                    </b-tabs>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      type="submit"
                      :disabled="invalid"
                    >
                      {{ $t('Add') }}
                    </b-button>
                  </b-form>
                </validation-observer>
              </b-modal>
            </div>
          </b-col>
        </b-row>

      </div>
      <!-- <div class="text-center">
        <b-spinner variant="primary" label="Text Centered" />
      </div> -->
      <b-table
        ref="refUserListTable"
        :busy.sync="isBusy"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>
        <!-- Column: User -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.id }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Name -->
        <template #cell(name)="data">
          {{ data.item.name }}
        </template>
        <!-- Column: Description -->
        <template #cell(description)="data">
          <p
            class="text-truncate"
            style="max-width: 600px"
            v-b-popover.hover.top="data.item.description"
          >
            {{ data.item.description }}
          </p>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-if="userRole === 'administrator'"
            v-b-modal="getModal2Id(data.item.id)"
            @click="fetchTemplate(data.item.id)"
            variant="primary"
            size="sm"
            class="text-nowrap"
          >
            <feather-icon icon="EditIcon" />
            <span class="ml-25">Edit</span>
          </b-button>
          <!-- <b-dropdown
            dropleft
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="userRole === 'administrator'"
              v-b-modal="getModal2Id(data.item.id)"
              @click="fetchTemplate(data.item.id)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('Edit') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="userRole === 'administrator'"
              v-b-modal="getModal1Id(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('Delete') }}</span>
            </b-dropdown-item>
          </b-dropdown> -->
          <b-modal
            :id="'modal1'+data.item.id"
            title="Delete Template"
            ok-title="Yes"
            cancel-title="No"
            centered
            @ok="remove(data.item.id)"
          >
            <b-card-text>
              <h5>{{ $t('Are you sure you want to delete') }} <strong>{{ data.item.name }}</strong> ?</h5>
            </b-card-text>
          </b-modal>
          <b-modal
            :id="'modal2'+data.item.id"
            title="Email Template"
            :ok-title="$t('Update')"
            ok-only
            centered
            size="xl"
            no-close-on-backdrop
            hide-footer
          >
            <div
              v-if="isBusyModal"
              class="text-center d-flex align-items-center justify-content-center"
            >
              <b-spinner
                variant="primary"
                label="Text Centered"
              />
              <strong class="ml-1">Loading...</strong>
            </div>
            <div v-if="!isBusyModal">
              <validation-observer
                #default="{invalid}"
                ref="emailTemplateFormEdit"
              >
                <b-form
                  class="p-2"
                  @submit.prevent="edit(data.item.id)"
                  @reset.prevent="resetForm"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Name"
                    rules="required"
                  >
                    <b-form-group
                      label="Name"
                      label-for="name"
                    >
                      <b-form-input
                        v-model="AnotherEmailName"
                        autofocus
                        :state="getValidationState(validationContext)"
                        placeholder="Name"
                        disabled
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="validationContext"
                    name="Description"
                  >
                    <b-form-group
                      label="Description"
                      label-for="description"
                    >
                      <b-form-textarea
                        v-model="AnotherEmailDescription"
                        autofocus
                        :state="getValidationState(validationContext)"
                        placeholder="Description"
                        rows="2"
                        max-rows="6"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <b-tabs>
                    <b-tab
                      v-for="emailData in template"
                      :key="emailData.lang"
                      :active="emailData.lang === 'English'"
                      :title="emailData.lang"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Subject"
                        :rules="emailData.lang === 'English' ? 'required' : null"
                      >
                        <b-form-group
                          label="Subject"
                          label-for="subject"
                        >
                          <b-form-input
                            v-model="emailData.subject"
                            autofocus
                            :state="getValidationState(validationContext)"
                            placeholder="Subject"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                      <validation-provider
                        #default="validationContext"
                        name="Body"
                        :rules="emailData.lang === 'English' ? 'required' : null"
                      >
                        <b-form-group
                          :label="isLoadingEditor ? 'Loading...' : 'Body'"
                          label-for="body"
                          :state="getValidationState(validationContext)"
                        >
                          <div @focusin.stop>
                            <Editor
                              :key="refreshKey"
                              v-model="emailData.body"
                              :api-key="tinyMceApiKey"
                              :init="{
                                plugins: 'lists link image table code help wordcount',
                                height : '480',
                                setup: onEditorSetup,
                              }"
                            />
                          </div>
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-tab>
                  </b-tabs>
                  <b-button
                    centered
                    type="submit"
                    variant="primary"
                    size="xl"
                    :disabled="invalid"
                  >
                    <span class="text-nowrap">{{ $t('Update') }}</span>
                  </b-button>
                </b-form>
              </validation-observer>
            </div>
          </b-modal>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="!isBusy"
              v-model="currentPage"
              :total-rows="totalCities"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
// eslint-disable-next-line
import Editor from '@tinymce/tinymce-vue'
import {
  BCard, BCardText, BRow, BCol, BForm, BFormInput, BFormGroup, BButton, BTable, BMedia, BLink, BFormTextarea,
  BPagination, BModal, BTabs, BTab, BSpinner, BFormInvalidFeedback, VBPopover,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
// import UsersListFilters from './UsersListFilters.vue'
import useCitiesList from './useCitiesList'
import cityStoreModule from './cityStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    // UsersListFilters,
    BSpinner,
    Editor,

    BCard,
    BCardText,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    BModal,
    BTabs,
    BTab,
    // quillEditor,
    BFormTextarea,

    vSelect,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      required,
      isLoadingEditor: true,
      tinyMceApiKey: process.env.VUE_APP_TINY_MCE_API_KEY,
      statusOptions: ['Active', 'Deleted'],
      // editorOption: {
        // modules: {
          // toolbarOptions: ['bold', 'italic', 'underline', 'strike'],
        // },
      // },
    }
  },
  mounted() {
    document.addEventListener('focusin', e => {
      if (e.target.closest('.tox-tinymce-aux, .moxman-window, .tam-assetmanager-root') !== null) {
        e.stopImmediatePropagation()
      }
    })
  },
  setup() {
    /* eslint-disable camelcase */
    const getModal1Id = id => `modal1${id}`
    const getModal2Id = id => `modal2${id}`

    const CITY_STORE_MODULE_NAME = 'city'

    // Register module
    if (!store.hasModule(CITY_STORE_MODULE_NAME)) store.registerModule(CITY_STORE_MODULE_NAME, cityStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CITY_STORE_MODULE_NAME)) store.unregisterModule(CITY_STORE_MODULE_NAME)
    })

    const roleOptions = [
      { label: 'Admin', value: 'administrator' },
      { label: 'Student', value: 'student' },
      { label: 'Teacher', value: 'teacher' },
      { label: 'Agent', value: 'agent' },
      { label: 'Company', value: 'company' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Deleted', value: 'deleted' },
    ]
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userRole = userData.role
    const selectedStatus = ref({ label: 'Active', value: 'Active' })
    const EmailName = ref('')
    const AnotherEmailName = ref('')
    const AnotherEmailDescription = ref('')
    const EmailDescription = ref('')
    const emailsData = ref([
      {
        lang: 'English',
        subject: '',
        body: '',
      },
      // {
      //   lang: 'Norwegian',
      //   subject: '',
      //   body: '',
      // },
      // {
      //   lang: 'French',
      //   subject: '',
      //   body: '',
      // },
      // {
      //   lang: 'German',
      //   subject: '',
      //   body: '',
      // },
    ])
    const template = ref([])
    const isBusyModal = ref(true)

    // Use toast
    const toast = useToast()

    const {
      isBusy,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalCities,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useCitiesList()

    const remove = id => {
      store.dispatch('city/deleteTemplate', id)
        .then(res => {
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const edit = function (id) {
      this.$refs.emailTemplateFormEdit.validate().then(success => {
        if (success) {
          const payload = {
          name: AnotherEmailName.value,
          description: AnotherEmailDescription.value,
          subject: {
            en: template.value[0].subject,
            // no: template.value[1].subject,
            // fr: template.value[2].subject,
            // de: template.value[3].subject,
          },
          body: {
            en: template.value[0].body,
            // no: template.value[1].body,
            // fr: template.value[2].body,
            // de: template.value[3].body,
          },
        }
        store.dispatch('city/editTemplate', { id, payload })
          .then(res => {
            toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$bvModal.hide(`modal1${id}`)
            refetchData()
        }).catch(err => {
          toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          })
        }
      })
    }
    const add = function add() {
      this.$refs.emailTemplateFormAdd.validate().then(success => {
        if (success) {
          const payload = {
            name: EmailName.value,
            description: EmailDescription.value,
            subject: {
              en: emailsData.value[0].subject,
              // no: emailsData.value[1].subject,
              // fr: emailsData.value[2].subject,
              // de: emailsData.value[3].subject,
            },
            body: {
              en: emailsData.value[0].body,
              // no: emailsData.value[1].body,
              // fr: emailsData.value[2].body,
              // de: emailsData.value[3].body,
            },
          }
          store.dispatch('city/addTemplate', { payload })
            .then(res => {
              toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'success',
                },
              })
              refetchData()
              this.$bvModal.hide('modal-1')
          }).catch(err => {
            toast({
              component: ToastificationContent,
              props: {
                title: err.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    }
    const resetModal = () => {
      EmailName.value = ''
      emailsData.value = [
      {
        lang: 'English',
        subject: '',
        body: '',
      },
      // {
      //   lang: 'Norwegian',
      //   subject: '',
      //   body: '',
      // },
      // {
      //   lang: 'French',
      //   subject: '',
      //   body: '',
      // },
      // {
      //   lang: 'German',
      //   subject: '',
      //   body: '',
      // },
      ]
    }
    const refreshKey = ref(0)
    const reInitTmc = () => {
      setTimeout(() => {
        refreshKey.value += 1
      }, 200)
    }
    const fetchTemplate = id => {
      reInitTmc()
      store.dispatch('city/fetchTemplate', id).then(res => {
        AnotherEmailName.value = res.data.data.name
        AnotherEmailDescription.value = res.data.data.description
        template.value = [
          {
            lang: 'English',
            subject: res.data.data.subject.en,
            body: res.data.data.body.en,
          },
          // {
          //   lang: 'Norwegian',
          //   subject: res.data.data.subject.no,
          //   body: res.data.data.body.no,
          // },
          // {
          //   lang: 'French',
          //   subject: res.data.data.subject.fr,
          //   body: res.data.data.body.fr,
          // },
          // {
          //   lang: 'German',
          //   subject: res.data.data.subject.de,
          //   body: res.data.data.body.de,
          // },
        ]
        isBusyModal.value = false
      })
    }
    const blankUserData = [
      {
        lang: 'English',
        subject: '',
        body: '',
      },
      {
        lang: 'French',
        subject: '',
        body: '',
      },
      {
        lang: 'German',
        subject: '',
        body: '',
      },
    ]
    const Data = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      Data.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      reInitTmc,
      refreshKey,
      isBusy,
      Data,
      refFormObserver,
      getValidationState,
      resetForm,
      // Modal ids
      getModal1Id,
      getModal2Id,
      emailsData,
      EmailName,
      fetchTemplate,
      AnotherEmailName,
      EmailDescription,
      AnotherEmailDescription,
      isBusyModal,
      resetModal,

      // Actions
      remove,
      edit,
      add,

      userRole,
      selectedStatus,

      // Sidebar

      fetchUsers,
      template,
      tableColumns,
      perPage,
      currentPage,
      totalCities,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  methods: {
    onEditorSetup(editor) {
      editor.on('init', () => {
        this.isLoadingEditor = false
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.per-page-selector {
  width: 90px;
}
.ql-editor  {
    min-height: 150px !important;
  }
.ql-toolbar .ql-video, .ql-toolbar .ql-image, .ql-toolbar .ql-font {
  display: none !important;
}
#sample {
  display: flex;
  flex-direction: column;
  place-items: center;
  width: 1000px;
}
</style>
