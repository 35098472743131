<template>
  <div>
    <h1>Hello</h1>
    <b-modal
      ref="my-modal"
      title="Details"
      size="lg"
      ok-only
      ok-title="Cancel"
      ok-variant="secondary"
      @hide="$emit('hide-modal')"
    >
      <div>
        <course-list />
      </div>
    </b-modal>
  </div>
</template>
<script>
/* eslint-disable-next-line */
import { VBModal, BModal } from 'bootstrap-vue'
// import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import CourseList from '@/views/courses/admin/courses-list/CoursesList.vue'

export default {
  components: {
    BModal,
    // VueGoodTable,
    // BAvatar,
    // BBadge,
    // BDropdown,
    // BDropdownItem,

    CourseList,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'Name',
          field: 'fullName',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Date',
          field: 'startDate',
        },
        {
          label: 'Salary',
          field: 'salary',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.$http.get('/good-table/basic')
      .then(res => { this.rows = res.data })
  },
  mounted() {
    this.showModal()
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
  },
}
</script>
