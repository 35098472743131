<template>
  <span>
    <b-form-input
      v-model="response"
      class="d-inline-flex"
      style="max-width: 20%"
      @keyup="$emit('responseevent', {id: idx, response: response})"
    />
  </span>
</template>

<script>
import { BFormInput } from 'bootstrap-vue'

export default {
    name: 'InputComponent',
    components: { BFormInput },
    props: {
        content: Object,
        idx: Number,
    },
    data() {
        return {
            response: null,
        }
    },
}
</script>

<style lang="scss">

</style>
