<template>
  <div>
    <!-- Account Notification -->
    <b-link
      v-for="(notification, idx) in notifications"
      :key="idx"
      @click="emitStuff(notification)"
      class="text-dark"
      :to="redirectTo(notification)"
    >
      <b-media class="bg-notification" :class="!notification.isReaded ? 'bg-light-secondary' : ''">
        <template #aside>
          <b-avatar
            size="32"
            :src="notification.avatar"
            :text="notification.avatar"
            :variant="notification.type"
          />
        </template>
        <p
          class="media-heading"
        >
          <span v-html="notification.title" />
        </p>
        <small class="notification-text">{{ notification.date }}</small>
      </b-media>
    </b-link>
  </div>
</template>

<script>
import {
  BMedia, BLink, BAvatar, /* BFormCheckbox */
} from 'bootstrap-vue'

export default {
  name: 'NotifcationElement',
  components: {
    BMedia,
    BLink,
    BAvatar,
  },
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentPage: 2,
      userData: JSON.parse(localStorage.userData),
    }
  },
  methods: {
    /* eslint-disable no-param-reassign, consistent-return, spaced-comment */
    emitStuff(notification) {
      this.$emit('mark-notification-as-read', notification.id, notification.type, notification.data)
      this.$emit('decrease-notificatiopns-count', notification.isReaded)
      notification.isReaded = true
    },
    redirectTo(notification) {
      /* eslint-disable object-curly-newline */
      if (notification.type === 'notification_approval') { return { name: this.userData.role === 'admin' ? 'admin-course-approval' : 'teacher-teacher-calendar' } } // Approved filter
      if (notification.type === 'notification_assign_course') { return { name: 'teacher-list-all-courses', params: { label: 'Awaiting approval', value: 'awaiting_approval' } } }
      if (notification.type === 'placement_test') { return { name: 'test' } }
      if (notification.type === 'prologation_test') { return { name: 'test-request' } }
      if (notification.type === 'claim_attendance') { return { name: 'admin-claim-list' } }
      if (notification.type === 'notification_substitution_teacher_request') { return { name: 'admin-course-approval', params: { tab: 'requests' } } } //teacher tab
      if (notification.type === 'notification_substitution_admin_request') { return { name: 'teacher-courses-substitute', params: { label: 'Awaiting Approval', value: 'awaitingApproval' } } } //awaiting approval filter
      if (notification.type === 'notification_substitution_teacher_approve') { return { name: 'admin-approval-history', params: { label: 'Approved', value: 'approved' } } } // Approved filter
      if (notification.type === 'notification_substitution_teacher_cancel') { return { name: 'admin-approval-history', params: { label: 'Declined', value: 'declined' } } } //Declined filter
      if (notification.type === 'notification_substitution_admin_approve') { return { name: 'admin-approval-historyteacher-attendance' } }
      if (notification.type === 'notification_substitution_admin_cancel') { return { name: 'teacher-courses-substitute', params: { label: 'Awaiting Approval', value: 'awaitingApproval' } } } // Filter makaynsh
      if (notification.type === 'notification_approval_by_teacher') { return { name: 'admin-approval-history', params: { label: 'Approved', value: 'approved' } } } // Approved filter
      if (notification.type === 'notification_approval_cancel_by_teacher') { return { name: 'admin-approval-history', params: { label: 'Declined', value: 'declined' } } } // Declined filter
      if (notification.type === 'notification_approval_request_join_course') { return { name: 'admin-course-approval', params: { tab: 'requests' } } } // Teacher tab
      if (notification.type === 'change_name_request') { return { name: 'apps-users-list-student', params: { label: 'Requested renaming', value: 'requested_naming' } } } // Requested ranaming
      if (notification.type === 'notification_update') { return { name: 'apps-users-list-student' } }
      if (notification.type === 'notification_second_account') { return { name: 'pages-account-setting', params: { tab: 'secAccount' } } } // Second account tab
      if (notification.type === 'notification_holiday_request') { return { name: 'admin-teacher-events' } } // Pending filter
      if (notification.type === 'notification_holiday_approved') { return { name: 'teacher-teacher-calendar' } }
      if (notification.type === 'notification_assign_course_admin_request') { return { name: 'teacher-list-all-courses', params: { label: 'Awaiting approval', value: 'awaiting_approval' } } } // Awaiting approval
      if (notification.type === 'notification_assign_course_admin_cancel') { return { name: 'teacher-list-all-courses', params: { label: 'Requested', value: 'requested' } } } // Requested
      if (notification.type === 'notification_assign_course_admin_approve') { return { name: 'teacher-list-all-courses', params: { label: 'Assigned', value: 'current' } } } // Assigned
      if (notification.type === 'change_name_approved') { return { name: 'pages-account-setting', params: { tab: 'general' } } } // General tab
      if (notification.type === 'courses_to_watch') { return { name: 'admin-courses', params: { label: 'To watch', code: 'towatch', color: 'info' } } } // To watch filter
      if (notification.type === 'courses_to_confirm') { return { name: 'admin-courses', params: { label: 'To confirm', code: 'toconfirm', color: 'primary' } } } // To watch filter
      if (notification.type === 'admission_reminder') { return { name: 'registrations', query: { course: notification.data.admission_course, reg_status: 'confirmed', start: notification.data.date_start, end: notification.data.date_end } } }
      if (notification.type === 'refund_notification') return { name: 'student-invoices' }
    },
  },
}
</script>

<style scoped>
.isReaded {
  background-color: #E2DEDE;
}
.bg-notification:hover {
  background-color: #E2DEDE !important;
}
</style>
