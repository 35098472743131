<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout" :key="refreshKey">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import Echo from 'laravel-echo'
import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  data() {
    return {
      isLoggedIn: false,
      refreshKey: 0,
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  watch: {
    '$store.state.appConfig.isLoggedIn': {
      handler() {
        this.isLoggedIn = this.$store.state.appConfig.isLoggedIn
        this.connectLaravelEcho()
      },
      deep: true,
    },
    '$route.query': {
      handler() {
        if (this.$route.query.start) this.refreshKey += 1
      },
      deep: true,
    },
    '$route.params': {
      handler() {
        if (this.$route.params.id) this.refreshKey += 1
      },
      deep: true,
    },
    /* eslint-disable quote-props */
  },
  created() {
    this.connectLaravelEcho()
  },
  methods: {
    connectLaravelEcho() {
      /* eslint-disable-next-line */
    window.Pusher = require('pusher-js')

    window.Echo = new Echo({
      broadcaster: 'pusher',
      id: 'local',
      key: 'local',
      cluster: 'eu',
      forceTLS: false,
      wsHost: process.env.VUE_APP_DOMAIN,
      // wsPort: 6001,
      // wssPort: 6001,
      disableStates: true,
      enabledTransports: ['ws', 'wss'], // <- added this param
      // authHost: 'http://alfa.test',
      auth: {
        headers: {
          // eslint-disable-next-line
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          Accept: 'application/json',
        },
      },
      /* eslint-disable-next-line */
      authEndpoint: 'https://' + process.env.VUE_APP_DOMAIN + '/api/broadcasting/auth',
    })
    },
  },
  /* eslint-disable-next-line */
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>
