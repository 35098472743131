import { render, staticRenderFns } from "./DahboardCourseTimeline.vue?vue&type=template&id=20d21f0a&scoped=true&"
import script from "./DahboardCourseTimeline.vue?vue&type=script&lang=js&"
export * from "./DahboardCourseTimeline.vue?vue&type=script&lang=js&"
import style0 from "./DahboardCourseTimeline.vue?vue&type=style&index=0&id=20d21f0a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20d21f0a",
  null
  
)

export default component.exports