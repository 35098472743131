<template>

  <div>

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :payer-options="payerOptions.filter(el => el.id)"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <!-- <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->
    <!-- <registrations-filter
      :isFilterActive.sync="isFilterActive"
      :student.sync="student"
      :course.sync="course"
      :defaultStatus.sync="defaultStatus"
      :payer_confirmed.sync="payer_confirmed"
      :visa.sync="visa"
      :who_pays.sync="who_pays"
    /> -->

    <courses-list-big-modal
      v-if="isBigModalActive"
      :isBigModalActive.sync="isBigModalActive"
      :classrooms="classrooms"
      :teachers="teachers"
      :masters="masters"
      :cities="cities"
      :TofDArray="TofDArray"
      :currentCourseData="currentCourseData"
      :msgs="msgs"
      :statusArray="statusArray"
      :UsersToAdd="UsersToAdd"
      :UsersAlreadyAdded="UsersAlreadyAdded"
      :currentUser="currentUser"
      :loading="spinner"
      @refetch-data="refetchData"
      @loading="loadSpinner"
      @suggest-classrooms="suggestClassrooms"
      @suggest-teacher="suggestTeacher"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div>

        <!-- Table Top -->
        <b-row>

          <!-- filter courses by course name  -->
          <b-col
            cols="12"
            class="d-flex flex-wrap align-items-center justify-content-between"
          >
            <div class="flex-grow-1 d-flex">
              <b-button
                v-if="selectedRows.length === 0"
                v-b-tooltip.hover.top="'Select all'"
                class="mr-25 mb-1"
                variant="primary"
                @click="selectAllRows"
              >
                <feather-icon icon="CheckSquareIcon" />
              </b-button>
              <b-button
                v-if="selectedRows.length > 0"
                v-b-tooltip.hover.top="'Clear selection'"
                class="mr-25 mb-1"
                variant="primary"
                @click="clearSelected"
              >
                <feather-icon icon="MinusSquareIcon" />
              </b-button>
              <b-button
                variant="primary"
                class="text-nowrap mb-1 flex-fill flex-sm-grow-0"
                :disabled="isLoadingExcelDoc"
                @click="getExcelDoc(student, course, date_range, who_pays.id, defaultStatus.id, paymentFilter.id, regDefaultStatus.value, selectedRows, selectAll, unselectedRows)"
              >
                <b-spinner
                  v-if="isLoadingExcelDoc"
                  class="mr-25"
                  small
                  label="Loading..."
                />
                <span>Export</span>
                <feather-icon
                  v-if="!isLoadingExcelDoc"
                  class="ml-25"
                  icon="ExternalLinkIcon"
                />
              </b-button>
            </div>
            <user-select-actions
              v-if="selectedRows.length !== 0 && !isBusy"
              :key="selectedRows.length"
              :selected-rows="selectedRows"
              @multi-action="multiAction"
              @show-move-student-modal="$bvModal.show('move-studentModal')"
              @cancel-registration-modal="$bvModal.show('cancel-registration-modal')"
              @show-send-email-modal="$bvModal.show('send-emailModal')"
              @copy-emails="copyEmails(emailsList.join('; '))"
            />
          </b-col>
          <b-col
            v-if="selectedRows.length > 0"
            cols="12"
          >
            <b-alert
              variant="secondary"
              show
            >
              <div class="alert-body text-center">
                <span>{{ selectAll ? dataMeta.of - unselectedRows.length : selectedRows.length }} entries are selected.</span>
                <b-Link
                  v-if="!selectAll && unselectedRows.length !== 0 && selectedRows.length !== dataMeta.of"
                  @click="selectedRows = $store.state.registerStoreModule.registrations.rows; selectAll = true"
                >
                  Select all {{ dataMeta.of }} entries
                </b-Link>
                <b-Link
                  v-if="selectAll"
                  @click="selectAll = false; selectedRows = []"
                >
                  Clear selection
                </b-Link>
              </div>
            </b-alert>
          </b-col>

          <!-- Search -->
          <b-col
            v-if="!groupId"
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
                debounce="500"
              />
              <b-button
                v-b-tooltip.hover.top="'Filters'"
                variant="primary"
                class="d-inline-block mr-1"
                @click="isFilterActive = true"
              >
                <feather-icon icon="FilterIcon" />
              </b-button>

              <b-button
                v-b-tooltip.hover.top="'Add'"
                variant="primary"
                class="d-inline-block mr-1"
                @click="isAddNewUserSidebarActive = true"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mb-1">
            <v-select
              :value="defaultCourse"
              label="label"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="groupCourses"
              :clearable="false"
              @input="(val) => { defaultCourse = val }"
            >
              <template #option="{ label, date }">
                <div class="d-flex justify-content-between">
                  <span> {{ label }}</span>
                  <span v-if="date">{{ formatDateTime1(date.split(' ')[0]) }}</span>
                </div>
              </template>
            </v-select>
          </b-col>
          <b-col class="mb-1">
            <v-select
              v-model="regDefaultStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="regStatusOptions"
              :clearable="false"
            />
          </b-col>
          <b-col class="mb-1">
            <v-select
              v-model="who_pays"
              label="name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="payerOptions"
              :clearable="false"
            />
          </b-col>
          <b-col class="mb-1">
            <v-select
              v-model="defaultStatus"
              label="name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :clearable="false"
            />
          </b-col>
          <b-col class="mb-1">
            <v-select
              v-model="paymentFilter"
              label="name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="methodOptions"
              :clearable="false"
            />
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        key="1545"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="dashboard ? tableColumns1 : (studentId ? tableColumns2 : tableColumns)"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
        :tbody-tr-class="tbodyRowClass"
        @row-clicked="onRowSelected"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>
        <!-- Selected -->
        <template #cell(selected)="data">
          <b-form-checkbox
            disabled
            :checked="selectedRows.some(el => el.id === data.item.id)"
          >
            <feather-icon
              v-if="data.item.moved_from"
              v-b-popover.hover.top="`Moved from ${data.item.moved_from.course} (${data.item.moved_from.group})`"
              icon="CornerRightDownIcon"
              class="text-warning mr-1 b-avatar b-avatar-sm badge-light-warning rounded-circle p-25"
            />
            <feather-icon
              v-if="data.item.moved_to"
              v-b-popover.hover.top="`Moved to ${data.item.moved_to.course} (${data.item.moved_to.group})`"
              icon="CornerRightUpIcon"
              class="text-info mr-1 b-avatar b-avatar-sm badge-light-info rounded-circle p-25"
            />
          </b-form-checkbox>
          <small class="text-secondary">{{ data.item.id }}</small>
        </template>
        <!-- Column: student Id, not registration id -->
        <template #cell(id)="data">
          <div class="media-body">
            <div class="font-weight-bold d-block text-nowrap text-capitalize">
              {{ data.item.student_id }}
            </div>
            <!--            <small class="text-muted text-nowrap">{{ formatDateTime(data.item.created_at) }}</small>-->
          </div>

        </template>

        <!-- Column: visa -->
        <template #cell(course_group)="data">
          <b
            style="cursor: pointer"
            class="d-block"
            @click="ModalData(data.item.course_id, 'group')"
          >
            {{ data.item.course_group }}
          </b>
          <small class="text-muted">
            {{ data.item.course_name }}
          </small>
        </template>
        <!-- Column: student name & visa -->
        <template #cell(student)="data">
          <div class="media-body">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-primary"
              :to="{ name: 'apps-users-view', params: { id: data.item.student_id } }"
              class="font-weight-bold d-block text-nowrap text-capitalize p-25"
              :style="[data.item.waiting_list ? {'postition': 'relative', 'color': '#005160', 'background-color': '#cff4fc', 'border-color': '#b6effb'} : {}]"
            >
              <span class="d-flex">
                {{ data.item.student_name ? data.item.student_name.substring(0,20) + (data.item.student_name.length >= 20 ? '...' : '') : '' }}
              </span>
              <div class="d-flex align-items-center">
                <small
                  class="d-block mr-1 d-flex text-muted"
                  style="color: #055160"
                >{{ data.item.student_id }}</small>
                <small
                  v-if="data.item.nationality"
                  class="mr-50 d-flex text-muted"
                >
                  <span class="badge text-capitalize badge-light-secondary badge-pill">{{ data.item.nationality }}</span>
                </small>
              </div>
            </b-button>
          </div>
        </template>

        <!-- Column: who pays -->
        <template #cell(who_pays)="data">
          <div
            v-if="data.item.payer"
            class="media-body"
          >
            <div class="font-weight-bold d-block text-nowrap text-capitalize">
              {{ data.item.payer.name }}
            </div>
          </div>
        </template>
        <template #cell(payment_method)="data">
          <div class="media-body">
            <div class="font-weight-bold d-block text-nowrap text-capitalize">
              {{ data.item.paimentMethod ? data.item.paimentMethod.name : '' }}
            </div>
          </div>
        </template>
        <template #cell(payment_status)="data">
          <!-- <span
            v-if="!data.item.paid"
            class="b-avatar ml-1 badge-light-warning rounded-circle d-flex align-items-center justify-content-center"
          >
            <div
              v-b-tooltip.hover.top="'Awaiting payment'"
              class="d-flex align-items-center justify-content-center"
            >
              <svg
                enable-background="new 0 0 700 700"
                class="ml-25"
                width="23"
                height="26"
                version="1.1"
                viewBox="0 0 700 700"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m422.86 173.64c-0.617 5.57-0.934 11.233-0.934 16.969 0 5.738 0.316 11.397 0.934 16.969h-351.64c-15.621 0-28.285 12.664-28.285 28.285v282.83c0 15.621 12.664 28.281 28.285 28.281h458.18c15.621 0 28.285-12.66 28.285-28.281v-176.29c5.57 0.613 11.233 0.93 16.969 0.93s11.398-0.316 16.969-0.93v176.29c0 34.363-27.854 62.223-62.223 62.223h-458.18c-34.363 0-62.223-27.858-62.223-62.223v-282.83c0-34.363 27.858-62.223 62.223-62.223h351.64zm17.902 90.504c14.613 26.551 36.891 48.285 63.854 62.223h-461.68v-62.223h397.82zm-397.83 62.223v-62.223 62.223zm531.72-254.55c65.605 0 118.79 53.184 118.79 118.79 0 65.605-53.184 118.79-118.79 118.79-65.605 0-118.79-53.185-118.79-118.79 0-65.606 53.184-118.79 118.79-118.79zm16.968 39.598c0-9.371-7.598-16.969-16.969-16.969s-16.969 7.598-16.969 16.969v92.066l61.733 61.733c6.625 6.625 17.371 6.625 23.996 0 6.629-6.629 6.629-17.37 0-24l-51.793-51.793 2e-3 -78.006z"
                  fill="#6e6b7b"
                />
              </svg>
            </div>
          </span>
          <span
            v-if="data.item.paid"
            v-b-tooltip.hover.top="'Paid'"
            class="b-avatar ml-1 badge-light-success rounded-circle d-flex align-items-center justify-content-center"
          >
            <span class="b-avatar-custom">
              <feather-icon
                icon="CreditCardIcon"
                size="16"
                class="align-middle text-body"
              />
            </span>
          </span> -->
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.registrationFilter ? data.item.registrationFilter.name : '')}`"
            class="text-capitalize"
            style="font-size: 10px"
          >
            {{ data.item.registrationFilter ? data.item.registrationFilter.name : '' }}
          </b-badge>
          <small
            v-if="data.item.extra_data
              && data.item.extra_data.refund
              && data.item.extra_data.refund.refund_failed
              && data.item.extra_data.refund.refund_failed.data
              && data.item.extra_data.refund.refund_failed.data.error"
            v-b-tooltip.hover.top="data.item.extra_data
              && data.item.extra_data.refund
              && data.item.extra_data.refund.refund_failed
              && data.item.extra_data.refund.refund_failed.data
              && data.item.extra_data.refund.refund_failed.data.error ? data.item.extra_data.refund.refund_failed.data.error.message : ''"
            class="d-block text-danger text-nowrap ml-25 p-25"
          >
            Refund Failed
          </small>
        </template>
        <!-- Column: who pays -->
        <template #cell(status)="data">
          <span
            v-if="data.item.status === 'confirmed'"
            v-b-tooltip.hover.top="data.item.status"
            class="b-avatar ml-1 badge-light-success rounded-circle d-flex align-items-center justify-content-center"
          >
            <span class="b-avatar-custom">
              <feather-icon
                icon="CheckCircleIcon"
                size="16"
                class="align-middle text-body"
              />
            </span>
          </span>
          <!-- <span
            v-if="data.item.registration_status === 'pending confirmation'"
            v-b-tooltip.hover.top="data.item.registration_status"
            class="b-avatar ml-1 badge-light-warning rounded-circle d-flex align-items-center justify-content-center"
          >
            <span class="b-avatar-custom">
              <feather-icon
                icon="LoaderIcon"
                size="16"
                class="align-middle text-body"
              />
            </span>
          </span> -->
          <span
            v-if="data.item.status === 'pending'"
            v-b-tooltip.hover.top="data.item.status"
            class="b-avatar ml-1 badge-light-warning rounded-circle d-flex align-items-center justify-content-center"
          >
            <span class="b-avatar-custom">
              <feather-icon
                icon="LoaderIcon"
                size="16"
                class="align-middle text-body"
              />
            </span>
          </span>
          <span
            v-if="data.item.status === 'canceled'"
            v-b-tooltip.hover.top="data.item.status"
            class="b-avatar ml-1 badge-light-danger rounded-circle d-flex align-items-center justify-content-center"
          >
            <span class="b-avatar-custom">
              <feather-icon
                icon="SlashIcon"
                size="16"
                class="align-middle text-body"
              />
            </span>
          </span>
        </template>

        <!-- Column: who pays -->
        <template #cell(attendance)="data">
          <b-badge
            pill
            :variant="`light-primary`"
            class="text-capitalize"
          >
            {{ data.item.attendance }}%
          </b-badge>
        </template>
        <template #cell(comment)="data">
          <b-button
            :id="`popover-target-${data.item.id}`"
            variant="flat-primary"
            size="sm"
            class="btn-icon rounded-circle"
            @click="handleChatClick({model:'registration', id: data.item.id})"
          >
            <feather-icon
              icon="ClipboardIcon"
              class="align-middle text-body"
              badge-classes="bg-danger"
              :badge="data.item.comments_number > 0 ? data.item.comments_number : ''"
              size="16"
            />
          </b-button>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-if="data.item.deleted_at"
            variant="primary"
            size="sm"
            @click="restoreReg(data.item.id)"
          >
            Restore
          </b-button>
          <b-dropdown
            v-if="!data.item.deleted_at"
            dropleft
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="handleChatClick1(data.item.student_id)">
              <feather-icon icon="MessageSquareIcon" />
              <span class="align-middle ml-50">Contact student</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.registrationFilter && data.item.registrationFilter.code !== 'refunded'"
              v-b-modal="getModal7Id(data.item.id)"
              @click="getData(data.item)"
            >
              <feather-icon icon="CreditCardIcon" />
              <span class="align-middle ml-50">Update payment</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.registrationFilter && data.item.registrationFilter.code === 'unpaid' || data.item.registrationFilter.code === 'to_be_refunded'"
              @click="markAs(data.item.invoice.id, 1)"
            >
              <feather-icon icon="CheckCircleIcon" />
              <span class="align-middle ml-50">Mark as paid</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.registrationFilter && data.item.registrationFilter.code === 'paid'"
              @click="markAs(data.item.invoice.id, 2)"
            >
              <feather-icon icon="LoaderIcon" />
              <span class="align-middle ml-50">Mark as unpaid</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.status !== 'confirmed' && data.item.registrationFilter && data.item.registrationFilter.code !== 'refunded'"
              v-b-modal="getModal2Id(data.item.id)"
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">{{ $t('Confirm registration') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.status !== 'canceled' && data.item.registrationFilter && data.item.registrationFilter.code !== 'refunded'"
              v-b-modal="getModal4Id(data.item.id)"
            >
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">{{ $t('Cancel registration') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.registrationFilter && data.item.registrationFilter.code !== 'refunded'"
              v-b-modal="getModal3Id(data.item.id)"
              @click="getPlacementTest"
            >
              <feather-icon icon="SendIcon" />
              <span class="align-middle ml-50">{{ $t('Send Placement test') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.registrationFilter && data.item.registrationFilter.code !== 'refunded'"
              @click="regToWaitingList(data.item.id)"
            >
              <feather-icon icon="ClipboardIcon" />
              <span class="align-middle ml-50">{{ $t('Add to waiting list') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!data.item.visa_needed && data.item.registrationFilter && data.item.registrationFilter.code !== 'refunded'"
              @click="sendAdmissionLetter(data.item.id)"
            >
              <feather-icon icon="FeatherIcon" />
              <span class="align-middle ml-50">{{ $t('Send admission letter') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.paimentMethod && (data.item.paimentMethod.name === 'Subsidy (IMDI)' || data.item.paimentMethod.name === 'Subsidy (UKR)')"
              @click="proofParticipation(data.item.id)"
            >
              <feather-icon icon="ThumbsUpIcon" />
              <span class="align-middle ml-50">{{ $t('Send proof of participation') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="parseInt(data.item.attendance, 0) > 80"
              @click="generateCertificate(data.item.id)"
            >
              <feather-icon icon="AwardIcon" />
              <span class="align-middle ml-50">{{ $t('Generate certificate') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="generateContract(data.item.id)"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">{{ $t('Generate contract') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!data.item.moved_to_id && data.item.status === 'confirmed' && data.item.registrationFilter && data.item.registrationFilter.code !== 'refunded'"
              v-b-modal="getModal5Id(data.item.id)"
            >
              <feather-icon icon="MoveIcon" />
              <span class="align-middle ml-50">{{ $t('Move student') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-b-modal="getModal6Id(data.item.id)"
            >
              <feather-icon icon="MailIcon" />
              <span class="align-middle ml-50">{{ ('Send email') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                parseInt(data.item.course_price) !== 0
                  && data.item.registrationFilter
                  && (data.item.registrationFilter.code === 'paid' || data.item.registrationFilter.code === 'to_be_refunded')"
              @click="$bvModal.show(`refunModalGroup${data.item.id}`)"
            >
              <feather-icon icon="DollarSignIcon" />
              <span class="align-middle ml-50">{{ 'Refund' }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                parseInt(data.item.course_price) !== 0
                  && data.item.registrationFilter
                  && (data.item.registrationFilter.code === 'paid')"
              @click="$bvModal.show(`tobeRefundedModalGroup${data.item.id}`)"
            >
              <feather-icon icon="CornerUpLeftIcon" />
              <span class="align-middle ml-50">{{ 'To be refunded' }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!data.item.deleted_at"
              v-b-modal="getModal1Id(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('Delete') }}</span>
            </b-dropdown-item>
          </b-dropdown>
          <!-- send email modal -->
          <b-modal
            :id="'regmodal6'+data.item.id"
            :title="$t('Send email')"
            :ok-title="$t('Send')"
            centered
            size="lg"
            no-close-on-backdrop
            :ok-disabled="!emailValueSubject || !emailValueBody"
            @show="emailValueSubject = null;emailValueBody = null"
            @ok="sendEmailAction([data.item.id])"
          >
            <b-form-group
              label="Email subject"
              label-for="email-subject"
            >
              <b-form-input
                v-model="emailValueSubject"
                placeholder="Email subject"
                type="text"
              />
            </b-form-group>
            <b-form-group
              label="Email body"
              label-for="email-body"
              style="height: 260px"
            >
              <quill-editor
                v-model="emailValueBody"
                style="height: 170px"
                :options="{ modules: {} }"
              />
            </b-form-group>
          </b-modal>
          <!-- basic modal -->
          <b-modal
            :id="'regmodal1'+data.item.id"
            :title="$t('Delete Registration')"
            ok-title="Yes"
            cancel-title="No"
            centered
            @ok="remove(data.item.id)"
          >
            <b-card-text>
              <h5>{{ $t('Are you sure you want to delete') }} <strong>{{ data.item.student_name }}</strong></h5> registration from <strong>{{ data.item.course_name }}?</strong>
            </b-card-text>
          </b-modal>
          <!-- basic modal -->
          <!-- <b-modal
            :id="'regmodal1'+data.item.id"
            :title="$t('Delete Registration')"
            ok-title="Yes"
            cancel-title="No"
            centered
            @ok="remove(data.item.id)"
          >
            <b-card-text>
              <h5>{{ $t('Are you sure you want to delete') }} <strong>{{ data.item.student_name }}</strong></h5> registration from <strong>{{ data.item.course_name }}?</strong>
            </b-card-text>
          </b-modal> -->

          <!-- basic modal -->
          <b-modal
            :id="'regmodal2'+data.item.id"
            title="Confirm registration"
            ok-title="Yes"
            cancel-title="No"
            centered
            @ok="confirm(data.item.id)"
          >
            <b-card-text>
              <h5>Are you sure you want to confirm <strong>{{ data.item.student_name }}</strong>'s registration on course <strong>{{ data.item.course_group }}</strong>?</h5>
            </b-card-text>
          </b-modal>

          <!-- basic modal -->
          <b-modal
            :id="'regmodal4'+data.item.id"
            title="Confirm Cancelation"
            :ok-title="$t('Yes')"
            cancel-title="No"
            centered
            @ok="cancel(data.item.id, sendEmail)"
            @hidden="refundCancel || isTobeRefunded ? refetchData() : ''; isTobeRefunded = false"
            @show="sendEmail = false; tobeRefundedCancel = false; cancelRefund = false"
          >
            <h5 class="mb-1">
              Do you want to cancel <strong>{{ data.item.student_name }}</strong>'s registration on course <strong class="text-nowrap">{{ data.item.course_code }} {{ data.item.course_group ? `(${data.item.course_group})` : null }}</strong>?
            </h5>
            <div class="d-flex justify-content-start mb-2">
              <b-form-checkbox
                v-model="sendEmail"
              >
                Send email
              </b-form-checkbox>
              <div
                v-if="!refundCancel
                  && data.item.registrationFilter
                  && (data.item.registrationFilter.code === 'paid' || data.item.registrationFilter.code === 'to_be_refunded')
                  && parseInt(data.item.course_price) !== 0
                  && !isTobeRefunded"
                class="d-flex justify-content-start"
              >
                <span class="ml-1">|</span>
                <b-form-checkbox
                  v-model="cancelRefund"
                  class="ml-1"
                  @change="tobeRefundedCancel = false"
                >
                  Refund
                </b-form-checkbox>
                <b-form-checkbox
                  v-if="data.item.registrationFilter && data.item.registrationFilter.code === 'paid'"
                  v-model="tobeRefundedCancel"
                  class="ml-1"
                  @change="cancelRefund = false"
                >
                  To be refunded
                </b-form-checkbox>
              </div>
            </div>
            <!-- <b-alert
              v-if="refundCancel"
              class="p-1"
              show
              style="background: #ffe9d9 !important"
            >
              <div class="alert-body">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-25 text-danger"
                />
                <span class="text-secondary">
                  Attention! A refund for this registration is currently in process.
                </span>
              </div>
            </b-alert> -->
          </b-modal>
          <b-modal
            :id="'tobeRefundedModalGroup'+data.item.id"
            :title="`Set status to 'To be refunded' for ${data.item.course_code} (${data.item.course_group})`"
            ok-title="To be refunded"
            :ok-disabled="!refundReason"
            centered
            size="lg"
            no-close-on-backdrop
            @show="refundReason = null; isTobeRefunded = false"
            @ok="tobeRefunded(data.item.id, data.item.status); isTobeRefunded = true"
          >
            <b-row>
              <b-col cols="4">
                <b-form-group
                  label="Student name"
                  label-for="student-name"
                >
                  <b-form-input
                    v-model="data.item.student_name"
                    placeholder="Student name"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  label="Registration id"
                  label-for="registration-id"
                >
                  <b-form-input
                    v-model="data.item.id"
                    placeholder="Registration id"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  label="Registration status"
                  label-for="registration-status"
                >
                  <b-form-input
                    v-model="data.item.status"
                    placeholder="Registration status"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Reason for Refund"
                  label-for="registration-id"
                >
                  <b-form-textarea
                    v-model="refundReason"
                    placeholder="Reason for Refund"
                    rows="1"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-modal>

          <!-- basic modal -->
          <b-modal
            :id="'regmodal3'+data.item.id"
            title="Send placement test"
            :ok-title="$t('Send')"
            cancel-title="Cancel"
            centered
            size="lg"
            no-close-on-backdrop
            @ok="sendPTest([data.item.student_id], selectedTest.id, due_date)"
          >
            <div
              v-if="!isLoadedTests"
              class="text-center d-flex align-items-center justify-content-center"
            >
              <b-spinner
                variant="primary"
                label="Text Centered"
              />
            </div>
            <div v-if="isLoadedTests">
              <b-form-group
                :label="$t('Test')"
                label-for="test"
              >
                <v-select
                  v-model="selectedTest"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="tests"
                  :clearable="false"
                />
              </b-form-group>
              <b-form-group
                :label="$t('Due date')"
                label-for="due-date"
              >
                <flat-pickr
                  v-model="due_date"
                  class="form-control"
                  name="date"
                  :placeholder="$t('Due date')"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss', locale: { firstDayOfWeek: 1 }}"
                />
              </b-form-group>
            </div>
          </b-modal>

          <!-- basic modal -->
          <b-modal
            :id="'regmodal5'+data.item.id"
            :title="$t('Move Student')"
            ok-title="Accept"
            centered
            no-close-on-backdrop
            @ok="moveStudent(data.item.id)"
          >
            <b-card-text>
              <b-form-group
                :label="$t('Course')"
                label-for="course"
              >
                <v-select
                  v-model="selectedCourse"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="courses"
                  @keyup.native="autoCompleteCourse($event.target.value)"
                  @click.native="autoCompleteCourse('')"
                >
                  <span slot="no-options">
                    <div
                      v-if="isTypingCourse"
                      class="text-center d-flex align-items-center justify-content-center"
                    >
                      <b-spinner
                        variant="primary"
                        label="Text Centered"
                      />
                    </div>
                    <div
                      v-if="!isTypingCourse && !courses.length"
                      class="text-center d-flex align-items-center justify-content-center"
                    >
                      No matching records found
                    </div>
                  </span>
                  <template #selected-option="{ code }">
                    <span>{{ code }}</span>
                  </template>
                  <template #option="{ code, group, date_start }">
                    <div class="d-flex justify-content-between">
                      <span> {{ code }} <small
                        v-if="group"
                        class="text-secondary"
                      >({{ group }})</small></span>
                      <small>{{ date_start }}</small>
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </b-card-text>
          </b-modal>
          <!-- Update payment -->
          <b-modal
            :id="'regmodal7'+data.item.id"
            :title="('Update payment')"
            :ok-title="$t('Edit')"
            hide-footer
            size="lg"
            centered
            no-close-on-backdrop
          >
            <validation-observer
              #default="{ handleSubmit }"
              ref="form"
            >
              <!-- Form -->
              <b-form
                class="p-2"
                @submit.prevent="handleSubmit(updatePayment(payload))"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <!-- Student -->
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Student"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('Student')"
                        label-for="student"
                        :state="getValidationState(validationContext)"
                      >
                        <v-select
                          v-model="payload.student_name"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="students"
                          :clearable="false"
                          disabled
                          @keyup.native="autoCompleteStudent($event.target.value, 'student')"
                          @click.native="autoCompleteStudent('', 'student')"
                        >
                          <span slot="no-options">
                            <div
                              v-if="isTypingStudent"
                              class="text-center d-flex align-items-center justify-content-center"
                            >
                              <b-spinner
                                variant="primary"
                                label="Text Centered"
                              />
                            </div>
                            <div
                              v-if="!isTypingStudent && !students.length"
                              class="text-center d-flex align-items-center justify-content-center"
                            >
                              No matching records found
                            </div>
                          </span>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Course -->
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="course"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('Course')"
                        label-for="course"
                        :state="getValidationState(validationContext)"
                      >
                        <v-select
                          v-model="payload.course_name"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :clearable="false"
                          :options="courses"
                          disabled
                          @keyup.native="autoCompleteCourse($event.target.value)"
                          @click.native="autoCompleteCourse('')"
                        >
                          <span slot="no-options">
                            <div
                              v-if="isTypingCourse"
                              class="text-center d-flex align-items-center justify-content-center"
                            >
                              <b-spinner
                                variant="primary"
                                label="Text Centered"
                              />
                            </div>
                            <div
                              v-if="!isTypingCourse && !courses.length"
                              class="text-center d-flex align-items-center justify-content-center"
                            >
                              No matching records found
                            </div>
                          </span>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Sent date -->
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Sent date"
                    >
                      <b-form-group
                        :label="$t('Sent date')"
                        label-for="example-datePicker2"
                        :state="getValidationState(validationContext)"
                      >
                        <flat-pickr
                          v-model="payload.invoice.sent_date"
                          class="form-control sent-date"
                          name="date"
                          :config="{ altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 } }"
                          :placeholder="$t('Sent at...')"
                          disabled
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Sent date -->
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Paid date"
                    >
                      <b-form-group
                        label-for="example-datePicker3"
                        :label="$t('Paid date')"
                      >
                        <flat-pickr
                          v-model="payload.paid_date"
                          class="form-control"
                          name="date"
                          :config="{ altFormat: 'd-m-Y', altInput: true}"
                          :placeholder="$t('Paid at...')"
                          @input="errors.paid_date = []"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                        <small
                          v-if="errors"
                          class="text-danger"
                        >{{ errors.paid_date[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Method -->
                  <b-col
                    cols="12"
                    sm="4"
                  >
                    <b-form-group
                      label="Payment method"
                      label-for="payment-method"
                    >
                      <v-select
                        v-model="payload.paimentMethod"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="methodOptions.filter(el => el.id)"
                        label="name"
                        :clearable="false"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Payment status -->
                  <b-col
                    cols="12"
                    sm="4"
                  >
                    <b-form-group
                      label="Payment status"
                      label-for="payment-status"
                    >
                      <v-select
                        v-model="payload.registrationFilter"
                        label="name"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statusOptions.filter(el => el.id && !el.filtered)"
                        :clearable="false"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Payer -->
                  <b-col
                    cols="12"
                    sm="4"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Payer"
                    >
                      <b-form-group
                        label="Payer"
                        label-for="who-pays"
                        :state="getValidationState(validationContext)"
                      >
                        <v-select
                          v-model="payload.who_pays"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="payerOptions.filter(el => el.id)"
                          :clearable="false"
                          @input="payload.payer_name = null; payload.payer_email = null; payload.payer_address = null; payload.company_number; payload.company_number; payload.responsible = null; payload.payer_tel = null"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Payer name -->
                  <b-col
                    v-if="payload.who_pays && (payload.who_pays.code === 'payer' || payload.who_pays.code === 'company')"
                    cols="12"
                    sm="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Payer name"
                    >
                      <b-form-group
                        :label="('Payer name')"
                        label-for="payer-name"
                      >
                        <b-form-input
                          v-model="payload.payer_name"
                          autofocus
                          :state="getValidationState(validationContext)"
                          :placeholder="('Payer name')"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Payer address -->
                  <b-col
                    v-if="payload.who_pays && (payload.who_pays.code === 'payer' || payload.who_pays.code === 'company')"
                    cols="12"
                    sm="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Invoicing address"
                    >
                      <b-form-group
                        :label="$t('Invoicing address')"
                        label-for="invoicing-address"
                      >
                        <b-form-input
                          v-model="payload.payer_address"
                          autofocus
                          :state="getValidationState(validationContext)"
                          :placeholder="('Invoicing address')"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Company number -->
                  <b-col
                    v-if="payload.who_pays && payload.who_pays.code === 'company'"
                    cols="12"
                    sm="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Company org.number"
                    >
                      <b-form-group
                        label="Company number"
                        label-for="company-number"
                      >
                        <b-form-input
                          v-model="payload.company_number"
                          autofocus
                          :state="getValidationState(validationContext)"
                          :placeholder="('Responsible / contact person')"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Responsible -->
                  <b-col
                    v-if="payload.who_pays && payload.who_pays.code === 'company'"
                    cols="12"
                    sm="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Company org.number"
                    >
                      <b-form-group
                        label="Company number"
                        label-for="company-number"
                      >
                        <b-form-input
                          v-model="payload.responsible"
                          autofocus
                          :state="getValidationState(validationContext)"
                          :placeholder="('Responsible / contact person')"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Phone number -->
                  <b-col
                    v-if="payload.who_pays && (payload.who_pays.code === 'payer' || payload.who_pays.code === 'company')"
                    cols="12"
                    sm="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Phone number"
                    >
                      <b-form-group
                        label="Phone number"
                        label-for="phone-number"
                      >
                        <b-form-input
                          v-model="payload.payer_tel"
                          autofocus
                          :state="getValidationState(validationContext)"
                          trim
                          :placeholder="('Phone number')"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Email address -->
                  <b-col
                    v-if="payload.who_pays && (payload.who_pays.code === 'payer' || payload.who_pays.code === 'company')"
                    cols="12"
                    sm="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Email address"
                      rules="email"
                    >
                      <b-form-group
                        label="Email address"
                        label-for="email-address"
                      >
                        <b-form-input
                          v-model="payload.payer_email"
                          autofocus
                          type="email"
                          :state="getValidationState(validationContext)"
                          trim
                          :placeholder="('Email address')"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <!-- Status -->
                <!-- <b-form-group
                  :label="$t('Status')"
                  label-for="status"
                >
                  <v-select
                    v-model="data.item.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statusOptions"
                    :clearable="false"
                  />
                </b-form-group> -->

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                  >
                    {{ $t('Update') }}
                  </b-button>
                </div>

              </b-form>
            </validation-observer>
          </b-modal>
          <!-- Refund modal -->
          <b-modal
            :id="'refunModalGroup'+data.item.id"
            :title="`Refund Details for ${data.item.course_code} (${data.item.course_group})`"
            ok-title="Refund"
            :ok-disabled="!refundAmount && !refundFull || parseInt(refundAmount)>parseInt(data.item.course_price)"
            centered
            size="lg"
            no-close-on-backdrop
            @ok="submitRefund(data.item.id, data.item.status)"
            @show="refundAmount = null
                   refundReason = data.item.extra_data
                     && data.item.extra_data.refund
                     && data.item.extra_data.refund.to_be_refunded_reason ? data.item.extra_data.refund.to_be_refunded_reason : null
                   refundFull = false
                   refundComments = null"
          >
            <b-row>
              <b-col cols="6">
                <b-form-group
                  label="Student name"
                  label-for="student-name"
                >
                  <b-form-input
                    v-model="data.item.student_name"
                    placeholder="Student name"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Registration id"
                  label-for="registration-id"
                >
                  <b-form-input
                    v-model="data.item.id"
                    placeholder="Registration id"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Registration status"
                  label-for="registration-status"
                >
                  <b-form-input
                    v-model="data.item.status"
                    placeholder="Registration status"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Amount"
                  label-for="amount"
                >
                  <div class="d-flex align-items-center">
                    <b-form-input
                      v-model="refundAmount"
                      placeholder="Amount"
                      :disabled="refundFull"
                      @keypress="validateInput"
                    />
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :variant="refundFull ? 'success' : 'secondary'"
                      class="text-nowrap ml-50"
                      @click="refundFull = !refundFull; refundFull ? refundAmount = parseInt(data.item.course_price, 0).toFixed(2) : refundAmount = null"
                    >
                      <feather-icon
                        v-if="refundFull && data.item.course_price"
                        icon="CheckCircleIcon"
                      />
                      Full refund {{ parseInt(data.item.course_price, 0).toFixed(2) }} {{ $store.state.appConfig.currency }}
                    </b-button>
                    <!-- <b-form-checkbox
                      v-model="refundFull"
                      class="text-nowrap"
                    >
                      Full refund
                    </b-form-checkbox> -->
                  </div>
                  <small
                    v-if="parseInt(refundAmount) >parseInt(data.item.course_price)"
                    class="text-danger"
                  >Amount must be less than {{ parseInt(data.item.course_price, 0).toFixed(2) }}</small>
                </b-form-group>
              </b-col>
              <b-col
                v-if="refundReason"
                cols="12"
              >
                <b-form-group
                  label="Reason for Refund"
                  label-for="registration-id"
                >
                  <p class="mb-2">
                    {{ refundReason }}
                  </p>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Reason for Refund"
                  label-for="registration-id"
                >
                  <b-form-textarea
                    v-model="refundComments"
                    placeholder="Write your comments..."
                    rows="1"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-modal>
        </template>

      </b-table>
      <div
        v-if="!groupId"
        class="mx-2 mb-2"
      >
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <div
      v-if="isChatActive"
      class="notesModule shadow-lg"
    >
      <div
        style="background: #fff"
        class="pb-1"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="light"
          size="sm"
          class="btn-icon shadow-sm ml-auto "
          style="display: block; transform: translate(8px, -2px);"
          @click="isChatActive = false"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
      <chat-module
        :key="chatModelId.id"
        :model-id="chatModelId.id"
        model-type="registration"
        @notes-increament="refetchData"
        @decrease-notes="refetchData"
      />
    </div>
    <b-modal
      id="send-emailModal"
      :title="$t('Send email')"
      :ok-title="$t('Send')"
      centered
      size="lg"
      no-close-on-backdrop
      :ok-disabled="!emailValueSubject || !emailValueBody"
      @show="emailValueSubject = null;emailValueBody = null"
      @ok="multiAction('send-email')"
    >
      <b-form-group
        label="Email subject"
        label-for="email-subject"
      >
        <b-form-input
          v-model="emailValueSubject"
          placeholder="Email subject"
          type="text"
        />
      </b-form-group>
      <b-form-group
        label="Email body"
        label-for="email-body"
        style="height: 260px"
      >
        <quill-editor
          v-model="emailValueBody"
          style="height: 170px"
          :options="{ modules: {} }"
        />
      </b-form-group>
    </b-modal>
    <div
      v-if="isChatActive1"
      class="commentschats"
    >
      <feather-icon
        icon="XSquareIcon"
        size="22"
        class="align-middle text-body close-btn"
        @click="isChatActive1 = false"
      />
      <chat-module
        :conversation="conversation"
        :messages="messages"
      />
    </div>
    <b-modal
      id="cancel-registration-modal"
      title="Cancel Registration"
      :ok-title="$t('Yes')"
      cancel-title="No"
      centered
      @ok="multiAction('cancel-registration', sendEmail)"
    >
      <b-card-text>
        <h5>{{ 'Are you sure you want to cancel these registrations' }}</h5>
      </b-card-text>
      <b-form-checkbox
        v-model="sendEmail"
      >
        Send email
      </b-form-checkbox>
    </b-modal>
    <!-- basic modal -->
    <b-modal
      id="move-studentModal"
      :title="$t('Move Student')"
      :ok-title="$t('Move')"
      centered
      no-close-on-backdrop
      @ok="multiAction('move-student')"
    >
      <b-card-text>
        <b-form-group
          :label="$t('Course')"
          label-for="course"
        >
          <v-select
            v-model="selectedCourse"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="courses"
            @keyup.native="autoCompleteCourse($event.target.value)"
            @click.native="autoCompleteCourse('')"
          >
            <span slot="no-options">
              <div
                v-if="isTypingCourse"
                class="text-center d-flex align-items-center justify-content-center"
              >
                <b-spinner
                  variant="primary"
                  label="Text Centered"
                />
              </div>
              <div
                v-if="!isTypingCourse && !courses.length"
                class="text-center d-flex align-items-center justify-content-center"
              >
                No matching records found
              </div>
            </span>
            <template #selected-option="{ code }">
              <span>{{ code }}</span>
            </template>
            <template #option="{ code, group, date_start }">
              <div class="d-flex justify-content-between">
                <span> {{ code }} <small
                  v-if="group"
                  class="text-secondary"
                >({{ group }})</small></span>
                <small>{{ date_start }}</small>
              </div>
            </template>
          </v-select>
        </b-form-group>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BFormInput, BButton, BTable, BForm, BLink, VBPopover,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, BFormGroup, BFormTextarea,
  BFormCheckbox, BSpinner, VBTooltip, BFormInvalidFeedback, BAlert,
} from 'bootstrap-vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import {
  ref, onUnmounted, onMounted,
} from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// import UsersListFilters from './UsersListFilters.vue'
import ChatModule from '@/views/admin/ChatModule.vue'
import CoursesListBigModal from '@/views/courses/admin/courses-list/CoursesListBigModal.vue'
import useUsersList from './useUsersList'
import userStoreModule from '../registerStoreModule'
import UserListAddNew from './UserListAddNew.vue'
import userSelectActions from './UserSelectActions.vue'
// import RegistrationsFilter from './RegistrationsFilter.vue'

export default {
  name: 'RegistrationsList',
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BForm,

    CoursesListBigModal,
    // UsersListFilters,
    UserListAddNew,
    // RegistrationsFilter,
    userSelectActions,
    ChatModule,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
    BSpinner,
    BAlert,
    BLink,

    vSelect,
    flatPickr,
    quillEditor,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    groupId: {
      type: String,
      required: false,
      default: null,
    },
    dashboard: {
      type: Boolean,
      default: false,
    },
    studentId: {
      type: Number,
      default: null,
    },
    groupCourses: {
      type: Array,
    },
  },
  data() {
    return {
      isChatActive1: false,
      conversation: {},
      messages: {},
      required,
      email,
      students: [],
      isTypingStudent: false,
    }
  },
  mounted() {
    if (typeof this.groupId !== 'undefined') {
      this.group_id = this.groupId
    }
  },
  methods: {
    validateInput(event) {
      const charCode = (event.which) ? event.which : event.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        event.preventDefault()
      }
    },
    tbodyRowClass(item) {
      /* Style the row as needed */
      if (item && this.selectedRows.some(el => el.id === item.id)) return ['b-table-row-selected', 'cursor-pointer']
      return ['cursor-pointer']
    },
    /* eslint-disable camelcase */
    autoCompleteStudent(search, role_name) {
      this.isTypingStudent = true
      this.$http.post('/autocomplete/user', { search, role_name })
      .then(res => {
        this.isTypingStudent = false
        this.students = res.data
      })
    },
    selectAllRows() {
       this.$store.state.registerStoreModule.registrations.rows.forEach(el => {
      this.selectedRows.push(el)
      this.emailsList.push(`${el.student_name} <${el.student_email}>`)
    })
    // this.$refs.refUserListTable.selectAllRows()
    },
    copyEmails(emails) {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Emails copied',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        return navigator.clipboard.writeText(emails)
      }
      /* eslint-disable prefer-promise-reject-errors */
      return Promise.reject('The Clipboard API is not available.')
    },
    // copyEmails(emails) {
    //   this.$copyText(emails).then(() => {
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Emails copied',
    //         icon: 'CheckIcon',
    //         variant: 'success',
    //       },
    //     })
    //   }, () => {
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Can not copy!',
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //   })
    // },
    clearSelected() {
      this.selectedRows = []
      this.emailsList = []
      // this.$refs.refUserListTable.clearSelected()
    },
    handleChatClick1(id) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.$http.post('/conversation', { participants: [id], user_id: userData.id })
      .then(res => {
        this.isChatActive1 = true
        this.conversation = {
          id: res.data.id,
          avatar: res.data.avatar,
          name: res.data.name,
        }
        this.messages = {
          data: res.data.messages,
        }
      })
    },
  },
  setup(props) {
    /* eslint-disable camelcase */
    const getModal1Id = id => `regmodal1${id}`
    const getModal2Id = id => `regmodal2${id}`
    const getModal3Id = id => `regmodal3${id}`
    const getModal4Id = id => `regmodal4${id}`
    const getModal5Id = id => `regmodal5${id}`
    const getModal6Id = id => `regmodal6${id}`
    const getModal7Id = id => `regmodal7${id}`

    const USER_APP_STORE_MODULE_NAME = 'registrations_'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const query1 = ref('')
    const query2 = ref('')
    const selectedTemplate = ref({})
    const selectedCourse = ref({})
    const templates = ref([])
    const courses = ref([])
    const sendEmail = ref(false)
    // Use toast
    const toast = useToast()

    const {
      payerOptions,
      unselectedRows,
      selectAll,
      isBusy,
      getExcelDoc,
      date_range,
      isLoadingExcelDoc,
      paymentFilter,
      regStatusOptions,
      regDefaultStatus,
      fetchUsers,
      tableColumns,
      tableColumns1,
      tableColumns2,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      selectedRows,
      student,
      course,
      student_id,
      // statuss,
      payer_confirmed,
      visa,
      who_pays,
      courseId,
      group_id,
      statusOptions,
      defaultStatus,
      defaultCourse,

      // Course modal
      classrooms,
      teachers,
      masters,
      cities,
      TofDArray,
      statusArray,
      currentUser,
      suggestClassrooms,
      suggestTeacher,
      ModalData,
      currentCourseData,
      msgs,
      UsersToAdd,
      UsersAlreadyAdded,
      isBigModalActive,
      spinner,

      // UI
      resolveUserStatusVariant,
      resolveVisaStatusVariant,
    } = useUsersList()
    if (props.dashboard) { defaultStatus.value = 'pending' }
    if (props.studentId) { student_id.value = props.studentId }
    const loadSpinner = () => {
      // open
      spinner.value = true
      setTimeout(() => {
        spinner.value = false
      }, 1000)
    }
    const refundReason = ref(null)
    const refundComments = ref(null)
    const refundAmount = ref(null)
    const refundFull = ref(false)
    const refundCancel = ref(false)
    const tobeRefundedCancel = ref(false)
    const cancelRefund = ref(false)
    const isTobeRefunded = ref(false)

    const submitRefund = function (registration_id, registration_status) {
      const payload = {
        registration_id,
        amount: refundAmount.value,
        reason: refundComments.value,
        full: refundFull.value,
      }
      store.dispatch('registerStoreModule/refund', payload)
      .then(res => {
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          if (registration_status !== 'canceled') return this.$bvModal.show(`regmodal4${registration_id}`)
          return refetchData()
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
    const tobeRefunded = function (registration_id, registration_status) {
      const payload = { registration_id, reason: refundReason.value }
      store.dispatch('registerStoreModule/tobeRefunded', payload)
      .then(res => {
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        if (registration_status !== 'canceled') return this.$bvModal.show(`regmodal4${registration_id}`)
        isTobeRefunded.value = false
        return refetchData()
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
    const restoreReg = id => {
      store.dispatch('registrations_/restoreReg', id)
      .then(res => {
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
    const payload = ref({})
    const getData = item => {
      payload.value = { ...item }
      payload.value.invoice = payload.value.invoice ? payload.value.invoice : { sent_date: null }
      payload.value.who_pays = payload.value.payer
    }
    const methodOptions = ref([])
    const getPaymentMethod = () => {
      store.dispatch('registrations_/getPaymentMethod').then(res => {
        methodOptions.value = res.data.rows
        methodOptions.value.unshift({ name: 'All methods', id: null })
      })
    }
    const getRegFilters = () => {
      store.dispatch('registrations_/getRegFilters').then(res => {
        statusOptions.value = res.data.rows
        // defaultStatus.value = statusOptions.value.find(el => el.name === 'Paid')
        statusOptions.value.unshift({ name: 'All statuses', id: null })
      })
    }
    onMounted(getPaymentMethod)
    onMounted(getRegFilters)
    const emailsList = ref([])
    const onRowSelected = item => {
      const idToCheck = item.id
      if (selectedRows.value.some(el => el.id === idToCheck)) {
        // If already selected, remove it from selectedRows and add it to unselectedRows
        selectedRows.value = selectedRows.value.filter(el => el.id !== idToCheck)
        unselectedRows.value.push(item)
        emailsList.value.splice(emailsList.value.indexOf(idToCheck), 1)
      } else if (unselectedRows.value.some(el => el.id === idToCheck)) {
        // If already unselected, remove it from unselectedRows and add it to selectedRows
        unselectedRows.value = unselectedRows.value.filter(el => el.id !== idToCheck)
        selectedRows.value.push(item)
        emailsList.value.push(`${item.student_name} <${item.student_email}>`)
      } else {
        // If not selected or unselected, add it to selectedRows
        selectedRows.value.push(item)
        emailsList.value.push(`${item.student_name} <${item.student_email}>`)
      }
    }
    const tests = ref([])
    const selectedTest = ref({})
    const due_date = ref(null)
    const isLoadedTests = ref(false)
    const getPlacementTest = () => {
      store.dispatch('registrations_/getPlacementTest')
      .then(response => {
        tests.value = response.data.rows
        isLoadedTests.value = true
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while confirming registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const errors = ref({
      paid_date: [],
    })
    const markAsPaidPending = (id, paid) => {
      const item = { id, paid }
      store.dispatch('invoiceStoreModule/editInvoice', { item })
        .then(res => {
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const markAs = (id, payment_status_id) => {
      const item = { id, payment_status_id }
      store.dispatch('invoiceStoreModule/editInvoice', { item })
          .then(res => {
            toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            refetchData()
        }).catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          errors.value = error.response.data.errors
        })
    }
    // eslint-disable-next-line func-names
    const updatePayment = function (element) {
      /* eslint-disable no-nested-ternary */
      if (this.$refs.form.flags.valid) {
        const item = {
          id: element.invoice.id,
          student_id: element.student_name.id === undefined ? element.student_id : element.student_name.id,
          course_id: element.course_name.id === undefined ? element.course_id : element.course_name.id,
          sent_date: element.sent_date,
          paid_date: element.paid_date,
          paid_method_id: element.paimentMethod ? element.paimentMethod.id : null,
          // paid: element.paid ? element.paid.value : null,
          payer_id: element.who_pays ? element.who_pays.id : null,
          payer_name: element.payer_name,
          company_number: element.company_number,
          payer_address: element.payer_address,
          responsible: element.responsible,
          payer_phone: element.payer_tel,
          payer_email: element.payer_email,
          payment_status_id: element.registrationFilter ? element.registrationFilter.id : null,
          // payment_method_id: element.paimentMethod.id,
        }
        store.dispatch('invoiceStoreModule/editInvoice', { item })
          .then(res => {
            toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            refetchData()
        }).catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          errors.value = error.response.data.errors
        })
      }
    }
    const emailValueSubject = ref(null)
    const emailValueBody = ref(null)
    /* eslint-disable object-curly-newline */
    const sendEmailAction = registrations => {
      const action = 'send-email'
      const course_id = selectedCourse.value.id
      const unselected = unselectedRows.value.length > 0 ? unselectedRows.value.map(el => el.id) : []
      const isAllSelected = selectAll.value
      const subject = emailValueSubject.value
      const body = emailValueBody.value
      store.dispatch('registrations_/multiAction', { registrations, action, course_id, subject, body, unselected, isAllSelected })
      .then(response => {
        response.data.message.forEach(message => {
          toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'FrownIcon',
            variant: 'danger',
          },
        })
      })
    }
    const multiAction = (action, send_email) => {
      const registrations = selectedRows.value.length > 0 ? selectedRows.value.map(el => el.id) : []
      const unselected = unselectedRows.value.length > 0 ? unselectedRows.value.map(el => el.id) : []
      const isAllSelected = selectAll.value
      const course_id = selectedCourse.value.id
      const subject = emailValueSubject.value
      const body = emailValueBody.value
      store.dispatch('registrations_/multiAction', { registrations, action, course_id, subject, body, send_email, unselected, isAllSelected })
      .then(response => {
        response.data.message.forEach(message => {
          toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        if (action !== 'send-email' && action !== 'generate-contract') refetchData()
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'FrownIcon',
            variant: 'danger',
          },
        })
      })
    }

    const regToWaitingList = id => {
      store.dispatch('registrations_/regToWaitingList', id)
      .then(response => {
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while confirming registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const sendAdmissionLetter = id => {
      store.dispatch('registrations_/sendAdmissionLetter', id)
      .then(response => {
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while sending admission letter',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    /* eslint-disable no-shadow */
    const sendPTest = (students, placement_test_id, due_date) => {
      store.dispatch('registrations_/sendPTest', { students, placement_test_id, due_date })
      .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while confirming registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const moveStudent = studentId => {
      const course_id = selectedCourse.value.id
      store.dispatch('registrations_/moveStudent', { studentId, course_id })
      .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while confirming registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const isTypingTemplate = ref(false)
    const isTypingCourse = ref(false)
    const autoCompleteCourse = search => {
      isTypingCourse.value = true
      store.dispatch('registrations_/autoCompleteCourses', { search })
      .then(response => {
        courses.value = response.data
        isTypingCourse.value = false
        })
    }
    const autoCompleteTemplate = search => {
      isTypingTemplate.value = true
      store.dispatch('registrations_/autoComplete', search)
      .then(response => {
        templates.value = response.data
        isTypingTemplate.value = false
      })
    }

    const generateContract = id => {
      store.dispatch('registrations_/generateContract', id)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while creating contract',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const generateCertificate = genId => {
      store.dispatch('registrations_/generateCertificate', genId)
      .then(res => {
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while creating certificate registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const confirm = confId => {
      store.dispatch('registrations_/confirmRegistration', confId)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Registration Has Been confirmed',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while confirming registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }

    const cancel = function (id) {
      store.dispatch('registrations_/cancelRegistration', { id, send_email: sendEmail.value, tobeRefunded: tobeRefundedCancel.value })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Registration Has Been canceled',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          if (cancelRefund.value) return this.$bvModal.show(`refunModalGroup${id}`)
          return refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while canceling registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const proofParticipation = id => {
      store.dispatch('registrations/proofParticipation', id)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Registration Has Been removed',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while removing registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
    const remove = regId => {
      store.dispatch('registrations_/deleteRegistration', regId)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Registration Has Been removed',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while removing registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const isChatActive = ref(false)
    const chatModelId = ref(null)
    const handleChatClick = data => {
      isChatActive.value = true
      chatModelId.value = data
    }
    const isFilterActive = ref(false)
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation({})
    const formatDateTime1 = dateTime => {
      if (typeof dateTime !== 'undefined') {
        const timestamp = new Date(dateTime).getTime()
        const todate = new Date(timestamp).getDate()
        const tomonth = new Date(timestamp).getMonth() + 1
        const toyear = new Date(timestamp).getFullYear()

        const today = `0${todate.toString().slice(-2)}`
        const thisMonth = `0${tomonth.toString().slice(-2)}`
        const day = (today.length === 2 ? today : today.substr(1))
        const month = (thisMonth.length === 2 ? thisMonth : thisMonth.substr(1))

        const original_date = `${day}-${month}-${toyear}`
        return original_date
      }
      return ''
    }

    const formatDateTime = dateTime => {
      if (typeof dateTime !== 'undefined') {
        const date_ob = new Date()

// adjust 0 before single digit date
        const date = (`0 ${date_ob.getDate()}`).slice(-2)
// current month
        const month = (`0 ${date_ob.getMonth() + 1}`).slice(-2)
// current year
        const year = date_ob.getFullYear()
// current hours
        const hours = date_ob.getHours() < 10 ? `0${date_ob.getHours()}` : date_ob.getHours()
// current minutes
        const minutes = date_ob.getMinutes() < 10 ? `0${date_ob.getMinutes()}` : date_ob.getMinutes()
// current seconds
//         const seconds = date_ob.getSeconds()
// prints date & time in YYYY-MM-DD HH:MM:SS format
        /* eslint-disable-next-line */
        // console.log(year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + seconds)
        return `${date}-${month}-${year} ${hours}:${minutes}`
      }
      return ''
    }

    return {
      // Refund
      refundComments,
      submitRefund,
      refundAmount,
      refundReason,
      refundCancel,
      cancelRefund,
      tobeRefundedCancel,
      refundFull,
      isTobeRefunded,
      tobeRefunded,

      payerOptions,
      proofParticipation,
      restoreReg,
      unselectedRows,
      selectAll,
      isBusy,
      getExcelDoc,
      date_range,
      isLoadingExcelDoc,
      regStatusOptions,
      regDefaultStatus,
      paymentFilter,
      getData,
      payload,
      methodOptions,
      getPaymentMethod,
      getRegFilters,

      refFormObserver,
      getValidationState,
      resetForm,

      errors,
      markAsPaidPending,
      updatePayment,
      markAs,

      sendEmailAction,
      emailValueSubject,
      emailValueBody,
      emailsList,
      // course modal
      isBigModalActive,
      classrooms,
      teachers,
      masters,
      cities,
      TofDArray,
      statusArray,
      UsersToAdd,
      currentUser,
      suggestClassrooms,
      suggestTeacher,
      ModalData,
      UsersAlreadyAdded,
      spinner,
      loadSpinner,
      currentCourseData,
      msgs,

      onRowSelected,

      tests,
      selectedTest,
      due_date,
      isLoadedTests,
      getPlacementTest,
      sendAdmissionLetter,

      // Modal ids
      getModal1Id,
      getModal2Id,
      getModal3Id,
      getModal4Id,
      getModal5Id,
      getModal6Id,
      getModal7Id,

      isTypingTemplate,
      isTypingCourse,

      student,
      course,
      // statuss,
      payer_confirmed,
      visa,
      who_pays,

      isChatActive,
      chatModelId,
      handleChatClick,

      // Actions..
      remove,
      cancel,
      confirm,
      generateCertificate,
      generateContract,
      moveStudent,
      sendPTest,
      regToWaitingList,
      selectedRows,
      multiAction,

      isFilterActive,

      // autocomplete
      query1,
      query2,
      selectedTemplate,
      selectedCourse,
      templates,
      courses,
      sendEmail,
      autoCompleteTemplate,
      autoCompleteCourse,
      formatDateTime,
      formatDateTime1,

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      tableColumns1,
      tableColumns2,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      courseId,
      group_id,
      statusOptions,
      defaultStatus,
      defaultCourse,

      // UI
      resolveUserStatusVariant,
      resolveVisaStatusVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.list-filter {
  width: 233px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
