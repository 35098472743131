<template>
  <b-modal
    id="modal-center"
    :visible="isAddActive"
    title="Add New Course"
    footer-class="d-flex justify-content-between"
    size="lg"
    static
    scrollable
    @change="(val) => $emit('update:isAddActive', val)"
  >
    <template #modal-footer>
      <!-- submit and reset -->
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
          @click="createCourse"
        >
          Create
        </b-button>
      </b-col>
    </template>
    <b-form @submit.prevent>
      <b-container>
        <b-row>
          <b-col>
            <b-form-group
              label="Group"
              label-for="group"
            >
              <b-form-input
                id="group"
                v-model="record.group"
                placeholder="Course group"
                class="input"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Price"
              label-for="price"
            >
              <b-form-input
                id="price"
                v-model="record.price"
                type="number"
                placeholder="Course price"
                class="input"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Hours"
              label-for="hours"
            >
              <b-form-input
                id="hours"
                v-model="record.hours"
                placeholder="Total hours"
                class="input"
                type="number"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Days"
              label-for="days"
            >
              <b-form-checkbox-group
                id="days"
                v-model="selectedDays"
                :options="days"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col>
            <b-form-group
              label="Mastercourse"
              label-for="mastercourse"
            >
              <v-select
                id="mastercourse"
                v-model="record.mastercourse_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="masters"
                :reduce="x => x.id"
                label="name"
                placeholder="choose course Master"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Classroom"
              label-for="classroom"
            >
              <v-select
                id="classroom"
                v-model="record.classroom_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="classrooms"
                :reduce="x => x.id"
                label="name"
                placeholder="choose course classroom"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Teacher"
              label-for="teacher"
            >
              <v-select
                id="teacher"
                v-model="record.teacher_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="teachers"
                :reduce="x => x.id"
                placeholder="choose course teacher"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Students limit"
              label-for="studentslimit"
            >
              <b-form-input
                id="studentslimit"
                v-model="record.students_limit"
                type="number"
                placeholder="Students limit"
                class="input"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col>
            <b-form-group
              label="Sessions"
              label-for="sessions"
            >
              <b-form-input
                id="sessions"
                v-model="record.attendancecolumns"
                type="number"
                placeholder="Sessions Number (attendance columns)"
                class="input"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Date Start"
              label-for="dstart"
            >
              <flat-pickr
                id="dstart"
                v-model="record.date_start"
                placeholder="Date start of the course"
                class="form-control input"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Date End"
              label-for="dend"
            >
              <flat-pickr
                id="dend"
                v-model="record.date_end"
                placeholder="Date end of the course"
                class="form-control input"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Start time"
              label-for="st"
            >
              <flat-pickr
                id="st"
                v-model="record.time_from"
                placeholder="When it starts"
                class="form-control input"
                :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="End time"
              label-for="et"
            >
              <flat-pickr
                id="et"
                v-model="record.time_to"
                placeholder="When it ends"
                class="form-control input"
                :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col>
            <b-form-group
              label="Status"
              label-for="status"
            >
              <v-select
                id="status"
                v-model="record.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="statusArray"
                :reduce="x => x.value"
                placeholder="Status"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </b-modal>
</template>

<script>
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow, BCol, BFormCheckboxGroup, BContainer, BForm, BFormGroup, BModal, BFormInput, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
// import { onMounted } from 'vue'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckboxGroup,
    BContainer,
    BForm,
    BFormGroup,
    BModal,
    BFormInput,
    BButton,
    vSelect,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    isAddActive: {
      type: Boolean,
      required: true,
    },
    masters: {
      type: Array,
      required: true,
    },
    cities: {
      type: Array,
      required: true,
    },
    classrooms: {
      type: Array,
      required: true,
    },
    teachers: {
      type: Array,
      required: true,
    },
    statusArray: {
      type: Array,
      required: true,
    },
    TofDArray: {
      type: Array,
      required: true,
    },
    currentCourseData: {
      type: Object,
    },
  },
  mounted() {
    if (typeof this.currentCourseData !== 'undefined') {
      this.record = this.currentCourseData
      this.record.price = Math.round(this.record.price)
      this.selectedDays = this.currentCourseData.days.toString().split(',')
      this.isAddSimilar = true
    }
    //  @todo:check days
  },
  setup(props, { emit }) {
    const toast = useToast()
    const record = ref({
      group: '',
      mastercourse_id: '',
      classroom_id: '',
      date_start: '',
      date_end: '',
      time_from: '',
      time_to: '',
      days: '',
      hours: '',
      teacher_id: '',
      price: '',
      status: '',
      approved: 'pending',
      attendancecolumns: '',
      students_limit: '',
    })
    const isAddSimilar = ref(false)
    const selectedDays = ref([])
    const days = ref([
      { text: 'Sunday', value: 'su' },
      { text: 'Monday', value: 'mo' },
      { text: 'Tuesday', value: 'tu' },
      { text: 'Wednesday', value: 'we' },
      { text: 'Thursday', value: 'th' },
      { text: 'Friday', value: 'fr' },
      { text: 'Saturday', value: 'sa' },
    ])
    const createCourse = () => {
      record.value.days = selectedDays.value.toString()
      store.dispatch('app-courses/storeCourse', record.value)
      .then(() => {
        emit('refetch-data')
        emit('update:isAddActive', false)
        toast({
            component: ToastificationContent,
            props: {
            title: 'Created successfully',
            icon: 'CheckIcon',
            variant: 'success',
            },
        })
      }).catch(error => {
          toast({
              component: ToastificationContent,
              props: {
              title: error,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              },
          })
      })
    }

    return {
      createCourse,
      selectedDays,
      isAddSimilar,
      days,
      record,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
