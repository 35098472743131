<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Registrations Per Age</b-card-title>
      <!-- <b-dropdown
        text="Last 7 Days"
        variant="transparent"
        class="chart-dropdown "
        right
        no-caret
        toggle-class="p-0 mb-25"
        size="sm"
      >
        <b-dropdown-item
          v-for="day in chartData.lastDays"
          :key="day"
        >
          {{ day }}
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>

    <b-card-body>

      <!-- apex chart -->
      <vue-apex-charts
        type="pie"
        height="325"
        class="mt-2 mb-1"
        :options="customersPie.chartOptions"
        :series="customersPie.series"
      />

      <!-- list group -->
      <b-row>
        <b-col cols="5">
          <div
            v-for="(label, index) in customersPie.chartOptions.labels"
            :key="index"
          >
            <feather-icon
              icon="CircleIcon"
              size="16"
              class="text-primary"
            />
            <span class="font-weight-bolder ml-75 mr-25">{{ label }}</span>
          </div>
        </b-col>
        <b-col>
          <div
            v-for="(data,index) in customersPie.series"
            :key="index"
          >
            <span>{{ data }}</span>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    BCardBody,
    VueApexCharts,
  },
  props: {
    customersPie: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartData: {},
    }
  },
}
</script>
