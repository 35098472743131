// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

export default function userCalendar() {
  /* eslint-disable camelcase */
  // Use toast
  const toast = useToast()
  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null)
  const bindClick = () => {
    document.getElementsByClassName('statfull').forEach(el => {
      el.addEventListener('click', getClassroom)
    })
  }
  bindClick()
  const getClassroom = () => {
    // const classData = document.getElementsByClassName('statfull')[0].getAttribute('data-classroom')
    // console.log(classData)
  }

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null
  onMounted(() => {
    calendarApi = refCalendar.value.getApi()
  })

  // ------------------------------------------------
  // calendars
  // ------------------------------------------------
  const calendarsColor = {
    // available: 'success',
    // Holiday: 'success',
    // Personal: 'danger',
    // Family: 'warning',
    unavailable: 'danger',
  }

  // ------------------------------------------------
  // event
  // ------------------------------------------------
  const blankEvent = {
    id: null,
    title: '',
    start: '',
    end: '',
    allDay: false,
    url: '',
    extendedProps: {
      calendar: '',
      guests: [],
      location: '',
      description: '',
    },
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  // *===========================================================================---*
  // *--------- Calendar API Function/Utils --------------------------------------------*
  // Template Future Update: We might move this utils function in its own file
  // *===========================================================================---*

  // ------------------------------------------------
  // (UI) addEventInCalendar
  // ? This is useless because this just add event in calendar and not in our data
  // * If we try to call it on new event then callback & try to toggle from calendar we get two events => One from UI and one from data
  // ------------------------------------------------
  // const addEventInCalendar = eventData => {
  //   toast({
  //     component: ToastificationContent,
  //     position: 'bottom-right',
  //     props: {
  //       title: 'Event Added',
  //       icon: 'CheckIcon',
  //       variant: 'success',
  //     },
  //   })
  //   calendarApi.addEvent(eventData)
  // }

  // ------------------------------------------------
  // (UI) updateEventInCalendar
  // ------------------------------------------------
  const updateEventInCalendar = (updatedEventData, propsToUpdate, extendedPropsToUpdate) => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Updated',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })

    const existingEvent = calendarApi.getEventById(updatedEventData.id)

    // --- Set event properties except date related ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setProp
    // dateRelatedProps => ['start', 'end', 'allDay']
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < propsToUpdate.length; index++) {
      const propName = propsToUpdate[index]
      existingEvent.setProp(propName, updatedEventData[propName])
    }

    // --- Set date related props ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setDates
    existingEvent.setDates(updatedEventData.start, updatedEventData.end, { allDay: updatedEventData.allDay })

    // --- Set event's extendedProps ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < extendedPropsToUpdate.length; index++) {
      const propName = extendedPropsToUpdate[index]
      existingEvent.setExtendedProp(propName, updatedEventData.extendedProps[propName])
    }
  }

  // ------------------------------------------------
  // (UI) removeEventInCalendar
  // ------------------------------------------------
  const removeEventInCalendar = eventId => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Removed',
        icon: 'TrashIcon',
        variant: 'danger',
      },
    })
    calendarApi.getEventById(eventId).remove()
  }

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = eventApi => {
    const {
      id,
      title,
      start,
      end,
      // eslint-disable-next-line object-curly-newline
      extendedProps: { calendar, guests, location, description },
      allDay,
    } = eventApi

    return {
      id,
      title,
      start,
      end,
      extendedProps: {
        calendar,
        guests,
        location,
        description,
      },
      allDay,
    }
  }

  // ------------------------------------------------
  // addEvent
  // ------------------------------------------------
  const addEvent = eventData => {
    store.dispatch('calendar/addEvent', { event: eventData }).then(() => {
      // eslint-disable-next-line no-use-before-define
      refetchEvents()
    })
  }

  // ------------------------------------------------
  // updateEvent
  // ------------------------------------------------
  const updateEvent = eventData => {
    store.dispatch('calendar/updateEvent', { event: eventData }).then(response => {
      const updatedEvent = response.data.event

      const propsToUpdate = ['id', 'title', 'url']
      const extendedPropsToUpdate = ['calendar', 'guests', 'location', 'description']

      updateEventInCalendar(updatedEvent, propsToUpdate, extendedPropsToUpdate)
    })
  }

  // ------------------------------------------------
  // removeEvent
  // ------------------------------------------------
  const removeEvent = () => {
    const eventId = event.value.id
    store.dispatch('calendar/removeEvent', { id: eventId }).then(() => {
      removeEventInCalendar(eventId)
    })
  }

  // ------------------------------------------------
  // refetchEvents
  // ------------------------------------------------
  const refetchEvents = () => {
    calendarApi.refetchEvents()
  }

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed(() => store.state.calendar.selectedCalendars)

  const classroomOptions = ref([{ name: 'All' }])
  const selectedClassroom = ref({ name: 'All' })
  const classroom_id = ref(null)
  const isBusyCalendar = ref(true)
  const selectedFilter = ref({ name: 'All', value: 'all' })
  const classroomFilters = ref([{ name: 'All', value: 'all' }, { name: 'Available', value: 'available' }, { name: 'Unavailable', value: 'unavailable' }])

  // --------------------------------------------------------------------------------------------------
  // AXIOS: fetchEvents
  // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
  // --------------------------------------------------------------------------------------------------
  const urlQueries = router.currentRoute.query
  if (urlQueries.filter) selectedFilter.value = classroomFilters.value.find(el => el.value === urlQueries.filter)
  const getClassrooms = () => {
    store.dispatch('calendar/getClassrooms').then(res => {
      classroomOptions.value = res.data.rows
      classroomOptions.value.unshift({ name: 'All' })
      if (urlQueries.classroom) {
        classroom_id.value = parseInt(urlQueries.classroom, 0)
        selectedClassroom.value = classroomOptions.value.find(el => el.id === parseInt(urlQueries.classroom, 0))
      }
      // classroomOptions.value = res.data.rows
    })
  }
  const urlParams = new URLSearchParams()
  const fetchEvents = (info, successCallback) => {
    isBusyCalendar.value = true
    // If there's no info => Don't make useless API call
    if (!info) return
    urlParams.set('?date_start', info.startStr ? info.startStr : '')
    urlParams.set('filter', selectedFilter.value ? selectedFilter.value.value : '')
    urlParams.set('classroom', classroom_id.value ? classroom_id.value : '')
    window.history.replaceState(null, null, decodeURIComponent(urlParams))
    /* eslint-disable prefer-template */
    const dateStart = info.startStr.split('T')[0] + ' ' + info.startStr.split('T')[1].split('+')[0]
    const dateEnd = info.endStr.split('T')[0] + ' ' + info.endStr.split('T')[1].split('+')[0]

    // Fetch Events from API endpoint
    store
      .dispatch('calendar/fetchEvents', {
        // status: selectedCalendars.value,
        date_start: dateStart,
        date_end: dateEnd,
        classroom_id: classroom_id.value,
        filter: selectedFilter.value.value,
      })
      .then(response => {
        /* eslint-disable no-use-before-define */
        isBusyCalendar.value = false
        successCallback(response.data)
      })
      .catch(() => {
        isBusyCalendar.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching classrooms calendar',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  const handleClick = () => {
    console.log('hey')
  }
  const courseGroup = ref(null)
  const isModalActive = ref(false)
  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: 'listMonth',
    headerToolbar: {
      start: 'sidebarToggle, prev,next, title',
      end: 'dateScroller, dayGridMonth,timeGridWeek,timeGridDay,listMonth',
    },
    firstDay: [1],
    hiddenDays: [0],
    events: fetchEvents,
    // eventContent: { html: `<span>${fetchEvents}</span>` },
    eventContent: arg => {
      return {
        html: `${arg.event.title}`,
      }
    },
    // console.log(arg.event.title.split('<span>'))
    selectable: true,
    initialDate: router.currentRoute.query.date_start ? router.currentRoute.query.date_start.split('T')[0] : new Date(),

    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    // editable: true,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 5,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,
    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]

      return [
        // Background Color
        `bg-light-${colorName}`,
      ]
    },
    eventClick({ event: clickedEvent }) {
      /* eslint-disable-next-line no-unused-expressions */
      (selectedClassroom.value === 'All' ? isEventModalActive.value = false : isEventModalActive.value = true)
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      event.value = grabEventDataFromEventApi(clickedEvent)
      /* eslint-disable prefer-destructuring */
      // if (selectedClassroom.value === 'All') {
      //   // console.log(event.value.title.split('"')[5])
      //   console.log(event.value.title.split('"'))
      //   classroom_id.value = parseInt(event.value.title.split('"')[5], 10)
      //   classroomOptions.value.forEach(el => {
      //     console.log(el.id, classroom_id.value)
      //     if (classroom_id.value === el.id) {
      //       selectedClassroom.value = el
      //       console.log(selectedClassroom.value)
      //     }
      //   })
        // courseGroup.value = event.value.title.split('"')[3]
      // }
      const htmlString = event.value.title
      const parser = new DOMParser()
      const doc = parser.parseFromString(htmlString, 'text/html')

      // const group = doc.querySelector('span').getAttribute('data-group')
      // const classroom = doc.querySelector('span').getAttribute('data-classroom')
      const courseId = doc.querySelector('span').getAttribute('data-course-id')
      if (courseId) {
        // courseGroup.value = group
        ModalData(courseId, 'group')
      }
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = false
      // isModalActive = true
    },
    eventMouseEnter(hoveredEvent) {
      hoveredEvent.el.classList.add('fc-event-default')
    },

    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'sidebar',
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
        },
      },
      dateScroller: {
        text: 'Today',
        click() {
          let today = new Date()
          const dd = String(today.getDate()).padStart(2, '0')
          const mm = String(today.getMonth() + 1).padStart(2, '0')
          const yyyy = today.getFullYear()

          today = `${yyyy}-${mm}-${dd}`
          console.log(today)
          const dateAttr = `[data-date="${today}"]`
          console.log(dateAttr)
          const element = document.querySelector(dateAttr)
          element.scrollIntoView()
        },
      },
    },

    dateClick(info) {
      /*
        ! Vue3 Change
        Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */
      event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { start: info.date })))
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
      isEventModalActive.value = true
      // isModalActive = true
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      updateEvent(grabEventDataFromEventApi(droppedEvent))
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      updateEvent(grabEventDataFromEventApi(resizedEvent))
    },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,
  })
  watch(selectedCalendars, () => {
    refetchEvents()
  })
  watch(selectedFilter, () => {
    refetchEvents()
  })
  watch(classroom_id, () => {
    refetchEvents()
    // selectedCalendars.value = store.state.calendar.selectedCalendars
    // store.commit('calendar/SET_SELECTED_EVENTS', ['Available', 'Unavailable'])
    // calendarOptions.value.events = fetchEvents
  })

  // ------------------------------------------------------------------------

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const isEventHandlerSidebarActive = ref(false)
  const isEventModalActive = ref(false)
  const isGroupModalActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)

  const currentCourseData = ref(null)
  const isBigModalActive = ref(false)
  const masters = ref([])
  const ModalData = (id, tab) => {
    store.dispatch('coursesStoreModule/getSCourse', id)
      .then(() => {
        currentCourseData.value = store.state.coursesStoreModule.singleCourse.data
        currentCourseData.value.pendingTeachers = store.state.coursesStoreModule.singleCourse.pendingTeachers
        if (store.state.coursesStoreModule.singleCourse.currentTeacher) { currentCourseData.value.currentTeacher = store.state.coursesStoreModule.singleCourse.currentTeacher }
        currentCourseData.value.tab = tab
        currentCourseData.value.days = currentCourseData.value.days ? currentCourseData.value.days.split(',') : []
        isBigModalActive.value = true
      }).catch(error => console.log(error))
  }
  const fetchMCourses = () => {
    store.dispatch('coursesStoreModule/fetchMCourses', { group: null, perPage: 500, sortBy: 'code' })
      .then(() => {
        masters.value = store.state.coursesStoreModule.Mcourses
      })
  }
  onMounted(fetchMCourses)

  return {
    isBigModalActive,
    fetchMCourses,
    masters,
    ModalData,
    currentCourseData,
    bindClick,
    getClassroom,
    refCalendar,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    classroomOptions,
    selectedClassroom,
    classroom_id,
    event,
    clearEventData,
    addEvent,
    updateEvent,
    removeEvent,
    refetchEvents,
    fetchEvents,
    getClassrooms,
    courseGroup,
    isGroupModalActive,

    selectedFilter,
    classroomFilters,

    isBusyCalendar,
    handleClick,

    // ----- UI ----- //
    isEventHandlerSidebarActive,
    isEventModalActive,
    isModalActive,
  }
}
