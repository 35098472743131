// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

export default function userCalendar() {
  /* eslint-disable camelcase, brace-style */
  // Use toast
  const toast = useToast()
  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null)

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null
  onMounted(() => {
    calendarApi = refCalendar.value.getApi()
  })

  // ------------------------------------------------
  // calendars
  // ------------------------------------------------
  const calendarsColor = {
    available: 'success',
    course: 'primary',
    event: 'warning',
    // day_off: 'success',
    // Personal: 'danger',
    // Family: 'warning',
    unavailable: 'danger',
  }

  // ------------------------------------------------
  // event
  // ------------------------------------------------
  const blankEvent = {
    id: null,
    title: '',
    start: '',
    end: '',
    allDay: false,
    url: '',
    extendedProps: {
      calendar: '',
      session_id: null,
      shallUpdate: null,
      guests: [],
      location: '',
      description: '',
    },
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  // *===========================================================================---*
  // *--------- Calendar API Function/Utils --------------------------------------------*
  // Template Future Update: We might move this utils function in its own file
  // *===========================================================================---*

  // ------------------------------------------------
  // (UI) addEventInCalendar
  // ? This is useless because this just add event in calendar and not in our data
  // * If we try to call it on new event then callback & try to toggle from calendar we get two events => One from UI and one from data
  // ------------------------------------------------
  // const addEventInCalendar = eventData => {
  //   toast({
  //     component: ToastificationContent,
  //     position: 'bottom-right',
  //     props: {
  //       title: 'Event Added',
  //       icon: 'CheckIcon',
  //       variant: 'success',
  //     },
  //   })
  //   calendarApi.addEvent(eventData)
  // }

  // ------------------------------------------------
  // (UI) updateEventInCalendar
  // ------------------------------------------------
  const updateEventInCalendar = (updatedEventData, propsToUpdate, extendedPropsToUpdate) => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Updated',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })

    const existingEvent = calendarApi.getEventById(updatedEventData.id)

    // --- Set event properties except date related ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setProp
    // dateRelatedProps => ['start', 'end', 'allDay']
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < propsToUpdate.length; index++) {
      const propName = propsToUpdate[index]
      existingEvent.setProp(propName, updatedEventData[propName])
    }

    // --- Set date related props ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setDates
    existingEvent.setDates(updatedEventData.start, updatedEventData.end, { allDay: updatedEventData.allDay })

    // --- Set event's extendedProps ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < extendedPropsToUpdate.length; index++) {
      const propName = extendedPropsToUpdate[index]
      existingEvent.setExtendedProp(propName, updatedEventData.extendedProps[propName])
    }
  }

  // ------------------------------------------------
  // (UI) removeEventInCalendar
  // ------------------------------------------------
  const removeEventInCalendar = eventId => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Removed',
        icon: 'TrashIcon',
        variant: 'danger',
      },
    })
    calendarApi.getEventById(eventId).remove()
  }

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = eventApi => {
    const {
      id,
      title,
      start,
      end,
      // eslint-disable-next-line object-curly-newline
      extendedProps: { calendar, guests, location, description, session_id, shallUpdate },
      allDay,
    } = eventApi

    return {
      id,
      title,
      start,
      end,
      extendedProps: {
        calendar,
        guests,
        location,
        description,
        session_id,
        shallUpdate,
      },
      allDay,
    }
  }

  // ------------------------------------------------
  // addEvent
  // ------------------------------------------------
  /* eslint-disable no-use-before-define */
  const addEvent = eventData => {
    store.dispatch('calendar/addEvent', { event: eventData }).then(() => {
      refetchEvents()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Holiday request is sent',
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error sending event',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      // eslint-disable-next-line no-use-before-define
    })
  }

  // ------------------------------------------------
  // updateEvent
  // ------------------------------------------------
  const updateEvent = eventData => {
    store.dispatch('calendar/updateEvent', { event: eventData }).then(response => {
      const updatedEvent = response.data.event

      const propsToUpdate = ['id', 'title', 'url']
      const extendedPropsToUpdate = ['calendar', 'guests', 'location', 'description']

      updateEventInCalendar(updatedEvent, propsToUpdate, extendedPropsToUpdate)
    })
  }

  // ------------------------------------------------
  // removeEvent
  // ------------------------------------------------
  const removeEvent = () => {
    const eventId = event.value.id
    store.dispatch('calendar/removeEvent', { id: eventId }).then(() => {
      removeEventInCalendar(eventId)
    })
  }

  // ------------------------------------------------
  // refetchEvents
  // ------------------------------------------------
  const refetchEvents = () => {
    calendarApi.refetchEvents()
  }

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const urlQueries = router.currentRoute.query
  if (urlQueries.calendar) store.state.calendar.selectedCalendars = urlQueries.calendar.split(',')
  const selectedCalendars = computed(() => store.state.calendar.selectedCalendars)
  const selectedLevels = computed(() => store.state.calendar.selectedLevels)

  const teachersOptions = ref([])
  const levelsOptions = ref([
    { label: 'All levels', value: 'all' },
    { label: 'A1-A2', value: 'A1-A2' },
    { label: 'B1', value: 'B1' },
    { label: 'B2', value: 'B2' },
    { label: 'C1', value: 'C1' },
  ])
  // const selectedClassroom = ref('All')
  const selectedLevel = ref({ label: 'All levels', value: 'all' })
  const selectedTeacher = ref({})
  const teacher_id = ref(null)
  const isBusyCalendar = ref(true)

  // --------------------------------------------------------------------------------------------------
  // AXIOS: fetchEvents
  // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
  // --------------------------------------------------------------------------------------------------
  if (urlQueries.calendar) console.log(urlQueries.calendar.split(','))
  const urlParams = new URLSearchParams()
  const fetchEvents = (info, successCallback) => {
    // If there's no info => Don't make useless API call
    if (!info) return
    urlParams.set('?date_start', info.startStr ? info.startStr : '')
    urlParams.set('calendar', selectedCalendars.value ? selectedCalendars.value : '')
    window.history.replaceState(null, null, decodeURIComponent(urlParams))
    isBusyCalendar.value = true
    /* eslint-disable prefer-template */
    const dateStart = info.startStr.split('T')[0] + ' ' + info.startStr.split('T')[1].split('+')[0]
    const dateEnd = info.endStr.split('T')[0] + ' ' + info.endStr.split('T')[1].split('+')[0]

    // Fetch Events from API endpoint
    store
      .dispatch('calendar/fetchEvents', {
        // status: selectedCalendars.value,
        date_start: dateStart,
        date_end: dateEnd,
        teacher_id: teacher_id.value,
        filter: selectedCalendars.value,
      })
      .then(response => {
        /* eslint-disable no-use-before-define */
        isBusyCalendar.value = false
        successCallback(response.data)
      })
      .catch(() => {
        isBusyCalendar.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching attendance calendar',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: 'listMonth',
    headerToolbar: {
      start: 'sidebarToggle, prev,next, title',
      end: 'dateScroller, dayGridMonth,listMonth',
    },
    firstDay: [1],
    hiddenDays: [0],
    events: fetchEvents,
    selectable: true,
    initialDate: urlQueries.date_start ? urlQueries.date_start.split('T')[0] : new Date(),
    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    // editable: true,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 5,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]

      return [
        // Background Color
        `bg-light-${colorName}`,
      ]
    },
    eventClick({ event: clickedEvent }) {
      /* eslint-disable-next-line no-unused-expressions */
      // (selectedClassroom.value === 'All' ? isEventModalActive.value = false : isEventModalActive.value = true)
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      event.value = grabEventDataFromEventApi(clickedEvent)
      if (event.value.extendedProps.session_id && event.value.extendedProps.shallUpdate) {
        isAttendanceModalActive.value = true
      }

      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
      // isModalActive = true
    },
    eventMouseEnter(hoveredEvent) {
      if (hoveredEvent.event.extendedProps.day_off) {
        hoveredEvent.el.classList.add('fc-event-default')
        const eventElements = hoveredEvent.el.querySelectorAll('*')
        eventElements.forEach(element => {
          element.classList.add('fc-event-default')
        })
      }
    },
    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'sidebar',
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
        },
      },
      dateScroller: {
        text: 'Today',
        click() {
          calendarApi.today()
          let today = new Date()
          const dd = String(today.getDate()).padStart(2, '0')
          const mm = String(today.getMonth() + 1).padStart(2, '0')
          const yyyy = today.getFullYear()

          today = `${yyyy}-${mm}-${dd}`
          const dateAttr = `[data-date="${today}"]`
          const element = document.querySelector(dateAttr)
          element.scrollIntoView()
        },
      },
    },

    dateClick(info) {
      /*
        ! Vue3 Change
        Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */
      event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { start: info.date })))
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
      isEventModalActive.value = true
      // isModalActive = true
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      updateEvent(grabEventDataFromEventApi(droppedEvent))
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      updateEvent(grabEventDataFromEventApi(resizedEvent))
    },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,
  })
  watch([selectedCalendars, selectedLevels], () => {
    refetchEvents()
  })
  watch(teacher_id, () => {
    // selectedCalendars.value = store.state.calendar.selectedCalendars
    store.commit('calendar/SET_SELECTED_EVENTS', ['Event', 'Success'])
    calendarOptions.value.events = fetchEvents
  })

  // ------------------------------------------------------------------------

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const isEventHandlerSidebarActive = ref(false)
  const isEventModalActive = ref(false)
  const isModalActive = ref(false)
  const isAttendanceModalActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)

  return {
    refCalendar,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    teachersOptions,
    levelsOptions,

    // selectedClassroom,
    selectedTeacher,
    selectedLevel,
    teacher_id,
    event,
    clearEventData,
    addEvent,
    updateEvent,
    removeEvent,
    refetchEvents,
    fetchEvents,
    isBusyCalendar,

    // ----- UI ----- //
    isAttendanceModalActive,
    isEventHandlerSidebarActive,
    isEventModalActive,
    isModalActive,
  }
}
