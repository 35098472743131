import axios from '@axios'

export default {
  namespaced: true,
  state: {
    classrooms: {
      rows: [],
      totalRecords: 0,
    },
  },
  getters: {},
  /* eslint-disable camelcase */
  mutations: {
    setClassrooms(state, users) {
      state.classrooms = users
    },
    deleteClassroom(state, userId) {
      const regData = {
        rows: state.classrooms.rows.filter(reg => reg.id !== userId),
        totalRecords: state.classrooms.totalRecords - 1,
      }
      state.classrooms = regData
    },
  },
  actions: {
    fetchClassrooms(store, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/classroom-list/data', { params: queryParams })
          .then(response => {
            store.commit('setClassrooms', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/get-user/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addClassroom(ctx, classroomData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/classrooms', classroomData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put('/user/update', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteClassroom(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/classrooms/${id}`)
          .then(response => {
            store.commit('deleteClassroom', id)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    editClassroom(store, { id, payload }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/classrooms/${id}`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    confirmRegistration(store, regId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/classrooms/confirm-reg/${regId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelRegistration(store, cancelId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/classrooms/cancel-reg/${cancelId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    generateCertificate(store, { genId, template_id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/classrooms/generate-certificate/${genId}`, { template_id }, { responseType: 'blob' })
          .then(response => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')

            fileLink.href = fileURL
            fileLink.setAttribute('download', 'file.pdf')
            document.body.appendChild(fileLink)

            fileLink.click()
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    printInvoice(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/invoice/print/${id}`, {}, { responseType: 'blob' })
          .then(response => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')

            fileLink.href = fileURL
            fileLink.setAttribute('download', 'file.pdf')
            document.body.appendChild(fileLink)

            fileLink.click()
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    moveStudent(store, { studentId, course_id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/classrooms/move-student/${studentId}`, { course_id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendPTest(store, pId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/classrooms/send-p-test/${pId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    regToWaitingList(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/classrooms/waiting-list/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    autoCompleteCity(store, search) {
      return new Promise((resolve, reject) => {
        axios
          .post('/city/autocomplete', search)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    autoCompleteCourses(store, search) {
      return new Promise((resolve, reject) => {
        axios
          .post('/autocomplete/course', search)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
