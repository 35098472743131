<template>
  <div>
    <!-- Account Notification -->
    <b-link
      v-for="(notification, idx) in messages"
      :key="idx"
      :to="sendToRoute(notification)"
      @click="$emit('close-notification')"
    >
      <b-media class="bg-messages">
        <template #aside>
          <b-avatar
            size="32"
            :src="notification.avatar"
            :variant="notification.type"
          />
        </template>
        <p class="media-heading">
          <span class="font-weight-bolder">
            {{ notification.title }}
          </span>
        </p>
        <small class="notification-text">{{ notification.subtitle }} . {{ notification.date }}</small>
        <b-badge
          v-if="notification.unread"
          pill
          variant="primary"
          class="float-right"
        >
          {{ notification.unread }}
      </b-badge>
      </b-media>
    </b-link>
  </div>
</template>

<script>
import {
  BMedia, BLink, BAvatar, BBadge, /* BFormCheckbox */
} from 'bootstrap-vue'

export default {
  name: 'MessageElement',
  components: {
    BMedia,
    BLink,
    BAvatar,
    BBadge,
  },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    sendToRoute(notification) {
      return { name: (notification.type === 'chat' || !notification.type) ? 'apps-chat' : 'apps-support-center', params: { id: notification.conversation_id } }
    },
  },
}
</script>

<style scoped>
.bg-messages:hover {
  background-color: #E2DEDE !important;
}
</style>
