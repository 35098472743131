<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': shallShowUserProfileSidebar || shallShowActiveChatContactSidebar || mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar=shallShowActiveChatContactSidebar=shallShowUserProfileSidebar=false"
    />

    <!-- Main Area -->
    <section class="chat-app-window">

      <!-- Start Chat Logo -->
      <div
        v-if="!activeChat.conversation"
        class="start-chat-area"
        style="background: none;"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
            icon="MessageSquareIcon"
            size="56"
          />
        </div>
        <h4
          class="sidebar-toggle start-chat-text"
          @click="startConversation"
        >
          {{ $t('Start Conversation') }}
        </h4>
      </div>

      <!-- Chat Content -->
      <div
        v-else
        class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">

            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">

              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = false"
                />
              </div>

              <b-avatar
                size="36"
                :src="activeChat.conversation.avatar"
                class="mr-1 cursor-pointer badge-minimal"
                :badge-variant="resolveAvatarBadgeVariant(activeChat.conversation.status)"
                @click.native="shallShowActiveChatContactSidebar=true"
              />
              <h6 class="mb-0">
                {{ activeChat.conversation.name }}
              </h6>
            </div>
            <div
              v-if="userData.role !== 'student'"
              class="d-flex align-items-center"
            >
              <feather-icon
                v-b-modal.modal-add
                icon="UserPlusIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-50"
              />
              <feather-icon
                v-b-modal.modal-delete
                icon="Trash2Icon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-50"
              />
              <feather-icon
                v-b-modal.modal-edit
                icon="EditIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-50"
              />
              <b-modal
                id="modal-add"
                no-close-on-backdrop
                centered
                title="Add to conversation"
                ok-only
                ok-title="Add"
                @ok="addToConversation(activeChat.conversation.id, selected)"
              >
                <b-form-group>
                  <v-select
                    v-model="selected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="usersOptions"
                    multiple
                    @keyup.native="autocomplete($event.target.value)"
                    @click.native="autocomplete($event.target.value)"
                  >
                    <span slot="no-options">
                      <div
                        v-if="isTyping"
                        class="text-center d-flex align-items-center justify-content-center"
                      >
                        <b-spinner
                          variant="primary"
                          label="Text Centered"
                        />
                      </div>
                      <div
                        v-if="!isTyping && !usersOptions.length"
                        class="text-center d-flex align-items-center justify-content-center"
                      >
                        No matching records found
                      </div>
                    </span>
                  </v-select>
                </b-form-group>
              </b-modal>
              <b-modal
                id="modal-delete"
                no-close-on-backdrop
                centered
                title="Delete conversation"
                ok-only
                ok-title="Delete"
                @ok="deleteConversation(activeChat.conversation.id)"
              >
                <b-card-text>
                  Are you you want to delete <strong>{{ activeChat.conversation.name }}</strong>?
                </b-card-text>
              </b-modal>
              <b-modal
                id="modal-edit"
                centered
                title="Rename the conversation"
                ok-only
                ok-title="Update"
                @ok="renameConversation(activeChat.conversation.id)"
              >
                <b-form-group
                  label="New name"
                  label-for="new-name"
                >
                  <b-form-input
                    v-model="newName"
                    placeholder="New name"
                  />
                </b-form-group>
              </b-modal>
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
          style="background: none;"
        >
          <chat-log
            :from-chat="true"
            :is-loading="isLoading"
            :chat-data="activeChat"
            :profile-user-data="profileUserData"
            :profile-user-avatar="profileUserDataMinimal.avatar"
            :profile-user-id="profileUserDataMinimal.id"
            @refresh-chat="refreshChat"
            @get-more-messages="getMoreMessages"
          />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form
          class="chat-app-form"
          @submit.prevent="sendMessage"
        >
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input
              v-model="chatInputMessage"
              placeholder="Enter your message"
            />
          </b-input-group>
          <b-button
            variant="primary"
            type="submit"
          >
            {{ $t('Send') }}
          </b-button>
        </b-form>
      </div>
    </section>

    <!-- Active Chat Contact Details Sidebar -->
    <!-- <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
      :contact="activeChat.conversation || {}"
    /> -->

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :shall-show-more="shallShowMore"
        :chats-contacts="chatsContacts"
        :is-loaded="isLoaded"
        :active-chat-contact-id="activeChat.conversation ? activeChat.conversation.id : null"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="openChatOfContact"
        @create-conversation="createConversation"
        @show-more="showMore"
      />
    </portal>
  </div>
</template>

<script>
import {
  ref, onUnmounted, nextTick,
} from '@vue/composition-api'
import {
  BAvatar, BForm, BInputGroup, BFormInput, BFormGroup, BButton, BModal, BSpinner, BCardText,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'
import router from '@/router'
import store from '@/store'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import chatStoreModule from './chatStoreModule'
// import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ChatLog from './ChatLog.vue'
import useChat from './useChat'

export default {
  components: {

    // BSV
    BAvatar,
    BForm,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BButton,
    BModal,
    vSelect,
    BSpinner,
    BCardText,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    // ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },
  data() {
    return {
      selected: [],
      dir: 'ltr',
      userData: {},
      usersOptions: [],
      isTyping: false,
      parameters: null,
    }
  },
  // watch: {
  //    $route: {
  //       handler: id => {
  //          console.log(id)
  //       },
  //       deep: true,
  //       immediate: true,
  //     },
  // },
  watch: {
    '$store.state.chatStoreModule.newConversation': {
      handler() {
        window.location.reload()
        // this.connectLaravelEcho()
      },
      deep: true,
    },
  },
  // watch: {
  //   parameters: val => {
  //     console.log(val)
  //   },
  // },
  mounted() {
    const userData = getUserData()
    this.userData = userData
    this.parameters = this.$route.params.id
  },
  methods: {
    autocomplete(query) {
      this.isTyping = true
      /* eslint-disable no-return-assign */
      if (this.userData.role === 'administrator') {
        return this.$http.post('/autocomplete/user', { search: query }).then(res => {
          this.isTyping = false
          this.usersOptions = res.data
        })
      }
      return this.$http.get(`/${this.userData.role}/autocomplete`, { params: { search: query } }).then(res => {
        this.isTyping = false
        this.usersOptions = res.data.data
      })
    },
  },
  setup() {
    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'

    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const { resolveAvatarBadgeVariant } = useChat()

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      if (refChatLogPS.value) {
        const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
        scrollEl.scrollTop = scrollEl.scrollHeight
      }
    }

    // ------------------------------------------------
    // Chats & Contacts ...
    // ------------------------------------------------
    const chatsContacts = ref([])
    const activeChats = ref([])
    const contacts = ref([])
    let channels = []
    const activeChat = ref({})
    const chatInputMessage = ref('')
    /* eslint-disable camelcase */
    let conversation_id = null

    /* eslint-disable */
    const openChatOfContact = userId => {
      chatsContacts.value.map(contact => {
        if (contact.id === userId && contact.unread_messages > 0) {
          store.dispatch('app-chat/markAsRead', { conversation_id: userId })
          contact.unread_messages = 0
        }
      })
      activeChats.value.forEach(el => {
        if (el.conversation.id === userId) {
          activeChat.value = el
        }      
      })
      conversation_id = userId
      // Reset send message input value
      chatInputMessage.value = ''
      //     // Scroll to bottom
          nextTick(() => { scrollToBottomInChatLog() })
      //   })

      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
    }
    const listenToConversations = () => {
      channels.forEach(channel => {
            window.Echo.channel(`conversation.${channel}`)
              .listen('.chat.message', e => {
                /* eslint-disable */
                chatsContacts.value.forEach(conversation => {
                  if (conversation.id === e.message.conversation.id) {
                    conversation.messages.unshift({
                      message: e.message.message,
                      sender: e.message.sender,
                      time: e.message.time,
                    })
                    conversation.latest_message = {
                      id: e.message.id,
                      message: e.message.message,
                      sender: e.message.sender,
                      time: e.message.time,
                    }
                    // conversation.unread_messages = e.message.unread_messages
                    // Set messages to read in the active conversatopn
                    store.dispatch('app-chat/markAsRead', { conversation_id: activeChat.value.conversation.id })
                    // openChatOfContact(e.message.conversation.id)
                    nextTick(() => { scrollToBottomInChatLog() })
                  }
                })
                nextTick(() => { scrollToBottomInChatLog() })
                // fetchChatAndContacts()
              })
          })
    }
    // Create new conversation
    const createConversation = (userId, participants, conversation_type) => {
      /* eslint-disable-next-line */
      const participants_ids = []
      if (participants) {
        participants.forEach(participant => participants_ids.push(participant.id))
      }
      let body = {
        user_id: userId,
        participants: participants_ids,
      }
      if (conversation_type === 'support_center') {
        body = {
          user_id: userId,
          conversation_type,
          participants: [],
        }
      }
      store.dispatch('app-chat/createConversation', body)
      .then(res => {
        if (window.location.pathname !== '/apps/support_center') {
          channels.push(res.data.id)
          listenToConversations()
          chatsContacts.value.unshift(res.data)
          activeChats.value.unshift({
            conversation: {
              id: res.data.id,
              avatar: res.data.avatar,
              name: res.data.name,
            },
            messages: {
              data: res.data.messages,
            },
          })
        }
        toast({
          component: ToastificationContent,
          props: {
            title: 'Conversation created',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        openChatOfContact(res.data.id)
        // refreshChat(res.data.id)
        })
      // store.dispatch('app-chat/conversation', body)
    }
    const isLoaded = ref(false)
    const pageParam = ref(1)
    const showMore = page => {
      pageParam.value = page
      fetchChatAndContacts()
    }
    const listen = ref(true)
    const shallShowMore = ref(true)
    const fetchChatAndContacts = conversationId => {
      // const foo = chatsContacts.value.map(el => { return el.id })
      const userData = getUserData()
      isLoaded.value = false
      let dispatchDestination = 'app-chat/fetchChatsAndContacts'
      let payload = { page: pageParam.value }
      if (window.location.pathname === '/apps/support_center') {
        if (userData.role === 'student') {
          createConversation(userData.id, [], 'support_center')
          payload = {
            page: pageParam.value,
            conversation_type: 'support_center', 
          }
        } else {
          dispatchDestination = 'app-chat/fetchSupportConversations'
        }
      }
      store.dispatch(dispatchDestination, payload)
        .then(response => {
          // if (userData.role === 'student') {
          //   createConversation(userData.id, null, 'support_center')
          // }
          if (!response.data.conversations.data) {
            shallShowMore.value = false
          }
          chatsContacts.value = store.state.chatStoreModule.data.conversations
          isLoaded.value = true
          chatsContacts.value.forEach(el => {
            activeChats.value.push(
              {
                conversation: {
                  id: el.id,
                  avatar: el.avatar,
                  name: el.name,
                },
                messages: {
                  data: el.messages,
                },
              },
            )
          })
          channels = chatsContacts.value.map(obj => { return obj.id })
          contacts.value = store.state.chatStoreModule.data.users
          if (router.currentRoute.params.id !== undefined) {
            openChatOfContact(router.currentRoute.params.id)  
          }
          if (conversationId) {
            openChatOfContact(conversationId)
          }
          if (chatsContacts.value.length && !conversationId){
            openChatOfContact(chatsContacts.value[0].id)
          } else {
            activeChat.value = {}
          }
          if (listen.value) { listenToConversations() }
          // openChatOfContact(router.currentRoute.params.id !== undefined ? router.currentRoute.params.id : channels[0])
          // eslint-disable-next-line no-use-before-define
          profileUserDataMinimal.value = JSON.parse(localStorage.userData)
        })
    }
    fetchChatAndContacts()

    // View more messages demo until get messages endpoint works properly
    const isLoading = ref(false)
    const getMoreMessages = page => {
      isLoading.value = true
      const userId = conversation_id
      store.dispatch('app-chat/getChat', { userId, page })
      .then(() => {
        isLoading.value = false
        activeChat.value = store.state.chatStoreModule.activeChat
      })
    }

    const sendMessage = () => {
      if (!chatInputMessage.value) return
      const payload = {
        conversation_id: activeChat.value.conversation.id,
        // eslint-disable-next-line no-use-before-define
        user_id: profileUserDataMinimal.value.id,
        content: chatInputMessage.value,
      }
      store.dispatch('app-chat/sendMessage', payload)
        .then(() => {

          // Reset send message input value
          chatInputMessage.value = ''

          // Set Last Message for active contact
          // const conversation = chatsContacts.value.find(c => c.id === activeChat.value.conversation.id)
          // contact.chat.lastMessage = message

          // Scroll to bottom
          openChatOfContact(conversation_id)
          nextTick(() => { scrollToBottomInChatLog() })
        })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({})
    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({})

    const shallShowUserProfileSidebar = ref(false)
    const showUserProfileSidebar = () => {
      store.dispatch('app-chat/getProfileUser')
        .then(response => {
          profileUserData.value = response.data
          shallShowUserProfileSidebar.value = true
        })
    }

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }
    // Add to conversation
    const addToConversation = (conversation_id, participants) => {
      listen.value = false
      const users = []
      participants.forEach(participant => users.push(participant.id))
      store.dispatch('app-chat/addToConversation', { conversation_id, users }).then(res => {
        fetchChatAndContacts(conversation_id, listen.value)
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        })
    }
    const deleteConversation = id => {
      listen.value = false
      store.dispatch('app-chat/deleteConversation', { id }).then(() => {
        fetchChatAndContacts()
      })
    }
    const newName = ref(null)
    const renameConversation = id => {
      const name = newName.value
      store.dispatch('app-chat/renameConversation', { id, name })
      .then(res => {
        refreshChat(id)
      })
    }
    // Realtime refetch data
    const refreshChat = conversationId => {
      fetchChatAndContacts()
      openChatOfContact(conversationId)

    }
    return {
      newName,
      renameConversation,
      // Filters
      // formatDate,

      // useChat
      resolveAvatarBadgeVariant,
      listen,

      // Chat & Contacts
      showMore,
      pageParam,
      chatsContacts,
      contacts,
      refreshChat,
      isLoaded,
      isLoading,
      addToConversation,
      deleteConversation,

      // Create Conversation
      createConversation,
      listenToConversations,
      shallShowMore,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      openChatOfContact,
      sendMessage,
      getMoreMessages,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
