<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- filter status -->
          <b-col
            cols="12"
            md="6"
            class="mb-1"
          >
            <v-select
              v-model="selectedFilter"
              :reduce="x => x.value"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filters"
              lebel="label"
              :clearable="false"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
                debounce="500"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refclaimTable"
        class="position-relative"
        :items="fetchElements"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>
        <template #cell(student_name)=data>
          <b-link
            v-b-tooltip.hover.top="data.item.student_name"
            :to="{ name: 'apps-users-view',params: { id: data.item.student_id } }"
            style="max-width: 180px"
            class="d-block font-weight-bold text-truncate mr-1"
          >
            {{ data.item.student_name }}
          </b-link>
          <small class="text-secondary">{{ data.item.student_id }}</small>
        </template>
        <template #cell(lesson)=data>
          <span class="d-block font-weight-bold">{{ data.item.lesson_number }}</span>
          <small class="text-secondary text-nowrap">{{ data.item.session_date }}</small>
        </template>
        <template #cell(course)=data>
          <strong class="d-block text-nowrap">{{ data.item.course_group }}</strong>
          <span class="text-secondary">{{ data.item.course_code }}</span>
        </template>
        <!-- Column: Comment -->
        <template #cell(comment)="data">
          <span
            v-b-popover.hover.focus="data.item.claim_message"
            style="max-width: 140px"
            class="d-block text-truncate"
            title="Details"
          >
            {{ data.item.claim_message }}
          </span>
        </template>
        <!-- Column: Date -->
        <template #cell(claim_date)="data">
            <span class="d-block text-nowrap">
              {{ data.item.claim_date }}
            </span>
        </template>
        <template #cell(teacher)="data">
          <b-avatar
            v-if="data.item.teacher"
            v-b-tooltip.hover.top="data.item.teacher.name"
            class="pull-up pending-teachers"
            button
            :title="data.item.teacher.name"
            :src="data.item.teacher.avatar.split('/')[5] !== 'default-avatar.jpg' ? data.item.teacher.avatar : null"
            :text="avatarText(data.item.teacher.name)"
          />
        </template>
        <template #cell(attendance)="data">
          <strong>{{ data.item.claim_old_hours ? data.item.claim_old_hours : 0 }} h</strong>
        </template>
        <template #cell(notes)="data">
          <b-button
            :id="`popover-target-${data.item.id}`"
            variant="flat-primary"
            size="sm"
            class="btn-icon rounded-circle"
            @click="handleChatClick({model:'attendance', id: data.item.attendance_id})"
          >
            <feather-icon
              icon="ClipboardIcon"
              class="align-middle text-body"
              badge-classes="bg-danger"
              :badge="data.item.comments_number > 0 ? data.item.comments_number : ''"
              size="16"
            />
          </b-button>
        </template>
        <template #cell(actions)="data">
            <b-form-checkbox
              v-for="index in 3"
              :key="index"
              v-model="data.item.claim_hours"
              :value="index"
              disabled
              class="custom-control-primary d-inline-block mr-50 mb-1"
            >
              {{ index }}h
            </b-form-checkbox>
          <div class="d-inline-block">
            <b-button
              v-if="data.item.claim_status !== 'rejected'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="data.item.claim_status === 'approved' ? 'flat-success' : 'success'"
              size="sm"
              :disabled="data.item.claim_status === 'approved'"
              @click="approveClaim(data.item.attendance_id)"
            >
              {{ data.item.claim_status !== 'approved' ? 'Approve' : 'Approved' }}
            </b-button>
            <b-button
              v-if="data.item.claim_status !== 'approved'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="data.item.claim_status === 'rejected' ? 'flat-danger' : 'danger'"
              class="ml-25"
              size="sm"
              :disabled="data.item.claim_status === 'rejected'"
              @click="rejectClaim(data.item.attendance_id)"
            >
              {{ data.item.claim_status !== 'rejected' ? 'Reject' : 'Rejected' }}
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="3"
            sm="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>
          <b-col
            cols="3"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} to {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="!isBusy"
              v-model="currentPage"
              :total-rows="totalElements"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <div
      v-if="isChatActive"
      class="notesModule shadow-lg"
    >
      <div style="background: #fff" class="pb-1">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="light"
          size="sm"
          class="btn-icon shadow-sm ml-auto "
          @click="isChatActive = false"
          style="display: block; transform: translate(8px, -2px);"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
      <chat-module
        :key="chatModelId.id"
        @decrease-notes="refetchData"
        @notes-increament="refetchData"
        :model-id="chatModelId.id"
        model-type="attendance"
      />
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BFormCheckbox, VBPopover,
  BPagination, BSpinner, VBTooltip, BAvatar, BButton, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ChatModule from '@/views/admin/ChatModule.vue'
import useClaimList from './useClaimList'
import claimStoreModule from './claimStoreModule'

export default {
  components: {
    ChatModule,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BSpinner,
    BAvatar,
    BFormCheckbox,
    BLink,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    Ripple,
  },
  setup() {
    const CLAIM_STORE_MODULE_NAME = 'claim'
    // Register module
    if (!store.hasModule(CLAIM_STORE_MODULE_NAME)) store.registerModule(CLAIM_STORE_MODULE_NAME, claimStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLAIM_STORE_MODULE_NAME)) store.unregisterModule(CLAIM_STORE_MODULE_NAME)
    })
    const {
      selectedFilter,
      filters,
      approveClaim,
      rejectClaim,
      isBusy,
      fetchElements,
      tableColumns,
      perPage,
      currentPage,
      totalElements,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refclaimTable,
      refetchData,
      handleChatClick,
      isChatActive,
      chatModelId,
    } = useClaimList()
    return {
      handleChatClick,
      isChatActive,
      chatModelId,
      // Filters
      selectedFilter,
      filters,
      avatarText,

      // Actions
      fetchElements,
      approveClaim,
      rejectClaim,

      // Loader
      isBusy,

      tableColumns,
      perPage,
      currentPage,
      totalElements,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refclaimTable,
      refetchData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
