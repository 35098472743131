<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Registrations Per Country</b-card-title>
      <!-- <b-dropdown
        text="Last 7 Days"
        variant="transparent"
        class="chart-dropdown "
        right
        no-caret
        toggle-class="p-0 mb-25"
        size="sm"
      >
        <b-dropdown-item
          v-for="day in chartData.lastDays"
          :key="day"
        >
          {{ day }}
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>

    <b-card-body>

      <!-- apex chart -->
      <vue-apex-charts
        type="donut"
        height="300"
        class="my-1"
        :options="sessionsByDeviceDonut.chartOptions"
        :series="sessionsByDeviceDonut.series"
      />

      <!-- chart info -->
      <b-row>
        <b-col cols="5">
          <div
            v-for="(label, index) in sessionsByDeviceDonut.chartOptions.labels"
            :key="index"
          >
            <feather-icon
              icon="MapPinIcon"
              size="16"
              class="text-primary"
            />
            <span class="font-weight-bolder ml-75 mr-25">{{ label }} -</span>
          </div>
        </b-col>
        <b-col>
          <div
            v-for="(data,index) in sessionsByDeviceDonut.series"
            :key="index"
            class="d-flex justify-content-between"
          >
            <span>{{ data }}%</span>
          </div>
          <!-- <div>
            <span>{{ data.upDown }}%</span>
            <feather-icon
              :icon="data.upDown > 0 ? 'ArrowUpIcon':'ArrowDownIcon'"
              :class="data.upDown > 0 ? 'text-success':'text-danger'"
              class="mb-25 ml-25"
            />
          </div> -->
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
// import { $themeColors } from '@themeConfig'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    // BDropdown,
    // BDropdownItem,
    BCardBody,
    VueApexCharts,
  },
  props: {
    sessionsByDeviceDonut: {
      type: Object,
      required: true,
    },
  },
}
</script>
