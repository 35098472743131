<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="GlobeIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('settings.General') }}</span>
      </template>

      <account-setting-general
        v-if="options.general"
        :general-data="options.general"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('settings.Change_Password') }}</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('settings.personal-information') }}</span>
      </template>

      <account-setting-information
        v-if="options.info"
        :information-data="options.info"
      />
    </b-tab>
    <!-- Personal informations -->
    <b-tab
      @click="refreshKey++"
      v-if="userData.role === 'teacher'"
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="AwardIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('settings.professional-information') }}</span>
      </template>

      <!-- Added refresh key to reset initial hieght of form repeater for time_frames repeater -->
      <account-setting-professional
        v-if="options.professional"
        :key="refreshKey"
        :professional-data="options.professional"
      />
    </b-tab>

    <!-- social links -->
    <b-tab v-if="userData.role !== 'student'" :active="$route.params.tab === 'social'">

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('settings.Social') }}</span>
      </template>

      <account-setting-social
        v-if="options.social"
        :social-data="options.social"
      />
    </b-tab>

    <!-- notification -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('settings.Notifications') }}</span>
      </template>

      <account-setting-notification
        v-if="options.notification"
        :notification-data="options.notification"
      />
    </b-tab>

    <!-- Language -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="GlobeIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('settings.Language') }}</span>
      </template>
      <local />
    </b-tab>
    <!-- Language -->
    <b-tab :active="$route.params.tab === 'secAccount'" v-if="userData.role === 'administrator'">
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UsersIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Second account</span>
      </template>
      <second-account
        v-if="options.account"
        :second-account-data="options.account"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import Local from '@core/layouts/components/app-navbar/components/Locale.vue'
import { getUserData } from '@/auth/utils'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import AccountSettingProfessional from './AccountSettingProfessional.vue'
import AccountSettingSocial from './AccountSettingSocial.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'
import SecondAccount from './SecondAccount.vue'

export default {
  components: {
    BTabs,
    BTab,
    Local,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingProfessional,
    AccountSettingSocial,
    AccountSettingNotification,
    SecondAccount,
  },
  data() {
    return {
      refreshKey: 0,
      options: {},
      userData: getUserData(),
    }
  },
  beforeCreate() {
    this.$http.get('/account-setting/data').then(res => {
        this.options = res.data
        this.options.professional.days = res.data.professional.days ? res.data.professional.days.split(',') : []
        this.options.professional.levels = res.data.professional.levels ? res.data.professional.levels.split(',') : []
        this.options.professional.time = res.data.professional.time ? this.options.professional.time.split(',') : []
      })
  },
}
</script>
