<template>
  <div class="p-1">
    <h3 v-if="testTitle" class="mb-2">
      {{ testTitle }}
    </h3>
    <div v-if="test.groups">
      <div
        v-for="group in test.groups"
        :key="group.id"
      >
        <div class="mb-2">
          <strong>{{ group.name }}</strong>
          <question-component
            v-for="(question, key) in group.questions"
            :key="key"
            :QuestionData="question"
            :questionId="question.question_id"
            @question_responses="listenQResponses"
          />
          <br>
        </div>
      </div>
      <br>
      <b-button
        v-if="userRole === 'student'"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mr-2"
        @click="handleSubmit"
      >
        {{ $t('Submit') }}
      </b-button>
    </div>
    <div
      v-if="!test.groups"
      class="text-center d-flex align-items-center justify-content-center"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
        style="width: 3rem; height: 3rem;"
      />
      <strong class="ml-1">Loading...</strong>
    </div>
  </div>
</template>
<script>
import { BSpinner, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import QuestionComponent from './components/QuestionComponent.vue'

export default {
  components: {
    BSpinner,
    BButton,
    QuestionComponent,
  },
  directives: { Ripple },
  props: {
    testId: {
      type: Number,
      required: true,
    },
    userRole: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      test: {},
      testTitle: null,
    }
  },
  /* eslint-disable no-param-reassign */
  created() {
    this.$http.get(`/placement-test/${this.testId}`).then(resp => {
        this.test = resp.data.data
        this.$emit('test-name', this.test.name)
    }).catch(console.error)
  },
  methods: {
    listenQResponses(dataResponse) {
      this.test.groups.forEach(group => {
          group.questions.forEach(question => {
              if (question.question_id === dataResponse.questionId) {
                    question = dataResponse.QuestionData
                }
          })
      })
    },
    handleSubmit() {
      const fArray = []
      this.test.groups.forEach(group => {
          group.questions.forEach(question => {
              question.fields.forEach(field => {
                  const resp = (typeof field.response !== 'undefined') ? field.response : null
                  if (field.formType !== 'image' || field.formType !== 'audio') {
                      fArray.push({
                          field_id: field.id,
                          response: resp,
                      })
                  }
              })
          })
      })
      this.$http.post('/placement-test/student/send-responses', { test_id: this.test.id, responses: fArray })
      .then(resp => {
        this.testTitle = `Placement Test Score: ${resp.data.data.percent}%`
      })
    },
  },
}
</script>
