<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title class="ml-25">
        {{ ('Activity log') }}
      </b-card-title>
    </b-card-header>

    <!-- timeline -->
    <b-card-body>
      <app-timeline v-if="timeline.length !== 0">
        <app-timeline-item
          v-for="(item, index) in timeline"
          :key="item.date+String(index)"
          variant="success"
        >
          <div
            class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
          >
            <h6
              v-b-tooltip.hover.top="item.title"
              class="d-inline-block text-truncate"
              style="max-width: 280px"
            >
              {{ item.title }}
            </h6>
            <small
              v-if="item.progress === null"
              class="timeline-item-time text-nowrap text-muted ml-1"
            >
              {{ formatDateTime(item.date) }}
            </small>
            <div
              v-if="item.progress"
              class="d-flex align-items-center"
            >
              <span class="font-weight-bold text-body-heading mr-1">{{ item.progress.attendance_percent }} %</span>
              <vue-apex-charts
                v-if="chartData.length !== 0"
                type="radialBar"
                height="30"
                width="30"
                :options="chartData[index].options"
                :series="chartData[index].series"
              />
              <!-- <span class="font-weight-bold text-body-heading mr-1">{{ item.progress.hours_completed }} ({{ item.progress.attendance_percent }} %)</span> -->
              <!-- <vue-apex-charts
                type="radialBar"
                height="30"
                width="30"
                :options="chartData[index].options"
                :series="[...item.progress.attendance_percent]"
              /> -->
            </div>
          </div>
          <div v-if="item.progress">
            <b-button
              v-if="parseInt(item.progress.attendance_percent, 10) >= 80"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              size="sm"
              variant="outline-primary"
              @click="downloadCertificate(item.course_id)"
            >
              Download certificate
            </b-button>
          </div>
        </app-timeline-item>
      </app-timeline>
      <app-timeline v-if="timeline.length === 0">
        <span>Nothing to show</span>
      </app-timeline>
      <!--/ timeline -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, VBTooltip, BButton,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
// import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BButton,
    AppTimeline,
    AppTimelineItem,
    VueApexCharts,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    studentId: {
      type: Number,
      default: null,
    },
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      timeline: [
        { title: '', date: '', progress: {} },
      ],
      chartData: [],
      chartClone: {},
      // chartColor: [$themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger],
      chartSeries: [78],
      chart: {
        series: [],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '22%',
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
        },
      },
    }
  },
  /* eslint-disable object-shorthand */
  watch: {
    refreshKey: function () {
      this.fetchTimeline()
    },
  },
  created() {
    this.fetchTimeline()
  },
  methods: {
    fetchTimeline() {
      let URL = '/student/dashboard/timeline'
      if (this.studentId) { URL = `/student/dashboard/timeline?student_id=${this.studentId}` }
      this.$http.get(URL).then(res => {
        this.timeline = res.data.data
        /* eslint-disable array-callback-return, consistent-return */
        this.chartSeries = res.data.data.map(el => {
          if (el.progress) {
            return parseInt(el.progress.attendance_percent, 10)
            // parseInt(el.progress.attendance_percent, 10)
          }
        })
        for (let i = 0; i < this.chartSeries.length; i += 1) {
          const chartClone = JSON.parse(JSON.stringify(this.chart))
          // chartClone.options.colors[0] = this.chartColor[i]
          chartClone.series[0] = this.chartSeries[i]
          this.chartData.push(chartClone)
        }
        // this.chartSeries = res.data.data.forEach(el => {
        //   if (el.progress) {
        //     this.chartSeries.push(parseInt(el.progress.attendance_percent, 10))
        //     // console.log(this.chartSeries)
        //     for (let i = 0; i < 5; i += 1) {
        //       const chartClone = JSON.parse(JSON.stringify(this.chart))
        //       chartClone.options.colors[0] = this.chartColor[i]
        //       chartClone.series[0] = this.chartSeries[i]
        //       this.chartData.push(chartClone)
        //     }
        //   }
        // })
      })
    },
    downloadCertificate(id) {
      this.$http.post(`/student/attendance/certificate/${id}`, {}, { responseType: 'blob' })
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', `certificate_${new Date()}.pdf`)
        document.body.appendChild(fileLink)

        fileLink.click()
      })
      .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error while downloading certificate',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
  setup() {
    /* eslint-disable camelcase */
    const formatDateTime = dateTime => {
      if (typeof dateTime !== 'undefined') {
        const timestamp = new Date(dateTime).getTime()
        const todate = new Date(timestamp).getDate()
        const tomonth = new Date(timestamp).getMonth() + 1
        const toyear = new Date(timestamp).getFullYear()

        const today = `0${todate.toString().slice(-2)}`
        const thisMonth = `0${tomonth.toString().slice(-2)}`
        const day = (today.length === 2 ? today : today.substr(1))
        const month = (thisMonth.length === 2 ? thisMonth : thisMonth.substr(1))

        const original_date = `${day}-${month}-${toyear}`
        return original_date
      }
      return ''
    }
    return {
      formatDateTime,
    }
  },
}
</script>
<style scoped>
.app-timeline {
  height: 250px;
  overflow-y: auto;
  padding: 0px 15px;
}
</style>
