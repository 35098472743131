<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            lg="6"
          >
            <v-select
              v-model="selectedFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filters"
              :reduce="x => x.value"
              lebel="label"
              :clearable="false"
              class="courses-filter d-inline-block mx-50"
              style="min-width: 150px;"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
                debounce="500"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="teacherId ? tableColumns1 : tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>
        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.id }}
            </b-link>
          </b-media>
        </template>
        <!-- Column: Code -->
        <template #cell(course_name)="data">
          {{ data.item.code }}
        </template>
        <!-- Column: Classroom -->
        <template #cell(classroom)="data">
          <div v-if="data.item.classroom" class="d-flex align-items-center">
            <span>{{ data.item.classroom.name }}</span>
            <b-button
              v-if="data.item.classroom.link"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat-primary"
              size="sm"
              class="btn-icon p-0 pb-0 ml-50 mb-25"
              target="_blank"
              :href="data.item.classroom.link"
            >
              <feather-icon icon="ExternalLinkIcon" />
            </b-button>
          </div>
        </template>
        <template #cell(date)="data">
          <span
            v-if="data.item.date_start && data.item.date_end"
            class="text-capitalize"
          >
            {{ data.item.date_start }} - {{ data.item.date_end }}
          </span>
          <small class="text-secondary d-block">{{ data.item.time_from }} - {{ data.item.time_to }}</small>
        </template>
        <template #cell(feedbacks)="data">
          <b-button
            v-if="data.item.surveys !== 0"
            v-b-modal="getModal3Id(data.item.id)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            size="sm"
            pill
            @click="getFeedbacks(data.item.id)"
          >
            Feedback
          </b-button>
          <b-modal
            :id="'modal3'+data.item.id"
            :title="`${data.item.course_name}(${data.item.group})`"
            ok-only
            centered
            no-close-on-backdrop
            ok-title="Close"
            size="lg"
          >
            <b-list-group>
              <b-tabs>
                <b-tab title="First survey">
                  <b-list-group-item
                    v-for="survey in surveys.surveys1"
                    :key="survey.id"
                  >
                    <b-row>
                      <b-col cols="6">
                        <span>{{ survey.student.name }}</span>
                      </b-col>
                      <b-col cols="3">
                        <b-badge
                          pill
                          :variant="survey.status === 'pending' ? 'warning' : 'success'"
                        >
                          {{ survey.status }}
                        </b-badge>
                      </b-col>
                      <b-col cols="3">
                        <b-button
                          v-if="survey.status === 'active'"
                          v-b-modal="getModal4Id(survey.id)"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="primary"
                          size="sm"
                          pill
                        >
                          View Feedback
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-modal
                      :id="'modal4'+survey.id"
                      :title="`${survey.student.name}'s feedback`"
                      centered
                      no-close-on-backdrop
                      ok-title="Close"
                      ok-only
                      size="lg"
                      class="admin-survey"
                    >
                      <div class="mb-3">
                        <h5 class="text-center">
                          Course: {{ data.item.course_name }}({{ data.item.group }})
                        </h5>
                        <h5 class="text-center">
                          Teacher: {{ data.item.teacher_name }}
                        </h5>
                      </div>
                      <div
                        v-for="(data,key) in survey.responses"
                        :key="key"
                        class="mt-2"
                      >
                        <h5 class="text-capitalize mb-75">
                          {{ key + 1 }} : {{ data.label }}
                        </h5>
                        <!-- <b-form-textarea
                          v-model="data.value"
                          rows="2"
                        /> -->
                        <b-card-text>
                          {{ data.value.label ? data.value.label : data.value }}
                        </b-card-text>
                      </div>
                      <!-- <b-form-checkbox
                        class="mt-1"
                        v-model="isPublished1"
                        value="yes"
                      >
                        Teachers can see see this
                      </b-form-checkbox> -->
                    </b-modal>
                  </b-list-group-item>
                </b-tab>
                <b-tab title="End course survey">
                  <b-list-group-item
                    v-for="survey in surveys.surveys2"
                    :key="survey.id"
                  >
                    <b-row>
                      <b-col cols="6">
                        <span>{{ survey.student.name }}</span>
                      </b-col>
                      <b-col cols="3">
                        <b-badge
                          pill
                          :variant="survey.status === 'pending' ? 'warning' : 'success'"
                        >
                          {{ survey.status }}
                        </b-badge>
                      </b-col>
                      <b-col cols="3">
                        <b-button
                          v-if="survey.status === 'active'"
                          v-b-modal="getModal5Id(survey.id)"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="primary"
                          size="sm"
                          pill
                        >
                          View Feedback
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-modal
                      :id="'modal5'+survey.id"
                      :title="`${survey.student.name}'s feedback`"
                      centered
                      size="lg"
                      no-close-on-backdrop
                      ok-title="Close"
                      ok-only
                      class="admin-survey"
                    >
                      <div class="mb-3">
                        <h5 class="text-center">
                          Course: {{ data.item.course_name }}({{ data.item.group }})
                        </h5>
                        <h5 class="text-center">
                          Teacher: {{ data.item.teacher_name }}
                        </h5>
                      </div>
                      <div
                        v-for="(data,key) in survey.responses"
                        :key="key"
                        class="mt-2"
                      >
                        <h5 class="text-capitalize mb-75">
                          {{ key + 1 }} : {{ data.label }}
                        </h5>
                        <b-card-text v-if="key !== 2 && data.options">
                          <span>{{ data.value.label ? data.value.label : data.value }}</span>
                          <span
                            v-if="data.value.details"
                            class="d-block mt-50"
                          >Details: {{ data.value.details }}</span>
                        </b-card-text>
                        <div v-if="key === 2">
                          <b-card-text
                            v-for="el in data.value"
                            :key="el.label"
                          >
                            <div class="row">
                              <span class="col-6">{{ el.label }}</span>
                              <span class="col-6">{{ el.value }}</span>
                            </div>
                          </b-card-text>
                        </div>
                      </div>
                    </b-modal>
                  </b-list-group-item>
                </b-tab>
              </b-tabs>
            </b-list-group>
          </b-modal>
        </template>
        <template #cell(reviews)="data">
          <span
            v-b-modal="getModal2Id(data.item.id)"
            @click="getReview(data.item.id)"
          >
            <b-form-rating
              v-model="data.item.reviews"
              size="sm"
              inline
              no-border
              readonly
              variant="warning"
            />
            <!-- @change="addReview(data.item.id, data.item.reviews, null)" -->
          </span>
          <b-modal
            :id="'modal2'+data.item.id"
            :title="`${data.item.group}`"
            ok-title="Close"
            hide-footer
            centered
            size="lg"
            no-close-on-backdrop
          >
            <div
              v-if="isBusyReview"
              class="text-center d-flex align-items-center justify-content-center mt-3 mb-3"
            >
              <b-spinner
                variant="primary"
                label="Text Centered"
              />
              <strong class="ml-1">{{ $t('Loading...') }}</strong>
            </div>
            <course-reviews
              v-if="!isBusyReview"
              :reviews="reviews"
            />
          </b-modal>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-b-modal="getModal1Id(data.item.id)"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="btn-icon rounded-circle"
            variant="primary"
          >
            <feather-icon icon="UserCheckIcon" />
          </b-button>
          <!-- Delete modal -->
          <b-modal
            :id="'modal1'+data.item.id"
            :title="'Attendance list: ' + data.item.code + ' | ' + data.item.group"
            centered
            no-close-on-backdrop
            ok-title="Accept"
            size="xl"
            hide-footer
          >
            <course-attendance-list
              :course-id="data.item.id"
              @close-attendance-modal="closeAttendanceModal(data.item.id)"
            />
          </b-modal>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="!isBusy"
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BLink, BTab, BTabs, BCardText,
  BBadge, BPagination, BModal, BButton, BSpinner, BFormRating, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted, ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import CourseReviews from '@/views/courses/CourseReviews.vue'
import useCoursesList from './useCoursesList'
import courseStoreModule from './courseStoreModule'
import CourseAttendanceList from './CourseAttendanceList.vue'

export default {
  components: {
    CourseAttendanceList,
    CourseReviews,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    BModal,
    BButton,
    BSpinner,
    BTab,
    BTabs,
    BListGroup,
    BListGroupItem,
    BCardText,
    vSelect,
    BFormRating,
  },
  directives: {
    Ripple,
  },
  props: {
    teacherId: {
      type: Number,
      default: null,
    },
  },
  methods: {
    closeAttendanceModal(id) {
      this.$bvModal.hide(`modal1${id}`)
    },
  },
  setup(props) {
    /* eslint-disable camelcase */
    const getModal1Id = id => `modal1${id}`
    const getModal2Id = id => `modal2${id}`
    const getModal3Id = id => `modal3${id}`
    const getModal4Id = id => `modal4${id}`
    const getModal5Id = id => `modal5${id}`
    const COURSE_STORE_MODULE_NAME = 'course'
    // Register module
    if (!store.hasModule(COURSE_STORE_MODULE_NAME)) store.registerModule(COURSE_STORE_MODULE_NAME, courseStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COURSE_STORE_MODULE_NAME)) store.unregisterModule(COURSE_STORE_MODULE_NAME)
    })

    const toast = useToast()
    const filters = [
      { label: 'All', value: null },
      { label: 'On going', value: 'on_going' },
      { label: 'Planned', value: 'planned' },
      { label: 'Past', value: 'past' },
    ]

    const {
      isBusy,
      userData,
      fetchUsers,
      tableColumns,
      tableColumns1,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      selectedFilter,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      teacherID,
    } = useCoursesList()
    if (props.teacherId) { teacherID.value = props.teacherId }
    const surveys = ref({})
    const getFeedbacks = id => {
      store.dispatch('coursesStoreModule/getSurveys', id).then(res => {
        surveys.value = res.data
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while fetching feedback list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }

    const reviews = ref({})
    const isBusyReview = ref(true)
    const getReview = id => {
      isBusyReview.value = true
      store.dispatch('course/getReview', id)
        .then(res => {
          isBusyReview.value = false
          reviews.value = res.data
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while fetching reviews',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    return {
      isBusy,
      surveys,
      getFeedbacks,
      // Modal ids
      getModal1Id,
      getModal2Id,
      getModal3Id,
      getModal4Id,
      getModal5Id,

      getReview,
      reviews,
      isBusyReview,

      userData,
      fetchUsers,
      tableColumns,
      tableColumns1,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      selectedFilter,
      filters,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      teacherID,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
