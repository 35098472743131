<template>

  <div>

    <classroom-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <!-- <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            lg="6"
          >
            <v-select
              v-model="selectedFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filters"
              lebel="label"
              :reduce="x => x.value"
              :clearable="false"
              class="courses-filter d-inline-block mx-50"
              style="min-width: 150px;"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
                debounce="500"
              />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">{{ $t('Add Classroom') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>
        <!-- Column: User -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
              {{ data.item.id }}
          </b-media>
        </template>
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <b-link
              v-b-tooltip.hover.top="data.item.name"
              class="font-weight-bold d-block text-nowrap"
              style="max-width: 300px;"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">{{ data.item.code }}</small>
          </b-media>
        </template>
        <!-- Column: visa -->
        <template #cell(classrooms_type)="data">
          <span
            class="d-inline-block text-truncate text-capitalize"
            style="max-width: 300px;"
          >{{ data.item.classroom_type }}</span>
        </template>

        <!-- Column: who pays -->
        <template #cell(max_student)="data">
          {{ data.item.max_students }}
        </template>

        <!-- Column: who pays -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: who pays -->
        <template #cell(comments)="data">
          <b-button
            :id="`popover-target-${data.item.id}`"
            variant="flat-primary"
            size="sm"
            class="btn-icon rounded-circle"
            @click="handleChatClick({model:'teacher', id: data.item.id})"
          >
            <feather-icon
              icon="ClipboardIcon"
              class="align-middle text-body"
              badge-classes="bg-danger"
              :badge="data.item.comments_number > 0 ? data.item.comments_number : ''"
              size="16"
            />
          </b-button>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            dropleft
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="userRole === 'administrator'"
              v-b-modal="getModal2Id(data.item.id)"
              @click="record = { ...data.item }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="userRole === 'administrator'"
              v-b-modal="getModal1Id(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
            <!-- basic modal -->
            <b-modal
              :id="'modal1'+data.item.id"
              :title="$t('Delete')"
              :ok-title="$t('Yes')"
              cancel-title="No"
              centered
              @ok="remove(data.item.id)"
            >
              <b-card-text>
                <h5>{{ $t('Are you sure you want to delete') }} </h5><strong>{{ data.item.name }}</strong>
              </b-card-text>
            </b-modal>

            <!-- basic modal -->
            <b-modal
              :id="'modal2'+data.item.id"
              title="Update classroom data"
              :ok-title="$t('Update')"
              ok-only
              size="lg"
              centered
              hide-footer
              no-close-on-backdrop
            >
              <validation-observer
                #default="{ handleSubmit }"
                ref="form1"
              >
                <!-- Form -->
                <b-form
                  class="p-2"
                  @submit.prevent="handleSubmit(edit(data.item.id, record))"
                >
                  <b-row>
                    <b-col cols="6">
                      <validation-provider
                        #default="validationContext"
                        name="Name"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Name')"
                          label-for="name"
                        >
                          <b-form-input
                            v-model="record.name"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            :placeholder="$t('Name')"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="6">
                      <validation-provider
                        #default="validationContext"
                        name="Code"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Code')"
                          label-for="code"
                        >
                          <b-form-input
                            v-model="record.code"
                            autofocus
                            :state="getValidationState(validationContext)"
                            :placeholder="$t('Code')"
                            :class="errors.code[0] ? 'is-invalid' : ''"
                            @keyup="errors.code = []"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                          <small
                            v-if="errors"
                            class="text-danger"
                          >{{ errors.code[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="6">
                      <validation-provider
                        #default="validationContext"
                        name="External data"
                      >
                        <b-form-group
                          :label="$t('External data')"
                          label-for="External data"
                        >
                          <b-form-input
                            v-model="record.external_data"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            placeholder="External data"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="6">
                      <validation-provider
                        #default="validationContext"
                        name="Status"
                        rules="required"
                      >
                        <b-form-group
                          :state="getValidationState(validationContext)"
                          :label="$t('Status')"
                        >
                          <v-select
                            v-model="record.status"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="statusPicker"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="6">
                      <validation-provider
                        #default="validationContext"
                        name="Max students"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Max student')"
                          label-for="max student"
                        >
                          <b-form-input
                            v-model="record.max_students"
                            autofocus
                            trim
                            :state="getValidationState(validationContext)"
                            :placeholder="$t('Max student')"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="6">
                      <validation-provider
                        #default="validationContext"
                        name="Classroom type"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Classroom type')"
                          label-for="Classroom type"
                          :state="getValidationState(validationContext)"
                        >
                          <v-select
                            v-model="record.classroom_type"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="classroomTypeOptions"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- <b-col cols="6">
                      <validation-provider
                        #default="validationContext"
                        name="City"
                        rules="required"
                      >
                        <b-form-group
                          label="City"
                          label-for="city"
                          :state="getValidationState(validationContext)"
                        >
                          <v-select
                            v-model="record.city_name"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :options="cities"
                            placeholder="City"
                            @keyup.native="autoComplete($event.target.value)"
                            @click.native="autoComplete('')"
                          >
                            <span slot="no-options">
                              <div
                                v-if="isTyping"
                                class="text-center d-flex align-items-center justify-content-center"
                              >
                                <b-spinner
                                  variant="primary"
                                  label="Text Centered"
                                />
                              </div>
                              <div
                                v-if="!isTyping && !cities.length"
                                class="text-center d-flex align-items-center justify-content-center"
                              >
                                No matching records found
                              </div>
                            </span>
                          </v-select>
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col> -->
                  </b-row>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                  >
                    Update
                  </b-button>
                </b-form>
              </validation-observer>
            </b-modal>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t("Showing") }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="!isBusy"
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <div
      v-if="isChatActive"
      class="notesModule"
    >
      <feather-icon
        icon="XSquareIcon"
        size="22"
        class="align-middle text-body close-btn"
        @click="isChatActive = false"
      />
      <chat-module
        :key="chatModelId.id"
        @notes-increament="refetchData"
        @decrease-notes="refetchData"
        :model-id="chatModelId.id"
        model-type="classroom"
      />
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BFormInput, BFormGroup, BButton, BTable, BMedia, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, BSpinner, VBTooltip, BFormInvalidFeedback, BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// import UsersListFilters from './UsersListFilters.vue'
import ChatModule from '@/views/admin/ChatModule.vue'
import useClassroomsList from './useClassroomsList'
import classroomStoreModule from './classroomStoreModule'
import ClassroomListAddNew from './ClassroomListAddNew.vue'

export default {
  components: {
    // Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BForm,

    // UsersListFilters,
    ClassroomListAddNew,
    ChatModule,

    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BSpinner,

    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    /* eslint-disable camelcase, no-shadow */
    const getModal1Id = id => `modal1${id}`
    const getModal2Id = id => `modal2${id}`

    const CLASSROOM_STORE_MODULE_NAME = 'classroom'

    // Register module
    if (!store.hasModule(CLASSROOM_STORE_MODULE_NAME)) store.registerModule(CLASSROOM_STORE_MODULE_NAME, classroomStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLASSROOM_STORE_MODULE_NAME)) store.unregisterModule(CLASSROOM_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'administrator' },
      { label: 'Student', value: 'student' },
      { label: 'Teacher', value: 'teacher' },
      { label: 'Agent', value: 'agent' },
      { label: 'Company', value: 'company' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]
    const filters = [
      { label: 'All', value: null },
      { label: 'Active', value: 'Active' },
      { label: 'Deleted', value: 'Deleted' },
    ]
    const query = ref('')
    const selectedCity = ref({})
    const cities = ref([])
    const comments = ref('')
    const classroomTypeOptions = ['Online', 'Classroom']
    const selectedType = ref('')
    const external_data = ref('')
    const statusPicker = ['active', 'deleted']
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userRole = userData.role
    const record = ref({})

    // Use toast
    const toast = useToast()

    const {
      isBusy,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      selectedFilter,
    } = useClassroomsList()
    const isTyping = ref(false)
    const autoComplete = search => {
      isTyping.value = true
      store.dispatch('classroom/autoCompleteCity', search)
      .then(response => {
        isTyping.value = false
        cities.value = response.data
      })
    }

    const remove = id => {
      store.dispatch('classroom/deleteClassroom', id)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Classroom Has Been removed',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while removing Classroom',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const edit = function (id, record) {
        const payload = {
          code: record.code,
          name: record.name,
          max_student: record.max_students,
          status: record.status,
          comment: record.comment,
          external_data: record.external_data,
          classroom_type: record.classroom_type ? record.classroom_type.toLowerCase() : null,
        }
        if (this.$refs.form1.flags.valid) {
          store.dispatch('classroom/editClassroom', { id, payload })
            .then(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Classroom Has Been edited',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              refetchData()
          }).catch(err => {
            if (err.response.data.errors && err.response.data.errors.code) {
              errors.value.code = err.response.data.errors.code
            }
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error while editing Classroom',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            })
      }
    }
    const errors = ref({
      code: [],
    })
    const isChatActive = ref(false)
    const chatModelId = ref(null)
    const handleChatClick = data => {
      isChatActive.value = true
      chatModelId.value = data
    }
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(() => {})

    return {
      errors,
      record,
      isBusy,
      // Validation
      refFormObserver,
      getValidationState,
      // Modal ids
      getModal1Id,
      getModal2Id,

      isChatActive,
      chatModelId,
      handleChatClick,

      // Actions
      remove,
      edit,

      // autocomplete
      query,
      selectedCity,
      cities,
      autoComplete,
      isTyping,

      comments,
      userRole,
      classroomTypeOptions,
      selectedType,
      external_data,
      statusPicker,

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      selectedFilter,
      filters,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
