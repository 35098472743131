import axios from '@axios'

export default {
  namespaced: true,
  state: {
    cities: {
      rows: [],
      totalRecords: 0,
    },
  },
  getters: {},
  /* eslint-disable camelcase */
  mutations: {
    setCities(state, users) {
      state.cities = users
    },
    deleteCity(state, userId) {
      const regData = {
        rows: state.cities.rows.filter(reg => reg.id !== userId),
        totalRecords: state.cities.totalRecords - 1,
      }
      state.cities = regData
    },
  },
  actions: {
    fetchUsers(store, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/email/template-list/data', { params: queryParams })
          .then(response => {
            store.commit('setCities', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchTemplate(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/email_templates/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTemplate(ctx, { payload }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/email_templates', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editTemplate(ctx, { id, payload }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/email_templates/${id}`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put('/user/update', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTemplate(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/email_templates/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    editCity(store, { id, payload }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/cities/${id}`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    autoComplete(store, search) {
      return new Promise((resolve, reject) => {
        axios
          .post('/templates/autocomplete', search)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    autoCompleteCourses(store, search) {
      return new Promise((resolve, reject) => {
        axios
          .post('/autocomplete/course', search)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
