<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 style="margin-bottom: 5px">
                {{ userData.name }}
                <b-button
                  v-b-tooltip.hover.right="'Copy student\'s name'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="flat-primary"
                  class="btn-icon rounded-circle mr-50"
                  @click="doCopy(userData.name, 'Student\'s name copied successfully')"
                >
                  <feather-icon icon="CopyIcon" />
                </b-button>
              </h4>
              <div class="d-flex align-items-center justify-content-start">
                <feather-icon
                  icon="MailIcon"
                  class="mr-75"
                />
                <span class="card-text">{{ userData.email }}</span>
                <b-button
                  v-b-tooltip.hover.right="'Copy the email'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="flat-primary"
                  class="btn-icon rounded-circle mr-50"
                  @click="doCopy(userData.email, 'Email copied successfully')"
                >
                  <feather-icon icon="CopyIcon" />
                </b-button>
              </div>
              <div class="d-flex align-items-center justify-content-start">
                <feather-icon
                  v-if="userData.phone"
                  icon="PhoneIcon"
                  class="mr-75"
                />
                <span class="card-text"> {{ userData.phone }}</span>
                <b-button
                  v-if="userData.phone"
                  v-b-tooltip.hover.right="'Copy the phone number'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="flat-primary"
                  class="btn-icon rounded-circle mr-50"
                  @click="doCopy(userData.phone, 'Phone copied successfully')"
                >
                  <feather-icon icon="CopyIcon" />
                </b-button>
              </div>

            </div>
            <div class="d-flex flex-wrap">
              <b-button
                v-b-tooltip.hover.bottom="'Edit infos'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :to="{ name: 'apps-users-edit', params: { id: $route.params.id } }"
                variant="primary"
                class="btn-icon mr-50"
              >
                Edit
                <!--                <feather-icon icon="EditIcon" />-->
              </b-button>
              <b-button
                v-if="userData.role === 'student'"
                v-b-modal.modal-2
                v-b-tooltip.hover.bottom="'Add to a course'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mr-50"
                @click="fetchPayers()"
              >
                <!--                <feather-icon icon="UserPlusIcon" />-->
                Assign to course
              </b-button>
              <b-button
                v-if="userData.student_id || userData.teacher_id"
                v-b-tooltip.hover.bottom="'Add notes'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mr-50"
                @click="handleChatClick({model: userData.role, id: userData.student_id ? userData.student_id : userData.teacher_id})"
              >
                Add a note
                <b-badge
                  pill
                  variant="dark"
                  class="text-capitalize"
                >
                  {{ userData.notes_count ? userData.notes_count : 0 }}
                </b-badge>
                <!--                <feather-icon icon="ClipboardIcon" />-->
              </b-button>
              <!--              <b-button
                v-if="userData.student_id"
                v-b-tooltip.hover.bottom="'Login as student'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon rounded-circle mr-50"
                @click="login(userData.student_id)"
              >
                <feather-icon icon="LogInIcon" />
              </b-button>
              <b-button
                v-b-modal.modal-1
                v-b-tooltip.hover.bottom="'Delete'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon rounded-circle mr-50"
              >
                <feather-icon icon="TrashIcon" />
              </b-button> -->
              <!-- <b-dropdown
                id="dropdown-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="Actions"
                variant="primary"
              >
                <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: $route.params.id } }">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="handleChatClick({model: userData.role, id: userData.student_id})">
                  <feather-icon icon="MessageSquareIcon" />
                  <span class="align-middle ml-50">Comment</span>
                </b-dropdown-item>
                <b-dropdown-item
                    v-if="userData.student_id"
                    @click="login(userData.student_id)">
                    <feather-icon icon="LogInIcon" />
                    <span class="align-middle ml-50">Login</span>
                  </b-dropdown-item>
                <b-dropdown-item v-b-modal.modal-1>
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown> -->
              <b-modal
                id="modal-1"
                :title="userData.name"
                ok-title="Accept"
                centered
                @ok="remove(userData.student_id)"
              >
                <b-card-text>
                  <h5>{{ $t('Are you sure you want to delete') }} <strong>{{ userData.name }}</strong></h5>
                </b-card-text>
              </b-modal>
              <b-modal
                id="restore-modal-student-profile"
                :title="restoreMessage"
                cancel-title="Restore old registration"
                ok-title="Create new registration"
                centered
                size="lg"
                @ok="save(selectedCourse ? selectedCourse.id : null, userData.student_id, selectedMethod, false)"
                @cancel="save(selectedCourse ? selectedCourse.id : null, userData.student_id, selectedMethod, true)"
              >
                <div class="d-flex align-items-center justify-content-start">
                  <feather-icon
                    icon="AlertTriangleIcon"
                    class="mr-25 mb-50 text-warning"
                  />
                  <h5 class="text-secondary">
                    The Student <strong
                      v-if="userData.name"
                      class="text-nowrap"
                    >{{ userData.name }}</strong>'s registration on the course <strong
                      v-if="selectedCourse && selectedCourse.group"
                      class="text-nowrap"
                    >{{ selectedCourse.group }}  </strong> is already deleted.
                  </h5>
                </div>
              </b-modal>
              <b-modal
                id="modal-2"
                :title="userData.name"
                ok-title="Add to course"
                ok-only
                centered
                size="lg"
                no-close-on-backdrop
                hide-footer
                @hidden="resetForm()"
              >
                <validation-observer
                  ref="refFomAssignToCourse"
                  #default="{invalid}"
                >
                  <!-- Form -->
                  <b-form
                    class="p-2"
                    @submit.prevent="save(selectedCourse ? selectedCourse.id : null, userData.student_id, selectedMethod)"
                  >
                    <b-row>
                      <b-col cols="6">
                        <validation-provider
                          #default="validationContext"
                          name="Levels"
                        >
                          <b-form-group
                            :label="$t('Levels')"
                            label-for="levels"
                          >
                            <v-select
                              v-model="selectedLevel"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="label"
                              :options="levels"
                              :clearable="false"
                              @input="filterLevel(selectedLevel.value)"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="6">
                        <validation-provider
                          #default="validationContext"
                          name="Mastercourse"
                        >
                          <b-form-group
                            :label="$t('Mastercourse')"
                            label-for="mastercoure"
                            :state="getValidationState(validationContext)"
                          >
                            <v-select
                              v-model="selectedMcourse"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="code"
                              :options="filteredMastercourses"
                              @input="autoCompleteCourse(selectedCourse ? selectedCourse.name : null, selectedDate, selectedMcourse)"
                            />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="6">
                        <validation-provider
                          #default="validationContext"
                          name="Start date"
                        >
                          <b-form-group
                            :label="('Start date')"
                            label-for="date_start"
                          >
                            <flat-pickr
                              v-model="selectedDate"
                              class="form-control"
                              :config="{ dateFormat: 'Y-m-d', altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 } }"
                              @input="autoCompleteCourse(selectedCourse ? selectedCourse.name : null, selectedDate, selectedMcourse)"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="6">
                        <validation-provider
                          #default="validationContext"
                          name="Course"
                          rules="required:id"
                        >
                          <b-form-group
                            label="Course"
                            label-for="course"
                            :state="getValidationState(validationContext)"
                          >
                            <v-select
                              v-model="selectedCourse"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="name"
                              :options="courses"
                              :clearable="false"
                              @keyup.native="autoCompleteCourse($event.target.value, selectedDate)"
                              @click.native="autoCompleteCourse('', selectedDate, selectedMcourse)"
                            >
                              <template #selected-option="{ code, group }">
                                <b class="text-primary">{{ code }} <small v-if="group">({{ group }})</small></b>
                              </template>
                              <span slot="no-options">
                                <div
                                  v-if="isTypingCourse"
                                  class="text-center d-flex align-items-center justify-content-center"
                                >
                                  <b-spinner
                                    variant="primary"
                                    label="Text Centered"
                                  />
                                </div>
                                <div
                                  v-if="!isTypingCourse && !courses.length"
                                  class="text-center d-flex align-items-center justify-content-center"
                                >
                                  No matching records found
                                </div>
                              </span>
                              <template #option="{ name }">
                                <div class="row">
                                  <b
                                    v-b-tooltip.hover.right="name.split('_')[0]"
                                    class="col-4 text-truncate"
                                  > {{ name.split('_')[0] }}</b>
                                  <small
                                    v-b-tooltip.hover.right="name.split('_')[1]"
                                    class="col-5 mt-25 text-truncate"
                                  >{{ name.split('_')[1] }}</small>
                                  <small
                                    v-b-tooltip.hover.right="name.split('_')[2]"
                                    class="col-3 text-truncate"
                                  >{{ name.split('_')[2] }}</small>
                                </div>
                              </template>
                            </v-select>
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <hr>
                    <!-- Payment -->
                    <b-row>
                      <b-col cols="12">
                        <!-- Who pays -->
                        <validation-provider
                          #default="validationContext"
                          name="Payer"
                          rules="required"
                        >
                          <b-form-group
                            :label="('Payer')"
                            label-for="who-pays"
                            :state="getValidationState(validationContext)"
                          >
                            <v-select
                              v-model="who_pays"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="name"
                              :options="payerOptions"
                              :clearable="false"
                              @input="resetRegistrationData(true)"
                            />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col
                        v-if="who_pays && (who_pays.code === 'payer' || who_pays.code === 'company')"
                        cols="6"
                      >
                        <!-- Payer name -->
                        <validation-provider
                          #default="validationContext"
                          name="Payer name"
                          rules="required"
                        >
                          <b-form-group
                            :label="('Payer name')"
                            label-for="payer-name"
                          >
                            <b-form-input
                              v-model="payer_name"
                              autofocus
                              :state="getValidationState(validationContext)"
                              :placeholder="('Payer name')"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col
                        v-if="who_pays && (who_pays.code === 'payer' || who_pays.code === 'company')"
                        cols="6"
                      >
                        <!-- Payer address -->
                        <validation-provider
                          #default="validationContext"
                          name="Invoicing address"
                          rules="required"
                        >
                          <b-form-group
                            :label="$t('Invoicing address')"
                            label-for="invoicing-address"
                          >
                            <b-form-input
                              v-model="payer_address"
                              autofocus
                              :state="getValidationState(validationContext)"
                              :placeholder="('Invoicing address')"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col
                        v-if="who_pays && who_pays.code === 'company'"
                        cols="6"
                      >
                        <!-- Company number -->
                        <validation-provider
                          #default="validationContext"
                          name="Company org.number"
                          rules="required"
                        >
                          <b-form-group
                            :label="$t('Company org.number')"
                            label-for="Company-org.number"
                          >
                            <b-form-input
                              v-model="company_number"
                              autofocus
                              :state="getValidationState(validationContext)"
                              trim
                              :placeholder="('Company org.number')"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col
                        v-if="who_pays && who_pays.code === 'company'"
                        cols="6"
                      >
                        <!-- Responsible -->
                        <validation-provider
                          #default="validationContext"
                          name="Responsible / contact person"
                          rules="required"
                        >
                          <b-form-group
                            :label="('Responsible / contact person')"
                            label-for="Resposible-contact-person"
                          >
                            <b-form-input
                              v-model="responsible"
                              autofocus
                              :state="getValidationState(validationContext)"
                              :placeholder="('Responsible / contact person')"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col
                        v-if="who_pays && (who_pays.code === 'payer' || who_pays.code === 'company')"
                        cols="6"
                      >
                        <!-- Phone number -->
                        <validation-provider
                          #default="validationContext"
                          name="Phone number"
                          rules="required"
                        >
                          <b-form-group
                            :label="('Phone number')"
                            label-for="Phone-number"
                          >
                            <b-form-input
                              v-model="payer_phone"
                              autofocus
                              :state="getValidationState(validationContext)"
                              trim
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43"
                              :placeholder="('Phone number')"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col
                        v-if="who_pays && (who_pays.code === 'payer' || who_pays.code === 'company')"
                        cols="6"
                      >
                        <!-- Email address -->
                        <validation-provider
                          #default="validationContext"
                          name="Email address"
                          rules="required|email"
                        >
                          <b-form-group
                            :label="('Email address')"
                            label-for="Email-address"
                          >
                            <b-form-input
                              v-model="payer_email"
                              autofocus
                              type="email"
                              :state="getValidationState(validationContext)"
                              trim
                              :placeholder="('Email address')"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="6">
                        <!-- Paid -->
                        <validation-provider
                          #default="validationContext"
                          name="Payment status"
                          rules="required"
                        >
                          <b-form-group
                            label="Payment status"
                            label-for="paid"
                            :state="getValidationState(validationContext)"
                          >
                            <v-select
                              v-model="paid"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="name"
                              :reduce="x => x.id"
                              :options="paidOptions.filter(el => !el.filtered)"
                              :clearable="false"
                            />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="6">
                        <validation-provider
                          #default="validationContext"
                          name="Payment method"
                          rules="required"
                        >
                          <b-form-group
                            :label="$t('Payment method')"
                            label-for="payment-method"
                          >
                            <v-select
                              v-model="selectedMethod"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="name"
                              :reduce="x => x.id"
                              :options="methods"
                              :clearable="false"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end mt-1">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class=""
                        type="submit"
                        :disabled="invalid"
                      >
                        Add to course
                      </b-button>
                    </div>
                  </b-form>
                </validation-observer>
              </b-modal>
              <div
                v-if="isChatActive"
                class="notesModule shadow-lg"
              >
                <div
                  style="background: #fff"
                  class="pb-1"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="light"
                    size="sm"
                    class="btn-icon shadow-sm ml-auto "
                    style="display: block; transform: translate(8px, -2px);"
                    @click="isChatActive = false"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </div>
                <chat-module
                  :model-id="chatModelId.id"
                  :model-type="chatModelId.model"
                  @notes-increament="userData.notes_count++"
                  @decrease-notes="userData.notes_count--"
                />
              </div>
              <!-- <div
                v-if="isChatActive"
                class="commentschats"
              >
                <feather-icon
                  icon="XSquareIcon"
                  size="22"
                  class="align-middle text-body close-btn"
                  @click="isChatActive = false"
                />
                <chat-module
                  :model-id="chatModelId.id"
                  :model-type="chatModelId.model"
                  @notes-increament="userData.notes_count++"
                  @decrease-notes="userData.notes_count--"
                />
              </div> -->
              <!-- <b-button
                :to="{ name: 'apps-users-edit', params: { id: $route.params.id } }"
                variant="primary"
              >
                {{ $t('Edit') }}
              </b-button> -->
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('ID') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ $route.path.split('/')[5] }}
              <!-- {{ userData.role }} -->
              <b-button
                v-b-tooltip.hover.right="'Copy the student ID'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-primary"
                class="btn-icon mr-50"
                style="padding : 1px 1px;"
                @click="doCopy($route.path.split('/')[5], 'Student ID copied successfully')"
              >
                <feather-icon icon="CopyIcon" />
              </b-button>
            </td>
          </tr>
          <tr>
            <th
              v-if="userData.dob"
              class="pb-50"
            >
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Birth date') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              <!-- {{ $route.path.split('/')[5] }} -->
              {{ userData.dob }}
            </td>
          </tr>
          <tr v-if="userData.address">
            <th class="pb-50">
              <feather-icon
                icon="NavigationIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Address') }}</span>
            </th>
            <td class="pb-50">
              {{ userData.address }}
            </td>
          </tr>
          <tr v-if="userData.city">
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('City') }}</span>
            </th>
            <td class="pb-50">
              {{ userData.city }} {{ (userData.postcode !== null) ? ',' + userData.postcode : '' }}
            </td>
          </tr>
          <tr v-if="userData.country !== ''">
            <th>
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Country') }}</span>
            </th>
            <td>
              {{ userData.country }}
            </td>
          </tr>
          <tr v-if="userData.levels">
            <th>
              <feather-icon
                icon="BarChartIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ ('Levels') }}</span>
            </th>
            <td>
              {{ userData.levels }}
            </td>
          </tr>
          <tr v-if="userData.days">
            <th>
              <feather-icon
                icon="CheckSquareIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ ('Days') }}</span>
            </th>
            <td>
              {{ userData.days }}
            </td>
          </tr>
          <tr v-if="userData.time_frames && userData.role === 'teacher'">
            <th class="d-flex mt-25">
              <feather-icon
                icon="ClockIcon"
                class="mr-75"
              />
              <span class="font-weight-bold text-nowrap">{{ ('Time') }}</span>
            </th>
            <td>
              <span
                v-for="(time, indx) in userData.time_frames.filter(el => el.days === 'all')"
                :key="indx"
                class="d-block"
              >{{ time.start }} - {{ time.end }}</span>
              <span
                v-if="userData.time_frames.some(el => el.days === 'sa')"
              >
                {{ userData.time_frames.find(el => el.days === 'sa').start }} - {{ userData.time_frames.find(el => el.days === 'sa').end }}
                (Saturday time)
              </span>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardText, BButton, VBTooltip, BFormGroup, BSpinner, BBadge, BFormInvalidFeedback, BForm, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import router from '@/router'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ChatModule from '@/views/admin/ChatModule.vue'
// import useUsersList from '../users-list/useUsersList'
import userStoreModule from '../userStoreModule'

export default {
  components: {
    BCard, BRow, BCol, BCardText, ChatModule, BButton, vSelect, flatPickr, BFormGroup, BSpinner, BBadge, ValidationProvider, ValidationObserver, BFormInvalidFeedback, BForm, BFormInput,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      courses: [],
      mastercourses: [],
      filteredMastercourses: [],
      levels: [
        { label: 'All', value: null },
        { label: 'Beginner', value: 'beginner' },
        { label: 'Intermediate', value: 'intermediate' },
        { label: 'Advanced', value: 'advanced' },
      ],
      methods: [
        { label: 'Card', value: 'card' },
        { label: 'Cash', value: 'cash' },
        // { label: 'Online', value: 'online' },
        { label: 'Wire transfer', value: 'wire_transfer' },
      ],

      isTypingCourse: false,
      paidOptions: [],
      selectedLevel: { label: 'All', value: null },
      selectedMcourse: null,
      selectedDate: new Date(),
      selectedCourse: null,
      paid: null,
      selectedMethod: null,
      who_pays: null,
      payer_name: null,
      company_number: null,
      payer_address: null,
      responsible: null,
      payer_phone: null,
      payer_email: null,
      restoreMessage: null,
    }
  },
  mounted() {
    /* eslint-disable prefer-template, no-return-assign */
    this.$http.get('/master-course-list/data?perPage=100&sortBy=code').then(res => {
      this.mastercourses = res.data.rows
      this.filteredMastercourses = this.mastercourses
    })
    this.$http.get('/invoice/paid/method/list').then(res => {
      this.methods = res.data.rows
      // this.selectedMethod = this.methods.find(el => el.name === 'Card')
    })
    this.$http.get('/registration/filter/list').then(res => {
      this.paidOptions = res.data.rows
      // this.paid = this.paidOptions.find(el => el.name === 'Paid')
    })
      const today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0')
      const yyyy = today.getFullYear()
      this.selectedDate = yyyy + '-' + mm + '-' + dd
  },
  methods: {
    resetForm() {
      this.selectedLevel = { label: 'All', value: null }
      this.selectedMcourse = null
      this.selectedDate = new Date()
      this.selectedCourse = null
      this.paid = null
      this.selectedMethod = null
      this.who_pays = null
      this.payer_name = null
      this.company_number = null
      this.payer_address = null
      this.responsible = null
      this.payer_phone = null
      this.payer_email = null
      this.restoreMessage = null
    },
    filterLevel(level) {
      this.filteredMastercourses = this.mastercourses
      if (!level) return
      this.filteredMastercourses = this.mastercourses.filter(mCourse => mCourse.level === level)
    },
    doCopy(data, message) {
      this.$copyText(data).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: (typeof message !== 'undefined') ? message : 'Copied successfully',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    login(id) {
      this.$http.post(`/login/as/student/${id}`).then(res => {
        localStorage.altToken = localStorage.accessToken
        localStorage.altUserData = localStorage.userData
        const tokens = res.data.accessToken.split('.')
        const decodedToken = JSON.parse(atob(tokens[1]))
        localStorage.accessToken = res.data.accessToken
        if (typeof decodedToken !== 'undefined') {
          localStorage.userData = JSON.stringify(decodedToken)
          if (decodedToken.language && decodedToken.language.length) {
            this.$i18n.locale = decodedToken.language
          }
          this.$ability.update(decodedToken.ability)
          this.$router.replace(getHomeRouteForLoggedInUser(decodedToken.role, 'fromLogin'))
          .then(() => {
            store.state.appConfig.isLoggedIn = true
            store.state.appConfig.adminLoggedAsStudent = true
            window.location.reload()
          })
        }
      })
    },
    /* eslint-disable camelcase */
    autoCompleteCourse(search, date_start, mastercourse, reset) {
      if (reset) {
        this.selectedCourse = null
      }
      this.isTypingCourse = true
      this.$http.post('/autocomplete/course', { search, date_start, mastercourse_id: mastercourse ? mastercourse.id : null })
      .then(res => {
        this.courses = res.data
        this.isTypingCourse = false
      })
    },
    save(course_id, student_id, paid_method_id, restore) {
      this.$refs.refFomAssignToCourse.validate().then(success => {
        if (success) {
          const payload = {
            course_id,
            student_id,
            paid_method_id,
            payment_status: this.paid,
            payer_id: this.who_pays.id,
            payer_name: this.payer_name,
            company_number: this.company_number,
            payer_address: this.payer_address,
            responsible: this.responsible,
            payer_phone: this.payer_phone,
            payer_email: this.payer_email,
            restore,
          }
          this.$http.post('/registrations', payload)
          .then(res => {
              this.selectedLevel = { label: 'All', value: null }
              this.selectedMcourse = {}
              this.selectedDate = new Date()
              this.selectedCourse = {}
              this.paid = {}
              this.selectedMethod = {}
              this.who_pays = null
              this.payer_name = null
              this.company_number = null
              this.payer_address = null
              this.responsible = null
              this.payer_phone = null
              this.payer_email = null
            this.$bvModal.hide('modal-2')
            this.$emit('refresh-course-list')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            }).catch(error => {
              if (error.response.data.data && error.response.data.data.deleted) {
                this.restoreMessage = error.response.data.message
                return this.$bvModal.show('restore-modal-student-profile')
              }
              return this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const toast = useToast()
    // const { resolveUserRoleVariant } = useUsersList()
    const isChatActive = ref(false)
    const chatModelId = ref(null)
    const payerOptions = ref([])
    const handleChatClick = data => {
      isChatActive.value = true
      chatModelId.value = data
    }
    const remove = id => {
      store.dispatch('userStoreModule/deleteUser', id)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'User Has Been deleted',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          router.push({
            name: 'apps-users-list-student',
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while deleting user',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const fetchPayers = () => {
      store.dispatch('registerStoreModule/fetchPayers').then(res => payerOptions.value = res.data.rows)
    }
    const resetRegistrationData = () => {
      // payer_name.value = null
      // company_number.value = null
      // payer_address.value = null
      // responsible.value = null
      // payer_phone.value = null
      // payer_email.value = null
      // selectedWhoPays.value = ''
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRegistrationData)
    return {
      payerOptions,
      fetchPayers,
      resetRegistrationData,
      refFormObserver,
      getValidationState,
      resetForm,

      avatarText,
      remove,
      isChatActive,
      chatModelId,
      handleChatClick,
      // resolveUserRoleVariant,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
