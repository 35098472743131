<template>
  <div>
    <!-- warning modal-->
    <b-modal
      :id="'modalwarning'"
      title="Your sessions will be overriden"
      ok-title="Accept"
      @ok="handleOk(selected, courseId, true)"
    >
      <b-card-text>
        <h5>Some sessions belonging to this course have a different teacher than the one specified, <strong> override ?</strong></h5>
      </b-card-text>
    </b-modal>
    <!-- main suggest modal-->
    <b-modal
      id="modal-center"
      :visible="isModalSActive"
      :title="'Teachers list'"
      size="lg"
      hide-footer
      centered
      no-close-on-backdrop
      @change="(val) => $emit('update:isModalSActive', val)"
    >
      <b-row class="mb-1">
        <b-col
          md="10"
        >
          <v-select
            v-model="selected"
            :options="options"
            label="name"
            :reduce="x => x.id"
            multiple
            :selectable="el => el.available === 'available'"
          >
            <template #option="{ name, code, available }">
              <div class="d-flex align-items-center justify-content-between">
                <span class="d-block"> {{ code }}</span>
                <small class="ml-50">
                  <b-badge
                    pill
                    :variant="`light-${available === 'available' ? 'success' : 'danger'}`"
                    class="text-capitalize"
                  >
                    {{ available === 'available' ? 'Available' : 'Unavailable' }}
                  </b-badge>
                </small>
                <!-- <small class="ml-50" :class="available ? 'text-success' : 'text-danger'">{{ available ? 'Available' : 'Unavailable' }}</small> -->
              </div>
              <small class="text-muted">{{ name }}</small>
            </template>
            <span slot="no-options">
              <div
                v-if="spinner"
                class="text-center d-flex align-items-center justify-content-center"
              >
                <b-spinner
                  variant="primary"
                  label="Text Centered"
                />
              </div>
              <div
                v-if="!spinner && options.length === 0"
                class="text-center d-flex align-items-center justify-content-center"
              >
                No matching records found
              </div>
            </span>
          </v-select>
        </b-col>
        <b-col
          md="2"
        >
          <b-button
            variant="primary"
            @click="handleOk(selected, courseId, false)"
          >
            OK
          </b-button>
        </b-col>
      </b-row>
      <!-- v-if="options.length === 0" -->
      <!-- <div
        v-if="teachers.length === 0"
        class="d-flex justify-content-center p-2 align-items-center"
      >
        <b-spinner
          variant="primary"
          label="Loading..."
        />
      </div>
      <b-list-group style="columns: 2; display: inherit;">
        <b-list-group-item
          v-for="teacher in teachers"
          :key="teacher.value"
          style="padding: 0.3rem 1.25rem; width: 100%; display: inline-block;"
          class="border-0"
        >
          <b-form-checkbox
            v-model="selected"
            :value="teacher.id"
          >
            {{ teacher.name }}
          </b-form-checkbox>
        </b-list-group-item>
      </b-list-group>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="d-block ml-auto"
            @click="handleOk(selected, courseId, false)"
          >
            OK
          </b-button>
        </div>
      </template> -->
    </b-modal>
  </div>
</template>

<script>
  import {
    BModal, BRow, BCol, BButton, BCardText, BBadge, BSpinner,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import store from '@/store'
  import coursesStoreModule from '../coursesStoreModule'

  export default {
    name: 'SuggestTeacher',
    components: {
      BModal, BRow, BCol, BButton, BCardText, BBadge, BSpinner, vSelect,
    },
    model: {
      prop: 'isModalSActive',
      event: 'update:isModalSActive',
    },
    props: {
      courseId: {
        type: Number,
        default: 99,
      },
      // teachers: {
      //   type: Array,
      // },
      isModalSActive: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isModalActive: true,
        selected: [],
        options: [],
        spinner: false,
        // overrid: false,
      }
    },
    mounted() {
      const USER_APP_STORE_MODULE_NAME = 'app-courses'
      // Register module
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, coursesStoreModule)
      this.fetchTeachers()
    },
    methods: {
      fetchTeachers() {
        this.spinner = true
        store.dispatch('app-courses/getTeachersByCourse', { course_id: this.courseId })
          .then(res => {
            this.options = res.data.allTeachers
            this.spinner = false
          })
      },
      handleOk(selected, courseId, override) {
        store.dispatch('app-courses/setCourseTeacher', { // setCourseTeacher
          teachers: selected,
          course_id: courseId,
          override,
        })
          .then(res => {
            const resp = store.state.coursesStoreModule.suggestTeachersResponse.response
            if (resp === 'warning') {
              this.$bvModal.show('modalwarning')
            }
            if (resp === 'success') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'success',
                },
              })
              this.$emit('refetch-data')
              this.$emit('update:isModalSActive', false)
              this.selected = null
            }
          }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error while adding teachers',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      },
    },
  }
</script>

<style scoped>

</style>
