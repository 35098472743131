<template>
  <!-- eslint-disable vue/mustache-interpolation-spacing -->
  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            lg="6"
          >
            <v-select
              v-model="selectedFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filters"
              lebel="label"
              :clearable="false"
              class="courses-filter d-inline-block mx-50"
              style="min-width: 150px;"
            />
            <v-select
              v-model="selectedFilter2"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filters2"
              lebel="label"
              :clearable="false"
              class="courses-filter d-inline-block mx-50"
              style="min-width: 220px;"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
                debounce="500"
              />
            </div>
          </b-col>
          <!-- Search -->
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        :busy.sync="isBusy"
        class="position-relative"
        :items="fetchElements"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>
        <!-- Column: Name -->
        <template #cell(name)="data">
          <span
            v-b-tooltip.hover.top="data.item.name"
            class="d-inline-block text-truncate"
            style="max-width: 300px;"
          >{{ data.item.name }}</span>
        </template>
        <template #cell(course_name)="data">
          <span
            class="d-block text-truncate text-primary"
            style="max-width: 300px;"
          >{{ data.item.group_name }}</span>
          <small cla>{{ data.item.course_name }}</small>
        </template>
        <template #cell(reason)="data">
          <div class="row">
            <span
              v-b-popover.hover.top="data.item.reason"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              title="Reason"
              variant="outline-primary"
              class="text-truncate col-6"
            >
              {{ data.item.reason }}
            </span>
          </div>
        </template>
        <!-- Column: Teacher -->
        <template #cell(teacher)="data">
          <b-avatar
            v-if="data.item.teacher"
            v-b-tooltip.hover
            class="pull-up"
            button
            size="38"
            :title="data.item.teacher.name"
            :src="data.item.teacher.avatar.split('/')[5] === 'default-avatar.jpg' ? null : data.item.teacher.avatar"
            :text="avatarText(data.item.teacher.name)"
            @click="handleChatClick1(data.item.teacher.id)"
          />
        </template>
        <!-- Column: Teacher -->
        <template #cell(admin)="data">
          <b-avatar
            v-if="data.item.admin"
            v-b-tooltip.hover
            class="pull-up"
            size="38"
            :title="data.item.admin.name"
            :src="data.item.admin.avatar.split('/')[5] === 'default-avatar.jpg' ? null : data.item.admin.avatar"
            :text="avatarText(data.item.admin.name)"
          />
        </template>
        <!-- Column: Name -->
        <template #cell(date)="data">
          <span class="font-weight-bold d-block text-nowrap text-capitalize">
            {{ data.item.date_start }} {{ data.item.approval_type === 'course' ? '- ' + data.item.date_end : '' }}</span>
          <small class="text-muted text-nowrap">{{ data.item.time_from }} - {{ data.item.time_to }}</small>
        </template>
        <!-- Column: Name -->
        <template #cell(action_date)="data">
          <span
            v-if="data.item.action_date"
            class="font-weight-bold d-block text-nowrap text-capitalize"
          >
            {{ data.item.action_date.split(' ')[0] }}</span>
          <small
            v-if="data.item.action_date"
          >{{ data.item.action_date.split(' ')[1] }}</small>
        </template>
        <!-- Column: Status -->
        <template #cell(awaiting_status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.awaiting_status)}`"
            class="text-capitalize"
          >
            {{ data.item.awaiting_status }}
          </b-badge>
        </template>

      </b-table>
      <div
        v-if="isChatActive1"
        class="commentschats"
      >
        <feather-icon
          icon="XSquareIcon"
          size="22"
          class="align-middle text-body close-btn"
          @click="isChatActive1 = false"
        />
        <chat-module
          :conversation="conversation"
          :messages="messages"
        />
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{$t('Show')}}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{$t('entries')}}</label>
          </b-col>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{$t('Showing')}} {{ dataMeta.from }} {{$t('to')}} {{ dataMeta.to }} {{$t('of')}} {{ dataMeta.of }} {{$t('entries')}}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="!isBusy"
              v-model="currentPage"
              :total-rows="totalElements"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BAvatar, BFormInput,
  BBadge, BPagination, BSpinner, VBTooltip, VBPopover,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import ChatModule from '@/views/admin/ChatModule.vue'
import store from '@/store'
// import UsersListFilters from './UsersListFilters.vue'
import useApproveHistoryList from './useApproveHistoryList'
import approveHistoryStoreModule from './approveHistoryStoreModule'

export default {
  components: {
    ChatModule,
    // UsersListFilters,

    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BPagination,
    BSpinner,
    BAvatar,
    BFormInput,

    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      avatarText,
      statusOptions: ['Active', 'Deleted'],
      isChatActive1: false,
      conversation: {},
      messages: [],
      userData: JSON.parse(localStorage.userData),
    }
  },
  methods: {
    handleChatClick1(id) {
      this.$http.post('/conversation', { participants: [id], user_id: this.userData.id })
      .then(res => {
        this.isChatActive1 = true
        this.conversation = {
          id: res.data.id,
          avatar: res.data.avatar,
          name: res.data.name,
        }
        this.messages = {
          data: res.data.messages,
        }
      })
    },
  },
  setup() {
    /* eslint-disable camelcase */
    const getModal1Id = id => `modal1${id}`
    const getModal2Id = id => `modal2${id}`

    const APPROVALHISTORY_STORE_MODULE_NAME = 'approvalHistory'

    // Register module
    if (!store.hasModule(APPROVALHISTORY_STORE_MODULE_NAME)) store.registerModule(APPROVALHISTORY_STORE_MODULE_NAME, approveHistoryStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APPROVALHISTORY_STORE_MODULE_NAME)) store.unregisterModule(APPROVALHISTORY_STORE_MODULE_NAME)
    })

    const userData = JSON.parse(localStorage.getItem('userData'))
    const userRole = userData.role

    // Use toast

    const {
      isBusy,
      fetchElements,
      tableColumns,
      perPage,
      currentPage,
      totalElements,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      selectedStatus,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      selectedFilter,
      selectedFilter2,
      searchQuery,
      filters,
      filters2,
    } = useApproveHistoryList()

    return {
      isBusy,
      // Modal ids
      getModal1Id,
      getModal2Id,

      // Actions
      userRole,
      selectedStatus,

      // Sidebar

      fetchElements,
      tableColumns,
      perPage,
      currentPage,
      totalElements,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      filters,
      filters2,
      selectedFilter,
      selectedFilter2,
      searchQuery,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
