import {
  ref, watch,
  computed, onMounted,
} from '@vue/composition-api'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import store from '@/store'
import router from '@/router'

export default function useCoursesList() {
  // Use toast
  const toast = useToast()
  const { t } = useI18nUtils()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: t('id'), sortable: true },

    { key: 'code', label: 'level' },
    { key: 'group', label: t('group') },
    { key: 'date', label: t('date & time'), sortable: true },
    { key: 'classroom', label: t('classroom') },
    { key: 'attendance', label: t('attendance') },
    { key: 'status', label: 'reg status', sortable: true },
    // { key: 'reviews', label: t('reviews'), sortable: true },
    { key: 'comments', label: t('notes') },
    { key: 'actions', label: t('actions'), tdClass: 'table-actions' },
  ]
  const tableColumns1 = [
    // { key: 'id', label: t('id'), sortable: true },

    { key: 'code', label: 'level' },
    { key: 'group', label: t('group') },
    { key: 'date', label: t('date & time'), sortable: true },
    { key: 'classroom', label: t('classroom') },
    { key: 'attendance', label: t('attendance') },
    { key: 'status', label: 'status' },
    // { key: 'reviews', label: t('reviews'), sortable: true },
    // { key: 'comments', label: t('notes') },
    { key: 'actions', label: t('actions'), tdClass: 'table-actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const userData = ref(JSON.parse(localStorage.userData))
  const studentId = ref(userData.id)
  const StudentId = ref(null)
  /* eslint-disable-next-line */
  const role_name = ref(window.location.href.split('/')[3])

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }
  const urlParams = new URLSearchParams(window.location.search)
  const urlQueries = router.currentRoute.query
  if (urlQueries.search) searchQuery.value = urlQueries.search
  if (urlQueries.page) currentPage.value = urlQueries.page
  if (urlQueries.perPage) perPage.value = urlQueries.perPage
  /* eslint-disable-next-line */
  watch([currentPage, perPage, roleFilter, planFilter, statusFilter, role_name, searchQuery], () => {
    urlParams.set('search', searchQuery.value ? searchQuery.value : '')
    urlParams.set('page', currentPage.value ? currentPage.value : '')
    urlParams.set('perPage', perPage.value ? perPage.value : '')
    // urlParams.set('filter', selectedFilter.value ? selectedFilter.value : '')
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams.toString()}`)

    refetchData()
  })
  const isBusy = ref(false)

  const fetchUsers = (ctx, callback) => {
    const payload = {
      search: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      role_name: roleFilter.value,
      plan: planFilter.value,
      status: statusFilter.value,
      student_id: StudentId.value ? StudentId.value : userData.value.id,
      // role_name: role_name.value,
    }
    store
      .dispatch('classroom/fetchClassrooms', payload)
      .then(res => {
        const users = res.data.rows
        const total = res.data.totalRecords
        callback(users)
        totalUsers.value = total
      })
      .catch(() => {
        isBusy.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching courses list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const cities = ref([])
  const masters = ref([])
  const classrooms = ref([])
  const teachers = ref([])
  const TofDArray = ref([
    { name: 'daytime', value: 'daytime' },
    { name: 'anytime', value: 'anytime' },
    { name: 'evening', value: 'evening' },
  ])
  const statusArray = ref([
    { name: 'Active', value: 'active', color: 'primary' },
    { name: 'Hidden', value: 'hidden', color: 'primary' },
    { name: 'Past', value: 'past', color: 'primary' },
    { name: 'Deleted', value: 'deleted', color: 'primary' },
    { name: 'Planned', value: 'planned', color: 'primary' },
  ])
  // lists
  const fetchCities = () => {
    store.dispatch('coursesStoreModule/fetchCities')
      .then(() => {
        cities.value = store.state.coursesStoreModule.cities
      })
  }
  const fetchMCourses = () => {
    store.dispatch('coursesStoreModule/fetchMCourses', { group: null, perPage: 100, sortBy: 'code' })
      .then(() => {
        masters.value = store.state.coursesStoreModule.Mcourses
      })
  }
  const fetchTeachers = () => {
    store.dispatch('coursesStoreModule/fetchTeachers')
      .then(() => {
        teachers.value = store.state.coursesStoreModule.teachers
      })
  }
  const fetchClassrooms = () => {
    store.dispatch('coursesStoreModule/fetchClassrooms')
      .then(() => {
        classrooms.value = store.state.coursesStoreModule.classrooms
      })
  }
  const suggestClassrooms = (record, Days) => {
    /* eslint-disable no-param-reassign */
    record.days = Days.join(',')
    store.dispatch('coursesStoreModule/suggestClassrooms', record)
      .then(res => {
        classrooms.value = res.data.available
      })
  }
  const suggestTeacher = (record, Days) => {
    /* eslint-disable no-param-reassign */
    record.days = Days.join(',')
    store.dispatch('coursesStoreModule/suggestTeacher', record)
      .then(res => {
        teachers.value = res.data.available
      })
  }
  // call on mounted
  if (studentId.value) {
    onMounted(fetchMCourses)
    onMounted(fetchTeachers)
    onMounted(fetchClassrooms)
    onMounted(fetchCities)
  }
  //

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveAttendanceVariant = attendance => {
    if (attendance > 0) return 'flat-warning'
    if (attendance > 80) return 'flat-success'
    return 'flat-danger'
  }
  const resolveUserStatusVariant = status => {
    if (status === 'On going') return 'success'
    if (status === 'Pending Confirm' || status === 'Cancel pending') return 'warning'
    if (status === 'Planned') return 'secondary'
    if (status === 'Canceled' || status === 'Passed') return 'danger'
    return 'primary'
  }
  const resolveStatusVariant = status => {
    if (status === 'confirmed') return 'success'
    if (status === 'pending') return 'warning'
    if (status === 'canceled') return 'danger'
    return 'primary'
  }

  return {
    isBusy,
    fetchUsers,
    tableColumns,
    tableColumns1,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    role_name,
    studentId,
    userData,
    StudentId,

    resolveAttendanceVariant,
    resolveUserStatusVariant,
    resolveStatusVariant,
    refetchData,

    fetchCities,
    fetchMCourses,
    fetchTeachers,
    fetchClassrooms,
    suggestClassrooms,
    suggestTeacher,

    masters,
    cities,
    classrooms,
    teachers,
    TofDArray,
    statusArray,

  }
}
