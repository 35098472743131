<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2">
        {{ $t('settings.notification.Activity') }}
      </h6>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch1"
          v-model="localOptions.emailNotifications"
          :checked="localOptions.emailNotifications"
          switch
          inline
        >
          <span>Enable email notification</span>
        </b-form-checkbox>
      </b-col>

      <!-- application switch -->
      <h6 class="section-label mx-1 mt-2">
        {{ $t('settings.notification.Application') }}
      </h6>
      <b-col
        cols="12"
        class="mt-1 mb-2"
      >
        <b-form-checkbox
          id="accountSwitch4"
          v-model="localOptions.newAnnouncements"
          :checked="localOptions.newAnnouncements"
          switch
          inline
        >
          <span>{{ $t('settings.notification.News and announcements') }}</span>
        </b-form-checkbox>
      </b-col>

      <!--/ application switch -->
      <!-- buttons -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1 mt-1"
          @click="save()"
        >
          {{ $t('settings.Save Changes') }}
        </b-button>
      </b-col>
      <!--/ buttons -->

    </b-row>
  </b-card>
</template>

<script>
import {
  BButton, BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    notificationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localOptions: JSON.parse(JSON.stringify(this.notificationData)),
    }
  },
  mounted() {
    this.localOptions.newAnnouncements = this.localOptions.newAnnouncements === '0' ? 'false' : 'true'
    this.localOptions.emailNotifications = this.localOptions.emailNotifications === '0' ? 'false' : 'true'
  },
  methods: {
    save() {
      const body = {
        newAnnouncements: this.localOptions.newAnnouncements,
        emailNotifications: this.localOptions.emailNotifications,
      }
      this.$http.post('/account-setting/info', body, {
        headers: {
          Accept: 'application/json',
        },
      }).then(response => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            text: response.data.message,
            icon: 'CheckIcon',
          },
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            text: error.response.data.message,
            icon: 'AlertTriangleIcon',
          },
        })
        this.$refs.generalData.setErrors(error.response.data.errors)
      })
    },
  },
}
</script>
