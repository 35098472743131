<template>
  <div>
    <b-button
      v-if="classroom.classroom_external_data"
      size="sm"
      class="mb-1 ml-50"
      :href="classroom.classroom_external_data"
      target="_blank"
      variant="primary"
      style="float: right"
      :tbody-tr-class="tbodyRowClass"
    >
      {{ 'Classroom link' }}
      <feather-icon icon="ExternalLinkIcon" />
    </b-button>
    <div class="demo-spacing-0 d-flex align-items-start justify-content-between">
      <!-- <b-alert
        show
        variant="primary"
        class="flex-grow-1 mr-1"
      >
        <div class="alert-body">
          <feather-icon
            class="mr-25"
            icon="StarIcon"
          />
          <span class="ml-25">{{ course.course_group ? course.course_group : 'A1901t1100 ' }} ekstra under. 7 mai.</span>
        </div>
      </b-alert> -->
      <b-media
        v-if="userData.role === 'teacher' && items.length > 0"
        no-body
        class="mb-1"
      >
        <b-media-aside v-if="file || items[0].picture">
          <b-link target="_blank" :href="items[0].picture">
            <b-img
              ref="previewEl"
              rounded
              :src="items[0].picture"
              alt="Image"
              height="80"
            />
          </b-link>
          <!--/ avatar -->
        </b-media-aside>

        <b-media-body>
          <!-- upload button -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            @click="$refs.refInputEl.$el.click()"
          >
            {{ $t('settings.general.Upload') }}
          </b-button>
          <b-form-file
            ref="refInputEl"
            v-model="file"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            @input="inputImageRenderer"
          />
          <!--/ upload button -->
        </b-media-body>
      </b-media>
      <!-- <b-form-file
        v-if="userData.role === 'teacher'"
        v-model="file"
        class="mr-1 mb-1"
        accept="image/*"
        placeholder="Add an image"
        drop-placeholder="Drop file here..."
        style="max-width: 202px"
      /> -->
      <b-button
        v-if="userData.role === 'teacher' && session.homework"
        v-b-modal.modal-homework
        v-b-tooltip.hover.top="'Homework'"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        :variant="session.homework.link ? 'info' : 'secondary'"
        size="sm"
        class="btn-icon mb-1"
      >
        <feather-icon
          icon="BookOpenIcon"
        />
        <!-- <span>Add homework</span> -->
      </b-button>
      <b-modal
        id="modal-homework"
        title="Homework"
        ok-only
        ok-title="Save"
        hide-footer
        centered
        no-close-on-backdrop
        @ok="saveHomeworks"
      >
        <validation-observer ref="simpleRules">
          <validation-provider
            #default="{ errors }"
            name="URL"
            rules="required|url"
          >
            <b-form-input
              v-model="session.homework.link"
              :state="errors.length > 0 ? false:null"
              placeholder="htttps://www.google.com"
              type="url"
              trim
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <b-button
            class="mt-1 d-block"
            variant="primary"
            type="submit"
            @click.prevent="saveHomeworks"
          >
            Save
          </b-button>
        </validation-observer>
      </b-modal>
    </div>
    <b-table
      responsive="sm"
      :items="items"
      :fields="tableColumns"
      show-empty
      empty-text="No matching records found"
      :tbody-tr-class="tbodyRowClass"
      :busy.sync="isBusy"
    >
      <template #table-busy>
        <div
          v-if="isBusy"
          class="text-center d-flex align-items-center justify-content-center"
        >
          <b-spinner
            variant="primary"
            label="Text Centered"
          />
          <strong class="ml-1">Loading...</strong>
        </div>
      </template>
      <template #cell(student_name)="data">
        <feather-icon
          v-if="data.item.moved_from"
          v-b-popover.hover.top="`Moved from ${data.item.moved_from.course} (${data.item.moved_from.group})`"
          icon="CornerRightDownIcon"
          class="text-warning mr-50 b-avatar b-avatar-sm badge-light-warning rounded-circle p-25"
        />
        <feather-icon
          v-if="data.item.moved_to"
          v-b-popover.hover.top="`Moved to ${data.item.moved_to.course} (${data.item.moved_to.group})`"
          icon="CornerRightUpIcon"
          class="text-info mr-50 b-avatar b-avatar-sm badge-light-info rounded-circle p-25"
        />
        <feather-icon
          v-if="data.item.registration_status === 'canceled'"
          v-b-popover.hover.top="'Canceled registration'"
          icon="SlashIcon"
          class="text-danger mr-50 b-avatar b-avatar-sm badge-light-danger rounded-circle p-25"
        />
        <span>{{ data.item.student_name }}</span>
      </template>
      <template #cell(present)="data">
        <!-- Levels checkbox -->
        <b-form-group>
          <div class="d-flex align-items-center justify-content-start">
            <b-form-checkbox
              v-model="data.item.present"
              value="0"
              :disabled="userData.role === 'student' || data.item.moved_to"
              class="custom-control-primary d-inline ml-1"
            >
              {{ 0 }}h
            </b-form-checkbox>
            <b-form-checkbox
              v-for="index in data.item.max_hours"
              :key="index"
              v-model="data.item.present"
              :value="index"
              :disabled="userData.role === 'student' || data.item.moved_to"
              class="custom-control-primary d-inline ml-1"
            >
              {{ index }}h
            </b-form-checkbox>
          </div>
        </b-form-group>
      </template>
      <template #cell(claim)="data">
        <b-button
          v-if="userData.role === 'student' && !data.item.claim_status"
          v-b-modal="getModalId1(data.item.attendance_id)"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :variant="data.item.claim_status === 'pending' ? 'warning' : 'primary'"
          size="sm"
          class="ml-1"
          @click="attendanceHours = data.item.present; claimMessage = null"
        >
          Claim
        </b-button>
        <b-button
          v-if="data.item.claim_status === 'pending'"
          v-b-modal="getModalId(data.item.attendance_id)"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-warning"
          size="sm"
        >
          Pending
        </b-button>
        <b-button
          v-b-modal="getModalId(data.item.attendance_id)"
          v-if="data.item.claim_status === 'approved'"
          size="sm"
          variant="flat-success"
        >
          Approved
        </b-button>
        <b-button
          v-b-modal="getModalId(data.item.attendance_id)"
          v-if="data.item.claim_status === 'rejected'"
          size="sm"
          variant="flat-danger"
        >
          Rejected
        </b-button>
        <!-- Claim modal -->
        <b-modal
          :id="'claim'+data.item.attendance_id"
          title="Claim attendance"
          ok-title="Approve"
          :hide-footer="data.item.claim_status !== 'pending' || (userData.role === 'student' && data.item.claim_status)"
          cancel-title="Reject"
          @ok="approveClaim(data.item.attendance_id)"
          @cancel="rejectClaim(data.item.attendance_id)"
          centered
          :no-close-on-backdrop="data.item.claim_status === 'pending'"
          size="lg"
        >
          <b-form-group
            :label="$t('Attendance')"
            label-for="attendance"
          >
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex">
                <b-form-checkbox
                  v-for="index in data.item.max_hours"
                  :key="index"
                  v-model="data.item.claim_hours"
                  :value="index"
                  disabled
                  class="custom-control-primary mr-1"
                >
                  {{ index }}h
                </b-form-checkbox>
              </div>
              <b-button
                v-if="data.item.picture"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                :href="data.item.picture"
                target="_blank"
              >
                <!-- @click="download(data.item.picture)" -->
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span class="align-middle">View image</span>
              </b-button>
            </div>
          </b-form-group>
          <b-form-group
            :label="$t('Details')"
            label-for="details"
          >
            <b-form-textarea
              v-model="data.item.claim_message"
              disabled
              placeholder="Details"
              rows="3"
              maxlength="500"
            />
          </b-form-group>
        </b-modal>
        <b-modal
          :id="'claim1'+data.item.attendance_id"
          title="Claim attendance"
          ok-only
          centered
          no-close-on-backdrop
          ok-title="send"
          hide-footer
          size="lg"
        >
          <validation-observer
            #default="{ handleSubmit }"
            ref="claimForm"
          >
            <!-- Form -->
            <b-form
              class="p-2"
              @submit.prevent="handleSubmit(claim(data.item.attendance_id, attendanceHours, claimMessage))"
              @reset.prevent="resetForm"
            >
              <validation-provider
                #default="validationContext"
                name="Attendance"
                rules="required"
              >
                <b-form-group
                  :label="$t('Attendance')"
                  label-for="attendance"
                  :state="getValidationState(validationContext)"
                >
                  <div class="d-flex">
                    <b-form-checkbox
                      v-for="index in data.item.max_hours"
                      :key="index"
                      v-model="attendanceHours"
                      :value="index"
                      :disabled="index === data.item.present"
                      class="custom-control-primary mr-1"
                    >
                      {{ index }}h
                    </b-form-checkbox>
                  </div>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="validationContext"
                name="Details"
                rules="required"
              >
                <b-form-group
                  :label="$t('Details')"
                  label-for="details"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-textarea
                    v-model="claimMessage"
                    placeholder="Details"
                    rows="3"
                    maxlength="500"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
              >
                {{ $t('Claim') }}
              </b-button>
            </b-form>
          </validation-observer>
        </b-modal>
      </template>
    </b-table>
    <!-- Modal actiions -->
    <div class="d-flex justify-content-end">
      <b-button
        v-if="userData.role !== 'student'"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mr-1"
        variant="primary"
        @click="saveChanges"
      >
        <feather-icon
          icon="SaveIcon"
          class="mr-50"
        />
        Save changes
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="secondary"
        @click="$emit('close-modal')"
      >
        Close
      </b-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import {
  BTable, BButton, BFormGroup, BFormCheckbox, VBTooltip, BModal, BFormInput, BFormFile, BFormTextarea,
  BMedia, BMediaAside, BMediaBody, BImg, BLink, BSpinner, BForm, BFormInvalidFeedback, VBPopover,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { url } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BTable,
    BFormGroup,
    BFormCheckbox,
    BModal,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BLink,
    BSpinner,
    BForm,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  props: {
    sessionId: {
      default: null,
      type: Number,
    },
    sessionNumber: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      isBusy: false,
      url,
      file: null,
      tableColumns: [
        { key: 'student_name' },
        // { key: 'student_id' },
        // { key: 'course_id' },
        { key: 'course_group' },
        { key: 'course_level' },
        { key: 'present' },
        { key: 'claim' },
      ],
      items: [],
      course: {},
      classroom: {},
      session: {},
      userData: null,
      claimMessage: null,
      attendanceHours: null,
    }
  },
  /* eslint-disable camelcase */
  created() {
    this.fetchItems()
  },
  methods: {
    // eslint-disable-next-line consistent-return
    tbodyRowClass(item) {
      // eslint-disable-next-line consistent-return
      /* Style the row as needed */
      if (item && item.moved_to) return ['b-table-disabled']
    },
    fetchItems() {
      this.isBusy = true
      this.$http.post('/attendance/list', { session_id: this.sessionId })
      .then(res => {
        this.isBusy = false
        this.course = res.data.course
        this.classroom = res.data.classroom
        this.session = res.data.session
        this.items = res.data.students.data
        this.session.homework = this.session.homework ? this.session.homework : { link: null }
        this.$emit('modal-title', { lesson: this.session.lesson_number, date: this.session.day })
      })
      this.userData = JSON.parse(localStorage.userData)
    },
    getModalId(id) {
      return `claim${id}`
    },
    getModalId1(id) {
      return `claim1${id}`
    },
    approveClaim(id) {
      this.$http.post('attendance/claim/approve', { attendance_id: id }).then(res => {
        this.fetchItems()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: res.data.message,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    rejectClaim(id) {
      this.$http.post('attendance/claim/reject', { attendance_id: id }).then(res => {
        this.fetchItems()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: res.data.message,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    saveChanges() {
      const formData = new FormData()
      formData.append('attendances', JSON.stringify(this.items))
      formData.append('picture', this.file)
      this.$http.post('/attendance/update', formData)
      .then(res => {
        this.$emit('close-modal')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: res.data.message,
            icon: 'CoffeeIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error updating students attendance',
            icon: 'CoffeeIcon',
            variant: 'danger',
          },
        })
      })
    },
    saveHomeworks() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$http.post(`/session/add/homework/${this.sessionId}`, { homework: this.session.homework })
          .then(res => {
            this.$bvModal.hide('modal-homework')
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: res.data.message,
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            })
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error updating students homeworks',
                icon: 'CoffeeIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { previewEl.value.src = base64 })
    const claim = function (attendance_id, claim_hours, claim_message) {
      if (this.$refs.claimForm.flags.valid) {
        this.$http.post('/attendance/claim', { attendance_id, claim_hours, claim_message })
        .then(res => {
          this.fetchItems()
          this.claimMessage = null
          this.attendanceHours = null
          this.$bvModal.hide(`claim${attendance_id}`)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      }
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation({})
    return {
      refFormObserver,
      getValidationState,
      resetForm,
      claim,
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
