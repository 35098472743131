<template>
  <div>
    <audio controls>
      <source
        :src="content.media"
        type="audio/mpeg"
      >
      Your browser does not support the audio element.
    </audio>
    <div>
      <b-form-input
        v-model="response"
        class="d-inline-flex"
        style="max-width: 20%"
        @keyup="$emit('responseevent', {id: idx, response: response})"
      />
    </div>
  </div>
</template>

<script>
import { BFormInput } from 'bootstrap-vue'

export default {
    name: 'AudioComponent',
    components: { BFormInput },
    props: {
        content: Object,
        idx: Number,
    },
    data() {
        return {
            response: null,
        }
    },
}
</script>

<style scoped>
  audio {
    max-height: 20px;
  }

</style>
