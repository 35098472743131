import {
  ref, watch, computed, onMounted,
} from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import store from '@/store'
import router from '@/router'

export default function useUsersList() {
  // Use toast
  const toast = useToast()
  const { t } = useI18nUtils()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'selected', label: 'reg id' },
    // { key: 'id', label: t('id'), sortable: false },
    // { key: 'id', label: t('Student id'), sortable: false },
    { key: 'student', label: t('name'), sortable: true },
    { key: 'course_group', label: t('course'), sortable: true },
    { key: 'date', label: t('creation date'), sortable: true },
    // { key: 'visa', label: 'visa', sortable: true },
    { key: 'payment_status', label: ('payment status') },
    // { key: 'payment_method', label: ('payment method') },
    { key: 'who_pays', label: t('payer'), sortable: true },
    // { key: 'status', label: t('status'), sortable: true },
    // { key: 'attendance', label: t('attendance') },
    { key: 'comment', label: t('notes') },
    { key: 'actions', label: t('actions'), tdClass: 'table-actions' },
  ]
  // const tableColumns1 = [
    // { key: 'selected', label: '', sortable: true },
    // { key: 'id', label: t('Student id'), sortable: false },
    // { key: 'student', label: t('name'), sortable: true },
    // { key: 'course_group', label: t('course'), sortable: true },
    // { key: 'date', label: t('creation date'), sortable: true },
    // { key: 'who_pays', label: t('payer'), sortable: true },
    // { key: 'visa', label: 'visa', sortable: true },
    // { key: 'payment_status', label: ('payment status') },
    // { key: 'who_pays', label: t('payer'), sortable: true },
    // { key: 'payer_confirmed', label: t('payer_confirmed') },
    // { key: 'status', label: t('status'), sortable: true },
    // { key: 'comment', label: t('notes') },
    // { key: 'actions', label: t('actions'), tdClass: 'table-actions' },
  // ]
  // const tableColumns2 = [
    // { key: 'selected', label: '', sortable: true },
    // { key: 'id', label: t('id'), sortable: false },
    // { key: 'student', label: t('student'), sortable: true },
    // { key: 'course_group', label: t('course'), sortable: true },
    // { key: 'date', label: t('creation date'), sortable: true },
    // { key: 'visa', label: 'visa', sortable: true },
    // { key: 'who_pays', label: t('payment'), sortable: true },
    // { key: 'payer_confirmed', label: t('payer_confirmed') },
    // { key: 'status', label: t('status'), sortable: true },
    // { key: 'comment', label: t('notes') },
    // { key: 'actions', label: t('actions'), tdClass: 'table-actions' },
  // ]
  /* eslint-disable camelcase */
  const perPage = ref(10)
  const totalRecords = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const selectedRows = ref([])
  const courseId = ref(null)
  const student = ref(null)
  const course = ref(null)
  const student_id = ref(null)
  // const statuss = ref(null)
  const payer_confirmed = ref(null)
  const visa = ref(null)
  const who_pays = ref(null)
  const payerOptions = ref([])
  const statusOptions = ref([
    { label: 'All', value: 'all' },
    { label: 'Confirmed', value: 'confirmed' },
    { label: 'Canceled', value: 'canceled' },
    { label: 'Pending', value: 'pending' },
    { label: 'Deleted', value: 'deleted' },
  ])
  // const defaultStatus = ref('all')
  /* eslint-disable-next-line */
  const role_name = ref(window.location.href.split('/')[6])

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRecords.value,
    }
  })
  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  const urlParams = new URLSearchParams(window.location.search)
  const urlQueries = router.currentRoute.query
  if (urlQueries.search) searchQuery.value = urlQueries.search
  if (urlQueries.page) currentPage.value = urlQueries.page
  if (urlQueries.perPage) perPage.value = urlQueries.perPage
  /* eslint-disable-next-line */
  watch([currentPage, perPage, roleFilter, planFilter, statusFilter, role_name, student, course, /* defaultStatus */, payer_confirmed, visa, who_pays, student_id, searchQuery],  () => {
    urlParams.set('search', searchQuery.value ? searchQuery.value : '')
    urlParams.set('page', currentPage.value ? currentPage.value : '')
    urlParams.set('perPage', perPage.value ? perPage.value : '')
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams.toString()}`)
    refetchData()
  })
  const isBusy = ref(false)

  /* eslint-disable no-param-reassign, no-dupe-keys */
  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('registerStoreModule/fetchUsers', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role_name: roleFilter.value,
        plan: planFilter.value,
        single_course_id: courseId.value,
        // status: statusFilter.value,
        student: student.value,
        course: course.value,
        status: 'unfulfilled',
        visa: visa.value,
        who_pays: who_pays.value,
        payer_confirmed: payer_confirmed.value,
        student: student_id.value,
        // role_name: role_name.value,
      })
      .then(() => {
        const users = store.state.registerStoreModule.registrations.rows
        const total = store.state.registerStoreModule.registrations.totalRecords
        callback(users)
        totalRecords.value = total
        currentUser.value = JSON.parse(localStorage.getItem('userData'))
      })
      .catch(() => {
        isBusy.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching registrations list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const isBigModalActive = ref(false)
  const UsersToAdd = ref([])
  const UsersAlreadyAdded = ref([])
  const spinner = ref(false)
  const currentCourseData = ref(null)
  const msgs = ref([])
  // const cities = ref([])
  const masters = ref([])
  const teachers = ref([])
  const classrooms = ref([])
  const currentUser = ref({})
  const statusArray = ref([
    { name: 'Active', value: 'active', color: 'primary' },
    { name: 'Hidden', value: 'hidden', color: 'primary' },
    { name: 'Past', value: 'past', color: 'primary' },
    { name: 'Deleted', value: 'deleted', color: 'primary' },
    { name: 'Planned', value: 'planned', color: 'primary' },
  ])
  const TofDArray = ref([
    { name: 'daytime', value: 'daytime' },
    { name: 'anytime', value: 'anytime' },
    { name: 'evening', value: 'evening' },
  ])
  const ModalData = (id, tab) => {
    store.dispatch('registerStoreModule/getSCourse', id)
      .then(res => {
        currentCourseData.value = res.data.data
        currentCourseData.value.pendingTeachers = res.data.pendingTeachers
        currentCourseData.value.currentTeacher = res.data.currentTeacher
        currentCourseData.value.tab = tab
        const sDays = currentCourseData.value.days
        const daysArr = sDays.split(',')
        currentCourseData.value.days = daysArr
        if (currentCourseData.value.conversation === null) {
          msgs.value = []
          UsersToAdd.value = []
          UsersAlreadyAdded.value = []
        } else {
          if (typeof currentCourseData.value.conversation !== 'undefined') {
            msgs.value = currentCourseData.value.conversation.messages
          }
          UsersToAdd.value = currentCourseData.value.users_To_Add
          UsersAlreadyAdded.value = currentCourseData.value.users_Already_In
        }
        isBigModalActive.value = true
      }).catch(error => console.log(error))
  }
  // const fetchCities = () => {
  //   store.dispatch('registrations/fetchCities')
  //     .then(res => {
  //       cities.value = res.data.rows
  //     })
  // }
  const fetchMCourses = () => {
    store.dispatch('registerStoreModule/fetchMCourses')
      .then(res => {
        masters.value = res.data.rows
      })
  }
  const fetchTeachers = () => {
    store.dispatch('registerStoreModule/fetchTeachers')
      .then(res => {
        teachers.value = res.data.rows
      })
  }
  const fetchClassrooms = () => {
    store.dispatch('registerStoreModule/fetchClassrooms')
      .then(res => {
        classrooms.value = res.data.rows
      })
  }
  const suggestClassrooms = (record, Days) => {
    record.days = Days.join(',')
    store.dispatch('registerStoreModule/suggestClassrooms', record)
      .then(res => {
        classrooms.value = res.data.available
      })
  }
  const suggestTeacher = (record, Days) => {
    /* eslint-disable no-param-reassign */
    record.days = Days.join(',')
    store.dispatch('registerStoreModule/suggestTeacher', record)
      .then(res => {
        teachers.value = res.data.available
      })
  }
  const fetchPayers = () => {
    store.dispatch('registerStoreModule/fetchPayers').then(res => {
      payerOptions.value = res.data.rows
      payerOptions.value.unshift({ name: 'All payers', id: null })
    })
  }
  // call on mounted
  onMounted(fetchMCourses)
  onMounted(fetchTeachers)
  onMounted(fetchClassrooms)
  onMounted(fetchPayers)
  // onMounted(fetchCities)

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'Paid') return 'success'
    if (status === 'Unpaid') return 'warning'
    if (status === 'Refunded') return 'danger'
    if (status === 'To be refunded') return 'danger'
    return 'secondary'
  }
  const resolveVisaStatusVariant = status => {
    if (status === false) return '<span class="badge text-capitalize badge-light-danger badge-pill">No visa</span>'
    if (status === true) return '<span class="badge text-capitalize badge-light-success badge-pill">Visa</span>'
    return '<span class="light-danger"></span>'
  }
  // const resolveStatusIcon = status => {
  //   if (status === 'Pending') return 'LoaderIcon'
  //   if (status === 'Active') return 'CheckCircleIcon'
  //   if (status === 'Deleted') return 'SlashIcon'
  //   return 'primary'
  // }
  const resolveInvoiceStatusVariantAndIcon = status => {
    if (status === 'pending') return { variant: 'warning', icon: 'LoaderIcon' }
    if (status === 'confirmed') return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === 'canceled') return { variant: 'danger', icon: 'SlashIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  return {
    fetchPayers,
    payerOptions,
    isBusy,
    fetchUsers,
    tableColumns,
    // tableColumns1,
    // tableColumns2,
    perPage,
    currentPage,
    totalRecords,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    selectedRows,
    courseId,
    role_name,
    student,
    course,
    student_id,
    // statuss,
    payer_confirmed,
    visa,
    who_pays,
    statusOptions,
    // defaultStatus,

    // Course modal
    // fetchCities,
    fetchClassrooms,
    fetchTeachers,
    fetchMCourses,
    suggestClassrooms,
    suggestTeacher,
    isBigModalActive,
    UsersToAdd,
    UsersAlreadyAdded,
    spinner,
    currentCourseData,
    msgs,
    classrooms,
    teachers,
    masters,
    // cities,
    TofDArray,
    statusArray,
    currentUser,
    ModalData,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveVisaStatusVariant,
    refetchData,
    // resolveStatusIcon,
    resolveInvoiceStatusVariantAndIcon,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
