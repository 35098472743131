<template>
  <div>
    <div
      v-for="(option, index) in content.options"
      :key="index"
    >
      <b-form-checkbox
        :id="`check_${index}`"
        v-model="checkedOptions"
        :value="`${option}`"
        @change="$emit('responseevent', {id: idx, response: checkedOptions})"
      >
        <label :for="`check_${index}`">{{ option }}</label>
        Checked
      </b-form-checkbox>
      <!-- <input
        :id="`check_${index}`"
        v-model="checkedOptions"
        type="checkbox"
        :value="`${option}`"
        @change="$emit('responseevent', {id: idx, response: checkedOptions})"
      >
      <label :for="`check_${index}`">{{ option }}</label> -->
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'

export default {
    name: 'CheckboxComponent',
    components: {
      BFormCheckbox,
    },
    props: {
        content: Object,
        idx: Number,
    },
    data() {
        return {
            checkedOptions: [],
        }
    },
}
</script>

<style scoped>

</style>
