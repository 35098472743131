<template>
  <b-carousel
    id="carousel-example-generic"
    controls
    indicators
  >
    <a :href="`${sliders.slider1.url}`" target="_blank">
      <b-carousel-slide
        :img-src="`${sliders.slider1.img}`"
        class="slide-carousel-el"
      />
    </a>
    <a :href="`${sliders.slider2.url}`" target="_blank">
      <b-carousel-slide
          :img-src="`${sliders.slider2.img}`"
          class="slide-carousel-el"
      />
    </a>
    <a :href="`${sliders.slider3.url}`" target="_blank">
      <b-carousel-slide
          :img-src="`${sliders.slider3.img}`"
          class="slide-carousel-el"
      />
    </a>
  </b-carousel>
</template>

<script>
import { BCarousel, BCarouselSlide } from 'bootstrap-vue'
/* eslint-disable */
export default {
  components: {
    BCarousel,
    BCarouselSlide,
  },
  computed: {
    sliders() {
      return {
        slider1: {
          /* eslint-disable-next-line */
          img: process.env.VUE_APP_SLIDE1IMG ? '//'+window.location.host+'/'+process.env.VUE_APP_SLIDE1IMG : require('@/assets/images/slider/01.jpg'),
          url: process.env.VUE_APP_SLIDE1LNK ?? '#',
        },
        slider2: {
          img: process.env.VUE_APP_SLIDE2IMG ? '//'+window.location.host+'/'+process.env.VUE_APP_SLIDE2IMG : require('@/assets/images/slider/02.jpg'),
          url: process.env.VUE_APP_SLIDE2LNK ?? '#',
        },
        slider3: {
          img: process.env.VUE_APP_SLIDE3IMG ? '//'+window.location.host+'/'+process.env.VUE_APP_SLIDE3IMG : require('@/assets/images/slider/03.jpg'),
          url: process.env.VUE_APP_SLIDE3LNK ?? '#',
        },
      }
    },
  },
}
</script>

<style>
  .carousel-inner {
    border-radius: 5px !important;
  }
  .slide-carousel-el img{
    height: 334px; object-fit: cover; object-position: 50% 50%;
  }
</style>
