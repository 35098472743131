<template>

  <div>
    <courses-list-big-modal
      v-if="isBigModalActive"
      :isBigModalActive.sync="isBigModalActive"
      :classrooms="classrooms"
      :teachers="teachers"
      :masters="masters"
      :TofDArray="TofDArray"
      :currentCourseData="currentCourseData"
      :msgs="msgs"
      :statusArray="statusArray"
      :UsersToAdd="UsersToAdd"
      :UsersAlreadyAdded="UsersAlreadyAdded"
      :currentUser="currentUser"
      :loading="spinner"
      @refetch-data="refetchData"
      @loading="loadSpinner"
      @suggest-classrooms="suggestClassrooms"
      @suggest-teacher="suggestTeacher"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :clearable="false"
              :reduce="x => x.value"
              style="min-width: 180px"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
                debounce="500"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="studentId ? tableColumns1 : tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>
        <!-- Column: Id -->
        <template #cell(id)="data">
          <span>
            {{ data.item.registration_id }}
          </span>
        </template>
        <template #cell(student_name)="data">
          <b-Link
            v-b-tooltip.hover.top="data.item.student_name"
            class="ml-50 d-inline-block text-truncate"
            style="max-width: 300px;"
            :to="{ name: 'apps-users-view', params: { id: data.item.student_id } }"
          >{{ data.item.student_name }}
          </b-link>
          <div class="ml-50 d-flex align-items-center">
            <small class="d-block d-flex text-muted" style="color: #055160">{{ data.item.student_id }}</small>
            <!-- <small
              class="ml-50 d-flex text-muted"
            >
              <span class="text-secondary">{{ data.item.student_email }}</span>
            </small> -->
          </div>
        </template>
        <!-- Column: Date -->
        <template #cell(date)="data">
          <span class="text-nowrap d-block">
            {{ data.item.date ? data.item.date.split(' ')[0] : '' }}
          </span>
          <small>{{ data.item.date ? data.item.date.split(' ')[1] : '' }}</small>
          <!-- Column: Course name & status -->
        </template>
        <template #cell(course_name)="data">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            @click="ModalData(data.item.course_id, 'group')"
            :disabled="!data.item.course_group"
            class="pl-25"
          >
            <b class="d-flex d-block text-nowrap">
              {{ data.item.course_group }}
            </b>
            <small class="d-flex mt-50 text-muted">
              {{ data.item.course_name }}
            </small>
          </b-button>
        </template>
        <!-- Column: fee & medium -->
        <template #cell(fee)="data">
          <div class="media-body">
            <div class="font-weight-bold d-block text-nowrap text-capitalize">
              {{ data.item.fee }}
            </div>
            <small class="text-muted text-capitalize">{{ data.item.medium }}</small>
          </div>
        </template>
        <template #cell(comments)="data">
          <b-button
            :id="`popover-target-${data.item.id}`"
            variant="flat-primary"
            size="sm"
            class="btn-icon rounded-circle"
            @click="handleChatClick({model:'invoice', id: data.item.id})"
          >
            <feather-icon
              icon="MessageSquareIcon"
              class="align-middle text-body"
              badge-classes="bg-danger"
              :badge="data.item.comments_number > 0 ? data.item.comments_number : ''"
              size="21"
            />
          </b-button>
        </template>
        <!-- Column: reasonss -->
        <template #cell(reason)="data">
          <div class="media-body">
            <div class="font-weight-bold d-block text-nowrap text-capitalize">
              {{ data.item.reason ? data.item.reason.replace('_', ' ') : data.item.reason }}
            </div>
            <small
              v-b-tooltip.hover.top="data.item.reason_other"
              class="d-inline-block text-truncate"
              style="max-width: 180px;"
            >{{ data.item.reason_other }}</small>
          </div>
        </template>
        <!-- Column: reasonss -->
        <template #cell(p_status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.payment_status.toLowerCase())}`"
            class="text-capitalize"
            style="font-size: 10px"
          >
            {{ data.item.payment_status }}
          </b-badge>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
            <!-- v-if="data.item.status !== 'rejected'" -->
            <div class="d-flex justify-content-center">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="data.item.status === 'Confirmed' ? 'flat-success' : 'success'"
                size="sm"
                :disabled="data.item.status === 'Confirmed'"
                class="text-nowrap"
                @click="$bvModal.show(`withdrawalModal${data.item.id}`)"
              >
                <feather-icon v-if="data.item.status === 'Confirmed'" icon="CheckIcon" />
                {{ data.item.status === 'Confirmed' ? $t('Accepted') : $t('Accept') }}
              </b-button>
            </div>
          <!-- <b-button
            v-if="data.item.status !== 'Confirmed'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="ml-50"
            :variant="data.item.status === 'rejected' ? 'flat-danger' : 'danger'"
            size="sm"
            :disabled="data.item.status === 'rejected'"
            @click="$bvModal.show(`rejectModal${data.item.id}`)"
          >
            <feather-icon v-if="data.item.status === 'rejected'" icon="XIcon" />
            {{ data.item.status === 'rejected' ? 'Rejected' : 'Reject' }}
          </b-button> -->
          <!-- Reject modal -->
          <b-modal
            :id="'rejectModal'+data.item.id"
            :title="('Confirm Rejection')"
            ok-title="Yes"
            cancel-title="No"
            centered
            @ok="rejectWithdrawal(data.item.id)"
          >
            <b-card-text>
              <h5>
                {{ ('Are you sure you want to reject') }} <strong>{{ data.item.student_name }}</strong>'s withdrawal request from course <strong>{{ data.item.course_name }}?</strong>
              </h5>
            </b-card-text>
          </b-modal>
          <!-- Withdrawal modal -->
          <b-modal
            :id="'withdrawalModal'+data.item.id"
            :title="('Confirm withdrawal request')"
            ok-title="Yes"
            cancel-title="No"
            centered
            @ok="apply(data.item.id, toRefund, tobeRefunded)"
            @show="tobeRefunded = false; toRefund = false"
          >
            <b-card-text>
              <h5>{{ $t('Are you sure you want to accept') }} <strong>{{ data.item.student_name }}</strong>'s withdrawal request from course <strong>{{ data.item.course_name }}?</strong></h5>
            </b-card-text>
            <div v-if="parseInt(data.item.course_price) !== 0" class="d-flex justify-content-start mt-1">
              <b-form-checkbox
                v-if="data.item.payment_status === 'Paid' || data.item.payment_status === 'To be refunded'"
                v-model="toRefund"
                @change="tobeRefunded = false"
              >
                Refund
              </b-form-checkbox>
              <b-form-checkbox
                v-if="data.item.payment_status === 'Paid'"
                v-model="tobeRefunded"
                @change="toRefund = false"
                class="ml-1"
              >
                To be refunded
              </b-form-checkbox>
            </div>
          </b-modal>
          <!-- Refund modal -->
          <b-modal
            :id="'refunModal'+data.item.id"
            :title="`Refund Details for ${data.item.course_name} ${data.item.course_group ? '('+data.item.course_group+')' : ''}`"
            ok-title="Refund"
            :ok-disabled="!refundAmount && !refundFull || parseInt(refundAmount)>parseInt(data.item.course_price)"
            @ok="submitRefund(data.item.registration_id, data.item.status)"
            @show="refundComments = null; refundAmount = null; refundReason = data.item.reason_other ? data.item.reason_other : data.item.reason; refundFull = data.item.fee === 'full'"
            @hidden="refetchData()"
            centered
            size="lg"
            no-close-on-backdrop
          >
            <b-row>
              <b-col cols="6">
                <b-form-group
                  label="Student name"
                  label-for="student-name"
                >
                  <b-form-input
                    v-model="data.item.student_name"
                    placeholder="Student name"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Registration id"
                  label-for="registration-id"
                >
                  <b-form-input
                    v-model="data.item.registration_id"
                    placeholder="Registration id"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Registration status"
                  label-for="registration-status"
                >
                  <b-form-input
                    v-model="data.item.status"
                    placeholder="Registration status"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Amount"
                  label-for="amount"
                >
                  <div class="d-flex align-items-center">
                    <b-form-input
                      v-model="refundAmount"
                      placeholder="Amount"
                      @keypress="validateInput"
                      :disabled="refundFull"
                    />
                    <b-button
                      :variant="refundFull ? 'success' : 'secondary'"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="text-nowrap ml-50"
                      @click="refundFull = !refundFull; refundFull ? refundAmount = parseInt(data.item.course_price, 0).toFixed(2) : refundAmount = null"
                    >
                      <feather-icon v-if="refundFull" icon="CheckCircleIcon" />
                      Full refund {{ parseInt(data.item.course_price, 0).toFixed(2) }} {{ $store.state.appConfig.currency }}
                    </b-button>
                    <!-- <b-form-checkbox
                      v-model="refundFull"
                      class="text-nowrap"
                    >
                      Full refund
                    </b-form-checkbox> -->
                  </div>
                  <small v-if="parseInt(refundAmount) >parseInt(data.item.course_price)" class="text-danger">Amount must be less than {{ parseInt(data.item.course_price, 0).toFixed(2) }}</small>
                </b-form-group>
              </b-col>
              <b-col v-if="refundReason" cols="12">
                <b-form-group
                  label="Reason for Refund"
                  label-for="refund-reason"
                >
                  <p class="mb-2">{{ refundReason }}</p>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Comments"
                  label-for="refund-comments"
                >
                  <b-form-textarea
                    placeholder="Write your comments..."
                    v-model="refundComments"
                    rows="1"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-modal>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="3"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>
          <b-col
            cols="3"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} to {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="!isBusy"
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <div
      v-if="isChatActive"
      class="commentschats"
    >
      <feather-icon
        icon="XSquareIcon"
        size="22"
        class="align-middle text-body close-btn"
        @click="isChatActive = false"
      />
      <chat-module
        :key="chatModelId.id"
        @notes-increament="refetchData"
        @decrease-notes="refetchData"
        :model-id="chatModelId.id"
        model-type="resignation"
      />
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BFormTextarea, BLink, BCardText,
  BPagination, BButton, BSpinner, VBTooltip, BFormGroup, BFormCheckbox, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import CoursesListBigModal from '@/views/courses/admin/courses-list/CoursesListBigModal.vue'
import ChatModule from '@/views/admin/ChatModule.vue'
import store from '@/store'
import useCoursesList from './useCoursesList'
import courseStoreModule from './courseStoreModule'

export default {
  components: {
    CoursesListBigModal,
    BFormTextarea,
    BFormGroup,
    ChatModule,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BFormCheckbox,
    BCardText,
    BButton,
    BLink,
    BSpinner,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    studentId: {
      type: Number,
      default: null,
    },
  },
  methods: {
    validateInput(event) {
      const charCode = (event.which) ? event.which : event.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        event.preventDefault()
      }
    },
    closeAttendanceModal(id) {
      this.$bvModal.hide(`modal1${id}`)
    },
  },
  setup(props) {
    // Use toast
    const toast = useToast()
    /* eslint-disable camelcase */
    const getModal1Id = id => `modal1${id}`
    const COURSE_STORE_MODULE_NAME = 'course'
    // Register module
    if (!store.hasModule(COURSE_STORE_MODULE_NAME)) store.registerModule(COURSE_STORE_MODULE_NAME, courseStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COURSE_STORE_MODULE_NAME)) store.unregisterModule(COURSE_STORE_MODULE_NAME)
    })
    const {
      resolveUserStatusVariant,
      // Modal
      TofDArray,
      currentCourseData,
      msgs,
      UsersToAdd,
      UsersAlreadyAdded,
      isBigModalActive,
      spinner,
      masters,
      teachers,
      classrooms,
      suggestTeacher,
      suggestClassrooms,
      statusArray,
      currentUser,

      ModalData,
      status,
      statusOptions,
      isBusy,
      userData,
      fetchUsers,
      tableColumns,
      tableColumns1,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      student_id,
    } = useCoursesList()

    const loadSpinner = () => {
      // open
      spinner.value = true
      setTimeout(() => {
        spinner.value = false
      }, 1000)
    }
    const apply = function (id, refund, to_be_refunded) {
      store.dispatch('course/confirmResignation', { id, refund, to_be_refunded })
      .then(res => {
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        if (toRefund.value) return this.$bvModal.show(`refunModal${id}`)
        return refetchData()
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
    const rejectWithdrawal = id => {
      store.dispatch('course/rejectWithdrawal', id)
      .then(res => {
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        refetchData()
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
    const refundReason = ref(null)
    const refundComments = ref(null)
    const refundAmount = ref(null)
    const refundFull = ref(false)
    const tobeRefunded = ref(false)
    const toRefund = ref(false)

    const submitRefund = function (registration_id) {
      const payload = {
        registration_id,
        amount: refundAmount.value,
        reason: refundComments.value,
        full: refundFull.value,
      }
      store.dispatch('registerStoreModule/refund', payload)
      .then(res => {
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
    if (props.studentId) { student_id.value = props.studentId }
    const isChatActive = ref(false)
    const chatModelId = ref(null)
    const handleChatClick = data => {
      isChatActive.value = true
      chatModelId.value = data
    }
    return {
      resolveUserStatusVariant,
      refundComments,
      rejectWithdrawal,
      // Modal
      TofDArray,
      statusArray,
      currentCourseData,
      msgs,
      UsersToAdd,
      UsersAlreadyAdded,
      isBigModalActive,
      loadSpinner,
      masters,
      teachers,
      classrooms,
      suggestTeacher,
      suggestClassrooms,
      spinner,
      currentUser,

      ModalData,
      status,
      statusOptions,

      // Refund
      submitRefund,
      refundReason,
      refundAmount,
      refundFull,
      tobeRefunded,
      toRefund,

      isBusy,
      // Modal ids
      isChatActive,
      chatModelId,
      handleChatClick,

      getModal1Id,
      userData,
      fetchUsers,
      tableColumns,
      tableColumns1,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      apply,
      student_id,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
