<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Students</b-card-title>
      <!-- <b-dropdown
        text="Last 7 Days"
        variant="transparent"
        class="chart-dropdown "
        right
        no-caret
        toggle-class="p-0 mb-25"
        size="sm"
      >
        <b-dropdown-item
          v-for="day in chartData.lastDays"
          :key="day"
        >
          {{ day }}
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>

    <b-card-body>

      <!-- apex chart -->
      <vue-apex-charts
        type="pie"
        height="325"
        class="mt-2 mb-1"
        :options="customersPie.chartOptions"
        :series="customersPie.series"
      />

      <!-- list group -->
      <div class="pt-25">
        <div
          v-for="(data,index) in customersPie.series"
          :key="index"
          class="d-flex justify-content-between"
          :class="index === customersPie.series.length - 1 ? '':'mb-1'"
        >
          <div class="series-info">
            <feather-icon
              icon="CircleIcon"
              size="16"
              class="mr-50"
              :class="(index === 0 ? 'text-success' : 'text-warning')"
            />
            <span class="font-weight-bolder">{{ (index === 0 ? 'New' : 'Returning') }}</span>
          </div>
          <span>{{ data }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    VueApexCharts,
  },
  props: {
    customersPie: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartData: {},
    }
  },
  created() {
    this.$http.get('/card/card-analytics/customers').then(res => {
      this.chartData = res.data
    })
  },
}
</script>
