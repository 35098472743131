import { ref, watch, computed } from '@vue/composition-api'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'

export default function useEventsList() {
  // Use toast
  const toast = useToast()

  const refTeacherEventsTable = ref(null)

  // Table Handlers
  const tableColumns = ref([
    { key: 'name', label: 'subject', sortable: true },
    { key: 'description' },
    { key: 'teacher', label: 'Teacher' },
    { key: 'date_start', label: 'Start date', sortable: true },
    { key: 'date_end', label: 'End date', sortable: true },
    { key: 'status' },
    { key: 'notes' },
    { key: 'actions' },
  ])
  const perPage = ref(10)
  const totalElements = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('date_start')
  const isSortDirDesc = ref(true)
  const teacherID = ref(null)
  const selectedFilter = ref('Pending')
  /* eslint-disable-next-line */

  const dataMeta = computed(() => {
    const localItemsCount = refTeacherEventsTable.value ? refTeacherEventsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalElements.value,
    }
  })

  const refetchData = () => {
    refTeacherEventsTable.value.refresh()
  }
  const urlParams = ref(new URLSearchParams())
  if (router.currentRoute.query.search) searchQuery.value = router.currentRoute.query.search
  if (router.currentRoute.query.page) currentPage.value = router.currentRoute.query.page
  if (router.currentRoute.query.perPage) perPage.value = router.currentRoute.query.perPage
  if (router.currentRoute.query.filter) selectedFilter.value = router.currentRoute.query.filter
  /* eslint-disable-next-line */
  watch([currentPage, perPage, sortBy, isSortDirDesc, selectedFilter, searchQuery],  () => {
    urlParams.value.set('?search', searchQuery.value ? searchQuery.value : '')
    urlParams.value.set('page', currentPage.value ? currentPage.value : '')
    urlParams.value.set('perPage', perPage.value ? perPage.value : '')
    urlParams.value.set('filter', selectedFilter.value ? selectedFilter.value : '')
    window.history.replaceState(null, null, decodeURIComponent(urlParams.value))
    refetchData()
  })
  const isBusy = ref(false)

  const fetchEvents = (ctx, callback) => {
    const payload = {
      search: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      teacher_id: teacherID.value,
      status: selectedFilter.value,
      // role_name: role_name.value,
    }
    store
      .dispatch('teacherStoreModule/fetchEvents', payload)
      .then(() => {
        const users = store.state.teacherStoreModule.events.rows
        const total = store.state.teacherStoreModule.events.totalRecords
        callback(users)
        totalElements.value = total
      })
      .catch(() => {
        isBusy.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching teachers events list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserStatusVariant = status => {
    if (status === 'Pending') return 'warning'
    if (status === 'Active') return 'success'
    if (status === 'Rejected') return 'danger'
    if (status === 'Expired') return 'secondary'
    return 'primary'
  }

  const resolveTeacherAvailability = status => {
    if (status === 'n') return { class: 'danger', text: 'Unavailable' }
    if (status === 'y') return { class: 'success', text: 'Available' }
    if (status === '') return { class: 'success', text: 'Available' }
    return { class: 'success', text: 'Available' }
  }

  return {
    isBusy,
    fetchEvents,
    tableColumns,
    perPage,
    currentPage,
    totalElements,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTeacherEventsTable,

    resolveUserStatusVariant,
    refetchData,
    resolveTeacherAvailability,
    teacherID,
    selectedFilter,

  }
}
