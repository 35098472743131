<template>
  <div>
    <b-table
      responsive="sm"
      show-empty
      empty-text="No attendance recorded yet."
      :items="items"
      :fields="tableColumns"
      :tbody-tr-class="tbodyRowClass"
      :busy.sync="isBusy"
    >
      <template #table-busy>
        <div
          v-if="isBusy"
          class="text-center d-flex align-items-center justify-content-center"
        >
          <b-spinner
            variant="primary"
            label="Text Centered"
          />
          <strong class="ml-1">Loading...</strong>
        </div>
      </template>
      <template #cell(student_id)="data">
        {{ data.item.student_id }}
        <feather-icon
          v-if="data.item.moved_from"
          v-b-popover.hover.top="`Moved from ${data.item.moved_from.course} (${data.item.moved_from.group})`"
          icon="CornerRightDownIcon"
          class="text-warning ml-1 b-avatar b-avatar-sm badge-light-warning rounded-circle p-25"
        />
        <feather-icon
          v-if="data.item.moved_to"
          v-b-popover.hover.top="`Moved to ${data.item.moved_to.course} (${data.item.moved_to.group})`"
          icon="CornerRightUpIcon"
          class="text-info ml-1 b-avatar b-avatar-sm badge-light-info rounded-circle p-25"
        />
        <feather-icon
          v-if="data.item.registration_status === 'canceled'"
          v-b-popover.hover.top="'Canceled registration'"
          icon="SlashIcon"
          class="text-danger ml-1 b-avatar b-avatar-sm badge-light-danger rounded-circle p-25"
        />
      </template>
      <!-- Column: Student name -->
      <template #cell(student_name)="data">
        <b-media vertical-align="center" class="d-flex">
          <b-link
            v-b-modal="getModalId(data.item.student_id)"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.student_name }}
          </b-link>
        </b-media>
        <!-- Modal -->
        <b-modal
          :id="'modal'+data.item.student_id"
          :title="$t('Attendance')"
          ok-only
          centered
          no-close-on-backdrop
          :ok-title="$t('Close')"
          size="lg"
          hide-footer
          scrollable
          body-class="attendance-modal"
        >
          <template #modal-title>
            <div class="text-nowrap">
              Attendance of <b>{{ data.item.student_name }}</b> in Course Group <b>{{ data.item.course_group }}</b>
            </div>
          </template>
          <course-student-attendance
            :course-id="courseId"
            :student-id="data.item.student_id"
            :moved-from="data.item.moved_from"
            :moved-to="data.item.moved_to"
            :registration-status="data.item.registration_status"
            @hide-modal="fetchAttendance(); $bvModal.hide(`modal${data.item.student_id}`)"
          />
        </b-modal>
      </template>
      <template #cell(course_date_end)="data">
        {{ data.item.course_date_start }} | {{ data.item.course_date_end }}
      </template>
      <template #cell(attendance_percent)="data">
        <span :class="parseInt(data.item.attendance_percent, 10) >= 80 ? 'text-success' : 'percent'">
          {{ data.item.attendance_percent }}%
        </span>
      </template>
    </b-table>
    <!-- Modal actiions -->
    <div v-if="items.length > 0" class="d-flex justify-content-end">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mr-1"
        variant="primary"
        @click="sendCertificate"
      >
        {{ $t('Send certificates') }}
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="secondary"
        @click="$emit('close-attendance-modal')"
      >
        {{ $t('Close') }}
      </b-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline, no-param-reassign */
import { BTable, BButton, BLink, BMedia, VBModal, BSpinner, VBPopover } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import CourseStudentAttendance from './CourseStudentAttendance.vue'

export default {
  components: {
    BButton,
    BTable,
    BLink,
    BMedia,
    BSpinner,
    CourseStudentAttendance,
  },
  directives: {
    'b-modal': VBModal,
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    courseId: {
      default: null,
      type: Number,
    },
  },
  data() {
    return {
      tableColumns: [
        { key: 'student_id' },
        { key: 'student_name' },
        { key: 'attendance_hours' },
        { key: 'attendance_percent' },
        { key: 'course_code' },
        { key: 'course_date_end', label: 'Period' },
        // { key: 'course_group' },
      ],
      items: [],
      isBusy: false,
    }
  },
  created() {
    this.fetchAttendance()
  },
  methods: {
    // eslint-disable-next-line consistent-return
    tbodyRowClass(item) {
      console.log('fsdkljfsjfj')
      /* Style the row as needed */
      if (item && item.move_to) return ['b-table-disabled']
    },
    fetchAttendance() {
      this.isBusy = true
      this.$http.get('/attendance/students', { params: { course_id: this.courseId } })
      .then(res => {
        this.isBusy = false
        this.items = res.data
      })
    },
    getModalId(id) {
      return `modal${id}`
    },
    sendCertificate() {
      this.$http.post('/attendance/send/certificates', { attendances: this.items })
      .then(() => {
        this.$emit('close-attendance-modal')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Certificates has been sent',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error updating students attendance',
            icon: 'CoffeeIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>
