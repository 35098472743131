<template>

  <div>
    <!-- Table Container Card -->
    <b-modal
      id="create-modal"
      title="Create test"
      size="xl"
      hide-footer
      no-close-on-backdrop
      scrollable
      centered
    >
      <!-- <create-test
        @refetch-data="refetchData"
        @close-modal="$bvModal.hide('create-modal')"
      /> -->
      <test-module
        @refetch-data="refetchData"
        @close-modal="$bvModal.hide('create-modal')"
      />
    </b-modal>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-button
              v-b-modal.create-modal
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              {{ $t('Create new test') }}
            </b-button>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                debounce="500"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">{{ $t('Loading...') }}</strong>
          </div>
        </template>
        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.id }}
            </b-link>
          </b-media>
        </template>
        <!-- Column: Name -->
        <template #cell(name)="data">
          <span
            v-b-tooltip.hover.top="data.item.name"
            class="d-inline-block text-truncate"
            style="max-width: 300px;"
          >
            {{ data.item.name }}
          </span>
        </template>
        <!-- Column: Descriptiom -->
        <template #cell(description)="data">
          <span
            v-b-tooltip.hover.top="data.item.description"
            style="max-width: 300px;"
          >
            {{ filterTags(data.item.description) }}
          </span>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            dropleft
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-b-modal="getModal3Id(data.item.id)"
            >
              <feather-icon icon="SendIcon" />
              <span class="align-middle ml-50">Send test to a student</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-b-modal="getModal4Id(data.item.id)"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">{{ $t('Preview test') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-b-modal="getModal2Id(data.item.id)"
              @click="getTest(data.item.id)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-b-modal="getModal1Id(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
            <b-modal
              :id="'modal4'+data.item.id"
              size="xl"
              :title="testName"
              ok-only
              no-close-on-backdrop
              hide-footer
              centered
            >
              <test
                :test-id="data.item.id"
                @test-name="getTestName"
              />
            </b-modal>
            <!-- basic modal -->
            <b-modal
              :id="'modal1'+data.item.id"
              title="Delete test"
              @ok="remove(data.item.id)"
            >
              <b-card-text>
                <h5>Are you sure you want to delete </h5><strong>{{ data.item.name }}</strong>
              </b-card-text>
            </b-modal>
            <!-- Send test modal -->
            <b-modal
              :id="'modal3'+data.item.id"
              :title="data.item.name"
              :ok-disabled="selectedCourse.id === null || !due_date || selectedStudent.length === 0"
              ok-only
              no-close-on-backdrop
              centered
              @ok="sendTest((selectedStudent.length === students.length ? selectedCourse.id : selectedStudent), data.item.id)"
            >
              <b-form-group
                :label="$t('Course')"
                label-for="course"
              >
                <v-select
                  v-model="selectedCourse"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="courses"
                  :clearable="false"
                  @keyup.native="autoCompleteCourse($event.target.value)"
                  @click.native="autoCompleteCourse('')"
                  @input="getStudents(selectedCourse.id)"
                >
                  <span slot="no-options">
                    <div
                      v-if="isTypingCourse"
                      class="text-center d-flex align-items-center justify-content-center"
                    >
                      <b-spinner
                        variant="primary"
                        label="Text Centered"
                      />
                      <div
                        v-if="!isTypingCourse && !courses.length"
                        class="text-center d-flex align-items-center justify-content-center"
                      >
                        No matching records found
                      </div>
                    </div>
                  </span>
                </v-select>
              </b-form-group>
              <b-form-group
                v-if="selectedCourse.id"
                :label="'Regestered students'"
                label-for="regestered-students"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <v-select
                    v-model="selectedStudent"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="students"
                    :clearable="false"
                    multiple
                    style="width: 75%;"
                  >
                    <span slot="no-options">
                      <div
                        v-if="!students.length"
                        class="text-center d-flex align-items-center justify-content-center"
                      >
                        No matching records found
                      </div>
                    </span>
                  </v-select>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="ml-50"
                    :variant="selectedStudent.length === students.length ? 'secondary' : 'primary'"
                    @click="selectedStudent.length === students.length ? selectedStudent = [] : selectedStudent = students"
                  >
                    {{ $t(selectedStudent.length === students.length ? 'Clear' : 'Select all') }}
                  </b-button>
                </div>
              </b-form-group>
              <b-form-group
                :label="$t('Due date')"
                label-for="due-date"
              >
                <flat-pickr
                  v-model="due_date"
                  class="form-control"
                  name="date"
                  :placeholder="$t('Due date')"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss', locale: { firstDayOfWeek: 1 }}"
                />
              </b-form-group>
            </b-modal>

            <!-- basic modal -->
            <b-modal
              :id="'modal2'+data.item.id"
              title="Update test"
              size="xl"
              hide-footer
              no-close-on-backdrop
              scrollable
              centered
            >
              <div
                v-if="isBusyModal"
                class="text-center"
              >
                <b-spinner
                  variant="primary"
                  label="Text Centered"
                />
                <strong class="ml-1">{{ $t('Loading...') }}</strong>
              </div>
              <test-module
                v-if="test && !isBusyModal"
                :key="test"
                :placement-test="test"
                @refetch-data="refetchData"
                @close-modal="$bvModal.hide('create-modal')"
              />
            </b-modal>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BLink,
  BPagination, BModal, BDropdown, BDropdownItem, BCardText, BSpinner, BButton, VBTooltip, BFormGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { filterTags } from '@core/utils/filter'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { onUnmounted, ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// import ChatModule from '@/views/admin/ChatModule.vue'
import Test from '@/views/test/Test.vue'
import useTestsList from './useTestsList'
// import CreateTest from '../CreateTest.vue'
import TestModule from './TestModule.vue'
import testStoreModule from './testStoreModule'

export default {
  components: {
    // ChatModule,
    // CreateTest,
    Test,
    TestModule,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BLink,
    BPagination,
    BModal,
    BDropdown,
    BDropdownItem,
    BCardText,
    vSelect,
    flatPickr,
    BSpinner,
    BButton,
    BFormGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  methods: {
    closeAttendanceModal(id) {
      this.$bvModal.hide(`modal1${id}`)
    },
  },
  setup() {
    /* eslint-disable camelcase */
    const getModal1Id = id => `modal1${id}`
    const getModal2Id = id => `modal2${id}`
    const getModal3Id = id => `modal3${id}`
    const getModal4Id = id => `modal4${id}`
    // const getChatId = id => `popover-target-${id}`

    const TEST_STORE_MODULE_NAME = 'test'

    // Register module
    if (!store.hasModule(TEST_STORE_MODULE_NAME)) store.registerModule(TEST_STORE_MODULE_NAME, testStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TEST_STORE_MODULE_NAME)) store.unregisterModule(TEST_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const {
      isBusy,
      userData,
      test,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
    } = useTestsList()
    const remove = id => {
      store.dispatch('test/deleteTest', id)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Test Has Been removed',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while removing Test',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const isBusyModal = ref(true)
    const getTest = id => {
      store.dispatch('test/getTest', id)
        .then(res => {
          test.value = res.data.data
          isBusyModal.value = false
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while removing Test',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const selectedStudent = ref([])
    const selectedCourse = ref({})
    const due_date = ref(null)
    const courses = ref([])
    const isTypingCourse = ref(false)
    const students = ref([])
    const testName = ref(null)
    const autoCompleteCourse = search => {
      isTypingCourse.value = true
      store.dispatch('test/autoCompleteCourse', search)
      .then(res => {
        courses.value = res.data
        isTypingCourse.value = false
      })
    }
    const getStudents = course_id => {
      selectedStudent.value = []
      store.dispatch('test/getStudents', course_id)
      .then(res => {
        students.value = res.data.data
      })
    }
    const sendTest = (action, placement_test_id) => {
      let payload = {}
      if (typeof action === 'number') {
        payload = {
          placement_test_id,
          due_date: due_date.value,
          course_id: action,
        }
      } else {
        payload = {
          placement_test_id,
          due_date: due_date.value,
          students: action.map(el => el.id),
        }
      }
      store.dispatch('test/sendTest', { payload })
        .then(res => {
          toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while removing Test',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const getTestName = name => {
      testName.value = name
    }
    return {
      isBusy,
      // Modal ids
      getModal1Id,
      getModal2Id,
      getModal3Id,
      getModal4Id,
      // getChatId,
      isBusyModal,

      getTestName,
      testName,
      sendTest,
      selectedStudent,
      selectedCourse,
      autoCompleteCourse,
      due_date,
      isTypingCourse,
      courses,
      getStudents,
      students,

      // Actions
      remove,
      getTest,
      filterTags,

      userData,
      test,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
