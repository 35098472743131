<template>
  <b-sidebar
    id="sidebar"
    :visible="isFilterActive"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    right
    backdrop
    shadow
    width="450px"
    @change="(val) => $emit('update:isFilterActive', val)"
  >
    <div>
      <b-form @submit.prevent>
        <b-container>
          <b-row>
            <b-col>
              <b-form-group
                label="Name"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  :value="name"
                  placeholder="Name"
                  class="input"
                  @input="(val) => { $emit('update:name', val), onEmit() }"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Language"
                label-for="Language"
              >
                <v-select
                  id="Language"
                  :value="languageId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="languages"
                  :reduce="x => x.id"
                  label="name"
                  placeholder="choose master language"
                  @input="(val) => { $emit('update:languageId', val), onEmit() }"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Type"
                label-for="Type"
              >
                <v-select
                  id="Type"
                  :value="typeId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="Types"
                  :reduce="x => x.id"
                  label="name"
                  placeholder="choose master type"
                  @input="(val) => { $emit('update:typeId', val), onEmit() }"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Description"
                label-for="Description"
              >
                <v-select
                  id="Description"
                  :value="descriptionId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="descriptions"
                  :reduce="x => x.id"
                  placeholder="choose master description"
                  @input="(val) => { $emit('update:descriptionId', val), onEmit() }"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Status"
                label-for="Status"
              >
                <v-select
                  id="Status"
                  :value="statusId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="statusArray"
                  :reduce="x => x.value"
                  placeholder="choose master status"
                  @input="(val) => { $emit('update:statusId', val), onEmit() }"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
    </div>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, /* BFormInvalidFeedback,  */BRow, BCol, BContainer,
} from 'bootstrap-vue'
/* import { ValidationProvider, ValidationObserver } from 'vee-validate' */
/* import { ref } from '@vue/composition-api' */
/* import formValidation from '@core/comp-functions/forms/form-validation' */
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
/* import flatPickr from 'vue-flatpickr-component' */

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BContainer,
    BRow,
    BCol,
    /* BFormInvalidFeedback, */
    vSelect,

    // Form Validation
    /* ValidationProvider,
    ValidationObserver, */
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isFilterActive',
    event: 'update:isFilterActive',
  },
  props: {
    isFilterActive: {
      type: Boolean,
      required: true,
    },
    masters: {
      type: Array,
      required: true,
    },
    descriptions: {
      type: Array,
      required: true,
    },
    Types: {
      type: Array,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    statusArray: {
      type: Array,
      required: true,
    },
    name: {
      type: [String, null],
      default: null,
    },
    languageId: {
      type: [Number, null],
      default: null,
    },
    typeId: {
      type: [Number, null],
      default: null,
    },
    descriptionId: {
      type: [Number, null],
      default: null,
    },
    statusId: {
      type: [String, null],
      default: null,
    },
  },
  setup(props, { emit }) {
    const onEmit = () => {
      emit('refetch')
    }
    return {
      onEmit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  /* background-color: #f7f7f7; */
}
.input:focus {
  outline: none;
}
</style>
