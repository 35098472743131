import axios from '@axios'

export default {
  /* eslint-disable object-curly-newline */
  namespaced: true,
  state: {
    emails: [],
    users: [],
  },
  getters: {},
  mutations: {
    setEmails(state, response) {
      state.emails = response.conversations.data
      state.users = response.allusers
    },
    setMoreMessages(/* state, response */) {
      // Get more messages
    },
  },
  actions: {
    fetchEmails(store) {
      return new Promise((resolve, reject) => {
        axios
          .get('/conversations')
          .then(response => {
            store.commit('setEmails', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getMoreMessages(store, { userId, page }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/conversation/get/messages/${userId}`, { params: { page } })
          .then(response => {
            store.commit('setMoreMessages', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    composeEmail(ctx, { receivers, sender_id, message, toSms }) {
      return new Promise((resolve, reject) => {
        axios
          .post('send/message/group', { receivers, sender_id, message, toSms })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    sendMessage(ctx, { conversation_id, user_id, content }) {
      console.log(conversation_id, user_id, content)
      return new Promise((resolve, reject) => {
        axios
          .post('/conversation/message/send', { conversation_id, user_id, content })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateEmail(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/email/update-emails', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEmailLabels(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/email/update-emails-label', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    paginateEmail(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/email/paginate-email', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
