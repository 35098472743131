<template>

  <div>
    <!-- Table Container Card -->
    <courses-list-big-modal
      v-if="isBigModalActive"
      :isBigModalActive.sync="isBigModalActive"
      :masters="masters"
      :classrooms="classrooms"
      :teachers="teachersModal"
      :currentCourseData="currentCourseData"
      @refetch-data="refetchData"
      @suggest-classrooms="suggestClassrooms"
      @suggest-teacher="suggestTeacher"
    />

    <substitution-list-add-new
      :is-add-new-substitution-sidebar-active.sync="isAddNewSubstitutionSidebarActive"
      @refetch-data="refetchData"
    />

    <b-card
      class="mb-0"
    >
      <b-tabs
        justified
        content-class="mt-3"
      >
        <b-tab
          title="Teacher request"
          @click="selectedFilter = 'requests'; sortBy = 'nearest_date'"
          :disabled="isBusy && selectedFilter !== 'requests'"
        />
        <b-tab
          title="Assign teacher"
          @click="selectedFilter = 'needs_teacher'; sortBy = 'date'"
          :disabled="isBusy && selectedFilter !== 'needs_teacher'"
        />
        <b-tab
          title="Assign substitute"
          @click="selectedFilter = 'needs_substitute'; sortBy = 'nearest_date'"
          :disabled="isBusy && selectedFilter !== 'needs_substitute'"
        />
      </b-tabs>
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            lg="6"
          >
            <!-- <v-select
              v-model="selectedFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filters"
              lebel="label"
              :clearable="false"
              class="courses-filter d-inline-block mx-50"
            /> -->
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-if="selectedFilter === 'requests'"
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
                debounce="500"
              />
              <b-form-input
                v-if="selectedFilter === 'needs_teacher'"
                v-model="searchQuery1"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
                debounce="500"
              />
              <b-form-input
                v-if="selectedFilter === 'needs_substitute'"
                v-model="searchQuery2"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
                debounce="500"
              />
              <b-button
                v-if="selectedFilter === 'needs_substitute'"
                variant="primary"
                @click="isAddNewSubstitutionSidebarActive = true"
              >
                <span class="text-nowrap">{{ ('Request Substitutes') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields=" selectedFilter === 'needs_substitute' ? tableColumns1 : selectedFilter === 'needs_teacher' ? tableColumns.filter(el => el.key !== 'teachers') : tableColumns"
        :primary-key="'id'+Math.random()"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner variant="primary" />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>
        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.id }}
            </b-link>
          </b-media>
        </template>
        <template #cell(nearest_date)="data">
          <span>{{ data.item.lesson_number }}</span>
          <small class="d-block text-nowrap text-secondary">{{ data.item.nearest_date }}</small>
        </template>
        <!-- Column: Name -->
        <template #cell(course_name)="data">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            :disabled="!data.item.group"
            @click="ModalData(data.item.id, 'group')"
          >
            <b class="d-flex d-block text-nowrap">
              {{ data.item.group }}
            </b>
            <small class="d-flex mt-50 text-muted">
              {{ data.item.course_name }}
            </small>
          </b-button>
        </template>

        <template #cell(classroom_name)="data">
          <span
            class="d-inline-block text-truncate"
            style="max-width: 300px;"
          >{{ data.item.classroom_name }}</span>
        </template>
        <!-- Column: Teachers -->
        <template #cell(teachers)="data">
          <b-avatar-group
            size="38px"
          >
            <b-avatar
              v-for="teacher in data.item.teachers"
              :key="teacher.id"
              v-b-tooltip.hover
              class="pull-up"
              button
              :title="teacher.name"
              :src="teacher.avatar && teacher.avatar.split('/')[5] === 'default-avatar.jpg' ? null : teacher.avatar"
              :text="avatarText(teacher.name)"
              :class="teacher.by === 'admin' ? 'pending-admin' : 'pending-teacher'"
              @click="$router.push({ name: 'admin-teacher-calendar', params: { teacher } })"
            />
          </b-avatar-group>
        </template>
        <template #cell(substitutes)="data">
          <b-avatar-group
            size="38px"
          >
            <b-avatar
              v-for="teacher in data.item.substitutes"
              :key="teacher.id+teacher.by"
              v-b-tooltip.hover
              class="pull-up"
              button
              :title="teacher.name"
              :src="teacher.avatar && teacher.avatar.split('/')[5] === 'default-avatar.jpg' ? null : teacher.avatar"
              :text="avatarText(teacher.name)"
              :class="teacher.status === 'Approved' ? 'active-teacher' : teacher.by === 'admin' ? 'pending-admin' : 'pending-teacher'"
              @click="$router.push({ name: 'admin-teacher-calendar', params: { teacher } })"
            />
          </b-avatar-group>
        </template>
        <template #cell(teacher)="data">
          <b-avatar-group
            v-if="data.item.current_teacher"
            size="38px"
          >
            <b-avatar
              v-if="data.item.current_teacher"
              v-b-tooltip.hover
              class="pull-up"
              button
              :title="data.item.current_teacher.name"
              :src="data.item.current_teacher.avatar && data.item.current_teacher.avatar.split('/')[5] === 'default-avatar.jpg' ? null : data.item.current_teacher.avatar"
              :text="avatarText(data.item.current_teacher.name)"
              @click="$router.push({ name: 'admin-teacher-calendar', params: { current_teacher } })"
            />
          </b-avatar-group>
        </template>
        <!-- Column: Date -->
        <template #cell(date)="data">
          <div class="font-weight-bold d-block text-nowrap text-capitalize">
            {{ formatDateTime(data.item.date_start) }} {{ data.item.approval_type === 'course' ? '- ' + formatDateTime(data.item.date_end) : '' }}
          </div>
          <small class="text-muted text-nowrap">{{ data.item.time_from }} - {{ data.item.time_to }}</small>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!-- Assign substitute action -->
          <b-button
            v-b-modal="data.item.teachers.length > 1 ? getModal4Id(data.item.id,data.index) : ''"
            v-if="selectedFilter === 'requests'"
            size="sm"
            :variant="'success'"
            @click="reason = null; approvedTeacher = {}; data.item.teachers.length === 1 ? apply('approve', data.item.teachers[0].id, data.item, reason) : ''"
          >
            <span>{{ $t('Approve') }}</span>
          </b-button>
          <!-- Assign substitute action -->
          <b-button
            v-if="selectedFilter === 'needs_substitute'"
            v-b-modal="getModal3Id(data.item.id)"
            size="sm"
            :variant="'primary'"
            @click="getSubstitutes(data.item.id)"
          >
            <span>{{ $t('Assign substitute') }}</span>
          </b-button>
          <!-- Assign teacher action -->
          <b-button
            v-if="selectedFilter === 'needs_teacher'"
            v-b-modal="getModal1Id(data.item.id)"
            size="sm"
            :variant="'primary'"
            @click="getSubstitutes(data.item.id)"
          >
            <span>{{ $t('Assign teacher') }}</span>
          </b-button>
          <b-button
            v-if="selectedFilter === 'requests'"
            v-b-modal="getModal2Id(data.item.id)"
            size="sm"
            variant="danger"
            class="ml-50"
            @click="reason = null"
          >
            <span>{{ data.item.teachers.length > 1 ? 'Decline all' : 'Decline' }}</span>
          </b-button>
          <b-modal
            :id="'modal2'+(selectedFilter === 'needs_substitute' ? data.item.session_id : data.item.id)"
            title="Denial reason"
            ok-title="Decline"
            ok-only
            centered
            size="lg"
            no-close-on-backdrop
            @ok="deny(data.item.approval_type === 'session' ? data.item.session_id : data.item.id, data.item.approval_type, reason)"
          >
            <b-form-group
              v-if="data.item.teachers.length"
              label="Reason"
              label-for="reason"
            >
              <b-form-textarea
                v-model="reason"
                placeholder="Reason"
                rows="3"
                maxlength="500"
              />
            </b-form-group>
          </b-modal>
          <b-modal
            v-if="selectedFilter === 'needs_teacher'"
            :id="'modal1'+data.item.id"
            title="Assign the following teacher"
            ok-title="Assign"
            ok-only
            centered
            size="lg"
            no-close-on-backdrop
            @ok="apply(('assign'), (selectedTeachers.map(teacher => teacher.id)), data.item, reason)"
          >
            <div
              v-if="!isLoaded"
              class="d-flex justify-content-center p-2 align-items-center"
            >
              <b-spinner
                variant="primary"
                label="Loading..."
              />
            </div>
            <b-form-group
              v-if="isLoaded"
              label="Teachers"
              label-for="teachers"
            >
              <v-select
                v-model="selectedTeachers"
                multiple
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="teacherOptions"
                :clearable="false"
                :selectable="el => el.available === 'available'"
              >
                <template #option="{ name, code, available }">
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="d-block"> {{ code }}</span>
                    <small class="ml-50">
                      <b-badge
                        pill
                        :variant="`light-${available === 'available' ? 'success' : 'danger'}`"
                        class="text-capitalize"
                      >
                        {{ available === 'available' ? 'Available' : 'Unavailable' }}
                      </b-badge>
                    </small>
                    <!-- <small class="ml-50" :class="available ? 'text-success' : 'text-danger'">{{ available ? 'Available' : 'Unavailable' }}</small> -->
                  </div>
                  <small class="text-muted">{{ name }}</small>
                </template>
              </v-select>
            </b-form-group>
            <b-form-group
              v-if="data.item.teachers.length"
              label="Reason"
              label-for="reason"
            >
              <b-form-textarea
                v-model="reason"
                placeholder="Message"
                rows="3"
                maxlength="500"
              />
            </b-form-group>
          </b-modal>
          <b-modal
            v-if="data.item.teachers.length > 1"
            :id="'modal4'+data.item.id+data.index"
            title="Approve teacher"
            centered
            no-close-on-backdrop
            ok-title="Approve"
            @ok="apply('approve', approvedTeacher.id, data.item, reason)"
          >
            <b-form-group
              label="Select teacher"
              label-for="select-teacher"
            >
              <v-select
                v-model="approvedTeacher"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="data.item.teachers.filter(teacher => teacher.by !== 'admin')"
                :clearable="false"
              >
                <template #option="{ name, avatar }">
                  <b-avatar
                    size="22"
                    class="pull-up"
                    button
                    :title="name"
                    :src="avatar"
                  />
                  <span class="ml-1">{{ name }}</span>
                </template>
              </v-select>
            </b-form-group>
            <b-form-group
              label="Reason"
              label-for="reason"
            >
              <b-form-textarea
                v-model="reason"
                placeholder="Reason"
                rows="3"
                maxlength="500"
              />
            </b-form-group>
          </b-modal>
          <b-modal
            :id="'modal3'+data.item.id"
            :title="'Assign substitue to course: '+data.item.course_name+'('+data.item.group+')'"
            ok-title="Close"
            ok-only
            hide-footer
            centered
            size="xl"
            body-class="substitute-modal"
            no-close-on-backdrop
            @hidden="refetchData"
          >
            <substitute-list
              :courseId="data.item.id"
              :has-loaded-teachers="isLoaded"
              @refetch-data="refetchData"
            />
          </b-modal>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="3"
            lg="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>
          <b-col
            cols="3"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="!isBusy"
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BLink,
  BPagination, BButton, BSpinner, VBTooltip, BAvatarGroup, BAvatar, BModal,
  BTabs, BTab, BFormGroup, BFormTextarea, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted, ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import CoursesListBigModal from '@/views/courses/admin/courses-list/CoursesListBigModal.vue'
import { avatarText } from '@core/utils/filter'
import useCoursesList from './useCoursesList'
import courseStoreModule from './courseStoreModule'
import SubstitutionListAddNew from './SubstitutionListAddNew.vue'
import SubstituteList from './SubstitutesList.vue'

export default {
  components: {
    SubstituteList,
    SubstitutionListAddNew,
    CoursesListBigModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BLink,
    BPagination,
    BButton,
    BSpinner,
    BAvatarGroup,
    BAvatar,
    BModal,
    BTabs,
    BTab,
    BFormGroup,
    BFormTextarea,
    BBadge,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      avatarText,
      isBusy: false,
      teachers: [],
      date_range: null,
      selectedDay: [],
      selectedTime: [],
      allRowsSelected: false,
    }
  },
  /* eslint-disable camelcase */
  created() {
    this.$http.get('/user-list/data', { params: { role_name: 'teacher', perPage: 100, status: 'Active' } })
    .then(res => { this.teachers = res.data.rows })
  },
  methods: {
    closeAttendanceModal(id) {
      this.$bvModal.hide(`modal1${id}`)
    },
    selectAllRows() {
      this.$refs.refSubstituteListTable.selectAllRows()
        this.allRowsSelected = true
      },
    clearSelected() {
      this.$refs.refSubstituteListTable.clearSelected()
      this.allRowsSelected = false
    },
  },
  setup() {
    const getModal1Id = id => `modal1${id}`
    const getModal2Id = id => `modal2${id}`
    const getModal3Id = id => `modal3${id}`
    const getModal4Id = (id, indx) => `modal4${id}${indx}`

    const COURSE_STORE_MODULE_NAME = 'course'
    // Register module
    if (!store.hasModule(COURSE_STORE_MODULE_NAME)) store.registerModule(COURSE_STORE_MODULE_NAME, courseStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COURSE_STORE_MODULE_NAME)) store.unregisterModule(COURSE_STORE_MODULE_NAME)
    })
    const filters = [
      { label: 'All', value: 'all' },
      { label: 'Requests', value: 'requests' },
      { label: 'Needing teacher', value: 'needs_teacher' },
      { label: 'Needing substitutes', value: 'needs_substitute' },
    ]
    const isAddNewSubstitutionSidebarActive = ref(false)
    const {
      approvedTeacher,
      userData,
      fetchUsers,
      tableColumns,
      tableColumns1,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchQuery1,
      searchQuery2,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      apply,
      reason,
      deny,
      selectedTeachers,
      selectedFilter,
      resolveUserStatusVariant,
      // Course modal
      classrooms,
      teachersModal,
      masters,
      suggestClassrooms,
      suggestTeacher,
      ModalData,
      currentCourseData,
      isBigModalActive,
      spinner,
    } = useCoursesList()
    const selectedRows = ref([])
    const onRowSelected = items => {
      selectedRows.value = items.map(item => item.session_id)
    }
    const substitutions = ref([
        {
          id: '1', lesson_number: '1', course_name: 'A1-4J;LK2', teacher: 'Kriss', substitute: 'Windey', date: '17-09-2022', classroom_name: 'UNO', time_from: '15:00', time_to: '17:00',
        },
        {
          id: '2', lesson_number: '1', course_name: 'A1-4J;LK2', teacher: 'Kriss', substitute: 'Windey', date: '17-09-2022', classroom_name: 'UNO', time_from: '15:00', time_to: '17:00',
        },
    ])
    const loadSpinner = () => {
      // open
      spinner.value = true
      setTimeout(() => {
        spinner.value = false
      }, 1000)
    }
    const teacherOptions = ref([])
    const isLoaded = ref(true)
    const getSubstitutes = function (course_id) {
      isLoaded.value = false
      teacherOptions.value = []
      reason.value = null
      selectedTeachers.value = null
      this.$http.get('/teacher/suggest/by-course', { params: { course_id } })
      .then(res => {
        isLoaded.value = true
        teacherOptions.value = res.data.allTeachers
      })
    }
    const assignSubtitution = (courses, substitutes) => {
      console.log(courses, substitutes)
    }
    // format from timestamp to dd:mm:yyyy h:mm:ss
    const formatDateTime = dateTime => {
      if (dateTime) {
        const timestamp = dateTime.split(' ')
        // const fullTime = (typeof timestamp[1] !== 'undefined') ? timestamp[1] : ''
        const fullDate = (typeof timestamp[0] !== 'undefined') ? timestamp[0] : ''
        let dateArr
        let dateStr = null
        if (fullDate !== '') {
          dateArr = fullDate.split('-')
          dateStr = `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`
        }
        return `${dateStr}`
      }
      return ''
    }
    return {
      // getSessionData,
      approvedTeacher,
      assignSubtitution,
      isAddNewSubstitutionSidebarActive,
      onRowSelected,
      selectedRows,
      substitutions,
      getSubstitutes,
      isLoaded,
      teacherOptions,
      formatDateTime,
      selectedFilter,
      filters,
      // Modal ids
      getModal1Id,
      getModal2Id,
      getModal3Id,
      getModal4Id,
      userData,
      fetchUsers,
      tableColumns,
      tableColumns1,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchQuery1,
      searchQuery2,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      apply,
      reason,
      deny,
      selectedTeachers,
      resolveUserStatusVariant,
      // Course modal
      classrooms,
      teachersModal,
      masters,
      suggestClassrooms,
      suggestTeacher,
      ModalData,
      currentCourseData,
      isBigModalActive,
      spinner,
      loadSpinner,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  /* background-color: #f7f7f7; */
}
.input:focus {
  outline: none;
}
  .courses-filter {
    min-width: 210px;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
