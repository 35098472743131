<!-- eslint-disable -->
<template>
  <b-card>
    <!-- form -->
    <validation-observer
      ref="generalData"
    >
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <validation-provider
                    #default="{ errors }"
                    name="Role"
                    vid="role"
                    rules="required"
            >
            <b-form-group
              label="Role"
              label-for="role"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <v-select
                v-model="localOptions.role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="label"
                :clearable="false"
                :disabled="$store.state.appConfig.hasOtherAccount || hasOtherAccount"
                :options="['teacher', 'student']"
                @input="getUsers('', localOptions.role)"
              />
            </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
                    #default="{ errors }"
                    name="Account"
                    vid="account"
                    rules="required"
            >
              <b-form-group
                label="Associated account"
                label-for="user-associated"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <v-select
                  v-model="localOptions.user"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :clearable="false"
                  :filterable="false"
                  :options="usersList"
                  :disabled="$store.state.appConfig.hasOtherAccount || hasOtherAccount"
                  @keyup.native="getUsers($event.target.value, localOptions.role)"
                  @click.native="getUsers('', localOptions.role)"
                >
                  <template #option="{ name, email }">
                      <span> {{ name }}</span>
                      <small class="text-secondary d-block">{{ email }}</small>
                  </template>
                  <span slot="no-options">
                    <div
                      v-if="isTypingUser"
                      class="text-center d-flex align-items-center justify-content-center"
                    >
                      <b-spinner
                        variant="primary"
                        label="Text Centered"
                      />
                    </div>
                    <div
                      v-if="!isTypingUser && !usersList.length"
                      class="text-center d-flex align-items-center justify-content-center"
                    >
                      No matching records found
                    </div>
                  </span>
                </v-select>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" />
          <b-col cols="12">
            <b-button
              v-if="!localOptions.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="isBusy || (localOptions.user && !localOptions.user.id)"
              @click.prevent="save(localOptions.user.id)"
            >
              <b-spinner v-if="isBusy" small />
              Associate account
            </b-button>
            <b-button
              v-if="localOptions.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="isBusy"
              @click="remove(localOptions.id)"
            >
              <b-spinner v-if="isBusy" small />
              Dessociate account
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
/* eslint-disable camelcase */
import {
  BButton, BForm, BFormGroup, BRow, BCol, BCard,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    secondAccountData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: {},
      localOptions: JSON.parse(JSON.stringify(this.secondAccountData)),
      profileFile: null,
      oldName: '',
      // validation rules
      required,
      email,
      userData: getUserData(),
      usersList: [],
      isTypingUser: false,
      isBusy: false,
      hasOtherAccount: false,
    }
  },
  mounted() {
    // this.getUsers()
    if (this.localOptions.id) {
      this.$store.state.appConfig.hasOtherAccount = true
      this.hasOtherAccount = true
    }
  },
  methods: {
    remove(id) {
      this.isBusy = true
      this.$http.post('/account/desassociate', { id }).then(res => {
        this.isBusy = false
        this.hasOtherAccount = false
        localStorage.removeItem('otherAccount')
        this.$store.state.appConfig.otherAccount = null
        this.$store.state.appConfig.hasOtherAccount = false
        this.localOptions = {
          role: null,
          user: {},
          id: null,
        }
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            text: res.data.message,
            icon: 'CheckIcon',
          },
        })
      })
      .catch(error => this.$refs.generalData.setErrors(error.response.data.errors))
    },
    getUsers(search, role_name) {
      this.isTypingUser = true
      this.$http.post('/autocomplete/user', { search, role_name })
      .then(res => {
        this.usersList = res.data
        this.isTypingUser = false
      })
    },
    save(id) {
      this.isBusy = true
      const body = {
        email: this.localOptions.email,
        role: this.localOptions.role,
        user_id: id,
      }
      this.$http.post('/add/other/account', body)
      .then(response => {
          this.isBusy = false
          localStorage.otherAccount = JSON.stringify({ role: this.localOptions.role, id })
          this.$store.state.appConfig.otherAccount = { role: this.localOptions.role, id }
          this.hasOtherAccount = true
          this.localOptions.id = id
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              text: response.data.message,
              icon: 'CheckIcon',
            },
          })
        }).catch(error => {
          this.$refs.generalData.setErrors(error.response.data.errors)
        })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { previewEl.value.src = base64 })
    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
