<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            lg="6"
          >
            <v-select
              v-model="selectedFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filters"
              lebel="label"
              :clearable="false"
              class="courses-filter d-inline-block mx-50"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
                debounce="500"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
              style="width: 3rem; height: 3rem;"
            />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>
        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.id }}
            </b-link>
          </b-media>
        </template>
        <template #cell(teacher)="data">
          <span
            v-b-tooltip.hover.top="data.item.teacher"
            class="d-inline-block text-truncate"
            style="max-width: 300px;"
          >{{ data.item.teacher }}</span>
        </template>
        <template #cell(substitute)="data">
          <span
            v-b-tooltip.hover.top="data.item.substitute"
            class="d-inline-block text-truncate"
            style="max-width: 300px;"
          >{{ data.item.substitute }}</span>
        </template>
        <template #cell(classroom)="data">
          <span
            v-b-tooltip.hover.top="data.item.classroom"
            class="d-inline-block text-truncate"
            style="max-width: 300px;"
          >{{ data.item.classroom }}</span>
        </template>
        <template #cell(course)="data">
          <span class="d-inline">{{ data.item.session_number }} </span>(<span
            v-b-tooltip.hover.top="data.item.course"
            class="text-truncate"
            style="max-width: 300px;"
          >{{ data.item.course }}</span>)
          <small class="text-muted d-block text-nowrap">{{ data.item.group }}</small>
        </template>

        <!-- Column: Session ID & date time  -->
        <template #cell(time_session)="data">
          <span class="d-block">{{ data.item.sdate }}</span>
          <small class="text-muted text-nowrap">
            {{ data.item.stime_from }} - {{ data.item.stime_to }}
          </small>
        </template>

        <!-- Column: Date -->
        <template #cell(date)="data">
          <b-badge
            pill
            variant="light-secondary"
            class="text-capitalize"
          >
            {{ formatDateTime(data.item.date) }}
          </b-badge>
        </template>
        <!-- Column: Date -->
        <template #cell(time)="data">
          <b-badge
            pill
            variant="light-secondary"
            class="text-capitalize"
          >
            {{ data.item.time_from }} {{ data.item.time_to }}
          </b-badge>
        </template>
        <!-- Column: Teacher -->
        <template #cell(substitutions)="data">
          <b-avatar-group
            size="32px"
          >
            <b-avatar
              v-for="teacher in data.item.teachers"
              :key="teacher.id"
              v-b-tooltip.hover
              class="pull-up"
              :title="teacher.name"
              :src="teacher.avatar"
            />
          </b-avatar-group>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-b-modal="getModal1Id(data.item.id)"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="btn-icon"
            :variant="data.item.substitute === 0 ? 'flat-primary' : 'flat-success'"
          >
            {{ data.item.substitute === 0 ? 'Assign substitute' : 'Approve' }}
            <feather-icon :icon="data.item.substitute === 0 ? 'UserPlusIcon' : 'CheckIcon'" />
          </b-button>
          <!-- basic modal -->
          <b-modal
            :id="'modal1'+data.item.id"
            :title="$t('Select a teacher for substitution')"
            ok-title="Save"
            ok-only
            @ok="apply(data.item.session_id, selectedTeacher.id)"
          >
            <v-select
              v-model="selectedTeacher"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="data.item.teachers.length ? data.item.teachers : teachers"
              :clearable="false"
            >
              <template #option="{ avatar, name }">
                <b-avatar
                  size="sm"
                  :src="avatar"
                />
                <span class="ml-50"> {{ name }}</span>
              </template>

              <template #selected-option="{ avatar, name }">
                <b-avatar
                  size="sm"
                  class="border border-white"
                  :src="avatar"
                />
                <span class="ml-50"> {{ name }}</span>
              </template>
            </v-select>
          </b-modal>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="3"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>
          <b-col
            cols="3"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} to {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BLink,
  BPagination, BButton, BBadge, BModal, BSpinner, VBTooltip, BAvatar, BAvatarGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import useCoursesList from './useCoursesList'
import courseStoreModule from './courseStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BLink,
    BPagination,
    BButton,
    BBadge,
    BModal,
    BSpinner,
    BAvatar,
    BAvatarGroup,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      selectedTeacher: {},
      teachers: [],
      isBusy: false,
    }
  },
  created() {
    this.$http.get('/user-list/data', { params: { role_name: 'teacher', perPage: 100, status: 'Active' } })
    .then(res => { this.teachers = res.data.rows })
  },
  methods: {
    closeAttendanceModal(id) {
      this.$bvModal.hide(`modal1${id}`)
    },
  },
  setup() {
    /* eslint-disable camelcase */
    const getModal1Id = id => `modal1${id}`
    const COURSE_STORE_MODULE_NAME = 'course'
    // Register module
    if (!store.hasModule(COURSE_STORE_MODULE_NAME)) store.registerModule(COURSE_STORE_MODULE_NAME, courseStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COURSE_STORE_MODULE_NAME)) store.unregisterModule(COURSE_STORE_MODULE_NAME)
    })

    const filters = [
      { label: 'All', value: 'all' },
      { label: 'Waiting substitute', value: 'waiting_substitute' },
      { label: 'Awaiting approval', value: 'need_to_be_approved' },
    ]
    const {
      userData,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      apply,
      selectedFilter,
    } = useCoursesList()

    // format from timestamp to dd:mm:yyyy h:mm:ss
    const formatDateTime = dateTime => {
      if (typeof dateTime !== 'undefined') {
        const timestamp = dateTime.split(' ')
        const fullTime = (typeof timestamp[1] !== 'undefined') ? timestamp[1] : ''
        const fullDate = (typeof timestamp[0] !== 'undefined') ? timestamp[0] : ''
        let dateArr
        let dateStr = null
        if (fullDate !== '') {
          dateArr = fullDate.split('-')
          dateStr = `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`
        }
        return `${dateStr} ${fullTime}`
      }
      return ''
    }
    return {
      formatDateTime,
      // Modal ids
      getModal1Id,
      userData,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      apply,
      selectedFilter,
      filters,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  /* background-color: #f7f7f7; */
}
.input:focus {
  outline: none;
}
  .courses-filter {
    min-width: 230px;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
