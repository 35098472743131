<template>
  <div>
    <!-- Date and filters -->
    <b-row class="mb-1">
      <b-col
        v-if="!studentId"
        cols="6"
      />
      <b-col :cols="!studentId ? '3' : '6'">
        <div>
          <v-select
            v-model="dateOption"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="dateOptions"
            @input="fetchStatistics()"
          />
        </div>
      </b-col>
      <b-col :cols="!studentId ? '3' : '6'">
        <!-- datepicker -->
        <div class="d-flex align-items-center">
          <feather-icon
            icon="CalendarIcon"
            size="16"
          />
          <flat-pickr
            v-model="rangePicker"
            :config="{ mode: 'range', dateFormat: 'Y-m-d', altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 }}"
            class="form-control flat-picker bg-transparent border-0 shadow-none"
            placeholder="YYYY-MM-DD"
            @input="foo"
          />
        </div>
        <!-- datepicker -->
      </b-col>
    </b-row>
    <!-- Statistics -->
    <b-row>
      <b-col cols="6">
        <current-courses-attendance
          v-if="!studentId"
          :key="refreshKey+String('current_courses_attendance')"
          :support-tracker-radial-bar="apexChartData.supportTrackerRadialBar"
          :tracker-data="trackerData"
        />
      </b-col>
      <b-col :cols="!studentId ? '6' : '12'">
        <study-time
          :key="refreshKey+String('study_time')"
          :sales-line="apexChartData.salesLine"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import apexChartData from './apexChartData'
import CurrentCoursesAttendance from './CurrentCoursesAttendance.vue'
import StudyTime from './StudyTime.vue'

export default {
  components: {
    BRow,
    BCol,
    vSelect,
    flatPickr,
    CurrentCoursesAttendance,
    StudyTime,
  },
  props: {
    studentId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      apexChartData,
      refreshKey: 0,
      rangePicker: [],
      initialRangePicker: [],
      start: '',
      end: '',
      trackerData: {},
      dateOption: { title: 'Daily', value: 'daily' },
      dateOptions: [
        { title: 'Daily', value: 'daily' },
        { title: 'Weekly', value: 'weekly' },
        { title: 'Monthly', value: 'monthly' },
      ],
    }
  },
  created() {
    this.start = moment(new Date()).format('YYYY-MM-DD')
    const nextMonth = new Date(new Date(this.start).setMonth(new Date(this.start).getMonth() + 1))
    this.end = moment(nextMonth).format('YYYY-MM-DD')
    this.rangePicker.push(this.start, this.end)
    this.initialRangePicker.push(this.start, this.end)
    this.fetchStatistics()
    // this.$http.get('/statistics/admin/charts', {  })
  },
  methods: {
    /* eslint-disable prefer-destructuring */
    foo() {
      if (this.rangePicker.split(' ').length > 1) {
        this.start = this.rangePicker.split(' ')[0]
        this.end = this.rangePicker.split(' ')[2]
        this.fetchStatistics()
      }
    },
    fetchStatistics() {
      this.$http.get('/statistics/student/charts', {
        params: {
          date_start: this.start,
          date_end: this.end,
          filter: this.dateOption.value,
          student_id: this.teacherId,
        },
      }).then(res => {
        apexChartData.supportTrackerRadialBar.series = [res.data.currentCoursesAttendance.completed_teckets]
        this.trackerData = {
          courses: res.data.currentCoursesAttendance.courses,
          hoursComplited: res.data.currentCoursesAttendance.hours_completed,
          toBeDone: res.data.currentCoursesAttendance.to_be_done,
          totalHours: res.data.currentCoursesAttendance.total_hours,
        }

        apexChartData.salesLine.series[0].data = res.data.studyTime.data
        apexChartData.salesLine.chartOptions.xaxis.categories = res.data.studyTime.dates

        this.refreshKey += 1
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
