<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Registrations</b-card-title>
      <feather-icon
        icon="SettingsIcon"
        size="18"
        class="text-muted cursor-pointer"
      />
    </b-card-header>

    <b-card-body class="pb-0">
      <div class="d-flex justify-content-start mb-3">
        <div class="mr-2">
          <b-card-text class="mb-50">
            This Month
          </b-card-text>
          <h3 class="font-weight-bolder">
            <!-- <sup class="font-medium-1">$ </sup> -->
            <span class="text-primary">{{ report.thisMonth }}</span>
          </h3>
        </div>
        <div>
          <b-card-text class="mb-50">
            Last Month
          </b-card-text>
          <h3 class="font-weight-bolder">
            <!-- <sup class="font-medium-1">$ </sup> -->
            <span>{{ report.lastMonth }}</span>
          </h3>
        </div>
      </div>

      <!-- apex chart -->
      <vue-apex-charts
        type="line"
        height="240"
        :options="revenueComparisonLine.chartOptions"
        :series="revenueComparisonLine.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
// import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    BCardText,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
  props: {
    revenueComparisonLine: {
      type: Object,
      required: true,
    },
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      revenue: {},
    }
  },
  created() {
    // this.$http.get('/card/card-analytics/revenue').then(res => { this.revenue = res.data })
  },
}
</script>
