var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-add-new-event","sidebar-class":"sidebar-lg","visible":_vm.isEventHandlerSidebarActive,"bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { _vm.$emit('update:is-event-handler-sidebar-active', val); _vm.range = null }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Request a time off ")]),_c('div',[(_vm.eventLocal.id)?_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"TrashIcon"},on:{"click":function($event){_vm.$emit('remove-event'); hide();}}}):_vm._e(),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1)]),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('validation-provider',{attrs:{"name":"Subject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Subject","label-for":"event-title"}},[_c('b-form-input',{attrs:{"id":"event-title","autofocus":"","state":_vm.getValidationState(validationContext),"trim":"","placeholder":"Subject"},model:{value:(_vm.eventLocal.title),callback:function ($$v) {_vm.$set(_vm.eventLocal, "title", $$v)},expression:"eventLocal.title"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('b-form-textarea',{attrs:{"id":"event-description","placeholder":"Description","autofocus":"","state":_vm.getValidationState(validationContext),"rows":"3"},model:{value:(_vm.eventLocal.description),callback:function ($$v) {_vm.$set(_vm.eventLocal, "description", $$v)},expression:"eventLocal.description"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Date range","label-for":"date-range"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                dateFormat: 'Y-m-d', altFormat: 'd-m-Y', altInput: true,
                locale: { firstDayOfWeek: 1 }, mode: 'range', minDate: new Date(),
                onClose: function (dates) { return _vm.setDates(dates); },
              }},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Start time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"From","label-for":"start-time","state":_vm.getValidationState(validationContext)}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ noCalendar: true, enableTime: true, minTime: '07:00', time_24hr: true }},model:{value:(_vm.eventLocal.from),callback:function ($$v) {_vm.$set(_vm.eventLocal, "from", $$v)},expression:"eventLocal.from"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"6"},[_c('validation-provider',{attrs:{"name":"End time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"To","label-for":"end-time","state":_vm.getValidationState(validationContext)}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ noCalendar: true, enableTime: true, minTime: _vm.eventLocal.to, time_24hr: true }},model:{value:(_vm.eventLocal.to),callback:function ($$v) {_vm.$set(_vm.eventLocal, "to", $$v)},expression:"eventLocal.to"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":invalid || !_vm.range}},[_vm._v(" Apply ")])],1)],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }