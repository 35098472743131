<template>
  <div>
    <master-filter
      :isFilterActive.sync="isFilterActive"
      :yesNoArray="yesNoArray"
      :statusArray="statusArray"
      :masters="masters"
      :descriptions="descriptions"
      :Types="Types"
      :languages="languages"
      :name.sync="name"
      :descriptionId.sync="descriptionId"
      :typeId.sync="typeId"
      :languageId.sync="languageId"
      :statusId.sync="statusId"
      @refetch="fetchMCourses"
    />
    <edit-course
      :isModalActive.sync="isModalActive"
      :data="singleData"
      :loading="spinner"
      :masters="masters"
      :descriptions="descriptions"
      :Types="Types"
      :languages="languages"
      :notBelong="notBelong"
      @initilize="ModalData($event)"
      @loading="loadSpinner"
      @refetch="fetchMCourses"
    />
    <!-- // modal 1 -->
    <b-modal
      id="modal-center2"
      centered
      hide-footer
      size="lg"
      title="Add new master"
    >
      <b-form>
        <b-tabs v-model="tabIndex">
          <b-tab title="Step 1">
            <b-row>
              <b-col>
                <b-form-group label="Language">
                  <v-select
                    v-model="Model.language_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Select"
                    label="name"
                    :options="languages"
                    :reduce="x => x.id"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Course type">
                  <v-select
                    v-model="Model.course_type_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Select"
                    label="name"
                    :options="Types"
                    :reduce="x => x.id"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Course description">
                  <v-select
                    v-model="Model.course_desc_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Select"
                    label="name"
                    :options="descriptions"
                    :reduce="x => x.id"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Next">
                  <v-select
                    v-model="Model.next_master_course_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Select"
                    label="name"
                    :options="masters"
                    :reduce="x => x.id"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Step 2">
            <b-row>
              <b-col>
                <b-form-group label="Code">
                  <b-form-input
                    v-model="Model.code"
                    placeholder="Code"
                    type="text"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Name">
                  <b-form-input
                    v-model="Model.name"
                    placeholder="Name"
                    type="text"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Definition">
                  <quill-editor
                    v-model="Model.definition"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Description">
                  <quill-editor
                    v-model="Model.description"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Step 3">
            <b-row>
              <b-col>
                <b-form-group label="Visa required">
                  <v-select
                    v-model="Model.visa_required"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="yesNoArray"
                    :reduce="x => x.value"
                    placeholder="Select"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Visa day limit">
                  <b-form-input
                    v-model="Model.visa_day_limit"
                    placeholder="Visa day limit"
                    type="number"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Payment required">
                  <v-select
                    v-model="Model.payment_required"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="yesNoArray"
                    :reduce="x => x.value"
                    placeholder="Select"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Show start">
                  <v-select
                    v-model="Model.date_start_visible"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="yesNoArray"
                    :reduce="x => x.value"
                    placeholder="Select"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Step 4">
            <b-row>
              <b-col>
                <b-form-group label="Show end">
                  <v-select
                    v-model="Model.date_end_visible"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="yesNoArray"
                    :reduce="x => x.value"
                    placeholder="Select"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Days to hide"
                >
                  <b-form-checkbox-group
                    v-model="Model.selected_days"
                    :options="days"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="always visible">
                  <v-select
                    v-model="Model.always_visible"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="yesNoArray"
                    :reduce="x => x.value"
                    placeholder="Select"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Status"
                  label-for="status"
                >
                  <v-select
                    id="status"
                    v-model="Model.status"
                    label="name"
                    :options="statusArray"
                    :reduce="x => x.value"
                    placeholder="Status"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group>
              <b-button
                variant="primary"
                @click="addNewMaster"
              >
                Add new master course
              </b-button>
            </b-form-group>
          </b-tab>
        </b-tabs>
        <div class="text-center">
          <b-button-group class="mt-1">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="tabIndex--"
            >
              Previous
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="tabIndex++"
            >
              Next
            </b-button>
          </b-button-group>
          <b-card-text>
            <small>Current Tab: {{ tabIndex }}</small>
          </b-card-text>
        </div>
      </b-form>
    </b-modal>
    <b-card>
      <b-row>
        <b-col md="12">
          <h5>Master courses</h5>
          <b-row>
            <b-col md="8">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-center2
                variant="primary"
                class="d-inline-block mr-1"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
              <b-button
                variant="primary"
                class="d-inline-block mr-1"
                @click="isFilterActive = true"
              >
                <feather-icon icon="FilterIcon" />
              </b-button>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Search</label>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Search"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <vue-good-table
            :columns="columns"
            :rows="masters"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'language' && props.row.language">
                {{ props.row.language.name }}
              </span>
              <span v-else-if="props.column.field === 'courseType' && props.row.courseType">
                {{ props.row.courseType.name }}
              </span>
              <span v-else-if="props.column.field === 'CourseDesc' && props.row.CourseDesc">
                {{ props.row.CourseDesc.name }}
              </span>
              <span v-else-if="props.column.field === 'NextMatserCourse' && props.row.NextMatserCourse">
                {{ props.row.NextMatserCourse.name }}
              </span>
              <span v-else-if="props.column.field === 'code'">
                {{ props.row.code }}
              </span>
              <span v-else-if="props.column.field === 'days_to_hide'">
                {{ props.row.days_to_hide }}
              </span>
              <span v-else-if="props.column.field === 'status'">
                <b-badge
                  v-if="props.row.status == 'active'"
                  variant="success"
                >
                  Active
                </b-badge>
                <b-badge
                  v-else
                  variant="danger"
                >
                  Deleted
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'name'">
                {{ props.row.name }}
              </span>
              <span v-else-if="props.column.field === 'payment_required'">
                <feather-icon
                  v-if="props.row.payment_required"
                  icon="CheckSquareIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
                <feather-icon
                  v-else
                  icon="SquareIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </span>
              <span v-else-if="props.column.field === 'date_start_visible'">
                <feather-icon
                  v-if="props.row.date_start_visible"
                  icon="CheckSquareIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
                <feather-icon
                  v-else
                  icon="SquareIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </span>
              <span v-else-if="props.column.field === 'date_end_visible'">
                <feather-icon
                  v-if="props.row.date_end_visible"
                  icon="CheckSquareIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
                <feather-icon
                  v-else
                  icon="SquareIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </span>
              <span v-else-if="props.column.field === 'always_visible'">
                <feather-icon
                  v-if="props.row.always_visible"
                  icon="CheckSquareIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
                <feather-icon
                  v-else
                  icon="SquareIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </span>
              <span v-else-if="props.column.field === 'courses'">
                <b-list-group v-if="props.row.courses.length > 0">
                  <b-list-group-item
                    v-for="(course, index) in props.row.courses.slice(0,4)"
                    :key="index"
                  >
                    <span v-if="index < 3">
                      {{ course.date_start }}
                    </span>
                    <span v-else>
                      <feather-icon
                        icon="MoreHorizontalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </span>
                  </b-list-group-item>
                </b-list-group>
                <span v-else>
                  Empty
                </span>
              </span>
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    dropleft
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item @click="ModalData(props.row.id)">
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span class="align-middle ml-50">Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="deleteMaster(props.row.id)">
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
              <span v-else>
                Empty
              </span>
            </template>
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BForm,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BFormCheckboxGroup,
  BButton,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardText,
  BButtonGroup,
  BListGroup,
  BListGroupItem,
  BBadge,
  BModal,
  VBModal,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import {
    ref,
    onMounted,
    onUnmounted,
} from '@vue/composition-api'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'
import store from '@/store'
import coursesStoreModule from '../coursesStoreModule'
import EditCourse from './EditCourse.vue'
import MasterFilter from './MasterListFilter.vue'

export default {
  components: {
    MasterFilter,
    EditCourse,
    BRow,
    BCol,
    BForm,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BFormCheckboxGroup,
    BButton,
    BDropdown,
    BDropdownItem,
    BCard,
    BListGroup,
    BListGroupItem,
    BBadge,
    BModal,
    BTabs,
    BTab,
    BCardText,
    BButtonGroup,

    // third party
    VueGoodTable,
    vSelect,
    quillEditor,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-tree'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, coursesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    //
    const toast = useToast()

    const days = ref([
      { text: 'Su', value: 'su' },
      { text: 'Mo', value: 'mo' },
      { text: 'Tu', value: 'tu' },
      { text: 'We', value: 'we' },
      { text: 'Th', value: 'th' },
      { text: 'Fr', value: 'fr' },
      { text: 'Sa', value: 'sa' },
    ])
    const statusArray = ref([
      { name: 'Active', value: 'active' },
      { name: 'Deleted', value: 'deleted' },
    ])
    const yesNoArray = ref([
      { name: 'Yes', value: true },
      { name: 'No', value: false },
    ])
    const columns = ref([
      {
        label: 'Courses',
        field: 'courses',
      },
      {
        label: 'Language',
        field: 'language',
      },
      {
        label: 'Type',
        field: 'courseType',
      },
      {
        label: 'Description',
        field: 'CourseDesc',
      },
      {
        label: 'Next',
        field: 'NextMatserCourse',
      },
      {
        label: 'Code',
        field: 'code',
      },
      {
        label: 'Name',
        field: 'name',
      },
      {
        label: 'Payment R?',
        field: 'payment_required',
      },
      {
        label: 'Show start?',
        field: 'date_start_visible',
      },
      {
        label: 'Show end?',
        field: 'date_end_visible',
      },
      {
        label: 'Days to hide',
        field: 'days_to_hide',
      },
      {
        label: 'Always visible?',
        field: 'always_visible',
      },
      {
        label: 'Status',
        field: 'status',
      },
      {
        label: 'Action',
        field: 'action',
      },
    ])
    const isFilterActive = ref(false)
    const searchTerm = ref('')
    const pageLength = ref(3)
    const masters = ref([])
    const languages = ref([])
    const descriptions = ref([])
    const Model = ref({})
    const isModalActive = ref(false)
    const singleData = ref({})
    const notBelong = ref([])
    const spinner = ref(false)
    const tabIndex = ref(0)
    const Types = ref([])
    const name = ref('')
    const languageId = ref(null)
    const typeId = ref(null)
    const descriptionId = ref(null)
    const statusId = ref(null)
    const fetchMCourses = () => {
      store.dispatch('app-tree/fetchMCourses', {
        name: name.value,
        course_desc_id: descriptionId.value,
        course_type_id: typeId.value,
        language_id: languageId.value,
        status: statusId.value,
      })
      .then(() => {
        masters.value = store.state.coursesStoreModule.Mcourses
      })
    }
    const ModalData = id => {
      isModalActive.value = true
      store.dispatch('app-tree/getSMCourse', id)
        .then(() => {
          singleData.value = store.state.coursesStoreModule.singleMasterCourse.single
          notBelong.value = store.state.coursesStoreModule.singleMasterCourse.not_belong
          const sDays = singleData.value.days_to_hide
          if (sDays) {
            const daysArr = sDays.split(',')
            singleData.value.days_to_hide = daysArr
          } else {
            singleData.value.days_to_hide = []
          }
        }).catch(error => console.log(error))
    }
    const fetchLanguages = () => {
      store.dispatch('app-tree/fetchLanguages')
      .then(() => {
        languages.value = store.state.coursesStoreModule.Languages
      })
    }
    const fetchTypes = () => {
        store.dispatch('app-tree/fetchTypes')
        .then(() => {
            Types.value = store.state.coursesStoreModule.Types
        })
    }
    const fetchDescriptions = () => {
      store.dispatch('app-tree/fetchDescriptions')
      .then(() => {
        descriptions.value = store.state.coursesStoreModule.Descriptions
      })
    }
    const addNewMaster = () => {
      if (Model.value.selected_days) {
        const selectedDays = Model.value.selected_days.toString()
        Model.value.days_to_hide = selectedDays
      } else {
        Model.value.days_to_hide = ''
      }
      store.dispatch('app-tree/AddNewM', Model.value)
      .then(() => {
        fetchMCourses()
        Model.value = {}
        toast({
            component: ToastificationContent,
            props: {
            title: 'Master added successfully',
            icon: 'CheckIcon',
            variant: 'success',
            },
        })
      }).catch(error => {
        toast({
            component: ToastificationContent,
            props: {
            title: error,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            },
        })
       })
    }

    const deleteMaster = id => {
        store.dispatch('app-tree/deleteM', id)
        .then(() => {
            fetchMCourses()
            toast({
                component: ToastificationContent,
                props: {
                title: 'Deleted successfully',
                icon: 'CheckIcon',
                variant: 'success',
                },
            })
        }).catch(error => {
            toast({
                component: ToastificationContent,
                props: {
                title: error,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                },
            })
        })
    }

    const loadSpinner = () => {
      // open
      spinner.value = true
      setTimeout(() => {
        spinner.value = false
      }, 2000)
    }

    onMounted(fetchMCourses)
    onMounted(fetchLanguages)
    onMounted(fetchDescriptions)
    onMounted(fetchTypes)

    return {
        columns,
        searchTerm,
        pageLength,
        days,
        statusArray,
        yesNoArray,
        masters,
        Types,
        languages,
        descriptions,
        Model,
        spinner,
        singleData,
        isModalActive,
        notBelong,
        tabIndex,
        isFilterActive,
        name,
        languageId,
        typeId,
        descriptionId,
        statusId,
        addNewMaster,
        ModalData,
        loadSpinner,
        fetchMCourses,
        deleteMaster,
    }
  },
}
</script>
