import { ref, watch, computed } from '@vue/composition-api'
// import { title } from '@core/utils/filter'
// Notification

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import store from '@/store'
import router from '@/router'

export default function useCoursesList() {
  // Use toast
  const toast = useToast()
  const { t } = useI18nUtils()

  const refLogTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: t('id'), sortable: true },
    { key: 'user', sortable: true },
    { key: 'message', label: 'action', sortable: true },
    { key: 'date', label: t('date & time'), sortable: true },
    // { key: 'actions', label: t('actions'), tdClass: 'table-actions' },
  ]
  const perPage = ref(10)
  const totalElements = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('date')
  const isSortDirDesc = ref(true)
  const dataMeta = computed(() => {
    const localItemsCount = refLogTable.value ? refLogTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalElements.value,
    }
  })

  const refetchData = () => {
    refLogTable.value.refresh()
  }
  const urlParams = new URLSearchParams(window.location.search)
  const urlQueries = router.currentRoute.query
  if (urlQueries.search) searchQuery.value = urlQueries.search
  if (urlQueries.page) currentPage.value = urlQueries.page
  if (urlQueries.perPage) perPage.value = urlQueries.perPage
  watch([currentPage, perPage, searchQuery], () => {
    urlParams.set('search', searchQuery.value ? searchQuery.value : '')
    urlParams.set('page', currentPage.value ? currentPage.value : '')
    urlParams.set('perPage', perPage.value ? perPage.value : '')
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams.toString()}`)
    refetchData()
  })
  const isBusy = ref(false)

  const fetchElements = (ctx, callback) => {
    const payload = {
      search: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    }
    store
      .dispatch('log/fetchActivities', payload)
      .then(res => {
        const users = res.data.rows
        const total = res.data.totalRecords
        callback(users)
        totalElements.value = total
      })
      .catch(() => {
        isBusy.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching activity log list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchElements,
    tableColumns,
    perPage,
    currentPage,
    totalElements,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refLogTable,
    refetchData,
  }
}
