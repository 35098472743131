<template>
  <!-- eslint-disable vue/mustache-interpolation-spacing -->
  <div>
    <!-- Filters -->
    <!-- <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            lg="6"
          />
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
                debounce="500"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        :busy.sync="isBusy"
        class="position-relative"
        :items="fetchRequests"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>
        <!-- Column: User -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.id }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Name -->
        <template #cell(name)="data">
          <span
            v-b-tooltip.hover.top="data.item.name"
            class="d-inline-block text-truncate"
            style="max-width: 300px;"
          >{{ data.item.name }}</span>
        </template>
        <!-- Column: Due date -->
        <template #cell(due_date)="data">
          <div>{{ formatDateTime(data.item.due_date) }}</div>
          <small class="text-muted">{{ data.item.due_date.split(' ')[1] }}</small>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-b-modal="getModal4Id(data.item.id)"
              :disabled="data.item.status !== 'request_prolong'"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{$t('Extend due date')}}</span>
            </b-dropdown-item>
            <!-- Edit due date -->
            <b-modal
              :id="'modal4'+data.item.id"
              :title="$t('Prolong the test')"
              :ok-title="$t('Save')"
              :ok-disabled="!new_due_date"
              ok-only
              centered
              no-close-on-backdrop
              @ok="prolong(data.item.id)"
            >
              <b-form-group>
                <flat-pickr
                  v-model="new_due_date"
                  class="form-control"
                  name="date"
                  :placeholder="$t('New due date')"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss'}"
                />
              </b-form-group>
            </b-modal>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{$t('Show')}}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{$t('entries')}}</label>
          </b-col>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{$t('Showing')}} {{ dataMeta.from }} {{$t('to')}} {{ dataMeta.to }} {{$t('of')}} {{ dataMeta.of }} {{$t('entries')}}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="!isBusy"
              v-model="currentPage"
              :total-rows="totalCities"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormGroup, BTable, BMedia, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, BSpinner, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// import UsersListFilters from './UsersListFilters.vue'
import useCitiesList from './useCitiesList'
import cityStoreModule from './cityStoreModule'

export default {
  components: {
    // UsersListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BSpinner,

    vSelect,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      statusOptions: ['Active', 'Deleted'],
      isBusy: false,
      required,
    }
  },
  setup() {
    /* eslint-disable camelcase */
    const getModal1Id = id => `modal1${id}`
    const getModal2Id = id => `modal2${id}`
    const getModal3Id = id => `modal3${id}`
    const getModal4Id = id => `modal4${id}`

    const CITY_STORE_MODULE_NAME = 'city'

    // Register module
    if (!store.hasModule(CITY_STORE_MODULE_NAME)) store.registerModule(CITY_STORE_MODULE_NAME, cityStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CITY_STORE_MODULE_NAME)) store.unregisterModule(CITY_STORE_MODULE_NAME)
    })

    const roleOptions = [
      { label: 'Admin', value: 'administrator' },
      { label: 'Student', value: 'student' },
      { label: 'Teacher', value: 'teacher' },
      { label: 'Agent', value: 'agent' },
      { label: 'Company', value: 'company' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Deleted', value: 'deleted' },
    ]
    const filters = [
      { label: 'All', value: null },
      { label: 'Active', value: 'Active' },
      { label: 'Deleted', value: 'Deleted' },
    ]
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userRole = userData.role
    const selectedStatus = ref({ label: 'Active', value: 'Active' })

    // Use toast
    const toast = useToast()

    const {
      fetchRequests,
      tableColumns,
      perPage,
      currentPage,
      totalCities,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      selectedFilter,
    } = useCitiesList()

    /* eslint-disable camelcase */
    const new_due_date = ref(null)
    const remove = id => {
      store.dispatch('city/deleteCity', id)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'City Has Been removed',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while removing City',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const edit = function (id, name, ordering) {
      if (this.$refs.form.flags.valid) {
        const payload = {
          name,
          ordering,
          status: selectedStatus.value.value,
        }
        store.dispatch('city/editCity', { id, payload })
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'City Has Been edited',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            refetchData()
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error while editing City',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          })
      }
    }
    const prolong = testId => {
      store.dispatch('city/prolong', { new_due_date, testId })
      .then(res => {
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        refetchData()
    }).catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error while prolonging date',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      })
    }
    const StudentProlong = testId => {
      store.dispatch('city/StudentProlong', testId)
      .then(res => {
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        refetchData()
    }).catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error while prolonging date',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      })
    }
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(() => {})
    const formatDateTime = dateTime => {
      if (typeof dateTime !== 'undefined') {
        const timestamp = new Date(dateTime).getTime()
        const todate = new Date(timestamp).getDate()
        const tomonth = new Date(timestamp).getMonth() + 1
        const toyear = new Date(timestamp).getFullYear()

        const today = `0${todate.toString().slice(-2)}`
        const thisMonth = `0${tomonth.toString().slice(-2)}`
        const day = (today.length === 2 ? today : today.substr(1))
        const month = (thisMonth.length === 2 ? thisMonth : thisMonth.substr(1))

        const original_date = `${day}-${month}-${toyear}`
        return original_date
      }
      return ''
    }

    return {
      refFormObserver,
      getValidationState,
      // Modal ids
      getModal1Id,
      getModal2Id,
      getModal3Id,
      getModal4Id,
      formatDateTime,

      // Actions
      new_due_date,
      remove,
      edit,
      prolong,
      StudentProlong,

      userRole,
      selectedStatus,

      fetchRequests,
      tableColumns,
      perPage,
      currentPage,
      totalCities,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      filters,
      selectedFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
