<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isFilterActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:isFilterActive', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('Registrations filter') }}
        </h5>
        <div>
          <feather-icon
            icon="RefreshCcwIcon"
            class="cursor-pointer"
            @click="$emit('reset-filter')"
          />
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

      </div>
      <div class="p-2">
        <b-form-group
          :label="$t('Student')"
          label-for="student"
        >
          <v-select
            :value="student"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="students"
            placeholder="Student"
            @keyup.native="autoCompleteStudent($event.target.value, 'student')"
            @click.native="autoCompleteStudent('', 'student')"
            @input="(val) => $emit('update:student', val)"
          >
            <span slot="no-options">
              <div
                v-if="isTypingStudent"
                class="text-center d-flex align-items-center justify-content-center"
              >
                <b-spinner
                  variant="primary"
                  label="Text Centered"
                />
              </div>
              <div
                v-if="!isTypingStudent && !students.length"
                class="text-center d-flex align-items-center justify-content-center"
              >
                No matching records found
              </div>
            </span>
            <template #option="{ name, email }">
              <span class="text-primary">{{ name }}</span>
              <small class="d-block text-secondary">{{ email }}</small>
            </template>
          </v-select>
        </b-form-group>
        <b-form-group
          :label="('Course')"
          label-for="course"
        >
          <v-select
            :value="course"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="courses"
            placeholder="Course"
            @keyup.native="autoCompleteCourse($event.target.value)"
            @click.native="autoCompleteCourse('', 'student')"
            @input="(val) => $emit('update:course', val)"
          >
            <span slot="no-options">
              <div
                v-if="isTypingCourse"
                class="text-center d-flex align-items-center justify-content-center"
              >
                <b-spinner
                  variant="primary"
                  label="Text Centered"
                />
                <div
                  v-if="!isTypingCourse && !courses.length"
                  class="text-center d-flex align-items-center justify-content-center"
                >
                  No matching records found
                </div>
              </div>
            </span>
            <template #selected-option="{ code, group }">
              <div class="d-flex align-items-center justify-content-between">
                <span class="text-primary">{{ code }}</span>
                <small v-if="group" class="text-secondary ml-25">({{ group }})</small>
              </div>
            </template>
            <template #option="{ code, group, date_start }">
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <span>{{ code }}</span>
                  <small v-if="group" class="d-block text-muted">({{ group }})</small>
                </div>
                <small class="d-block text-secondary">{{ date_start }}</small>
              </div>
            </template>
          </v-select>
        </b-form-group>
        <!-- <b-form-group
          :label="('Date range')"
          label-for="date-range"
        >
          {{ date_range }}
          <flat-pickr
            :value="date_range"
            class="form-control"
            name="date"
            :placeholder="$t('Date range')"
            :config="{
              altFormat: 'd-m-Y',
              mode: 'range',
              altInput: true,
              locale: { firstDayOfWeek: 1 },
              onClose: dates => emitDates(dates),
            }"
          />
        </b-form-group> -->
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              :label="('From')"
              label-for="date-start"
            >
              <flat-pickr
                :value="dateStart"
                class="form-control"
                name="date"
                placeholder="From"
                :config="{
                  altFormat: 'd-m-Y',
                  altInput: true,
                  locale: { firstDayOfWeek: 1 },
                }"
                @input="val => $emit('update:dateStart', val)"
              />
            </b-form-group>

          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="To"
              label-for="date-end"
            >
              <flat-pickr
                :value="dateEnd"
                class="form-control"
                name="date"
                placeholder="To"
                :config="{
                  altFormat: 'd-m-Y',
                  altInput: true,
                  minDate: dateStart,
                  locale: { firstDayOfWeek: 1 },
                }"
                @input="val => $emit('update:dateEnd', val)"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Who pays? -->
        <b-form-group label="Registration status" label-for="registration-status">
          <v-select
            :value="regDefaultStatus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="regStatusOptions"
            :clearable="false"
            @input="(val) => $emit('update:regDefaultStatus', val)"
          />
        </b-form-group>
        <b-form-group :label="$t('Who pays')" label-for="who-pays">
          <v-select
            :value="who_pays"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="payerOptions"
            label="name"
            placeholder="Who pays"
            :clearable="false"
            @input="(val) => $emit('update:who_pays', val)"
          />
        </b-form-group>
        <b-form-group :label="('Payment status')" label-for="payment-status">
          <v-select
            :value="defaultStatus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusOptions"
            label="name"
            :clearable="false"
            placeholder="Status"
            @input="(val) => $emit('update:defaultStatus', val)"
          />
        </b-form-group>
        <b-form-group :label="('Payment method')" label-for="payment-method">
          <v-select
            :value="paymentFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="methodOptions"
            label="name"
            :clearable="false"
            placeholder="Status"
            @input="(val) => $emit('update:paymentFilter', val)"
          />
        </b-form-group>
      </div>
      <b-button
        variant="primary"
        class="d-block ml-auto mr-2"
        @click="$emit('export-registration')"
      >
        <div class="d-flex">
          <b-spinner v-if="isLoadingExcelDoc" class="mr-25" small label="Loading..." />
          <span v-if="!isLoadingExcelDoc">Export </span>
        </div>
      </b-button>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BFormGroup, BSpinner, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from '@axios'
import countries from '@/@fake-db/data/other/countries'
/* eslint-disable camelcase */
export default {
  components: {
    BSidebar,
    BFormGroup,
    BSpinner,
    BButton,
    BRow,
    BCol,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isFilterActive',
    event: 'update:isFilterActive',
  },
  props: {
    isLoadingExcelDoc: {
      type: Boolean,
      default: false,
    },
    isFilterActive: {
      type: Boolean,
      required: true,
    },
    student: {
      type: [Object, null],
      default: null,
    },
    course: {
      type: [Object, null],
      default: null,
    },
    statusOptions: {
      type: [Array, null],
      default: null,
    },
    defaultStatus: {
      type: [Object, null],
      default: null,
    },
    methodOptions: {
      type: [Array, null],
      default: null,
    },
    paymentFilter: {
      type: [Object, null],
      default: null,
    },
    payer_confirmed: {
      type: [Boolean, null],
      default: null,
    },
    visa: {
      type: [Boolean, null],
      default: null,
    },
    who_pays: {
      type: [Object, null],
      default: null,
    },
    payerOptions: {
      type: [Array, null],
      default: null,
    },
    regDefaultStatus: {
      type: [Object, null],
      default: null,
    },
    regStatusOptions: {
      type: [Array, null],
      default: null,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    dateStart: {
      type: String,
      default: null,
    },
    dateEnd: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      query1: '',
      query2: '',
      students: [],
      courses: [],
      isTypingStudent: false,
      isTypingCourse: false,
      visaOptions: [{ label: 'Visa', value: true }, { label: 'No Visa', value: false }],
      whoPays: [{ label: 'All payers', value: null }, { label: 'Student', value: 'student' }, { label: 'Private person', value: 'payer' }, { label: 'Company', value: 'company' }],
      confirmedOptions: [{ label: 'Payer confirmed', value: true }, { label: 'Payer not confirmed', value: false }],
      // statusOptions: [
      //   { label: 'All', value: '' },
      //   { label: 'Confirmed', value: 'confirmed' },
      //   { label: 'Canceled', value: 'canceled' },
      //   { label: 'Pending', value: 'pending' },
      //   { label: 'Deleted', value: 'deleted' },
      // ],
    }
  },
  methods: {
    autoCompleteStudent(search, role_name) {
      this.isTypingStudent = true
      axios.post('/autocomplete/user', { search, role_name })
      .then(res => {
        this.isTypingStudent = false
        this.students = res.data
      })
    },

    autoCompleteCourse(search) {
      this.isTypingCourse = true
      axios.post('/autocomplete/course', { search })
      .then(res => {
        this.isTypingCourse = false
        this.courses = res.data
      })
    },
  },
  setup() {
    const selectedStudent = ref({})
    const selectedCourse = ref({})
    const selectedWhoPays = ref({})
    const selectedConfirmed = ref({})
    const selectedStatus = ref({})

    return {
      selectedStudent,
      selectedCourse,
      selectedWhoPays,
      selectedConfirmed,
      selectedStatus,

      // statusOptions,
      // defaultStatus,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
