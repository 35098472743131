<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and legend -->
      <b-card-title class="mb-50">
        {{ $t('Courses attendance') }}
      </b-card-title>
      <!--/ title and legend -->

      <!-- datepicker -->
    <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="bar"
        height="400"
        :options="columnChart.chartOptions"
        :series="columnChart.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
// import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    // flatPickr,
    BCardTitle,
  },
  props: {
    columnChart: {
      type: Object,
      required: true,
    },
    initialRangePicker: {
      type: Array,
      required: true,
    },
  },
}
</script>
