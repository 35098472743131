import { ref, watch, computed } from '@vue/composition-api'
// import { title } from '@core/utils/filter'
// Notification

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { useUtils as useI18nUtils } from '@core/libs/i18n'
import store from '@/store'
import router from '@/router'

export default function useCoursesList() {
  // Use toast
  const toast = useToast()
  // const { t } = useI18nUtils()

  const refclaimTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: t('id'), sortable: true },
    { key: 'student_name', sortable: true },
    { key: 'lesson' },
    { key: 'course', sortable: true },
    // { key: 'group' },
    { key: 'claim_date', label: 'date', sortable: true },
    { key: 'teacher' },
    { key: 'comment' },
    { key: 'notes' },
    { key: 'attendance' },
    { key: 'actions', tdClass: 'table-actions' },
  ]
  const perPage = ref(10)
  const totalElements = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const filters = ref([
    { label: 'Pending claims', value: 'pending' },
    { label: 'Approved claims', value: 'approved' },
    { label: 'Rejected claims', value: 'rejected' },
  ])
  const selectedFilter = ref('pending')
  const dataMeta = computed(() => {
    const localItemsCount = refclaimTable.value ? refclaimTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalElements.value,
    }
  })
  /* eslint-disable camelcase */

  const refetchData = () => {
    refclaimTable.value.refresh()
  }
  const urlParams = new URLSearchParams(window.location.search)
  const urlQueries = router.currentRoute.query
  if (urlQueries.search) searchQuery.value = urlQueries.search
  if (urlQueries.page) currentPage.value = urlQueries.page
  if (urlQueries.perPage) perPage.value = urlQueries.perPage
  if (urlQueries.filter) selectedFilter.value = urlQueries.filter
  watch([currentPage, perPage, selectedFilter, searchQuery], () => {
    urlParams.set('search', searchQuery.value ? searchQuery.value : '')
    urlParams.set('page', currentPage.value ? currentPage.value : '')
    urlParams.set('perPage', perPage.value ? perPage.value : '')
    urlParams.set('filter', selectedFilter.value ? selectedFilter.value : '')
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams.toString()}`)
    refetchData()
  })
  const isBusy = ref(false)
  const isChatActive = ref(false)
  const chatModelId = ref(null)
  const handleChatClick = data => {
    isChatActive.value = true
    chatModelId.value = data
  }

  const fetchElements = (ctx, callback) => {
    const payload = {
      search: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      filter: selectedFilter.value,
    }
    store
      .dispatch('claim/fetchClaims', payload)
      .then(res => {
        const users = res.data.rows
        const total = res.data.totalRecords
        callback(users)
        totalElements.value = total
      })
      .catch(() => {
        isBusy.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching claim list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const approveClaim = attendance_id => {
    store.dispatch('claim/approveClaim', { attendance_id })
      .then(res => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
      .catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const rejectClaim = attendance_id => {
    store.dispatch('claim/rejectClaim', { attendance_id })
      .then(res => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
      .catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    selectedFilter,
    filters,
    approveClaim,
    rejectClaim,
    fetchElements,
    tableColumns,
    perPage,
    currentPage,
    totalElements,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refclaimTable,
    refetchData,
    handleChatClick,
    isChatActive,
    chatModelId,
  }
}
