import { ref, watch, computed } from '@vue/composition-api'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import store from '@/store'
import router from '@/router'

export default function useCoursesList() {
  // Use toast
  const toast = useToast()
  const { t } = useI18nUtils()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: t('id'), sortable: true },
    { key: 'course', label: t('Course name'), sortable: true },
    { key: 'course_group', label: t('group code'), sortable: true },
    { key: 'session_number', label: 'lesson number' },
    { key: 'date', label: t('date & time'), sortable: true },
    { key: 'classroom', label: t('classroom') },
    { key: 'actions', label: t('actions'), tdClass: 'table-actions' },
  ]
  const tableColumns1 = [
    // { key: 'id', label: t('id'), sortable: true },
    { key: 'course', label: t('Course name'), sortable: true },
    { key: 'course_group', label: t('group code'), sortable: true },
    { key: 'session_number', label: 'lesson number' },
    { key: 'date', label: t('date & time'), sortable: true },
    { key: 'classroom', label: t('classroom') },
    { key: 'by', label: t('by'), tdClass: 'table-actions' },
    { key: 'reason', label: t('reason'), tdClass: 'table-actions' },
  ]
  const tableColumns2 = [
    // { key: 'id', label: t('id'), sortable: true },
    { key: 'course', label: t('Course name'), sortable: true },
    { key: 'course_group', label: t('group code'), sortable: true },
    { key: 'session_number', label: t('lesson number') },
    { key: 'date', label: t('date & time'), sortable: true },
    { key: 'classroom', label: t('classroom'), sortable: true },
  ]
  const tableColumns3 = [
    // { key: 'id', label: t('id'), sortable: true },
    { key: 'course', label: t('Course name'), sortable: true },
    { key: 'course_group', label: t('group code'), sortable: true },
    { key: 'session_number', label: t('lesson number') },
    { key: 'date', label: t('date & time'), sortable: true },
    { key: 'by', label: t('by'), tdClass: 'table-actions' },
    { key: 'classroom', label: t('classroom') },
    { key: 'actions', label: t('actions'), tdClass: 'table-actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('date')
  const isSortDirDesc = ref(true)
  const userData = ref(JSON.parse(localStorage.userData))
  /* eslint-disable-next-line */
  const role_name = ref(window.location.href.split('/')[3])
  const defaultStatus = ref('awaitingApproval')
  const statusOptions = ref([
    { label: 'Substitutions needing teacher', value: 'substitution_requests' },
    { label: 'Awaiting my approval', value: 'awaitingApproval' },
    { label: 'Awaiting admin approval', value: 'requested' },
    // { label: 'My sent substitutions', value: 'sent_substitutions' },
    { label: 'Approved', value: 'approved' },
    { label: 'Declined', value: 'declined' },
    { label: 'Expired', value: 'expired' },
  ])
  if (router.currentRoute.params.value) {
    defaultStatus.value = router.currentRoute.params
  }

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }
  /* eslint-disable camelcase */
  const apply = (session_id, action) => {
    if (action === 'request') {
      store.dispatch('courseSubstitute/applyForSubstitution', session_id)
      .then(res => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        }).catch(err => {
          toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
      })
    }
    if (action === 'cancel') {
      store.dispatch('courseSubstitute/cancelRequest', session_id)
      .then(res => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        }).catch(err => {
          toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
      })
    }
  }
  const approve = teacher_substitute_id => {
    store.dispatch('courseSubstitute/approveRequest', teacher_substitute_id)
    .then(res => {
      refetchData()
      toast({
        component: ToastificationContent,
        props: {
          title: res.data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
    })
  }
  const decline = teacher_substitute_id => {
    store.dispatch('courseSubstitute/declineRequest', teacher_substitute_id)
    .then(res => {
      refetchData()
      toast({
        component: ToastificationContent,
        props: {
          title: res.data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
    })
  }
  const urlParams = new URLSearchParams(window.location.search)
  const urlQueries = router.currentRoute.query
  if (urlQueries.search) searchQuery.value = urlQueries.search
  if (urlQueries.page) currentPage.value = urlQueries.page
  if (urlQueries.perPage) perPage.value = urlQueries.perPage
  if (urlQueries.filter) defaultStatus.value = urlQueries.filter
  /* eslint-disable-next-line */
  watch([currentPage, perPage, defaultStatus, searchQuery],  () => {
    urlParams.set('search', searchQuery.value ? searchQuery.value : '')
    urlParams.set('page', currentPage.value ? currentPage.value : '')
    urlParams.set('perPage', perPage.value ? perPage.value : '')
    urlParams.set('filter', defaultStatus.value ? defaultStatus.value : '')
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams.toString()}`)
    refetchData()
  })
  const isBusy = ref(false)

  const fetchUsers = (ctx, callback) => {
    const payload = {
      search: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      teacher_id: userData.value.id,
      filter: defaultStatus.value,
      // role_name: role_name.value,
    }
    store
      .dispatch('courseSubstitute/fetchCourses', payload)
      .then(res => {
        const users = res.data.rows
        const total = res.data.totalRecords
        callback(users)
        totalUsers.value = total
      })
      .catch(() => {
        isBusy.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching courses list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }
  const resolveAttendanceVariant = attendance => {
    if (attendance > 0) return 'flat-warning'
    if (attendance > 80) return 'flat-success'
    return 'flat-secondary'
  }

  return {
    isBusy,
    fetchUsers,
    tableColumns,
    tableColumns1,
    tableColumns2,
    tableColumns3,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    role_name,
    userData,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveAttendanceVariant,
    refetchData,
    apply,
    approve,
    decline,
    defaultStatus,
    statusOptions,
  }
}
