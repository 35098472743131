<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <b-row class="m-2">
        <b-col
          cols="12"
          lg="6"
        >
          <v-select
            v-model="selectedFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterOptions"
            :reduce="x => x.value"
            lebel="label"
            :clearable="false"
            class="courses-filter d-inline-block mx-50"
            style="min-width: 180px"
          />
        </b-col>
        <b-col
          v-if="!teacherView"
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('Search...')"
              debounce="500"
            />
            <b-button
              v-b-modal.modal2
              variant="primary"
            >
              <span class="text-nowrap">{{ $t('Add Event') }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <!-- Add new event modal -->
      <b-modal
        id="modal2"
        :title="$t('Add event')"
        ok-title="Accept"
        size="lg"
        centered
        no-close-on-backdrop
        hide-footer
      >
        <validation-observer
          #default="{ handleSubmit }"
          ref="form1"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(addEvent((teacherId ? teacherId : selectedTeacher.id), name, description, date_start, date_end))"
            @reset.prevent="resetForm"
          >
            <!-- @submit.prevent="handleSubmit($emit('add-event', (teacherId ? teacherId : selectedTeacher.id), name, date_start, date_end))" -->
            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
            >
              <b-form-group
                :label="$t('Name')"
                label-for="name"
              >
                <b-form-input
                  v-model="name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  :placeholder="$t('Event name')"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="Description"
              rules="required"
            >
              <b-form-group
                :label="$t('Description')"
                label-for="Description"
              >
                <b-form-textarea
                  v-model="description"
                  placeholder="Description"
                  :state="getValidationState(validationContext)"
                  rows="3"
                  maxlength="500"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="Start date"
              rules="required"
            >
              <b-form-group
                :label="$t('Start date')"
                label-for="date_start"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="date_start"
                  class="form-control"
                  :placeholder="$t('Pick a date')"
                  :config="{ enableTime: true, altFormat: 'd-m-Y H:i:ss', altInput: true, locale: { firstDayOfWeek: 1 }}"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="End date"
              rules="required"
            >
              <b-form-group
                :label="('End date')"
                label-for="date_end"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="date_end"
                  class="form-control"
                  :placeholder="$t('Pick a date')"
                  :config="{ enableTime: true, altFormat: 'd-m-Y H:i:ss', altInput: true, locale: { firstDayOfWeek: 1 }}"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="Teacher"
              rules="required"
            >
              <b-form-group
                v-if="!teacherId"
                :label="('Teacher')"
                label-for="teacher"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="selectedTeacher"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="teachers"
                >
                  <!-- @click.native="autoComplete($event.target.value, 'teacher')" -->
                  <!-- @keyup.native="autoComplete($event.target.value, 'teacher')" -->
                  <span slot="no-options">
                    <div
                      v-if="isTyping"
                      class="text-center d-flex align-items-center justify-content-center"
                    >
                      <b-spinner
                        variant="primary"
                        label="Text Centered"
                      />
                    </div>
                    <div
                      v-if="!isTyping && !teachers.length"
                      class="text-center d-flex align-items-center justify-content-center"
                    >
                      No matching records found
                    </div>
                  </span>
                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('Add') }}
            </b-button>
          </b-form>
        </validation-observer>
      </b-modal>
      <!-- Events table -->
      <b-table
        ref="refTeacherEventsTable"
        responsive
        primary-key="id"
        :items="fetchEvents"
        :fields="(teacherId || teacherView) ? tableColumns.filter(el => el.key !== 'teacher' && el.key !== 'notes') : tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        show-empty
        class="position-relative mb-50"
        empty-text="No matching records found"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">{{ $t('Loading...') }}</strong>
          </div>
        </template>
        <template #cell(name)="data">
          <span
            v-b-tooltip.hover.top="data.item.name"
            class="d-inline-block text-truncate"
            style="max-width: 180px"
          >
            {{ data.item.name }}
          </span>
        </template>
        <template #cell(teacher)="data">
          <b-avatar
            v-b-tooltip.hover="`${data.item.teacher.name.split(' ')[0]} ${data.item.teacher.name.split(' ')[1] ? data.item.teacher.name.split(' ')[1].charAt(0).toUpperCase() : ''}.`"
            class="pull-up pending-teachers"
            button
            :title="data.item.teacher.name"
            :src="data.item.teacher.avatar.split('/')[5] === 'default-avatar.jpg' ? null : data.item.teacher.avatar"
            :text="avatarText(data.item.teacher.name)"
          />
        </template>
        <template #cell(date_start)="data">
          <span v-if="data.item.date_start" class="text-nowrap">
            {{ data.item.date_start.split(' ')[0] }}
          </span>
          <small v-if="data.item.date_start" class="text-secondary d-block">{{ data.item.date_start ? data.item.date_start.split(' ')[1].split(':')[0]+':'+data.item.date_start.split(' ')[1].split(':')[1] : '' }}</small>
        </template>
        <template #cell(date_end)="data">
          <span v-if="data.item.date_end" class="text-nowrap">
            {{ data.item.date_end.split(' ')[0] }}
          </span>
          <small v-if="data.item.date_end" class="text-secondary d-block">{{ data.item.date_end ? data.item.date_end.split(' ')[1].split(':')[0]+':'+data.item.date_end.split(' ')[1].split(':')[1] : '' }}</small>
        </template>
        <template #cell(description)="data">
          <div>
            <p
              v-b-popover.hover.top="data.item.description"
              title="Description"
              class="text-truncate"
              :style="{'max-width':  teacherId ? '700px' : '300px'}"
            >
              {{ data.item.description }}
            </p>
          </div>
        </template>
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>
        <template #cell(notes)="data">
          <b-button
            :id="`popover-target-${data.item.id}`"
            variant="flat-primary"
            size="sm"
            class="btn-icon rounded-circle"
            @click="handleChatClick({model:'registration', id: data.item.id})"
          >
            <feather-icon
              icon="ClipboardIcon"
              class="align-middle text-body"
              badge-classes="bg-danger"
              :badge="data.item.comments_number > 0 ? data.item.comments_number : ''"
              size="16"
            />
          </b-button>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            v-if="!teacherView || (teacherView && data.item.status === 'Pending')"
            dropleft
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="!teacherView && data.item.status === 'Pending'"
              @click="approveEvent(data.item.id)"
            >
              <feather-icon icon="CheckCircleIcon" />
              <span class="align-middle ml-50">Accept</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!teacherView && data.item.status === 'Pending'"
              v-b-modal="getModal3Id(data.item.id)"
            >
              <feather-icon icon="XCircleIcon" />
              <span class="align-middle ml-50">{{ 'Reject' }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="teacherView && data.item.status === 'Pending'"
              v-b-modal="getModal1Id(data.item.id)"
              @click="selectedDateStart = formatDateTime(data.item.date_start); selectedDateEnd = formatDateTime(data.item.date_end)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('Edit') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!teacherView || (data.item.status === 'Pending' && teacherView)"
              v-b-modal="getModal2Id(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('Delete') }}</span>
            </b-dropdown-item>
            <!-- Edit modal -->
            <b-modal
              :id="'modal1'+data.item.id"
              :title="$t('Edit event')"
              ok-title="Accept"
              size="lg"
              centered
              no-close-on-backdrop
              hide-footer
            >
              <validation-observer
                #default="{ handleSubmit }"
                ref="form2"
              >
                <!-- Form -->
                <b-form
                  class="p-2"
                  @submit.prevent="handleSubmit(updateEvent(data.item.id, data.item.teacher.id, data.item.name, data.item.description, selectedDateStart, selectedDateEnd))"
                  @reset.prevent="resetForm"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Name"
                    rules="required"
                  >
                    <!-- Name -->
                    <b-form-group
                      :label="$t('Name')"
                      label-for="name"
                    >
                      <b-form-input
                        v-model="data.item.name"
                        autofocus
                        :state="getValidationState(validationContext)"
                        :placeholder="$t('Name')"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="validationContext"
                    name="Description"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Description')"
                      label-for="Description"
                    >
                      <b-form-textarea
                        v-model="data.item.description"
                        placeholder="Description"
                        :state="getValidationState(validationContext)"
                        rows="3"
                        maxlength="500"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="validationContext"
                    name="Start date"
                    rules="required"
                  >
                    <b-form-group
                      label="Start date"
                      label-for="start-date"
                      :state="getValidationState(validationContext)"
                    >
                      <flat-pickr
                        v-model="selectedDateStart"
                        class="form-control"
                        :placeholder="$t('Pick a date')"
                        :config="{ enableTime: true, altFormat: 'd-m-Y H:i:ss', altInput: true, locale: { firstDayOfWeek: 1 }}"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="validationContext"
                    name="End date"
                    rules="required"
                  >
                    <b-form-group
                      label="End date"
                      label-for="end-date"
                      :state="getValidationState(validationContext)"
                    >
                      <flat-pickr
                        v-model="selectedDateEnd"
                        class="form-control"
                        :placeholder="$t('Pick a date')"
                        :config="{ enableTime: true, altFormat: 'd-m-Y H:i:ss', altInput: true, locale: { firstDayOfWeek: 1 }}"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    v-if="!teacherId && !teacherView"
                    #default="validationContext"
                    name="Teacher"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Teacher')"
                      label-for="teacher"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="data.item.teacher"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="teachers"
                      >
                        <!-- @keyup.native="autoComplete($event.target.value, 'teacher')" -->
                        <!-- @click.native="autoComplete($event.target.value, 'teacher')" -->
                        <span slot="no-options">
                          <div
                            v-if="isTyping"
                            class="text-center d-flex align-items-center justify-content-center"
                          >
                            <b-spinner
                              variant="primary"
                              label="Text Centered"
                            />
                          </div>
                          <div
                            v-if="!isTyping && !teachers.length"
                            class="text-center d-flex align-items-center justify-content-center"
                          >
                            No matching records found
                          </div>
                        </span>
                      </v-select>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                  >
                    {{ $t('Update') }}
                  </b-button>
                </b-form>
              </validation-observer>
            </b-modal>
            <!-- Delete modal -->
            <b-modal
              :id="'modal2'+data.item.id"
              :title="$t('Delete Event')"
              ok-title="Yes"
              cancel-title="No"
              centered
              @ok="deleteEvent(data.item.id)"
            >
              <h5>{{ $t('Are you sure you want to delete') }} </h5><strong>{{ data.item.name }}</strong>
            </b-modal>
            <!-- Reject modal -->
            <b-modal
              :id="'modal3'+data.item.id"
              :title="'Confirm rejection'"
              ok-title="Yes"
              cancel-title="No"
              centered
              @ok="rejectEvent(data.item.id)"
            >
              <h5>{{ $t('Are you sure you want to reject') }} <strong>{{ data.item.teacher ? data.item.teacher.name : 'this teacher' }}</strong>'s timeoff request ?</h5>
            </b-modal>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="3"
            lg="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <b-col
            cols="3"
            lg="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="6"
            lg="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="!isBusy"
              v-model="currentPage"
              :total-rows="totalElements"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
          <div
            v-if="isChatActive"
            class="notesModule shadow-lg"
          >
            <div
              style="background: #fff"
              class="pb-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="light"
                size="sm"
                class="btn-icon shadow-sm ml-auto "
                style="display: block; transform: translate(8px, -2px);"
                @click="isChatActive = false"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>
            <chat-module
              :key="chatModelId.id"
              :model-id="chatModelId.id"
              model-type="teacherEvent"
              @decrease-notes="refetchData"
              @notes-increament="refetchData"
            />
          </div>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable-next-line */
import {
  BTable, BButton, BBadge, BDropdown, BDropdownItem, BModal, VBModal,
  BFormGroup, BFormInput, BSpinner, VBTooltip, BFormInvalidFeedback, BForm,
  BFormTextarea, BPagination, BRow, BCol, VBPopover, BAvatar, BCard,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import ChatModule from '@/views/admin/ChatModule.vue'
import useEventsList from './useEventsList'

export default {
  components: {
    ChatModule,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BForm,
    BTable,
    BPagination,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    BModal,
    BFormGroup,
    BFormInput,
    vSelect,
    BSpinner,
    BRow,
    BCol,
    BFormTextarea,
    BAvatar,
    BCard,

    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    teacherId: {
      default: null,
      type: Number,
    },
    teacherView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      avatarText,
      selectedDateStart: null,
      selectedDateEnd: null,
      required,
      query: '',
      // teachers: [],
      isTyping: false,
    }
  },
  methods: {
    // addEvent(teacherId, name, description, dateStart, dateEnd) {
    //   if (this.$refs.form1.flags.valid) {
    //     this.$emit('add-event', teacherId, name, description, dateStart, dateEnd)
    //   }
    // },
    // updateEvent(id, teacherId, name, dateStart, dateEnd) {
    //   if (this.$refs.form2.flags.valid) {
    //     this.$emit('update-event', id, teacherId, name, dateStart, dateEnd)
    //   }
    // },
    // approveEvent(id) { this.$emit('approve-event', id) },
    /* eslint-disable no-unused-expressions, camelcase */
    getModal1Id(id) { return `modal1${id}` },
    getModal2Id(id) { return `modal2${id}` },
    getModal3Id(id) { return `modal3${id}` },
    // autoComplete(search, role_name) {
    //   this.isTyping = true
    //   this.$http.post('/autocomplete/user', { search, role_name })
    //   .then(res => {
    //     this.isTyping = false
    //     this.teachers = res.data
    //   })
    // },
  },
  setup(props) {
    const {
      isBusy,
      fetchEvents,
      tableColumns,
      perPage,
      currentPage,
      totalElements,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTeacherEventsTable,
      teacherID,
      selectedFilter,

      resolveUserStatusVariant,
      refetchData,
      resolveTeacherAvailability,

    } = useEventsList()

    const isChatActive = ref(false)
    const chatModelId = ref(null)
    const handleChatClick = data => {
      isChatActive.value = true
      chatModelId.value = data
    }

    const teachers = ref([])
    const fetchTeachers = () => {
      store.dispatch('coursesStoreModule/fetchTeachersInit')
        .then(res => {
          teachers.value = res.data.rows
          // teachers.value.unshift({ name: 'All teachers' })
        })
    }
    fetchTeachers()
    const toast = useToast()
    if (props.teacherId) { teacherID.value = props.teacherId }
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(() => {})
    /* eslint-disable object-curly-newline, no-shadow */
      const name = ref('')
      const description = ref('')
      const date_start = ref('')
      const date_end = ref('')
      const selectedTeacher = ref({})
      const resetForm = () => {
        name.value = ''
        description.value = ''
        date_start.value = ''
        date_end.value = ''
        selectedTeacher.value = {}
      }
    const addEvent = function (teacher_id, name, description, date_start, date_end) {
      this.$refs.form1.validate().then(success => {
        if (success) {
          store.dispatch('teacherStoreModule/addEvent', { teacher_id, name, description, date_start, date_end })
            .then(res => {
              resetForm()
              this.$bvModal.hide('modal2')
              toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'success',
                },
              })
              refetchData()
          }).catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error while adding Event',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            })
        }
      })
    }
    const updateEvent = function (id, teacher_id, name, description, date_start, date_end) {
      this.$refs.form2.validate().then(success => {
        if (success) {
          store.dispatch('teacherStoreModule/updateEvent', { id, teacher_id, name, description, date_start, date_end })
            .then(() => {
              this.$bvModal.hide(`modal1${id}`)
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Event Has Been updated',
                  icon: 'AlertTriangleIcon',
                  variant: 'success',
                },
              })
              refetchData()
          }).catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error while updating Event',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            })
        }
      })
    }
    const approveEvent = id => {
      store.dispatch('teacherStoreModule/approveEvent', id)
        .then(res => {
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while approving Event',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const rejectEvent = id => {
      store.dispatch('teacherStoreModule/rejectEvent', id)
        .then(res => {
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while rejecting Event',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const deleteEvent = id => {
      store.dispatch('teacherStoreModule/deleteEvent', id)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Event Has Been removed',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while deleting Event',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const formatDateTime = dateTime => {
      if (typeof dateTime !== 'undefined') {
        const timestamp = dateTime.split(' ')
        const fullTime = (typeof timestamp[1] !== 'undefined') ? timestamp[1] : ''
        const fullDate = (typeof timestamp[0] !== 'undefined') ? timestamp[0] : ''
        let dateArr
        let dateStr = null
        if (fullDate !== '') {
          dateArr = fullDate.split('-')
          dateStr = `${dateArr[2]}-${dateArr[1]}-${dateArr[0]} ${fullTime}`
        }
        return `${dateStr}`
      }
      return ''
    }
    const filterOptions = [
      { label: 'Accepted requests', value: 'Active' },
      { label: 'Rejected requests', value: 'Rejected' },
      { label: 'Pending requests', value: 'Pending' },
      { label: 'Expired requests', value: 'Expired' },
    ]
    return {
      isBusy,
      teachers,
      chatModelId,
      isChatActive,
      filterOptions,
      formatDateTime,
      refFormObserver,
      handleChatClick,
      getValidationState,

      name,
      description,
      date_start,
      date_end,
      selectedTeacher,

      fetchEvents,
      tableColumns,
      perPage,
      currentPage,
      totalElements,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTeacherEventsTable,

      resolveUserStatusVariant,
      refetchData,
      resolveTeacherAvailability,

      // Actions
      addEvent,
      updateEvent,
      teacherID,
      deleteEvent,
      rejectEvent,
      approveEvent,
      selectedFilter,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>
