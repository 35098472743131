import { $themeColors } from '@themeConfig'

export default {
  salesLine: {
    series: [
      {
        name: 'Study time',
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        toolbar: { show: false },
        zoom: { enabled: false },
        type: 'line',
        dropShadow: {
          enabled: true,
          top: 18,
          left: 2,
          blur: 5,
          opacity: 0.2,
        },
        offsetX: -10,
      },
      stroke: {
        curve: 'smooth',
        width: 4,
      },
      grid: {
        borderColor: '#ebe9f1',
        padding: {
          top: -20,
          bottom: 5,
          left: 20,
        },
      },
      legend: {
        show: false,
      },
      colors: ['#df87f2'],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          inverseColors: false,
          gradientToColors: [$themeColors.primary],
          shadeIntensity: 1,
          type: 'horizontal',
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100],
        },
      },
      markers: {
        size: 0,
        hover: {
          size: 5,
        },
      },
      xaxis: {
        labels: {
          offsetY: 5,
          style: {
            colors: '#b9b9c3',
            fontSize: '0.857rem',
          },
        },
        axisTicks: {
          show: false,
        },
        categories: [],
        axisBorder: {
          show: false,
        },
        tickPlacement: 'on',
      },
      yaxis: {
        tickAmount: 5,
        labels: {
          style: {
            colors: '#b9b9c3',
            fontSize: '0.857rem',
          },
          formatter(val) {
            return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
          },
        },
      },
      tooltip: {
        x: { show: false },
      },
    },
  },
  supportTrackerRadialBar: {
    series: [],
    chartOptions: {
      plotOptions: {
        radialBar: {
          size: 150,
          offsetY: 20,
          startAngle: -150,
          endAngle: 150,
          hollow: {
            size: '65%',
          },
          track: {
            background: '#fff',
            strokeWidth: '100%',
          },
          dataLabels: {
            name: {
              offsetY: -5,
              color: '#5e5873',
              fontSize: '1rem',
            },
            value: {
              offsetY: 15,
              color: '#5e5873',
              fontSize: '1.714rem',
            },
          },
        },
      },
      colors: [$themeColors.danger],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: [$themeColors.primary],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        dashArray: 8,
      },
      labels: ['Completed Hours'],
    },
  },
}
