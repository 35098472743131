<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('Error fetching user data') }}
      </h4>
      <div class="alert-body">
        {{ $t('No user found with this user id. Check') }}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          {{ $t('User List') }}
        </b-link>
        {{ $t('for other users.') }}
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
        >
          <user-view-user-info-card
            :user-data="userData"
            @refresh-course-list="refreshKey++"
          />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <!-- <user-view-user-plan-card /> -->
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <!-- <user-view-user-timeline-card />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <user-view-user-permissions-card /> -->
        </b-col>
      </b-row>

      <!-- <invoice-list /> -->
    </template>
    <b-card v-if="studentId">
      <b-tabs
        justified
      >
        <b-tab
          title="Courses"
          @click="tab = 'course'"
        >
          <courses-list
            v-if="tab === 'course'"
            :refreshKey="refreshKey"
            :studentID="studentId"
            class="mb-2"
          />
        </b-tab>
        <b-tab
          title="Registrations"
          @click="tab = 'register'"
        >
          <registrations
            v-if="tab === 'register'"
            :refreshKey="refreshKey"
            :student-id="studentId"
            class="mb-2"
          />
        </b-tab>
        <b-tab
          title="Payments"
          @click="tab = 'payment'"
        >
          <invoices
            v-if="tab === 'payment'"
            :student-id="studentId"
            :refreshKey="refreshKey"
            class="mb-2"
          />
        </b-tab>
        <!-- <b-tab v-if="studentId" title="Resignations">
          <resignations-list
            :student-id="studentId"
            class="mb-2"
          />
        </b-tab> -->
      </b-tabs>
    </b-card>
    <b-card v-if="teacherId">
      <b-tabs
        justified
        class="mb-2"
      >
        <b-tab
          title="Courses"
          @click="tab = 'course'"
        >
          <teacher-courses
            v-if="tab === 'course'"
            :teacher-id="teacherId"
          />
        </b-tab>
        <b-tab
          title="Substitutes"
          @click="tab = 'substitute'"
        >
          <teacher-substitute
            v-if="tab === 'substitute'"
            :teacher-id="teacherId"
          />
        </b-tab>
        <b-tab
          title="Days off"
          @click="tab = 'dayOff'"
        >
          <teacher-events
            v-if="tab === 'dayOff'"
            :teacher-id="teacherId"
          />
        </b-tab>
      </b-tabs>
    </b-card>
    <b-row>
      <b-col :cols="studentId ? '6' : '12'">
        <chat-module
          v-if="conversation.id"
          :conversation="conversation"
          :messages="messages"
          :student="true"
        />
      </b-col>
      <b-col cols="6">
        <student-timeline
          v-if="studentId"
          :student-id="studentId"
          :refreshKey="refreshKey"
        />
      </b-col>
    </b-row>
    <teacher-statistics
      v-if="teacherId"
      :teacher-id="teacherId"
    />
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BTabs, BTab, BCard,
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import TeacherStatistics from '@/views/admin/statistics/teachers-statistics/Statistics.vue'
import TeacherCourses from '@/views/teacher/courses-list/CoursesList.vue'
import TeacherSubstitute from '@/views/teacher/substitutes/CoursesList.vue'
import TeacherEvents from '@/views/admin/teachers-list/TeacherEvents.vue'
import StudentTimeline from '@/views/student/dashboard/DahboardCourseTimeline.vue'
import Registrations from '@/views/courses/registrations/registrations-list/RegistrationsList.vue'
import Invoices from '@/views/courses/invoices/InvoicesList.vue'
// import ResignationsList from '@/views/admin/resignations-list/ResignationsList.vue'
import ChatModule from '@/views/admin/ChatModule.vue'
import CoursesList from '@/views/student/courses-list/CoursesList.vue'
import userStoreModule from '../userStoreModule'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
// import UserViewUserPlanCard from './UserViewUserPlanCard.vue'
// import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue'
// import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'

export default {
  components: {
    ChatModule,
    TeacherStatistics,
    TeacherSubstitute,
    TeacherCourses,
    TeacherEvents,
    StudentTimeline,
    Registrations,
    Invoices,
    // ResignationsList,
    CoursesList,
    BRow,
    BCol,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BCard,

    // Local Components
    UserViewUserInfoCard,
    // UserViewUserPlanCard,
    // UserViewUserTimelineCard,
    // UserViewUserPermissionsCard,

    // InvoiceList,
  },
  data() {
    return {
      refreshKey: 0,
      conversation: {},
      messages: {},
      UserData: JSON.parse(localStorage.userData),
      items: [],
      tab: 'course',
    }
  },
  created() {
    this.$http.post('/conversation', { participants: [router.currentRoute.params.id], user_id: this.UserData.id })
      .then(res => {
        this.conversation = {
          id: res.data.id,
          avatar: res.data.avatar,
          name: res.data.name,
        }
        this.messages = {
          data: res.data.messages,
        }
      })
  },
  setup() {
    const userData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {
        userData.value = response.data
        getUserStats(userData.value.role)
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

      const teacherId = ref(null)
      const studentId = ref(null)
      const getUserStats = role => {
        if (role === 'teacher') {
          teacherId.value = parseInt(router.currentRoute.params.id, 10)
        }
        if (role === 'student') {
          studentId.value = parseInt(router.currentRoute.params.id, 10)
        }
      }

    return {
      userData,
      teacherId,
      studentId,
    }
  },
}
</script>

<style scoped>
</style>
