import axios from '@axios'

export default {
  namespaced: true,
  state: {
    classrooms: {
      rows: [],
      totalRecords: 0,
    },
  },
  getters: {},
  /* eslint-disable camelcase */
  mutations: {
    setClassrooms(state, users) {
      state.classrooms = users
    },
  },
  actions: {
    fetchCourses(store, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/substitute/teacher/list', { params: queryParams })
          .then(response => {
            store.commit('setClassrooms', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    applyForSubstitution(store, session_id) {
      return new Promise((resolve, reject) => {
        axios
          .post('//substitute/teacher/list', { session_id })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    cancelRequest(store, session_id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/session/substitute/cancel', { session_id })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    approveRequest(store, teacher_substitute_id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/session/substitute/approve', { teacher_substitute_id })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    declineRequest(store, teacher_substitute_id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/session/substitute/cancel', { teacher_substitute_id })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
