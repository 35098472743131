export default [
  {
    title: 'Actions',
    icon: 'SendIcon',
    children: [
      {
        title: 'Invite employee',
        route: 'send-invitation',
        icon: 'BookIcon',
        roles: ['company'],
      },
    ],
  },
]
