<template>
  <b-card no-body>
    <!-- title and dropdown -->
    <b-card-header class="pb-0">
      <b-card-title>{{ $t('Current Courses Attendance') }}</b-card-title>
      <!-- <b-dropdown
        no-caret
        right
        text="Last 7 Days"
        variant="transparent"
        class="chart-dropdown"
        toggle-class="p-50"
        size="sm"
      >
        <b-dropdown-item
          v-for="day in trackerData.lastDays"
          :key="day"
        >
          {{ day }}
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>
    <!--/ title and dropdown -->

    <b-card-body>
      <b-row>
        <b-col
          sm="2"
          class="d-flex flex-column flex-wrap text-center"
        >
          <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
            {{ trackerData.totalHours }}
          </h1>
          <b-card-text>Hours</b-card-text>
        </b-col>

        <!-- chart -->
        <b-col
          sm="10"
          class="d-flex justify-content-center"
        >

          <!-- apex chart -->
          <vue-apex-charts
            type="radialBar"
            height="270"
            :options="supportTrackerRadialBar.chartOptions"
            :series="supportTrackerRadialBar.series"
          />
        </b-col>
        <!--/ chart -->
      </b-row>

      <!-- chart info -->
      <div class="d-flex justify-content-between">
        <div class="text-center">
          <b-card-text class="mb-50">
            {{ $t('Courses') }}
          </b-card-text>
          <span class="font-large-1 font-weight-bold">{{ trackerData.courses }}</span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50">
            {{ $t('Hours Complited') }}
          </b-card-text>
          <span class="font-large-1 font-weight-bold">{{ trackerData.hoursComplited }}</span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50">
            {{ $t('to Be Done') }}
          </b-card-text>
          <span class="font-large-1 font-weight-bold">{{ trackerData.toBeDone }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
  },
  props: {
    supportTrackerRadialBar: {
      type: Object,
      required: true,
    },
    trackerData: {
      type: Object,
      required: true,
    },
  },
}
</script>
