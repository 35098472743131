import {
  ref, watch, computed, onMounted,
} from '@vue/composition-api'
// import { title } from '@core/utils/filter'
// import { datetimeToDate } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function usePackagesList() {
  // Use toast
  const toast = useToast()

  const refCourseListTable = ref(null)
  const selectedRows = ref([])

  // Table Handlers
  const tableColumns = [
    { key: 'selected', sortable: false, label: '' },
    {
      key: 'id',
      sortable: true,
      // sortKey: true,
      // sortDirection: 'asc',
    },
    // { key: 'student', sortable: true },
    { key: 'course', sortable: true },
    {
      key: 'date_start',
      sortable: true,
      // formatter: datetimeToDate,
      label: 'Start - end dates',
    },
    {
      key: 'time_start',
      sortable: true,
      label: 'Start - end times',
    },
    {
      key: 'teachers_name',
      sortable: true,
      label: 'Teachers',
    },
    { key: 'actions', tdClass: 'table-actions' },
  ]
  // lists
  const number = ref(null)
  const group = ref(null)
  /* eslint-disable camelcase */
  const mastercourse_id = ref(null)
  const classroom_id = ref(null)
  const teacher_id = ref(null)
  const date_start_from = ref(null)
  const date_start_to = ref(null)
  const date_end_from = ref(null)
  const date_end_to = ref(null)
  const fStatus = ref(null)
  const time_of_day = ref(null)
  const cities = ref([])
  const masters = ref([])
  const classrooms = ref([])
  const teachers = ref([])
  const statusArray = ref([
    { name: 'Active', value: 'active' },
    { name: 'Hidden', value: 'hidden' },
    { name: 'Past', value: 'past' },
    { name: 'Deleted', value: 'deleted' },
    { name: 'Planned', value: 'planned' },
  ])
  const TofDArray = ref([
    { name: 'daytime', value: 'daytime' },
    { name: 'anytime', value: 'anytime' },
    { name: 'evening', value: 'evening' },
  ])
  //
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const currentUser = ref({})
  /* eslint-disable-next-line */
  const role_name = ref(window.location.href.split('/')[6])

  const dataMeta = computed(() => {
    const localItemsCount = refCourseListTable.value ? refCourseListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })
  // lists
  const fetchCities = () => {
    store.dispatch('app-courses/fetchCities')
      .then(() => {
        cities.value = store.state.coursesStoreModule.cities
      })
  }
  const fetchMCourses = () => {
    store.dispatch('app-courses/fetchMCourses')
      .then(() => {
        masters.value = store.state.coursesStoreModule.Mcourses
      })
  }
  const fetchTeachers = () => {
    store.dispatch('app-courses/fetchTeachers')
      .then(() => {
        teachers.value = store.state.coursesStoreModule.teachers
      })
  }
  const fetchClassrooms = () => {
    store.dispatch('app-courses/fetchClassrooms')
      .then(() => {
        classrooms.value = store.state.coursesStoreModule.classrooms
      })
  }
  // call on mounted
  onMounted(fetchMCourses)
  onMounted(fetchTeachers)
  onMounted(fetchClassrooms)
  onMounted(fetchCities)
  //
  const refetchData = () => {
    refCourseListTable.value.refresh()
  }

  const fetchCourses = (ctx, callback) => {
    store
      .dispatch('app-courses/fetchCourses', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        number: number.value,
        group: group.value,
        mastercourse_id: mastercourse_id.value,
        teacher_id: teacher_id.value,
        classroom_id: classroom_id.value,
        date_start_from: date_start_from.value,
        date_start_to: date_start_to.value,
        date_end_from: date_end_from.value,
        date_end_to: date_end_to.value,
        status: fStatus.value,
        time_of_day: time_of_day.value,
        /* role_name: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value, */
        // role_name: role_name.value,
      })
        .then(() => {
          const users = store.state.coursesStoreModule.courses.rows
          const total = store.state.coursesStoreModule.courses.totalRecords
          callback(users)
          totalUsers.value = total
          currentUser.value = JSON.parse(localStorage.getItem('userData'))
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching courses list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
      })
  }

  /* eslint-disable-next-line */
  watch([currentPage, perPage, searchQuery, number, group, mastercourse_id, teacher_id, classroom_id, date_start_from, date_start_to, date_end_from, date_end_to, fStatus, time_of_day],  () => {
    // fetchCourses()
    refetchData()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchCourses,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCourseListTable,
    role_name,
    number,
    group,
    mastercourse_id,
    teacher_id,
    classroom_id,
    date_start_from,
    date_start_to,
    date_end_from,
    date_end_to,
    fStatus,
    time_of_day,
    cities,
    masters,
    classrooms,
    teachers,
    statusArray,
    TofDArray,
    currentUser,
    selectedRows,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
